import React, { useEffect } from 'react';
import { Form, FormLabel } from 'react-bootstrap';
import { FunnelLayout } from '../components/FunnelLayout';
import styled from 'styled-components';
import { SaveData } from '../components/GreenButton';

const Styles = styled.div`
color : var(--mindaro-theme-primary-text);

.sub-header{
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 65px;
}
`;

export const PaymentComplete = () => {
    const goToSuccess = async () => {
        await SaveData({cyberPaymentResult: JSON.stringify({status:'COMPLETE'})});
        window.location.replace('/cyber/success');
    }

    useEffect(()=>{
       setTimeout(goToSuccess, 1000);
    });

    return (
        <FunnelLayout HideBackButton={true} HideAmber={true}>
            <Styles>
                <Form autoComplete="off">
                    <FormLabel className="sub-header">
                        Payment complete!  Thank you for your business.
                    </FormLabel>
                </Form>
            </Styles>
        </FunnelLayout>
    )
}
