import React, { useState } from 'react';
import { Form, FormLabel, InputGroup, FloatingLabel } from 'react-bootstrap';
import { Layout } from './components/Layout';
import { GreenButton, LoadApp, SaveData } from './components/GreenButton';
import alertify from 'alertifyjs';
import serializeJSON from './libraries/serializeJSON';

export const Rewards = () => {
    const app = LoadApp();
    const [email, setEmail] = useState(app.email);
    const emailChanged = (event) => setEmail(event.target.value);
    const onSubmit = async (event) => {
        document.getElementById('RewardsGreenButton').disabled = true;
        event.preventDefault();
        const form = event.target;
        const strData = serializeJSON(form);
        const newData = JSON.parse(strData);
        await SaveData(newData);
        alertify.alert('Thanks!', 'We\'ll let you know when the program is launched.').set({glossary: {ok: 'Close'}}).show();
    }
    return (
        <Layout visibleOnMobile={true} hideAmberRow>
            <Form onSubmit={onSubmit} autoComplete="off">
                <input type="hidden" name="Rewards" value="Yes" />
                <FormLabel>Referal program is coming soon! Get rewards up to <b className="pink">$100</b><br/><small><i className="small-grey">Have friends to refer to Mindaro? Leave your email below and we'll let you know when the program is launched.</i></small></FormLabel>
                <InputGroup>
                    <FloatingLabel controlId="inputEmail" label="Email address">
                        <Form.Control name="email" type="email" className="shadow" placeholder="Email address" value={email} onChange={emailChanged} />
                    </FloatingLabel>
                </InputGroup>
                <GreenButton id="RewardsGreenButton">Submit</GreenButton>
            </Form>
        </Layout>
    )
}
