import React, { useState } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Image,
  Nav,
  Navbar,
  Offcanvas,
  Container,
} from "react-bootstrap";
import { scrollToTop } from "../GreenButton";
import mindaroImage from "../../assets/mindaro-light-logo.svg";

// const AmberRowWrapper = styled.div`
//   #amberRow {
//     margin: 0px;
//     background-color: var(--bs-body-bg);
//   }

//   #amberRow .col {
//     padding-left: 0px;
//     padding-right: 0px;
//   }

//   #amberRow hr {
//     margin-top: 42px;
//     opacity: 100%;
//   }

//   #amberImage {
//     margin-top: -62px;
//     height: 87px;
//     width: 87px;
//   }

//   #progressRow {
//     margin: 0px;
//     padding-top: 18px;
//     padding-bottom: 18px;
//     background-color: var(--bs-body-bg);
//   }

//   #progressRow div.progress {
//     position: relative;
//     left: 50%;
//     transform: translateX(-50%);
//     height: 7px;
//     width: 110px;
//   }
//   @media (max-width: 768px) {
//     #amberRow {
//       padding-top: 18px;
//     }
//   }
// `;

export const Header = (props) => {
  const navigate = useNavigate();
  const Navigate = (goTo) => {
    navigate(goTo);
    let closeButton = document.querySelector(
      "#offcanvasNavbar button.btn-close"
    );
    if (closeButton) closeButton.click();
    scrollToTop();
  };

  function goBack() {
    navigate(-1);
  }

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const toggleShow = () => setShow((s) => !s);

  function MindaroMenu(props) {
   
    
    return (
      <Navbar expand={false}>
        <Container>
          <div id="headerLoginButtons">
            <Nav.Item
              as="div"
              className="quote-button btn btn-primary me-2"
              style={{
                lineHeight: "30px",
              }}
              onClick={() => {
                Navigate("/cyber/welcome");
              }}
            >
              Get a free quote!
            </Nav.Item>
          </div>
          <Navbar.Toggle
            aria-controls="offcanvasNavbar"
            style={{ marginLeft: "20px", cursor: "pointer" }}
          >
            <svg className="toggle-icon" onClick={toggleShow} viewBox="0 0 47 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="2" y1="2" x2="45" y2="2" stroke="currentColor" strokeWidth="4" strokeLinecap="round"/>
              <line x1="2" y1="14" x2="45" y2="14" stroke="currentColor" strokeWidth="4" strokeLinecap="round"/>
              <line x1="2" y1="27" x2="45" y2="27" stroke="currentColor" strokeWidth="4" strokeLinecap="round"/>
            </svg>
          </Navbar.Toggle>
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            show={show}
            onHide={handleClose}
            aria-labelledby="offcanvasNavbarLabel"
            placement="end"
          >
            <Offcanvas.Header>
              <svg class="toggle-icon bi bi-x-lg" onClick={toggleShow} xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 16 16" data-bs-dismiss="offcanvas" aria-label="Close">
                <path fill-rule="evenodd" d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"/>
                <path fill-rule="evenodd" d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"/>
              </svg>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end nav-padding flex-grow-1">
              <Nav.Link
                  className="profile-button"
                  style={{ display: "none" }}
                  onClick={() => {
                    Navigate("/profile");
                  }}
                >
                  My profile
                </Nav.Link>

                <Nav.Link
                  className="login-button"
                  style={{ display: "none" }}
                  onClick={() => {
                    Navigate("/login");
                  }}
                >
                  Login
                </Nav.Link>


                <Nav.Link
                  onClick={() => {
                    Navigate("/");
                  }}
                >
                  Home
                </Nav.Link>

                <Nav.Link
                  href="/about"
                >
                  About
                </Nav.Link>
                
                <Nav.Link
                  href="/marketplace"
                >
                  Partners marketplace
                </Nav.Link>

                <Nav.Link
                  href="/partners"
                >
                  Become a partner
                </Nav.Link>
                
                <Nav.Link
                  href="/support"
                >
                  Support
                </Nav.Link>
                
                <Nav.Link
                  href="/blogs"
                >
                  Blog
                </Nav.Link>

                <Nav.Link
                  href="/claims"
                >
                  Claims
                </Nav.Link>
                <Nav.Link
                 href="/privacy"
                >
                  Privacy policy
                </Nav.Link>
                
                <Nav.Link
                  href="/terms"
                >
                  Terms &amp; Conditions
                </Nav.Link>

                
              </Nav>
              
            </Offcanvas.Body>
            <div className="left-align mb-4" style={{paddingLeft:40}}>
                <a className="text-mindaro-third" href="mailto:hello@mindaro.io">hello@mindaro.io</a>
            </div>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    );
  }

  function MindaroImage() {
    return (
      <Link to="/">
        <Image id="mindaroImage" src={mindaroImage} />
      </Link>
    );
  }

  function MindaroRow(props) {
    if (props.minimalHeader) {
      return (
        <div className="header-wrapper">
          <Container>
            <Row id="mindaroRow">
              {
                props.backButton && <Col xs="auto" className="d-flex align-items-center">
                  <i
                    id="mindaroBack"
                    className="fas fa-chevron-left"
                    onClick={goBack}
                  ></i>
                </Col>
              }
              <Col xs="auto" className="d-flex align-items-center">
                <MindaroImage />
              </Col>
              <Col>
                <MindaroMenu />
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else {
      return (
        <div className="header-wrapper">
          <Container>
            <Row id="mindaroRow">
              <Col className="d-flex align-items-center ps-0">
                <MindaroImage />
              </Col>
              <Col xs="auto" className="ps-0">
                <MindaroMenu />
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

  // function AmberRow() {
  //   return (
  //     <>
  //       <div className="bg-white d-flex pt-4">
  //         <Container>
  //           <Row>
  //             <Col className="d-flex justify-content-start ps-0">
  //               <i
  //                 id="mindaroBack"
  //                 className="fas fa-chevron-left"
  //                 onClick={goBack}
  //                 ></i>
  //             </Col>
  //           </Row>
  //         </Container>
  //       </div>
  //       <Row id="amberRow">
  //         <Col>
  //           <hr />
  //         </Col>
  //         <Col>
  //           <hr />
  //           <Image id="amberImage" src={amberImage} />
  //         </Col>
  //         <Col>
  //           <hr />
  //         </Col>
  //       </Row>
  //     </>
  //   );
  // }

  // function ProgressRow(props) {
  //   return (
  //     <Row id="progressRow">
  //       <Col>
  //         {typeof props.percent == "undefined" ? (
  //           <i>&nbsp;</i>
  //         ) : (
  //           <ProgressBar min={0} max={100} now={props.percent} />
  //         )}
  //       </Col>
  //     </Row>
  //   );
  // }

  if (props.minimalHeader) {
    return (
      <MindaroRow minimalHeader={props.minimalHeader} backButton={props.backButton}/>
    );
  } else {
    return (
      <>
        <MindaroRow minimalHeader={props.minimalHeader} />
        {/* <AmberRowWrapper>
          {!props.hideAmberRow && <AmberRow /> }
          <ProgressRow percent={props.percent} />
        </AmberRowWrapper> */}
      </>
    );
  }
};
