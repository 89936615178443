import React, { useState } from 'react';
import { Form} from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import alertify from 'alertifyjs';

import { Layout } from './components/Layout';
import { GreenButton } from './components/GreenButton';
import { cleanErrorMessage } from './libraries/AuthLibraries';
import styled from "styled-components"


const Styled = styled.div`

.header{
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 66px;
    text-align: center; 
    color: #0D0D0D;
    max-width : 460px;
    margin-bottom : 35px;
    margin-top : 100px;
    
}

.sub-header{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    color: #0A0909;
    margin-bottom: 50px;

    
}
.custom-input{
    margin-bottom: 25px;
    color : var( --mindaro-theme-primary-background);
    border-top: none;
    border-left:none;
    border-right:none;
    border-bottom: 2px solid  var(--mindaro-theme-primary-background);
    outline:none;
    padding-left:0px;
    background-color: transparent;
    border-radius : 0;
    max-width : 300px;
}

input::placeholder {
    color:var( --mindaro-theme-primary-background);
    
}
.buttonWrapper button{
    background:var(--mindaro-theme-primary-background);
    min-width: 270px;
    border: none;
}

.buttonWrapper{
margin-bottom: 300px;
}
@media (max-width : 567px) {
    .header{
        text-align : center;
        font-size: 40px;
        line-height: 40px;
        width : 100%;
        margin-top : 20px;
    }  
    .buttonWrapper{
        position : absolute;
        margin-bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        margin-top : 5vh;

    }
}
`

export const Login = () => {
    const navigate = useNavigate();
    const nextPage = '/';
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = async (event) => {
        event.preventDefault();
        Auth.signIn(email, password)
            .then(user => {
                navigate(nextPage);
            })
            .catch(error => {
                alertify.alert('Log in error', cleanErrorMessage(error));
            });
    }
    return (
        <Layout>
            <Styled>
            <Form onSubmit={onSubmit} autoComplete="off">
                <p className="header">Log in to your Mindaro account!</p>
                <p className="sub-header">Don't have an account yet? <Link to="/create_account">Sign up</Link></p>
              
                
                    <input id="inputEmail" name="email" type="email" className="custom-input" placeholder="Email address" value={email} onChange={(event)=>setEmail(event.target.value)} />
              
               
                    <input id="inputPassword" name="password" type="password" className="custom-input" placeholder="Password" value={password} onChange={(event)=>setPassword(event.target.value)} />
                    <p className="sub-header"> <Link to="/forget_password">Forgot my password</Link></p>
                    <div className="buttonWrapper">
                <GreenButton style={{}}>Log in</GreenButton>
                    </div>
            </Form>
            </Styled>
        </Layout>
    );
}
