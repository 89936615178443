import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, FormLabel } from 'react-bootstrap';
import { GreenButton, LoadApp, SaveData } from '../components/GreenButton';
import { NumberInput} from '../components/CustomInputs';
import { FunnelLayout } from '../components/FunnelLayout';
import styled from "styled-components"

const Styled = styled.div`

color : var(--mindaro-theme-primary-text);

.sub-header{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 65px;
}

`

const title ="How many employees does your business have?"
export const Employees = (props) => {
    const navigate = useNavigate();
    const app = LoadApp();
    const { register, handleSubmit, formState: { errors },setValue,} = useForm({
        defaultValues: {
            fullTimeEmployees: app.fullTimeEmployees,
            partTimeEmployees: "0",
        },
    });
    const onSubmit = async (data) => {
        await SaveData(data);
        navigate(props.next);
    }

    const handleEnteryKey = (event) => {
        if(event.key === 'Enter'){
            const val = document.getElementsByName("fullTimeEmployees")[0].value;
            setValue('fullTimeEmployees', val )
            handleSubmit(onSubmit)();
            console.log(event.key)
        }
    }

    return (
        <FunnelLayout title={title}  percent={props.percent}>
            <Styled>
                <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <FormLabel className="sub-header">The number of your W2 employees including yourself. Don’t include any subcontractors.</FormLabel>
                    <br /><br />
                    
                        <NumberInput onKeyPress={handleEnteryKey} name="fullTimeEmployees" placeholder="Full time employees" register={register} errors={errors} />
                
                        <NumberInput hidden={true} name="partTimeEmployees" value="0" placeholder="Part time employees" register={register} errors={errors} />
                    
                        <div className="buttonwrapper">
                            <GreenButton>Continue</GreenButton>
                        </div>
                </Form>
            </Styled>
        </FunnelLayout>
    )
}
