import React , {useState} from 'react';
import { useForm } from 'react-hook-form';
import { Form, InputGroup, Row, Col, Image, Button, Container, Modal, Spinner } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router';
import styled from "styled-components"
import { Layout } from './components/Layout';
import {TextInput} from "./components/CustomInputs"
import { LoadApp, SaveData } from './components/GreenButton';
import cowbelImage from "./assets/cowbel-white.png"
import { API } from 'aws-amplify';
import { dollarFormat } from './libraries/Formatting';



const MainStyles = styled.div`
margin : 0;
padding : 0;
.banner{
    background: linear-gradient(180deg, #231F20 0%, #8836B4 100%);
    height : 280px;
    margin : 0;
    padding : 0;
}

.custom-input{
    background-color: transparent;
    margin-bottom : 5px;
}

.personal-profile-container{
    background: linear-gradient(99.87deg, #0D0D0D 0%, #8836B4 100%);
    border-radius: 15px;
    padding-top : 70px;
    padding-bottom : 90px;
    margin-top : 100px;
}

.input-container{
width : 385px;
}

.header{
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 48px;
    color : var(--mindaro-theme-primary-text);
}

.hr{
    border: 1px solid #F8F8F8;
    max-width : 830px;
    margin-top : 50px;
    margin-bottom: 70px;
}

.profile-avatar{
    width : 150px;
    height : 150px;
    background: #1B1D21;
    opacity: 0.95;
    box-shadow: -4px -4px 10px rgba(255, 255, 255, 0.07), 4px 4px 10px rgba(0, 0, 0, 0.62);
    border-radius: 50%;
    color : #fff;
    display: flex;
    justify-content : center;
    align-items : center;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    margin-top : 60px;
}

.policy-header-text{
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 66px;
    text-align: center;
    margin : 80px 0;
}

@media (max-width : 767px) {

   .input-container{
       padding : 10px;
   }

   .hr{
     margin : 0 10px;
     display : none;
   }

   .header{
    font-weight: 800;
    font-size: 30px;
    line-height: 40px;
   }

   .personal-profile-container{
    margin-top : 50px;
   }

   .policy-header-text{
       margin : 40px 0;
       font-size: 40px;
    line-height: 55px;
   }
}

.btn-edit{
    border-radius : 15px;
    margin-top : 30px;
    width : 300px;
}
`

const PolicyCardStyles = styled.div`

.policy-card{
    background: linear-gradient(120.07deg, #000000 -1.22%, #8836B4 100%);
    border-radius: 10px;
    height : 440px;
    color : var(--mindaro-theme-primary-text);
    padding : 0;
    margin : 0;
}

.policy-header{
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
}

.policy-sub-header{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    
}

.desc-wraper{
    background: rgba(18, 18, 18, 0.3);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin : 19px 15px;
    width : 285px;
    text-align : center;
}
.details-wraper{
    padding-right : 22px;
    text-align : left;
    margin-left : 20px;
    margin-top : 8px;
}
.details{
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 3px;

}

.policy-btn{
    color : var(--mindaro-theme-primary-text);
    border : 1px solid var(--mindaro-theme-primary-text);
    max-width : 280px;
    height : 48px;
    border-radius: 15px;
    margin-bottom : 20px;
    margin-top: 60px;
}

.payment-btn{
    max-width : 280px;
    height : 48px;
    border-radius: 15px;
    margin-bottom : 20px;
    margin-top: 60px;
    display : flex;
    align-items: center;
    justify-content : center;
}

.policy-btn:hover{
    background:var(--mindaro-orange);
}

`


export const Profile = () => {
    const navigate = useNavigate();
    Auth.currentAuthenticatedUser({ bypassCache: false })
    .catch( error => {
        navigate("/login")
    });
    let app = LoadApp();
    const cowbellQuote = app.cowbellQuote? JSON.parse(app.cowbellQuote) : null;
    const [showPreview, setShowPreview] = useState(false);
    const [previewURL, setPreviewURL] = useState('');
    let failCounter =0;

    if (app.name && !(app.firstName && app.lastName)) {
        let [tempFirst, tempLast] = app.name.split(' ');
        app.firstName = tempFirst;
        app.lastName = tempLast;
    }

    const {  register, formState: { errors }, control, getValues} = useForm({
        defaultValues: {
            firstName: app.firstName,
            lastName: app.lastName,
            email: app.email,
            phone: app.phone,
            businessName: app.businessName,
            businessAddress: app.address,
        },
    });

    const pdfViewerEnabled = () => {
        return !!navigator.pdfViewerEnabled;
    }
    const doShowPreview = (event) => {
        event.preventDefault();
        setPreviewURL('');
        setShowPreview(true);
        setTimeout(getPreviewURL, 100);
    }

    const getPreviewURL = async () => {
        let app = LoadApp();
        let quote = JSON.parse(app.cowbellQuote);
        let accountId = quote.accountId;
        let quoteId = quote.id;
        let createdDate = Date.parse(quote.created);
        let result;
        let policy;
        let policyId
        try {
            policy = await API.post('applicantwebapprestapi', '/cyber', {body: { method: 'details', accountId, quoteId, createdDate }});
            policyId = policy.data.details.policyId;
            result = await API.post('applicantwebapprestapi', '/cyber', {body: { method: 'policydocs',accountId, policyId, createdDate }});
            if ((!result.error) && result.data && result.data.policyURL && result.data.policyURL !== '') {
                if (pdfViewerEnabled()) {
                    setPreviewURL(result.data.policyURL);
                } else {
                    setPreviewURL(`${window.location.origin}/pdfjs/web/viewer.html?file=${encodeURIComponent(result.data.policyURL)}`);
                }
            } else {
                throw new Error('No previewURL, yet, retrying...');
            }
        } catch (err) {

            if(failCounter < 3){
            console.error(err)
            setTimeout(getPreviewURL, 1000)
            failCounter++
            }

            else{
                setShowPreview(false)
                
            }
        }
    }
  
    
  
    const onSaveSection = async (event) => {
        event.preventDefault();
        let values = getValues();
        app.name = values.firstName +' '+ values.lastName;
        app.email = values.email;
        app.phone = values.phone;
        app.businessName = values.businessName;
        app.address = values.businessAddress;
        app.zipCode = values.businessZipCode;
        await SaveData(app);
        
    }
    const onLogout = async (event) => {
        await Auth.signOut();
        navigate('/');
    }


    const AvatarLetters = () => {
        if(app.firstName != null && app.lastName !=null){
        return app.firstName[0] + app.lastName[0]
        }

        return "?"
    }
    
    const PolicyCard = () => {
        return(
        <PolicyCardStyles>
            <Row className="d-flex justify-content-center">
            <Col md="3" sm="4" className="policy-card">
            <Row className="d-flex justify-content-center">
                <Col md="12" className="desc-wraper">
                    <span className="policy-header">Cyber liability</span>
                    <p className="policy-sub-header">Underwriten by Cowbel Cyber</p>
                    <Image src={cowbelImage} />
                </Col>
           </Row>
           <Row className="details-wraper">
                <Col md="12" >
                    <p className="details">Policy number: <b>{cowbellQuote.policyNumber}</b> </p>
                    <p className="details">Up to: <b>{dollarFormat(cowbellQuote.initialRequestData.limit)}</b></p>
                    <p className="details">Deductible: <b>{dollarFormat(cowbellQuote.initialRequestData.deductible)}</b></p>
                    <p className="details">Ending: <b>{new Date(cowbellQuote.expiresOn).toLocaleDateString()}</b></p>
                </Col>
           </Row>

           <Row className="d-flex justify-content-center mt-4">
               
             { app.cyberPaymentResult ? 
             
                     <Button onClick={doShowPreview}  className="policy-btn" variant='outline-primary'> Download policy documents </Button>
                 
               :      <Button type="submit"  href="/cyber/contact_info" variant='primary' className="payment-btn"> Complete payment </Button>
                            

        }
               
           </Row>
           </Col>
           </Row>
        </PolicyCardStyles>
        );

    }
    
    return (
        <MainStyles>
        <Layout fullWidth={true}>
            <Container className='banner p-0' fluid={true}>
            <Row className="d-flex justify-content-center">
                    <div className="profile-avatar">
                         <span>{AvatarLetters()} </span>
                    </div>
                    </Row>
            </Container>

            <Container>
            <Form noValidate style={{textAlign:'left',whiteSpace:'nowrap'}} onSubmit={onSaveSection}  autoComplete="off">

                <div className="personal-profile-container">
                    <Row >
                        <Col md="6" className="d-flex justify-content-center">
                                <p className="header">Personal <br/> information</p>
                        </Col>

                        <Col md="6" className="d-flex justify-content-flex-start align-items-center">
                        
                    <div className="input-container">
                        <TextInput className="custom-input" register={register} errors={errors} control={control} placeholder="First name" name="firstName"  /> 
                        <TextInput className="custom-input" register={register} errors={errors} control={control} placeholder="Last name" name="lastName"  /> 
                        <TextInput className="custom-input" register={register} errors={errors} control={control} placeholder="Email" name="email"  /> 
                        <TextInput className="custom-input" register={register} errors={errors} control={control} placeholder="Phone" name="phone" /> 
                     </div>
                        </Col>
                    </Row>
                    <Row className="d-flex justify-content-center">
                        <div className="hr"></div>
                    </Row>
                    <Row >
                        <Col md="6" className="d-flex justify-content-center">
                                <p className="header">Business <br/>information</p>
                        </Col>

                        <Col md="6" className="d-flex justify-content-flex-start align-items-center">
                        
                    <div className="input-container">
    
                        <TextInput placeholder="Business name" className="custom-input" register={register} errors={errors} control={control} name="businessName" />
                        <TextInput placeholder="Business address" className="custom-input" register={register} errors={errors} control={control} name="businessAddress" />
                        <Button type="submit" className="btn-edit">Edit</Button>
                    </div>
                        </Col>
                    </Row>
                    </div>   
            </Form>
            { cowbellQuote && <>
            <p className="policy-header-text">My policies</p>
                <Container className="p-0"> 
                    <Row>
                        <PolicyCard/>
                    </Row>
                </Container>
            </>
                }
            <InputGroup style={{justifyContent:'center',marginTop:'40px'}}>
                    <Button className="orange" id="logout-button" onClick={onLogout} style={{color:'var(--mindaro-theme-third)',backgroundColor:'transparent',borderColor:'transparent',maxWidth:'max-content'}}>Log out</Button>
                </InputGroup>
                
            </Container>      
        </Layout>
        <Modal show={showPreview} keyboard={true} onHide={()=>setShowPreview(false)} dialogClassName="preview-dialog" contentClassName="preview-content">
                    <Modal.Header closeVariant="white" closeButton style={{borderBottom:'none',paddingBottom:'8px',color:"#fff",height:'48px'}}>
                        <Modal.Title style={{fontSize:'16px'}}>Policy Preview PDF</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{padding:'0px 8px 8px 8px',height:'calc(100% - 50px)'}}>
                        { previewURL === ''
                            ?
                                <Spinner variant="warning" animation="border" role="status" style={{position:'absolute',top:'calc(50% - 50px)',left:'calc(50% - 50px)',width:'100px',height:'100px',borderWidth : "10px"}}>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            :
                                <iframe src={previewURL} style={{width:'100%',height:'calc(100% - 8px)'}} title="Preview"></iframe>
                        }
                    </Modal.Body>
                </Modal>
        </MainStyles> 
    )
}
