import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, FormLabel, Modal, Spinner } from 'react-bootstrap';
import { API } from 'aws-amplify';
import alertify from 'alertifyjs';

import { GreenButton, LoadApp, SaveData, scrollToTop } from '../components/GreenButton';
import { TextInput } from '../components/CustomInputs';
import { cleanErrorMessage } from '../libraries/AuthLibraries';
import { FunnelLayout } from '../components/FunnelLayout';
import  styled from "styled-components"

const Styles = styled.div`
color : var(--mindaro-theme-primary-text);

.header{
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    margin-bottom: 17px;
}

.sub-header{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 65px;
}

.form-check-label{
    padding-bottom: 25px;
}

.btn-wrapper{
position : relative;
margin-top : 27px;
}

@media (max-width : 567px) {
    .btn-wrapper{
        margin-top:57px;
    }    
    .form-check-label{
        max-width : 287px;
        ont-weight: 300;
        font-size: 17px;
        line-height: 24px;
    } 
}
`
const ModalStyle = styled.div`
background: #282727;
box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.18);
color : var(--mindaro-theme-primary-text);
width: 500px;
height : 400px;
display: flex;
justify-content: center;
align-items : center;

.SpinnerText {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
}
@media(max-width : 768px) {
    width: 100%;
}
`

export const ContactInfo = (props) => {
    const app = LoadApp();
    const [showSpinner, setShowSpinner] = useState(false);
    const [showApplication, setShowApplication] = useState(false);
    const [applicationURL, setApplicationURL] = useState('');
    const { register, handleSubmit, getValues, formState: { errors }} = useForm({
        defaultValues: {
            email: app.email,
            phone: app.phone,
            agreeToBeInfoSecContact: app.agreeToBeInfoSecContact,
            agreeToTermsAndConditions: app.agreeToTermsAndConditions,
        },
    });
    const pdfViewerEnabled = () => {
        return !!navigator.pdfViewerEnabled;
    }
    const doShowApplication = (event) => {
        event.preventDefault();
        setApplicationURL('');
        setShowApplication(true);
        setTimeout(getApplicationURL, 100);
    }
    const getApplicationURL = async () => {
        let app = LoadApp();
        let quote = JSON.parse(app.cowbellQuote);
        let accountId = quote.accountId;
        let quoteId = quote.id;
        let createdDate = Date.parse(quote.created);
        let result;
        try {
            console.log('Requesting applicationURL...');
            result = await API.post('applicantwebapprestapi', '/cyber', {body: { method: 'application', accountId, quoteId, createdDate }});
            console.log('result:', result);
            if ((!result.error) && result.data && result.data.applicationURL && result.data.applicationURL !== '') {
                if (pdfViewerEnabled()) {
                    setApplicationURL(result.data.applicationURL);
                } else {
                    setApplicationURL(`${window.location.origin}/pdfjs/web/viewer.html?file=${encodeURIComponent(result.data.applicationURL)}`);
                }
            } else {
                throw new Error('Quote documents not ready, yet, retrying...');
            }
        } catch (err) {
            console.error(err);
            setTimeout(getApplicationURL, 1000);
        }
    }
    const getPaymentRedirectURLs = () => {
        const baseURL = window.location.origin;
        return {
            paymentPortalDeclineOfferRedirectURL: baseURL +'/cyber/payment_cancel',
            paymentPortalCancelRedirectURL: baseURL +'/cyber/payment_cancel',
            insuredESignCompletionURL: baseURL +'/cyber/payment_complete',
        }
    }
    const onSubmit = async (data) => {
        setShowSpinner(true);
        try {
            await SaveData(data);
            let app = {...LoadApp(), ...data};
            if (!('cowbellAttestAndBind' in app)) {
                let result = await API.post('applicantwebapprestapi', '/cyber', {body: { method: 'bind', app}});
                console.log(result);
                if (result.error) {
                    alertify.alert('Error...', result.error);
                } else if (result.data.error) {
                    alertify.alert('Error...', result.data.error);
                } else {
                    const account = result.data.account;
                    console.log('account:', account);
                    const attest = result.data.attest;
                    console.log('attest:', attest);
                    const bind = result.data.bind;
                    console.log('bind:', bind);
                    await SaveData({
                        cowbellAccount: JSON.stringify(account),
                        cowbellAttestAndBind: JSON.stringify({ attest, bind }),
                        cowbellAttestClientIP: attest.clientIP,
                        cowbellAttestTimestamp: attest.timestamp,
                    });
                    app = LoadApp();
                }
            }

            if (('cowbellAttestAndBind' in app)) {
                const cowbellQuote = JSON.parse(app.cowbellQuote);
                let paymentResult = await API.post('applicantwebapprestapi', '/payment', {body: { ...getPaymentRedirectURLs(), app, amount: cowbellQuote.totalPremium}});
                await SaveData({ cyberPaymentQuote: JSON.stringify(paymentResult.data) });
                const insuredURL = paymentResult.data?.item?.eSignResult?.insuredURL || '';
                console.log('insuredURL:', insuredURL);
                window.location.href = insuredURL;
            }
        } catch (err) {
            alertify.alert('Error...', cleanErrorMessage(err));
        }
    }
    return (
        <FunnelLayout percent={props.percent}>
            <Styles>
                <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <FormLabel className="header">
                        Almost there!
                    </FormLabel>    

                    <FormLabel className="sub-header">
                        Please fill your contact information below.
                    </FormLabel>

                    <TextInput
                        name="email"
                        placeholder="Email address"
                        register={register}
                        required={false}
                        validate={(value) => !!getValues().phone || !!value}
                        errors={errors}
                    />

                    <TextInput
                        name="phone"
                        placeholder="Phone number"
                        register={register}
                        required={false}
                        validate={(value) => !!getValues().email || !!value}
                        errors={errors}
                    />

                    <br/>

                    <Form.Check {...register('agreeToBeInfoSecContact', {required: true})}
                        id="inputAgreeToBeInfoSecContact"
                        label="I agree to be the designated Information Security Contact"
                        name="agreeToBeInfoSecContact"
                        type="checkbox"
                    />
               
                    <Form.Check
                        style={{whiteSpace:'nowrap'}}
                        id="inputAgreeToTermsAndConditions"
                        name="agreeToTermsAndConditions"
                        type="checkbox"
                    >
                        <Form.Check.Input {...register('agreeToTermsAndConditions', {required: true})} />
                        <Form.Check.Label>
                            I agree to the <a className='orange' target='_blank' href="/terms-conditions-cowbell">terms &amp; conditions</a> and <a className='orange' target="_blank" rel="noreferrer" href={applicationURL} onClick={doShowApplication}>Insurance Fraud Warnings and State-Specific Signature-Bearing Endorsements</a>.
                        </Form.Check.Label>
                    </Form.Check>
                    <div className="btn-wrapper">
                        <GreenButton>Complete Payment</GreenButton>
                    </div>
                </Form>
            </Styles>
            <Modal show={showApplication} keyboard={true} onHide={()=>setShowApplication(false)} dialogClassName="preview-dialog" contentClassName="preview-content">
                <Modal.Header closeVariant="white" closeButton style={{borderBottom:'none',paddingBottom:'8px',color:'#fff',height:'48px'}}>
                    <Modal.Title style={{fontSize:'16px'}}>Application PDF</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{padding:'0px 8px 8px 8px',height:'calc(100% - 50px)'}}>
                    { applicationURL === ''
                        ?
                            <Spinner variant="primary" animation="border" role="status" style={{position:'absolute',top:'calc(50% - 50px)',left:'calc(50% - 50px)',width:'100px',height:'100px'}}>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        :
                            <iframe src={applicationURL} style={{width:'100%',height:'calc(100% - 8px)'}} title="Application"></iframe>
                    }
                </Modal.Body>
            </Modal>
            <Modal contentClassName="modalStyles" show={showSpinner} fullscreen={false} style={{top: "20%", borderRadius : "15px"}}>
                <ModalStyle>
                    <Modal.Body>
                        <h2 style={{fontWeight:'700'}}>Sit tight while we use our super cool tech!</h2>
                        <Spinner variant="primary" animation="border" role="status" style={{width:'100px',height:'100px'}}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Modal.Body>
                </ModalStyle>
            </Modal>
        </FunnelLayout>
    )
}