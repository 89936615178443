import React from "react";
import { Form, FloatingLabel, InputGroup, Image } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Switch from 'react-switch';
import NumberFormat from 'react-number-format';
import styled from "styled-components"
import FloatingSelect, { FloatingAsyncSelect } from "./FloatingSelect";
import onIcon from "../assets/onIcon.png"
import offIcon from "../assets/offIcon.png"
import checkbox from "../assets/check.png"

const Styles = styled.div`
color:#fff;
.form-control{
  color:#fff;
  border-top: none;
  border-left:none;
  border-right:none;
  border-bottom: 1px solid #fff;
  outline:none;
  padding-left:0px;
  background-color: #272626;
  margin-bottom: 35px;
  border-radius : 0;
}

.form-floating : focus{
  border-top: none;
  border-left:none;
  border-right:none;
  outline:none;
  box-shadow:none;
  
  
}

.form-control : placeholder{
    color:"red";
    box-shadow: none;
    outline : none;
}
label{
color: #fff;
padding: 0;
margin:0;
}

.hide{
    display :none;
}


@media(max-width : 576px){
    .form-control{
    margin-bottom:12px;
    
    }
}



`


const SwitchStyle = styled.div`
height : 100%;
.switch{
position: absolute;
}

icons{
    position: relative;
}
`

function Capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1);
}


export function TextInput(props) {
    return (
        <Styles>
        <FloatingLabel className={props.formLabelClassName}  controlId={'input'+ Capitalize(props.name)} label={props.placeholder}>
            <Form.Control  autoComplete="off" {...props.register(props.name, { required: 'required' in props ? props.required : true, validate: props.validate })} name={props.name} type={props.type} className={`${props.className} ${props.errors[props.name] ? "is-invalid" : ""}`} placeholder={props.placeholder} />
        </FloatingLabel>
        </Styles>
    );
}

export function NumberInput(props) {
    return (
        <Styles>
        <FloatingLabel controlId={'input'+ Capitalize(props.name)} label={props.placeholder} className={props.hidden && "hide"}>
            <Form.Control onKeyPress={props.onKeyPress} autoComplete="off" hidden={props.hidden}  {...props.register(props.name, { required: true })} name={props.name} type="number" className={` ${props.errors[props.name] ? "is-invalid" : ""}`} placeholder={props.placeholder} step="0.1" inputMode="decimal" pattern="[0-9]*" onChange={props.onChange} />
        </FloatingLabel>
        </Styles>
    );
}

export function FormatNumberInput_(props) {
    function CustomControl(controlProps) {
        console.log('props:', props);
        console.log('controlProps:', controlProps);
        let temp = {...controlProps};
        delete temp.value;
        return (
            
            <Form.Control {...props.register(props.name, { required: true })}
                {...temp}
            />
           
        );
    }
    return (
        <Styles>
       <FloatingLabel controlId={'input'+ Capitalize(props.name)} label={props.placeholder} className={props.className}>
            <NumberFormat
                name={props.name}
                type="tel"
                placeholder={props.placeholder}
                thousandSeparator=","
                prefix="$"
                allowNegative={false}
                decimalScale={0} // don't allow decimals
                customInput={CustomControl}
                isNumericString={true} // value is a numeric string
            />
       </FloatingLabel>
    </Styles>
    );
}
export function FormatNumberInput(props) {
    function CustomControl(controlProps) {
        return (
            <Styles>
            <Form.Control autoComplete="off" {...controlProps} autoFocus
                className={`${props.errors[props.name] ? "is-invalid" : ""}`}
            />
            </Styles>
        );
    }
    return (

        <Styles>
            <Controller
                autoComplete="off"
                name={props.name}
                control={props.control}
                rules={{ required: true }}
                render={({ field }) =>  <FloatingLabel controlId={'input'+ Capitalize(props.name)} label={props.placeholder} className={props.className}>
                                            <NumberFormat
                                                {...field}
                                                name={props.name}
                                                type="tel"
                                                placeholder={props.placeholder}
                                                thousandSeparator={true}
                                                prefix="$"
                                                allowNegative={false}
                                                decimalScale={0} // don't allow decimals
                                                isNumericString={false}
                                                customInput={CustomControl}
                                                onValueChange={props.onValueChange}
                                                allowLeadingZeros={false}
                                            />
                                        </FloatingLabel>
                }
            />
        </Styles> 
    );
}

export function CustomSelect(props) {
    return (
        
        <Controller
            name={props.name}
            control={props.control}
            rules={{ required: 'required' in props ? props.required : true }}
            render={({ field }) => <FloatingSelect {...field}
                                       id={'input'+ Capitalize(props.name)}
                                       name={props.name}
                                       className={`react-select-container ${props.errors[props.name] ? "is-invalid" : ""}`}
                                       classNamePrefix="react-select"
                                       options={props.options}
                                       placeholder={props.placeholder}
                                       filterOption={props.filterOption}
                                       isOptionDisabled={props.isOptionDisabled}
                                   />}
        />
        
        
    );
}

export function CustomAsyncSelect(props) {
    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={{ required: true }}
            render={({ field }) => <FloatingAsyncSelect
                                        {...field}
                                        id={'input'+ Capitalize(props.name)}
                                        name={props.name}
                                        className={`react-select-container ${props.errors[props.name] ? "is-invalid" : ""}`}
                                        classNamePrefix="react-select"
                                        loadOptions={props.loadOptions}
                                        onInputChange={props.onInputChange}
                                        defaultOptions
                                        cacheOptions
                                        placeholder={props.placeholder}
                                        noOptionsMessage={props.noOptionsMessage}
                                    />}
        />
    );
}

export function RadioGroup(props) {
    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={{ required: true }}
            render={({ field }) =>
                <InputGroup className={`check-row ${props.errors[props.name] ? "is-invalid" : ""}`} style={props.groupStyle} >
                {props.options.map((option) => (
                    <Form.Check {...field} key={`option~${props.name}~${option.value}`} type="radio" inline name={props.name} {...option} checked={option.value === props.control._formValues[props.name]} />
                ))}
                </InputGroup>}
        />
    );
}

export const LabeledSwitch = (props) => {
    return (
        <SwitchStyle>
        <Controller
            name={props.name}
            control={props.control}
            render={({ field }) => (
                <div className={`labeled-switch ${props.className}`}>
                    <label>{props.children}</label>
                    <Switch
                        {...field}
                        checked={field.value}
                        className="mindaro-switch switch"
                        height={24}
                        width={48}
                        handleDiameter={16}
                        boxShadow="3px 2px 6px rgba(0, 0, 0, 0.8)"
                        onColor="#181A1E" // TODO: Find a way to use variables
                        offColor="#181A1E" // TODO: Find a way to use variables
                        offHandleColor="#FF01B8" // TODO: Find a way to use variables
                         
                        uncheckedHandleIcon={
                            <div
                                style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100%",
                                color: "red",
                                fontSize: 18,
                                }}
                            >
                            <Image className="icons" src={offIcon}/>
                            </div>
                          }

                          checkedHandleIcon={
                            <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 20,
                            }}
                          >
                            <Image src={onIcon}/>
                            </div>
                          }

                          checkedIcon={
                              <Image src={checkbox}/>
                          }
                          uncheckedIcon={
                            <div
                            style={{
                              display: "flex",
                              justifyContent: "right",
                              alignItems: "center",
                              height: "100%",
                              fontSize: 20,
                            }}
                          >
                            <Image src={checkbox}/>
                            </div>
                          }
                    />
                </div>
            )}
        />
        </SwitchStyle>
    );
}