import React from "react"
import { AmberRow } from "./AmberRow"
import { Layout } from "./Layout"
import {Row, Col, Container} from "react-bootstrap"
import styled from "styled-components"

const Styles = styled.div`
background-color: #272626;
width: 100%;
height: auto;

@media(max-width : 768px) {
 min-height: 100vh;
}
`
export const FunnelLayout = (props) => {
    
    const width = props.Col === undefined ? 4 : props.Col;

    return(
        <Styles>
        <Layout  >
            
            <Container className="p-0">
            <Row><AmberRow HideBackButton={props.HideBackButton} percent={props.percent} title={props.title} HideAmber = {props.HideAmber}/> </Row>
            <Row className="d-flex justify-content-center">
            <Col md={width} sm={12}>{props.children}</Col>
            </Row>
            </Container>
            
        </Layout>
        </Styles>
    )
}