import { Container, Row, Col, Image, FormLabel } from "react-bootstrap"
import {useNavigate} from "react-router-dom"
import styled from "styled-components"
import amberImage from "../assets/NewAmber.png";
import {CircularProgressbarWithChildren, buildStyles} from "react-circular-progressbar"
const Styles = styled.div`
color:#fff;

#amberImage {
    height: 81px;
    width: 81px;
    border: 5px solid #1B1D21;
  }
  
.form-label{
    color:#fff;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
    max-width: 400px;
}  
.mindaroBack{
    color: #fff;
}
`

export const AmberRow = ({title, percent, HideAmber, HideBackButton}) => {
const navigate = useNavigate();

    function goBack() {
        navigate(-1);
    }
    return(
        <Styles >
        <Container className="p-0" >
            {HideBackButton ? <></> :
          <Row> 
            <Col xs="auto" className="d-flex align-items-center mt-4">
                  <i
                    className="fas mindaroBack  fa-chevron-left"
                    onClick={goBack}>

                    </i>
                </Col>
            </Row>

    }
            

        {     

            HideAmber? <></> :
            <Row className="d-flex justify-content-center">
              
               <Col md={2} style={{width:"125px"}} xs={4} >
                <CircularProgressbarWithChildren strokeWidth={5} minValue={0} maxValue={100}  value={percent} styles={buildStyles({
                    pathColor: "#F98342",
                    trailColor: "#1B1D21",
                    strokeLinecap: "butt",
                })}>
                 <Image id="amberImage" roundedCircle src={amberImage} />
                 </CircularProgressbarWithChildren>
                
                 </Col>
              
            </Row>

            }
            <Row className="d-flex justify-content-center">
            <FormLabel>
                {title}
            </FormLabel>
            </Row>

        </Container>
        
        </Styles>
    )

}