import React from 'react';
import { Button } from 'react-bootstrap';
import { DataStore } from 'aws-amplify';
import { Application } from '../models/index';

var subscription = null;

export function scrollToTop() {
    setTimeout(()=>{ window.scrollTo(0,0); }, 1);
}

export function LoadApp() {
    let appId = localStorage.getItem('appId');
    if ((appId != null) && (subscription == null)) {
        subscription = DataStore.observe(Application, appId).subscribe(msg => {
            console.log('observed: ', msg);
        });
    }
    return JSON.parse(localStorage.getItem('app') || '{}');
}

async function SaveApplication(newData) {
    let appId = localStorage.getItem('appId');
    let original = null;
    if (appId != null) {
        // if we have a local appId, try to load the latest data from the backend
        try {
            original = await DataStore.query(Application, appId);
            // if we get multiples for some reason then just take the first
            if (Array.isArray(original)) original = original[0];
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
    if (!original) {
        // if we still don't have the original data, either because we had no appId or the records doesn't exist, create a new one
        original = new Application({});
        appId = original.id;
        localStorage.setItem('appId', appId);
    }
    try {
        const result = await DataStore.save(Application.copyOf(original, updated => {
            for (let key in newData) {
                updated[key] = newData[key];
            }
        }));
        localStorage.setItem('appId', result.id);
    } catch (error) {
        console.error(error);
    }
}

export async function SaveData(newData) {
    // load current app data from local storage
    const app = LoadApp();
    // merge in new values
    newData = {...app, ...newData};
    // save it back to local storage
    localStorage.setItem('app', JSON.stringify(newData));
    // save it to the backend
    await SaveApplication(newData);
}

export const GreenButton = (props) => {
    return (
        <React.Fragment>
            <div className="form-flex-spacer"></div>
            <Button style={props.style} id={props.id} className="shadow green-button" type="submit" disabled={'disabled' in props ? props.disabled : false}>{props.children}</Button>
        </React.Fragment>
    )
}
