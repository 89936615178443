import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Form, FormLabel} from 'react-bootstrap';
import styled from "styled-components"
import { GreenButton, LoadApp, SaveData } from '../components/GreenButton';
import { TextInput } from '../components/CustomInputs';
import { FunnelLayout } from '../components/FunnelLayout';
const Styles = styled.div`
   
.form-label{
    color:#fff;
    margin-bottom: 20px;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    }

`

export const Welcome = (props) => {
    const title = "Hey there, I'm Amber and I'll find the best Cyber policy to fit your needs."
    const navigate = useNavigate();
    const app = LoadApp();
    const { register, handleSubmit, formState: { errors }} = useForm({
        defaultValues: {
            firstName: app.firstName,
            lastName: app.lastName,
            jobTitle: app.jobTitle
            // zipCode: app.zipCode,
        },
    });

    const onSubmit = async (data) => {
        await SaveData(data);
        navigate(props.next);
    }

    const [searchParams] = useSearchParams();

    const parseBusinessFromQueryString = async () => {
        const business = searchParams.get('business');
        const naicsCode = searchParams.get('naics_code');
        if (!business || !naicsCode) return;
        await SaveData({ business: business, naicsCode: naicsCode });
    };

    parseBusinessFromQueryString();

    return (
       <FunnelLayout percent={props.percent} title={title}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Styles>
                    <FormLabel>
                        Are you ready? 
                    </FormLabel>
                </Styles>
                <TextInput name="firstName" placeholder="First name" register={register} errors={errors} />
                <TextInput name="lastName" placeholder="Last name" register={register} errors={errors} />
                <TextInput name="jobTitle" placeholder="Job title" register={register} errors={errors} required={false} />
                <div className="buttonwrapper">
                <GreenButton>Let's do this</GreenButton>
                </div>
            </Form>
        </FunnelLayout>
    )
}
