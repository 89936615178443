export const LicensesData = [
{state:"Alabama", pcLicense:"3001321476", surplusLicence: "3001321476"},
{state:"California", pcLicense:"4128673", surplusLicence: "4128673"},
{state:"Colorado", pcLicense:"738300", surplusLicence: "738300"},
{state:"Delaware", pcLicense:"3001831367", surplusLicence: "3001831367"},
{state:"Florida", pcLicense:"W748028", surplusLicence: "W748028"},
{state:"Illinois", pcLicense:"6962080", surplusLicence: "6962080"},
{state:"Indiana", pcLicense:"3675449", surplusLicence: "3675449"},
{state:"Maryland", pcLicense:"3001831420", surplusLicence: "3001831420"},
{state:"Massachusetts", pcLicense:"6962080", surplusLicence: "6962080"},
{state:"Michigan", pcLicense:"6962080", surplusLicence: "6962080"},
{state:"Minnesota", pcLicense:"40782830", surplusLicence: "40782830"},
{state:"Montana", pcLicense:"3001786608", surplusLicence: "3001786608"},
{state:"Nevada", pcLicense:"3673100", surplusLicence: "3673100"},
{state:"New Jersey", pcLicense:"3001834501", surplusLicence: "3001834501"},
{state:"New York", pcLicense:"PC-1664016", surplusLicence: "PC-1664016"},
{state:"North Carolina", pcLicense:"6962080", surplusLicence: "6962080"},
{state:"North Dakota", pcLicense:"6962080", surplusLicence: "6962080"},
{state:"Ohio", pcLicense:"1426047", surplusLicence: "1426047"},
{state:"Oregon ", pcLicense:"6962080", surplusLicence: "6962080"},
{state:"Pennsylvania", pcLicense:"1058509", surplusLicence: "1058509"},
{state:"South Carolina", pcLicense:"6962080", surplusLicence: "6962080"},
{state:"South Dakota", pcLicense:"40596388", surplusLicence: "40596388"},
{state:"Tennessee", pcLicense:"3001831533", surplusLicence: "3001831533"},
{state:"Texas", pcLicense:"2673185", surplusLicence: "2773015"},
{state:"Washington", pcLicense:"1150495", surplusLicence: "1150495"},
{state:"Wisconsin", pcLicense:"6962080", surplusLicence: "6962080"},
]

