import React, { useEffect } from 'react';
import { Form, FormLabel, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { GreenButton, LoadApp } from './components/GreenButton';
import { FunnelLayout } from './components/FunnelLayout';
import SuccessImg from "./assets/success.png"
import styled from "styled-components"
import { API } from "aws-amplify";

const Styles = styled.div`

color: var(--mindaro-theme-primary-text);
margin-top: 150px;

.header {
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    margin-bottom: 12px;
    margin-top: 35px;
}

.sub-header {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    margin-bottom: 12px;
    max-width: 400px;
}

.img {
    width: 117px;
    height: 117px;
}

@media (max-width : 567px) {
    margin-top: 50px;   
}
`

export const Success = () => {
    const logCustomer = async () => {
        let app = LoadApp();
        API.post("applicantwebapprestapi", "/monday", {body: { board: 'customers', app }});
    }

    const navigate = useNavigate();
    useEffect(()=>{
        setTimeout(logCustomer, 100);
    });

    const onSubmit = async (event) => {
        event.preventDefault();
        navigate('/create_account');
    }

    return (
        <FunnelLayout HideBackButton={true} HideAmber={true}>
            <Styles>
            <Image className="img" src={SuccessImg}/>
            <Form onSubmit={onSubmit} autoComplete="off">
                <FormLabel className="header">
                    Success!
                </FormLabel>
                <FormLabel className="sub-header">
                    Your quote has been bound, and your payment recieved.
                    You will recieve your policy documents via email.
                    Please complete account setup for easy access.
                </FormLabel>
                <div className="buttonwrapper">
                    <GreenButton style={{minWidth:"250px"}}>Complete account setup</GreenButton>
                </div>
            </Form>
            </Styles>
        </FunnelLayout>
    )
}
