export const naics_codes =
[
{c:111110,d: "Soybean farming, field and seed production"},
{c:111120,d: "Canola farming, field and seed production"},
{c:111120,d: "Flaxseed farming, field and seed production"},
{c:111120,d: "Mustard seed farming, field and seed production"},
{c:111120,d: "Oilseed farming (except soybean), field and seed production"},
{c:111120,d: "Rapeseed farming, field and seed production"},
{c:111120,d: "Safflower farming, field and seed production"},
{c:111120,d: "Sesame farming, field and seed production"},
{c:111120,d: "Sunflower farming, field and seed production"},
{c:111130,d: "Bean farming, dry, field and seed production"},
{c:111130,d: "Cowpea farming, dry, field and seed production"},
{c:111130,d: "Garbanzo farming, dry, field and seed production"},
{c:111130,d: "Lentil farming, dry, field and seed production"},
{c:111130,d: "Lima bean farming, dry, field and seed production"},
{c:111130,d: "Pea farming, dry, field and seed production"},
{c:111140,d: "Wheat farming, field and seed production"},
{c:111150,d: "Corn farming (except sweet corn), field and seed production"},
{c:111150,d: "Popcorn farming, field and seed production"},
{c:111160,d: "Rice (except wild rice) farming, field and seed production"},
{c:111191,d: "Oilseed and grain combination farming, field and seed production"},
{c:111199,d: "Barley farming, field and seed production"},
{c:111199,d: "Broomcorn farming"},
{c:111199,d: "Buckwheat farming"},
{c:111199,d: "Milo farming, field and seed production"},
{c:111199,d: "Oat farming, field and seed production"},
{c:111199,d: "Rye farming, field and seed production"},
{c:111199,d: "Sorghum farming, field and seed production"},
{c:111199,d: "Wild rice farming, field and seed production"},
{c:111211,d: "Potato farming, field and seed potato production"},
{c:111211,d: "Sweet potato farming, field and seed potato production"},
{c:111211,d: "Vegetable and melon farming, potato dominant crop, field and seed production"},
{c:111211,d: "Vegetable and potato farming, potato dominant crop, field and seed potato production"},
{c:111211,d: "Yam farming, field and seed production"},
{c:111219,d: "Acorn squash farming, field, bedding plant and seed production"},
{c:111219,d: "Artichoke farming, field, bedding plant and seed production"},
{c:111219,d: "Asparagus farming, field, bedding plant and seed production"},
{c:111219,d: "Bean (except dry) farming, field and seed production"},
{c:111219,d: "Beet farming (except sugar beets), field, bedding plant and seed production"},
{c:111219,d: "Broccoli farming, field, bedding plant and seed production"},
{c:111219,d: "Brussel sprout farming, field, bedding plant and seed production"},
{c:111219,d: "Butternut squash farming, field, bedding plant and seed production"},
{c:111219,d: "Cabbage farming, field, bedding plant and seed production"},
{c:111219,d: "Cantaloupe farming, field, bedding plant and seed production"},
{c:111219,d: "Carrot farming, field, bedding plant and seed production"},
{c:111219,d: "Casaba melon farming, field, bedding plant and seed production"},
{c:111219,d: "Cassava farming, field and seed production"},
{c:111219,d: "Cauliflower farming, field, bedding plant and seed production"},
{c:111219,d: "Celery farming, field, bedding plant and seed production"},
{c:111219,d: "Chinese pea farming, bedding plant and seed production"},
{c:111219,d: "Chive farming, field, bedding plant and seed production"},
{c:111219,d: "Collard farming, field, bedding plant and seed production"},
{c:111219,d: "Cowpea (except dry) farming, field and seed production"},
{c:111219,d: "Crenshaw melon farming, field, bedding plant and seed production"},
{c:111219,d: "Cucumber farming (except under cover), field, bedding plant and seed production"},
{c:111219,d: "Dill farming, field and seed production"},
{c:111219,d: "Eggplant farming (except under cover), field, bedding plant and seed production"},
{c:111219,d: "Endive farming (except under cover), field, bedding plant and seed production"},
{c:111219,d: "English pea farming (except under cover), field, bedding plant and seed production"},
{c:111219,d: "Escarole farming (except under cover), field, bedding plant and seed production"},
{c:111219,d: "Garlic farming (except under cover), field, bedding plant and seed production"},
{c:111219,d: "Gingerroot farming (except under cover), field, bedding plant and seed production"},
{c:111219,d: "Ginseng farming (except under cover), field, bedding plant and seed production"},
{c:111219,d: "Green bean farming, field and seed production"},
{c:111219,d: "Green cowpea farming, field and seed production"},
{c:111219,d: "Green lima bean farming, field and seed production"},
{c:111219,d: "Green pea farming, field and seed production"},
{c:111219,d: "Honeydew melon farming, field, bedding plant and seed production"},
{c:111219,d: "Kale farming, field, bedding plant and seed production"},
{c:111219,d: "Leek farming, field, bedding plant and seed production"},
{c:111219,d: "Lettuce farming, field, bedding plant and seed production"},
{c:111219,d: "Melon farming (e.g., cantaloupe, casaba, honeydew, watermelon), field, bedding plant and seed production"},
{c:111219,d: "Muskmelon farming, field, bedding plant and seed production"},
{c:111219,d: "Okra farming, field, bedding plant and seed production"},
{c:111219,d: "Onion farming, field, bedding plant and seed production"},
{c:111219,d: "Parsley farming, field, bedding plant and seed production"},
{c:111219,d: "Parsnip farming, field, bedding plant and seed production"},
{c:111219,d: "Pea (except dry) farming, field and seed production"},
{c:111219,d: "Pepper (e.g., bell, chili, green, hot, red, sweet) farming"},
{c:111219,d: "Pumpkin farming, field and seed production"},
{c:111219,d: "Radish farming, field and seed production"},
{c:111219,d: "Rhubarb farming, field and seed production"},
{c:111219,d: "Romaine lettuce farming, field and seed production"},
{c:111219,d: "Rutabaga farming, field and seed production"},
{c:111219,d: "Shallot farming, field and seed production"},
{c:111219,d: "Snap bean farming (i.e., bush and pole), field and seed production"},
{c:111219,d: "Snow pea farming, field and seed production"},
{c:111219,d: "Spaghetti squash farming, field, bedding plant and seed production"},
{c:111219,d: "Spinach farming, field, bedding plant and seed production"},
{c:111219,d: "Squash farming, field, bedding plant and seed production"},
{c:111219,d: "String bean farming, field and seed production"},
{c:111219,d: "Sweet corn farming, field and seed production"},
{c:111219,d: "Sweet pepper farming, field, bedding plant and seed production"},
{c:111219,d: "Taro farming, field and seed production"},
{c:111219,d: "Tomato farming (except under cover), field, bedding plant and seed production"},
{c:111219,d: "Truck farming, field, bedding plant and seed production"},
{c:111219,d: "Turnip farming, field, bedding plant and seed production"},
{c:111219,d: "Vegetable and melon farming, vegetable (except potato) and melon dominant crops, field, bedding plants and seed production"},
{c:111219,d: "Vegetable and potato farming, vegetable (except potato) dominant crops, field, bedding plants and seed production"},
{c:111219,d: "Watermelon farming, field, bedding plant and seed production"},
{c:111219,d: "Zucchini farming, field, bedding plant and seed production"},
{c:111310,d: "Orange groves"},
{c:111320,d: "Citrus groves (except orange)"},
{c:111320,d: "Grapefruit groves"},
{c:111320,d: "Lemon groves"},
{c:111320,d: "Lime groves"},
{c:111320,d: "Mandarin groves"},
{c:111320,d: "Tangelo groves"},
{c:111320,d: "Tangerine groves"},
{c:111331,d: "Apple orchards"},
{c:111332,d: "Grape farming without making wine"},
{c:111332,d: "Raisin farming"},
{c:111333,d: "Strawberry farming"},
{c:111334,d: "Berry (except strawberry) farming"},
{c:111334,d: "Blackberry farming"},
{c:111334,d: "Blueberry farming"},
{c:111334,d: "Boysenberry farming"},
{c:111334,d: "Cranberry farming"},
{c:111334,d: "Currant farming"},
{c:111334,d: "Dewberry farming"},
{c:111334,d: "Gooseberry farming"},
{c:111334,d: "Huckleberry farming"},
{c:111334,d: "Loganberry farming"},
{c:111334,d: "Raspberry farming"},
{c:111334,d: "Youngberry farming"},
{c:111335,d: "Almond farming"},
{c:111335,d: "Brazil nut farming"},
{c:111335,d: "Cashew farming"},
{c:111335,d: "Filbert farming"},
{c:111335,d: "Hazelnut farming"},
{c:111335,d: "Macadamia farming"},
{c:111335,d: "Pecan farming"},
{c:111335,d: "Pistachio farming"},
{c:111335,d: "Tree nut farming"},
{c:111335,d: "Walnut farming"},
{c:111336,d: "Fruit and tree nut combination farming"},
{c:111339,d: "Apricot farming"},
{c:111339,d: "Avocado farming"},
{c:111339,d: "Banana farming"},
{c:111339,d: "Cactus fruit farming"},
{c:111339,d: "Cherry farming"},
{c:111339,d: "Coconut tree farming"},
{c:111339,d: "Coffee farming"},
{c:111339,d: "Date farming"},
{c:111339,d: "Deciduous tree fruit (except apples, citrus) farming"},
{c:111339,d: "Fig farming"},
{c:111339,d: "Guava farming"},
{c:111339,d: "Kiwi fruit farming"},
{c:111339,d: "Mango farming"},
{c:111339,d: "Nectarine farming"},
{c:111339,d: "Noncitrus fruit farming"},
{c:111339,d: "Olive farming"},
{c:111339,d: "Papaya farming"},
{c:111339,d: "Passion fruit farming"},
{c:111339,d: "Peach farming"},
{c:111339,d: "Pear farming"},
{c:111339,d: "Persimmon farming"},
{c:111339,d: "Pineapple farming"},
{c:111339,d: "Plantain farming"},
{c:111339,d: "Plum farming"},
{c:111339,d: "Pomegranate farming"},
{c:111339,d: "Prickly pear farming"},
{c:111339,d: "Prune farming"},
{c:111339,d: "Quince farming"},
{c:111411,d: "Mushroom farming"},
{c:111411,d: "Mushroom spawn farming"},
{c:111411,d: "Shitake mushroom farming"},
{c:111419,d: "Alfalfa sprout farming, grown under cover"},
{c:111419,d: "Bean sprout farming,  grown under cover"},
{c:111419,d: "Cucumber farming, grown under cover"},
{c:111419,d: "Eggplant farming, grown under cover"},
{c:111419,d: "Endive farming, grown under cover"},
{c:111419,d: "English pea farming, grown under cover"},
{c:111419,d: "Escarole farming, grown under cover"},
{c:111419,d: "Fruit farming, grown under cover"},
{c:111419,d: "Garlic farming, grown under cover"},
{c:111419,d: "Gingerroot farming, grown under cover"},
{c:111419,d: "Ginseng farming, grown under cover"},
{c:111419,d: "Herb farming, grown under cover"},
{c:111419,d: "Hydroponic crop farming"},
{c:111419,d: "Marijuana, grown under cover"},
{c:111419,d: "Melon farming, grown under cover"},
{c:111419,d: "Rhubarb, grown under cover"},
{c:111419,d: "Spice farming, grown under cover"},
{c:111419,d: "Tomato farming, grown under cover"},
{c:111419,d: "Truffles farming, grown under cover"},
{c:111419,d: "Vegetable farming, grown under cover"},
{c:111421,d: "Azalea farming"},
{c:111421,d: "Christmas tree growing"},
{c:111421,d: "Corms farming"},
{c:111421,d: "Field nurseries (i.e., growing of flowers and shrubbery)"},
{c:111421,d: "Flower bulb growing"},
{c:111421,d: "Fruit stock (e.g., plants, seedlings, trees) growing"},
{c:111421,d: "Herbaceous perennial growing"},
{c:111421,d: "Nursery stock growing"},
{c:111421,d: "Nursery with tree production (except for reforestation)"},
{c:111421,d: "Preseeded mat farming"},
{c:111421,d: "Propagation material farming"},
{c:111421,d: "Rose bush growing"},
{c:111421,d: "Short rotation woody tree growing (i.e., growing and harvesting cycle ten years or less)"},
{c:111421,d: "Shrubbery farming"},
{c:111421,d: "Sod farming"},
{c:111421,d: "Tissue culture farming"},
{c:111421,d: "Tree crop farming (except forestry), short rotation growing and harvesting cycle"},
{c:111421,d: "Turf farming"},
{c:111422,d: "Bedding plant growing (except vegetable and melon bedding plants)"},
{c:111422,d: "Cultivated florist greens growing"},
{c:111422,d: "Cut flower growing"},
{c:111422,d: "Cut rose growing"},
{c:111422,d: "Cuttings farming"},
{c:111422,d: "Flower growing"},
{c:111422,d: "Flower seed production"},
{c:111422,d: "Foliage growing"},
{c:111422,d: "Hanging basket plant growing"},
{c:111422,d: "House plant growing"},
{c:111422,d: "Ornamental plant growing"},
{c:111422,d: "Plant, ornamental, growing"},
{c:111422,d: "Plant, potted flower and foliage, growing"},
{c:111422,d: "Plug (i.e., floriculture products) growing"},
{c:111910,d: "Tobacco farming, field and seed production"},
{c:111920,d: "Cotton farming, field and seed production"},
{c:111920,d: "Cottonseed farming"},
{c:111930,d: "Cane farming, sugar, field production"},
{c:111930,d: "Sugarcane farming, field production"},
{c:111940,d: "Alfalfa hay farming"},
{c:111940,d: "Clover hay farming"},
{c:111940,d: "Grass hay farming"},
{c:111940,d: "Hay farming (e.g., alfalfa hay, clover hay, grass hay)"},
{c:111940,d: "Mixed hay farming"},
{c:111991,d: "Sugar beet farming"},
{c:111992,d: "Peanut farming"},
{c:111998,d: "Agave farming"},
{c:111998,d: "Alfalfa seed farming"},
{c:111998,d: "Aloe farming"},
{c:111998,d: "Amaranth farming"},
{c:111998,d: "Bentgrass seed farming"},
{c:111998,d: "Bluegrass-Kentucky seed farming"},
{c:111998,d: "Chicory farming"},
{c:111998,d: "Clover seed farming"},
{c:111998,d: "Crimson cloves seed farming"},
{c:111998,d: "Fescue seed farming"},
{c:111998,d: "General combination crop farming (except fruit and nut combinations, oilseed and grain, vegetable)"},
{c:111998,d: "Grass seed farming"},
{c:111998,d: "Guar farming"},
{c:111998,d: "Hay seed farming"},
{c:111998,d: "Herb farming, open field"},
{c:111998,d: "Hop farming"},
{c:111998,d: "Jojoba farming"},
{c:111998,d: "Kenaf farming"},
{c:111998,d: "Maple sap concentrating (i.e., producing pure maple syrup in the field)"},
{c:111998,d: "Maple sap gathering"},
{c:111998,d: "Maple syrup (i.e., maple sap reducing)"},
{c:111998,d: "Marijuana, grown in an open field"},
{c:111998,d: "Mint farming"},
{c:111998,d: "Orchard grass seed farming"},
{c:111998,d: "Ryegrass seed farming"},
{c:111998,d: "Sorghum sudan seed farming"},
{c:111998,d: "Spice farming"},
{c:111998,d: "Syrup, pure maple (i.e., maple syrup reducing)"},
{c:111998,d: "Tea farming"},
{c:112111,d: "Backgrounding, cattle"},
{c:112111,d: "Beef cattle ranching or farming"},
{c:112111,d: "Calf (e.g., feeder, stocker, veal) production"},
{c:112111,d: "Cattle conditioning operations"},
{c:112111,d: "Cattle farming or ranching"},
{c:112111,d: "Dairy heifer replacement production"},
{c:112111,d: "Feeder calf production"},
{c:112111,d: "Stocker calf production"},
{c:112111,d: "Veal calf production"},
{c:112112,d: "Beef cattle feedlots (except stockyards for transportation)"},
{c:112112,d: "Cattle feedlots (except stockyards for transportation)"},
{c:112112,d: "Fattening cattle"},
{c:112112,d: "Feed yards (except stockyards for transportation), cattle"},
{c:112112,d: "Feedlots (except stockyards for transportation), cattle"},
{c:112120,d: "Dairy cattle farming"},
{c:112120,d: "Milk production, dairy cattle"},
{c:112130,d: "Dual-purpose cattle ranching and farming"},
{c:112120,d: "Milking dairy cattle"},
{c:112210,d: "Farrow-to-finish operations"},
{c:112210,d: "Feeder pig farming"},
{c:112210,d: "Feedlots (except stockyards for transportation), hog"},
{c:112210,d: "Hog and pig (including breeding, farrowing, nursery, and finishing activities) farming"},
{c:112210,d: "Hog feedlots (except stockyards for transportation)"},
{c:112210,d: "Pig farming"},
{c:112210,d: "Swine farming"},
{c:112210,d: "Weaning pig operations"},
{c:112310,d: "Chicken egg production"},
{c:112310,d: "Chicken eggs (table, hatching) production"},
{c:112310,d: "Cull hen production"},
{c:112310,d: "Egg production, chicken"},
{c:112310,d: "Eggs, chicken (table, hatching) production"},
{c:112310,d: "Layer-type chicken production"},
{c:112310,d: "Started pullet production"},
{c:112320,d: "Broiler chicken production"},
{c:112320,d: "Chicken production (except egg laying)"},
{c:112320,d: "Cornish hen production"},
{c:112320,d: "Fryer chicken production"},
{c:112320,d: "Roaster chicken production"},
{c:112330,d: "Egg production, turkey"},
{c:112330,d: "Turkey egg production"},
{c:112330,d: "Turkey production"},
{c:112340,d: "Chicken hatcheries"},
{c:112340,d: "Egg hatcheries, poultry"},
{c:112340,d: "Hatcheries, poultry"},
{c:112340,d: "Poultry hatcheries"},
{c:112340,d: "Turkey hatcheries"},
{c:112390,d: "Chukar partridge production"},
{c:112390,d: "Duck production"},
{c:112390,d: "Emu production"},
{c:112390,d: "Geese production"},
{c:112390,d: "Hungarian partridge production"},
{c:112390,d: "Ostrich production"},
{c:112390,d: "Partridge production"},
{c:112390,d: "Pheasant production"},
{c:112390,d: "Quail production"},
{c:112390,d: "Ratite production"},
{c:112410,d: "Dairy sheep farming"},
{c:112410,d: "Feedlots (except stockyards for transportation), lamb"},
{c:112410,d: "Lamb feedlots (except stockyards for transportation)"},
{c:112410,d: "Milking dairy sheep"},
{c:112410,d: "Sheep farming (e.g., meat, milk, wool production)"},
{c:112420,d: "Angora goat farming"},
{c:112420,d: "Dairy goat farming"},
{c:112420,d: "Goat farming (e.g., meat, milk, mohair production)"},
{c:112420,d: "Milking dairy goat"},
{c:112420,d: "Mohair farming"},
{c:112511,d: "Baitfish production, farm raising"},
{c:112511,d: "Catfish production, farm raising"},
{c:112511,d: "Finfish production, farm raising"},
{c:112511,d: "Finfish, hatcheries"},
{c:112511,d: "Fish farms, finfish"},
{c:112511,d: "Goldfish production, farm raising"},
{c:112511,d: "Hatcheries, finfish"},
{c:112511,d: "Hybrid striped bass production"},
{c:112511,d: "Minnow production, farm raising"},
{c:112511,d: "Ornamental fish production, farm raising"},
{c:112511,d: "Tilapia production"},
{c:112511,d: "Tropical fish production, farm raising"},
{c:112511,d: "Trout production, farm raising"},
{c:112512,d: "Clam production, farm raising"},
{c:112512,d: "Crawfish production, farm raising"},
{c:112512,d: "Crustacean production, farm raising"},
{c:112512,d: "Cultured pearl production, farm raising"},
{c:112512,d: "Fish farms, shellfish"},
{c:112512,d: "Hatcheries, shellfish"},
{c:112512,d: "Mollusk production, farm raising"},
{c:112512,d: "Mussel production, farm raising"},
{c:112512,d: "Oyster production, farm raising"},
{c:112512,d: "Shellfish hatcheries"},
{c:112512,d: "Shrimp production, farm raising"},
{c:112519,d: "Algae farming"},
{c:112519,d: "Alligator production, farm raising"},
{c:112519,d: "Animal aquaculture (except finfish, shellfish)"},
{c:112519,d: "Frog production, farm raising"},
{c:112519,d: "Plant aquaculture"},
{c:112519,d: "Sea plant agriculture"},
{c:112519,d: "Seaweed farming"},
{c:112519,d: "Turtle production, farm raising"},
{c:112910,d: "Bee pollen collection"},
{c:112910,d: "Bee production (i.e., apiculture)"},
{c:112910,d: "Beeswax production"},
{c:112910,d: "Honey bee production"},
{c:112910,d: "Propolis production, bees"},
{c:112910,d: "Queen bee production"},
{c:112910,d: "Royal jelly production, bees"},
{c:112920,d: "Burro production"},
{c:112920,d: "Donkey production"},
{c:112920,d: "Horse (including thoroughbreds) production"},
{c:112920,d: "Mule production"},
{c:112920,d: "Pony production"},
{c:112930,d: "Chinchilla production"},
{c:112930,d: "Fox production"},
{c:112930,d: "Fur-bearing animal production"},
{c:112930,d: "Mink production"},
{c:112930,d: "Rabbit production"},
{c:112990,d: "Alpaca production"},
{c:112990,d: "Aviaries (i.e., raising birds for sale)"},
{c:112990,d: "Bird production (e.g., canaries, love birds, parakeets, parrots)"},
{c:112990,d: "Bison production"},
{c:112990,d: "Breeding of pets (e.g., birds, cats, dogs)"},
{c:112990,d: "Buffalo production"},
{c:112990,d: "Cat production"},
{c:112990,d: "Combination livestock farming (except dairy, poultry)"},
{c:112990,d: "Companion animals production (e.g., cats, dogs, parakeets, parrots)"},
{c:112990,d: "Cricket production"},
{c:112990,d: "Deer production"},
{c:112990,d: "Dog production"},
{c:112990,d: "Earthworm hatcheries"},
{c:112990,d: "Elk production"},
{c:112990,d: "General combination animal farming"},
{c:112990,d: "Kennels, breeding and raising stock for sale"},
{c:112990,d: "Laboratory animal production (e.g., guinea pigs, mice, rats)"},
{c:112990,d: "Llama production"},
{c:112990,d: "Raising swans, peacocks, flamingos, or other adornment birds"},
{c:112990,d: "Rattlesnake production"},
{c:112990,d: "Worm production"},
{c:113110,d: "Timber tract operations"},
{c:113210,d: "Aromatic wood gathering"},
{c:113210,d: "Balsam needles gathering"},
{c:113210,d: "Bark gathering"},
{c:113210,d: "Cherry gum, gathering"},
{c:113210,d: "Chestnut gum, gathering"},
{c:113210,d: "Forest nurseries for reforestation, growing trees"},
{c:113210,d: "Gathering of forest products (e.g., barks, gums, needles, seeds)"},
{c:113210,d: "Gathering, extracting, and selling tree seeds"},
{c:113210,d: "Ginseng gathering"},
{c:113210,d: "Gum (i.e., forest product) gathering"},
{c:113210,d: "Harvesting berries or nuts from native and non-cultivated plants"},
{c:113210,d: "Hemlock gum gathering"},
{c:113210,d: "Huckleberry greens, gathering of"},
{c:113210,d: "Moss gathering"},
{c:113210,d: "Nurseries for reforestation growing trees"},
{c:113210,d: "Pine gum extracting"},
{c:113210,d: "Spanish moss gathering"},
{c:113210,d: "Sphagnum moss gathering"},
{c:113210,d: "Spruce gum gathering"},
{c:113210,d: "Teaberries gathering"},
{c:113210,d: "Tree seed extracting"},
{c:113210,d: "Tree seed gathering"},
{c:113210,d: "Tree seed growing for reforestation"},
{c:113310,d: "Cutting and transporting timber"},
{c:113310,d: "Cutting timber"},
{c:113310,d: "Log harvesting"},
{c:113310,d: "Logging"},
{c:113310,d: "Pulpwood logging camps"},
{c:113310,d: "Rails, rough wood, manufacturing"},
{c:113310,d: "Stump removing in the field"},
{c:113310,d: "Timber piling"},
{c:113310,d: "Timber pole cutting"},
{c:113310,d: "Tree chipping in the field"},
{c:113310,d: "Wood chipping in the field"},
{c:114111,d: "Anchovy fishing"},
{c:114111,d: "Bluefish fishing"},
{c:114111,d: "Cod catching"},
{c:114111,d: "Cod fishing"},
{c:114111,d: "Croaker fishing"},
{c:114111,d: "Dolphin fishing"},
{c:114111,d: "Eel fishing"},
{c:114111,d: "Finfish fishing (e.g., flounder, salmon, trout)"},
{c:114111,d: "Fisheries, finfish"},
{c:114111,d: "Flounder fishing"},
{c:114111,d: "Grouper fishing"},
{c:114111,d: "Haddock fishing"},
{c:114111,d: "Hake fishing"},
{c:114111,d: "Halibut fishing"},
{c:114111,d: "Herring fishing"},
{c:114111,d: "Lingcod fishing"},
{c:114111,d: "Mackerel fishing"},
{c:114111,d: "Mahimahi fishing"},
{c:114111,d: "Menhaden fishing"},
{c:114111,d: "Mullet fishing"},
{c:114111,d: "Perch fishing"},
{c:114111,d: "Pilchard fishing"},
{c:114111,d: "Pollock fishing"},
{c:114111,d: "Porgy fishing"},
{c:114111,d: "Ray fishing"},
{c:114111,d: "Rockfish fishing"},
{c:114111,d: "Sablefish fishing"},
{c:114111,d: "Salmon fishing"},
{c:114111,d: "Sea bass fishing"},
{c:114111,d: "Sea herring fishing"},
{c:114111,d: "Sea trout fishing"},
{c:114111,d: "Shark fishing"},
{c:114111,d: "Snapper fishing"},
{c:114111,d: "Swordfish fishing"},
{c:114111,d: "Tilefish fishing"},
{c:114111,d: "Trout fishing"},
{c:114111,d: "Tuna fishing"},
{c:114111,d: "Whiting fishing"},
{c:114112,d: "Clam digging"},
{c:114112,d: "Crabbing"},
{c:114112,d: "Crayfish fishing"},
{c:114112,d: "Fisheries, shellfish"},
{c:114112,d: "Lobster fishing"},
{c:114112,d: "Mussel fishing"},
{c:114112,d: "Octopus fishing"},
{c:114112,d: "Oyster dredging"},
{c:114112,d: "Scallop fishing"},
{c:114112,d: "Sea urchin fishing"},
{c:114112,d: "Shellfish fishing (e.g., clam, crab, oyster, shrimp)"},
{c:114112,d: "Shrimp fishing"},
{c:114112,d: "Squid fishing"},
{c:114119,d: "Frog fishing"},
{c:114119,d: "Seaweed gathering"},
{c:114119,d: "Sponge gathering"},
{c:114119,d: "Terrapin fishing"},
{c:114119,d: "Turtle fishing"},
{c:114210,d: "Animal trapping, commercial"},
{c:114210,d: "Fishing preserves"},
{c:114210,d: "Game preserves, commercial"},
{c:114210,d: "Game propagation"},
{c:114210,d: "Game retreats"},
{c:114210,d: "Hunting preserves"},
{c:115111,d: "Cotton ginning"},
{c:115111,d: "Ginning cotton"},
{c:115112,d: "Aerial crop dusting or spraying (i.e., using specialized or dedicated aircraft)"},
{c:115112,d: "Bracing of orchard trees and vines"},
{c:115112,d: "Chemical treatment of soil for crops"},
{c:115112,d: "Citrus grove cultivation services"},
{c:115112,d: "Crop dusting"},
{c:115112,d: "Crop spraying"},
{c:115112,d: "Cultivation services"},
{c:115112,d: "Detasseling corn"},
{c:115112,d: "Disease control for crops"},
{c:115112,d: "Dusting crops"},
{c:115112,d: "Entomological service, agricultural"},
{c:115112,d: "Fertilizer application for crops"},
{c:115112,d: "Hoeing"},
{c:115112,d: "Insect control for crops"},
{c:115112,d: "Orchard cultivation services (e.g., bracing, planting, pruning, removal, spraying, surgery)"},
{c:115112,d: "Pest control services, agricultural"},
{c:115112,d: "Planting crops"},
{c:115112,d: "Plowing"},
{c:115112,d: "Pollinating"},
{c:115112,d: "Pruning of orchard trees and vines"},
{c:115112,d: "Seed bed preparing"},
{c:115112,d: "Seeding crops"},
{c:115112,d: "Spraying crops"},
{c:115112,d: "Spreading lime for crops"},
{c:115112,d: "Surgery on trees and vines"},
{c:115112,d: "Thinning of crops, mechanical and chemical"},
{c:115112,d: "Transplanting services"},
{c:115112,d: "Vineyard cultivation services"},
{c:115112,d: "Weed control services for crops"},
{c:115113,d: "Berries, machine harvesting"},
{c:115113,d: "Chopping and silo filling"},
{c:115113,d: "Combining, agricultural"},
{c:115113,d: "Cotton, machine harvesting"},
{c:115113,d: "Fruit, machine harvesting"},
{c:115113,d: "Grain, machine harvesting"},
{c:115113,d: "Hay mowing, raking, baling, and chopping"},
{c:115113,d: "Nuts, machine harvesting"},
{c:115113,d: "Peanut, machine harvesting"},
{c:115113,d: "Sugar beets, machine harvesting"},
{c:115113,d: "Sugarcane, machine harvesting"},
{c:115113,d: "Threshing service"},
{c:115113,d: "Vegetables, machine harvesting"},
{c:115114,d: "Almond hulling and shelling"},
{c:115114,d: "Banana ripening"},
{c:115114,d: "Bean cleaning"},
{c:115114,d: "Corn drying"},
{c:115114,d: "Corn shelling"},
{c:115114,d: "Crop cleaning"},
{c:115114,d: "Decorticating flax"},
{c:115114,d: "Delinting cottonseed"},
{c:115114,d: "Filbert hulling and shelling"},
{c:115114,d: "Fruit precooling"},
{c:115114,d: "Fruit sorting, grading, and packing"},
{c:115114,d: "Fruit, sun drying"},
{c:115114,d: "Fruit, vacuum cooling"},
{c:115114,d: "Fumigating grain"},
{c:115114,d: "Grain cleaning"},
{c:115114,d: "Grain drying"},
{c:115114,d: "Grain fumigation"},
{c:115114,d: "Grain grinding (except custom grinding for animal feed)"},
{c:115114,d: "Hulling and shelling of nuts"},
{c:115114,d: "Irradiation of fruits and vegetables"},
{c:115114,d: "Nut hulling and shelling"},
{c:115114,d: "Packaging fresh or farm-dried fruits and vegetables"},
{c:115114,d: "Packing fruits and vegetables"},
{c:115114,d: "Peanut shelling"},
{c:115114,d: "Pecan hulling and shelling"},
{c:115114,d: "Potato curing"},
{c:115114,d: "Retting flax"},
{c:115114,d: "Rice drying"},
{c:115114,d: "Seed cleaning"},
{c:115114,d: "Seed processing, postharvest for propagation"},
{c:115114,d: "Sorting, grading, cleaning, and packing of fruits and vegetables"},
{c:115114,d: "Sun drying of dates, prunes, raisins, and olives"},
{c:115114,d: "Sun drying of fruits and vegetables"},
{c:115114,d: "Sun drying of tomatoes"},
{c:115114,d: "Sweet potato curing"},
{c:115114,d: "Tobacco grading"},
{c:115114,d: "Vegetable precooling"},
{c:115114,d: "Vegetable sorting, grading, and packing"},
{c:115114,d: "Vegetable sun drying"},
{c:115114,d: "Vegetable vacuum cooling"},
{c:115114,d: "Walnut hulling and shelling"},
{c:115114,d: "Waxing, fruits or vegetables"},
{c:115115,d: "Agriculture production or harvesting crews"},
{c:115115,d: "Crew leaders, farm labor"},
{c:115115,d: "Farm labor contractors"},
{c:115115,d: "Labor contractors, farm"},
{c:115116,d: "Farm management services"},
{c:115116,d: "Management services, farm"},
{c:115210,d: "Animal semen banks"},
{c:115210,d: "Artificial insemination services for livestock"},
{c:115210,d: "Artificial insemination services for pets"},
{c:115210,d: "Boarding horses (except racehorses)"},
{c:115210,d: "Branding"},
{c:115210,d: "Breeding, animal, services"},
{c:115210,d: "Bull testing stations"},
{c:115210,d: "Cattle spraying"},
{c:115210,d: "Cleaning poultry houses"},
{c:115210,d: "Corralling, drovers"},
{c:115210,d: "Dairy herd improvement associations"},
{c:115210,d: "Equine boarding"},
{c:115210,d: "Farriers"},
{c:115210,d: "Hoof trimming"},
{c:115210,d: "Horses (except racehorses), boarding"},
{c:115210,d: "Horses, training (except racehorses)"},
{c:115210,d: "Horseshoeing"},
{c:115210,d: "Livestock breeding services (except consulting)"},
{c:115210,d: "Livestock spraying"},
{c:115210,d: "Milk testing for butterfat and milk solids"},
{c:115210,d: "Pedigree (i.e., livestock, pets, poultry) record services"},
{c:115210,d: "Poultry catching services"},
{c:115210,d: "Reproductive flushing services for animals"},
{c:115210,d: "Semen collection"},
{c:115210,d: "Sheep dipping and shearing"},
{c:115210,d: "Showing of cattle, hogs, sheep, goats, and poultry"},
{c:115210,d: "Stud services"},
{c:115210,d: "Training horses (except racehorses)"},
{c:115210,d: "Vaccinating livestock (except by veterinarians)"},
{c:115310,d: "Aerial forest mulching or seeding"},
{c:115310,d: "Cruising timber"},
{c:115310,d: "Estimating timber"},
{c:115310,d: "Fire prevention, forest"},
{c:115310,d: "Firefighting, forest"},
{c:115310,d: "Forest management plans preparation"},
{c:115310,d: "Forest thinning"},
{c:115310,d: "Forestry services"},
{c:115310,d: "Pest control services, forestry"},
{c:115310,d: "Reforestation"},
{c:115310,d: "Timber valuation"},
{c:211120,d: "Crude petroleum from oil sand"},
{c:211120,d: "Crude petroleum from oil shale"},
{c:211120,d: "Crude petroleum lease condensate production"},
{c:211120,d: "Crude petroleum production"},
{c:211120,d: "Kerogen processing"},
{c:211120,d: "Offshore crude petroleum production"},
{c:211120,d: "Oil field development for own account"},
{c:211120,d: "Oil field exploration for own account"},
{c:211120,d: "Oil shale mining and/or beneficiating"},
{c:211120,d: "Operating crude petroleum field gathering lines, except on a contract basis"},
{c:211120,d: "Petroleum, crude, production (i.e., extraction)"},
{c:211120,d: "Shale, oil, mining and/or beneficiating"},
{c:211120,d: "Stripper oil well production"},
{c:211120,d: "Tar sands mining"},
{c:211130,d: "Butane, natural, mining"},
{c:211130,d: "Casing-head butane and propane production"},
{c:211130,d: "Coal gasification at mine site"},
{c:211130,d: "Coal liquefaction at mine site"},
{c:211130,d: "Coal pyrolysis"},
{c:211130,d: "Condensate, cycle, natural gas production"},
{c:211130,d: "Cycle condensate production"},
{c:211130,d: "Ethane recovered from oil and gas field gases"},
{c:211130,d: "Fractionating natural gas liquids"},
{c:211130,d: "Gas field development for own account"},
{c:211130,d: "Gas field exploration for own account"},
{c:211130,d: "Gas, natural liquefied petroleum, extraction"},
{c:211130,d: "Gas, natural, extraction"},
{c:211130,d: "Gas, natural, liquids, extraction"},
{c:211130,d: "Gas, residue, extraction"},
{c:211130,d: "Gases, petroleum, liquefied, extraction"},
{c:211130,d: "Gasoline, natural, production"},
{c:211130,d: "Isobutane recovered from oil and gas field gases"},
{c:211130,d: "Isopentane recovered from oil and gas field gases"},
{c:211130,d: "Liquefied petroleum gases (LPG), natural"},
{c:211130,d: "Liquid hydrocarbons recovered from oil and gas field gases"},
{c:211130,d: "Liquids, natural gas (e.g., ethane, isobutane, natural gasoline, propane) recovered from oil and gas field gases"},
{c:211130,d: "Natural gas liquid lease condensate production"},
{c:211130,d: "Natural gas liquids (e.g., ethane, isobutane, natural gasoline, propane) recovered from oil and gas field gases"},
{c:211130,d: "Natural gas production"},
{c:211130,d: "Natural gas, offshore production"},
{c:211130,d: "Natural gasoline recovered from oil and gas field gases"},
{c:211130,d: "Offshore natural gas production"},
{c:211130,d: "Oil line drip, natural gas liquid"},
{c:211130,d: "Operating natural gas liquid field gathering lines, except on a contract basis"},
{c:211130,d: "Petroleum gases, liquefied, recovering from oil and gas field gases"},
{c:211130,d: "Propane recovered from oil and gas field gases"},
{c:211130,d: "Residue gas production"},
{c:211130,d: "Stripper gas well production"},
{c:211130,d: "Sulfur recovered from natural gas"},
{c:212111,d: "Bituminous coal and lignite surface mine site development for own account"},
{c:212111,d: "Bituminous coal cleaning plants"},
{c:212111,d: "Bituminous coal crushing"},
{c:212111,d: "Bituminous coal or lignite beneficiating (e.g., cleaning, crushing, screening, washing)"},
{c:212111,d: "Bituminous coal screening plants"},
{c:212111,d: "Bituminous coal stripping (except on a contract, fee, or other basis)"},
{c:212111,d: "Bituminous coal surface mining and/or beneficiating"},
{c:212111,d: "Bituminous coal washeries"},
{c:212111,d: "Brown coal mining and/or beneficiating"},
{c:212111,d: "Cleaning plants, bituminous coal"},
{c:212111,d: "Coal beneficiating plants, bituminous or lignite (surface or underground)"},
{c:212111,d: "Coal, bituminous, beneficiating"},
{c:212111,d: "Coal, brown, mining and/or beneficiating"},
{c:212111,d: "Crushing plants, bituminous coal"},
{c:212111,d: "Culm bank recovery, bituminous coal or lignite (except on a contract basis)"},
{c:212111,d: "Lignite surface mining and/or beneficiating"},
{c:212111,d: "Screening plants, bituminous coal or lignite"},
{c:212111,d: "Semianthracite surface mining and/or beneficiating"},
{c:212111,d: "Semibituminous coal surface mining and/or beneficiating"},
{c:212111,d: "Strip mining, bituminous coal or lignite, on own account"},
{c:212111,d: "Strip mining, lignite, on own account"},
{c:212111,d: "Subbituminous coal surface mining and/or beneficiating"},
{c:212111,d: "Tipple operation, bituminous coal mining and/or beneficiating"},
{c:212111,d: "Washeries, bituminous coal or lignite"},
{c:212112,d: "Bituminous coal underground mine site development for own account"},
{c:212112,d: "Bituminous coal underground mining or mining and beneficiating"},
{c:212112,d: "Coal, bituminous, underground mining or mining and beneficiating"},
{c:212112,d: "Semianthracite underground mining or mining and beneficiating"},
{c:212112,d: "Semibituminous coal underground mining or mining and beneficiating"},
{c:212112,d: "Subbituminous coal underground mining or mining and beneficiating"},
{c:212113,d: "Anthracite beneficiating (e.g., crushing, screening, washing, cleaning, sizing)"},
{c:212113,d: "Anthracite mining and/or beneficiating"},
{c:212113,d: "Breakers, anthracite mining and/or beneficiating"},
{c:212113,d: "Cleaning plants, anthracite coal"},
{c:212113,d: "Coal beneficiating plants, anthracite"},
{c:212113,d: "Coal, anthracite, mining and/or beneficiating"},
{c:212113,d: "Culm bank recovery, anthracite (except on a contract basis)"},
{c:212113,d: "Hard coal (i.e., anthracite) surface mining"},
{c:212113,d: "Hard coal (i.e., anthracite) underground mining"},
{c:212113,d: "Pennsylvania anthracite mining and/or beneficiating"},
{c:212113,d: "Preparation plants, anthracite"},
{c:212113,d: "Screening plants, anthracite"},
{c:212113,d: "Strip mining, anthracite, on own account"},
{c:212113,d: "Washeries, anthracite"},
{c:212210,d: "Agglomerates, iron ore, beneficiating"},
{c:212210,d: "Briquets, iron, mining and/or beneficiating"},
{c:212210,d: "Brown ore mining and/or beneficiating"},
{c:212210,d: "Hematite mining and/or beneficiating"},
{c:212210,d: "Iron ore (e.g., hematite, magnetite, siderite, taconite) mining and/or beneficiating"},
{c:212210,d: "Iron ore agglomerates mining and/or beneficiating"},
{c:212210,d: "Iron ore beneficiating plants (e.g., agglomeration, sintering)"},
{c:212210,d: "Iron ore mine site development for own account"},
{c:212210,d: "Iron ore, blocked, mining and/or beneficiating"},
{c:212210,d: "Limonite mining and/or beneficiating"},
{c:212210,d: "Manganiferous ores valued for iron content, mining and/or beneficiating"},
{c:212210,d: "Siderite mining and/or beneficiating"},
{c:212210,d: "Sintered iron ore produced at the mine"},
{c:212210,d: "Taconite concentrates or agglomerates beneficiating"},
{c:212210,d: "Taconite ores mining and/or beneficiating"},
{c:212221,d: "Bullion, gold, produced at the mine"},
{c:212221,d: "Calaverite mining and/or beneficiating"},
{c:212221,d: "Gold lode mining and/or beneficiating"},
{c:212221,d: "Gold ore mine site development for own account"},
{c:212221,d: "Gold ore mining and/or beneficiating plants"},
{c:212221,d: "Gold ores, concentrates, bullion, and/or precipitates mining and/or beneficiating"},
{c:212221,d: "Gold placer mining and/or beneficiating"},
{c:212221,d: "Lode gold mining and/or beneficiating"},
{c:212221,d: "Placer gold mining and/or beneficiating"},
{c:212221,d: "Sylvanite mining and/or beneficiating"},
{c:212221,d: "Telluride (gold) mining and/or beneficiating"},
{c:212222,d: "Bullion, silver, produced at the mine"},
{c:212222,d: "Placer silver mining and/or beneficiating"},
{c:212222,d: "Silver ores mining and/or beneficiating"},
{c:212230,d: "Blende (zinc) mining and/or beneficiating"},
{c:212230,d: "Calamine mining and/or beneficiating"},
{c:212230,d: "Cerussite mining and/or beneficiating"},
{c:212230,d: "Chalcocite mining and/or beneficiating"},
{c:212230,d: "Chalcopyrite mining and/or beneficiating"},
{c:212230,d: "Copper beneficiating plants"},
{c:212230,d: "Copper ore concentrates recovery"},
{c:212230,d: "Copper ore mine site development for own account"},
{c:212230,d: "Copper ores mining and/or beneficiating"},
{c:212230,d: "Copper-water precipitates"},
{c:212230,d: "Cuprite mining and/or beneficiating"},
{c:212230,d: "Galena mining and/or beneficiating"},
{c:212230,d: "Lead ore mine site development for own account"},
{c:212230,d: "Lead ore mining and/or beneficiating"},
{c:212230,d: "Lead-zinc ore mining and/or beneficiating"},
{c:212230,d: "Nickel concentrates recovery"},
{c:212230,d: "Nickel ore beneficiating plants"},
{c:212230,d: "Nickel ore mine site development for own account"},
{c:212230,d: "Nickel ores mining and/or beneficiating"},
{c:212230,d: "Smithsonite mining and/or beneficiating"},
{c:212230,d: "Sphalerite mining and/or beneficiating"},
{c:212230,d: "Willemite mining and/or beneficiating"},
{c:212230,d: "Zinc ore mine site development for own account"},
{c:212230,d: "Zinc ores mining and/or beneficiating"},
{c:212230,d: "Zinc-blende (sphalerite) mining and/or beneficiating"},
{c:212230,d: "Zincite mining and/or beneficiating"},
{c:212291,d: "Carnotite mining and/or beneficiating"},
{c:212291,d: "Leaching of uranium, radium, or vanadium ores"},
{c:212291,d: "Pitchblende mining and/or beneficiating"},
{c:212291,d: "Radium ores mining and/or beneficiating"},
{c:212291,d: "Roscoelite (vanadium hydromica) mining and/or beneficiating"},
{c:212291,d: "Tyuyamunite mining and/or beneficiating"},
{c:212291,d: "Uraninite (pitchblende) mining and/or beneficiating"},
{c:212291,d: "Uranium ores mining and/or beneficiating"},
{c:212291,d: "Uranium-radium-vanadium ore mine site development for own account"},
{c:212291,d: "Uranium-radium-vanadium ores mining and/or beneficiating"},
{c:212291,d: "Vanadium ores mining and/or beneficiating"},
{c:212299,d: "Antimony concentrates mining and/or beneficiating"},
{c:212299,d: "Antimony ores mining and/or beneficiating"},
{c:212299,d: "Bastnaesite mining and/or beneficiating"},
{c:212299,d: "Bauxite mining and/or beneficiating"},
{c:212299,d: "Beryl mining and/or beneficiating"},
{c:212299,d: "Beryllium concentrates beneficiating"},
{c:212299,d: "Beryllium ores mining and/or beneficiating"},
{c:212299,d: "Cerium concentrates mining and/or beneficiating"},
{c:212299,d: "Cerium ores mining and/or beneficiating"},
{c:212299,d: "Chromite mining and/or beneficiating"},
{c:212299,d: "Chromium concentrates beneficiating"},
{c:212299,d: "Chromium ore mining and/or beneficiating"},
{c:212299,d: "Cinnabar mining and/or beneficiating"},
{c:212299,d: "Cobalt concentrates beneficiating"},
{c:212299,d: "Cobalt ores mining and/or beneficiating"},
{c:212299,d: "Columbite mining and/or beneficiating"},
{c:212299,d: "Columbium ores mining and/or beneficiating"},
{c:212299,d: "Ferberite ores and concentrates mining and/or beneficiating"},
{c:212299,d: "Ferroalloy ores (except vanadium) (e.g., chromium, columbium, molybdenum, tungsten) mining and/or beneficiating"},
{c:212299,d: "Hafnium mining and/or beneficiating"},
{c:212299,d: "Huebnerite mining and/or beneficiating"},
{c:212299,d: "Ilmenite ores mining and/or beneficiating"},
{c:212299,d: "Iridium mining and/or beneficiating"},
{c:212299,d: "Manganese concentrates beneficiating"},
{c:212299,d: "Manganese ores mining and/or beneficiating"},
{c:212299,d: "Manganiferousares ores (not valued for iron content) mining and/or beneficiating"},
{c:212299,d: "Manganite mining and/or beneficiating"},
{c:212299,d: "Mercury (quicksilver) mining and/or beneficiating"},
{c:212299,d: "Mercury ores mining and/or beneficiating"},
{c:212299,d: "Microlite mining and/or beneficiating"},
{c:212299,d: "Molybdenite mining and/or beneficiating"},
{c:212299,d: "Molybdenum ores mining and/or beneficiating"},
{c:212299,d: "Molybdite mining and/or beneficiating"},
{c:212299,d: "Monazite mining and/or beneficiating"},
{c:212299,d: "Osmium mining and/or beneficiating"},
{c:212299,d: "Palladium mining and/or beneficiating"},
{c:212299,d: "Platinum mining and/or beneficiating"},
{c:212299,d: "Psilomelane mining and/or beneficiating"},
{c:212299,d: "Pyrolusite mining and/or beneficiating"},
{c:212299,d: "Quicksilver ores and metal mining and/or beneficiating"},
{c:212299,d: "Rare earth metal concentrates beneficiating"},
{c:212299,d: "Rare earth metal ores mining and/or beneficiating"},
{c:212299,d: "Rhodium mining and/or beneficiating"},
{c:212299,d: "Rhodochrosite mining and/or beneficiating"},
{c:212299,d: "Ruthenium ore mining and/or beneficiating"},
{c:212299,d: "Rutile mining and/or beneficiating"},
{c:212299,d: "Scheelite mining and/or beneficiating"},
{c:212299,d: "Tantalite mining and/or beneficiating"},
{c:212299,d: "Tantalum ores mining and/or beneficiating"},
{c:212299,d: "Thallium mining and/or beneficiating"},
{c:212299,d: "Thorite mining and/or beneficiating"},
{c:212299,d: "Thorium ores mining and/or beneficiating"},
{c:212299,d: "Tin metal concentrates beneficiating"},
{c:212299,d: "Tin metal ores mining and/or beneficiating"},
{c:212299,d: "Titaniferous-magnetite ores, valued chiefly for titanium content, mining and/or beneficiating"},
{c:212299,d: "Titanium concentrates beneficiating"},
{c:212299,d: "Titanium ores mining and/or beneficiating"},
{c:212299,d: "Tungsten concentrates beneficiating"},
{c:212299,d: "Tungsten ores mining and/or beneficiating"},
{c:212299,d: "Wolframite mining and/or beneficiating"},
{c:212299,d: "Wulfenite mining and/or beneficiating"},
{c:212299,d: "Zirconium concentrates beneficiating"},
{c:212299,d: "Zirconium ores mining and/or beneficiating"},
{c:212311,d: "Argillite mining or quarrying"},
{c:212311,d: "Basalt mining or quarrying"},
{c:212311,d: "Bluestone mining or quarrying"},
{c:212311,d: "Calcareous tufa mining or quarrying"},
{c:212311,d: "Diabase mining or quarrying"},
{c:212311,d: "Dimension stone mining or quarrying"},
{c:212311,d: "Diorite mining or quarrying"},
{c:212311,d: "Dolomite mining or quarrying"},
{c:212311,d: "Dolomitic marble mining or quarrying"},
{c:212311,d: "Flagstone mining or quarrying"},
{c:212311,d: "Gabbro mining or quarrying"},
{c:212311,d: "Gneiss mining or quarrying"},
{c:212311,d: "Granite mining or quarrying"},
{c:212311,d: "Greenstone mining or quarrying"},
{c:212311,d: "Limestone mining or quarrying"},
{c:212311,d: "Marble mining or quarrying"},
{c:212311,d: "Mica schist mining or quarrying"},
{c:212311,d: "Onyx marble mining or quarrying"},
{c:212311,d: "Quartzite dimension stone mining or quarrying"},
{c:212311,d: "Sandstone mining or quarrying"},
{c:212311,d: "Schist, mica, mining or quarrying"},
{c:212311,d: "Serpentine mining or quarrying"},
{c:212311,d: "Slate mining or quarrying"},
{c:212311,d: "Stone, dimension, mining or quarrying"},
{c:212311,d: "Syenite (except nepheline) mining or quarrying"},
{c:212311,d: "Trap rock mining or quarrying"},
{c:212311,d: "Travertine mining or quarrying"},
{c:212311,d: "Tufa, calcareous, mining or quarrying"},
{c:212311,d: "Verde' antique mining or quarrying"},
{c:212311,d: "Volcanic rock, mining or quarrying"},
{c:212312,d: "Agricultural limestone mining and/or beneficiating"},
{c:212312,d: "Calcareous tufa crushed and broken stone mining and/or beneficiating"},
{c:212312,d: "Cement rock crushed and broken stone mining and/or beneficiating"},
{c:212312,d: "Chalk crushed and broken stone mining and/or beneficiating"},
{c:212312,d: "Chalk, ground or otherwise treated, mining and/or beneficiating"},
{c:212312,d: "Dolomite crushed and broken stone mining and/or beneficiating"},
{c:212312,d: "Lime rock, ground, mining and/or beneficiating"},
{c:212312,d: "Limestone (except bituminous) crushed and broken stone mining and/or beneficiating"},
{c:212312,d: "Limestone beneficiating plants (e.g., grinding or pulverizing)"},
{c:212312,d: "Marl crushed and broken stone mining and/or beneficiating"},
{c:212312,d: "Riprap, limestone, mining or quarrying"},
{c:212312,d: "Riprap, limestone, preparation plants"},
{c:212312,d: "Travertine crushed and broken stone mining and/or beneficiating"},
{c:212312,d: "Tufa, calcareous, crushed and broken stone, mining and/or beneficiating"},
{c:212312,d: "Whiting crushed and broken stone, mining and/or beneficiating"},
{c:212313,d: "Diorite crushed and broken stone mining and/or beneficiating"},
{c:212313,d: "Gneiss crushed and broken stone mining and/or beneficiating"},
{c:212313,d: "Granite beneficiating plants (e.g., grinding or pulverizing)"},
{c:212313,d: "Granite crushed and broken stone mining and/or beneficiating"},
{c:212313,d: "Riprap, granite, mining or quarrying"},
{c:212313,d: "Riprap, granite, preparation plants"},
{c:212313,d: "Syenite (except nepheline) crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Basalt crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Bituminous limestone mining and/or beneficiating"},
{c:212319,d: "Bituminous sandstone mining and/or beneficiating"},
{c:212319,d: "Boulder crushed and broken mining and/or beneficiating"},
{c:212319,d: "Diabase crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Dolomitic marble crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Gabbro crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Ganister crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Granules, slate, mining and/or beneficiating"},
{c:212319,d: "Grits crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Limestone, bituminous, mining and/or beneficiating"},
{c:212319,d: "Marble crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Mica schist crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Onyx marble crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Quartzite crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Riprap (except granite, limestone) preparation plants"},
{c:212319,d: "Riprap (except limestone and granite) mining or quarrying"},
{c:212319,d: "Sandstone crushed and broken stone mining"},
{c:212319,d: "Schist, mica, crushed and broken stone, mining and/or beneficiating"},
{c:212319,d: "Serpentine crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Slate crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Stone (except limestone and granite) beneficiating plants (e.g., grinding)"},
{c:212319,d: "Stone, crushed and broken (except granite or limestone), mining and/or beneficiating"},
{c:212319,d: "Trap rock crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Verde' antique crushed and broken stone mining and/or beneficiating"},
{c:212319,d: "Volcanic rock crushed and broken stone mining and/or beneficiating"},
{c:212321,d: "Common sand quarrying and/or beneficiating"},
{c:212321,d: "Construction sand and gravel beneficiating (e.g., grinding, screening, washing)"},
{c:212321,d: "Construction sand or gravel dredging"},
{c:212321,d: "Gravel quarrying and/or beneficiating"},
{c:212321,d: "Pebbles (except grinding) mining and/or beneficiating"},
{c:212321,d: "Sand and gravel quarrying (i.e., construction grade) and/or beneficiating"},
{c:212321,d: "Sand, construction grade, quarrying and/or beneficiating"},
{c:212322,d: "Abrasive sand quarrying and/or beneficiating"},
{c:212322,d: "Blast sand quarrying and/or beneficiating"},
{c:212322,d: "Enamel sand quarrying and/or beneficiating"},
{c:212322,d: "Filtration sand quarrying and/or beneficiating"},
{c:212322,d: "Foundry sand quarrying and/or beneficiating"},
{c:212322,d: "Glass sand quarrying and/or beneficiating"},
{c:212322,d: "Grinding sand quarrying and/or beneficiating"},
{c:212322,d: "Industrial sand beneficiating (e.g., screening, washing)"},
{c:212322,d: "Industrial sand sandpits and dredging"},
{c:212322,d: "Molding sand quarrying and/or beneficiating"},
{c:212322,d: "Sand, blast, quarrying and/or beneficiating"},
{c:212322,d: "Sand, industrial (e.g., engine, filtration, glass grinding, proppant), quarrying and/or beneficiating"},
{c:212322,d: "Silica mining and/or beneficiating"},
{c:212322,d: "Silica sand quarrying and/or beneficiating"},
{c:212324,d: "Ball clay mining and/or beneficiating"},
{c:212324,d: "China clay mining and/or beneficiating"},
{c:212324,d: "Clay, ball, mining and/or beneficiating"},
{c:212324,d: "Clay, natural, mining and/or beneficiating"},
{c:212324,d: "Kaolin mining and/or beneficiating"},
{c:212324,d: "Paper clay mining and/or beneficiating"},
{c:212324,d: "Rubber clay mining and/or beneficiating"},
{c:212324,d: "Slip clay mining and/or beneficiating"},
{c:212325,d: "Andalusite mining and/or beneficiating"},
{c:212325,d: "Aplite mining and/or beneficiating"},
{c:212325,d: "Bentonite mining and/or beneficiating"},
{c:212325,d: "Bleaching clay mining and/or beneficiating"},
{c:212325,d: "Brucite mining and/or beneficiating"},
{c:212325,d: "Burley mining and/or beneficiating"},
{c:212325,d: "Clay (except kaolin, ball) mining and/or beneficiating"},
{c:212325,d: "Clay bleaching"},
{c:212325,d: "Clay, ceramic and refractory minerals, mining and/or beneficiating"},
{c:212325,d: "Clay, fire, mining and/or beneficiating"},
{c:212325,d: "Common clay mining and/or beneficiating"},
{c:212325,d: "Common shale mining and/or beneficiating"},
{c:212325,d: "Cornwall stone mining and/or beneficiating"},
{c:212325,d: "Cyanite mining and/or beneficiating"},
{c:212325,d: "Diaspore mining and/or beneficiating"},
{c:212325,d: "Dumortierite mining and/or beneficiating"},
{c:212325,d: "Earth, fuller's (e.g., all natural bleaching clays), mining and/or beneficiating"},
{c:212325,d: "Feldspar mining and/or beneficiating"},
{c:212325,d: "Fire clay mining and/or beneficiating"},
{c:212325,d: "Flint clay mining and/or beneficiating"},
{c:212325,d: "Fuller's earth mining and/or beneficiating"},
{c:212325,d: "Kyanite mining and/or beneficiating"},
{c:212325,d: "Laterite mining and/or beneficiating"},
{c:212325,d: "Magnesite mining and/or beneficiating"},
{c:212325,d: "Nepheline syenite mining and/or beneficiating"},
{c:212325,d: "Olivine, non-gem, mining and/or beneficiating"},
{c:212325,d: "Pegmatite, feldspar, mining and/or beneficiating"},
{c:212325,d: "Pinite mining and/or beneficiating"},
{c:212325,d: "Plastic fire clay mining and/or beneficiating"},
{c:212325,d: "Refractory minerals mining and/or beneficiating"},
{c:212325,d: "Shale (except oil shale) mining and/or beneficiating"},
{c:212325,d: "Sillimanite mining and/or beneficiating"},
{c:212325,d: "Stoneware clay mining and/or beneficiating"},
{c:212325,d: "Syenite, nepheline, mining and/or beneficiating"},
{c:212325,d: "Topaz, non-gem, mining and/or beneficiating"},
{c:212391,d: "Alum, natural, mining and/or beneficiating"},
{c:212391,d: "Borate, natural, mining and/or beneficiating"},
{c:212391,d: "Borax, crude, ground or pulverized, mining and/or beneficiating"},
{c:212391,d: "Boron compounds prepared at beneficiating plants"},
{c:212391,d: "Boron mineral mining and/or beneficiating"},
{c:212391,d: "Colemanite mining and/or beneficiating"},
{c:212391,d: "Glauber's salt mining and/or beneficiating"},
{c:212391,d: "Kernite mining and/or beneficiating"},
{c:212391,d: "Muriate of potash, mining"},
{c:212391,d: "Potash mining and/or beneficiating"},
{c:212391,d: "Potassium bromide, natural, mining and/or beneficiating"},
{c:212391,d: "Potassium chloride mining and/or beneficiating"},
{c:212391,d: "Potassium compounds prepared at beneficiating plants"},
{c:212391,d: "Potassium compounds, natural, mining and/or beneficiating"},
{c:212391,d: "Potassium salts, natural, mining and/or beneficiating"},
{c:212391,d: "Probertite mining and/or quarrying"},
{c:212391,d: "Salines (except common salt) mining and/or beneficiating"},
{c:212391,d: "Soda ash mining and/or beneficiating"},
{c:212391,d: "Soda ash, natural, mining and/or beneficiating"},
{c:212391,d: "Sodium borates, natural, mining and/or beneficiating"},
{c:212391,d: "Sodium carbonates, natural, mining and/or beneficiating"},
{c:212391,d: "Sodium compounds prepared at beneficiating plants"},
{c:212391,d: "Sodium compounds, natural (except common salt), mining and/or beneficiating"},
{c:212391,d: "Sodium sulfate, natural, mining and/or beneficiating"},
{c:212391,d: "Sulfate, sodium, mining and/or beneficiating"},
{c:212391,d: "Trona mining and/or beneficiating"},
{c:212391,d: "Ulexite mining and/or beneficiating"},
{c:212392,d: "Apatite mining and/or beneficiating"},
{c:212392,d: "Calcined phosphate rock mining and/or beneficiating"},
{c:212392,d: "Dried phosphate rock mining and/or beneficiating"},
{c:212392,d: "Phosphate rock mining and/or beneficiating"},
{c:212392,d: "Sintered phosphate rock mining and/or beneficiating"},
{c:212393,d: "Alunite mining and/or beneficiating"},
{c:212393,d: "Amblygonite mining and/or beneficiating"},
{c:212393,d: "Arsenic mineral mining and/or beneficiating"},
{c:212393,d: "Barite mining and/or beneficiating"},
{c:212393,d: "Barium ores mining and/or beneficiating"},
{c:212393,d: "Barytes mining and/or beneficiating"},
{c:212393,d: "Brimstone mining and/or beneficiating"},
{c:212393,d: "Celestite mining and/or beneficiating"},
{c:212393,d: "Fertilizer minerals, natural, mining and/or beneficiating"},
{c:212393,d: "Fluorite mining and/or beneficiating"},
{c:212393,d: "Fluorspar mining and/or beneficiating"},
{c:212393,d: "Guano mining and/or beneficiating"},
{c:212393,d: "Lepidolite mining and/or beneficiating"},
{c:212393,d: "Lithium mineral mining and/or beneficiating"},
{c:212393,d: "Marcasite mining and/or beneficiating"},
{c:212393,d: "Mineral pigments, natural, mining and/or beneficiating"},
{c:212393,d: "Ocher mining and/or beneficiating"},
{c:212393,d: "Pigments, natural, mineral, mining and/or beneficiating"},
{c:212393,d: "Pyrite concentrates mining and/or beneficiating"},
{c:212393,d: "Pyrite mining and/or beneficiating"},
{c:212393,d: "Pyrrhotite mining and/or beneficiating"},
{c:212393,d: "Rock salt mining and/or beneficiating"},
{c:212393,d: "Salt, common, mining and/or beneficiating"},
{c:212393,d: "Salt, rock, mining and/or beneficiating"},
{c:212393,d: "Sienna mining and/or beneficiating"},
{c:212393,d: "Sodium chloride, rock salt, mining and/or beneficiating"},
{c:212393,d: "Spodumene mining and/or beneficiating"},
{c:212393,d: "Strontianite mining and/or beneficiating"},
{c:212393,d: "Strontium mineral mining and/or beneficiating"},
{c:212393,d: "Sulfur mining and/or beneficiating"},
{c:212393,d: "Sulfur, native, mining and/or beneficiating"},
{c:212393,d: "Umber mining and/or beneficiating"},
{c:212399,d: "Abrasive stones (e.g., emery, grindstones, hones, pumice) mining and/or beneficiating"},
{c:212399,d: "Abrasives, natural, mining and/or beneficiating"},
{c:212399,d: "Agate mining and/or beneficiating"},
{c:212399,d: "Alabaster mining and/or beneficiating"},
{c:212399,d: "Amethyst mining and/or beneficiating"},
{c:212399,d: "Asbestos mining and/or beneficiating"},
{c:212399,d: "Ash, volcanic, mining and/or beneficiating"},
{c:212399,d: "Asphalt rock mining and/or beneficiating"},
{c:212399,d: "Asphalt, native, mining and/or beneficiating"},
{c:212399,d: "Burrstones, natural, mining and/or beneficiating"},
{c:212399,d: "Calcite mining and/or beneficiating"},
{c:212399,d: "Catlinite mining and/or beneficiating"},
{c:212399,d: "Chasers mining and/or beneficiating"},
{c:212399,d: "Corundum mining and/or beneficiating"},
{c:212399,d: "Cryolite mining and/or beneficiating"},
{c:212399,d: "Diamonds, industrial, mining and/or beneficiating"},
{c:212399,d: "Diatomaceous earth mining and/or beneficitating"},
{c:212399,d: "Diatomite mining and/or beneficiating"},
{c:212399,d: "Dragstones mining and/or beneficiating"},
{c:212399,d: "Earth, diatomaceous, mining and/or beneficiating"},
{c:212399,d: "Emery mining and/or beneficiating"},
{c:212399,d: "Fill dirt pits mining and/or beneficiating"},
{c:212399,d: "Garnet mining and/or beneficiating"},
{c:212399,d: "Gem stone (e.g., amethyst, garnet, agate, ruby, sapphire, jade) mining and/or beneficiating"},
{c:212399,d: "Gilsonite mining and/or beneficiating"},
{c:212399,d: "Grahamite mining and/or beneficiating"},
{c:212399,d: "Graphite mining and/or beneficiating"},
{c:212399,d: "Greensand mining and/or beneficiating"},
{c:212399,d: "Grinding pebbles mining and/or beneficiating"},
{c:212399,d: "Grindstones mining and/or beneficiating"},
{c:212399,d: "Gypsite mining and/or beneficiating"},
{c:212399,d: "Gypsum mining and/or beneficiating"},
{c:212399,d: "Hones mining and/or beneficiating"},
{c:212399,d: "Humus, peat, mining and/or beneficiating"},
{c:212399,d: "Iceland spar (i.e., optical grade calcite), mining and/or beneficiating"},
{c:212399,d: "Jade mining and/or beneficiating"},
{c:212399,d: "Meerschaum mining and/or beneficiating"},
{c:212399,d: "Mica mining and/or beneficiating"},
{c:212399,d: "Millstones mining and/or beneficiating"},
{c:212399,d: "Muscovite mining and/or beneficiating"},
{c:212399,d: "Native asphalt mining and/or beneficiating"},
{c:212399,d: "Natural abrasives (e.g., emery, grindstones, hones, pumice) (except sand) mining and/or beneficiating"},
{c:212399,d: "Oilstones mining and/or beneficiating"},
{c:212399,d: "Optical grade calcite mining and/or beneficiating"},
{c:212399,d: "Ozokerite mining and/or beneficiating"},
{c:212399,d: "Pavers mining and/or beneficiating"},
{c:212399,d: "Peat grinding"},
{c:212399,d: "Peat humus mining and/or beneficiating"},
{c:212399,d: "Peat mining and/or beneficiating"},
{c:212399,d: "Pebbles grinding"},
{c:212399,d: "Perlite mining and/or beneficiating"},
{c:212399,d: "Phlogopite mining and/or beneficiating"},
{c:212399,d: "Pipestones mining and/or beneficiating"},
{c:212399,d: "Pozzolana mining and/or beneficiating"},
{c:212399,d: "Precious stones mining and/or beneficiating"},
{c:212399,d: "Pulpstones, natural, mining and/or beneficiating"},
{c:212399,d: "Pumice mining and/or beneficiating"},
{c:212399,d: "Pumicite mining and/or beneficiating"},
{c:212399,d: "Pyrophyllite mining and/or beneficiating"},
{c:212399,d: "Quartz crystal, pure, mining and/or beneficiating"},
{c:212399,d: "Reed peat mining and/or beneficiating"},
{c:212399,d: "Rubbing stones mining and/or beneficiating"},
{c:212399,d: "Ruby mining and/or beneficiating"},
{c:212399,d: "Sapphire mining and/or beneficiating"},
{c:212399,d: "Scoria mining and/or beneficiating"},
{c:212399,d: "Screening peat"},
{c:212399,d: "Scythestones mining and/or beneficiating"},
{c:212399,d: "Sedge peat mining and/or beneficiating"},
{c:212399,d: "Selenite mining and/or beneficiating"},
{c:212399,d: "Semiprecious stones mining and/or beneficiating"},
{c:212399,d: "Shell mining and/or beneficiating"},
{c:212399,d: "Shredding peat mining and/or beneficiating"},
{c:212399,d: "Soapstone mining and/or beneficiating"},
{c:212399,d: "Spar, iceland, mining and/or beneficiating"},
{c:212399,d: "Staurolite mining and/or beneficiating"},
{c:212399,d: "Steatite mining and/or beneficiating"},
{c:212399,d: "Stones, abrasive (e.g., emery, grindstones, hones, pumice), mining and/or beneficiating"},
{c:212399,d: "Talc mining and/or beneficiating"},
{c:212399,d: "Tripoli mining and/or beneficiating"},
{c:212399,d: "Turquoise mining and/or beneficiating"},
{c:212399,d: "Vermiculite mining and/or beneficiating"},
{c:212399,d: "Volcanic ash mining and/or beneficiating"},
{c:212399,d: "Whetstones mining and/or beneficiating"},
{c:212399,d: "Wollastonite mining and/or beneficiating"},
{c:212399,d: "Wurtzilite mining and/or beneficiating"},
{c:213111,d: "Directional drilling of oil and gas wells on a contract basis"},
{c:213111,d: "Drilling directional oil and gas field wells on a contract basis"},
{c:213111,d: "Drilling for gas on a contract basis"},
{c:213111,d: "Drilling for oil on a contract basis"},
{c:213111,d: "Drilling gas and oil field wells on a contract basis"},
{c:213111,d: "Drilling oil and gas field service wells on a contract basis"},
{c:213111,d: "Drilling water intake wells, oil and gas field on a contract basis"},
{c:213111,d: "Gas well drilling on a contract basis"},
{c:213111,d: "Oil and gas well drilling services (redrilling, spudding, tailing) on a contract basis"},
{c:213111,d: "Oil well drilling on a contract basis"},
{c:213111,d: "Reconditioning oil and gas field wells on a contract basis"},
{c:213111,d: "Reworking oil and gas wells on a contract basis"},
{c:213111,d: "Service well drilling on a contract basis"},
{c:213111,d: "Spudding in oil and gas wells on a contract basis"},
{c:213111,d: "Tailing in oil and gas field wells on a contract basis"},
{c:213111,d: "Water intake well drilling, oil and gas field on a contract basis"},
{c:213111,d: "Well drilling (i.e., oil, gas, water intake wells) on a contract basis"},
{c:213111,d: "Workover of oil and gas wells on a contract basis"},
{c:213112,d: "Acidizing oil and gas field wells on a contract basis"},
{c:213112,d: "Building oil and gas well foundations on a contract basis"},
{c:213112,d: "Building, erecting, repairing, and dismantling oil and gas field rigs and derricks on a contract basis"},
{c:213112,d: "Cementing oil and gas well casings on a contract basis"},
{c:213112,d: "Chemically treating oil and gas wells (e.g., acidizing, bailing, swabbing) on a contract basis"},
{c:213112,d: "Cleaning oil and gas field lease tanks on a contract basis"},
{c:213112,d: "Cleaning out (e.g., bailing out, steam cleaning, swabbing) wells on a contract basis"},
{c:213112,d: "Cleaning wells on a contract basis"},
{c:213112,d: "Cleaning, repairing, and dismantling oil and gas field lease tanks on a contract basis"},
{c:213112,d: "Contract services (except site preparation and related construction contractor activities) for oil and gas fields"},
{c:213112,d: "Core cutting in oil and gas wells, on a contract basis"},
{c:213112,d: "Core drilling, exploration services, oil and gas field"},
{c:213112,d: "Cutting cores in oil and gas wells on a contract basis"},
{c:213112,d: "Derrick building, repairing, and dismantling at oil and gas fields on a contract basis"},
{c:213112,d: "Dismantling of oil well rigs on a contract basis"},
{c:213112,d: "Drilling rat holes and mouse holes at oil and gas fields on a contract basis"},
{c:213112,d: "Drilling shot holes at oil and gas fields on a contract basis"},
{c:213112,d: "Drilling site preparation at oil and gas fields on a contract basis"},
{c:213112,d: "Drill-stem testing in oil, gas, dry, and service well drilling on a contract basis"},
{c:213112,d: "Excavating mud pits, slush pits, and cellars at oil and gas fields on a contract basis"},
{c:213112,d: "Exploration services for oil and gas (except geophysical surveying and mapping) on a contract basis"},
{c:213112,d: "Fishing for tools at oil and gas fields on a contract basis"},
{c:213112,d: "Gas well rig building, repairing, and dismantling on a contract basis"},
{c:213112,d: "Gas, compressing natural, in the field on a contract basis"},
{c:213112,d: "Geological exploration (except surveying) for oil and gas on a contract basis"},
{c:213112,d: "Geophysical exploration (except surveying) for oil and gas on a contract basis"},
{c:213112,d: "Hard banding oil and gas field service on a contract basis"},
{c:213112,d: "Hot oil treating of oil field tanks on a contract basis"},
{c:213112,d: "Hot shot service on a contract basis"},
{c:213112,d: "Hydraulic fracturing wells on a contract basis"},
{c:213112,d: "Impounding and storing salt water in connection with petroleum production"},
{c:213112,d: "Installing production equipment at the oil or gas field on a contract basis"},
{c:213112,d: "Lease tank cleaning and repairing on a contract basis"},
{c:213112,d: "Logging wells on a contract basis"},
{c:213112,d: "Mouse hole and rat hole drilling at oil and gas fields on a contract basis"},
{c:213112,d: "Mud service for oil field drilling on a contract basis"},
{c:213112,d: "Oil and gas field services (except contract drilling, site preparation and related construction contractor activities) on a contract basis"},
{c:213112,d: "Oil field exploration (except surveying) on a contract basis"},
{c:213112,d: "Oil sampling services on a contract basis"},
{c:213112,d: "Oil well logging on a contract basis"},
{c:213112,d: "Oil well rig building, repairing, and dismantling, on a contract basis"},
{c:213112,d: "Operating condensate gasoline field gathering lines on a contract basis"},
{c:213112,d: "Paraffin services, oil and gas field, on a contract basis"},
{c:213112,d: "Perforating oil and gas well casings on a contract basis"},
{c:213112,d: "Pipe testing services, oil and gas field, on a contract basis"},
{c:213112,d: "Plugging and abandoning wells on a contract basis"},
{c:213112,d: "Preparation of oil and gas field drilling sites (except site preparation and related construction contractor activities) on a contract basis"},
{c:213112,d: "Pulling oil and gas field casings, tubes, or rods on a contract basis"},
{c:213112,d: "Pumping oil and gas wells on a contract basis"},
{c:213112,d: "Rathole and mousehole drilling at oil and gas fields on a contract basis"},
{c:213112,d: "Rig skidding, oil and gas field, on a contract basis"},
{c:213112,d: "Roustabout mining services, on a contract basis"},
{c:213112,d: "Salt water disposal systems, oil and gas field, on a contract basis"},
{c:213112,d: "Sandblasting pipelines on lease, oil and gas field on a contract basis"},
{c:213112,d: "Seismograph exploration (except surveying) for oil and gas on a contract basis"},
{c:213112,d: "Servicing oil and gas wells on a contract basis"},
{c:213112,d: "Shot hole drilling, oil and gas field, on a contract basis"},
{c:213112,d: "Skidding of rigs, oil and gas field, on a contract basis"},
{c:213112,d: "Slush pits and cellars, excavation of, on a contract basis"},
{c:213112,d: "Steam cleaning oil and gas wells on a contract basis"},
{c:213112,d: "Stratigraphic drilling, oil and gas field exploration on a contract basis"},
{c:213112,d: "Surveying (except seismographic) oil or gas wells on a contract basis"},
{c:213112,d: "Swabbing oil or gas wells on a contract basis"},
{c:213112,d: "Well casing running, cutting and pulling, oil and gas field on a contract basis"},
{c:213112,d: "Well logging, oil and gas field, on a contract basis"},
{c:213112,d: "Well plugging, oil and gas field, on a contract basis"},
{c:213112,d: "Well pumping, oil and gas field, on a contract basis"},
{c:213112,d: "Well servicing, oil and gas field, on a contract basis"},
{c:213112,d: "Well surveying, oil and gas field, on a contract basis"},
{c:213112,d: "Wireline services, oil and gas field, on a contract basis"},
{c:213113,d: "Anthracite mine tunneling on a contract basis"},
{c:213113,d: "Anthracite mining services (except site preparation and related construction contractor activities) on a contract basis"},
{c:213113,d: "Auger coal mining services (except site preparation and related construction contractor activities) on a contract basis"},
{c:213113,d: "Bituminous coal mining services (except site preparation and related construction contractor activities) on a contract basis"},
{c:213113,d: "Bituminous coal or lignite surface mine site development (except site preparation and related construction contractor activities) on a contract basis"},
{c:213113,d: "Bituminous coal stripping service on a contract basis"},
{c:213113,d: "Bituminous or lignite auger mining service on a contract basis"},
{c:213113,d: "Blasting services, coal mining, on a contract basis"},
{c:213113,d: "Coal mining services (except site preparation and related construction contractor activities)"},
{c:213113,d: "Coal mining support services (tunneling, blasting, training, overburden removal)(except site preparation and related construction contractor activities)"},
{c:213113,d: "Culm bank recovery, anthracite, on a contract basis"},
{c:213113,d: "Culm bank recovery, coal, on a contract basis"},
{c:213113,d: "Draining or pumping coal mines on a contract basis"},
{c:213113,d: "Drilling services for coal mining on a contract basis"},
{c:213113,d: "Exploration services for coal (except geophysical surveying and mapping) on a contract basis"},
{c:213113,d: "Lignite mining services (except site preparation and related construction contractor activities) on a contract basis"},
{c:213113,d: "Mine shaft sinking services for coal mining on a contract basis"},
{c:213113,d: "Mine tunneling services for coal mining on a contract basis"},
{c:213113,d: "Overburden removal for coal mining on a contract basis"},
{c:213113,d: "Prospect and test drilling services for coal mining on contract basis"},
{c:213113,d: "Pumping or draining coal mines on a contract basis"},
{c:213113,d: "Removal of overburden for coal mining on a contract basis"},
{c:213113,d: "Shaft sinking for coal mines on a contract basis"},
{c:213113,d: "Sinking shafts for coal mining on a contract basis"},
{c:213113,d: "Stripping overburden services for coal mining on a contract basis"},
{c:213113,d: "Tunneling services for coal mining on a contract basis"},
{c:213114,d: "Blasting services, metal mining, on a contract basis"},
{c:213114,d: "Boring test holes for metal mining on a contract basis"},
{c:213114,d: "Draining or pumping of metal mines on a contract basis"},
{c:213114,d: "Drilling services for metal mining on a contract basis"},
{c:213114,d: "Exploration services for metal (except geophysical surveying and mapping) on a contract basis"},
{c:213114,d: "Metal mining support services (shaft sinking, tunneling, blasting) (except site preparation and related construction contractor activities)"},
{c:213114,d: "Mine development (except site preparation and related construction contractor activities) for metal mining on a contract basis"},
{c:213114,d: "Mine shaft sinking services for metal mining on a contract basis"},
{c:213114,d: "Mine tunneling services for metal mining on a contract basis"},
{c:213114,d: "Overburden removal for metal mining on a contract basis"},
{c:213114,d: "Prospect and test drilling services for metal mining on contract basis"},
{c:213114,d: "Pumping or draining metal mines on a contract basis"},
{c:213114,d: "Removal of overburden for metal mining on a contract basis"},
{c:213114,d: "Shaft sinking for metal mines on a contract basis"},
{c:213114,d: "Sinking shafts for metal mining on a contract basis"},
{c:213114,d: "Stripping overburden services for metal mining on a contract basis"},
{c:213114,d: "Test drilling for metal mining on a contract basis"},
{c:213115,d: "Blasting services, nonmetallic minerals mining (except fuels) on a contract basis"},
{c:213115,d: "Boring test holes for nonmetallic minerals mining (except fuels) on a contract basis"},
{c:213115,d: "Draining or pumping of nonmetallic mineral mines (except fuels) on a contract basis"},
{c:213115,d: "Drilling services for nonmetallic mineral (except fuels) mining on a contract basis"},
{c:213115,d: "Exploration services for nonmetallic minerals (except geophysical surveying and mapping) on a contract basis"},
{c:213115,d: "Mine development for nonmetallic minerals mining (except fuels) on a contract basis"},
{c:213115,d: "Mine shaft sinking services for nonmetallic minerals (except fuels) on a contract basis"},
{c:213115,d: "Mine tunneling services for nonmetallic minerals (except fuels) on a contract basis"},
{c:213115,d: "Nonmetallic minerals mining support services (e.g., blasting, shaft sinking, tunneling) (except site preparation and related construction contractor activities) on a contract basis"},
{c:213115,d: "Overburden removal for nonmetallic minerals mining (except fuels) on a contract basis"},
{c:213115,d: "Prospect and test drilling services for nonmetallic mineral mining (except fuels) on a contract basis"},
{c:213115,d: "Pumping or draining nonmetallic mineral mines (except fuel) on a contract basis"},
{c:213115,d: "Removal of overburden for nonmetallic minerals mining (except fuels) on a contract basis"},
{c:213115,d: "Stripping overburden services for nonmetallic minerals mining (except fuels) on a contract basis"},
{c:213115,d: "Test drilling for nonmetallic minerals mining (except fuel) on a contract basis"},
{c:221111,d: "Electric power generation, hydroelectric"},
{c:221111,d: "Hydroelectric power generation"},
{c:221111,d: "Power generation, hydroelectric"},
{c:221112,d: "Electric power generation, fossil fuel (e.g., coal, oil, gas)"},
{c:221112,d: "Power generation, fossil fuel (e.g., coal, gas, oil), electric"},
{c:221113,d: "Electric power generation, nuclear"},
{c:221113,d: "Power generation, nuclear electric"},
{c:221114,d: "Electric power generation, solar"},
{c:221114,d: "Power generation, solar electric"},
{c:221114,d: "Solar farms"},
{c:221115,d: "Electric power generation, wind"},
{c:221115,d: "Power generation, wind electric"},
{c:221116,d: "Electric power generation, geothermal"},
{c:221116,d: "Geothermal electric power generation"},
{c:221116,d: "Power generation, geothermal"},
{c:221117,d: "Biomass electric power generation"},
{c:221117,d: "Electric power generation, biomass"},
{c:221117,d: "Power generation, biomass"},
{c:221118,d: "Electric power generation, tidal"},
{c:221118,d: "Power generation, tidal electric"},
{c:221121,d: "Electric power control"},
{c:221121,d: "Electric power transmission systems"},
{c:221121,d: "Transmission of electric power"},
{c:221122,d: "Distribution of electric power"},
{c:221122,d: "Electric power brokers"},
{c:221122,d: "Electric power distribution systems"},
{c:221210,d: "Blue gas, carbureted, distribution"},
{c:221210,d: "Coke oven gas distribution"},
{c:221210,d: "Distribution of manufactured gas"},
{c:221210,d: "Distribution of natural gas"},
{c:221210,d: "Gas, manufactured, distribution"},
{c:221210,d: "Gas, mixed natural and manufactured, distribution"},
{c:221210,d: "Gas, natural, distribution"},
{c:221210,d: "Liquefied petroleum gas (LPG) distribution through mains"},
{c:221210,d: "Manufactured gas distribution"},
{c:221210,d: "Natural gas brokers"},
{c:221210,d: "Natural gas distribution systems"},
{c:221210,d: "Natural gas distribution with transmission"},
{c:221210,d: "Natural gas marketers"},
{c:221310,d: "Canal, irrigation"},
{c:221310,d: "Filtration plant, water"},
{c:221310,d: "Impounding reservoirs, irrigation"},
{c:221310,d: "Irrigation system operation"},
{c:221310,d: "Water distribution (except irrigation)"},
{c:221310,d: "Water distribution for irrigation"},
{c:221310,d: "Water filtration plant operation"},
{c:221310,d: "Water supply systems"},
{c:221310,d: "Water treatment and distribution"},
{c:221310,d: "Water treatment plants"},
{c:221320,d: "Collection, treatment, and disposal of waste through a sewer system"},
{c:221320,d: "Sewage disposal plants"},
{c:221320,d: "Sewage treatment plants or facilities"},
{c:221320,d: "Sewer systems"},
{c:221320,d: "Waste collection, treatment, and disposal through a sewer system"},
{c:221330,d: "Air-conditioning supply"},
{c:221330,d: "Cooled air distribution"},
{c:221330,d: "Distribution of cooled air"},
{c:221330,d: "Distribution of heated air"},
{c:221330,d: "Distribution of steam heat"},
{c:221330,d: "Geothermal steam production"},
{c:221330,d: "Heat, steam, distribution"},
{c:221330,d: "Heated air distribution"},
{c:221330,d: "Heating steam (suppliers of heat) providers"},
{c:221330,d: "Steam heat distribution"},
{c:221330,d: "Steam heating systems (i.e., suppliers of heat)"},
{c:221330,d: "Steam production and distribution"},
{c:221330,d: "Steam supply systems, including geothermal"},
{c:236115,d: "Cabin construction general contractors"},
{c:236115,d: "Condominium, single-family, construction general contractors"},
{c:236115,d: "Construction management, single-family building"},
{c:236115,d: "Cottage construction general contractors"},
{c:236115,d: "Custom builders (except for-sale), single-family home"},
{c:236115,d: "Duplex (i.e., side-by-side) construction general contractors"},
{c:236115,d: "Home builders (except for-sale), single-family"},
{c:236115,d: "Housing, single-family, construction general contractors"},
{c:236115,d: "Log home construction general contractors"},
{c:236115,d: "Low income housing, single-family, construction general contractors"},
{c:236115,d: "Modular single-family housing assembled on site by general contractors"},
{c:236115,d: "Panelized single-family housing assembled on site by general contractors"},
{c:236115,d: "Precut single-family housing assembled on site by general contractors"},
{c:236115,d: "Premanufactured single-family housing assembled on site by general contractors"},
{c:236115,d: "Residential construction, single-family, general contractors"},
{c:236115,d: "Row house (i.e., single-family type) construction general contractors"},
{c:236115,d: "Single-family attached housing construction general contractors"},
{c:236115,d: "Single-family detached housing construction general contractors"},
{c:236115,d: "Single-family homes built on land owned by others, general contractors of"},
{c:236115,d: "Single-family house construction by general contractors"},
{c:236115,d: "Time-share condominium construction general contractors"},
{c:236115,d: "Town house (i.e., single-family type) construction by general contractors"},
{c:236115,d: "Vacation home, single-family, construction by general contractors"},
{c:236116,d: "Apartment building construction general contractors"},
{c:236116,d: "Condominium, multifamily, construction general contractors"},
{c:236116,d: "Construction management, multifamily building"},
{c:236116,d: "Cooperative apartment, construction general contractors"},
{c:236116,d: "Custom builders (except for-sale), multifamily buildings"},
{c:236116,d: "Duplex (i.e., one unit above the other), construction general contractors"},
{c:236116,d: "Garden apartment construction general contractors"},
{c:236116,d: "High-rise apartment construction general contractors"},
{c:236116,d: "Home builders (except for-sale), multifamily"},
{c:236116,d: "Housing, multifamily, construction general contractors"},
{c:236116,d: "Low income housing, multifamily, construction general contractors"},
{c:236116,d: "Low-rise apartment  construction general contractors"},
{c:236116,d: "Multifamily building construction general contractors"},
{c:236116,d: "Panelized multifamily housing assembled on site by general contractors"},
{c:236116,d: "Precut multifamily housing assembled on site by general contractors"},
{c:236116,d: "Residential construction, multifamily, general contractors"},
{c:236117,d: "Apartment building for-sale builders"},
{c:236117,d: "Cabin for-sale builders"},
{c:236117,d: "Condominium for-sale builders"},
{c:236117,d: "Cooperative apartment for-sale builders"},
{c:236117,d: "Cottage for-sale builders"},
{c:236117,d: "Custom builders, for-sale builders, multifamily buildings"},
{c:236117,d: "Custom builders, for-sale builders, single-family home"},
{c:236117,d: "Duplex for-sale builders"},
{c:236117,d: "For-sale builders (i.e., building on own land, for sale), residential"},
{c:236117,d: "For-sale builders (i.e., building on own land, for sale), single-family housing"},
{c:236117,d: "Garden apartment for-sale builders"},
{c:236117,d: "High-rise apartment for-sale builders"},
{c:236117,d: "Home builders, for-sale"},
{c:236117,d: "Housing construction, for-sale builder"},
{c:236117,d: "Housing construction, merchant builder"},
{c:236117,d: "Log home for-sale builders"},
{c:236117,d: "Low income housing construction for-sale builders"},
{c:236117,d: "Low-rise apartment for-sale builders"},
{c:236117,d: "Merchant builders (i.e., building on own land, for sale), residential"},
{c:236117,d: "Modular housing, residential, assembled on site by for-sale builders"},
{c:236117,d: "Multifamily building for-sale builders"},
{c:236117,d: "Panelized housing, residential, assembled on site by for-sale builders"},
{c:236117,d: "Panelized multifamily housing assembled on site by for-sale builders"},
{c:236117,d: "Precut housing, residential, assembled on site by for-sale builders"},
{c:236117,d: "Premanufactured housing assembled on site by for-sale builders"},
{c:236117,d: "Residential for-sale builders"},
{c:236117,d: "Row house construction for-sale builders"},
{c:236117,d: "Single-family housing built on own land for sale (i.e., for-sale builders)"},
{c:236117,d: "Single-family housing construction for-sale builders"},
{c:236117,d: "Speculative builders (i.e., building on own land, for sale), multifamily housing"},
{c:236117,d: "Speculative builders (i.e., building on own land, for sale), residential"},
{c:236117,d: "Speculative builders (i.e., building on own land, for sale), single-family housing"},
{c:236117,d: "Time-share condominium construction for-sale builders"},
{c:236117,d: "Town house construction for-sale builders"},
{c:236117,d: "Vacation housing construction for-sale builders"},
{c:236118,d: "Addition, alteration and renovation (i.e., construction), multifamily building"},
{c:236118,d: "Addition, alteration and renovation (i.e., construction), residential building"},
{c:236118,d: "Addition, alteration and renovation of single-family dwellings"},
{c:236118,d: "Addition, alteration and renovation, multifamily building, for-sale builders"},
{c:236118,d: "Addition, alteration and renovation, multifamily building, general contractors"},
{c:236118,d: "Addition, alteration and renovation, residential building, for-sale builders"},
{c:236118,d: "Addition, alteration and renovation, residential building, general contractors"},
{c:236118,d: "Addition, alteration and renovation, single-family housing, for-sale builders"},
{c:236118,d: "Addition, alteration and renovation, single-family housing, general contractors"},
{c:236118,d: "Building, residential, addition, alteration and renovation"},
{c:236118,d: "Construction management, residential remodeling"},
{c:236118,d: "Fire and flood restoration, multifamily building, general contractors"},
{c:236118,d: "Fire and flood restoration, single-family housing, general contractors"},
{c:236118,d: "Handyman construction service, residential building"},
{c:236118,d: "Home improvement (e.g., adding on, remodeling, renovating)"},
{c:236118,d: "Home improvement (e.g., adding on, remodeling, renovating), multifamily building, for-sale builders"},
{c:236118,d: "Home improvement (e.g., adding on, remodeling, renovating), multifamily building, general contractors"},
{c:236118,d: "Home improvement (e.g., adding on, remodeling, renovating), single-family housing, for-sale builders"},
{c:236118,d: "Home improvement (e.g., adding on, remodeling, renovating), single-family housing, general contractors"},
{c:236118,d: "Home renovation"},
{c:236118,d: "Porch construction, residential-type"},
{c:236118,d: "Remodeling and renovating for-sale builders"},
{c:236118,d: "Remodeling and renovating general contractors, multifamily building"},
{c:236118,d: "Remodeling and renovating general contractors, residential"},
{c:236118,d: "Remodeling and renovating general contractors, single-family housing"},
{c:236118,d: "Remodeling and renovating single-family housing"},
{c:236118,d: "Remodeling and renovating, residential building"},
{c:236118,d: "Sunroom additions, residential"},
{c:236210,d: "Acid plant construction"},
{c:236210,d: "Addition, alteration and renovation, for-sale builders, industrial building (except warehouses)"},
{c:236210,d: "Addition, alteration and renovation, general contractors, industrial building (except warehouses)"},
{c:236210,d: "Addition, alteration and renovation, industrial building (except warehouses)"},
{c:236210,d: "Aluminum plant construction"},
{c:236210,d: "Ammonium plant construction"},
{c:236210,d: "Assembly plant construction"},
{c:236210,d: "Blast furnace construction"},
{c:236210,d: "Cannery construction"},
{c:236210,d: "Cement plant construction"},
{c:236210,d: "Chemical (except petrochemical process-type) plant construction"},
{c:236210,d: "Clean room construction"},
{c:236210,d: "Construction management, industrial building (except warehouses)"},
{c:236210,d: "Factory construction"},
{c:236210,d: "Fish processing plant construction"},
{c:236210,d: "Food processing plant construction"},
{c:236210,d: "For-sale builders (i.e., building on own land, for sale), industrial building (except warehouses)"},
{c:236210,d: "Foundry construction"},
{c:236210,d: "Furnace (i.e., industrial plant structure) construction"},
{c:236210,d: "Garbage disposal plant construction"},
{c:236210,d: "Incinerator, mass-burn type, construction"},
{c:236210,d: "Incinerator, municipal waste disposal, construction"},
{c:236210,d: "Industrial building (except warehouses) construction"},
{c:236210,d: "Industrial building (except warehouses) construction, for-sale builders"},
{c:236210,d: "Industrial building (except warehouses) construction, general contractors"},
{c:236210,d: "Kiln construction"},
{c:236210,d: "Manufacturing building construction"},
{c:236210,d: "Materials recovery facility construction"},
{c:236210,d: "Metal processing plant construction"},
{c:236210,d: "Mine loading and discharging station construction"},
{c:236210,d: "Motor vehicle assembly plant construction"},
{c:236210,d: "Ore and metal refinery construction"},
{c:236210,d: "Oven, industrial plant, construction"},
{c:236210,d: "Paper or pulp mill construction"},
{c:236210,d: "Pharmaceutical manufacturing plant construction"},
{c:236210,d: "Prefabricated industrial building (except warehouses) erection"},
{c:236210,d: "Printing plant construction"},
{c:236210,d: "Refuse disposal plant (except sewage treatment) construction"},
{c:236210,d: "Smelter construction"},
{c:236210,d: "Speculative builders (i.e., building on own land, for sale), industrial building (except warehouses)"},
{c:236210,d: "Steel mill construction"},
{c:236210,d: "Textile mill construction"},
{c:236210,d: "Tipple, mining, construction"},
{c:236210,d: "Washery, mining, construction"},
{c:236210,d: "Waste disposal plant (except sewage treatment) construction"},
{c:236220,d: "Addition, alteration and renovation, commercial and institutional building"},
{c:236220,d: "Addition, alteration and renovation, commercial warehouse"},
{c:236220,d: "Addition, alteration and renovation, for-sale builders, commercial and institutional building"},
{c:236220,d: "Addition, alteration and renovation, for-sale builders, commercial warehouse"},
{c:236220,d: "Addition, alteration and renovation, for-sale builders, hotel and motel"},
{c:236220,d: "Addition, alteration and renovation, for-sale builders, industrial warehouse"},
{c:236220,d: "Addition, alteration and renovation, general contractors, commercial and institutional building"},
{c:236220,d: "Addition, alteration and renovation, general contractors, commercial warehouse"},
{c:236220,d: "Addition, alteration and renovation, general contractors, hotel and motel"},
{c:236220,d: "Addition, alteration and renovation, general contractors, industrial warehouse"},
{c:236220,d: "Addition, alteration and renovation, hotel and motel"},
{c:236220,d: "Addition, alteration and renovation, industrial warehouse"},
{c:236220,d: "Administration building construction"},
{c:236220,d: "Airport building construction"},
{c:236220,d: "Airport terminal construction"},
{c:236220,d: "Amusement facility construction"},
{c:236220,d: "Animal shelter and clinic construction"},
{c:236220,d: "Arena construction"},
{c:236220,d: "Armory construction"},
{c:236220,d: "Athletic court, indoor, construction"},
{c:236220,d: "Auditorium construction"},
{c:236220,d: "Bank building construction"},
{c:236220,d: "Barber shop construction"},
{c:236220,d: "Barrack construction"},
{c:236220,d: "Beauty salon construction"},
{c:236220,d: "Broadcasting station construction"},
{c:236220,d: "Bunkhouse construction"},
{c:236220,d: "Bus shelter construction"},
{c:236220,d: "Bus terminal construction"},
{c:236220,d: "Casino construction"},
{c:236220,d: "Cinema construction"},
{c:236220,d: "Civic center construction"},
{c:236220,d: "Clinic construction"},
{c:236220,d: "Cold storage plant construction"},
{c:236220,d: "Commercial building construction"},
{c:236220,d: "Commercial building construction for-sale builders"},
{c:236220,d: "Commercial building construction general contractors"},
{c:236220,d: "Construction management, commercial and institutional building"},
{c:236220,d: "Dormitory construction"},
{c:236220,d: "Drycleaning plant construction"},
{c:236220,d: "Educational building construction"},
{c:236220,d: "Farm building construction"},
{c:236220,d: "Fire and flood restoration of commercial and institutional buildings"},
{c:236220,d: "Fire station construction"},
{c:236220,d: "For-sale builders (i.e., building on own land, for sale), commercial and institutional building"},
{c:236220,d: "Garage and service station, commercial, construction"},
{c:236220,d: "Grain bin construction"},
{c:236220,d: "Grain elevator construction"},
{c:236220,d: "Handyman construction service, commercial and institutional building"},
{c:236220,d: "Hangar construction"},
{c:236220,d: "Hatchery construction"},
{c:236220,d: "Health and athletic club construction"},
{c:236220,d: "Hospital construction"},
{c:236220,d: "Hotel construction"},
{c:236220,d: "Ice rink, indoor, construction"},
{c:236220,d: "Indoor swimming pool construction"},
{c:236220,d: "Institutional building construction"},
{c:236220,d: "Institutional building construction for-sale builders"},
{c:236220,d: "Institutional building construction general contractors"},
{c:236220,d: "Jail construction"},
{c:236220,d: "Laboratory construction"},
{c:236220,d: "Library construction"},
{c:236220,d: "Logging camp construction"},
{c:236220,d: "Mausoleum (i.e., building) construction"},
{c:236220,d: "Monument (i.e., building) construction"},
{c:236220,d: "Motel construction"},
{c:236220,d: "Museum construction"},
{c:236220,d: "Office building construction"},
{c:236220,d: "Parking garage construction"},
{c:236220,d: "Penitentiary construction"},
{c:236220,d: "Post office construction"},
{c:236220,d: "Prefabricated commercial building erection"},
{c:236220,d: "Prefabricated institutional building erection"},
{c:236220,d: "Prison construction"},
{c:236220,d: "Public warehouse construction"},
{c:236220,d: "Radio and television broadcast studio construction"},
{c:236220,d: "Radio station construction"},
{c:236220,d: "Railway station construction"},
{c:236220,d: "Recreational facility building construction"},
{c:236220,d: "Religious building (e.g., church, synagogue, mosque, temple) construction"},
{c:236220,d: "Restaurant construction"},
{c:236220,d: "Salon construction"},
{c:236220,d: "School building construction"},
{c:236220,d: "Service station construction"},
{c:236220,d: "Shopping center construction"},
{c:236220,d: "Shopping mall construction"},
{c:236220,d: "Silo construction"},
{c:236220,d: "Speculative builders (i.e., building on own land, for sale), commercial and institutional building"},
{c:236220,d: "Stadium and arena construction"},
{c:236220,d: "Storage elevator construction"},
{c:236220,d: "Store construction"},
{c:236220,d: "Swimming facility, indoor, construction"},
{c:236220,d: "Television station construction"},
{c:236220,d: "Tennis court, indoor, construction"},
{c:236220,d: "Theater construction"},
{c:236220,d: "Truck terminal construction"},
{c:236220,d: "Warehouse construction (e.g., commercial, industrial, manufacturing, private)"},
{c:236220,d: "Warehouse, commercial and institutional, construction"},
{c:236220,d: "Warehouse, industrial, construction"},
{c:237110,d: "Aqueduct construction"},
{c:237110,d: "Artesian well construction"},
{c:237110,d: "Capping of water wells"},
{c:237110,d: "Construction management, water and sewage treatment plant"},
{c:237110,d: "Construction management, water and sewer line"},
{c:237110,d: "Distribution line, sewer and water, construction"},
{c:237110,d: "Drilling water wells (except water intake wells in oil and gas fields)"},
{c:237110,d: "Fire hydrant installation"},
{c:237110,d: "Geothermal drilling"},
{c:237110,d: "Hydrant and flushing hydrant installation"},
{c:237110,d: "Irrigation project construction (except lawn)"},
{c:237110,d: "Lagoon, sewage treatment construction"},
{c:237110,d: "Pumping station, water and sewage system, construction"},
{c:237110,d: "Reservoir construction"},
{c:237110,d: "Sanitary sewer construction"},
{c:237110,d: "Sewage collection and disposal line construction"},
{c:237110,d: "Sewage disposal plant construction"},
{c:237110,d: "Sewage treatment plant construction"},
{c:237110,d: "Sewer construction"},
{c:237110,d: "Sewer main, pipe and connection, construction"},
{c:237110,d: "Storm sewer construction"},
{c:237110,d: "Utility line (i.e., sewer, water), construction"},
{c:237110,d: "Water desalination plant construction"},
{c:237110,d: "Water filtration plant construction"},
{c:237110,d: "Water main and line construction"},
{c:237110,d: "Water pumping or lift station construction"},
{c:237110,d: "Water sampling station installation"},
{c:237110,d: "Water system storage tank and tower construction"},
{c:237110,d: "Water treatment plant construction"},
{c:237110,d: "Water well drilling, digging, boring or sinking (except water intake wells in oil and gas fields)"},
{c:237110,d: "Water well pump and well piping system installation"},
{c:237120,d: "Compressor, metering and pumping station, gas and oil pipeline, construction"},
{c:237120,d: "Construction management, oil and gas pipeline"},
{c:237120,d: "Construction management, oil refinery and petrochemical complex"},
{c:237120,d: "Corrosion protection, underground pipeline and oil storage tank"},
{c:237120,d: "Distribution line, gas and oil, construction"},
{c:237120,d: "Gas main construction"},
{c:237120,d: "Gathering line, gas and oil field, construction"},
{c:237120,d: "Manhole, oil and gas, construction"},
{c:237120,d: "Natural gas pipeline construction"},
{c:237120,d: "Natural gas processing plant construction"},
{c:237120,d: "Oil and gas field distribution line construction"},
{c:237120,d: "Oil pipeline construction"},
{c:237120,d: "Oil refinery construction"},
{c:237120,d: "Petrochemical plant construction"},
{c:237120,d: "Petroleum refinery construction"},
{c:237120,d: "Pipe lining (except thermal insulating) contractors"},
{c:237120,d: "Pipeline construction on oil and gas field gathering lines to point of distribution on a contract basis"},
{c:237120,d: "Pipeline rehabilitation contractors"},
{c:237120,d: "Pipeline wrapping contractors"},
{c:237120,d: "Pipeline, gas and oil, construction"},
{c:237120,d: "Pumping station, gas and oil transmission, construction"},
{c:237120,d: "Refinery, petroleum, construction"},
{c:237120,d: "Service line, gas and oil, construction"},
{c:237120,d: "Storage tank, natural gas or oil, tank farm or field, construction"},
{c:237130,d: "Alternative energy (e.g., geothermal, ocean wave, solar, wind) structure construction"},
{c:237130,d: "Cable laying (e.g., cable television, electricity, marine, telephone), including underground"},
{c:237130,d: "Cellular phone tower construction"},
{c:237130,d: "Co-generation plant construction"},
{c:237130,d: "Communication antenna construction"},
{c:237130,d: "Communication tower construction"},
{c:237130,d: "Construction management, power and communication transmission line"},
{c:237130,d: "Electric light and power plant (except hydroelectric) construction"},
{c:237130,d: "Electric power transmission line and tower construction"},
{c:237130,d: "Electricity generating plant (except hydroelectric) construction"},
{c:237130,d: "Fiber optic cable transmission line construction"},
{c:237130,d: "Microwave relay tower construction"},
{c:237130,d: "Nuclear power plant construction"},
{c:237130,d: "Pole line construction"},
{c:237130,d: "Power line stringing"},
{c:237130,d: "Power plant (except hydroelectric) construction"},
{c:237130,d: "Radio transmitting tower construction"},
{c:237130,d: "Satellite receiving station construction"},
{c:237130,d: "Solar power structure construction"},
{c:237130,d: "Substation and switching station, power transmission line, construction"},
{c:237130,d: "Telephone line construction"},
{c:237130,d: "Telephone line stringing"},
{c:237130,d: "Television transmitting tower construction"},
{c:237130,d: "Thermal power plant construction"},
{c:237130,d: "Tower, power distribution and communication, construction"},
{c:237130,d: "Transformer station and substation, electric power, construction"},
{c:237130,d: "Transmission and distribution line construction"},
{c:237130,d: "Underground cable (e.g., cable television, electricity, telephone) laying"},
{c:237130,d: "Utility line (i.e., communication, electric power), construction"},
{c:237130,d: "Wind power structure construction"},
{c:237210,d: "Building lot subdividing"},
{c:237210,d: "Land (except cemeteries) subdividers"},
{c:237210,d: "Land acquisition, assembling and subdividing"},
{c:237210,d: "Land developers (i.e., subdividing and installing infrastructure)"},
{c:237210,d: "Land subdividing and utility installation (e.g., electric, sewer and water)"},
{c:237210,d: "Preparing and subdividing land for sale"},
{c:237210,d: "Real estate (except cemeteries) subdividers"},
{c:237210,d: "Real property (except cemeteries) subdivision"},
{c:237210,d: "Subdividers, real estate"},
{c:237210,d: "Subdividing and preparing land owned by others"},
{c:237210,d: "Subdividing real estate"},
{c:237310,d: "Abutment construction"},
{c:237310,d: "Airport runway construction"},
{c:237310,d: "Airport runway line painting (e.g., striping)"},
{c:237310,d: "Asphalt paving (i.e., highway, road, street, public sidewalk)"},
{c:237310,d: "Bridge approach construction"},
{c:237310,d: "Bridge construction"},
{c:237310,d: "Bridge decking construction"},
{c:237310,d: "Causeway construction"},
{c:237310,d: "Concrete paving (i.e., highway, road, street, public sidewalk)"},
{c:237310,d: "Construction management, highway, road, street and bridge"},
{c:237310,d: "Culverts, highway, road and street, construction"},
{c:237310,d: "Curbs and street gutters, highway, road and street, construction"},
{c:237310,d: "Elevated highway construction"},
{c:237310,d: "Grading, highway, road, street and airport runway"},
{c:237310,d: "Guardrail construction"},
{c:237310,d: "Highway construction"},
{c:237310,d: "Highway line painting"},
{c:237310,d: "Logging road construction"},
{c:237310,d: "Oil field road construction"},
{c:237310,d: "Overpass construction"},
{c:237310,d: "Painting lines on highways, streets and bridges"},
{c:237310,d: "Painting traffic lanes or parking lots"},
{c:237310,d: "Parking lot marking and line painting"},
{c:237310,d: "Parkway construction"},
{c:237310,d: "Pavement, highway, road, street, bridge or airport runway, construction"},
{c:237310,d: "Pothole filling, highway, road, street or bridge"},
{c:237310,d: "Repair, highway, road, street, bridge or airport runway"},
{c:237310,d: "Resurfacing, highway, road, street, bridge or airport runway"},
{c:237310,d: "Road construction"},
{c:237310,d: "Runway, airport, line painting (e.g., striping)"},
{c:237310,d: "Sidewalk, public, construction"},
{c:237310,d: "Sign erection, highway, road, street, or bridge"},
{c:237310,d: "Street construction"},
{c:237310,d: "Surfacing, highway, road, street, bridge or airport runway"},
{c:237310,d: "Tarring roads"},
{c:237310,d: "Traffic lane painting"},
{c:237310,d: "Trestle construction"},
{c:237310,d: "Underpass construction"},
{c:237990,d: "Anchored earth retention contractors"},
{c:237990,d: "Athletic field (except stadium) construction"},
{c:237990,d: "Avalanche, rockslide, mudslide, or roadside protection construction"},
{c:237990,d: "Breakwater construction"},
{c:237990,d: "Bridle path construction"},
{c:237990,d: "Bulkhead wall or embarkment construction"},
{c:237990,d: "Caisson (i.e., marine or pneumatic structures) construction"},
{c:237990,d: "Canal construction"},
{c:237990,d: "Channel construction"},
{c:237990,d: "Cofferdam construction"},
{c:237990,d: "Construction management, dam"},
{c:237990,d: "Construction management, marine structure"},
{c:237990,d: "Construction management, mass transit"},
{c:237990,d: "Construction management, outdoor recreation facility"},
{c:237990,d: "Construction management, tunnel"},
{c:237990,d: "Cribbing (i.e., shore protection), construction"},
{c:237990,d: "Dam construction"},
{c:237990,d: "Dike and other flood control structure construction"},
{c:237990,d: "Dock construction"},
{c:237990,d: "Drainage canal and ditch construction"},
{c:237990,d: "Drainage project construction"},
{c:237990,d: "Dredging (e.g., canal, channel, ditch, waterway)"},
{c:237990,d: "Drive-in movie facility construction"},
{c:237990,d: "Earth retention system construction"},
{c:237990,d: "Earth-filled dam construction"},
{c:237990,d: "Electricity generating plant, hydroelectric, construction"},
{c:237990,d: "Embankment construction"},
{c:237990,d: "Farm drainage tile installation"},
{c:237990,d: "Flood control project construction"},
{c:237990,d: "Floodway canal and ditch construction"},
{c:237990,d: "Gabion construction"},
{c:237990,d: "Golf course construction"},
{c:237990,d: "Harbor construction"},
{c:237990,d: "Horizontal drilling (e.g., underground cable, pipeline, sewer installation)"},
{c:237990,d: "Hydroelectric generating facility construction"},
{c:237990,d: "Ice rink (except indoor) construction"},
{c:237990,d: "Jetty construction"},
{c:237990,d: "Land drainage contractors"},
{c:237990,d: "Levee construction"},
{c:237990,d: "Light rail system construction"},
{c:237990,d: "Lock and waterway construction"},
{c:237990,d: "Marine construction"},
{c:237990,d: "Microtunneling contractors"},
{c:237990,d: "Missile facility construction"},
{c:237990,d: "Monorail construction"},
{c:237990,d: "Nuclear waste disposal site construction"},
{c:237990,d: "Outdoor recreation facility construction"},
{c:237990,d: "Park and recreational open space improvement construction"},
{c:237990,d: "Pier construction"},
{c:237990,d: "Pile driving, marine"},
{c:237990,d: "Pipe-jacking contractors"},
{c:237990,d: "Playground construction"},
{c:237990,d: "Port facility construction"},
{c:237990,d: "Power plant, hydroelectric, construction"},
{c:237990,d: "Railroad construction"},
{c:237990,d: "Railway construction (e.g., interlocker, roadbed, signal, track)"},
{c:237990,d: "Railway roadbed construction"},
{c:237990,d: "Recreation area, open space, construction"},
{c:237990,d: "Recreational vehicle park construction"},
{c:237990,d: "Retaining walls, anchored (e.g., with piles, soil nails, tieback anchors), construction"},
{c:237990,d: "Revetment construction"},
{c:237990,d: "Riprap installation"},
{c:237990,d: "Rock removal, underwater"},
{c:237990,d: "Seawall, wave protection, construction"},
{c:237990,d: "Sediment control system construction"},
{c:237990,d: "Ski tow construction"},
{c:237990,d: "Spillway, floodwater, construction"},
{c:237990,d: "Sports field construction"},
{c:237990,d: "Streetcar line construction"},
{c:237990,d: "Subway construction"},
{c:237990,d: "Tennis courts, outdoor, construction"},
{c:237990,d: "Timber removal, underwater"},
{c:237990,d: "Trail construction"},
{c:237990,d: "Trenching, underwater"},
{c:237990,d: "Tunnel construction"},
{c:237990,d: "Wharf construction"},
{c:238110,d: "Chimney, concrete, construction"},
{c:238110,d: "Concrete finishing"},
{c:238110,d: "Concrete floor surfacing"},
{c:238110,d: "Concrete pouring"},
{c:238110,d: "Concrete pumping (i.e., placement)"},
{c:238110,d: "Concrete repair"},
{c:238110,d: "Concrete resurfacing"},
{c:238110,d: "Footing and foundation concrete contractors"},
{c:238110,d: "Foundation, building, poured concrete, contractors"},
{c:238110,d: "Grouting (i.e., reinforcing with concrete)"},
{c:238110,d: "Gunite contractors"},
{c:238110,d: "Gunning shotcrete"},
{c:238110,d: "Mud-jacking contractors"},
{c:238110,d: "Retaining wall (except anchored earth), poured concrete, construction"},
{c:238110,d: "Shotcrete contractors"},
{c:238120,d: "Balcony, precast concrete, installation"},
{c:238120,d: "Concrete product (e.g., structural precast, structural prestressed) installation"},
{c:238120,d: "Concrete reinforcement placement"},
{c:238120,d: "Curtain wall, precast concrete, installation"},
{c:238120,d: "Erecting structural steel"},
{c:238120,d: "Iron work, structural, contractors"},
{c:238120,d: "Placing and tying reinforcing rod at a construction site"},
{c:238120,d: "Precast concrete panel, slab, or form installation"},
{c:238120,d: "Prestressed concrete beam, slab or other component installation"},
{c:238120,d: "Rebar contractors"},
{c:238120,d: "Reinforcing rod, bar, mesh and cage installation"},
{c:238120,d: "Reinforcing steel contractors"},
{c:238120,d: "Stairway, precast concrete, installation"},
{c:238120,d: "Steel reinforcing contractors"},
{c:238120,d: "Structural steel erecting or iron work contractors"},
{c:238130,d: "Building framing (except structural steel)"},
{c:238130,d: "Carpentry, framing"},
{c:238130,d: "Ceiling beam, wood, installation"},
{c:238130,d: "Foundation, building, wood, contractors"},
{c:238130,d: "Framework, house, contractors"},
{c:238130,d: "Framing contractors"},
{c:238130,d: "House framing"},
{c:238130,d: "Permanent wood foundation installation"},
{c:238130,d: "Post framing contractors"},
{c:238130,d: "Prefabricated wood frame component (e.g., trusses) installation"},
{c:238130,d: "Roof truss (wood) installation"},
{c:238130,d: "Sheathing, wood, installation"},
{c:238130,d: "Steel framing contractors"},
{c:238130,d: "Stud wall (e.g., wood, steel) installation"},
{c:238130,d: "Wall component (i.e., exterior, interior), prefabricated, installation"},
{c:238130,d: "Wood frame component (e.g., truss) fabrication on site"},
{c:238140,d: "Brick veneer, installation"},
{c:238140,d: "Bricklaying contractors"},
{c:238140,d: "Cement block laying"},
{c:238140,d: "Chimney, brick, block or stone, contractors"},
{c:238140,d: "Cinder block installation"},
{c:238140,d: "Concrete block laying"},
{c:238140,d: "Field stone (i.e., masonry) installation"},
{c:238140,d: "Fireplace, masonry, installation"},
{c:238140,d: "Foundation (e.g., brick, block, stone), building, contractors"},
{c:238140,d: "Glass block laying"},
{c:238140,d: "Glass unit (i.e., glass block) masonry"},
{c:238140,d: "Granite, exterior, contractors"},
{c:238140,d: "Marble, granite and slate, exterior, contractors"},
{c:238140,d: "Masonry contractors"},
{c:238140,d: "Masonry pointing, cleaning or caulking"},
{c:238140,d: "Refractory brick contractors"},
{c:238140,d: "Retaining wall, masonry (i.e., block, brick, stone), construction"},
{c:238140,d: "Slate (i.e., masonry) contractors"},
{c:238140,d: "Stonework (i.e., masonry) contractors"},
{c:238140,d: "Stucco contractors"},
{c:238140,d: "Tuck pointing contractors"},
{c:238150,d: "Curtain wall, glass, installation"},
{c:238150,d: "Decorative glass and mirror installation"},
{c:238150,d: "Glass cladding (i.e., curtain wall), installation"},
{c:238150,d: "Glass coating and tinting (except automotive) contractors"},
{c:238150,d: "Glass installation (except automotive) contractors"},
{c:238150,d: "Glass partitions, installation"},
{c:238150,d: "Glazing contractors"},
{c:238150,d: "Hermetically sealed window unit, commercial-type, installation"},
{c:238150,d: "Mirror installation"},
{c:238150,d: "Stained glass installation"},
{c:238150,d: "Window pane or sheet installation"},
{c:238160,d: "Asphalt roof shingle installation"},
{c:238160,d: "Copper roofing installation"},
{c:238160,d: "Corrugated metal roofing installation"},
{c:238160,d: "Galvanized iron roofing installation"},
{c:238160,d: "Low slope roofing installation"},
{c:238160,d: "Painting, spraying, or coating, roof"},
{c:238160,d: "Roll roofing installation"},
{c:238160,d: "Roof membrane installation"},
{c:238160,d: "Roof painting, spraying, or coating"},
{c:238160,d: "Roofing contractors"},
{c:238160,d: "Roofing, built-up tar and gravel, installation"},
{c:238160,d: "Shake and shingle, roof, installation"},
{c:238160,d: "Sheet metal roofing installation"},
{c:238160,d: "Skylight installation"},
{c:238160,d: "Solar reflecting coating, roof, application"},
{c:238160,d: "Steep slope roofing installation"},
{c:238160,d: "Treating roofs (by spraying, painting or coating)"},
{c:238170,d: "Aluminum siding installation"},
{c:238170,d: "Downspout, gutter, and gutter guard installation"},
{c:238170,d: "Eavestrough installation"},
{c:238170,d: "Fascia and soffit installation"},
{c:238170,d: "Flashing contractors"},
{c:238170,d: "Gutter and downspout contractors"},
{c:238170,d: "Gutters, seamless roof, formed and installed on site"},
{c:238170,d: "Ice apron, roof, installation"},
{c:238170,d: "Metal siding installation"},
{c:238170,d: "Siding (e.g., vinyl, wood, aluminum) installation"},
{c:238170,d: "Siding contractors"},
{c:238170,d: "Vinyl siding, soffit and fascia, installation"},
{c:238170,d: "Wood siding, installation"},
{c:238190,d: "Awning installation"},
{c:238190,d: "Balcony, metal, installation"},
{c:238190,d: "Cathodic protection, installation"},
{c:238190,d: "Concrete form contractors"},
{c:238190,d: "Curtain wall, metal, installation"},
{c:238190,d: "Deck and grate (except roof), metal, installation"},
{c:238190,d: "Decorative steel and wrought iron work installation"},
{c:238190,d: "Epoxy application contractors"},
{c:238190,d: "Erection and dismantling, poured concrete form"},
{c:238190,d: "Falsework construction"},
{c:238190,d: "Fire escape installation"},
{c:238190,d: "Forming contractor"},
{c:238190,d: "Forms for poured concrete, erecting and dismantling"},
{c:238190,d: "Metal furring contractors"},
{c:238190,d: "Ornamental metal work installation"},
{c:238190,d: "Shutter installation"},
{c:238190,d: "Stairway, metal, installation"},
{c:238190,d: "Storefront, metal or metal frame, installation"},
{c:238190,d: "Urethane slabjacking contractors"},
{c:238190,d: "Urethane soil stabilization contractors"},
{c:238190,d: "Welding, on-site, contractors"},
{c:238210,d: "Airport runway lighting contractors"},
{c:238210,d: "Alarm system (e.g., fire, burglar), electric, installation only"},
{c:238210,d: "Audio equipment installation (except automotive) contractors"},
{c:238210,d: "Building automation system installation contractors"},
{c:238210,d: "Burglar alarm system, electric, installation only"},
{c:238210,d: "Cable splicing, electrical or fiber optic"},
{c:238210,d: "Cable television hookup contractors"},
{c:238210,d: "Communication equipment installation"},
{c:238210,d: "Computer and network cable installation"},
{c:238210,d: "Control system (e.g., environmental, humidity, temperature) installation"},
{c:238210,d: "Electric contracting"},
{c:238210,d: "Electric power control panel and outlet installation"},
{c:238210,d: "Electrical contractors"},
{c:238210,d: "Electrical equipment and appliance installation"},
{c:238210,d: "Electrical wiring contractors"},
{c:238210,d: "Electrical work"},
{c:238210,d: "Electrical, electrical wiring, and low voltage electrical work"},
{c:238210,d: "Electrician"},
{c:238210,d: "Electronic containment fencing for pets, installation"},
{c:238210,d: "Electronic control installation and service"},
{c:238210,d: "Electronic control system installation"},
{c:238210,d: "Environmental control system installation"},
{c:238210,d: "Fiber optic cable (except transmission lines) installation"},
{c:238210,d: "Fire alarm system, electric, installation only"},
{c:238210,d: "Highway, street and bridge lighting and electrical signal installation"},
{c:238210,d: "Home automation system installation"},
{c:238210,d: "Home theater installation"},
{c:238210,d: "Humidity control system installation"},
{c:238210,d: "Installation of photovoltaic panels"},
{c:238210,d: "Intercommunication (intercom) system installation"},
{c:238210,d: "Lighting system installation"},
{c:238210,d: "Low voltage electrical work"},
{c:238210,d: "Private driveway or parking area lighting contractors"},
{c:238210,d: "Public address system installation"},
{c:238210,d: "Railroad signaling equipment installation"},
{c:238210,d: "Security and fire system, installation only"},
{c:238210,d: "Smoke detection system, installation only"},
{c:238210,d: "Snow melting cable, electric, installation"},
{c:238210,d: "Solar panel installation"},
{c:238210,d: "Sound equipment installation"},
{c:238210,d: "Surveillance system, installation only"},
{c:238210,d: "Telecommunications equipment and wiring (except transmission line) installation contractors"},
{c:238210,d: "Telephone equipment and building wiring installation"},
{c:238210,d: "Telephone installation contractors"},
{c:238210,d: "Temperature control system installation"},
{c:238210,d: "Traffic signal installation"},
{c:238210,d: "Tunnel lighting contractors"},
{c:238220,d: "Air system balancing and testing"},
{c:238220,d: "Air vent installation"},
{c:238220,d: "Air-conditioning system (except window) installation"},
{c:238220,d: "Bathroom plumbing fixture and sanitary ware installation"},
{c:238220,d: "Blower or fan, cooling and dry heating, installation"},
{c:238220,d: "Boiler chipping, cleaning and scaling"},
{c:238220,d: "Boiler, heating, installation"},
{c:238220,d: "Central air-conditioning equipment installation"},
{c:238220,d: "Central cooling equipment and piping installation"},
{c:238220,d: "Central heating equipment and piping installation"},
{c:238220,d: "Chilled water system installation"},
{c:238220,d: "Chimney liner installation"},
{c:238220,d: "Commercial freezer installation"},
{c:238220,d: "Commercial refrigeration system installation"},
{c:238220,d: "Cooling tower installation"},
{c:238220,d: "Drain, waste and vent system installation"},
{c:238220,d: "Drinking fountain installation"},
{c:238220,d: "Dry heating equipment installation"},
{c:238220,d: "Duct work (e.g., cooling, dust collection, exhaust, heating, ventilation) installation"},
{c:238220,d: "Dust collecting and bag house equipment installation"},
{c:238220,d: "Exhaust system (e.g., kitchens, industrial work areas) installation"},
{c:238220,d: "Fire extinguisher installation"},
{c:238220,d: "Fire extinguisher installation and repair"},
{c:238220,d: "Fire sprinkler system installation"},
{c:238220,d: "Fireplace, natural gas, installation"},
{c:238220,d: "Fuel oil burner installation"},
{c:238220,d: "Furnace conversion (i.e., from one fuel to another)"},
{c:238220,d: "Furnace humidifier installation"},
{c:238220,d: "Furnace installation"},
{c:238220,d: "Furnace, forced air, installation"},
{c:238220,d: "Gas fitting contractor"},
{c:238220,d: "Gas line installation, individual hookup, contractors"},
{c:238220,d: "Heat pump installation"},
{c:238220,d: "Heating and cooling duct work installation"},
{c:238220,d: "Heating and ventilation system component (e.g., air registers, diffusers, filters, grilles, sound attenuators) installation"},
{c:238220,d: "Heating boiler installation"},
{c:238220,d: "Heating contractors"},
{c:238220,d: "Heating equipment installation"},
{c:238220,d: "Heating, ventilation and air-conditioning (HVAC) contractors"},
{c:238220,d: "Hot water heating system installation"},
{c:238220,d: "Hot water tank installation"},
{c:238220,d: "Household oil storage tank installation"},
{c:238220,d: "HVAC (heating, ventilation and air-conditioning) contractors"},
{c:238220,d: "Hydronic heating system installation"},
{c:238220,d: "Industrial process piping installation"},
{c:238220,d: "Kitchen sink and hardware installation"},
{c:238220,d: "Lawn sprinkler system installation"},
{c:238220,d: "Mechanical contractors"},
{c:238220,d: "Natural gas piping installation"},
{c:238220,d: "Oil burner installation"},
{c:238220,d: "Pipe fitting contractors"},
{c:238220,d: "Plumbers"},
{c:238220,d: "Plumbing and heating contractors"},
{c:238220,d: "Plumbing contractors"},
{c:238220,d: "Plumbing fixture installation"},
{c:238220,d: "Process piping installation"},
{c:238220,d: "Pumping system, water, installation"},
{c:238220,d: "Radiant floor heating equipment installation"},
{c:238220,d: "Refrigeration system (e.g., commercial, industrial, scientific) installation"},
{c:238220,d: "Sanitary ware installation"},
{c:238220,d: "Scrubber, air purification, installation"},
{c:238220,d: "Sewer hookup and connection, building"},
{c:238220,d: "Sheet metal duct work installation"},
{c:238220,d: "Snow melting system (e.g., hot water, glycol) installation"},
{c:238220,d: "Solar heating equipment installation"},
{c:238220,d: "Sprinkler system, building, installation"},
{c:238220,d: "Steam fitting contractors"},
{c:238220,d: "Sump pump installation"},
{c:238220,d: "Ventilating contractors"},
{c:238220,d: "Warehouse refrigeration system installation"},
{c:238220,d: "Warm air heating system installation"},
{c:238220,d: "Water heater installation"},
{c:238220,d: "Water meter installation"},
{c:238220,d: "Water softener installation"},
{c:238220,d: "Water system balancing and testing contractors"},
{c:238220,d: "Waterless fire suppression system installation"},
{c:238220,d: "Waterless fire suppression system installation and repair"},
{c:238290,d: "Antenna, household-type, installation"},
{c:238290,d: "ATMs (automatic teller machines) installation"},
{c:238290,d: "Automated and revolving door installation"},
{c:238290,d: "Automatic gate (e.g., garage, parking lot) installation"},
{c:238290,d: "Boiler and pipe insulation installation"},
{c:238290,d: "Boiler covering installation"},
{c:238290,d: "Bowling alley equipment installation"},
{c:238290,d: "Church bell and tower clock installation"},
{c:238290,d: "Commercial kitchen food preparation equipment (e.g., mixers, ovens, stoves) installation"},
{c:238290,d: "Commercial-type door installation"},
{c:238290,d: "Conveyor system installation"},
{c:238290,d: "Deodorization (i.e., air filtration) system installation"},
{c:238290,d: "Dismantling large-scale machinery and equipment"},
{c:238290,d: "Door, commercial- or industrial-type, installation"},
{c:238290,d: "Duct insulation installation"},
{c:238290,d: "Dumbwaiter installation"},
{c:238290,d: "Elevator installation"},
{c:238290,d: "Escalator installation"},
{c:238290,d: "Garage door, commercial- or industrial-type, installation"},
{c:238290,d: "Gasoline pump, service station, installation"},
{c:238290,d: "Hoisting and placement of large-scale apparatus"},
{c:238290,d: "Incinerators, building equipment type, installation"},
{c:238290,d: "Insulation, boiler, duct and pipe, installation"},
{c:238290,d: "Lightning protection equipment (e.g., lightning rod) installation"},
{c:238290,d: "Lightning rod and conductor installation"},
{c:238290,d: "Machine rigging"},
{c:238290,d: "Machinery and equipment, large-scale, installation"},
{c:238290,d: "Material handling equipment installation"},
{c:238290,d: "Mechanical equipment insulation"},
{c:238290,d: "Millwrights"},
{c:238290,d: "Motor vehicle garage and service station mechanical equipment (e.g., gasoline pumps, hoists) installation"},
{c:238290,d: "Moving sidewalk installation"},
{c:238290,d: "Overhead door, commercial- or industrial-type, installation"},
{c:238290,d: "Pipe covering"},
{c:238290,d: "Pipe, duct and boiler insulation"},
{c:238290,d: "Pneumatic tube conveyor system installation"},
{c:238290,d: "Power boiler, installation only"},
{c:238290,d: "Power generating equipment installation"},
{c:238290,d: "Revolving door installation"},
{c:238290,d: "Rigging large-scale equipment"},
{c:238290,d: "Rubber door installation"},
{c:238290,d: "Satellite dish, household-type, installation"},
{c:238290,d: "Vacuum cleaning system, built-in, installation"},
{c:238290,d: "Vault, safe and banking machine installation"},
{c:238290,d: "Vehicle lift installation"},
{c:238290,d: "Vending machine installation"},
{c:238290,d: "Water pipe insulating"},
{c:238310,d: "Acoustical ceiling tile and panel installation"},
{c:238310,d: "Acoustical foam (i.e., sound barrier) installation"},
{c:238310,d: "Attic space insulating"},
{c:238310,d: "Blown-in insulation (e.g., cellulose, vermiculite) installation"},
{c:238310,d: "Building fireproofing contractors"},
{c:238310,d: "Building insulation contractors"},
{c:238310,d: "Ceiling tile installation"},
{c:238310,d: "Cellulosic fiber insulation installation"},
{c:238310,d: "Drop ceiling installation"},
{c:238310,d: "Drywall contractors"},
{c:238310,d: "Drywall finishing (e.g., sanding, spackling, stippling, taping, texturing)"},
{c:238310,d: "Drywall hanging"},
{c:238310,d: "Drywall installation"},
{c:238310,d: "Exterior insulation finish system installation"},
{c:238310,d: "Fabric wall system, noise insulating, installation"},
{c:238310,d: "Finishing drywall contractors"},
{c:238310,d: "Fireproof flooring installation"},
{c:238310,d: "Fireproofing buildings"},
{c:238310,d: "Firestop contractors"},
{c:238310,d: "Foam insulation installation"},
{c:238310,d: "Fresco (i.e., decorative plaster finishing) contractors"},
{c:238310,d: "Glass fiber insulation installation"},
{c:238310,d: "Gypsum board installation"},
{c:238310,d: "Insulation contractors"},
{c:238310,d: "Lathing contractors"},
{c:238310,d: "Mineral wool insulation installation"},
{c:238310,d: "Panel or rigid board insulation installation"},
{c:238310,d: "Plastering (i.e., ornamental, plain) contractors"},
{c:238310,d: "Polystyrene board insulation installation"},
{c:238310,d: "Roof insulation contractor"},
{c:238310,d: "Soundproofing contractors"},
{c:238310,d: "Styrofoam insulation installation"},
{c:238310,d: "Suspended ceiling installation"},
{c:238310,d: "Taping and finishing drywall"},
{c:238310,d: "Urethane foam insulation application"},
{c:238310,d: "Wall cavity and attic space insulation installation"},
{c:238320,d: "Bridge painting"},
{c:238320,d: "Electrostatic painting, on-site, contractors"},
{c:238320,d: "Engineering structure (e.g., oil storage tank, water tower) painting"},
{c:238320,d: "House painting"},
{c:238320,d: "Paint and wallpaper stripping"},
{c:238320,d: "Painting (except roof) contractors"},
{c:238320,d: "Painting and wallpapering"},
{c:238320,d: "Paperhanging and removal contractors"},
{c:238320,d: "Paperhanging or removal contractors"},
{c:238320,d: "Rustproofing (except automotive)"},
{c:238320,d: "Ship painting contractors"},
{c:238320,d: "Wall covering or removal contractors"},
{c:238320,d: "Wallpaper hanging and removal contractors"},
{c:238320,d: "Wallpaper stripping"},
{c:238320,d: "Whitewashing contractors"},
{c:238330,d: "Access flooring installation"},
{c:238330,d: "Asphalt flooring, installation only"},
{c:238330,d: "Carpet, installation only"},
{c:238330,d: "Computer flooring installation"},
{c:238330,d: "Floor laying, scraping, finishing and refinishing"},
{c:238330,d: "Floor tile and sheets, installation only"},
{c:238330,d: "Hardwood flooring, installation only"},
{c:238330,d: "Linoleum, installation only"},
{c:238330,d: "Parquet flooring installation"},
{c:238330,d: "Resilient floor tile or sheet (e.g., linoleum, rubber, vinyl), installation only"},
{c:238330,d: "Resurfacing hardwood flooring"},
{c:238330,d: "Vinyl flooring contractors"},
{c:238330,d: "Wood floor finishing (e.g., coating, sanding)"},
{c:238330,d: "Wood flooring, installation only"},
{c:238340,d: "Ceramic tile installation"},
{c:238340,d: "Granite, interior, installation"},
{c:238340,d: "Mantel, marble or stone, installation"},
{c:238340,d: "Marble, granite, and slate, interior installation contractors"},
{c:238340,d: "Mosaic work"},
{c:238340,d: "Slate, interior, installation"},
{c:238340,d: "Stone flooring installation"},
{c:238340,d: "Terrazzo and tile refinishing"},
{c:238340,d: "Terrazzo contractors"},
{c:238340,d: "Tile (except resilient) contractors"},
{c:238340,d: "Tile (except resilient) laying and setting"},
{c:238350,d: "Aluminum door and window, residential-type, installation"},
{c:238350,d: "Built-in wood cabinets constructed on site"},
{c:238350,d: "Cabinet work performed at the construction site"},
{c:238350,d: "Cabinetry work performed at the construction site"},
{c:238350,d: "Cabinets, wood built-in, constructed on site"},
{c:238350,d: "Carpenters (except framing)"},
{c:238350,d: "Carpentry work (except framing)"},
{c:238350,d: "Countertop, residential-type, installation"},
{c:238350,d: "Deck construction, residential-type"},
{c:238350,d: "Door and window frame construction"},
{c:238350,d: "Door and window, prefabricated, installation"},
{c:238350,d: "Door, folding, installation"},
{c:238350,d: "Finish carpentry"},
{c:238350,d: "Garage door, residential-type, installation"},
{c:238350,d: "Hermetically sealed window unit, residential-type, installation"},
{c:238350,d: "Kitchen cabinets and counters, constructed on site"},
{c:238350,d: "Millwork installation"},
{c:238350,d: "Molding or trim, wood or plastic, installation"},
{c:238350,d: "Overhead door, residential-type, installation"},
{c:238350,d: "Paneling installation"},
{c:238350,d: "Prefabricated kitchen and bath cabinet, residential-type, installation"},
{c:238350,d: "Prefabricated sash and door installation"},
{c:238350,d: "Shelving, wood, constructed on site"},
{c:238350,d: "Ship joinery contractors"},
{c:238350,d: "Stairway, wood, installation"},
{c:238350,d: "Trim and finish carpentry contractors"},
{c:238350,d: "Window and door (residential-type) of any material, prefabricated, installation"},
{c:238350,d: "Window installation"},
{c:238350,d: "Window, metal frame residential-type, installation"},
{c:238350,d: "Window, wood, installation"},
{c:238390,d: "Bathtub refinishing, on-site"},
{c:238390,d: "Bleacher installation"},
{c:238390,d: "Building fixture and fitting (except mechanical equipment) installation"},
{c:238390,d: "Caulking (i.e., waterproofing) contractors"},
{c:238390,d: "Ceiling, metal, installation"},
{c:238390,d: "Closet organizer system installation"},
{c:238390,d: "Coating concrete structures with plastics"},
{c:238390,d: "Concrete coating, glazing or sealing"},
{c:238390,d: "Countertop and cabinet, metal (except residential-type), installation"},
{c:238390,d: "Dampproofing contractors"},
{c:238390,d: "Drapery fixture (e.g., hardware, rods, tracks) installation"},
{c:238390,d: "Fabrication, metal cabinet or countertop, on site"},
{c:238390,d: "Foundation dampproofing (including installing rigid foam insulation)"},
{c:238390,d: "Laboratory furniture and equipment installation"},
{c:238390,d: "Lead lining walls for X-ray room contractors"},
{c:238390,d: "Modular furniture system attachment and installation"},
{c:238390,d: "Office furniture, modular system, installation"},
{c:238390,d: "Panel, metal, installation"},
{c:238390,d: "Partition (e.g., office, washroom), metal, installation"},
{c:238390,d: "Partition, moveable and/or demountable, installation"},
{c:238390,d: "Shelving, metal, constructed on site"},
{c:238390,d: "Spectator seating installation"},
{c:238390,d: "Trade show exhibit installation and dismantling contractors"},
{c:238390,d: "Ventilated wire shelving (i.e., closet organizing-type) installation"},
{c:238390,d: "Vibration isolation contractors"},
{c:238390,d: "Waterproofing contractors"},
{c:238390,d: "Weather stripping installation"},
{c:238390,d: "Weatherproofing concrete"},
{c:238390,d: "Window shade and blind installation"},
{c:238910,d: "Aerial or picker truck, construction, rental with operator"},
{c:238910,d: "Backfilling, construction"},
{c:238910,d: "Backhoe rental with operator"},
{c:238910,d: "Blast hole drilling (except mining)"},
{c:238910,d: "Blasting, building demolition"},
{c:238910,d: "Blasting, construction site"},
{c:238910,d: "Boring, for building construction"},
{c:238910,d: "Building demolition"},
{c:238910,d: "Bulldozer rental with operator"},
{c:238910,d: "Caisson (i.e., drilled building foundations) construction"},
{c:238910,d: "Cesspool construction"},
{c:238910,d: "Concrete breaking and cutting for demolition"},
{c:238910,d: "Construction equipment (except crane) rental with operator"},
{c:238910,d: "Core drilling and test boring for construction"},
{c:238910,d: "Crawler tractor rental with operator"},
{c:238910,d: "Culvert or bridge removal"},
{c:238910,d: "Cutting new rights of way"},
{c:238910,d: "Demolition contractor"},
{c:238910,d: "Demolition, building and structure"},
{c:238910,d: "Dewatering contractors"},
{c:238910,d: "Digging foundations"},
{c:238910,d: "Dirt moving for construction"},
{c:238910,d: "Dismantling engineering structures (e.g., oil storage tank)"},
{c:238910,d: "Drainage system (e.g., cesspool, septic tank) installation"},
{c:238910,d: "Drilled pier (i.e., for building foundations) contractors"},
{c:238910,d: "Drilled shaft (i.e., drilled building foundations) construction"},
{c:238910,d: "Dry well construction"},
{c:238910,d: "Equipment rental (except crane), construction, with operator"},
{c:238910,d: "Excavating, earthmoving or land clearing, mining (except overburden removal at open pit mine sites or quarries)"},
{c:238910,d: "Excavating, earthmoving, or land clearing contractors"},
{c:238910,d: "Excavation contractors"},
{c:238910,d: "Foundation digging (i.e., excavation)"},
{c:238910,d: "Foundation drilling contractors"},
{c:238910,d: "Grading construction sites"},
{c:238910,d: "Grave excavation contractors"},
{c:238910,d: "Ground thawing for construction site digging"},
{c:238910,d: "House demolishing"},
{c:238910,d: "House razing"},
{c:238910,d: "Hydrodemolition (i.e., demolition with pressurized water) contractors"},
{c:238910,d: "Land clearing"},
{c:238910,d: "Land leveling contractors"},
{c:238910,d: "Line slashing or cutting (except maintenance)"},
{c:238910,d: "Machinery, construction (except cranes), rental with operator"},
{c:238910,d: "Mine site preparation and related construction activities, construction contractors"},
{c:238910,d: "Pile driving, building foundation"},
{c:238910,d: "Piling (i.e., bored, cast-in-place, drilled), building foundation, contractors"},
{c:238910,d: "Power shovel, construction, rental with operator"},
{c:238910,d: "Power, communication and pipe line right of way clearance (except maintenance)"},
{c:238910,d: "Removal of dams, dikes, and other heavy and civil engineering constructions"},
{c:238910,d: "Right of way cutting (except maintenance)"},
{c:238910,d: "Road decommissioning"},
{c:238910,d: "Septic system contractors"},
{c:238910,d: "Septic tank and weeping tile installation"},
{c:238910,d: "Soil compacting"},
{c:238910,d: "Soil test drilling"},
{c:238910,d: "Test boring for construction"},
{c:238910,d: "Trenching (except underwater)"},
{c:238910,d: "Underground tank (except hazardous material) removal"},
{c:238910,d: "Weeping tile installation"},
{c:238910,d: "Wrecking, building or other structure"},
{c:238990,d: "Artificial turf installation"},
{c:238990,d: "Asphalt coating and sealing, residential and commercial parking lot and driveway"},
{c:238990,d: "Asphalting, residential and commercial driveway and parking area"},
{c:238990,d: "Billboard erection"},
{c:238990,d: "Blacktop work, residential and commercial driveway and parking area"},
{c:238990,d: "Boat lift installation"},
{c:238990,d: "Brick driveway contractors"},
{c:238990,d: "Brick paver (e.g., driveways, patios, sidewalks) installation"},
{c:238990,d: "Cable splicing (except electrical or fiber optic)"},
{c:238990,d: "Chain link fence installation"},
{c:238990,d: "Cleaning building interiors during and immediately after construction"},
{c:238990,d: "Cleaning new building interiors immediately after construction"},
{c:238990,d: "Concrete patio construction"},
{c:238990,d: "Concrete paving, residential and commercial driveway and parking area"},
{c:238990,d: "Concrete sawing and drilling (except demolition)"},
{c:238990,d: "Construction elevator (i.e., temporary use during construction) erection and dismantling"},
{c:238990,d: "Crane rental with operator"},
{c:238990,d: "Culvert, concrete, residential and commercial paved area"},
{c:238990,d: "Curb and gutter construction, residential and commercial driveway and parking area, concrete"},
{c:238990,d: "Driveway paving or sealing"},
{c:238990,d: "Fence installation (except electronic containment fencing for pets)"},
{c:238990,d: "Fencing contractors (except electronic containment fencing for pets)"},
{c:238990,d: "Flagpole installation"},
{c:238990,d: "House moving (i.e., raising from one site, moving, and placing on a new foundation)"},
{c:238990,d: "Interlocking brick and block installation"},
{c:238990,d: "Mail box units, outdoor, multiple box-type, erection"},
{c:238990,d: "Manufactured (mobile) home set up and tie-down work"},
{c:238990,d: "Parking lot paving and sealing"},
{c:238990,d: "Patio construction"},
{c:238990,d: "Paver, brick (e.g., driveway, patio, sidewalk), installation"},
{c:238990,d: "Paving, residential and commercial driveway and parking lot"},
{c:238990,d: "Playground equipment installation"},
{c:238990,d: "Pole (e.g., telephone) removal"},
{c:238990,d: "Posthole digging"},
{c:238990,d: "Radon gas alleviation contractors"},
{c:238990,d: "Safety net system, erecting and dismantling at construction site"},
{c:238990,d: "Sandblasting, building exterior"},
{c:238990,d: "Scaffold erecting and dismantling"},
{c:238990,d: "Shoring, construction"},
{c:238990,d: "Sidewalk construction, residential and commercial"},
{c:238990,d: "Sign (except on highways, streets, bridges and tunnels) erection"},
{c:238990,d: "Sign, building, erection"},
{c:238990,d: "Statue erection"},
{c:238990,d: "Steeplejack work"},
{c:238990,d: "Street, interlocking brick (i.e., not mortared), installation"},
{c:238990,d: "Swimming pool screen enclosure construction"},
{c:238990,d: "Swimming pool, outdoor, construction"},
{c:238990,d: "Tank lining contractors"},
{c:238990,d: "Turf, artificial, installation"},
{c:238990,d: "Underpinning, construction"},
{c:311111,d: "Animal feed mills, dog and cat, manufacturing"},
{c:311111,d: "Animal feeds, prepared, dog and cat, manufacturing"},
{c:311111,d: "Cat food manufacturing"},
{c:311111,d: "Dog and cat food (e.g., canned, dry, frozen, semimoist), manufacturing"},
{c:311111,d: "Dog food manufacturing"},
{c:311111,d: "Feed supplements, dog and cat, manufacturing"},
{c:311111,d: "Feeds, prepared for dog and cat, manufacturing"},
{c:311111,d: "Horsemeat, processing, for dog and cat food"},
{c:311111,d: "Meat canning, dog and cat, pet food, made from purchased carcasses"},
{c:311111,d: "Meat products, dog and cat, pet food, canning, made from purchased carcasses"},
{c:311111,d: "Pet food, dog and cat, manufacturing"},
{c:311119,d: "Alfalfa meal, dehydrated, manufacturing"},
{c:311119,d: "Alfalfa prepared as feed for animals"},
{c:311119,d: "Alfalfa, cubed, manufacturing"},
{c:311119,d: "Animal feed mills (except dog and cat) manufacturing"},
{c:311119,d: "Animal feeds, prepared (except dog and cat), manufacturing"},
{c:311119,d: "Barley feed, chopped, crushed or ground, manufacturing"},
{c:311119,d: "Bird feed, prepared, manufacturing"},
{c:311119,d: "Blending animal feed"},
{c:311119,d: "Bone meal prepared as feed for animals and fowls"},
{c:311119,d: "Cattle feeds, supplements, concentrates, and premixes, manufacturing"},
{c:311119,d: "Chicken feeds, prepared, manufacturing"},
{c:311119,d: "Citrus pulp, cattle feed, manufacturing"},
{c:311119,d: "Complete feed, livestock, manufacturing"},
{c:311119,d: "Custom milling of animal feed"},
{c:311119,d: "Dairy cattle feeds, supplements, concentrates, and premixes, manufacturing"},
{c:311119,d: "Earthworm food and bedding manufacturing"},
{c:311119,d: "Feed concentrates, animal, manufacturing"},
{c:311119,d: "Feed premixes, animal, manufacturing"},
{c:311119,d: "Feed supplements, animal (except cat, dog), manufacturing"},
{c:311119,d: "Feeds, prepared, for animals (except cat, dog) manufacturing"},
{c:311119,d: "Feeds, specialty (e.g., guinea pig, mice, mink), manufacturing"},
{c:311119,d: "Fish food for feeding fish manufacturing"},
{c:311119,d: "Grain grinding, custom, for animal feed"},
{c:311119,d: "Grain mills, animal feed"},
{c:311119,d: "Hay, cubed, manufacturing"},
{c:311119,d: "Kelp meal and pellets, animal feed manufacturing"},
{c:311119,d: "Laboratory animal feed manufacturing"},
{c:311119,d: "Livestock feeds, supplements, concentrates and premixes, manufacturing"},
{c:311119,d: "Meal, alfalfa, manufacturing"},
{c:311119,d: "Meal, bone, prepared as feed for animals and fowls, manufacturing"},
{c:311119,d: "Micro and macro premixes, livestock, manufacturing"},
{c:311119,d: "Mineral feed supplements (except cat, dog) manufacturing"},
{c:311119,d: "Mineral supplements, animal (except cat, dog), manufacturing"},
{c:311119,d: "Mobile feed mill"},
{c:311119,d: "Pet food (except cat, dog) manufacturing"},
{c:311119,d: "Poultry feeds, supplements, and concentrates manufacturing"},
{c:311119,d: "Rabbit food manufacturing"},
{c:311119,d: "Shell crushing and grinding for animal feed"},
{c:311119,d: "Shell crushing for feed"},
{c:311119,d: "Swine feed, complete, manufacturing"},
{c:311119,d: "Swine feed, supplements, concentrates, and premixes, manufacturing"},
{c:311119,d: "Turkey feeds, prepared, manufacturing"},
{c:311211,d: "Barley flour manufacturing"},
{c:311211,d: "Batters, prepared, made in flour mills"},
{c:311211,d: "Blended flour made in flour mills"},
{c:311211,d: "Brewers' and distillers' flakes and grits, corn, manufacturing"},
{c:311211,d: "Buckwheat flour manufacturing"},
{c:311211,d: "Bulgur (flour) manufacturing"},
{c:311211,d: "Cereal grain flour manufacturing"},
{c:311211,d: "Cereal grain germ manufacturing"},
{c:311211,d: "Corn flour manufacturing"},
{c:311211,d: "Corn meal made in flour mills"},
{c:311211,d: "Doughs, prepared, made in flour mills"},
{c:311211,d: "Durum flour manufacturing"},
{c:311211,d: "Farina (except breakfast food) made in flour mills"},
{c:311211,d: "Flour mills, cereals grains (except breakfast cereals, rice)"},
{c:311211,d: "Flour mixes made in flour mills"},
{c:311211,d: "Flour, blended, prepared, or self-rising (except rice), made in flour mills"},
{c:311211,d: "Fruit flour, meal, and powders, manufacturing"},
{c:311211,d: "Graham flour manufacturing"},
{c:311211,d: "Grain mills (except animal feed, breakfast cereal, rice)"},
{c:311211,d: "Grits and flakes, corn brewer's, manufacturing"},
{c:311211,d: "Hominy grits (except breakfast food), manufacturing"},
{c:311211,d: "Meal, corn, for human consumption made in flour mills"},
{c:311211,d: "Mixes, flour (e.g., biscuit, cake, doughnut, pancake) made in flour mills"},
{c:311211,d: "Oat flour manufacturing"},
{c:311211,d: "Potato flour manufacturing"},
{c:311211,d: "Prepared flour mixes made in flour mills"},
{c:311211,d: "Rye flour manufacturing"},
{c:311211,d: "Semolina flour manufacturing"},
{c:311211,d: "Sorghum flour manufacturing"},
{c:311211,d: "Vegetable flour manufacturing"},
{c:311211,d: "Vegetable flour, meal, and powders, made in flour mills"},
{c:311211,d: "Wheat bran manufacturing"},
{c:311211,d: "Wheat flour manufacturing"},
{c:311211,d: "Wheat germ manufacturing"},
{c:311212,d: "Bran and other residues of milling rice"},
{c:311212,d: "Brewers' rice manufacturing"},
{c:311212,d: "Cleaning and polishing rice"},
{c:311212,d: "Flour, rice, manufacturing"},
{c:311212,d: "Grain mills, rice"},
{c:311212,d: "Head rice manufacturing"},
{c:311212,d: "Milling rice"},
{c:311212,d: "Polished rice manufacturing"},
{c:311212,d: "Rice bran, flour, and meals, manufacturing"},
{c:311212,d: "Rice cleaning and polishing"},
{c:311212,d: "Rice flour manufacturing"},
{c:311212,d: "Rice meal manufacturing"},
{c:311212,d: "Rice milling"},
{c:311212,d: "Rice mixes (i.e., uncooked and packaged with other ingredients) made in rice mills"},
{c:311212,d: "Rice, brewer's, manufacturing"},
{c:311212,d: "Rice, brown, manufacturing"},
{c:311213,d: "Barley, malt, manufacturing"},
{c:311213,d: "Brewers' malt manufacturing"},
{c:311213,d: "Corn, malt, manufacturing"},
{c:311213,d: "Distiller's malt manufacturing"},
{c:311213,d: "Flour, malt, manufacturing"},
{c:311213,d: "Malt flour manufacturing"},
{c:311213,d: "Malt manufacturing"},
{c:311213,d: "Malt sprouts manufacturing"},
{c:311213,d: "Malting (germinating and drying grains)"},
{c:311213,d: "Rice malt manufacturing"},
{c:311213,d: "Rye malt manufacturing"},
{c:311213,d: "Wheat malt manufacturing"},
{c:311221,d: "Corn dextrin manufacturing"},
{c:311221,d: "Corn gluten feed manufacturing"},
{c:311221,d: "Corn gluten meal manufacturing"},
{c:311221,d: "Corn oil cake and meal manufacturing"},
{c:311221,d: "Corn oil mills"},
{c:311221,d: "Corn oil, crude and refined, made by wet milling corn"},
{c:311221,d: "Corn starch manufacturing"},
{c:311221,d: "Corn sweeteners (e.g., dextrose, fructose, glucose) made by wet milling corn"},
{c:311221,d: "Crude corn oil manufacturing"},
{c:311221,d: "Dextrin made by wet milling corn"},
{c:311221,d: "Gluten feed, flour, and meal, made by wet milling corn"},
{c:311221,d: "Gluten manufacturing"},
{c:311221,d: "HFCS (high fructose corn syrup) manufacturing"},
{c:311221,d: "High fructose corn syrup (HFCS) manufacturing"},
{c:311221,d: "Maltodextrins manufacturing"},
{c:311221,d: "Margarine and other corn oils made by wet milling corn"},
{c:311221,d: "Oil, corn crude and refined, made by wet milling corn"},
{c:311221,d: "Potato starches manufacturing"},
{c:311221,d: "Rice starches manufacturing"},
{c:311221,d: "Root starches manufacturing"},
{c:311221,d: "Starches (except laundry) manufacturing"},
{c:311221,d: "Steepwater concentrate manufacturing"},
{c:311221,d: "Sugar made by wet milling corn"},
{c:311221,d: "Syrup, corn, made by wet milling"},
{c:311221,d: "Table oil, corn, made by wet milling"},
{c:311221,d: "Tapioca manufacturing"},
{c:311221,d: "Vegetable starches manufacturing"},
{c:311221,d: "Wet milling corn and other vegetables"},
{c:311224,d: "Canola (rapeseed) oil, cake and meal, made in crushing mills"},
{c:311224,d: "Castor oil and pomace made in crushing mills"},
{c:311224,d: "Coconut oil made in crushing mills"},
{c:311224,d: "Cottonseed oil, cake and meal, made in crushing mills"},
{c:311224,d: "Flaxseed oil made in crushing mills"},
{c:311224,d: "Lecithin, cottonseed, made in crushing mills"},
{c:311224,d: "Lecithin, soybean, made in crushing mills"},
{c:311224,d: "Linseed oil, cake and meal, made in crushing mills"},
{c:311224,d: "Olive oil made in crushing mills"},
{c:311224,d: "Palm-kernel oil, cake, and meal made in crushing mills"},
{c:311224,d: "Peanut cake, meal, and oil made in crushing mills"},
{c:311224,d: "Rapeseed (i.e., canola) oil made in crushing mills"},
{c:311224,d: "Safflower oil made in crushing mills"},
{c:311224,d: "Shortening made in crushing mills"},
{c:311224,d: "Soybean cakes and meal manufacturing"},
{c:311224,d: "Soybean flour and grits manufacturing"},
{c:311224,d: "Soybean millfeed made in oil mills"},
{c:311224,d: "Soybean oil mills"},
{c:311224,d: "Soybean oil, cake, and meal, made in crushing mills"},
{c:311224,d: "Soybean oil, crude, manufacturing"},
{c:311224,d: "Soybean oil, deodorized, made in oil mills"},
{c:311224,d: "Soybean oil, refined, made in crushing mills"},
{c:311224,d: "Soybean protein concentrates made in crushing mills"},
{c:311224,d: "Soybean protein isolates made in crushing mills"},
{c:311224,d: "Sunflower seed oil, cake and meal, made in crushing mills"},
{c:311224,d: "Tree nut oils (e.g., tung, walnut) made in crushing mill"},
{c:311224,d: "Vegetable oils made in crushing mills"},
{c:311225,d: "Canola (rapeseed) oil, cake and meal, made from purchased oils"},
{c:311225,d: "Coconut oil made from purchased oils"},
{c:311225,d: "Cooking and baking oil sprays made from purchased oils"},
{c:311225,d: "Corn oil made from purchased oils"},
{c:311225,d: "Cottonseed oil made from purchased oils"},
{c:311225,d: "Flaxseed oil made from purchased oils"},
{c:311225,d: "Grease, inedible, animal and vegetable, refining and blending purchased oils"},
{c:311225,d: "Hydrogenating purchased oil"},
{c:311225,d: "Lecithin made from purchased oils"},
{c:311225,d: "Linseed oil made from purchased oils"},
{c:311225,d: "Margarine-butter blend made from purchased fats and oils"},
{c:311225,d: "Margarines (including imitation) made from purchased fats and oils"},
{c:311225,d: "Oil, olive, made from purchased oils"},
{c:311225,d: "Oil, vegetable stearin, made from purchased oils"},
{c:311225,d: "Olive oil made from purchased oils"},
{c:311225,d: "Palm-kernel oil made from purchased oils"},
{c:311225,d: "Peanut oil made from purchased oils"},
{c:311225,d: "Rapeseed (i.e., canola) oil made from purchased oils"},
{c:311225,d: "Safflower oil made from purchased oils"},
{c:311225,d: "Shortening made from purchased fats and oils"},
{c:311225,d: "Soybean cooking oil made from purchased oils"},
{c:311225,d: "Table oil made from purchased oils"},
{c:311225,d: "Tree nut oils (e.g., tung, walnut) made from purchased oils"},
{c:311225,d: "Vegetable oils made from purchased oils"},
{c:311230,d: "Breakfast cereals manufacturing"},
{c:311230,d: "Corn breakfast foods manufacturing"},
{c:311230,d: "Farina, breakfast cereal, manufacturing"},
{c:311230,d: "Flour mills, breakfast cereal, manufacturing"},
{c:311230,d: "Grain mills, breakfast cereal"},
{c:311230,d: "Grain, breakfast cereal, manufacturing"},
{c:311230,d: "Granola, cereal (except bars and clusters), manufacturing"},
{c:311230,d: "Hominy grits, prepared as cereal breakfast food, manufacturing"},
{c:311230,d: "Infant cereals, dry, manufacturing"},
{c:311230,d: "Instant hot cereals manufacturing"},
{c:311230,d: "Mix grain breakfast manufacturing"},
{c:311230,d: "Oatmeal (i.e., cereal breakfast food) manufacturing"},
{c:311230,d: "Oats, breakfast cereal, manufacturing"},
{c:311230,d: "Oats, rolled (i.e., cereal breakfast food), manufacturing"},
{c:311230,d: "Rice breakfast foods manufacturing"},
{c:311230,d: "Wheat breakfast cereal manufacturing"},
{c:311313,d: "Beet pulp, dried, manufacturing"},
{c:311313,d: "Beet sugar refining"},
{c:311313,d: "Brown beet sugar refining"},
{c:311313,d: "Brown sugar made from beet sugar"},
{c:311313,d: "Confectioner's beet sugar manufacturing"},
{c:311313,d: "Granulated beet sugar manufacturing"},
{c:311313,d: "Liquid beet syrup manufacturing"},
{c:311313,d: "Liquid sugar made from beet sugar"},
{c:311313,d: "Molasses made from sugar beets"},
{c:311313,d: "Raw beet sugar manufacturing"},
{c:311313,d: "Sugar, confectionery, made from sugar beets"},
{c:311313,d: "Sugar, granulated, made from sugar beets"},
{c:311313,d: "Sugar, invert, made from sugar beets"},
{c:311313,d: "Sugar, liquid, made from sugar beets"},
{c:311313,d: "Syrup made from sugar beets"},
{c:311314,d: "Blackstrap molasses manufacturing"},
{c:311314,d: "Brown sugar manufacturing"},
{c:311314,d: "Cane sugar manufacturing"},
{c:311314,d: "Cane syrup manufacturing"},
{c:311314,d: "Confectioner's powdered sugar manufacturing"},
{c:311314,d: "Granulated cane sugar manufacturing"},
{c:311314,d: "Invert sugar manufacturing"},
{c:311314,d: "Liquid sugar manufacturing"},
{c:311314,d: "Molasses manufacturing"},
{c:311314,d: "Molasses, blackstrap, manufacturing"},
{c:311314,d: "Sugar, cane, manufacturing"},
{c:311314,d: "Sugar, clarified, granulated, and raw, manufacturing"},
{c:311314,d: "Sugar, confectionery, manufacturing"},
{c:311314,d: "Sugar, granulated, manufacturing"},
{c:311314,d: "Sugar, invert, manufacturing"},
{c:311314,d: "Sugar, raw, manufacturing"},
{c:311314,d: "Sugar, refined, manufacturing"},
{c:311314,d: "Sugarcane mills"},
{c:311314,d: "Sugarcane refining"},
{c:311314,d: "Syrup, cane, manufacturing"},
{c:311340,d: "Breakfast bars, nonchocolate covered, manufacturing"},
{c:311340,d: "Cake ornaments, confectionery, manufacturing"},
{c:311340,d: "Candied fruits and fruit peel manufacturing"},
{c:311340,d: "Candy bars, nonchocolate, manufacturing"},
{c:311340,d: "Candy stores, nonchocolate, candy made on premises, not for immediate consumption"},
{c:311340,d: "Chewing gum base manufacturing"},
{c:311340,d: "Chewing gum manufacturing"},
{c:311340,d: "Confectionery, nonchocolate, manufacturing"},
{c:311340,d: "Corn confections manufacturing"},
{c:311340,d: "Cough drops (except medicated) manufacturing"},
{c:311340,d: "Crystallized fruits and fruit peel manufacturing"},
{c:311340,d: "Dates, sugared and stuffed, manufacturing"},
{c:311340,d: "Fruit peel products (e.g., candied, crystallized, glace, glazed) manufacturing"},
{c:311340,d: "Fruits (e.g., candied, crystallized, glazed) manufacturing"},
{c:311340,d: "Fudge, nonchocolate, manufacturing"},
{c:311340,d: "Granola bars and clusters, nonchocolate, manufacturing"},
{c:311340,d: "Gum, chewing, manufacturing"},
{c:311340,d: "Halvah manufacturing"},
{c:311340,d: "Hard candies manufacturing"},
{c:311340,d: "Jelly candies manufacturing"},
{c:311340,d: "Licorice candy manufacturing"},
{c:311340,d: "Lozenges, nonmedicated, candy, manufacturing"},
{c:311340,d: "Marshmallow creme manufacturing"},
{c:311340,d: "Marshmallows manufacturing"},
{c:311340,d: "Marzipan (i.e., candy) manufacturing"},
{c:311340,d: "Nuts, covered (except chocolate covered), manufacturing"},
{c:311340,d: "Popcorn balls manufacturing"},
{c:311340,d: "Popcorn, candy covered popped, manufacturing"},
{c:311340,d: "Synthetic chocolate manufacturing"},
{c:311340,d: "Toffee manufacturing"},
{c:311351,d: "Baking chocolate made from cacao beans"},
{c:311351,d: "Candy bars, chocolate (including chocolate covered), made from cacao beans"},
{c:311351,d: "Candy, chocolate, made from cacao beans"},
{c:311351,d: "Chocolate (e.g.,  coatings, instant, liquor, syrups) made from cacao beans"},
{c:311351,d: "Chocolate bars made from cocoa beans"},
{c:311351,d: "Chocolate, confectionery, made from cacao beans"},
{c:311351,d: "Coatings, chocolate, made from cacao beans"},
{c:311351,d: "Cocoa (e.g., instant, mix, mixed with other ingredients, powder drink, powdered) made from cacao beans"},
{c:311351,d: "Cocoa butter made from cocoa beans"},
{c:311351,d: "Confectionery chocolate made from cacao beans"},
{c:311351,d: "Cooking chocolate made from cacao beans"},
{c:311351,d: "Drink powdered mixes, cocoa, made from cacao"},
{c:311351,d: "Fudge, chocolate, made from cacao beans"},
{c:311351,d: "Granola bars and clusters, chocolate, made from cacao beans"},
{c:311351,d: "Liquor, chocolate, made from cacao beans"},
{c:311351,d: "Nuts, chocolate covered, made from cacao beans"},
{c:311351,d: "Syrup, chocolate, made from cacao beans"},
{c:311352,d: "Baking chocolate made from purchased chocolate"},
{c:311352,d: "Candy stores, chocolate, candy made on premises not for immediate consumption"},
{c:311352,d: "Chocolate (coating, instant, liquor, syrups) made from purchased chocolate"},
{c:311352,d: "Chocolate coatings and syrups made from purchased chocolate"},
{c:311352,d: "Chocolate covered candy bars made from purchased chocolate"},
{c:311352,d: "Chocolate covered granola bars made from purchased chocolate"},
{c:311352,d: "Cocoa, powdered drink, prepared, made from purchased chocolate"},
{c:311352,d: "Cocoa, powdered, made from purchased chocolate"},
{c:311352,d: "Cocoa, powdered, mixed with other ingredients, made from purchased chocolate"},
{c:311352,d: "Drink powdered mixes, cocoa, made from purchased cocoa"},
{c:311352,d: "Fudge, chocolate, made from purchased chocolate"},
{c:311352,d: "Granola bars and clusters, chocolate, made from purchased chocolate"},
{c:311352,d: "Liquor, chocolate, made from purchased chocolate"},
{c:311352,d: "Nuts, chocolate covered, made from purchased chocolate"},
{c:311352,d: "Syrup, chocolate, made from purchased chocolate"},
{c:311411,d: "Blast freezing on a contract basis"},
{c:311411,d: "Citrus pulp, frozen, manufacturing"},
{c:311411,d: "Concentrates, frozen fruit juice, manufacturing"},
{c:311411,d: "French fries, frozen, pre-cooked, manufacturing"},
{c:311411,d: "Frozen ades, drinks and cocktail mixes, manufacturing"},
{c:311411,d: "Frozen citrus pulp manufacturing"},
{c:311411,d: "Frozen fruit and vegetable processing"},
{c:311411,d: "Frozen fruits, fruit juices, and vegetables, manufacturing"},
{c:311411,d: "Fruit juice concentrates, frozen, manufacturing"},
{c:311411,d: "Fruits, frozen, manufacturing"},
{c:311411,d: "Juices, fruit or vegetable concentrates, frozen, manufacturing"},
{c:311411,d: "Juices, fruit or vegetable, frozen, manufacturing"},
{c:311411,d: "Vegetable juice concentrates, frozen, manufacturing"},
{c:311411,d: "Vegetables, frozen, manufacturing"},
{c:311412,d: "Chop suey, frozen, manufacturing"},
{c:311412,d: "Chow mein, frozen, manufacturing"},
{c:311412,d: "Dinners, frozen (except seafood-based), manufacturing"},
{c:311412,d: "French toast, frozen, manufacturing"},
{c:311412,d: "Frozen dinners (except seafood-based) manufacturing"},
{c:311412,d: "Frozen food entrees (except seafood-based), packaged, manufacturing"},
{c:311412,d: "Frozen pizza manufacturing"},
{c:311412,d: "Frozen pot pies manufacturing"},
{c:311412,d: "Frozen rice dishes manufacturing"},
{c:311412,d: "Frozen side dishes manufacturing"},
{c:311412,d: "Frozen soups (except seafood) manufacturing"},
{c:311412,d: "Frozen waffles manufacturing"},
{c:311412,d: "Macaroni, frozen, manufacturing"},
{c:311412,d: "Mexican foods, frozen, manufacturing"},
{c:311412,d: "Nationality specialty foods, frozen, manufacturing"},
{c:311412,d: "Pancakes, frozen, manufacturing"},
{c:311412,d: "Pizzas, frozen, manufacturing"},
{c:311412,d: "Pot pies, frozen, manufacturing"},
{c:311412,d: "Soups, frozen (except seafood), manufacturing"},
{c:311412,d: "Waffles, frozen, manufacturing"},
{c:311412,d: "Whipped topping, frozen, manufacturing"},
{c:311421,d: "Artichokes, canned, manufacturing"},
{c:311421,d: "Barbecue sauce manufacturing"},
{c:311421,d: "Berries, canned, manufacturing"},
{c:311421,d: "Beverages, fruit and vegetable juice, manufacturing"},
{c:311421,d: "Brining of fruits and vegetables"},
{c:311421,d: "Canning fruits and vegetables"},
{c:311421,d: "Canning jams and jellies"},
{c:311421,d: "Catsup manufacturing"},
{c:311421,d: "Chili sauce manufacturing"},
{c:311421,d: "Fruit brining"},
{c:311421,d: "Fruit butters manufacturing"},
{c:311421,d: "Fruit juice canning"},
{c:311421,d: "Fruit juices, fresh, manufacturing"},
{c:311421,d: "Fruit pickling"},
{c:311421,d: "Fruit pie fillings, canning"},
{c:311421,d: "Fruits pickling"},
{c:311421,d: "Fruits, canned, manufacturing"},
{c:311421,d: "Hominy, canned, manufacturing"},
{c:311421,d: "Horseradish (except sauce) canning"},
{c:311421,d: "Jellies and jams manufacturing"},
{c:311421,d: "Juices, fruit or vegetable, canned manufacturing"},
{c:311421,d: "Juices, fruit or vegetable, fresh, manufacturing"},
{c:311421,d: "Ketchup manufacturing"},
{c:311421,d: "Marmalade manufacturing"},
{c:311421,d: "Mushrooms canning"},
{c:311421,d: "Olives brined"},
{c:311421,d: "Onions pickled"},
{c:311421,d: "Pastes, fruit and vegetable, canning"},
{c:311421,d: "Pickles manufacturing"},
{c:311421,d: "Pickling fruits and vegetables"},
{c:311421,d: "Preserves (e.g., imitation) canning"},
{c:311421,d: "Relishes canning"},
{c:311421,d: "Salsa canning"},
{c:311421,d: "Sauces, tomato-based, canning"},
{c:311421,d: "Sauerkraut manufacturing"},
{c:311421,d: "Spaghetti sauce canning"},
{c:311421,d: "Vegetable brining"},
{c:311421,d: "Vegetable canning"},
{c:311421,d: "Vegetable juices canning"},
{c:311421,d: "Vegetable juices, fresh, manufacturing"},
{c:311421,d: "Vegetables pickling"},
{c:311422,d: "Baby foods (including meats) canning"},
{c:311422,d: "Baked beans canning"},
{c:311422,d: "Beans, baked, canning"},
{c:311422,d: "Bouillon canning"},
{c:311422,d: "Broth (except seafood) canning"},
{c:311422,d: "Canning soups (except seafood)"},
{c:311422,d: "Chili con carne canning"},
{c:311422,d: "Chinese foods canning"},
{c:311422,d: "Dry beans canning"},
{c:311422,d: "Gravy canning"},
{c:311422,d: "Infant and junior food canning"},
{c:311422,d: "Italian foods canning"},
{c:311422,d: "Meat canning, baby food, manufacturing"},
{c:311422,d: "Mexican foods canning"},
{c:311422,d: "Nationality specialty foods canning"},
{c:311422,d: "Pasta based products canning"},
{c:311422,d: "Pork and beans canning"},
{c:311422,d: "Soups (except seafood) canning"},
{c:311422,d: "Spaghetti canning"},
{c:311423,d: "Bouillon made in dehydration plants"},
{c:311423,d: "Dates, dried, made in dehydration plants"},
{c:311423,d: "Dehydrating fruits and vegetables"},
{c:311423,d: "Dehydrating potato products (e.g., flakes, granules)"},
{c:311423,d: "Freeze-dried, food processing, fruits and vegetables"},
{c:311423,d: "Fruit and vegetables, dehydrating, manufacturing"},
{c:311423,d: "Fruits dehydrating (except sun drying)"},
{c:311423,d: "Grapes, artificially drying"},
{c:311423,d: "Noodle mixes made in dehydration plants"},
{c:311423,d: "Olives, dried, made in dehydration plants"},
{c:311423,d: "Potato products (e.g., flakes, granules) dehydrating"},
{c:311423,d: "Prunes, dried, made in dehydration plants"},
{c:311423,d: "Raisins made in dehydration plants"},
{c:311423,d: "Rice mixes (i.e., uncooked and packaged with other ingredients) made in dehydration plants"},
{c:311423,d: "Salad dressing mixes, dry, made in dehydration plants"},
{c:311423,d: "Sauce mixes, dry, made in dehydration plants"},
{c:311423,d: "Soup mixes made in dehydration plants"},
{c:311423,d: "Sulphured fruits and vegetables manufacturing"},
{c:311423,d: "Vegetables dehydrating"},
{c:311511,d: "Acidophilus milk manufacturing"},
{c:311511,d: "Beverages, milk based (except dietary), manufacturing"},
{c:311511,d: "Buttermilk manufacturing"},
{c:311511,d: "Cheese, cottage,  manufacturing"},
{c:311511,d: "Chocolate drink (milk based) manufacturing"},
{c:311511,d: "Chocolate milk manufacturing"},
{c:311511,d: "Cottage cheese manufacturing"},
{c:311511,d: "Cream manufacturing"},
{c:311511,d: "Dips, sour cream based, manufacturing"},
{c:311511,d: "Drink, chocolate milk, manufacturing"},
{c:311511,d: "Eggnog, fresh, nonalcoholic, manufacturing"},
{c:311511,d: "Eggnog, nonalcoholic (except canned), manufacturing"},
{c:311511,d: "Flavored milk drinks manufacturing"},
{c:311511,d: "Fluid milk substitutes processing"},
{c:311511,d: "Homogenizing milk"},
{c:311511,d: "Milk based drinks (except dietary) manufacturing"},
{c:311511,d: "Milk drink, chocolate, manufacturing"},
{c:311511,d: "Milk pasteurizing"},
{c:311511,d: "Milk processing (e.g., bottling, homogenizing, pasteurizing, vitaminizing) manufacturing"},
{c:311511,d: "Milk substitutes manufacturing"},
{c:311511,d: "Milk, acidophilus, manufacturing"},
{c:311511,d: "Milk, fluid (except canned), manufacturing"},
{c:311511,d: "Nondairy creamers, liquid, manufacturing"},
{c:311511,d: "Pasteurizing milk"},
{c:311511,d: "Sour cream manufacturing"},
{c:311511,d: "Sour cream substitutes manufacturing"},
{c:311511,d: "Whipped topping (except dry mix, frozen) manufacturing"},
{c:311511,d: "Whipping cream manufacturing"},
{c:311511,d: "Yogurt (except frozen) manufacturing"},
{c:311512,d: "Anhydrous butterfat manufacturing"},
{c:311512,d: "Butter manufacturing"},
{c:311512,d: "Butter, creamery and whey, manufacturing"},
{c:311512,d: "Creamery butter manufacturing"},
{c:311512,d: "Whey butter manufacturing"},
{c:311513,d: "Cheese (except cottage cheese) manufacturing"},
{c:311513,d: "Cheese analogs manufacturing"},
{c:311513,d: "Cheese products, imitation or substitute, manufacturing"},
{c:311513,d: "Cheese spreads manufacturing"},
{c:311513,d: "Cheese, imitation or substitute, manufacturing"},
{c:311513,d: "Cheese, natural (except cottage cheese), manufacturing"},
{c:311513,d: "Curds, cheese, made in a cheese plant, manufacturing"},
{c:311513,d: "Dips, cheese based, manufacturing"},
{c:311513,d: "Processed cheeses manufacturing"},
{c:311513,d: "Spreads, cheese, manufacturing"},
{c:311513,d: "Whey, raw, liquid, manufacturing"},
{c:311514,d: "Baby formula, fresh, processed, and bottled, manufacturing"},
{c:311514,d: "Beverages, dietary, dairy and nondairy based"},
{c:311514,d: "Casein, dry and wet, manufacturing"},
{c:311514,d: "Condensed milk manufacturing"},
{c:311514,d: "Condensed, evaporated or powdered whey, manufacturing"},
{c:311514,d: "Cream, dried and powdered, manufacturing"},
{c:311514,d: "Dairy food canning"},
{c:311514,d: "Dehydrated milk manufacturing"},
{c:311514,d: "Dietary drinks, dairy and nondairy based, manufacturing"},
{c:311514,d: "Dry milk manufacturing"},
{c:311514,d: "Dry milk products and mixture manufacturing"},
{c:311514,d: "Dry milk products for animal feed manufacturing"},
{c:311514,d: "Eggnog, canned, nonalcoholic, manufacturing"},
{c:311514,d: "Evaporated milk manufacturing"},
{c:311514,d: "Feed grade dry milk products manufacturing"},
{c:311514,d: "Ice cream mix manufacturing"},
{c:311514,d: "Ice milk mix manufacturing"},
{c:311514,d: "Infant's formulas manufacturing"},
{c:311514,d: "Lactose manufacturing"},
{c:311514,d: "Malted milk manufacturing"},
{c:311514,d: "Milk based drinks, dietary, manufacturing"},
{c:311514,d: "Milk, concentrated, condensed, dried, evaporated, and powdered, manufacturing"},
{c:311514,d: "Milk, malted, manufacturing"},
{c:311514,d: "Milk, powdered, manufacturing"},
{c:311514,d: "Milk, ultra high temperature, manufacturing"},
{c:311514,d: "Milkshake mixes manufacturing"},
{c:311514,d: "Mix, ice cream, manufacturing"},
{c:311514,d: "Nondairy creamers, dry, manufacturing"},
{c:311514,d: "Nonfat dry milk manufacturing"},
{c:311514,d: "Powdered milk manufacturing"},
{c:311514,d: "UHT (ultra high temperature) milk manufacturing"},
{c:311514,d: "Whey, condensed, dried, evaporated, and powdered, manufacturing"},
{c:311514,d: "Whipped topping, dry mix, manufacturing"},
{c:311514,d: "Yogurt mix manufacturing"},
{c:311520,d: "Custard, frozen, manufacturing"},
{c:311520,d: "Desserts, frozen (except bakery), manufacturing"},
{c:311520,d: "Frozen custard manufacturing"},
{c:311520,d: "Frozen desserts (except bakery) manufacturing"},
{c:311520,d: "Fruit pops, frozen, manufacturing"},
{c:311520,d: "Ice cream manufacturing"},
{c:311520,d: "Ice cream specialties manufacturing"},
{c:311520,d: "Ice milk manufacturing"},
{c:311520,d: "Ice milk specialties manufacturing"},
{c:311520,d: "Ices, flavored sherbets, manufacturing"},
{c:311520,d: "Juice pops, frozen, manufacturing"},
{c:311520,d: "Pudding pops, frozen, manufacturing"},
{c:311520,d: "Sherbets manufacturing"},
{c:311520,d: "Tofu frozen desserts manufacturing"},
{c:311520,d: "Yogurt, frozen, manufacturing"},
{c:311611,d: "Abattoirs"},
{c:311611,d: "Animal fats (except poultry and small game) produced in slaughtering plants"},
{c:311611,d: "Bacon, slab and sliced, produced in slaughtering plants"},
{c:311611,d: "Beef carcasses, half carcasses, primal and sub-primal cuts, produced in slaughtering plants"},
{c:311611,d: "Beef produced in slaughtering plants"},
{c:311611,d: "Boxed beef produced in slaughtering plants"},
{c:311611,d: "Boxed meats produced in slaughtering plants"},
{c:311611,d: "Canned meats (except poultry) produced in slaughtering plants"},
{c:311611,d: "Cured hides and skins produced in slaughtering plants"},
{c:311611,d: "Custom slaughtering"},
{c:311611,d: "Fats, animal (except poultry, small game), produced in slaughtering plants"},
{c:311611,d: "Hams (except poultry) produced in slaughtering plants"},
{c:311611,d: "Hides and skins produced in slaughtering plants"},
{c:311611,d: "Horsemeat produced in slaughtering plants"},
{c:311611,d: "Hot dogs (except poultry) produced in slaughtering plants"},
{c:311611,d: "Inedible products (e.g., hides, skins, pulled wool, wool grease) produced in slaughtering plants"},
{c:311611,d: "Lamb carcasses, half carcasses, primal and sub-primal cuts, produced in slaughtering plants"},
{c:311611,d: "Lard produced in slaughtering plants"},
{c:311611,d: "Luncheon meat (except poultry) produced in slaughtering plants"},
{c:311611,d: "Meat canning (except poultry) produced in slaughtering plants"},
{c:311611,d: "Meats fresh, chilled or frozen (except poultry and small game), produced in slaughtering plants"},
{c:311611,d: "Meats, cured or smoked, produced in slaughtering plants"},
{c:311611,d: "Pork carcasses, half carcasses, and primal and sub-primal cuts produced in slaughtering plants"},
{c:311611,d: "Sausage casings, natural, produced in slaughtering plant"},
{c:311611,d: "Slaughtering, custom"},
{c:311611,d: "Tallow produced in a slaughtering plant"},
{c:311611,d: "Variety meats, edible organs, made in slaughtering plants"},
{c:311611,d: "Veal carcasses, half carcasses, primal and sub-primal cuts, produced in slaughtering plants"},
{c:311612,d: "Bacon, slab and sliced, made from purchased carcasses"},
{c:311612,d: "Beef stew made from purchased carcasses"},
{c:311612,d: "Beef, primal and sub-primal cuts, made from purchased carcasses"},
{c:311612,d: "Bologna made from purchased carcasses"},
{c:311612,d: "Boxed beef made from purchased carcasses"},
{c:311612,d: "Boxed meat produced from purchased carcasses"},
{c:311612,d: "Collagen sausage casings made from purchased hides"},
{c:311612,d: "Cooked meats made from purchased carcasses"},
{c:311612,d: "Corned meats made from purchases carcasses"},
{c:311612,d: "Cured meats (e.g., brined, dried, and salted) made from purchased carcasses"},
{c:311612,d: "Dried meats made from purchased carcasses"},
{c:311612,d: "Frozen meat pies (i.e., tourtires) made from purchased carcasses"},
{c:311612,d: "Hams, canned, made from purchased carcasses"},
{c:311612,d: "Hams, preserved (except poultry), made from purchased carcasses"},
{c:311612,d: "Hot dogs (except poultry) made from purchased carcasses"},
{c:311612,d: "Lamb, primal and sub-primal cuts, made from purchased carcasses"},
{c:311612,d: "Luncheon meat (except poultry) made from purchased carcasses"},
{c:311612,d: "Meat canning (except baby, pet food, poultry), made from purchased carcasses"},
{c:311612,d: "Meat extracts made from purchased carcasses"},
{c:311612,d: "Meat products canning (except baby, pet food, poultry) made from purchased carcasses"},
{c:311612,d: "Meats (except poultry), cured or smoked, made from purchased carcasses"},
{c:311612,d: "Meats, fresh or chilled  (except poultry and small game), frozen, made from purchased carcasses"},
{c:311612,d: "Pastrami made from purchased carcasses"},
{c:311612,d: "Pig's feet, cooked and pickled, made from purchased carcasses"},
{c:311612,d: "Pork, primal and sub-primal cuts, made from purchased carcasses"},
{c:311612,d: "Potted meats made from purchased carcasses"},
{c:311612,d: "Processed meats manufacturing"},
{c:311612,d: "Salted meats made from purchased carcasses"},
{c:311612,d: "Sandwich spreads, meat, made from purchased carcasses"},
{c:311612,d: "Sausage and similar cased products made from purchased carcasses"},
{c:311612,d: "Sausage casings, collagen, made from purchased hides"},
{c:311612,d: "Smoked meats made from purchased carcasses"},
{c:311612,d: "Variety meats, edible organs, made from purchased meats"},
{c:311612,d: "Veal, primal and sub-primal cuts, made from purchased carcasses"},
{c:311613,d: "Animal fats rendering"},
{c:311613,d: "Animal oil rendering"},
{c:311613,d: "Bones, fat, rendering"},
{c:311613,d: "Fats, animal, rendering"},
{c:311613,d: "Grease rendering"},
{c:311613,d: "Lard made from purchased fat"},
{c:311613,d: "Meat and bone meal and tankage, produced in rendering plant"},
{c:311613,d: "Neatsfoot oil rendering"},
{c:311613,d: "Oil, animal, rendering"},
{c:311613,d: "Rendering animals (carrion) for feed"},
{c:311613,d: "Rendering fats"},
{c:311613,d: "Rendering plants"},
{c:311613,d: "Stearin, animal, rendering"},
{c:311613,d: "Tallow produced in rendering plant"},
{c:311615,d: "Canning poultry (except baby and pet food)"},
{c:311615,d: "Chickens, processing, fresh, frozen, canned, or cooked (except baby and pet food)"},
{c:311615,d: "Chickens, slaughtering and dressing"},
{c:311615,d: "Dressing small game"},
{c:311615,d: "Ducks, processing, fresh, frozen, canned, or cooked"},
{c:311615,d: "Ducks, slaughtering and dressing"},
{c:311615,d: "Geese, processing, fresh, frozen, canned, or cooked"},
{c:311615,d: "Geese, slaughtering and dressing"},
{c:311615,d: "Hams, poultry, manufacturing"},
{c:311615,d: "Hot dogs, poultry, manufacturing"},
{c:311615,d: "Luncheon meat, poultry, manufacturing"},
{c:311615,d: "Meat canning, poultry (except baby and pet food), manufacturing"},
{c:311615,d: "Meat products (e.g., hot dogs, luncheon meats, sausages) made from a combination of poultry and other meats"},
{c:311615,d: "Poultry (e.g., canned, cooked, fresh, frozen) manufacturing"},
{c:311615,d: "Poultry (e.g., canned, cooked, fresh, frozen) processing"},
{c:311615,d: "Poultry canning (except baby, pet food)"},
{c:311615,d: "Poultry slaughtering, dressing, and packing"},
{c:311615,d: "Processed poultry manufacturing"},
{c:311615,d: "Rabbits processing (i.e., canned, cooked, fresh, frozen)"},
{c:311615,d: "Rabbits slaughtering and dressing"},
{c:311615,d: "Small game, processing, fresh, frozen, canned or cooked"},
{c:311615,d: "Small game, slaughtering, dressing and packing"},
{c:311615,d: "Turkeys, processing, fresh, frozen, canned, or cooked"},
{c:311615,d: "Turkeys, slaughtering and dressing"},
{c:311710,d: "Cannery, seafood"},
{c:311710,d: "Canning, fish, crustacea, and mollusks"},
{c:311710,d: "Chowders, seafood, manufacturing"},
{c:311710,d: "Cod liver oil extraction, crude, processing"},
{c:311710,d: "Curing fish and seafood"},
{c:311710,d: "Dinners, frozen seafood, manufacturing"},
{c:311710,d: "Drying fish and seafood"},
{c:311710,d: "Fish and marine animal oils processing"},
{c:311710,d: "Fish egg bait canning"},
{c:311710,d: "Fish freezing (e.g., blocks, fillets, ready-to-serve products)"},
{c:311710,d: "Fish manufacturing"},
{c:311710,d: "Fish meal processing"},
{c:311710,d: "Fish, curing, drying, pickling, salting, and smoking"},
{c:311710,d: "Floating factory ships, seafood processing"},
{c:311710,d: "Freezing fish (e.g., blocks, fillets, ready-to-serve products)"},
{c:311710,d: "Picking crab meat"},
{c:311710,d: "Seafood and seafood products canning"},
{c:311710,d: "Seafood and seafood products curing"},
{c:311710,d: "Seafood and seafood products manufacturing"},
{c:311710,d: "Seafood dinners, frozen, manufacturing"},
{c:311710,d: "Seaweed processing (e.g., dulse)"},
{c:311710,d: "Shellfish and shellfish products canning"},
{c:311710,d: "Shellfish and shellfish products manufacturing"},
{c:311710,d: "Shellfish curing"},
{c:311710,d: "Shucking and packing fresh shellfish"},
{c:311710,d: "Soups, seafood, manufacturing"},
{c:311710,d: "Surimi manufacturing"},
{c:311811,d: "Bakeries with baking from flour on the premises, retailing not for immediate consumption"},
{c:311812,d: "Bagels made in commercial bakeries"},
{c:311812,d: "Bakery products, fresh (i.e., bread, cakes, doughnuts, pastries), made in commercial bakeries"},
{c:311812,d: "Biscuits, bread-type, made in commercial bakeries"},
{c:311812,d: "Bread and bread-type rolls made in commercial bakeries"},
{c:311812,d: "Cakes, baking (except frozen), made in commercial bakeries"},
{c:311812,d: "Commercial bakeries"},
{c:311812,d: "Communion wafer manufacturing"},
{c:311812,d: "Croissants, baking, made in commercial bakeries"},
{c:311812,d: "Croutons and bread crumbs made in commercial bakeries"},
{c:311812,d: "Crullers (except frozen) made in commercial bakeries"},
{c:311812,d: "Doughnuts (except frozen) made in commercial bakeries"},
{c:311812,d: "Frozen bread and bread-type rolls, made in commercial bakeries"},
{c:311812,d: "Knishes (except frozen) made in commercial bakeries"},
{c:311812,d: "Matzo baking made in commercial bakeries"},
{c:311812,d: "Pastries (e.g., Danish, French), fresh, made in commercial bakeries"},
{c:311812,d: "Pies, fresh, made in commercial bakeries"},
{c:311812,d: "Pretzels, soft, manufacturing"},
{c:311812,d: "Rolls and buns (including frozen) made in commercial bakeries"},
{c:311812,d: "Soft pretzels made in a commercial bakery"},
{c:311812,d: "Sweet yeast goods (except frozen) manufacturing"},
{c:311812,d: "Unleavened bread made in commercial bakeries"},
{c:311813,d: "Baked goods (except bread, bread-type rolls), frozen, manufacturing"},
{c:311813,d: "Cake, frozen, manufacturing"},
{c:311813,d: "Crullers, frozen, made in a commercial bakery"},
{c:311813,d: "Desserts, frozen bakery, manufacturing"},
{c:311813,d: "Doughnuts, frozen, manufacturing"},
{c:311813,d: "Frozen cake manufacturing"},
{c:311813,d: "Knishes, frozen, manufacturing"},
{c:311813,d: "Pastries (e.g., Danish, French), frozen, manufacturing"},
{c:311813,d: "Pies, frozen, manufacturing"},
{c:311813,d: "Sweet yeast goods, frozen, manufacturing"},
{c:311821,d: "Bakery products, dry (e.g., biscuits, cookies, crackers), manufacturing"},
{c:311821,d: "Cones, ice cream, manufacturing"},
{c:311821,d: "Cookies manufacturing"},
{c:311821,d: "Cookies, filled, manufacturing"},
{c:311821,d: "Crackers (e.g., graham, soda) manufacturing"},
{c:311821,d: "Graham wafers manufacturing"},
{c:311821,d: "Ice cream cones manufacturing"},
{c:311821,d: "Saltines manufacturing"},
{c:311821,d: "Soda crackers manufacturing"},
{c:311824,d: "Batters, prepared, made from purchased flour"},
{c:311824,d: "Bread and bread-type roll mixes made from purchased flour"},
{c:311824,d: "Cake mixes made from purchased flour"},
{c:311824,d: "Cookie dough made from purchased flour"},
{c:311824,d: "Doughs, refrigerated or frozen, made from purchased flour"},
{c:311824,d: "Dry mixes made from purchased flour"},
{c:311824,d: "Dry pasta manufacturing"},
{c:311824,d: "Dry pasta packaged with other ingredients made in dry pasta plants"},
{c:311824,d: "Egg noodles, dry, manufacturing"},
{c:311824,d: "Flour, blended or self-rising, made from purchased flour"},
{c:311824,d: "Frozen doughs made from purchased flour"},
{c:311824,d: "Macaroni, dry, manufacturing"},
{c:311824,d: "Mixes, flour (e.g., biscuit, cake, doughnut, pancake), made from purchased flour"},
{c:311824,d: "Noodle mixes made in dry pasta plants"},
{c:311824,d: "Noodles, dry, manufacturing"},
{c:311824,d: "Pancake mixes made from purchased flour"},
{c:311824,d: "Pasta, dry, manufacturing"},
{c:311824,d: "Pastries, uncooked, manufacturing"},
{c:311824,d: "Pie crust shells, uncooked, made from purchased flour"},
{c:311824,d: "Pizza doughs made from purchased flour"},
{c:311824,d: "Prepared flour mixes made from purchased flour"},
{c:311824,d: "Refrigerated doughs made from purchased flour"},
{c:311824,d: "Spaghetti, dry, manufacturing"},
{c:311830,d: "Tortillas manufacturing"},
{c:311911,d: "Canned nuts manufacturing"},
{c:311911,d: "Nuts, kernels and seeds, roasting and processing"},
{c:311911,d: "Nuts, salted, roasted, cooked, canned, manufacturing"},
{c:311911,d: "Peanut butter blended with jelly manufacturing"},
{c:311911,d: "Peanut butter manufacturing"},
{c:311911,d: "Roasted nuts and seeds manufacturing"},
{c:311911,d: "Seeds, snack (e.g., canned, cooked, roasted, salted) manufacturing"},
{c:311919,d: "Cheese curls and puffs manufacturing"},
{c:311919,d: "Corn chips and related corn snacks manufacturing"},
{c:311919,d: "Popcorn (except candy covered), popped, manufacturing"},
{c:311919,d: "Pork rinds manufacturing"},
{c:311919,d: "Potato chips manufacturing"},
{c:311919,d: "Potato sticks manufacturing"},
{c:311919,d: "Pretzels (except soft) manufacturing"},
{c:311919,d: "Tortilla chips manufacturing"},
{c:311920,d: "Coffee concentrates (i.e., instant coffee) manufacturing"},
{c:311920,d: "Coffee extracts manufacturing"},
{c:311920,d: "Coffee flavoring and syrups (i.e., made from coffee) manufacturing"},
{c:311920,d: "Coffee roasting"},
{c:311920,d: "Coffee substitute manufacturing"},
{c:311920,d: "Coffee, blended, manufacturing"},
{c:311920,d: "Coffee, instant and freeze-dried, manufacturing"},
{c:311920,d: "Extracts, essences and preparations, coffee, manufacturing"},
{c:311920,d: "Extracts, essences and preparations, tea, manufacturing"},
{c:311920,d: "Instant coffee manufacturing"},
{c:311920,d: "Instant tea manufacturing"},
{c:311920,d: "Roasting coffee"},
{c:311920,d: "Syrup, flavoring, coffee based, manufacturing"},
{c:311920,d: "Tea (except herbal) manufacturing"},
{c:311920,d: "Tea blending"},
{c:311920,d: "Tea, herbal, manufacturing"},
{c:311920,d: "Tea, instant, manufacturing"},
{c:311930,d: "Beverage bases manufacturing"},
{c:311930,d: "Beverage flavorings (except coffee based) manufacturing"},
{c:311930,d: "Beverage syrups (except coffee based) manufacturing"},
{c:311930,d: "Concentrates, drink (except frozen fruit juice), manufacturing"},
{c:311930,d: "Concentrates, flavoring (except coffee based), manufacturing"},
{c:311930,d: "Flavoring concentrates (except coffee based) manufacturing"},
{c:311930,d: "Flavoring pastes, powders, and syrups for soft drink manufacturing"},
{c:311930,d: "Fruit syrups, flavoring, manufacturing"},
{c:311930,d: "Soda fountain syrups manufacturing"},
{c:311930,d: "Soft drink concentrates (i.e., syrup) manufacturing"},
{c:311930,d: "Syrup, beverage, manufacturing"},
{c:311930,d: "Syrup, flavoring (except coffee based), manufacturing"},
{c:311941,d: "Cheese based salad dressing manufacturing"},
{c:311941,d: "Cider vinegar manufacturing"},
{c:311941,d: "Cider, nonalcoholic, manufacturing"},
{c:311941,d: "Dips (except cheese and sour cream based) manufacturing"},
{c:311941,d: "Horseradish, prepared sauce, manufacturing"},
{c:311941,d: "Mayonnaise manufacturing"},
{c:311941,d: "Mustard, prepared, manufacturing"},
{c:311941,d: "Prepared sauces (except gravy, tomato-based) manufacturing"},
{c:311941,d: "Salad dressings manufacturing"},
{c:311941,d: "Sandwich spreads, salad dressing based, manufacturing"},
{c:311941,d: "Sauces (except tomato-based) manufacturing"},
{c:311941,d: "Sauces for meat (except tomato-based) manufacturing"},
{c:311941,d: "Sauces for seafood (except tomato-based) manufacturing"},
{c:311941,d: "Sauces for vegetable (except tomato-based) manufacturing"},
{c:311941,d: "Soy sauce manufacturing"},
{c:311941,d: "Tartar sauce manufacturing"},
{c:311941,d: "Vinegar manufacturing"},
{c:311941,d: "Worcestershire sauce manufacturing"},
{c:311942,d: "Cake frosting mixes manufacturing"},
{c:311942,d: "Chili pepper or powder manufacturing"},
{c:311942,d: "Extracts, food (except coffee, meat), manufacturing"},
{c:311942,d: "Extracts, malt, manufacturing"},
{c:311942,d: "Flavor extracts (except coffee) manufacturing"},
{c:311942,d: "Food coloring, natural, manufacturing"},
{c:311942,d: "Food extracts (except coffee, meat) manufacturing"},
{c:311942,d: "Fruit extracts manufacturing"},
{c:311942,d: "Gravy mixes, dry, manufacturing"},
{c:311942,d: "Grinding spices"},
{c:311942,d: "Hop extract manufacturing"},
{c:311942,d: "Malt extract and syrups manufacturing"},
{c:311942,d: "Pectin manufacturing"},
{c:311942,d: "Pepper (i.e., spice) manufacturing"},
{c:311942,d: "Salad dressing mixes, dry, manufacturing"},
{c:311942,d: "Salt substitute manufacturing"},
{c:311942,d: "Salt, table, manufacturing"},
{c:311942,d: "Sauce mixes, dry, manufacturing"},
{c:311942,d: "Seasoning salt manufacturing"},
{c:311942,d: "Spice grinding and blending"},
{c:311942,d: "Spice mixtures manufacturing"},
{c:311942,d: "Spices and spice mix manufacturing"},
{c:311942,d: "Table salt manufacturing"},
{c:311991,d: "Box lunches (for sale off premises) manufacturing"},
{c:311991,d: "Carrots, cut, peeled or sliced fresh, manufacturing"},
{c:311991,d: "Cole slaw, fresh, manufacturing"},
{c:311991,d: "Egg noodles, fresh, manufacturing"},
{c:311991,d: "Food, prepared, perishable, packaged for individual resale"},
{c:311991,d: "Fruit, cut or peeled, fresh, manufacturing"},
{c:311991,d: "Macaroni, fresh, manufacturing"},
{c:311991,d: "Noodles, fresh, manufacturing"},
{c:311991,d: "Pasta, fresh, manufacturing"},
{c:311991,d: "Pizzas, fresh, manufacturing"},
{c:311991,d: "Potatoes, peeled or cut, manufacturing"},
{c:311991,d: "Prepared meals, perishable, packaged for individual resale"},
{c:311991,d: "Salads, fresh or refrigerated, manufacturing"},
{c:311991,d: "Sandwiches, fresh (i.e., assembled and packaged for wholesale market), manufacturing"},
{c:311991,d: "Slaw, cole, fresh, manufacturing"},
{c:311991,d: "Tofu (i.e., bean curd) (except frozen desserts) manufacturing"},
{c:311991,d: "Vegetables, cut or peeled, fresh, manufacturing"},
{c:311999,d: "Almond pastes manufacturing"},
{c:311999,d: "Baking powder manufacturing"},
{c:311999,d: "Bread crumbs not made in bakeries"},
{c:311999,d: "Cake frosting manufacturing"},
{c:311999,d: "Chinese noodles, fried, manufacturing"},
{c:311999,d: "Cocktail mixes, dry, manufacturing"},
{c:311999,d: "Coconut, desiccated and shredded, manufacturing"},
{c:311999,d: "Corn syrups made from purchased sweeteners"},
{c:311999,d: "Drink powder mixes (except chocolate, coffee, milk based, tea) manufacturing"},
{c:311999,d: "Egg substitutes manufacturing"},
{c:311999,d: "Eggs, processed, manufacturing"},
{c:311999,d: "Fillings, cake or pie (except fruits, meat, vegetables), manufacturing"},
{c:311999,d: "Frosting, prepared, manufacturing"},
{c:311999,d: "Gelatin dessert preparations manufacturing"},
{c:311999,d: "Gelatin for cooking manufacturing"},
{c:311999,d: "Honey processing"},
{c:311999,d: "Maple syrup mixing into other products"},
{c:311999,d: "Noodle mixes made from purchased dry ingredients"},
{c:311999,d: "Noodles, fried, manufacturing"},
{c:311999,d: "Pancake syrups (except pure maple) manufacturing"},
{c:311999,d: "Pasta mixes made from purchased dry ingredients"},
{c:311999,d: "Popcorn (except popped) manufacturing"},
{c:311999,d: "Potato mixes made from purchased dry ingredients"},
{c:311999,d: "Powdered drink mixes (except chocolate, coffee, tea, milk based) manufacturing"},
{c:311999,d: "Powders, baking, manufacturing"},
{c:311999,d: "Puddings, canned dessert, manufacturing"},
{c:311999,d: "Puddings, dessert, manufacturing"},
{c:311999,d: "Rice mixes (i.e., uncooked and packaged with other ingredients) made from purchased rice and dry ingredients"},
{c:311999,d: "Sorghum syrup manufacturing"},
{c:311999,d: "Soup mixes, dry, made from purchased dry ingredients"},
{c:311999,d: "Sweetening syrups (except pure maple) manufacturing"},
{c:311999,d: "Syrup, corn (except wet milled), manufacturing"},
{c:311999,d: "Syrup, sweetening (except pure maple), manufacturing"},
{c:311999,d: "Syrup, table, artificially flavored, manufacturing"},
{c:311999,d: "Yeast manufacturing"},
{c:312111,d: "Artificially carbonated waters manufacturing"},
{c:312111,d: "Beverages, fruit and vegetable drinks, cocktails, and ades, manufacturing"},
{c:312111,d: "Beverages, soft drink (including artificially carbonated waters), manufacturing"},
{c:312111,d: "Carbonated soda manufacturing"},
{c:312111,d: "Carbonated soft drinks manufacturing"},
{c:312111,d: "Coffee, iced, manufacturing"},
{c:312111,d: "Drinks, fruit (except juice), manufacturing"},
{c:312111,d: "Flavored water manufacturing"},
{c:312111,d: "Fruit drinks (except juice), manufacturing"},
{c:312111,d: "Iced coffee manufacturing"},
{c:312111,d: "Iced tea manufacturing"},
{c:312111,d: "Pop, soda, manufacturing"},
{c:312111,d: "Soda carbonated, manufacturing"},
{c:312111,d: "Soda pop manufacturing"},
{c:312111,d: "Soft drinks manufacturing"},
{c:312111,d: "Tea, iced, manufacturing"},
{c:312111,d: "Water, artificially carbonated, manufacturing"},
{c:312111,d: "Water, flavored, manufacturing"},
{c:312112,d: "Beverages, naturally carbonated bottled water, manufacturing"},
{c:312112,d: "Naturally carbonated water, purifying and bottling"},
{c:312112,d: "Spring waters, purifying and bottling"},
{c:312112,d: "Water, naturally carbonated, purifying and bottling"},
{c:312113,d: "Block ice manufacturing"},
{c:312113,d: "Ice (except dry ice) manufacturing"},
{c:312120,d: "Ale brewing"},
{c:312120,d: "Beer brewing"},
{c:312120,d: "Beverages, beer, ale, and malt liquors, manufacturing"},
{c:312120,d: "Breweries"},
{c:312120,d: "Grain, brewers' spent, manufacturing"},
{c:312120,d: "Lager brewing"},
{c:312120,d: "Malt liquor brewing"},
{c:312120,d: "Near beer brewing"},
{c:312120,d: "Nonalcoholic beer brewing"},
{c:312120,d: "Porter brewing"},
{c:312120,d: "Stout brewing"},
{c:312130,d: "Alcoholic beverages, brandy, distilling"},
{c:312130,d: "Applejack distilling"},
{c:312130,d: "Beverages, wines and brandies, manufacturing"},
{c:312130,d: "Blending brandy"},
{c:312130,d: "Blending wines"},
{c:312130,d: "Brandy distilling"},
{c:312130,d: "Champagne method sparkling wine, manufacturing"},
{c:312130,d: "Cider, alcoholic, manufacturing"},
{c:312130,d: "Distilling brandy"},
{c:312130,d: "Fortified wines manufacturing"},
{c:312130,d: "Grape farming and making wine"},
{c:312130,d: "Ice wine"},
{c:312130,d: "Liquors, brandy, distilling and blending"},
{c:312130,d: "Nonalcoholic wines manufacturing"},
{c:312130,d: "Sake manufacturing"},
{c:312130,d: "Sparkling wines manufacturing"},
{c:312130,d: "Vermouth manufacturing"},
{c:312130,d: "Wine coolers manufacturing"},
{c:312130,d: "Wineries"},
{c:312130,d: "Wines manufacturing"},
{c:312130,d: "Wines, cooking, manufacturing"},
{c:312140,d: "Alcoholic beverages (except brandy) distilling"},
{c:312140,d: "Beverages, liquors (except brandies), manufacturing"},
{c:312140,d: "Blending distilled beverages (except brandy)"},
{c:312140,d: "Distilleries"},
{c:312140,d: "Distilling alcoholic beverages (except brandy)"},
{c:312140,d: "Distilling potable liquor (except brandy)"},
{c:312140,d: "Eggnog, alcoholic, manufacturing"},
{c:312140,d: "Ethyl alcohol, potable, manufacturing"},
{c:312140,d: "Grain alcohol, beverage, manufacturing"},
{c:312140,d: "Liquors, distilling and blending (except brandy)"},
{c:312140,d: "Mixed drinks, alcoholic, manufacturing"},
{c:312140,d: "Neutral spirit, beverages (except fruit), manufacturing"},
{c:312140,d: "Spirits, distilled (except brandy), manufacturing"},
{c:312230,d: "Chewing tobacco manufacturing"},
{c:312230,d: "Cigar manufacturing"},
{c:312230,d: "Cigarettes manufacturing"},
{c:312230,d: "Imitation tobacco cigarettes, manufacturing"},
{c:312230,d: "Pipe tobacco, prepared, manufacturing"},
{c:312230,d: "Reconstituting tobacco"},
{c:312230,d: "Smoking tobacco (e.g., cigarette, pipe) manufacturing"},
{c:312230,d: "Snuff manufacturing"},
{c:312230,d: "Tobacco leaf processing and aging"},
{c:312230,d: "Tobacco products (e.g., chewing, smoking, snuff) manufacturing"},
{c:312230,d: "Tobacco products, imitation, manufacturing"},
{c:312230,d: "Tobacco sheeting services"},
{c:312230,d: "Tobacco stemming and redrying"},
{c:313110,d: "Acetate spun yarns made from purchased fiber"},
{c:313110,d: "Acrylic and modacrylic filament yarn throwing, twisting, texturizing, or winding purchased yarn"},
{c:313110,d: "Acrylic spun yarns made from purchased fiber"},
{c:313110,d: "Animal fiber yarn twisting or winding of purchased yarn"},
{c:313110,d: "Bags, hemp, made from purchased fiber"},
{c:313110,d: "Beaming yarn"},
{c:313110,d: "Carded yarn manufacturing"},
{c:313110,d: "Carpet and rug yarn spinning"},
{c:313110,d: "Cotton cordage spun yarns made from purchased fiber"},
{c:313110,d: "Cotton spun yarns made from purchased fiber"},
{c:313110,d: "Cotton thread manufacturing"},
{c:313110,d: "Crochet spun yarns (e.g., cotton, manmade fiber, silk, wool) made from purchased fiber"},
{c:313110,d: "Darning thread (e.g., cotton, manmade fibers, silk, wool) manufacturing"},
{c:313110,d: "Embroidery spun yarns (e.g., cotton, manmade fiber, silk, wool) made from purchased fiber"},
{c:313110,d: "Embroidery thread (e.g., cotton, manmade fibers, silk, wool) manufacturing"},
{c:313110,d: "Flax spun yarns made from purchased fiber"},
{c:313110,d: "Hard fiber spun yarns made from purchased fiber"},
{c:313110,d: "Hard fiber thread manufacturing"},
{c:313110,d: "Hemp bags made from purchased fiber"},
{c:313110,d: "Hemp ropes made from purchased fiber"},
{c:313110,d: "Hemp spun yarns made from purchased fiber"},
{c:313110,d: "Knitting and crocheting thread manufacturing"},
{c:313110,d: "Knitting spun yarns (e.g., cotton, manmade fiber, silk, wool) made from purchased fiber"},
{c:313110,d: "Manmade fiber thread manufacturing"},
{c:313110,d: "Manmade staple spun yarns made from purchased fiber"},
{c:313110,d: "Modacrylic spun yarns made from purchased fiber"},
{c:313110,d: "Mohair yarn twisting or winding of purchased yarn"},
{c:313110,d: "Natural fiber (i.e., hemp, linen, ramie) thread manufacturing"},
{c:313110,d: "Natural fiber spun yarns (i.e., hemp, jute, ramie, flax) made from purchased fiber"},
{c:313110,d: "Nylon spun yarns made from purchased fiber"},
{c:313110,d: "Nylon thread manufacturing"},
{c:313110,d: "Nylon yarn twisting or winding of purchased yarn"},
{c:313110,d: "Paper yarn manufacturing"},
{c:313110,d: "Polyester filament yarn throwing, twisting, texturizing, or winding of purchased yarn"},
{c:313110,d: "Polyester spun yarns made from purchased fiber"},
{c:313110,d: "Polyester thread manufacturing"},
{c:313110,d: "Polypropylene filament yarn throwing, twisting, texturizing, or winding of puchased yarn"},
{c:313110,d: "Polypropylene spun yarns made from purchased fiber"},
{c:313110,d: "Ramie spun yarns made from purchased fiber"},
{c:313110,d: "Rayon spun yarns made from purchased fiber"},
{c:313110,d: "Rayon thread manufacturing"},
{c:313110,d: "Rayon yarn throwing, twisting, texturizing, or winding purchased filament"},
{c:313110,d: "Sewing threads manufacturing"},
{c:313110,d: "Silk spun yarns made from purchased fiber"},
{c:313110,d: "Silk thread manufacturing"},
{c:313110,d: "Silk throwing, spooling, twisting, or winding of purchased yarn"},
{c:313110,d: "Spinning carpet and rug yarn from purchased fiber"},
{c:313110,d: "Spinning yarn from purchased fiber"},
{c:313110,d: "Spooling of yarn"},
{c:313110,d: "Spooling of yarns for the trade"},
{c:313110,d: "Texturizing purchased yarn"},
{c:313110,d: "Thread mills"},
{c:313110,d: "Thread, all fibers, manufacturing"},
{c:313110,d: "Throwing purchased yarn"},
{c:313110,d: "Winding purchased yarn"},
{c:313110,d: "Winding, spooling, beaming and rewinding of purchased yarn"},
{c:313110,d: "Wool spun yarn made from purchased fiber"},
{c:313110,d: "Wool yarn, twisting or winding of purchased yarn"},
{c:313110,d: "Yarn spinning mills"},
{c:313110,d: "Yarn spun from purchased fiber"},
{c:313110,d: "Yarn throwing, twisting, and winding of purchased yarn"},
{c:313110,d: "Yarn, carpet and rug, spun from purchased fiber"},
{c:313210,d: "Blankets and bedspreads made in broadwoven fabric mills"},
{c:313210,d: "Broadwoven fabrics (except rugs, tire fabrics) weaving"},
{c:313210,d: "Brocades weaving"},
{c:313210,d: "Canvases weaving"},
{c:313210,d: "Carpet linings (except felt) weaving"},
{c:313210,d: "Chambrays weaving"},
{c:313210,d: "Cheesecloths weaving"},
{c:313210,d: "Chintzes weaving"},
{c:313210,d: "Corduroys weaving"},
{c:313210,d: "Cotton fabrics, broadwoven, weaving"},
{c:313210,d: "Curtains and draperies made in broadwoven fabric mills"},
{c:313210,d: "Denims weaving"},
{c:313210,d: "Drills weaving"},
{c:313210,d: "Ducks weaving"},
{c:313210,d: "Elastic fabrics, more than 12 inches in width, weaving"},
{c:313210,d: "Fabrics (except rug, tire fabrics), broadwoven, weaving"},
{c:313210,d: "Felts, broadwoven, weaving"},
{c:313210,d: "Fiberglass fabrics weaving"},
{c:313210,d: "Flannels, broadwoven, weaving"},
{c:313210,d: "Gauzes, surgical, made in broadwoven fabric mills"},
{c:313210,d: "Glass broadwoven fabrics weaving"},
{c:313210,d: "Hand weaving fabrics, more than 12 inches (30 cm) in width"},
{c:313210,d: "Hard fiber fabrics, broadwoven, weaving"},
{c:313210,d: "Jacquard woven fabrics weaving"},
{c:313210,d: "Jute bags made in broadwoven mills"},
{c:313210,d: "Manmade fabrics, broadwoven, weaving"},
{c:313210,d: "Natural fiber fabrics (i.e., jute, linen, hemp, ramie), broadwoven, weaving"},
{c:313210,d: "Nets and nettings, more than 12 inches in width, weaving"},
{c:313210,d: "Paper fabrics, broadwoven, weaving"},
{c:313210,d: "Percales weaving"},
{c:313210,d: "Poplins weaving"},
{c:313210,d: "Sheets and pillowcases made in broadwoven fabric mills"},
{c:313210,d: "Silk fabrics, broadwoven, weaving"},
{c:313210,d: "Spandex broadwoven fabrics"},
{c:313210,d: "Tablecloths made in broadwoven fabric mills"},
{c:313210,d: "Terry broadwoven fabrics weaving"},
{c:313210,d: "Textile broadwoven fabrics mills"},
{c:313210,d: "Textile mills, broadwoven fabrics"},
{c:313210,d: "Textile products (except apparel) made in broadwoven fabric mills"},
{c:313210,d: "Towels and washcloths made in broadwoven fabric mills"},
{c:313210,d: "Twills weaving"},
{c:313210,d: "Velvets, manmade fiber and silk, weaving"},
{c:313210,d: "Weaving and finishing of broadwoven fabrics (except rugs, tire fabric)"},
{c:313210,d: "Weaving broadwoven fabrics (except rugs, tire fabrics)"},
{c:313210,d: "Weaving broadwoven felts"},
{c:313210,d: "Weaving fabrics more than 12 inches (30cm) in width"},
{c:313210,d: "Wool fabrics, broadwoven, weaving"},
{c:313210,d: "Worsted fabrics weaving"},
{c:313220,d: "Apparel webbings manufacturing"},
{c:313220,d: "Belting fabrics, narrow woven"},
{c:313220,d: "Bias bindings, woven, manufacturing"},
{c:313220,d: "Bindings, narrow woven, manufacturing"},
{c:313220,d: "Braiding narrow fabrics"},
{c:313220,d: "Cords and braids, narrow woven, manufacturing"},
{c:313220,d: "Cotton fabrics, narrow woven weaving"},
{c:313220,d: "Elastic fabrics, narrow woven, manufacturing"},
{c:313220,d: "Embroideries, Schiffli machine, manufacturing"},
{c:313220,d: "Fabrics, narrow woven, weaving"},
{c:313220,d: "Fiberglasses, narrow woven, weaving"},
{c:313220,d: "Fringes weaving"},
{c:313220,d: "Glass fabrics, narrow woven weaving"},
{c:313220,d: "Hand weaving fabric, 12 inches or less (30cm)"},
{c:313220,d: "Hard fiber, narrow woven, weaving"},
{c:313220,d: "Hook and loop fastener fabric manufacturing"},
{c:313220,d: "Hose fabrics, tubular, weaving"},
{c:313220,d: "Labels weaving"},
{c:313220,d: "Laces (e.g., shoe), textile, manufacturing"},
{c:313220,d: "Manmade fabric, narrow woven, weaving"},
{c:313220,d: "Narrow fabrics weaving"},
{c:313220,d: "Natural fiber fabrics (i.e., jute, linen, hemp, ramie), narrow woven, weaving"},
{c:313220,d: "Paper fabric, narrow woven, weaving"},
{c:313220,d: "Ribbons made in narrow woven fabric mills"},
{c:313220,d: "Rubber thread and yarns, fabric covered, manufacturing"},
{c:313220,d: "Schiffli machine embroideries manufacturing"},
{c:313220,d: "Tapes weaving"},
{c:313220,d: "Textile mills, narrow woven fabric"},
{c:313220,d: "Textile narrow woven fabric mills"},
{c:313220,d: "Textile products (except apparel) made in narrow woven fabric mills"},
{c:313220,d: "Weaving and finishing narrow fabrics"},
{c:313220,d: "Weaving fabric less than 12 inches (30cm)"},
{c:313220,d: "Weaving narrow fabrics"},
{c:313220,d: "Webbing weaving"},
{c:313220,d: "Wicks manufacturing"},
{c:313220,d: "Wool fabrics, narrow woven, weaving"},
{c:313220,d: "Zipper tape weaving"},
{c:313230,d: "Air laid nonwoven fabrics manufacturing"},
{c:313230,d: "Blankets, nonwoven fabric, manufacturing"},
{c:313230,d: "Bonded-fiber fabrics manufacturing"},
{c:313230,d: "Carded nonwoven fabrics manufacturing"},
{c:313230,d: "Carpet paddings, nonwoven, manufacturing"},
{c:313230,d: "Fabrics, nonwoven, manufacturing"},
{c:313230,d: "Felts, nonwoven, manufacturing"},
{c:313230,d: "Melt blown nonwoven fabrics manufacturing"},
{c:313230,d: "Nonwoven fabric tapes manufacturing"},
{c:313230,d: "Nonwoven fabrics manufacturing"},
{c:313230,d: "Nonwoven felts manufacturing"},
{c:313230,d: "Pads and wadding, nonwoven, manufacturing"},
{c:313230,d: "Pressed felts manufacturing"},
{c:313230,d: "Punched felts manufacturing"},
{c:313230,d: "Ribbons made in nonwoven fabric mills"},
{c:313230,d: "Spunbonded fabrics manufacturing"},
{c:313230,d: "Tapes, nonwoven fabric, manufacturing"},
{c:313230,d: "Wet laid nonwoven fabrics manufacturing"},
{c:313230,d: "Wipes, nonwoven fabric, manufacturing"},
{c:313240,d: "Bags and bagging fabrics made in warp or weft knitting mills"},
{c:313240,d: "Bedspreads and bed sets made in lace mills"},
{c:313240,d: "Bedspreads and bed sets made in warp or weft knitting mills"},
{c:313240,d: "Circular (i.e., weft) fabrics knitting"},
{c:313240,d: "Curtains made in lace mills"},
{c:313240,d: "Curtains made in warp or weft knitting mills"},
{c:313240,d: "Dishcloths made in warp or weft knitting mills"},
{c:313240,d: "Fabrics, knit, made in warp or weft knit fabric mills"},
{c:313240,d: "Fabrics, lace, made in lace mills"},
{c:313240,d: "Flat (i.e., warp) fabrics knitting"},
{c:313240,d: "Hand knitting lace or warp fabric products"},
{c:313240,d: "Knitting and finishing lace"},
{c:313240,d: "Knitting and finishing warp or weft fabric"},
{c:313240,d: "Knitting lace"},
{c:313240,d: "Knitting warp or weft fabric"},
{c:313240,d: "Lace manufacturing"},
{c:313240,d: "Lace products (except apparel) made in lace mills"},
{c:313240,d: "Netting made in warp or weft knitting mills"},
{c:313240,d: "Netting made on a lace or net machine"},
{c:313240,d: "Pile fabrics made in warp or weft knitting mills"},
{c:313240,d: "Tablecloths made in lace mills"},
{c:313240,d: "Tablecloths made in warp or weft knitting mills"},
{c:313240,d: "Textile products (except apparel) made in lace mills"},
{c:313240,d: "Textile products (except apparel) made in warp or weft knitting mills"},
{c:313240,d: "Towels and washcloths made in warp or weft knitting mills"},
{c:313240,d: "Warp or weft fabrics knitting"},
{c:313310,d: "Bleaching textile products, apparel, and fabrics"},
{c:313310,d: "Broadwoven fabrics finishing"},
{c:313310,d: "Burling and mending fabrics"},
{c:313310,d: "Calendering textile products, apparel, and fabrics"},
{c:313310,d: "Carbonizing textile fibers"},
{c:313310,d: "Carding textile fibers"},
{c:313310,d: "Chemical finishing (e.g., fire, mildew, water resistance) fabrics"},
{c:313310,d: "Combing and converting top"},
{c:313310,d: "Combing textile fibers"},
{c:313310,d: "Converters, piece goods"},
{c:313310,d: "Converting textiles"},
{c:313310,d: "Crease resistant finishing of fabrics"},
{c:313310,d: "Dyeing gloves, woven or knit, for the trade"},
{c:313310,d: "Dyeing textile products and fabrics"},
{c:313310,d: "Embossing textile products and fabrics"},
{c:313310,d: "Fabric finishing"},
{c:313310,d: "Fabric mercerizing"},
{c:313310,d: "Gassing yarn (i.e., singeing)"},
{c:313310,d: "Mechanical finishing of fabrics"},
{c:313310,d: "Mercerizing textile products and fabrics"},
{c:313310,d: "Napping textile products and fabrics"},
{c:313310,d: "Preshrinking textile products and fabrics"},
{c:313310,d: "Printing fabric grey goods"},
{c:313310,d: "Printing textile banners (except screen printing)"},
{c:313310,d: "Printing textile products (except screen and apparel printing)"},
{c:313310,d: "Scouring and combing textile fibers"},
{c:313310,d: "Screen printing fabric grey goods"},
{c:313310,d: "Shrinking textile products and fabrics"},
{c:313310,d: "Sizing of fabrics"},
{c:313310,d: "Sponging textile products and fabrics"},
{c:313310,d: "Stone washing textile products, apparel, and fabrics"},
{c:313310,d: "Sueding textile products and fabrics"},
{c:313310,d: "Teaseling fabrics"},
{c:313310,d: "Textile products finishing"},
{c:313310,d: "Thread finishing"},
{c:313310,d: "Wool tops and noils manufacturing"},
{c:313320,d: "Garments, oiling (i.e., waterproofing)"},
{c:313320,d: "Laminating purchased textiles"},
{c:313320,d: "Leather, artificial, made from purchased fabric"},
{c:313320,d: "Metallizing purchased textiles"},
{c:313320,d: "Oilcloth manufacturing"},
{c:313320,d: "Oiling of purchased textiles and apparel"},
{c:313320,d: "Plastics coating of textiles and apparel"},
{c:313320,d: "Raincoats waterproofing (i.e., oiling)"},
{c:313320,d: "Rubberizing purchased capes"},
{c:313320,d: "Rubberizing purchased cloaks"},
{c:313320,d: "Rubberizing purchased clothing"},
{c:313320,d: "Rubberizing purchased coats"},
{c:313320,d: "Rubberizing purchased textiles and apparel"},
{c:313320,d: "Tapes, varnished and coated (except magnetic), made from purchased fabric"},
{c:313320,d: "Textile waterproofing"},
{c:313320,d: "Varnishing purchased textiles and apparel"},
{c:313320,d: "Vinyl coated fabrics manufacturing"},
{c:313320,d: "Waterproofing apparel, fabrics and textile products (e.g., oiling, rubberizing, waxing, varnishing)"},
{c:313320,d: "Waxing purchased textiles and apparel"},
{c:314110,d: "Bath mats and bath sets made in carpet mills"},
{c:314110,d: "Carpets and rugs made from textile materials"},
{c:314110,d: "Doormats, all materials (except entirely of rubber or plastics), manufacturing"},
{c:314110,d: "Finishing (e.g., dyeing) rugs and carpets"},
{c:314110,d: "Rugs and carpets made from textile materials"},
{c:314110,d: "Synthetic or artificial turf manufacturing"},
{c:314110,d: "Weaving rugs, carpets, and mats"},
{c:314120,d: "Bedspreads and bed sets made from purchased fabrics"},
{c:314120,d: "Blankets (except electric) made from purchased fabrics or felts"},
{c:314120,d: "Comforters made from purchased fabrics"},
{c:314120,d: "Curtains and draperies, window, made from purchased fabrics"},
{c:314120,d: "Cushions (except carpet, springs) made from purchased fabrics"},
{c:314120,d: "Draperies made from purchased fabrics or sheet goods"},
{c:314120,d: "Linens made from purchased materials"},
{c:314120,d: "Napkins made from purchased fabrics"},
{c:314120,d: "Pads and protectors (e.g., ironing board, mattress, table), textile, made from purchased fabrics or felts"},
{c:314120,d: "Pillowcases, bed, made from purchased fabrics"},
{c:314120,d: "Pillows, bed, made from purchased materials"},
{c:314120,d: "Placemats, all materials, made from purchased materials"},
{c:314120,d: "Quilts made from purchased materials"},
{c:314120,d: "Sheets and pillowcases made from purchased fabrics"},
{c:314120,d: "Shower and bath curtains, all materials, made from purchased fabric or sheet goods"},
{c:314120,d: "Slipcovers, all materials, made from purchased materials"},
{c:314120,d: "Tablecloths (except paper) made from purchased materials"},
{c:314120,d: "Towels or washcloths made from purchased fabrics"},
{c:314910,d: "Awnings and canopies, outdoor, made from purchased fabrics"},
{c:314910,d: "Bags, plastics, made from purchased woven plastics"},
{c:314910,d: "Bags, rubberized fabric, manufacturing"},
{c:314910,d: "Bags, textile, made from purchased woven or knitted materials"},
{c:314910,d: "Blanket bags manufacturing"},
{c:314910,d: "Canvas bags manufacturing"},
{c:314910,d: "Canvas products made from purchased canvas or canvas substitutes"},
{c:314910,d: "Covers (e.g., boat, swimming pool, truck) made from purchased fabrics"},
{c:314910,d: "Feed bags made from purchased woven or knitted materials"},
{c:314910,d: "Flour bags made from purchased woven or knitted materials"},
{c:314910,d: "Garment storage bags manufacturing"},
{c:314910,d: "Knapsacks (e.g., backpacks, book bags) manufacturing"},
{c:314910,d: "Knapsacks, made from purchased woven or knitted materials"},
{c:314910,d: "Laundry bags made from purchased woven or knitted materials"},
{c:314910,d: "Sails made from purchased fabrics"},
{c:314910,d: "Seed bags made from purchased woven or knitted materials"},
{c:314910,d: "Shipping bags made from purchased woven or knitted materials"},
{c:314910,d: "Tarpaulins made from purchased fabrics"},
{c:314910,d: "Tents made from purchased fabrics"},
{c:314910,d: "Textile bags made from purchased woven or knitted materials"},
{c:314994,d: "Cord (except wire) manufacturing"},
{c:314994,d: "Cord for reinforcing rubber tires, industrial belting, and fuel cells manufacturing"},
{c:314994,d: "Cordage (except wire) manufacturing"},
{c:314994,d: "Fabricated rope products (e.g., nets, slings) made in cordage or twine mills"},
{c:314994,d: "Fabrics for reinforcing rubber tires, industrial belting, and fuel cells manufacturing"},
{c:314994,d: "Industrial belting reinforcement, cord and fabric, manufacturing"},
{c:314994,d: "Ropes (except wire rope) manufacturing"},
{c:314994,d: "Strings (except musical instrument) manufacturing"},
{c:314994,d: "Tire cord and fabric, all materials, manufacturing"},
{c:314994,d: "Twines manufacturing"},
{c:314999,d: "Aircraft tie-down strap assemblies (except leather) manufacturing"},
{c:314999,d: "Apparel fillings (e.g., cotton mill waste, kapok) manufacturing"},
{c:314999,d: "Appliqueing on textile products (except apparel)"},
{c:314999,d: "Art needlework contractors on apparel"},
{c:314999,d: "Art needlework on clothing for the trade"},
{c:314999,d: "Badges, fabric, manufacturing"},
{c:314999,d: "Bags, sleeping, manufacturing"},
{c:314999,d: "Banners made from purchased fabrics (except banner printing)"},
{c:314999,d: "Batts and batting (except nonwoven fabrics) manufacturing"},
{c:314999,d: "Beading on textile products (except apparel) for the trade"},
{c:314999,d: "Belting made from purchased fabrics"},
{c:314999,d: "Bias bindings made from purchased fabrics"},
{c:314999,d: "Binding carpets and rugs for the trade"},
{c:314999,d: "Bindings, bias, made from purchased fabrics"},
{c:314999,d: "Bows made from purchased fabrics"},
{c:314999,d: "Burnt-out laces manufacturing"},
{c:314999,d: "Carpet cutting and binding"},
{c:314999,d: "Cotton battings (except nonwoven batting) manufacturing"},
{c:314999,d: "Crochet ware made from purchased materials"},
{c:314999,d: "Decorative stitching contractors on apparel"},
{c:314999,d: "Decorative stitching on textile articles and apparel"},
{c:314999,d: "Diapers (except disposable) made from purchased fabrics"},
{c:314999,d: "Dust cloths made from purchased fabrics"},
{c:314999,d: "Embroidering contractors on apparel"},
{c:314999,d: "Embroidering on textile products or apparel for the trade"},
{c:314999,d: "Fiber, textile recovery from textile mill waste and rags"},
{c:314999,d: "Filling (except nonwoven textile), upholstery, manufacturing"},
{c:314999,d: "Fire hose, textile, made from purchased materials"},
{c:314999,d: "Fishing nets made from purchased materials"},
{c:314999,d: "Flags, textile (e.g., banners, bunting, emblems, pennants), made from purchased fabrics"},
{c:314999,d: "Furniture trimmings made from purchased fabrics"},
{c:314999,d: "Garnetting of textile waste and rags"},
{c:314999,d: "Hammocks, fabric, manufacturing"},
{c:314999,d: "Lace, burnt-out, manufacturing"},
{c:314999,d: "Laundry nets made from purchased materials"},
{c:314999,d: "Linings, casket, manufacturing"},
{c:314999,d: "Linings, luggage, manufacturing"},
{c:314999,d: "Luggage linings manufacturing"},
{c:314999,d: "Military insignia, textile, manufacturing"},
{c:314999,d: "Mill menders, contract, woven fabrics"},
{c:314999,d: "Needlework art contractors on apparel"},
{c:314999,d: "Novelty stitching contractors on apparel"},
{c:314999,d: "Padding and wadding (except nonwoven fabric) manufacturing"},
{c:314999,d: "Parachutes manufacturing"},
{c:314999,d: "Powder puffs and mitts manufacturing"},
{c:314999,d: "Processing of textile mill waste and recovering fibers"},
{c:314999,d: "Quilting of textiles"},
{c:314999,d: "Reclaimed wool processing"},
{c:314999,d: "Recovered fibers processing"},
{c:314999,d: "Ribbons made from purchased fabrics"},
{c:314999,d: "Sleeping bags manufacturing"},
{c:314999,d: "Stitching, decorative and novelty, contractors on apparel"},
{c:314999,d: "Stitching, decorative and novelty, on textile articles and apparel"},
{c:314999,d: "Tassels manufacturing"},
{c:314999,d: "Textile fire hose made from purchased material"},
{c:314999,d: "Upholstering filling (except nonwoven fabric) manufacturing"},
{c:314999,d: "Weatherstripping made from purchased textiles"},
{c:314999,d: "Wool waste processing"},
{c:315110,d: "Anklets, sheer hosiery or socks, knitting or knitting and finishing"},
{c:315110,d: "Athletic socks knitting or knitting and finishing"},
{c:315110,d: "Boys' socks manufacturing"},
{c:315110,d: "Calf high sheer hosiery knitting or knitting and finishing"},
{c:315110,d: "Children's socks manufacturing"},
{c:315110,d: "Crew socks knitting or knitting and finishing"},
{c:315110,d: "Footies, sheer, knitting or knitting and finishing"},
{c:315110,d: "Girls' hosiery, sheer, full-length and knee-length, knitting or knitting and finishing"},
{c:315110,d: "Girls' socks manufacturing"},
{c:315110,d: "Hosiery, sheer, women's, misses', and girls' full-length and knee-length, knitting or knitting and finishing"},
{c:315110,d: "Hosiery, women's, girls', and infants', manufacturing"},
{c:315110,d: "Leggings knitting or knitting and finishing"},
{c:315110,d: "Men's socks knitting or knitting and finishing"},
{c:315110,d: "Nylon hosiery, sheer, women's, misses', and girls' full-length and knee-length, knitting or knitting and finishing"},
{c:315110,d: "Nylons, sheer, women's, misses', and girls' full-length and knee-length, knitting or knitting and finishing"},
{c:315110,d: "Panty hose, women's and girls', knitting or knitting and finishing"},
{c:315110,d: "Slipper socks made in sock mills"},
{c:315110,d: "Socks knitting or knitting and finishing"},
{c:315110,d: "Socks, men's and boy's, manufacturing"},
{c:315110,d: "Socks, slipper, made in sock mills"},
{c:315110,d: "Stockings, sheer, manufacturing"},
{c:315110,d: "Stockings, sheer, women's, misses', and girls', full-length and knee-length, knitting or knitting and finishing"},
{c:315110,d: "Tights knitting or knitting and finishing"},
{c:315190,d: "Athletic clothing made in apparel knitting mills"},
{c:315190,d: "Bathing suits made in apparel knitting mills"},
{c:315190,d: "Bathrobes made in apparel knitting mills"},
{c:315190,d: "Beachwear made in apparel knitting mills"},
{c:315190,d: "Blouses made in apparel knitting mills"},
{c:315190,d: "Body stockings made in apparel knitting mills"},
{c:315190,d: "Briefs, underwear, made in apparel knitting mills"},
{c:315190,d: "Caps made in apparel knitting mills"},
{c:315190,d: "Chemises made in apparel knitting mills"},
{c:315190,d: "Clerical vestments made in apparel knitting mills"},
{c:315190,d: "Collar and cuff sets made in apparel knitting mills"},
{c:315190,d: "Drawers, apparel, made in apparel knitting mills"},
{c:315190,d: "Dress and semidress gloves made in apparel knitting mills"},
{c:315190,d: "Dresses made in apparel knitting mills"},
{c:315190,d: "Dresses, hand-knit, manufacturing"},
{c:315190,d: "Ensemble dresses made in apparel knitting mills"},
{c:315190,d: "Girdles and other foundation garments made in apparel knitting mills"},
{c:315190,d: "Gloves, knit, made in apparel knitting mills"},
{c:315190,d: "Hats made in apparel knitting mills"},
{c:315190,d: "Housecoats made in apparel knitting mills"},
{c:315190,d: "Jackets made in apparel knitting mills"},
{c:315190,d: "Jerseys made in apparel knitting mills"},
{c:315190,d: "Jogging suits made in apparel knitting mills"},
{c:315190,d: "Knit gloves made in apparel knitting mills"},
{c:315190,d: "Leotards made in apparel knitting mills"},
{c:315190,d: "Lounging robes and dressing gowns made in apparel knitting mills"},
{c:315190,d: "Mittens, knit, made in apparel knitting mills"},
{c:315190,d: "Mufflers made in apparel knitting mills"},
{c:315190,d: "Neckties made in apparel knitting mills"},
{c:315190,d: "Neckwear made in a apparel knitting mills"},
{c:315190,d: "Negligees made in apparel knitting mills"},
{c:315190,d: "Nightgowns made in apparel knitting mills"},
{c:315190,d: "Nightshirts made in apparel knitting mills"},
{c:315190,d: "Nightwear made in apparel knitting mills"},
{c:315190,d: "Outerwear handknitted for the trade"},
{c:315190,d: "Pajamas made in apparel knitting mills"},
{c:315190,d: "Panties made in apparel knitting mills"},
{c:315190,d: "Pants, athletic, made in apparel knitting mills"},
{c:315190,d: "Pants, outerwear, made in apparel knitting mills"},
{c:315190,d: "Polo shirts made in apparel knitting mills"},
{c:315190,d: "Prayer shawls made in apparel knitting mills"},
{c:315190,d: "Robes, lounging, made in apparel knitting mills"},
{c:315190,d: "Scarves made in apparel knitting mills"},
{c:315190,d: "Shirts, outerwear, made in apparel knitting mills"},
{c:315190,d: "Shirts, underwear, made in apparel knitting mills"},
{c:315190,d: "Shorts, outerwear, made in apparel knitting mills"},
{c:315190,d: "Shorts, underwear, made in apparel knitting mills"},
{c:315190,d: "Ski pants made in apparel knitting mills"},
{c:315190,d: "Ski suits made in apparel knitting mills"},
{c:315190,d: "Skirts made in apparel knitting mills"},
{c:315190,d: "Slacks made in apparel knitting mills"},
{c:315190,d: "Slacks, jean-cut casual, made in apparel knitting mills"},
{c:315190,d: "Slips made in apparel knitting mills"},
{c:315190,d: "Snowsuits made in apparel knitting mills"},
{c:315190,d: "Sports clothing made in apparel knitting mills"},
{c:315190,d: "Sports shirts made in apparel knitting mills"},
{c:315190,d: "Suits made in apparel knitting mills"},
{c:315190,d: "Sweat bands made in apparel knitting mills"},
{c:315190,d: "Sweat pants made in apparel knitting mills"},
{c:315190,d: "Sweat suits made in apparel knitting mills"},
{c:315190,d: "Sweater jackets made in apparel knitting mills"},
{c:315190,d: "Sweater vests made in apparel knitting mills"},
{c:315190,d: "Sweaters made in apparel knitting mills"},
{c:315190,d: "Sweatshirts made in apparel knitting mills"},
{c:315190,d: "Swimsuits made in apparel knitting mills"},
{c:315190,d: "Tank tops, outerwear, made in apparel knitting mills"},
{c:315190,d: "Tank tops, underwear, made in apparel knitting mills"},
{c:315190,d: "Tennis shirts made in apparel knitting mills"},
{c:315190,d: "Tennis skirts made in apparel knitting mills"},
{c:315190,d: "Ties made in apparel knitting mills"},
{c:315190,d: "Trousers made in apparel knitting mills"},
{c:315190,d: "T-shirts, outerwear, made in apparel knitting mills"},
{c:315190,d: "T-shirts, underwear, made in apparel knitting mills"},
{c:315190,d: "Underwear made in apparel knitting mills"},
{c:315190,d: "Underwear shirts made in apparel knitting mills"},
{c:315190,d: "Underwear shorts made in apparel knitting mills"},
{c:315190,d: "Uniforms, nontailored, made in apparel knitting mills"},
{c:315190,d: "Union suits made in apparel knitting mills"},
{c:315190,d: "Warmup suits made in apparel knitting mills"},
{c:315190,d: "Work gloves and mittens, knit, made in apparel knitting mills"},
{c:315210,d: "Apparel cut and sew contractors"},
{c:315210,d: "Apparel trimmings and findings cut and sew apparel contractors"},
{c:315210,d: "Apparel, fur, cut and sew apparel contractors"},
{c:315210,d: "Appliqueing on apparel"},
{c:315210,d: "Aprons, waterproof (including rubberized fabric, plastics), cut and sew apparel contractors"},
{c:315210,d: "Aprons, work (except leather), cut and sew apparel contractors"},
{c:315210,d: "Arm bands cut and sew apparel contractors"},
{c:315210,d: "Ascots cut and sew apparel contractors"},
{c:315210,d: "Athletic clothing cut and sew apparel contractors"},
{c:315210,d: "Athletic uniforms, team, cut and sew apparel contractors"},
{c:315210,d: "Bakers' service apparel, washable, cut and sew apparel contractors"},
{c:315210,d: "Band uniforms cut and sew apparel contractors"},
{c:315210,d: "Bandeaux cut and sew apparel contractors"},
{c:315210,d: "Barbers' service apparel, washable, cut and sew apparel contractors"},
{c:315210,d: "Baseball caps (except plastics) cut and sew apparel contractors"},
{c:315210,d: "Baseball uniforms cut and sew apparel contractors"},
{c:315210,d: "Basketball uniforms cut and sew apparel contractors"},
{c:315210,d: "Bathing suits cut and sew apparel contractors"},
{c:315210,d: "Bathrobes cut and sew apparel contractors"},
{c:315210,d: "Beachwear cut and sew apparel contractors"},
{c:315210,d: "Bedjackets cut and sew apparel contractors"},
{c:315210,d: "Belts, apparel (e.g., fabric, leather, vinyl), cut and sew apparel contractors"},
{c:315210,d: "Belts, money, any material, cut and sew apparel contractors"},
{c:315210,d: "Bibs and aprons, waterproof (e.g., plastics, rubber, similar materials), cut and sew apparel contractors"},
{c:315210,d: "Blouses cut and sew apparel contractors"},
{c:315210,d: "Body stockings cut and sew apparel contractors"},
{c:315210,d: "Bow ties cut and sew apparel contractors"},
{c:315210,d: "Bra-slips cut and sew apparel contractors"},
{c:315210,d: "Brassieres cut and sew apparel contractors"},
{c:315210,d: "Bridal dresses or gowns cut and sew apparel contractors"},
{c:315210,d: "Briefs cut and sew apparel contractors"},
{c:315210,d: "Buntings cut and sew apparel contractors"},
{c:315210,d: "Burial garments cut and sew apparel contractors"},
{c:315210,d: "Buttonhole making apparel contractors"},
{c:315210,d: "Buttonhole making, fur goods, cut and sew apparel contractors"},
{c:315210,d: "Buttonholing and button covering apparel contractors"},
{c:315210,d: "Caftans cut and sew apparel contractors"},
{c:315210,d: "Camisoles cut and sew apparel contractors"},
{c:315210,d: "Capes cut and sew apparel contractors"},
{c:315210,d: "Caps (i.e., apparel accessory) cut and sew apparel contractors"},
{c:315210,d: "Caps and gowns, academic, cut and sew apparel contractors"},
{c:315210,d: "Caps, textiles, straw, fur-felt, and wool-felt, cut and sew apparel contractors"},
{c:315210,d: "Chauffeurs' hats and caps cut and sew apparel contractors"},
{c:315210,d: "Chemises cut and sew apparel contractors"},
{c:315210,d: "Clerical vestments cut and sew apparel contractors"},
{c:315210,d: "Clothing, fur, cut and sew apparel contractors"},
{c:315210,d: "Clothing, leather or sheep-lined, cut and sew apparel contractors"},
{c:315210,d: "Clothing, water resistant, cut and sew apparel contractors"},
{c:315210,d: "Clothing, waterproof, cut and sew apparel contractors"},
{c:315210,d: "Clothing, water-repellent, cut and sew apparel contractors"},
{c:315210,d: "Coat linings, fur, cut and sew apparel contractors"},
{c:315210,d: "Coat trimmings, fabric, cut and sew apparel contractors"},
{c:315210,d: "Coats (e.g., tailored, fur, artificial leather, leather, sheep-lined), cut and sew apparel contractors"},
{c:315210,d: "Coats, nontailored service apparel (e.g., laboratory, medical, mechanics'), cut and sew apparel contractors"},
{c:315210,d: "Coats, waterproof (i.e., plastics, rubberized fabric, similar materials), cut and sew apparel contractors"},
{c:315210,d: "Collar and cuff sets cut and sew apparel contractors"},
{c:315210,d: "Contractors, cut and sew apparel"},
{c:315210,d: "Corselets cut and sew apparel contractors"},
{c:315210,d: "Corsets and allied garments (except surgical) cut and sew apparel contractors"},
{c:315210,d: "Costumes (e.g., lodge, masquerade, theatrical) cut and sew apparel contractors"},
{c:315210,d: "Coveralls, work, cut and sew apparel contractors"},
{c:315210,d: "Culottes cut and sew apparel contractors"},
{c:315210,d: "Cummerbunds cut and sew apparel contractors"},
{c:315210,d: "Cut and sew apparel contractors"},
{c:315210,d: "Cutting fabric owned by others for apparel"},
{c:315210,d: "Diaper covers, water resistant and waterproof, cut and sew apparel contractors"},
{c:315210,d: "Dickeys cut and sew apparel contractors"},
{c:315210,d: "Down-filled clothing cut and sew apparel contractors"},
{c:315210,d: "Drawers cut and sew apparel contractors"},
{c:315210,d: "Dress and semidress gloves cut and sew apparel contractors"},
{c:315210,d: "Dress trimmings cut and sew apparel contractors"},
{c:315210,d: "Dresses cut and sew apparel contractors"},
{c:315210,d: "Dressing gowns cut and sew apparel contractors"},
{c:315210,d: "Dungarees cut and sew apparel contractors"},
{c:315210,d: "Dusters (i.e., apparel) cut and sew apparel contractors"},
{c:315210,d: "Ensemble dresses cut and sew apparel contractors"},
{c:315210,d: "Eyelet making contractors on apparel"},
{c:315210,d: "Feather-filled clothing cut and sew apparel contractors"},
{c:315210,d: "Field jackets, military, cut and sew apparel contractors"},
{c:315210,d: "Findings, suit and coat (e.g., coat fronts, pockets), cut and sew apparel contractors"},
{c:315210,d: "Firefighters' dress uniforms cut and sew apparel contractors"},
{c:315210,d: "Flannel shirts cut and sew apparel contractors"},
{c:315210,d: "Formal jackets cut and sew apparel contractors"},
{c:315210,d: "Foundation garments cut and sew apparel contractors"},
{c:315210,d: "Fur accessories and trimmings cut and sew apparel contractors"},
{c:315210,d: "Fur apparel (e.g., capes, coats, hats, jackets, neckpieces) cut and sew apparel contractors"},
{c:315210,d: "Fur clothing cut and sew apparel contractors"},
{c:315210,d: "Fur finishers, liners, and buttonhole makers cut and sew apparel contractors"},
{c:315210,d: "Fur plates and trimmings cut and sew apparel contractors"},
{c:315210,d: "Garments, leather or sheep-lined, cut and sew apparel contractors"},
{c:315210,d: "Garter belts cut and sew apparel contractors"},
{c:315210,d: "Garters cut and sew apparel contractors"},
{c:315210,d: "Girdles cut and sew apparel contractors"},
{c:315210,d: "Glove linings, fur, cut and sew apparel contractors"},
{c:315210,d: "Gloves and mittens (except athletic), leather, fabric, fur, or combinations, cut and sew apparel contractors"},
{c:315210,d: "Gloves and mittens, woven or knit, cut and sew apparel contractors"},
{c:315210,d: "Gowns (e.g., academic, choir, clerical) cut and sew apparel contractors"},
{c:315210,d: "Gowns, formal, cut and sew apparel contractors"},
{c:315210,d: "Gowns, hospital, surgical and patient, cut and sew apparel contractors"},
{c:315210,d: "Gowns, wedding, cut and sew apparel contractors"},
{c:315210,d: "Graduation caps and gowns cut and sew apparel contractors"},
{c:315210,d: "Handkerchiefs (except paper) cut and sew apparel contractors"},
{c:315210,d: "Hat bodies (e.g., fur-felt, straw, wool-felt) cut and sew apparel contractors"},
{c:315210,d: "Hat findings cut and sew apparel contractors"},
{c:315210,d: "Hat linings and trimmings cut and sew apparel contractors"},
{c:315210,d: "Hats (e.g., cloth, fur, fur-felt, leather, straw, wool-felt) cut and sew apparel contractors"},
{c:315210,d: "Hats, trimmed, cut and sew apparel contractors"},
{c:315210,d: "Headbands cut and sew apparel contractors"},
{c:315210,d: "Hemstitching apparel contractors on apparel"},
{c:315210,d: "Hospital service apparel, washable, cut and sew apparel contractors"},
{c:315210,d: "Housecoats cut and sew apparel contractors"},
{c:315210,d: "Housedresses cut and sew apparel contractors"},
{c:315210,d: "Hunting coats and vests cut and sew apparel contractors"},
{c:315210,d: "Industrial garments cut and sew apparel contractors"},
{c:315210,d: "Infants' cut and sew apparel contractors"},
{c:315210,d: "Jackets cut and sew apparel contractors"},
{c:315210,d: "Jackets, fur, cut and sew apparel contractors"},
{c:315210,d: "Jackets, leather (except welders') or sheep-lined, cut and sew apparel contractors"},
{c:315210,d: "Jackets, service apparel (e.g., laboratory, medical), cut and sew apparel contractors"},
{c:315210,d: "Jackets, ski, cut and sew apparel contractors"},
{c:315210,d: "Jean-cut casual slacks cut and sew apparel contractors"},
{c:315210,d: "Jeans cut and sew apparel contractors"},
{c:315210,d: "Jerseys cut and sew apparel contractors"},
{c:315210,d: "Jogging suits cut and sew apparel contractors"},
{c:315210,d: "Jumpsuits cut and sew apparel contractors"},
{c:315210,d: "Knickers cut and sew apparel contractors"},
{c:315210,d: "Knit gloves cut and sew apparel contractors"},
{c:315210,d: "Laboratory coats cut and sew apparel contractors"},
{c:315210,d: "Leather apparel (e.g., capes, coats, hats, jackets) cut and sew apparel contractors"},
{c:315210,d: "Leather clothing cut and sew apparel contractors"},
{c:315210,d: "Leather gloves or mittens (except athletic) cut and sew apparel contractors"},
{c:315210,d: "Leggings cut and sew apparel contractors"},
{c:315210,d: "Leotards cut and sew apparel contractors"},
{c:315210,d: "Lingerie cut and sew apparel contractors"},
{c:315210,d: "Linings, hat, cut and sew apparel contractors"},
{c:315210,d: "Lounging robes and dressing gowns cut and sew apparel contractors"},
{c:315210,d: "Mackinaws cut and sew apparel contractors"},
{c:315210,d: "Maternity bras and corsets cut and sew apparel contractors"},
{c:315210,d: "Medical service apparel cut and sew apparel contractors"},
{c:315210,d: "Melton jackets cut and sew apparel contractors"},
{c:315210,d: "Middies cut and sew apparel contractors"},
{c:315210,d: "Military dress uniforms cut and sew apparel contractors"},
{c:315210,d: "Millinery cut and sew apparel contractors"},
{c:315210,d: "Millinery trimmings cut and sew apparel contractors"},
{c:315210,d: "Mittens (e.g., leather, woven or knit) cut and sew apparel contractors"},
{c:315210,d: "Mufflers cut and sew apparel contractors"},
{c:315210,d: "Neckpieces, fur, cut and sew apparel contractors"},
{c:315210,d: "Neckties cut and sew apparel contractors"},
{c:315210,d: "Neckwear cut and sew apparel contractors"},
{c:315210,d: "Negligees cut and sew apparel contractors"},
{c:315210,d: "Nightgowns cut and sew apparel contractors"},
{c:315210,d: "Nightshirts cut and sew apparel contractors"},
{c:315210,d: "Nightwear cut and sew apparel contractors"},
{c:315210,d: "Opera hats cut and sew apparel contractors"},
{c:315210,d: "Overall jackets, work, cut and sew apparel contractors"},
{c:315210,d: "Overalls, work, cut and sew apparel contractors"},
{c:315210,d: "Overcoats cut and sew apparel contractors"},
{c:315210,d: "Pajamas cut and sew apparel contractors"},
{c:315210,d: "Panama hats cut and sew apparel contractors"},
{c:315210,d: "Panties cut and sew apparel contractors"},
{c:315210,d: "Pants (e.g., athletic, dress, leather, sweat, waterproof outerwear, work) cut and sew apparel contractors"},
{c:315210,d: "Pants outfits cut and sew apparel contractors"},
{c:315210,d: "Pantsuits cut and sew apparel contractors"},
{c:315210,d: "Panty girdles cut and sew apparel contractors"},
{c:315210,d: "Paper dresses cut and sew apparel contractors"},
{c:315210,d: "Plastics gowns cut and sew apparel contractors"},
{c:315210,d: "Plastics rainwear cut and sew apparel contractors"},
{c:315210,d: "Playsuits cut and sew apparel contractors"},
{c:315210,d: "Pleating contractors on apparel"},
{c:315210,d: "Pockets (e.g., coat, suit) cut and sew apparel contractors"},
{c:315210,d: "Police caps and hats (except protective head gear) cut and sew apparel contractors"},
{c:315210,d: "Police uniforms cut and sew apparel contractors"},
{c:315210,d: "Polo shirts cut and sew apparel contractors"},
{c:315210,d: "Ponchos and similar waterproof raincoats cut and sew apparel contractors"},
{c:315210,d: "Prayer shawls cut and sew apparel contractors"},
{c:315210,d: "Professional service apparel, washable, cut and sew apparel contractors"},
{c:315210,d: "Raincoats (e.g., water resistant, waterproof, water-repellent) cut and sew apparel contractors"},
{c:315210,d: "Riding clothes cut and sew apparel contractors"},
{c:315210,d: "Robes, lounging, cut and sew apparel contractors"},
{c:315210,d: "Rompers cut and sew apparel contractors"},
{c:315210,d: "Scarves cut and sew apparel contractors"},
{c:315210,d: "Service apparel, washable, cut and sew apparel contractors"},
{c:315210,d: "Sewing fabric owned by others for apparel"},
{c:315210,d: "Shirts, outerwear, cut and sew apparel contractors"},
{c:315210,d: "Shirts, underwear, cut and sew apparel contractors"},
{c:315210,d: "Shorts, outerwear, cut and sew apparel contractors"},
{c:315210,d: "Shorts, underwear, cut and sew apparel contractors"},
{c:315210,d: "Ski pants cut and sew apparel contractors"},
{c:315210,d: "Ski suits cut and sew apparel contractors"},
{c:315210,d: "Skirts cut and sew apparel contractors"},
{c:315210,d: "Slacks cut and sew apparel contractors"},
{c:315210,d: "Slacks, jean-cut casual, cut and sew apparel contractors"},
{c:315210,d: "Slips cut and sew apparel contractors"},
{c:315210,d: "Snowsuits cut and sew apparel contractors"},
{c:315210,d: "Sport coats cut and sew apparel contractors"},
{c:315210,d: "Sport coats, fur, cut and sew apparel contractors"},
{c:315210,d: "Sport coats, leather (including artificial and tailored), cut and sew apparel contractors"},
{c:315210,d: "Sport shirts cut and sew apparel contractors"},
{c:315210,d: "Sports clothing cut and sew apparel contractors"},
{c:315210,d: "Suit trimmings cut and sew  apparel contractors"},
{c:315210,d: "Suits (i.e., nontailored, tailored, work) cut and sew apparel contractors"},
{c:315210,d: "Suspenders cut and sew apparel contractors"},
{c:315210,d: "Sweat bands cut and sew apparel contractors"},
{c:315210,d: "Sweat pants cut and sew apparel contractors"},
{c:315210,d: "Sweat suits cut and sew apparel contractors"},
{c:315210,d: "Sweater jackets cut and sew apparel contractors"},
{c:315210,d: "Sweater vests cut and sew apparel contractors"},
{c:315210,d: "Sweaters cut and sew apparel contractors"},
{c:315210,d: "Sweatshirts cut and sew apparel contractors"},
{c:315210,d: "Swimsuits cut and sew apparel contractors"},
{c:315210,d: "Tailored dress and sport coats cut and sew apparel contractors"},
{c:315210,d: "Tank tops cut and sew apparel contractors"},
{c:315210,d: "Team athletic uniforms cut and sew apparel contractors"},
{c:315210,d: "Teddies cut and sew apparel contractors"},
{c:315210,d: "Tennis shirts cut and sew apparel contractors"},
{c:315210,d: "Tennis skirts cut and sew apparel contractors"},
{c:315210,d: "Theatrical costumes cut and sew apparel contractors"},
{c:315210,d: "Ties cut and sew apparel contractors"},
{c:315210,d: "Topcoats cut and sew apparel contractors"},
{c:315210,d: "Training pants (i.e., underwear) cut and sew apparel contractors"},
{c:315210,d: "Trimmings, fur, cut and sew apparel contractors"},
{c:315210,d: "Trousers cut and sew apparel contractors"},
{c:315210,d: "T-shirts, outerwear, cut and sew apparel contractors"},
{c:315210,d: "T-shirts, underwear, cut and sew apparel contractors"},
{c:315210,d: "Tuxedos cut and sew apparel contractors"},
{c:315210,d: "Underwear cut and sew apparel contractors"},
{c:315210,d: "Underwear shirts cut and sew apparel contractors"},
{c:315210,d: "Underwear shorts cut and sew apparel contractors"},
{c:315210,d: "Uniform hats and caps cut and sew apparel contractors"},
{c:315210,d: "Uniform shirts cut and sew apparel contractors"},
{c:315210,d: "Uniforms, band, cut and sew apparel contractors"},
{c:315210,d: "Uniforms, dress (e.g., military, police, fire fighter), cut and sew apparel contractors"},
{c:315210,d: "Uniforms, nontailored, cut and sew apparel contractors"},
{c:315210,d: "Uniforms, team athletic, cut and sew apparel contractors"},
{c:315210,d: "Union suits cut and sew apparel contractors"},
{c:315210,d: "Vestments, academic and clerical, cut and sew apparel contractors"},
{c:315210,d: "Vests cut and sew apparel contractors"},
{c:315210,d: "Vests, leather, fur, or sheep-lined, cut and sew apparel contractors"},
{c:315210,d: "Warmup suits cut and sew apparel contractors"},
{c:315210,d: "Washable service apparel (e.g., barbers', hospital, professional) cut and sew apparel contractors"},
{c:315210,d: "Water resistant apparel cut and sew apparel contractors"},
{c:315210,d: "Water resistant outerwear cut and sew apparel contractors"},
{c:315210,d: "Waterproof outerwear cut and sew apparel contractors"},
{c:315210,d: "Water-repellent outerwear cut and sew apparel contractors"},
{c:315210,d: "Wedding dresses cut and sew apparel contractors"},
{c:315210,d: "Windbreakers cut and sew apparel contractors"},
{c:315210,d: "Work coats and jackets cut and sew apparel contractors"},
{c:315210,d: "Work gloves, leather, cut and sew apparel contractors"},
{c:315210,d: "Work pants cut and sew apparel contractors"},
{c:315210,d: "Work shirts cut and sew apparel contractors"},
{c:315220,d: "Aprons, work (except leather, waterproof), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Athletic clothing (except team athletic uniforms), men's, boys' and unisex (i.e., sized without regard to gender), cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Bakers' service apparel, washable, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Barbers' service apparel, washable, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Bathing suits, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Bathrobes, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Beachwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Briefs, underwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Caftans, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Clothing, water resistant, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Clothing, water-repellent, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Coats (except fur, leather, waterproof), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Coats, nontailored service apparel (e.g., laboratory, mechanics', medical), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Coats, tailored (except fur, leather), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Coveralls, work, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Custom tailors, men's and boys' dress shirts, cut and sewn from purchased fabric"},
{c:315220,d: "Custom tailors, men's and boys' suits, cut and sewn from purchased fabric"},
{c:315220,d: "Down-filled clothing, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Drawers, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Dungarees, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Feather-filled clothing, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Field jackets, military, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Firefighters' dress uniforms, men's, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Flannel shirts, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Formal jackets, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Hospital service apparel, washable, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Hunting coats and vests, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Industrial garments, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Jackets (except fur, leather, sheep-lined), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Jackets, service apparel (e.g., laboratory, medical), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Jackets, ski, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Jackets, tailored (except fur, leather, sheep-lined), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Jean-cut casual slacks, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Jerseys, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Jogging suits, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Knickers, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Laboratory coats, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Lounging robes and dressing gowns, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Mackinaws, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Medical service apparel, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Melton jackets, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Military dress uniforms, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Nightgowns, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Nightshirts, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Nightwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Overall jackets, work, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Overalls, work, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Overcoats, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Pajamas, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Pants (e.g., athletic, dress, sweat, work), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Police dress uniforms, men's, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Polo shirts, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Professional service apparel, washable, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Raincoats, water resistant, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Raincoats, water-repellent, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Riding clothes, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Robes, lounging, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Service apparel, washable, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Shirts, outerwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Shirts, outerwear, unisex (i.e., sized without regard to gender), cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Shirts, underwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Shorts, outerwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Shorts, underwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Ski pants, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Ski suits, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Slacks, jean-cut casual, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Slacks, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Snowsuits, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Sport coats (except fur, leather), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Sport shirts, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Sports clothing (except team uniforms), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Suits, nontailored (e.g., jogging, snow, ski, warmup), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Suits, tailored, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Sweat pants, men's, boys', and unisex (i.e., sized without regard to gender), cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Sweat suits, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Sweater jackets, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Sweater vests, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Sweaters, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Sweatshirts, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Sweatshirts, outerwear, unisex (sized without regard to gender), cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Swimsuits, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Tailored dress and sport coats, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Tank tops, outerwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Tank tops, underwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Tennis shirts, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Topcoats, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Trousers, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "T-shirts, outerwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "T-shirts, outerwear, unisex (i.e., sized without regard to gender), cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "T-shirts, underwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Tuxedos cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Underwear shirts, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Underwear shorts, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Underwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Uniform shirts (except team athletic), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Uniforms, dress (e.g., fire fighter, military, police), men's, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Uniforms, nontailored, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Union suits, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Vests, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Warmup suits, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Washable service apparel (e.g., barbers', hospital, professional), men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Water resistant jackets and windbreakers, nontailored, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Water resistant outerwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Water resistant overcoats, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Water-repellent outerwear, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Water-repellent overcoats, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Windbreakers, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Work coats and jackets, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Work pants, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315220,d: "Work shirts, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Aprons, work (except waterproof, leather), women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Athletic clothing (except team athletic uniforms), women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Bakers' service apparel, washable, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Bandeaux, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Bathing suits, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Bathrobes, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Beachwear, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Bedjackets, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Blouses, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Body stockings, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Bra-slips, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Brassieres cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Bridal dresses or gowns, custom made"},
{c:315240,d: "Bridal dresses or gowns, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Briefs, underwear, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Buntings, infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Caftans, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Camisoles, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Capes (except fur, waterproof), women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Chemises, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Clothing, water resistant, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Clothing, water-repellent, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Coats (except fur, leather, waterproof), women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Coats, nontailored service apparel (e.g., laboratory, medical, mechanics'), women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Coats, tailored (except fur, leather), women's and girls', cut and sewn from purchased fabric"},
{c:315240,d: "Collar and cuff sets, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Corselets, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Corsets and allied garments (except surgical), women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Coveralls, work, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Culottes, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Custom tailors, women's and girls' dresses cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Diaper covers, infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Dickeys, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Down-filled clothing, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Drawers, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Dresses, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Dressing gowns, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Dungarees, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Dusters (i.e., apparel), women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Ensemble dresses, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Feather-filled clothing, jackets, and vests, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Firefighters' dress uniforms, women's, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Flannel shirts, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Foundation garments, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Garter belts cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Garters, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Girdles, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Gowns, formal, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Gowns, wedding, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Hospital service apparel, washable, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Housecoats, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Housedresses, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Industrial garments, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Infants' apparel cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Infants' water resistant outerwear cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Jackets (except fur, leather, sheep-lined), women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Jackets, service apparel (e.g., laboratory, medical), women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Jackets, ski, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Jean-cut casual slacks, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Jeans, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Jerseys, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Jogging suits, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Jumpsuits, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Knickers, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Laboratory coats, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Leggings, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Leotards, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Lingerie, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Lounging robes and dressing gowns, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Mackinaws, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Maternity bras and corsets, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Medical service apparel, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Melton jackets, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Middies, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Military dress uniforms, tailored, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Negligees, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Nightgowns, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Nightshirts, women's, girls', and infants', cut and sewn from purchased fabric (except contractors)"},
{c:315240,d: "Nightwear, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Overcoats, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Pajamas, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Panties, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Pants outfits, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Pants, athletic, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Pants, sweat, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Pants, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Pantsuits, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Panty girdles, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Paper dresses, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Playsuits, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Police dress uniforms, women's, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Polo shirts, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Professional service apparel, washable, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Raincoats, water resistant, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Raincoats, water-repellent, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Riding clothes, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Robes, lounging, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Rompers, infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Service apparel, washable, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Shirts, outerwear, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Shirts, underwear, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Shorts, outerwear, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Ski pants, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Ski suits, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Skirts, tennis, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Skirts, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Slacks, jean-cut casual, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Slacks, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Slips, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Snowsuits, women's, girls, and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Sports clothing (except team uniforms), women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Suits (e.g., jogging, snowsuit, warmup), women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Suits, tailored, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Sunsuits, infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Sweat pants, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Sweat suits, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Sweater jackets, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Sweater vests, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Sweaters, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Sweatshirts, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Swimsuits, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Tank tops, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Teddies, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Tennis shirts, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Tennis skirts, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Training pants (i.e., underwear), infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "T-shirts, outerwear, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "T-shirts, underwear, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Underwear shirts, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Underwear shorts, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Underwear, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Uniform shirts (except team athletic), women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Uniforms (except team athletic), nontailored, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Uniforms, dress, tailored (e.g., fire fighter, military, police), women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Union suits, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Vests, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Warmup suits, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Washable service apparel, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Water resistant jackets and windbreakers, not tailored, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Water resistant outerwear, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Water resistant overcoats, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Water-repellent outerwear, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Water-repellent overcoats, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Wedding dresses, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315240,d: "Windbreakers, women's, girls', and infants', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Apparel, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Athletic uniforms, team, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Band uniforms cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Baseball uniforms cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Basketball uniforms cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Burial garments cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Capes, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Capes, waterproof (e.g., plastics, rubber, similar materials), cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Caps and gowns, academic, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Caps, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Caps, leather (except apparel contractors), manufacturing"},
{c:315280,d: "Clerical vestments cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Clothing, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Clothing, leather or sheep-lined (except apparel contractors), manufacturing"},
{c:315280,d: "Clothing, waterproof, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Coat linings, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Coats (including tailored), leather or sheep-lined (except apparel contractors), manufacturing"},
{c:315280,d: "Coats, artificial leather, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Coats, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Coats, leather (except apparel contractors), manufacturing"},
{c:315280,d: "Coats, waterproof (e.g., plastics, rubberized fabric, similar materials), cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Coats, waterproof (e.g., plastics, rubberized fabric, similar materials), rubberizing fabric and manufacturing coats"},
{c:315280,d: "Costumes (e.g., lodge, masquerade, theatrical) cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Fur accessories and trimmings (except apparel contractors) manufacturing"},
{c:315280,d: "Fur apparel (e.g., capes, coats, hats, jackets, neckpieces) (except apparel contractors) manufacturing"},
{c:315280,d: "Fur clothing (except apparel contractors) manufacturing"},
{c:315280,d: "Fur plates and trimmings (except apparel contractors) manufacturing"},
{c:315280,d: "Garments, leather or sheep-lined (except apparel contractors), manufacturing"},
{c:315280,d: "Glazing furs"},
{c:315280,d: "Glove linings, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Gowns (e.g., academic, choir, clerical) cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Gowns, hospital, surgical and patient, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Graduation caps and gowns cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Hats, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Hats, leather (except apparel contractors), manufacturing"},
{c:315280,d: "Jackets, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Jackets, leather (except welders') or sheep-lined (except apparel contractors), manufacturing"},
{c:315280,d: "Leather apparel (e.g., capes, coats, hats, jackets) (except apparel contractors) manufacturing"},
{c:315280,d: "Leather clothing (except apparel contractors) manufacturing"},
{c:315280,d: "Neckpieces, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Pants, leather (except apparel contractors), manufacturing"},
{c:315280,d: "Pants, rubber and rubberized fabric, made in the same establishment as the basic material"},
{c:315280,d: "Pants, vulcanized rubber, manufacturing"},
{c:315280,d: "Pants, waterproof outerwear, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Plastics gowns cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Plastics rainwear cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Pointing furs"},
{c:315280,d: "Ponchos and similar waterproof raincoats cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Prayer shawls cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Raincoats, rubber or rubberized fabric, manufacturing"},
{c:315280,d: "Raincoats, waterproof, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Sport coats, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Sport coats, leather (including artificial and tailored) (except apparel contractors), manufacturing"},
{c:315280,d: "Sports clothing, team uniforms, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Sweat bands cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Team athletic uniforms cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Theatrical costumes cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Trimmings, fur (except apparel contractors), manufacturing"},
{c:315280,d: "Uniform shirts, team athletic, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Uniforms, band, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Uniforms, team athletic, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Vestments, academic and clerical, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Vests, leather, fur, or sheep-lined (except apparel contractors), manufacturing"},
{c:315280,d: "Waterproof outerwear cut and sewn from purchased fabric (except apparel contractors)"},
{c:315280,d: "Waterproof outerwear, rubberizing fabric and manufacturing outerwear"},
{c:315990,d: "Apparel findings and trimmings cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Aprons, waterproof (e.g., plastics, rubberized fabric), rubberizing fabric and manufacturing aprons"},
{c:315990,d: "Aprons, waterproof (including rubberized fabric, plastics), cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Arm bands, elastic, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Ascots, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Baseball caps cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Bathing caps, rubber, manufacturing"},
{c:315990,d: "Belts, apparel (e.g., fabric, leather, vinyl), cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Belts, money, any material, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Bibs and aprons, waterproof (e.g., plastics, rubber, similar materials), cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Bibs and aprons, waterproof (e.g., plastics, rubber, similar materials), rubberizing fabric and manufacturing bibs and aprons"},
{c:315990,d: "Bow ties, men's and boys', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Caps (except fur, leather) cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Caps (i.e., apparel accessory) cut and sewn from purchased fabric (except fur, leather, apparel contractors)"},
{c:315990,d: "Caps, textiles, straw, fur-felt, and wool-felt, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Chauffeurs' hats and caps cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Coat trimmings fabric cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Cummerbunds cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Dress and semidress gloves cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Dress trimmings cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Findings, suit and coat (e.g., coat fronts, pockets), cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Glove linings (except fur) manufacturing"},
{c:315990,d: "Gloves and mittens (except athletic), leather, fabric, fur, or combinations, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Gloves and mittens, woven or knit, cut and sewn from purchased fabric (except apparel contractors), manufacturing"},
{c:315990,d: "Gloves, leather (except athletic, cut and sewn apparel contractors), manufacturing"},
{c:315990,d: "Handkerchiefs (except paper) cut and sewn from purchased fabric"},
{c:315990,d: "Harvest hats, straw, manufacturing"},
{c:315990,d: "Hat bodies (e.g., fur-felt, straw, wool-felt) cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Hat findings cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Hat linings and trimmings cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Hats (except fur, knitting mill products, leather) cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Hats, cloth, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Hats, fur-felt, straw, and wool-felt, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Hats, trimmed, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Headbands, women's and girls', cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Knit gloves cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Leather gloves or mittens (except athletic, cut and sewn apparel contractors) manufacturing"},
{c:315990,d: "Linings, hat, men's, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Millinery cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Millinery trimmings cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Mittens cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Mittens, leather (except apparel contractors), manufacturing"},
{c:315990,d: "Mittens, woven or knit, cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Mufflers cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Neckties cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Neckwear cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Opera hats cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Panama hats cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Police caps and hats (except protective head gear) cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Scarves cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Straw hats manufacturing"},
{c:315990,d: "Suit trimmings cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Suspenders cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Ties, men's and boys' hand sewn (except apparel contractors), manufacturing"},
{c:315990,d: "Uniform hats and caps cut and sewn from purchased fabric (except apparel contractors)"},
{c:315990,d: "Work gloves, leather (except apparel contractors), manufacturing"},
{c:316110,d: "Bag leather manufacturing"},
{c:316110,d: "Belting butts, curried or rough, manufacturing"},
{c:316110,d: "Belting leather, manufacturing"},
{c:316110,d: "Bookbinder's leather manufacturing"},
{c:316110,d: "Bridle leather manufacturing"},
{c:316110,d: "Buffings, russet, manufacturing"},
{c:316110,d: "Case leather manufacturing"},
{c:316110,d: "Chamois leather manufacturing"},
{c:316110,d: "Collar leather, manufacturing"},
{c:316110,d: "Coloring leather"},
{c:316110,d: "Converters, leather"},
{c:316110,d: "Currying furs"},
{c:316110,d: "Currying leather"},
{c:316110,d: "Cutting of leather"},
{c:316110,d: "Dressing (i.e., bleaching, blending, currying, scraping, tanning) furs"},
{c:316110,d: "Dressing hides"},
{c:316110,d: "Dyeing furs"},
{c:316110,d: "Dyeing leather"},
{c:316110,d: "Embossing leather"},
{c:316110,d: "Exotic leathers manufacturing"},
{c:316110,d: "Fancy leathers manufacturing"},
{c:316110,d: "Finishing hides and skins on a contract basis"},
{c:316110,d: "Finishing leather"},
{c:316110,d: "Fleshers, leather (i.e., flesh side of split leather), manufacturing"},
{c:316110,d: "Fur stripping"},
{c:316110,d: "Furs, dressed (e.g., bleached, curried, dyed, scraped, tanned), manufacturing"},
{c:316110,d: "Garment leather manufacturing"},
{c:316110,d: "Glove leather manufacturing"},
{c:316110,d: "Handbag leather manufacturing"},
{c:316110,d: "Harness leather manufacturing"},
{c:316110,d: "Hides and skins, finishing on a contract basis"},
{c:316110,d: "Hides, tanning, currying, dressing, and finishing"},
{c:316110,d: "Japanning of leather"},
{c:316110,d: "Lace leather manufacturing"},
{c:316110,d: "Latigo leather manufacturing"},
{c:316110,d: "Leather coloring, cutting, embossing, and japanning"},
{c:316110,d: "Leather converters"},
{c:316110,d: "Leather tanning, currying, and finishing"},
{c:316110,d: "Lining leather manufacturing"},
{c:316110,d: "Mechanical leather manufacturing"},
{c:316110,d: "Parchment leather manufacturing"},
{c:316110,d: "Patent leather manufacturing"},
{c:316110,d: "Pelts bleaching, currying, dyeing, scraping, and tanning"},
{c:316110,d: "Rawhide manufacturing"},
{c:316110,d: "Roller leather manufacturing"},
{c:316110,d: "Saddlery leather manufacturing"},
{c:316110,d: "Shearling (i.e., prepared sheepskin) manufacturing"},
{c:316110,d: "Skins, tanning, currying and finishing"},
{c:316110,d: "Skirting leather manufacturing"},
{c:316110,d: "Skivers, leather, manufacturing"},
{c:316110,d: "Sole leather manufacturing"},
{c:316110,d: "Specialty leathers manufacturing"},
{c:316110,d: "Splits, leather, manufacturing"},
{c:316110,d: "Strap leather manufacturing"},
{c:316110,d: "Sweatband leather manufacturing"},
{c:316110,d: "Tannery leather manufacturing"},
{c:316110,d: "Tanning and currying furs"},
{c:316110,d: "Upholstery leather manufacturing"},
{c:316110,d: "Upper leather manufacturing"},
{c:316110,d: "Vellum leather manufacturing"},
{c:316110,d: "Welting leather manufacturing"},
{c:316110,d: "Wet blues manufacturing"},
{c:316210,d: "Arctics, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Arctics, rubber or rubber soled fabric, manufacturing"},
{c:316210,d: "Athletic shoes manufacturing"},
{c:316210,d: "Athletic shoes, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Athletic shoes, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Ballet slippers manufacturing"},
{c:316210,d: "Beach sandals, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Beach sandals, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Boots, dress and casual, children's and infants', manufacturing"},
{c:316210,d: "Boots, dress and casual, men's, manufacturing"},
{c:316210,d: "Boots, dress and casual, women's, manufacturing"},
{c:316210,d: "Boots, hiking, children's and infants', manufacturing"},
{c:316210,d: "Boots, hiking, men's, manufacturing"},
{c:316210,d: "Boots, hiking, women's, manufacturing"},
{c:316210,d: "Boots, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Boots, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Canvas shoes, plastics soled fabric upper, manufacturing"},
{c:316210,d: "Canvas shoes, rubber soled fabric upper, manufacturing"},
{c:316210,d: "Casual shoes, children's and infants', manufacturing"},
{c:316210,d: "Casual shoes, men's, manufacturing"},
{c:316210,d: "Casual shoes, women's, manufacturing"},
{c:316210,d: "Children's shoes (except orthopedic extension) manufacturing"},
{c:316210,d: "Cleated athletic shoes manufacturing"},
{c:316210,d: "Climbing shoes, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Climbing shoes, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Dress shoes, children's and infants', manufacturing"},
{c:316210,d: "Dress shoes, men's, manufacturing"},
{c:316210,d: "Dress shoes, women's, manufacturing"},
{c:316210,d: "Footholds, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Footholds, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Footwear, athletic, manufacturing"},
{c:316210,d: "Footwear, children's (except orthopedic extension), manufacturing"},
{c:316210,d: "Footwear, children's, leather or vinyl upper with rubber or plastics soles, manufacturing"},
{c:316210,d: "Footwear, men's (except orthopedic extension), manufacturing"},
{c:316210,d: "Footwear, men's leather or vinyl upper with rubber or plastics soles, manufacturing"},
{c:316210,d: "Footwear, plastics or plastics soled fabric uppers, manufacturing"},
{c:316210,d: "Footwear, women's (except orthopedic extension), manufacturing"},
{c:316210,d: "Footwear, women's leather or vinyl upper with rubber or plastics soles, manufacturing"},
{c:316210,d: "Gaiters, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Gaiters, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Galoshes, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Galoshes, rubber, or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Golf shoes, men's cleated, manufacturing"},
{c:316210,d: "Golf shoes, women's cleated, manufacturing"},
{c:316210,d: "House slippers manufacturing"},
{c:316210,d: "House slippers, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "House slippers, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Infant's shoes manufacturing"},
{c:316210,d: "Leather footwear manufacturing"},
{c:316210,d: "Leather footwear, men's, manufacturing"},
{c:316210,d: "Leather footwear, slippers, manufacturing"},
{c:316210,d: "Leather footwear, women's, manufacturing"},
{c:316210,d: "Leather house slippers manufacturing"},
{c:316210,d: "Leather upper athletic footwear manufacturing"},
{c:316210,d: "Moccasins manufacturing"},
{c:316210,d: "Orthopedic shoes (except extension shoes), children's, manufacturing"},
{c:316210,d: "Orthopedic shoes (except extension shoes), men's, manufacturing"},
{c:316210,d: "Orthopedic shoes (except extension shoes), women's, manufacturing"},
{c:316210,d: "Overshoes, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Overshoes, rubber, or rubber soled fabric, manufacturing"},
{c:316210,d: "Pacs, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Pacs, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Protective footwear, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Protective footwear, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Pumps (i.e., dress shoes) manufacturing"},
{c:316210,d: "Sandals, children's, manufacturing"},
{c:316210,d: "Sandals, men's footwear, manufacturing"},
{c:316210,d: "Sandals, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Sandals, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Sandals, women's footwear, manufacturing"},
{c:316210,d: "Shoes, athletic, manufacturing"},
{c:316210,d: "Shoes, ballet, manufacturing"},
{c:316210,d: "Shoes, children's and infant's (except orthopedic extension), manufacturing"},
{c:316210,d: "Shoes, cleated or spiked, all materials, manufacturing"},
{c:316210,d: "Shoes, men's (except orthopedic extension), manufacturing"},
{c:316210,d: "Shoes, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Shoes, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Shoes, theatrical, manufacturing"},
{c:316210,d: "Shoes, women's (except orthopedic extension), manufacturing"},
{c:316210,d: "Shoes, wooden, manufacturing"},
{c:316210,d: "Shower sandals or slippers, rubber, manufacturing"},
{c:316210,d: "Slipper socks made from purchased socks"},
{c:316210,d: "Slippers, ballet, manufacturing"},
{c:316210,d: "Slippers, house, manufacturing"},
{c:316210,d: "Socks, slipper, made from purchased socks"},
{c:316210,d: "Vinyl upper athletic footwear manufacturing"},
{c:316210,d: "Water shoes, plastics or plastics soled fabric upper, manufacturing"},
{c:316210,d: "Water shoes, rubber or rubber soled fabric upper, manufacturing"},
{c:316210,d: "Work shoes manufacturing"},
{c:316992,d: "Handbags, women's, all materials (except precious metal), manufacturing"},
{c:316992,d: "Leather handbags and purses manufacturing"},
{c:316992,d: "Purses (except precious metal), women's, manufacturing"},
{c:316998,d: "Aprons for textile machinery, leather, manufacturing"},
{c:316998,d: "Aprons, leather (e.g., blacksmith's, welder's), manufacturing"},
{c:316998,d: "Attache cases, all materials, manufacturing"},
{c:316998,d: "Bags (i.e., luggage), all materials, manufacturing"},
{c:316998,d: "Bags, athletic, manufacturing"},
{c:316998,d: "Belt laces, leather, manufacturing"},
{c:316998,d: "Belting for machinery, leather, manufacturing"},
{c:316998,d: "Belts, leather safety, manufacturing"},
{c:316998,d: "Billfolds, all materials, manufacturing"},
{c:316998,d: "Binocular cases manufacturing"},
{c:316998,d: "Blacksmith's aprons, leather, manufacturing"},
{c:316998,d: "Boot and shoe cut stock and findings, leather, manufacturing"},
{c:316998,d: "Bows, shoe, leather, manufacturing"},
{c:316998,d: "Box toes (i.e., shoe cut stock), leather, manufacturing"},
{c:316998,d: "Boxes, hat (except paper or paperboard), manufacturing"},
{c:316998,d: "Boxes, leather, manufacturing"},
{c:316998,d: "Briefcases, all materials, manufacturing"},
{c:316998,d: "Camera carrying bags, all materials, manufacturing"},
{c:316998,d: "Caps, heel and toe, leather, manufacturing"},
{c:316998,d: "Card cases (except metal) manufacturing"},
{c:316998,d: "Cases, jewelry (except metal), manufacturing"},
{c:316998,d: "Cases, luggage, manufacturing"},
{c:316998,d: "Cases, musical instrument, manufacturing"},
{c:316998,d: "Checkbook covers (except metal) manufacturing"},
{c:316998,d: "Cigar cases (except metal) manufacturing"},
{c:316998,d: "Cigarette cases (except metal) manufacturing"},
{c:316998,d: "Clasps, shoe (leather), manufacturing"},
{c:316998,d: "Coin purses (except metal) manufacturing"},
{c:316998,d: "Collars and collar pads (i.e., harness) manufacturing"},
{c:316998,d: "Collars, dog, manufacturing"},
{c:316998,d: "Comb cases (except metal) manufacturing"},
{c:316998,d: "Compacts, solid leather, manufacturing"},
{c:316998,d: "Corners, luggage, leather, manufacturing"},
{c:316998,d: "Cosmetic bags (except metal) manufacturing"},
{c:316998,d: "Counters (i.e., shoe cut stock), leather, manufacturing"},
{c:316998,d: "Crops, riding, manufacturing"},
{c:316998,d: "Cut stock for boots and shoes manufacturing"},
{c:316998,d: "Desk sets, leather, manufacturing"},
{c:316998,d: "Dog furnishings (e.g., collars, harnesses, leashes, muzzles) manufacturing"},
{c:316998,d: "Eyeglass cases, all materials, manufacturing"},
{c:316998,d: "Feed bags for horses manufacturing"},
{c:316998,d: "Findings, boot and shoe, manufacturing"},
{c:316998,d: "Handbags (except metal), men's, manufacturing"},
{c:316998,d: "Handles (e.g., luggage, whip), leather, manufacturing"},
{c:316998,d: "Harnesses and harness parts, leather, manufacturing"},
{c:316998,d: "Harnesses, dog, manufacturing"},
{c:316998,d: "Heel caps, leather or metal, manufacturing"},
{c:316998,d: "Heel lifts, leather, manufacturing"},
{c:316998,d: "Heels, boot and shoe, leather, manufacturing"},
{c:316998,d: "Holsters, leather, manufacturing"},
{c:316998,d: "Horse boots and muzzles manufacturing"},
{c:316998,d: "Inner soles, leather, manufacturing"},
{c:316998,d: "Jackets, welder's, leather, manufacturing"},
{c:316998,d: "Key cases (except metal) manufacturing"},
{c:316998,d: "Laces (e.g., shoe), leather, manufacturing"},
{c:316998,d: "Lashes (i.e., whips) manufacturing"},
{c:316998,d: "Leashes, dog, manufacturing"},
{c:316998,d: "Leather belting manufacturing"},
{c:316998,d: "Leather cut stock for shoe and boot manufacturing"},
{c:316998,d: "Leather goods, small personal (e.g., coin purses, eyeglass cases, key cases), manufacturing"},
{c:316998,d: "Leather luggage manufacturing"},
{c:316998,d: "Leather welting manufacturing"},
{c:316998,d: "Leggings, welder's, leather, manufacturing"},
{c:316998,d: "Lifts, heel, leather, manufacturing"},
{c:316998,d: "Linings, boot and shoe, leather, manufacturing"},
{c:316998,d: "Luggage, all materials, manufacturing"},
{c:316998,d: "Mill strapping for textile mills, leather, manufacturing"},
{c:316998,d: "Musical instrument cases, all materials, manufacturing"},
{c:316998,d: "Novelties, leather (e.g., cigarette lighter covers, key fobs), manufacturing"},
{c:316998,d: "Pegs, leather shoe, manufacturing"},
{c:316998,d: "Personal leather goods (e.g., coin purses, eyeglass cases, key cases), small, manufacturing"},
{c:316998,d: "Purses (except precious metal), men's, manufacturing"},
{c:316998,d: "Quarters (i.e., shoe cut stock), leather, manufacturing"},
{c:316998,d: "Rands (i.e., shoe cut stock), leather, manufacturing"},
{c:316998,d: "Razor strops manufacturing"},
{c:316998,d: "Riding crops manufacturing"},
{c:316998,d: "Saddles and parts, leather, manufacturing"},
{c:316998,d: "Safety belts, leather, manufacturing"},
{c:316998,d: "Sample cases, all materials, manufacturing"},
{c:316998,d: "Satchels, all materials, manufacturing"},
{c:316998,d: "Seatbelts, leather, manufacturing"},
{c:316998,d: "Sewing cases (except metal) manufacturing"},
{c:316998,d: "Shanks, shoe, leather, manufacturing"},
{c:316998,d: "Shoe kits (i.e., cases), all materials, manufacturing"},
{c:316998,d: "Shoe soles, leather, manufacturing"},
{c:316998,d: "Sleeves, welder's, leather, manufacturing"},
{c:316998,d: "Soles, boot and shoe, leather, manufacturing"},
{c:316998,d: "Spats, leather, manufacturing"},
{c:316998,d: "Stays, shoe, leather, manufacturing"},
{c:316998,d: "Straps, leather, manufacturing"},
{c:316998,d: "Straps, watch (except metal), manufacturing"},
{c:316998,d: "Suitcases, all materials, manufacturing"},
{c:316998,d: "Taps, shoe, leather, manufacturing"},
{c:316998,d: "Textile leathers (e.g., apron picker leather, mill strapping) manufacturing"},
{c:316998,d: "Tips, shoe, leather, manufacturing"},
{c:316998,d: "Tobacco pouches (except metal) manufacturing"},
{c:316998,d: "Toe caps, leather, manufacturing"},
{c:316998,d: "Toilet kits and cases (except metal) manufacturing"},
{c:316998,d: "Tongues, boot and shoe, leather, manufacturing"},
{c:316998,d: "Top lifts, boot and shoe, leather, manufacturing"},
{c:316998,d: "Transmission belting, leather, manufacturing"},
{c:316998,d: "Traveling bags, all materials, manufacturing"},
{c:316998,d: "Trimmings, shoe, leather, manufacturing"},
{c:316998,d: "Trunks (i.e., luggage), all materials, manufacturing"},
{c:316998,d: "Uppers (i.e., shoe cut stock), leather, manufacturing"},
{c:316998,d: "Valises, all materials, manufacturing"},
{c:316998,d: "Vamps, leather, manufacturing"},
{c:316998,d: "Vanity cases, leather, manufacturing"},
{c:316998,d: "Wallets (except metal) manufacturing"},
{c:316998,d: "Wardrobe bags (i.e., luggage) manufacturing"},
{c:316998,d: "Watch bands (except metal) manufacturing"},
{c:316998,d: "Welders' aprons, leather, manufacturing"},
{c:316998,d: "Welders' jackets, leggings, and sleeves, leather, manufacturing"},
{c:316998,d: "Whips, horse, manufacturing"},
{c:316998,d: "Whipstocks manufacturing"},
{c:321113,d: "Beams, wood, made from logs or bolts"},
{c:321113,d: "Boards, wood, made from logs or bolts"},
{c:321113,d: "Ceiling lumber, made from logs or bolts"},
{c:321113,d: "Chipper mills (except portable)"},
{c:321113,d: "Custom sawmills"},
{c:321113,d: "Dimension lumber, hardwood, made from logs or bolts"},
{c:321113,d: "Dimension lumber, made from logs or bolts"},
{c:321113,d: "Dimension lumber, softwood, made from logs or bolts"},
{c:321113,d: "Flitches (i.e., veneer stock) made in sawmills"},
{c:321113,d: "Lumber (i.e., rough, dressed) made from logs or bolts"},
{c:321113,d: "Lumber, hardwood dimension, made from logs or bolts"},
{c:321113,d: "Lumber, softwood dimension, made from logs or bolts"},
{c:321113,d: "Poles, wood, made from log or bolts"},
{c:321113,d: "Pressure treated lumber made from logs or bolts and treated"},
{c:321113,d: "Sawdust and shavings (i.e., sawmill byproducts) manufacturing"},
{c:321113,d: "Sawed lumber made in sawmills"},
{c:321113,d: "Sawmills"},
{c:321113,d: "Shakes (i.e., hand split shingles) manufacturing"},
{c:321113,d: "Shingle mills, wood"},
{c:321113,d: "Shingles, wood, sawed or hand split, manufacturing"},
{c:321113,d: "Siding mills, wood"},
{c:321113,d: "Siding, dressed lumber, manufacturing"},
{c:321113,d: "Stud mills"},
{c:321113,d: "Ties, railroad, made from logs or bolts"},
{c:321113,d: "Ties, wood, made from logs or bolts"},
{c:321113,d: "Timbers, made from logs or bolts"},
{c:321113,d: "Wood chips made in sawmills"},
{c:321114,d: "Bridge and trestle parts, wood, treating"},
{c:321114,d: "Creosoting of wood"},
{c:321114,d: "Crossties, treating"},
{c:321114,d: "Flooring, wood block, treating"},
{c:321114,d: "Millwork, treating"},
{c:321114,d: "Mine props, treating"},
{c:321114,d: "Mine ties, wood, treated, manufacturing"},
{c:321114,d: "Pilings, foundation and marine construction, treating"},
{c:321114,d: "Pilings, round wood, cutting and treating"},
{c:321114,d: "Pilings, wood, treating"},
{c:321114,d: "Poles, round wood, cutting and treating"},
{c:321114,d: "Poles, wood, treating"},
{c:321114,d: "Posts, round wood, cutting and treating"},
{c:321114,d: "Posts, wood, treating"},
{c:321114,d: "Preserving purchased wood and wood products"},
{c:321114,d: "Pressure treated lumber made from purchased lumber"},
{c:321114,d: "Railroad ties (i.e., bridge, cross, switch) treating"},
{c:321114,d: "Structural lumber and timber, treating"},
{c:321114,d: "Ties, wood railroad bridge, cross, and switch, treating"},
{c:321114,d: "Timber, structural, treating"},
{c:321114,d: "Treating purchased wood and wood products"},
{c:321114,d: "Treating wood products with creosote or other preservatives"},
{c:321114,d: "Trestle parts, wood, treating"},
{c:321114,d: "Wood fence (i.e., pickets, poling, rails), treating"},
{c:321114,d: "Wood products, creosoting purchased wood products"},
{c:321211,d: "Hardwood plywood composites manufacturing"},
{c:321211,d: "Hardwood veneer or plywood manufacturing"},
{c:321211,d: "Panels, hardwood plywood, manufacturing"},
{c:321211,d: "Plywood, faced with nonwood materials, hardwood, manufacturing"},
{c:321211,d: "Plywood, hardwood faced, manufacturing"},
{c:321211,d: "Plywood, hardwood, manufacturing"},
{c:321211,d: "Prefinished hardwood plywood manufacturing"},
{c:321211,d: "Veneer mills, hardwood"},
{c:321212,d: "Panels, softwood plywood, manufacturing"},
{c:321212,d: "Plywood, faced with nonwood materials, softwood, manufacturing"},
{c:321212,d: "Plywood, softwood faced, manufacturing"},
{c:321212,d: "Plywood, softwood, manufacturing"},
{c:321212,d: "Prefinished softwood plywood manufacturing"},
{c:321212,d: "Softwood plywood composites manufacturing"},
{c:321212,d: "Softwood veneer or plywood manufacturing"},
{c:321212,d: "Veneer mills, softwood"},
{c:321213,d: "Arches, glue laminated or pre-engineered wood, manufacturing"},
{c:321213,d: "Fabricated structural wood members (except trusses) manufacturing"},
{c:321213,d: "Finger joint lumber manufacturing"},
{c:321213,d: "I-joists, wood, fabricating"},
{c:321213,d: "Laminated structural wood members (except trusses) manufacturing"},
{c:321213,d: "Laminated veneer lumber (LVL) manufacturing"},
{c:321213,d: "Lumber, parallel strand, manufacturing"},
{c:321213,d: "LVL (laminated veneer lumber) manufacturing"},
{c:321213,d: "Parallel strand lumber manufacturing"},
{c:321213,d: "Structural members, glue laminated or pre-engineered wood, manufacturing"},
{c:321213,d: "Structural wood members (except trusses), fabricated, manufacturing"},
{c:321213,d: "Timbers, structural, glue laminated or pre-engineered wood, manufacturing"},
{c:321213,d: "Wood I-joists manufacturing"},
{c:321214,d: "Floor trusses, wood, manufacturing"},
{c:321214,d: "Roof trusses, wood, manufacturing"},
{c:321214,d: "Trusses, glue laminated or pre-engineered wood, manufacturing"},
{c:321214,d: "Trusses, wood roof or floor, manufacturing"},
{c:321214,d: "Trusses, wood, glue laminated or metal connected, manufacturing"},
{c:321219,d: "Board, bagasse, manufacturing"},
{c:321219,d: "Board, particle, manufacturing"},
{c:321219,d: "Chipboard (i.e., particle core, wood chip face) manufacturing"},
{c:321219,d: "Compression modified wood manufacturing"},
{c:321219,d: "Densified wood manufacturing"},
{c:321219,d: "Fiberboard manufacturing"},
{c:321219,d: "Flakeboard manufacturing"},
{c:321219,d: "Hardboard manufacturing"},
{c:321219,d: "Insulation board, cellular fiber or hard pressed wood, manufacturing"},
{c:321219,d: "Lath, fiber, manufacturing"},
{c:321219,d: "MDF (medium density fiberboard) manufacturing"},
{c:321219,d: "Medium density fiberboard (MDF) manufacturing"},
{c:321219,d: "Oriented strandboard (OSB) manufacturing"},
{c:321219,d: "OSB (oriented strandboard) manufacturing"},
{c:321219,d: "Particleboard manufacturing"},
{c:321219,d: "Reconstituted wood panels manufacturing"},
{c:321219,d: "Reconstituted wood sheets and boards manufacturing"},
{c:321219,d: "Strandboard, oriented, manufacturing"},
{c:321219,d: "Waferboard manufacturing"},
{c:321911,d: "Door frames and sash, wood and covered wood, manufacturing"},
{c:321911,d: "Door jambs, wood, manufacturing"},
{c:321911,d: "Door units, prehung, wood and covered wood, manufacturing"},
{c:321911,d: "Doors, combination screen-storm, wood, manufacturing"},
{c:321911,d: "Doors, wood and covered wood, manufacturing"},
{c:321911,d: "Frames, door and window, wood, manufacturing"},
{c:321911,d: "Garage doors, wood, manufacturing"},
{c:321911,d: "Louver windows and doors, made from purchased glass with wood frame"},
{c:321911,d: "Sash, door and window, wood and covered wood, manufacturing"},
{c:321911,d: "Screens, door and window, wood framed, manufacturing"},
{c:321911,d: "Storm doors and windows, wood framed, manufacturing"},
{c:321911,d: "Window frames and sash, wood and covered wood, manufacturing"},
{c:321911,d: "Window screens, wood framed, manufacturing"},
{c:321911,d: "Window units, wood and covered wood, manufacturing"},
{c:321911,d: "Windows, louver, wood, manufacturing"},
{c:321911,d: "Windows, wood and covered wood, manufacturing"},
{c:321911,d: "Wood door frames and sash manufacturing"},
{c:321911,d: "Wood window frames and sash manufacturing"},
{c:321912,d: "Blanks, wood (e.g., bowling pins, handles, textile machinery accessories), manufacturing"},
{c:321912,d: "Boards, wood, resawing purchased lumber"},
{c:321912,d: "Bobbin blocks and blanks, wood, manufacturing"},
{c:321912,d: "Bowling pin blanks manufacturing"},
{c:321912,d: "Brush blocks, wood, turned and shaped"},
{c:321912,d: "Cants, resawed (lumber), manufacturing"},
{c:321912,d: "Ceiling lumber, dressed, resawing purchased lumber"},
{c:321912,d: "Cut stock manufacturing"},
{c:321912,d: "Dimension lumber, hardwood, resawing purchased lumber"},
{c:321912,d: "Dimension lumber, resawing purchased lumber"},
{c:321912,d: "Dimension lumber, softwood, resawing purchased lumber"},
{c:321912,d: "Dimension stock, hardwood, manufacturing"},
{c:321912,d: "Dimension stock, softwood, manufacturing"},
{c:321912,d: "Dimension stock, wood, manufacturing"},
{c:321912,d: "Furniture dimension stock, hardwood, unfinished, manufacturing"},
{c:321912,d: "Furniture dimension stock, softwood, unfinished, manufacturing"},
{c:321912,d: "Furniture dimension stock, unfinished wood, manufacturing"},
{c:321912,d: "Furniture squares, unfinished hardwood, manufacturing"},
{c:321912,d: "Gun stock blanks manufacturing"},
{c:321912,d: "Handle blanks, wood, manufacturing"},
{c:321912,d: "Handle stock, sawed or planed, manufacturing"},
{c:321912,d: "Hardwood dimension lumber and stock, resawing purchased lumber"},
{c:321912,d: "Ladder rounds or rungs, hardwood, manufacturing"},
{c:321912,d: "Lath, wood, manufacturing"},
{c:321912,d: "Lathmills, wood"},
{c:321912,d: "Lumber, hardwood dimension, resawing purchased lumber"},
{c:321912,d: "Lumber, softwood dimension, resawing purchased lumber"},
{c:321912,d: "Planing mills (except millwork)"},
{c:321912,d: "Planing purchased lumber"},
{c:321912,d: "Resawing purchased lumber"},
{c:321912,d: "Rounds or rungs, furniture, hardwood, manufacturing"},
{c:321912,d: "Sawed lumber, resawing purchased lumber"},
{c:321912,d: "Snow fence lath manufacturing"},
{c:321912,d: "Softwood dimension lumber and stock, resawing purchased lumber"},
{c:321912,d: "Stock, chair, unfinished hardwood, manufacturing"},
{c:321912,d: "Studs, resawing purchased lumber"},
{c:321912,d: "Turnings, furniture, unfinished wood, manufacturing"},
{c:321912,d: "Vehicle stock, hardwood, manufacturing"},
{c:321918,d: "Baseboards, floor, wood, manufacturing"},
{c:321918,d: "Brackets, wood, manufacturing"},
{c:321918,d: "Clear and finger joint wood moldings manufacturing"},
{c:321918,d: "Columns, porch, wood, manufacturing"},
{c:321918,d: "Cornices, wood, manufacturing"},
{c:321918,d: "Decorative wood moldings (e.g., base, chair rail, crown, shoe) manufacturing"},
{c:321918,d: "Door shutters, wood, manufacturing"},
{c:321918,d: "Door trim, wood molding, manufacturing"},
{c:321918,d: "Exterior wood shutters manufacturing"},
{c:321918,d: "Floor baseboards, wood, manufacturing"},
{c:321918,d: "Flooring, wood, manufacturing"},
{c:321918,d: "Moldings, clear and finger joint wood, manufacturing"},
{c:321918,d: "Moldings, wood and covered wood, manufacturing"},
{c:321918,d: "Newel posts, wood, manufacturing"},
{c:321918,d: "Ornamental woodwork (e.g., cornices, mantels) manufacturing"},
{c:321918,d: "Panel work, wood millwork, manufacturing"},
{c:321918,d: "Parquet flooring, hardwood, manufacturing"},
{c:321918,d: "Parquetry, hardwood, manufacturing"},
{c:321918,d: "Planing mills, millwork"},
{c:321918,d: "Porch work (e.g., columns, newels, rails, trellises), wood, manufacturing"},
{c:321918,d: "Railings, wood stair, manufacturing"},
{c:321918,d: "Shutters, door and window, wood and covered wood, manufacturing"},
{c:321918,d: "Shutters, wood, manufacturing"},
{c:321918,d: "Stair railings, wood, manufacturing"},
{c:321918,d: "Stairwork (e.g., newel posts, railings, staircases, stairs), wood, manufacturing"},
{c:321918,d: "Trellises, wood, manufacturing"},
{c:321918,d: "Trim, wood and covered wood, manufacturing"},
{c:321918,d: "Venetian blind slats, wood, manufacturing"},
{c:321918,d: "Wainscots, wood, manufacturing"},
{c:321918,d: "Weather strip, wood, manufacturing"},
{c:321918,d: "Window trim, wood and covered wood moldings, manufacturing"},
{c:321918,d: "Wood flooring manufacturing"},
{c:321918,d: "Wood moldings (e.g., prefinished, unfinished), clear and finger joint, manufacturing"},
{c:321918,d: "Wood shutters manufacturing"},
{c:321920,d: "Ammunition boxes, wood, manufacturing"},
{c:321920,d: "Barrel heading and staves manufacturing"},
{c:321920,d: "Barrels, wood, coopered, manufacturing"},
{c:321920,d: "Baskets, wood (e.g., round stave, veneer), manufacturing"},
{c:321920,d: "Berry crates, wood, wirebound, manufacturing"},
{c:321920,d: "Berry cups, veneer and splint, manufacturing"},
{c:321920,d: "Box cleats, wood, manufacturing"},
{c:321920,d: "Box shook manufacturing"},
{c:321920,d: "Boxes, cigar, wood or part wood, manufacturing"},
{c:321920,d: "Boxes, jewelry, wood or part wood, manufacturing"},
{c:321920,d: "Boxes, wood, manufacturing"},
{c:321920,d: "Boxes, wood, plain or fabric covered, nailed or lock corner, manufacturing"},
{c:321920,d: "Buckets, wood, coopered, manufacturing"},
{c:321920,d: "Cases, shipping, wood, wirebound, manufacturing"},
{c:321920,d: "Cases, wood packing, nailed or lock corner, manufacturing"},
{c:321920,d: "Cases, wood shipping, nailed or lock corner, manufacturing"},
{c:321920,d: "Casks, wood, coopered, manufacturing"},
{c:321920,d: "Chests for tools, wood, manufacturing"},
{c:321920,d: "Chicken coops (i.e., crates), wood, wirebound for shipping poultry, manufacturing"},
{c:321920,d: "Cigar boxes, wood and part wood, manufacturing"},
{c:321920,d: "Containers, wood, manufacturing"},
{c:321920,d: "Cooperage manufacturing"},
{c:321920,d: "Cooperage stock (e.g., heading, hoops, staves) manufacturing"},
{c:321920,d: "Cooperage stock mills"},
{c:321920,d: "Coopered tubs manufacturing"},
{c:321920,d: "Crates (e.g., berry, butter, fruit, vegetable) made of wood, wirebound, manufacturing"},
{c:321920,d: "Drums, plywood, manufacturing"},
{c:321920,d: "Drums, shipping, wood, wirebound, manufacturing"},
{c:321920,d: "Egg cases, wood, manufacturing"},
{c:321920,d: "Firkins and kits, wood, coopered, manufacturing"},
{c:321920,d: "Flats, wood, greenhouse, manufacturing"},
{c:321920,d: "Fruit baskets, veneer and splint, manufacturing"},
{c:321920,d: "Fruit crates, wood, wirebound, manufacturing"},
{c:321920,d: "Heading, barrel (i.e., cooperage stock), wood, manufacturing"},
{c:321920,d: "Hogsheads, coopered wood, manufacturing"},
{c:321920,d: "Hoops, sawed or split wood for tight or slack cooperage, manufacturing"},
{c:321920,d: "Hot tubs, coopered, manufacturing"},
{c:321920,d: "Kegs, wood, coopered, manufacturing"},
{c:321920,d: "Packing cases, wood, nailed or lock corner, manufacturing"},
{c:321920,d: "Packing crates, wood, manufacturing"},
{c:321920,d: "Pails, coopered wood, manufacturing"},
{c:321920,d: "Pails, plywood, manufacturing"},
{c:321920,d: "Pails, wood, manufacturing"},
{c:321920,d: "Pallet containers, wood or wood and metal combination, manufacturing"},
{c:321920,d: "Pallet parts, wood, manufacturing"},
{c:321920,d: "Pallets, wood or wood and metal combination, manufacturing"},
{c:321920,d: "Round stave baskets (e.g., fruit, vegetable) manufacturing"},
{c:321920,d: "Shipping cases and drums, wood, wirebound, manufacturing"},
{c:321920,d: "Shipping cases, wood, nailed or lock corner, manufacturing"},
{c:321920,d: "Shipping crates, wood, manufacturing"},
{c:321920,d: "Shook, box, manufacturing"},
{c:321920,d: "Skids and pallets, wood or wood and metal combination, manufacturing"},
{c:321920,d: "Splint baskets for fruits and vegetables, manufacturing"},
{c:321920,d: "Staves, barrel, sawed or split, manufacturing"},
{c:321920,d: "Tanks, wood, coopered, manufacturing"},
{c:321920,d: "Tobacco hogshead stock, manufacturing"},
{c:321920,d: "Tobacco hogsheads, manufacturing"},
{c:321920,d: "Tool chests, wood, manufacturing"},
{c:321920,d: "Trays, carrier, wood, manufacturing"},
{c:321920,d: "Vegetable baskets, veneer and splint, manufacturing"},
{c:321920,d: "Vegetable crates, wood, wirebound, manufacturing"},
{c:321920,d: "Veneer baskets, for fruits and vegetables, manufacturing"},
{c:321991,d: "Buildings, mobile, commercial use, manufacturing"},
{c:321991,d: "Houses, prefabricated mobile homes, manufacturing"},
{c:321991,d: "Manufactured (mobile) buildings for commercial use (e.g., banks, offices) manufacturing"},
{c:321991,d: "Manufactured (mobile) classrooms manufacturing"},
{c:321991,d: "Manufactured (mobile) homes manufacturing"},
{c:321991,d: "Mobile homes manufacturing"},
{c:321992,d: "Buildings, prefabricated, wood, manufacturing"},
{c:321992,d: "Chicken coops, prefabricated, wood, manufacturing"},
{c:321992,d: "Corn cribs, prefabricated, wood, manufacturing"},
{c:321992,d: "Farm buildings, prefabricated wood, manufacturing"},
{c:321992,d: "Garages, prefabricated wood, manufacturing"},
{c:321992,d: "Geodesic domes, prefabricated, wood, manufacturing"},
{c:321992,d: "Houses, prefabricated, wood (except mobile homes), manufacturing"},
{c:321992,d: "Log cabins, prefabricated wood, manufacturing"},
{c:321992,d: "Panels, prefabricated wood building, manufacturing"},
{c:321992,d: "Prefabricated homes (except mobile homes), wood, manufacturing"},
{c:321992,d: "Prefabricated wood buildings manufacturing"},
{c:321992,d: "Sauna rooms, prefabricated, wood, manufacturing"},
{c:321992,d: "Sections, prefabricated wood building, manufacturing"},
{c:321992,d: "Sheds (e.g., garden, storage, utility), prefabricated wood, manufacturing"},
{c:321999,d: "Applicators, wood, manufacturing"},
{c:321999,d: "Audio cabinets (i.e., housings), wood, manufacturing"},
{c:321999,d: "Bentwood (steam bent) products (except furniture) manufacturing"},
{c:321999,d: "Blocks, tackle, wood, manufacturing"},
{c:321999,d: "Blocks, tailors' pressing wood, manufacturing"},
{c:321999,d: "Boards, bulletin, wood and cork, manufacturing"},
{c:321999,d: "Boards, wood (e.g., clip, ironing, meat, pastry), manufacturing"},
{c:321999,d: "Boot and shoe lasts, all materials, manufacturing"},
{c:321999,d: "Bottle corks manufacturing"},
{c:321999,d: "Bottle covers, willow, rattan, and reed, manufacturing"},
{c:321999,d: "Bowls, wood, turned and shaped, manufacturing"},
{c:321999,d: "Bulletin boards, wood and cork, manufacturing"},
{c:321999,d: "Bungs, wood, manufacturing"},
{c:321999,d: "Buoys, cork, manufacturing"},
{c:321999,d: "Burnt wood articles manufacturing"},
{c:321999,d: "Bushings, wood, manufacturing"},
{c:321999,d: "Cabinets (i.e., housings), wood (e.g., sewing machines, stereo, television), manufacturing"},
{c:321999,d: "Carpets, cork, manufacturing"},
{c:321999,d: "Clipboards, wood, manufacturing"},
{c:321999,d: "Cloth winding reels, wood, manufacturing"},
{c:321999,d: "Clothes dryers (clothes horses), wood, manufacturing"},
{c:321999,d: "Clothes poles, wood, manufacturing"},
{c:321999,d: "Clothespins, wood, manufacturing"},
{c:321999,d: "Cork products (except gaskets) manufacturing"},
{c:321999,d: "Corks, bottle, manufacturing"},
{c:321999,d: "Covers, bottle and demijohn, willow, rattan, and reed, manufacturing"},
{c:321999,d: "Curtain stretchers, wood, manufacturing"},
{c:321999,d: "Dishes, wood, manufacturing"},
{c:321999,d: "Dowels, wood, manufacturing"},
{c:321999,d: "Excelsior (e.g., pads, wrappers) manufacturing"},
{c:321999,d: "Extension ladders, wood, manufacturing"},
{c:321999,d: "Extension planks, wood, manufacturing"},
{c:321999,d: "Fencing, prefabricated sections, wood, manufacturing"},
{c:321999,d: "Fencing, wood (except rough pickets, poles, and rails), manufacturing"},
{c:321999,d: "Firewood and fuel wood containing fuel binder manufacturing"},
{c:321999,d: "Flagpoles, wood, manufacturing"},
{c:321999,d: "Flour, wood, manufacturing"},
{c:321999,d: "Forms, display, boot and shoe, all materials, manufacturing"},
{c:321999,d: "Furniture inlays manufacturing"},
{c:321999,d: "Garment hangers, wood, manufacturing"},
{c:321999,d: "Gavels, wood, manufacturing"},
{c:321999,d: "Grain measures, wood, turned and shaped, manufacturing"},
{c:321999,d: "Hammers, wood, meat, manufacturing"},
{c:321999,d: "Handles (e.g., broom, mop, handtool), wood, manufacturing"},
{c:321999,d: "Hangers, wooden, garment, manufacturing"},
{c:321999,d: "Heels, boot and shoe, finished wood, manufacturing"},
{c:321999,d: "Household-type woodenware manufacturing"},
{c:321999,d: "Hubs, wood, manufacturing"},
{c:321999,d: "Insulating materials, cork, manufacturing"},
{c:321999,d: "Ironing boards, wood, manufacturing"},
{c:321999,d: "Kiln drying lumber"},
{c:321999,d: "Kitchenware, wood, manufacturing"},
{c:321999,d: "Knobs, wood, manufacturing"},
{c:321999,d: "Ladder jacks, wood, manufacturing"},
{c:321999,d: "Ladders, extension, wood, manufacturing"},
{c:321999,d: "Last sole patterns, all materials, manufacturing"},
{c:321999,d: "Lumber, kiln drying"},
{c:321999,d: "Mallets, wood, manufacturing"},
{c:321999,d: "Marquetry, wood, manufacturing"},
{c:321999,d: "Mashers, potato, wood, manufacturing"},
{c:321999,d: "Masts, wood, manufacturing"},
{c:321999,d: "Mauls, wood, manufacturing"},
{c:321999,d: "Novelties, wood fiber, manufacturing"},
{c:321999,d: "Oars, wood, manufacturing"},
{c:321999,d: "Paddles, wood, manufacturing"},
{c:321999,d: "Pads, excelsior, wood, manufacturing"},
{c:321999,d: "Pads, table, rattan, reed, and willow, manufacturing"},
{c:321999,d: "Paint sticks, wood, manufacturing"},
{c:321999,d: "Pastry boards, wood, manufacturing"},
{c:321999,d: "Pencil slats, wood, manufacturing"},
{c:321999,d: "Plugs, wood, manufacturing"},
{c:321999,d: "Pressed logs of sawdust and other wood particles, nonpetroleum binder, manufacturing"},
{c:321999,d: "Pressing blocks, wood, tailor's, manufacturing"},
{c:321999,d: "Pulleys, wood, manufacturing"},
{c:321999,d: "Rails (except rough), wood fence, manufacturing"},
{c:321999,d: "Rattan ware (except furniture) manufacturing"},
{c:321999,d: "Reed ware (except furniture) manufacturing"},
{c:321999,d: "Reels, plywood, manufacturing"},
{c:321999,d: "Reels, wood, manufacturing"},
{c:321999,d: "Rollers, wood, manufacturing"},
{c:321999,d: "Rolling pins, wood, manufacturing"},
{c:321999,d: "Rulers and rules (except slide), wood, manufacturing"},
{c:321999,d: "Saddle trees, wood, manufacturing"},
{c:321999,d: "Sawdust, regrinding"},
{c:321999,d: "Scoops, wood, manufacturing"},
{c:321999,d: "Seat covers, rattan, manufacturing"},
{c:321999,d: "Seats, toilet, wood, manufacturing"},
{c:321999,d: "Sewing machine cabinets, wood, manufacturing"},
{c:321999,d: "Shoe display forms, all materials, manufacturing"},
{c:321999,d: "Shoe stretchers manufacturing"},
{c:321999,d: "Shoe trees manufacturing"},
{c:321999,d: "Skewers, wood, manufacturing"},
{c:321999,d: "Snow fence, sections or rolls, manufacturing"},
{c:321999,d: "Spars, wood, manufacturing"},
{c:321999,d: "Speaker cabinets (i.e., housings), wood, manufacturing"},
{c:321999,d: "Spigots, wood, manufacturing"},
{c:321999,d: "Spokes, wood, manufacturing"},
{c:321999,d: "Spools (except for textile machinery), wood, manufacturing"},
{c:321999,d: "Stakes, surveyor's, wood, manufacturing"},
{c:321999,d: "Stepladders, wood, manufacturing"},
{c:321999,d: "Stereo cabinets (i.e., housings), wood, manufacturing"},
{c:321999,d: "Stoppers, cork, manufacturing"},
{c:321999,d: "Straw baskets manufacturing"},
{c:321999,d: "Stretchers, curtain, wood, manufacturing"},
{c:321999,d: "Surveyor's stakes, wood, manufacturing"},
{c:321999,d: "Tackle blocks, wood, manufacturing"},
{c:321999,d: "Television cabinets (i.e., housings), wood, manufacturing"},
{c:321999,d: "Tent poles, wood, manufacturing"},
{c:321999,d: "Tile, cork, manufacturing"},
{c:321999,d: "Toilet seats, wood, manufacturing"},
{c:321999,d: "Tool handles, wood, turned and shaped, manufacturing"},
{c:321999,d: "Toothpicks, wood, manufacturing"},
{c:321999,d: "Trays, wood, wicker, and bagasse, manufacturing"},
{c:321999,d: "Trophy bases, wood, manufacturing"},
{c:321999,d: "Veneer work, inlaid, manufacturing"},
{c:321999,d: "Washboards, wood and part wood, manufacturing"},
{c:321999,d: "Webbing, cane, reed, and rattan, manufacturing"},
{c:321999,d: "Willow ware (except furniture) manufacturing"},
{c:321999,d: "Wood flour manufacturing"},
{c:321999,d: "Wood heel blocks manufacturing"},
{c:321999,d: "Wood heels, finished, manufacturing"},
{c:321999,d: "Wood pellets manufacturing"},
{c:321999,d: "Wood wool (excelsior) manufacturing"},
{c:321999,d: "Woodenware, kitchen and household, manufacturing"},
{c:321999,d: "Wrappers, excelsior, manufacturing"},
{c:321999,d: "Yardsticks, wood, manufacturing"},
{c:322110,d: "Deinking plants"},
{c:322110,d: "Deinking recovered paper"},
{c:322110,d: "Groundwood pulp manufacturing"},
{c:322110,d: "Pulp manufacturing (i.e., chemical, mechanical, or semichemical processes) without making paper"},
{c:322110,d: "Pulp manufacturing (made from bagasse, linters, rags, straw, wastepaper, or wood) without making paper"},
{c:322110,d: "Pulp mills not making paper or paperboard"},
{c:322110,d: "Wood pulp manufacturing"},
{c:322121,d: "Absorbent paper stock manufacturing"},
{c:322121,d: "Asphalt paper made in paper mills"},
{c:322121,d: "Bond paper made in paper mills"},
{c:322121,d: "Book paper, coated, made in paper mills"},
{c:322121,d: "Bristols paper stock manufacturing"},
{c:322121,d: "Building paper stock manufacturing"},
{c:322121,d: "Cigarette paper made in paper mills"},
{c:322121,d: "Construction paper, school and art, made in paper mills"},
{c:322121,d: "Cotton fiber paper stock manufacturing"},
{c:322121,d: "Diapers, disposable, made in paper mills"},
{c:322121,d: "Facial tissues made in paper mills"},
{c:322121,d: "Felts, asphalt, made in paper mills"},
{c:322121,d: "Glassine wrapping paper made in paper mills"},
{c:322121,d: "Groundwood paper, coated, laminated, or treated in paper mills"},
{c:322121,d: "Groundwood paper, coated, made in paper mills"},
{c:322121,d: "Kraft paper stock manufacturing"},
{c:322121,d: "Looseleaf fillers and paper made in paper mills"},
{c:322121,d: "Napkins, table, made in paper mills"},
{c:322121,d: "Office paper (e.g., computer printer, photocopy, plain paper) made in paper mills"},
{c:322121,d: "Paper (except newsprint, uncoated groundwood) manufacturing"},
{c:322121,d: "Paper (except newsprint, uncoated groundwood) products made in paper mills"},
{c:322121,d: "Paper (except newsprint, uncoated groundwood), coated, laminated or treated, made in paper mills"},
{c:322121,d: "Paper mills (except newsprint, uncoated groundwood paper mills)"},
{c:322121,d: "Paper stock for conversion into paper products (e.g., bag and sack stock, envelope stock, tissue stock, wallpaper stock) manufacturing"},
{c:322121,d: "Paper towels made in paper mills"},
{c:322121,d: "Paper, asphalt, made in paper mills"},
{c:322121,d: "Pulp and paper (except groundwood, newsprint) combined manufacturing"},
{c:322121,d: "Pulp mills producing paper (except groundwood, newsprint)"},
{c:322121,d: "Sanitary napkins and tampons made in paper mills"},
{c:322121,d: "Sanitary paper products (except newsprint, uncoated groundwood) made in paper mills"},
{c:322121,d: "Sanitary paper stock manufacturing"},
{c:322121,d: "Sanitary products made in paper mills"},
{c:322121,d: "Saturated felts made in paper mills"},
{c:322121,d: "Sheathing paper (except newsprint, uncoated groundwood) made in paper mills"},
{c:322121,d: "Tablets (e.g., memo, note, writing) made in paper mills"},
{c:322121,d: "Tar paper, building and roofing, made in paper mills"},
{c:322121,d: "Tissue paper stock manufacturing"},
{c:322121,d: "Toilet paper made in paper mills"},
{c:322121,d: "Towels, paper, made in paper mills"},
{c:322121,d: "Writing paper made in paper mills"},
{c:322122,d: "Groundwood paper products (e.g., publication and printing paper, tablet stock, wallpaper base) made in newsprint mills"},
{c:322122,d: "Groundwood paper, newsprint, made in paper mills"},
{c:322122,d: "Newsprint mills"},
{c:322122,d: "Newsprint paper, manufacturing"},
{c:322122,d: "Paper mills, newsprint"},
{c:322122,d: "Paper mills, uncoated groundwood"},
{c:322122,d: "Paper, newsprint and uncoated groundwood, manufacturing"},
{c:322122,d: "Pulp and newsprint combined manufacturing"},
{c:322122,d: "Pulp mills and groundwood paper, uncoated and untreated, manufacturing"},
{c:322122,d: "Pulp mills producing newsprint paper"},
{c:322122,d: "Uncoated groundwood paper mills"},
{c:322130,d: "Binder's board manufacturing"},
{c:322130,d: "Bristols board stock manufacturing"},
{c:322130,d: "Cardboard stock manufacturing"},
{c:322130,d: "Chipboard (i.e., paperboard) stock manufacturing"},
{c:322130,d: "Coated board made in paperboard mills"},
{c:322130,d: "Container board stock manufacturing"},
{c:322130,d: "Folding boxboard stock manufacturing"},
{c:322130,d: "Kraft linerboard manufacturing"},
{c:322130,d: "Leatherboard (i.e., paperboard based) made in paperboard mills"},
{c:322130,d: "Milk carton board made in paperboard mills"},
{c:322130,d: "Paperboard (e.g., can/drum stock, container board, corrugating medium, folding carton stock, linerboard, tube) manufacturing"},
{c:322130,d: "Paperboard coating, laminating, or treating in paperboard mills"},
{c:322130,d: "Paperboard mills"},
{c:322130,d: "Paperboard products (e.g., containers) made in paperboard mills"},
{c:322130,d: "Pulp and paperboard combined manufacturing"},
{c:322130,d: "Pulp mills producing paperboard"},
{c:322130,d: "Setup boxboard stock manufacturing"},
{c:322130,d: "Wet machine board mills"},
{c:322211,d: "Boxes, corrugated and solid fiber, made from purchased paper or paperboard"},
{c:322211,d: "Boxes, shipping, laminated paper and paperboard, made from purchased paperboard"},
{c:322211,d: "Corrugated and solid fiber boxes made from purchased paper or paperboard"},
{c:322211,d: "Corrugated and solid fiberboard pads made from purchased paper or paperboard"},
{c:322211,d: "Corrugated paper made from purchased paper or paperboard"},
{c:322211,d: "Pads, corrugated and solid fiberboard, made from purchased paper or paperboard"},
{c:322211,d: "Pallets, corrugated and solid fiber, made from purchased paper or paperboard"},
{c:322211,d: "Paper, corrugated, made from purchased paper or paperboard"},
{c:322211,d: "Partitions, corrugated and solid fiber, made from purchased paper or paperboard"},
{c:322211,d: "Shipping containers made from purchased paperboard"},
{c:322211,d: "Shipping containers, corrugated, made from purchased paper or paperboard"},
{c:322212,d: "Boxes, folding (except corrugated), made from purchased paperboard"},
{c:322212,d: "Cartons, folding (except milk), made from purchased paperboard"},
{c:322212,d: "Folding boxes (except corrugated) made from purchased paperboard"},
{c:322212,d: "Folding paper and paperboard containers (except corrugated) made from purchased paperboard"},
{c:322212,d: "Food containers, sanitary, folding, made from purchased paperboard"},
{c:322212,d: "Sanitary food container, folding, made from purchased paperboard"},
{c:322212,d: "Shoe boxes, folding, made from purchased paperboard"},
{c:322219,d: "Bobbins, fiber, made from purchased paperboard"},
{c:322219,d: "Boxes, sanitary food (except folding), made from purchased paper or paperboard"},
{c:322219,d: "Boxes, setup (i.e., not shipped flat), made from purchased paperboard"},
{c:322219,d: "Cartons, milk, made from purchased paper or paperboard"},
{c:322219,d: "Composite cans (i.e., foil-fiber and other combinations) manufacturing"},
{c:322219,d: "Cones (e.g., winding yarn, string, ribbon, cloth), fiber, made from purchased paperboard"},
{c:322219,d: "Cores (i.e., all-fiber, nonfiber ends of any material), fiber, made from purchased paperboard"},
{c:322219,d: "Fiber cans and drums (i.e., all-fiber, nonfiber ends of any material) made from purchased paperboard"},
{c:322219,d: "Fiber drums made from purchased paperboard"},
{c:322219,d: "Fiber spools, reels, blocks made from purchased paperboard"},
{c:322219,d: "Fiber tubes made from purchased paperboard"},
{c:322219,d: "Food containers, sanitary (except folding), made from purchased paper or paperboard"},
{c:322219,d: "Mailing cases and tubes, paper fiber (i.e., all-fiber, nonfiber ends of any material), made from purchased paperboard"},
{c:322219,d: "Milk cartons made from purchased paper or paperboard"},
{c:322219,d: "Paper cups made from purchased paper or paperboard"},
{c:322219,d: "Paper dishes (e.g., cups, plates) made from purchased paper or paperboard"},
{c:322219,d: "Paper plates made from purchased paper or paperboard"},
{c:322219,d: "Sanitary food containers (except folding) made from purchased paper or paperboard"},
{c:322219,d: "Setup (i.e., not shipped flat) boxes made from purchased paperboard"},
{c:322219,d: "Shoe boxes, setup, made from purchased paperboard"},
{c:322219,d: "Vulcanized fiber products made from purchased paperboard"},
{c:322219,d: "Wastebaskets, fiber made from purchased paperboard"},
{c:322220,d: "Adhesive tape (except medical) made from purchased materials"},
{c:322220,d: "Bags (except plastics only) made by laminating or coating combinations of purchased plastics, foil and paper"},
{c:322220,d: "Bags, coated paper, made from purchased paper"},
{c:322220,d: "Bags, foil, made from purchased foil"},
{c:322220,d: "Bags, multiwall, made from purchased uncoated paper"},
{c:322220,d: "Bags, paper, uncoated, made from purchased paper"},
{c:322220,d: "Bags, uncoated paper, made from purchased paper"},
{c:322220,d: "Book paper made by coating purchased paper"},
{c:322220,d: "Book paper, coated, made from purchased paper"},
{c:322220,d: "Cardboard, laminated or surface coated, made from purchased paperboard"},
{c:322220,d: "Chipboard, laminated or surface coated, made from purchased paperboard"},
{c:322220,d: "Coated board made from purchased paperboard"},
{c:322220,d: "Coating purchased papers for nonpackaging applications (except photosensitive paper)"},
{c:322220,d: "Coating purchased papers for packaging applications"},
{c:322220,d: "Duct tape made from purchased materials"},
{c:322220,d: "Flexible packaging sheet materials made by coating or laminating purchased paper"},
{c:322220,d: "Flexible packaging sheet materials made by laminating purchased foil"},
{c:322220,d: "Foil bags made from purchased foil"},
{c:322220,d: "Foil sheet, laminating purchased foil sheets for packaging applications"},
{c:322220,d: "Gift wrap made from purchased materials"},
{c:322220,d: "Grocers' bags and sacks made from purchased uncoated paper"},
{c:322220,d: "Groundwood paper, coated, made from purchased paper"},
{c:322220,d: "Gummed paper products (e.g., labels, sheets, tapes) made from purchased paper"},
{c:322220,d: "Laminating foil for flexible packaging applications"},
{c:322220,d: "Laminating purchased foil sheets for nonpackaging applications"},
{c:322220,d: "Laminating purchased paperboard"},
{c:322220,d: "Laminating purchased papers for nonpackaging applications"},
{c:322220,d: "Laminating purchased papers for packaging applications"},
{c:322220,d: "Leatherboard (i.e., paperboard based) made from purchased paperboard"},
{c:322220,d: "Masking tape made from purchased paper"},
{c:322220,d: "Milk carton board stock made from purchased paperboard"},
{c:322220,d: "Paper bags, coated, made from purchased paper"},
{c:322220,d: "Paper bags, uncoated, made from purchased paper"},
{c:322220,d: "Paperboard, pasted, lined, laminated, or surface coated, made from purchased paperboard"},
{c:322220,d: "Pressure sensitive paper and tape (except medical) made from purchased materials"},
{c:322220,d: "Sacks, multiwall, made from purchased uncoated paper"},
{c:322220,d: "Surface coating purchased paperboard"},
{c:322220,d: "Tapes, pressure sensitive (e.g., cellophane, masking), gummed, made from purchased paper or other materials"},
{c:322220,d: "Wallpaper made from purchased papers or other materials"},
{c:322220,d: "Waxed paper for nonpackaging applications made from purchased paper"},
{c:322220,d: "Waxed paper for packaging applications made from purchased paper"},
{c:322230,d: "Bond paper made from purchased paper"},
{c:322230,d: "Cards, die-cut office supply (e.g., index, library, time recording), made from purchased paper or paperboard"},
{c:322230,d: "Computer paper, die-cut, made from purchased paper"},
{c:322230,d: "Construction paper, school and art, made from purchased paper"},
{c:322230,d: "Die-cut paper products for office use made from purchased paper or paperboard"},
{c:322230,d: "Envelopes (i.e., mailing, stationery) made from any material"},
{c:322230,d: "File folders (e.g., accordion, expanding, hanging, manila) made from purchased paper and paperboard"},
{c:322230,d: "Letters, die-cut, made from purchased cardboard"},
{c:322230,d: "Looseleaf fillers and paper made from purchased paper"},
{c:322230,d: "Manila folders, die-cut, made from purchased paper or paperboard"},
{c:322230,d: "Notebooks (including mechanically bound by wire, or plastics) made from purchased paper"},
{c:322230,d: "Office paper (e.g., computer printer, photocopy, plain paper), cut sheet, made from purchased paper"},
{c:322230,d: "Office supplies, die-cut paper, made from purchased paper or paperboard"},
{c:322230,d: "Padded envelopes manufacturing"},
{c:322230,d: "Pads, desk, made from purchased paper"},
{c:322230,d: "Paper products, die-cut office supply, made from purchased paper or paperboard"},
{c:322230,d: "Pocket folders made from purchased paper or paperboard"},
{c:322230,d: "Report covers made from purchased paper or paperboard"},
{c:322230,d: "Seed packets made from purchased paper"},
{c:322230,d: "Stationery made from purchased paper"},
{c:322230,d: "Tablets (e.g., memo, note, writing) made from purchased paper"},
{c:322230,d: "Tapes (e.g., adding machine, calculator, cash register) made from purchased paper"},
{c:322230,d: "Writing paper and envelopes, boxed sets, made from purchased paper"},
{c:322230,d: "Writing paper, cut sheet, made from purchased paper"},
{c:322291,d: "Diapers, disposable, made from purchased paper or textile fiber"},
{c:322291,d: "Facial tissues made from purchased paper"},
{c:322291,d: "Handkerchiefs, paper, made from purchased paper"},
{c:322291,d: "Napkins, table, made from purchased paper"},
{c:322291,d: "Pads, incontinent and bed, manufacturing"},
{c:322291,d: "Paper napkins and tablecloths made from purchased paper"},
{c:322291,d: "Paper towels made from purchased paper"},
{c:322291,d: "Sanitary napkins and tampons made from purchased paper or textile fiber"},
{c:322291,d: "Sanitary products made from purchased sanitary paper stock"},
{c:322291,d: "Tablecloths, paper, made from purchased paper"},
{c:322291,d: "Toilet paper made from purchased paper"},
{c:322291,d: "Towels, paper, made from purchased paper"},
{c:322299,d: "Cards, die-cut (except office supply) made from purchased paper or paperboard"},
{c:322299,d: "Cartons, egg, molded pulp manufacturing"},
{c:322299,d: "Cigarette paper made from purchased paper"},
{c:322299,d: "Cigarette paper, book, made from purchased paper"},
{c:322299,d: "Coffee filters made from purchased paper"},
{c:322299,d: "Confetti made from purchased paper"},
{c:322299,d: "Crepe paper made from purchased paper"},
{c:322299,d: "Cups, molded pulp, manufacturing"},
{c:322299,d: "Die-cut paper products (except for office use) made from purchased paper or paperboard"},
{c:322299,d: "Doilies, paper, made from purchased paper"},
{c:322299,d: "Filters, paper, made from purchased paper"},
{c:322299,d: "Food containers made from molded pulp"},
{c:322299,d: "Food trays, molded pulp, manufacturing"},
{c:322299,d: "Glassine wrapping paper made from purchased paper"},
{c:322299,d: "Hats made from purchased paper"},
{c:322299,d: "Insulating batts, fills, or blankets made from purchased paper"},
{c:322299,d: "Molded pulp products (e.g., egg cartons, food containers, food trays) manufacturing"},
{c:322299,d: "Paper dishes (e.g., cups, plates) made from molded pulp"},
{c:322299,d: "Paper novelties made from purchased paper"},
{c:322299,d: "Paper products (except office supply), die-cut, made from purchased paper or paperboard"},
{c:322299,d: "Photograph folders, mats, and mounts manufacturing"},
{c:322299,d: "Plates, molded pulp, manufacturing"},
{c:322299,d: "Pulp products, molded, manufacturing"},
{c:322299,d: "Trays, food, molded pulp, manufacturing"},
{c:323111,d: "Address lists commercial printing (except screen) without publishing"},
{c:323111,d: "Advertising materials (e.g., coupons, flyers) commercial printing (except screen) without publishing"},
{c:323111,d: "Agricultural magazines and periodicals commercial printing (except screen) without publishing"},
{c:323111,d: "Albums (e.g., photo, scrap) and refills manufacturing"},
{c:323111,d: "Appointment books and refills manufacturing"},
{c:323111,d: "Art prints commercial printing (except screen) without publishing"},
{c:323111,d: "Atlases commercial printing (except screen) without publishing"},
{c:323111,d: "Blankbooks and refills manufacturing"},
{c:323111,d: "Books, sales, manifold, printing"},
{c:323111,d: "Business directories commercial printing (except screen) without publishing"},
{c:323111,d: "Business forms commercial printing (except screen) without publishing"},
{c:323111,d: "Business forms, manifold, printing"},
{c:323111,d: "Calendars commercial printing (except screen) without publishing"},
{c:323111,d: "Cards (e.g., business, greeting, playing, postcards, trading) commercial printing (except screen) without publishing"},
{c:323111,d: "Catalogs commercial printing (except screen) without publishing"},
{c:323111,d: "Catalogs of collections commercial printing (except screen) without publishing"},
{c:323111,d: "Checkbooks and refills printing"},
{c:323111,d: "Comic books commercial printing (except screen) without publishing"},
{c:323111,d: "Commercial digital printing (except books)"},
{c:323111,d: "Commercial engraving printing (except books)"},
{c:323111,d: "Commercial flexographic printing (except books)"},
{c:323111,d: "Commercial gravure printing (except books)"},
{c:323111,d: "Commercial letterpress printing (except books)"},
{c:323111,d: "Commercial lithographic (offset) printing (except books)"},
{c:323111,d: "Commercial printing (except screen, books)"},
{c:323111,d: "Commercial quick printing (except books)"},
{c:323111,d: "Computer forms (manifold or continuous) printing"},
{c:323111,d: "Credit and identification card imprinting, embossing, and encoding"},
{c:323111,d: "Databases commercial printing (except screen) without publishing"},
{c:323111,d: "Diaries manufacturing"},
{c:323111,d: "Digital printing (e.g., billboards, other large format graphic materials, high resolution) (except books)"},
{c:323111,d: "Directories commercial printing (except screen) without publishing"},
{c:323111,d: "Discount coupon books commercial printing (except screen) without publishing"},
{c:323111,d: "Financial magazines and periodicals commercial printing (except screen) without publishing"},
{c:323111,d: "Flexographic printing (except books, grey goods)"},
{c:323111,d: "Globe covers and maps commercial printing (except screen) without publishing"},
{c:323111,d: "Gravure printing (except books, grey goods)"},
{c:323111,d: "Greeting cards (e.g., birthday, holiday, sympathy) commercial printing (except screen) without publishing"},
{c:323111,d: "Guides, street map, commercial printing (except screen) without publishing"},
{c:323111,d: "Hydrographic printing"},
{c:323111,d: "Instant printing (i.e., quick printing) (except books)"},
{c:323111,d: "Intaglio printing (except books)"},
{c:323111,d: "Job printing (except screen, books)"},
{c:323111,d: "Job printing, engraving (except books)"},
{c:323111,d: "Job printing, flexographic (except books)"},
{c:323111,d: "Job printing, gravure (except books)"},
{c:323111,d: "Job printing, letterpress (except books)"},
{c:323111,d: "Job printing, lithographic (except books)"},
{c:323111,d: "Job printing, offset (except books)"},
{c:323111,d: "Juvenile magazines and periodicals commercial printing (except screen) without publishing"},
{c:323111,d: "Labels, commercial printing (except screen), on a job-order basis"},
{c:323111,d: "Lithographic printing (except books, grey goods)"},
{c:323111,d: "Looseleaf binders and devices manufacturing"},
{c:323111,d: "Magazines and periodicals commercial printing (except screen) without publishing"},
{c:323111,d: "Manifold business forms printing"},
{c:323111,d: "Maps commercial printing (except screen) without publishing"},
{c:323111,d: "Music, sheet, commercial printing (except screen) without publishing"},
{c:323111,d: "Newsletters commercial printing (except screen) without publishing"},
{c:323111,d: "Newspapers commercial printing (except screen) without publishing"},
{c:323111,d: "Offset printing (except books, grey goods)"},
{c:323111,d: "Patterns and plans (e.g., clothing patterns) commercial printing (except blueprinting, screen) without publishing"},
{c:323111,d: "Periodicals commercial printing (except screen) without publishing"},
{c:323111,d: "Photo albums and refills manufacturing"},
{c:323111,d: "Postcards commercial printing (except screen) without publishing"},
{c:323111,d: "Posters commercial printing (except screen) without publishing"},
{c:323111,d: "Print shops, digital (except printing books)"},
{c:323111,d: "Print shops, engraving (except printing books)"},
{c:323111,d: "Print shops, flexographic (except printing books)"},
{c:323111,d: "Print shops, gravure (except printing books)"},
{c:323111,d: "Print shops, letterpress (except printing books)"},
{c:323111,d: "Print shops, lithographic (offset) (except printing books)"},
{c:323111,d: "Print shops, quick (except printing books)"},
{c:323111,d: "Printing apparel (except screen printing)"},
{c:323111,d: "Printing manifold business forms"},
{c:323111,d: "Printing, digital (e.g., billboards, other large format graphical materials, high resolution) (except books, grey goods)"},
{c:323111,d: "Printing, engraving, on paper products"},
{c:323111,d: "Printing, flexographic (except books, grey goods)"},
{c:323111,d: "Printing, gravure (except books, grey goods)"},
{c:323111,d: "Printing, letterpress (except books, grey goods)"},
{c:323111,d: "Printing, lithographic (except books, grey goods)"},
{c:323111,d: "Printing, photo-offset (except books, grey goods)"},
{c:323111,d: "Printing, quick (except books, grey goods)"},
{c:323111,d: "Professional magazines and periodicals commercial printing (except screen) without publishing"},
{c:323111,d: "Quick printing (except books)"},
{c:323111,d: "Racetrack programs commercial printing (except screen) without publishing"},
{c:323111,d: "Racing forms commercial printing (except screen) without publishing"},
{c:323111,d: "Radio guides commercial printing (except screen) without publishing"},
{c:323111,d: "Radio schedules commercial printing (except screen) without publishing"},
{c:323111,d: "Religious magazines and periodicals commercial printing (except screen) without publishing"},
{c:323111,d: "Rotogravure printing (except books)"},
{c:323111,d: "Sales books, manifold, printing"},
{c:323111,d: "Scholarly journals commercial printing (except screen) without publishing"},
{c:323111,d: "Scholastic magazines and periodicals commercial printing (except screen) without publishing"},
{c:323111,d: "Scrapbooks and refills manufacturing"},
{c:323111,d: "Sheet music commercial printing (except screen) without publishing"},
{c:323111,d: "Shipping registers commercial printing (except screen) without publishing"},
{c:323111,d: "Stationery, commercial printing (except screen), on a job-order basis"},
{c:323111,d: "Technical magazines and periodicals commercial printing (except screen) without publishing"},
{c:323111,d: "Telephone directories commercial printing (except screen) without publishing"},
{c:323111,d: "Television guides commercial printing (except screen) without publishing"},
{c:323111,d: "Time planners/organizers and refills manufacturing"},
{c:323111,d: "Trade journals commercial printing (except screen) without publishing"},
{c:323111,d: "Trade magazines and periodicals commercial printing (except screen) without publishing"},
{c:323111,d: "Unit set forms (e.g., manifold credit card slips) printing"},
{c:323111,d: "Yearbooks commercial printing (except screen) without publishing"},
{c:323113,d: "Address lists screen printing without publishing"},
{c:323113,d: "Agricultural magazines and periodicals screen printing without publishing"},
{c:323113,d: "Art prints screen printing without publishing"},
{c:323113,d: "Atlases screen printing without publishing"},
{c:323113,d: "Business directories screen printing without publishing"},
{c:323113,d: "Business forms (except manifold) screen printing without publishing"},
{c:323113,d: "Calendars screen printing without publishing"},
{c:323113,d: "Cards (e.g., business, greeting, playing, postcards, trading) screen printing without publishing"},
{c:323113,d: "Catalogs of collections screen printing without publishing"},
{c:323113,d: "Catalogs screen printing without publishing"},
{c:323113,d: "Comic books screen printing without publishing"},
{c:323113,d: "Commercial screen printing (except books, manifold business forms, grey goods)"},
{c:323113,d: "Databases screen printing without publishing"},
{c:323113,d: "Directories screen printing without publishing"},
{c:323113,d: "Discount coupon books screen printing without publishing"},
{c:323113,d: "Financial magazines and periodicals screen printing without publishing"},
{c:323113,d: "Globe covers and maps screen printing without publishing"},
{c:323113,d: "Greeting cards (e.g., birthday, holiday, sympathy) screen printing without publishing"},
{c:323113,d: "Guides, street map, screen printing without publishing"},
{c:323113,d: "Job printing, screen"},
{c:323113,d: "Juvenile magazines and periodicals screen printing without publishing"},
{c:323113,d: "Magazines and periodicals screen printing without publishing"},
{c:323113,d: "Maps screen printing without publishing"},
{c:323113,d: "Music, sheet, screen printing without publishing"},
{c:323113,d: "Newsletters screen printing without publishing"},
{c:323113,d: "Newspapers screen printing without publishing"},
{c:323113,d: "Patterns and plans (e.g., clothing patterns) screen printing without publishing"},
{c:323113,d: "Periodicals screen printing without publishing"},
{c:323113,d: "Postcards screen printing without publishing"},
{c:323113,d: "Posters screen printing without publishing"},
{c:323113,d: "Print shops, screen"},
{c:323113,d: "Printing, screen (except books, manifold business forms, grey goods)"},
{c:323113,d: "Professional magazines and periodicals screen printing without publishing"},
{c:323113,d: "Racetrack programs screen printing without publishing"},
{c:323113,d: "Racing forms screen printing without publishing"},
{c:323113,d: "Radio guides screen printing without publishing"},
{c:323113,d: "Radio schedules screen printing without publishing"},
{c:323113,d: "Religious magazines and periodicals screen printing without publishing"},
{c:323113,d: "Scholarly journals screen printing without publishing"},
{c:323113,d: "Scholastic magazines and periodicals screen printing without publishing"},
{c:323113,d: "Screen printing (except books, manifold business forms, grey goods)"},
{c:323113,d: "Screen printing apparel and textile products (e.g., caps, napkins, placemats, T-shirts, towels) (except grey goods)"},
{c:323113,d: "Screen printing textile banners"},
{c:323113,d: "Sheet music screen printing without publishing"},
{c:323113,d: "Shipping registers screen printing without publishing"},
{c:323113,d: "Stationery, screen printing, on a job-order basis"},
{c:323113,d: "Technical magazines and periodicals screen printing without publishing"},
{c:323113,d: "Telephone directories screen printing without publishing"},
{c:323113,d: "Television guides screen printing without publishing"},
{c:323113,d: "Trade journals screen printing without publishing"},
{c:323113,d: "Trade magazines and periodicals screen printing without publishing"},
{c:323113,d: "Yearbooks screen printing without publishing"},
{c:323117,d: "Almanacs printing and binding without publishing"},
{c:323117,d: "Almanacs printing without publishing"},
{c:323117,d: "Books printing and binding without publishing"},
{c:323117,d: "Books printing without publishing"},
{c:323117,d: "Dictionaries printing and binding without publishing"},
{c:323117,d: "Dictionaries printing without publishing"},
{c:323117,d: "Encyclopedias printing and binding without publishing"},
{c:323117,d: "Encyclopedias printing without publishing"},
{c:323117,d: "Fiction books printing and binding without publishing"},
{c:323117,d: "Fiction books printing without publishing"},
{c:323117,d: "Music books printing or printing and binding without publishing"},
{c:323117,d: "Nonfiction books printing and binding without publishing"},
{c:323117,d: "Nonfiction books printing without publishing"},
{c:323117,d: "Pamphlets printing and binding without publishing"},
{c:323117,d: "Pamphlets printing without publishing"},
{c:323117,d: "Printing and binding books without publishing"},
{c:323117,d: "Printing books without publishing"},
{c:323117,d: "Professional books printing and binding without publishing"},
{c:323117,d: "Professional books printing without publishing"},
{c:323117,d: "Religious books printing and binding without publishing"},
{c:323117,d: "Religious books printing without publishing"},
{c:323117,d: "School books printing and binding without publishing"},
{c:323117,d: "School books printing without publishing"},
{c:323117,d: "Technical manuals and papers (books) printing and binding without publishing"},
{c:323117,d: "Technical manuals and papers (books) printing without publishing"},
{c:323117,d: "Textbooks printing and binding without publishing"},
{c:323117,d: "Textbooks printing without publishing"},
{c:323117,d: "Travel guide books printing and binding without publishing"},
{c:323117,d: "Travel guide books printing without publishing"},
{c:323120,d: "Almanac binding without printing"},
{c:323120,d: "Bookbinding shops"},
{c:323120,d: "Bookbinding without printing"},
{c:323120,d: "Color separation services, for the printing trade"},
{c:323120,d: "Dictionary binding without printing"},
{c:323120,d: "Electronic prepress services for the printing trade"},
{c:323120,d: "Electrotype plate preparation services"},
{c:323120,d: "Embossing plate preparation services"},
{c:323120,d: "Encyclopedia binding without printing"},
{c:323120,d: "Engraving printing plate, for the printing trade"},
{c:323120,d: "Fiction bookbinding without printing"},
{c:323120,d: "Flexographic plate preparation services"},
{c:323120,d: "Gold stamping books for the trade"},
{c:323120,d: "Gravure plate and cylinder preparation services"},
{c:323120,d: "Imagesetting services, prepress"},
{c:323120,d: "Letterpress plate preparation services"},
{c:323120,d: "Lithographic plate preparation services"},
{c:323120,d: "Nonfiction bookbinding without printing"},
{c:323120,d: "Offset plate preparation services"},
{c:323120,d: "Pamphlet binding without printing"},
{c:323120,d: "Photocomposition services, for the printing trade"},
{c:323120,d: "Photoengraving plate preparation services"},
{c:323120,d: "Phototypesetting services"},
{c:323120,d: "Postpress services (e.g., beveling, bronzing, folding, gluing, edging, foil stamping, gilding) on printed materials"},
{c:323120,d: "Prepress printing services (e.g., color separation, imagesetting, photocomposition, typesetting)"},
{c:323120,d: "Printing plate preparation services"},
{c:323120,d: "Printing postpress services (e.g., beveling, bronzing, folding, gluing, edging, foil stamping) to printed products (e.g., books, cards, paper)"},
{c:323120,d: "Printing prepress services (e.g., color separation, imagesetting, photocomposition, typesetting)"},
{c:323120,d: "Professional bookbinding without printing"},
{c:323120,d: "Rebinding books, magazines, or pamphlets"},
{c:323120,d: "Religious bookbinding without printing"},
{c:323120,d: "Repairing books"},
{c:323120,d: "Rotogravure printing plates and cylinders preparation services"},
{c:323120,d: "Samples mounting"},
{c:323120,d: "School textbooks binding without printing"},
{c:323120,d: "Screen for printing, preparation services"},
{c:323120,d: "Swatches and samples, mounting for the trade"},
{c:323120,d: "Technical manual paper (books) binding without printing"},
{c:323120,d: "Trade binding services"},
{c:323120,d: "Typesetting (i.e., computer controlled, hand, machine)"},
{c:324110,d: "Acid oils made in petroleum refineries"},
{c:324110,d: "Aliphatic chemicals (i.e., acyclic) made in petroleum refineries"},
{c:324110,d: "Alkylates made in petroleum refineries"},
{c:324110,d: "Asphalt and asphaltic materials made in petroleum refineries"},
{c:324110,d: "Asphalt paving mixtures made in petroleum refineries"},
{c:324110,d: "Aviation fuels manufacturing"},
{c:324110,d: "Benzene made in petroleum refineries"},
{c:324110,d: "Biodiesel fuels made in petroleum refineries"},
{c:324110,d: "Butylene (i.e., butene) made in petroleum refineries"},
{c:324110,d: "Coke, petroleum, made in petroleum refineries"},
{c:324110,d: "Crude oil refining"},
{c:324110,d: "Crude petroleum refineries"},
{c:324110,d: "Cumene made in petroleum refineries"},
{c:324110,d: "Cyclic aromatic hydrocarbons made in petroleum refineries"},
{c:324110,d: "Diesel fuels made in petroleum refineries"},
{c:324110,d: "Ethylene made in petroleum refineries"},
{c:324110,d: "Fuel oils manufacturing"},
{c:324110,d: "Fuels, jet, manufacturing"},
{c:324110,d: "Gasoline made in petroleum refineries"},
{c:324110,d: "Heating oils made in petroleum refineries"},
{c:324110,d: "Hydraulic fluids made in petroleum refineries"},
{c:324110,d: "Jet fuels manufacturing"},
{c:324110,d: "Kerosene manufacturing"},
{c:324110,d: "Liquefied petroleum gas (LPG) made in refineries"},
{c:324110,d: "Lubricating oils and greases made in petroleum refineries"},
{c:324110,d: "Naphtha made in petroleum refineries"},
{c:324110,d: "Naphthenic acids made in petroleum refineries"},
{c:324110,d: "Oil (i.e., petroleum) refineries"},
{c:324110,d: "Oil additives made in petroleum refineries"},
{c:324110,d: "Oils, fuel, manufacturing"},
{c:324110,d: "Paraffin waxes made in petroleum refineries"},
{c:324110,d: "Petrochemical feedstocks made in petroleum refineries"},
{c:324110,d: "Petrochemicals made in petroleum refineries"},
{c:324110,d: "Petroleum coke made in petroleum refineries"},
{c:324110,d: "Petroleum cracking and reforming"},
{c:324110,d: "Petroleum distillation"},
{c:324110,d: "Petroleum jelly made in petroleum refineries"},
{c:324110,d: "Petroleum lubricating oils made in petroleum refineries"},
{c:324110,d: "Petroleum refineries"},
{c:324110,d: "Propane gases made in petroleum refineries"},
{c:324110,d: "Propylene (i.e., propene) made in petroleum refineries"},
{c:324110,d: "Refineries, petroleum"},
{c:324110,d: "Refinery gases made in petroleum refineries"},
{c:324110,d: "Road oils made in petroleum refineries"},
{c:324110,d: "Solvents made in petroleum refineries"},
{c:324110,d: "Still gases made in petroleum refineries"},
{c:324110,d: "Styrene made in petroleum refineries"},
{c:324110,d: "Tar made in petroleum refineries"},
{c:324110,d: "Toluene made in petroleum refineries"},
{c:324110,d: "Waxes, petroleum, made in petroleum refineries"},
{c:324110,d: "Xylene made in petroleum refineries"},
{c:324121,d: "Asphalt paving blocks made from purchased asphaltic materials"},
{c:324121,d: "Asphalt paving mixtures made from purchased asphaltic materials"},
{c:324121,d: "Asphalt road compounds made from purchased asphaltic materials"},
{c:324121,d: "Blocks, asphalt paving, made from purchased asphaltic materials"},
{c:324121,d: "Coal tar paving materials made from purchased coal tar"},
{c:324121,d: "Paving blocks and mixtures made from purchased asphaltic materials"},
{c:324121,d: "Tar and asphalt paving mixtures made from purchased asphaltic materials"},
{c:324122,d: "Asphalt roofing cements made from purchased asphaltic materials"},
{c:324122,d: "Asphalt roofing coatings made from purchased asphaltic materials"},
{c:324122,d: "Asphalt saturated boards made from purchased asphaltic materials"},
{c:324122,d: "Asphalt saturated mats and felts made from purchased asphaltic materials and paper"},
{c:324122,d: "Asphalt shingles made from purchased asphaltic materials"},
{c:324122,d: "Boards, asphalt saturated, made from purchased asphaltic materials"},
{c:324122,d: "Cements, asphalt roofing, made from purchased asphaltic materials"},
{c:324122,d: "Coating compounds, tar, made from purchased asphaltic materials"},
{c:324122,d: "Pitch, roofing, made from purchased asphaltic materials"},
{c:324122,d: "Roofing cements, asphalt, made from purchased asphaltic materials"},
{c:324122,d: "Roofing coatings made from purchased asphaltic materials"},
{c:324122,d: "Roofing felts made from purchased asphaltic materials"},
{c:324122,d: "Saturated felts made from purchased paper"},
{c:324122,d: "Sheathing, asphalt saturated, made from refined petroleum"},
{c:324122,d: "Shingles made from purchased asphaltic materials"},
{c:324122,d: "Siding made from purchased asphaltic materials"},
{c:324122,d: "Tar paper made from purchased asphaltic materials and paper"},
{c:324122,d: "Tar paper, building and roofing, made from purchased paper"},
{c:324122,d: "Tar roofing cements and coatings made from purchased asphaltic materials"},
{c:324191,d: "Brake fluids, petroleum, made from refined petroleum"},
{c:324191,d: "Cutting oils made from refined petroleum"},
{c:324191,d: "Greases, petroleum lubricating, made from refined petroleum"},
{c:324191,d: "Grinding oils, petroleum, made from refined petroleum"},
{c:324191,d: "Hydraulic fluids, petroleum, made from refined petroleum"},
{c:324191,d: "Lubricating oils and greases, petroleum, made from refined petroleum"},
{c:324191,d: "Motor oils, petroleum, made from refined petroleum"},
{c:324191,d: "Oils, lubricating petroleum, made from refined petroleum"},
{c:324191,d: "Oils, petroleum lubricating, re-refining used"},
{c:324191,d: "Petroleum lubricating oils made from refined petroleum"},
{c:324191,d: "Re-refining used petroleum lubricating oils"},
{c:324191,d: "Rust arresting petroleum compounds made from refined petroleum"},
{c:324191,d: "Transmission fluids, petroleum, made from refined petroleum"},
{c:324199,d: "Biodiesel fuels not made in petroleum refineries and blended with purchased refined petroleum"},
{c:324199,d: "Boulets (i.e., fuel bricks) made from refined petroleum"},
{c:324199,d: "Briquettes, petroleum, made from refined petroleum"},
{c:324199,d: "Calcining petroleum coke from refined petroleum"},
{c:324199,d: "Coke oven products (e.g., coke, gases, tars) made in coke oven establishments"},
{c:324199,d: "Fuel briquettes or boulets made from refined petroleum"},
{c:324199,d: "Oil-based additives made from refined petroleum"},
{c:324199,d: "Petroleum jelly made from refined petroleum"},
{c:324199,d: "Petroleum waxes made from refined petroleum"},
{c:324199,d: "Road oils made from refined petroleum"},
{c:324199,d: "Waxes, petroleum, made from refined petroleum"},
{c:325110,d: "Acyclic hydrocarbons (e.g., butene, ethylene, propene) (except acetylene) made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Aliphatic (e.g., hydrocarbons) (except acetylene) made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Aromatic petrochemicals made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Benzene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Butadiene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Butane made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Butylene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Cumene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Cyclic aromatic hydrocarbons made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Dodecene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Ethane made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Ethylbenzene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Ethylene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Heptanes made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Heptenes made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Isobutane made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Isobutene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Isoprene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Nonene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Olefins made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Paraffins made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Pentanes made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Pentenes made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Propylene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Styrene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Toluene made from refined petroleum or liquid hydrocarbons"},
{c:325110,d: "Xylene made from refined petroleum or liquid hydrocarbons"},
{c:325120,d: "Acetylene manufacturing"},
{c:325120,d: "Argon manufacturing"},
{c:325120,d: "Carbon dioxide manufacturing"},
{c:325120,d: "Chlorodifluoromethane manufacturing"},
{c:325120,d: "Chlorofluorocarbon gases manufacturing"},
{c:325120,d: "Compressed and liquefied industrial gas manufacturing"},
{c:325120,d: "Dichlorodifluoromethane manufacturing"},
{c:325120,d: "Dry ice (i.e., solid carbon dioxide) manufacturing"},
{c:325120,d: "Fluorinated hydrocarbon gases manufacturing"},
{c:325120,d: "Fluorocarbon gases manufacturing"},
{c:325120,d: "Gases, industrial (i.e., compressed, liquefied, solid), manufacturing"},
{c:325120,d: "Helium manufacturing"},
{c:325120,d: "Helium recovery from natural gas"},
{c:325120,d: "Hydrogen manufacturing"},
{c:325120,d: "Ice, dry, manufacturing"},
{c:325120,d: "Industrial gases manufacturing"},
{c:325120,d: "Liquid air manufacturing"},
{c:325120,d: "Monochlorodifluoromethane manufacturing"},
{c:325120,d: "Neon manufacturing"},
{c:325120,d: "Nitrogen manufacturing"},
{c:325120,d: "Nitrous oxide manufacturing"},
{c:325120,d: "Oxygen manufacturing"},
{c:325130,d: "Acid dyes, synthetic organic, manufacturing"},
{c:325130,d: "Anthraquinone dyes manufacturing"},
{c:325130,d: "Antimony based pigments manufacturing"},
{c:325130,d: "Arsenic based pigments manufacturing"},
{c:325130,d: "Azine dyes manufacturing"},
{c:325130,d: "Azo dyes manufacturing"},
{c:325130,d: "Barytes based pigments manufacturing"},
{c:325130,d: "Black pigments (except carbon black, bone black, lamp black) manufacturing"},
{c:325130,d: "Blanc fixe (i.e., barium sulfate, precipitated) manufacturing"},
{c:325130,d: "Ceramic colors manufacturing"},
{c:325130,d: "Chrome pigments (e.g., chrome green, chrome orange, chrome yellow) manufacturing"},
{c:325130,d: "Color pigments, inorganic (except bone black, carbon black, lamp black), manufacturing"},
{c:325130,d: "Color pigments, organic (except animal black, bone black), manufacturing"},
{c:325130,d: "Copper base pigments manufacturing"},
{c:325130,d: "Direct dyes manufacturing"},
{c:325130,d: "Disperse dyes manufacturing"},
{c:325130,d: "Dyes, inorganic, manufacturing"},
{c:325130,d: "Dyes, synthetic organic, manufacturing"},
{c:325130,d: "Eosin dyes manufacturing"},
{c:325130,d: "Ferric oxide pigments manufacturing"},
{c:325130,d: "Fluorescent dyes manufacturing"},
{c:325130,d: "Food coloring, synthetic, manufacturing"},
{c:325130,d: "Inorganic pigments (except bone black, carbon black, lamp black) manufacturing"},
{c:325130,d: "Iron based pigments manufacturing"},
{c:325130,d: "Lakes (i.e., organic pigments) manufacturing"},
{c:325130,d: "Lead based pigments manufacturing"},
{c:325130,d: "Lead pigments manufacturing"},
{c:325130,d: "Litharge manufacturing"},
{c:325130,d: "Lithopone manufacturing"},
{c:325130,d: "Metallic pigments, inorganic, manufacturing"},
{c:325130,d: "Methyl violet toners manufacturing"},
{c:325130,d: "Mineral colors and pigments manufacturing"},
{c:325130,d: "Mordant dyes manufacturing"},
{c:325130,d: "Nitroso dyes manufacturing"},
{c:325130,d: "Ocher pigments manufacturing"},
{c:325130,d: "Organic pigments, dyes, lakes, and toners manufacturing"},
{c:325130,d: "Pararosaniline dyes manufacturing"},
{c:325130,d: "Peacock blue lake manufacturing"},
{c:325130,d: "Pearl essence pigment, synthetic, manufacturing"},
{c:325130,d: "Persian orange lake manufacturing"},
{c:325130,d: "Phosphomolybdic acid lakes and toners manufacturing"},
{c:325130,d: "Phosphotungstic acid lakes and toners manufacturing"},
{c:325130,d: "Phthalocyanine pigments manufacturing"},
{c:325130,d: "Pigment, scarlet lake, manufacturing"},
{c:325130,d: "Pigments (except animal black, bone black), organic, manufacturing"},
{c:325130,d: "Pigments (except bone black, carbon black, lamp black), inorganic, manufacturing"},
{c:325130,d: "Prussian blue pigments manufacturing"},
{c:325130,d: "Satin white pigments manufacturing"},
{c:325130,d: "Scarlet 2 R lake manufacturing"},
{c:325130,d: "Sienna pigment manufacturing"},
{c:325130,d: "Solvent dyes manufacturing"},
{c:325130,d: "Stains, biological, manufacturing"},
{c:325130,d: "Stilbene dyes manufacturing"},
{c:325130,d: "Titanium based pigments manufacturing"},
{c:325130,d: "Toners (except electrostatic, photographic) manufacturing"},
{c:325130,d: "Ultramarine pigments manufacturing"},
{c:325130,d: "Umber manufacturing"},
{c:325130,d: "Vat dyes, synthetic, manufacturing"},
{c:325130,d: "Vermilion pigments manufacturing"},
{c:325130,d: "White extender pigments (e.g., barytes, blanc fixe, whiting) manufacturing"},
{c:325130,d: "Whiting manufacturing"},
{c:325130,d: "Zinc based pigments manufacturing"},
{c:325180,d: "Alkalies manufacturing"},
{c:325180,d: "Aluminum chloride manufacturing"},
{c:325180,d: "Aluminum compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Aluminum hydroxide (i.e., alumina trihydrate) manufacturing"},
{c:325180,d: "Aluminum sulfate manufacturing"},
{c:325180,d: "Alums (e.g., aluminum ammonium sulfate, aluminum potassium sulfate) manufacturing"},
{c:325180,d: "Ammonium chloride manufacturing"},
{c:325180,d: "Ammonium compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Ammonium hydroxide manufacturing"},
{c:325180,d: "Ammonium molybdate manufacturing"},
{c:325180,d: "Ammonium perchlorate manufacturing"},
{c:325180,d: "Ammonium thiosulfate manufacturing"},
{c:325180,d: "Animal black manufacturing"},
{c:325180,d: "Antimony oxide (except pigments) manufacturing"},
{c:325180,d: "Arsenates (except insecticides) manufacturing"},
{c:325180,d: "Arsenic compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Arsenites (except insecticides) manufacturing"},
{c:325180,d: "Barium compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Barium hydroxide manufacturing"},
{c:325180,d: "Beryllium oxide manufacturing"},
{c:325180,d: "Bleaching agents, inorganic, manufacturing"},
{c:325180,d: "Bone black manufacturing"},
{c:325180,d: "Borax (i.e., sodium borate) manufacturing"},
{c:325180,d: "Boric acid manufacturing"},
{c:325180,d: "Boron compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Borosilicate manufacturing"},
{c:325180,d: "Bromine manufacturing"},
{c:325180,d: "Calcium carbide, chloride, and hypochlorite manufacturing"},
{c:325180,d: "Calcium hypochlorite manufacturing"},
{c:325180,d: "Calcium inorganic compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Carbides (e.g., boron, calcium, silicon, tungsten) manufacturing"},
{c:325180,d: "Carbon black manufacturing"},
{c:325180,d: "Carbon disulfide manufacturing"},
{c:325180,d: "Carbon inorganic compounds manufacturing"},
{c:325180,d: "Carbonic acid manufacturing"},
{c:325180,d: "Caustic potash manufacturing"},
{c:325180,d: "Caustic soda (i.e., sodium hydroxide) manufacturing"},
{c:325180,d: "Cerium salts manufacturing"},
{c:325180,d: "Cesium and cesium compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Channel black manufacturing"},
{c:325180,d: "Chloride of lime manufacturing"},
{c:325180,d: "Chlorine compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Chlorine dioxide manufacturing"},
{c:325180,d: "Chlorine manufacturing"},
{c:325180,d: "Chlorosulfonic acid manufacturing"},
{c:325180,d: "Chromic acid manufacturing"},
{c:325180,d: "Chromium compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Chromium oxide manufacturing"},
{c:325180,d: "Chromium salts manufacturing"},
{c:325180,d: "Cobalt 60 (i.e., radioactive cobalt) manufacturing"},
{c:325180,d: "Cobalt chloride manufacturing"},
{c:325180,d: "Cobalt compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Cobalt sulfate manufacturing"},
{c:325180,d: "Copper chloride manufacturing"},
{c:325180,d: "Copper compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Copper iodide manufacturing"},
{c:325180,d: "Copper sulfate manufacturing"},
{c:325180,d: "Cyanides manufacturing"},
{c:325180,d: "Deuterium oxide (i.e., heavy water) manufacturing"},
{c:325180,d: "Dichromates manufacturing"},
{c:325180,d: "Ferric chloride manufacturing"},
{c:325180,d: "Ferric oxide manufacturing"},
{c:325180,d: "Ferrocyanides manufacturing"},
{c:325180,d: "Fluoboric acid manufacturing"},
{c:325180,d: "Fluorine manufacturing"},
{c:325180,d: "Fuel propellants, solid inorganic, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Furnace black manufacturing"},
{c:325180,d: "Glauber's salt manufacturing"},
{c:325180,d: "Heavy water (i.e., deuterium oxide) manufacturing"},
{c:325180,d: "Hydrazine manufacturing"},
{c:325180,d: "Hydrochloric acid manufacturing"},
{c:325180,d: "Hydrocyanic acid manufacturing"},
{c:325180,d: "Hydrofluoric acid manufacturing"},
{c:325180,d: "Hydrofluosilicic acid manufacturing"},
{c:325180,d: "Hydrogen peroxide manufacturing"},
{c:325180,d: "Hydrogen sulfide manufacturing"},
{c:325180,d: "Hydrosulfites manufacturing"},
{c:325180,d: "Hypochlorites manufacturing"},
{c:325180,d: "Hypophosphites manufacturing"},
{c:325180,d: "Indium chloride manufacturing"},
{c:325180,d: "Iodides manufacturing"},
{c:325180,d: "Iodine, crude or resublimed, manufacturing"},
{c:325180,d: "Iron compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Iron sulphate manufacturing"},
{c:325180,d: "Lamp black manufacturing"},
{c:325180,d: "Lead oxides (except pigments) manufacturing"},
{c:325180,d: "Lead silicate manufacturing"},
{c:325180,d: "Lithium compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Magnesium carbonate manufacturing"},
{c:325180,d: "Magnesium chloride manufacturing"},
{c:325180,d: "Magnesium compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Manganese dioxide manufacturing"},
{c:325180,d: "Mercury chloride manufacturing"},
{c:325180,d: "Mercury compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Mercury oxide manufacturing"},
{c:325180,d: "Nickel ammonium sulfate manufacturing"},
{c:325180,d: "Nickel carbonate manufacturing"},
{c:325180,d: "Nickel compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Nickel sulfate manufacturing"},
{c:325180,d: "Nuclear fuel scrap reprocessing"},
{c:325180,d: "Nuclear fuels, inorganic, manufacturing"},
{c:325180,d: "Oleum (i.e., fuming sulfuric acid) manufacturing"},
{c:325180,d: "Perchloric acid manufacturing"},
{c:325180,d: "Peroxides, inorganic, manufacturing"},
{c:325180,d: "Phosphorus compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Phosphorus oxychloride manufacturing"},
{c:325180,d: "Potassium aluminum sulfate manufacturing"},
{c:325180,d: "Potassium bichromate and chromate manufacturing"},
{c:325180,d: "Potassium bromide manufacturing"},
{c:325180,d: "Potassium carbonate manufacturing"},
{c:325180,d: "Potassium chlorate manufacturing"},
{c:325180,d: "Potassium chloride manufacturing"},
{c:325180,d: "Potassium cyanide manufacturing"},
{c:325180,d: "Potassium hydroxide (i.e., caustic potash) manufacturing"},
{c:325180,d: "Potassium hypochlorate manufacturing"},
{c:325180,d: "Potassium inorganic compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Potassium iodide manufacturing"},
{c:325180,d: "Potassium nitrate manufacturing"},
{c:325180,d: "Potassium permanganate manufacturing"},
{c:325180,d: "Potassium salts manufacturing"},
{c:325180,d: "Potassium sulfate manufacturing"},
{c:325180,d: "Radioactive elements manufacturing"},
{c:325180,d: "Radioactive isotopes manufacturing"},
{c:325180,d: "Radium chloride manufacturing"},
{c:325180,d: "Radium luminous compounds manufacturing"},
{c:325180,d: "Rare earth compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Rare earth salts manufacturing"},
{c:325180,d: "Sal soda (i.e., washing soda) manufacturing"},
{c:325180,d: "Selenium compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Selenium dioxide manufacturing"},
{c:325180,d: "Silica gel manufacturing"},
{c:325180,d: "Silica, amorphous, manufacturing"},
{c:325180,d: "Silicofluorides manufacturing"},
{c:325180,d: "Silver bromide manufacturing"},
{c:325180,d: "Silver chloride manufacturing"},
{c:325180,d: "Silver compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Silver nitrate manufacturing"},
{c:325180,d: "Soda ash manufacturing"},
{c:325180,d: "Sodium aluminate manufacturing"},
{c:325180,d: "Sodium aluminum sulfate manufacturing"},
{c:325180,d: "Sodium antimoniate manufacturing"},
{c:325180,d: "Sodium arsenite (except insecticides) manufacturing"},
{c:325180,d: "Sodium bicarbonate manufacturing"},
{c:325180,d: "Sodium bichromate and chromate manufacturing"},
{c:325180,d: "Sodium borate manufacturing"},
{c:325180,d: "Sodium borohydride manufacturing"},
{c:325180,d: "Sodium bromide manufacturing"},
{c:325180,d: "Sodium carbonate (i.e., soda ash) manufacturing"},
{c:325180,d: "Sodium chlorate manufacturing"},
{c:325180,d: "Sodium cyanide manufacturing"},
{c:325180,d: "Sodium hydrosulfite manufacturing"},
{c:325180,d: "Sodium hydroxide (i.e., caustic soda) manufacturing"},
{c:325180,d: "Sodium hypochlorite manufacturing"},
{c:325180,d: "Sodium inorganic compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Sodium molybdate manufacturing"},
{c:325180,d: "Sodium perborate manufacturing"},
{c:325180,d: "Sodium peroxide manufacturing"},
{c:325180,d: "Sodium phosphate manufacturing"},
{c:325180,d: "Sodium polyphosphate manufacturing"},
{c:325180,d: "Sodium silicate (i.e., water glass) manufacturing"},
{c:325180,d: "Sodium silicofluoride manufacturing"},
{c:325180,d: "Sodium stannate manufacturing"},
{c:325180,d: "Sodium sulfate manufacturing"},
{c:325180,d: "Sodium tetraborate manufacturing"},
{c:325180,d: "Sodium thiosulfate manufacturing"},
{c:325180,d: "Sodium tungstate manufacturing"},
{c:325180,d: "Sodium uranate manufacturing"},
{c:325180,d: "Solid fuel propellants, inorganic, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Stannic and stannous chloride manufacturing"},
{c:325180,d: "Strontium carbonate manufacturing"},
{c:325180,d: "Strontium compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Strontium nitrate manufacturing"},
{c:325180,d: "Sulfides and sulfites manufacturing"},
{c:325180,d: "Sulfocyanides manufacturing"},
{c:325180,d: "Sulfur and sulfur compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Sulfur chloride manufacturing"},
{c:325180,d: "Sulfur dioxide manufacturing"},
{c:325180,d: "Sulfur hexafluoride gas manufacturing"},
{c:325180,d: "Sulfur recovering or refining (except from sour natural gas)"},
{c:325180,d: "Sulfuric acid manufacturing"},
{c:325180,d: "Tanning agents, inorganic, manufacturing"},
{c:325180,d: "Thiocyanate manufacturing"},
{c:325180,d: "Tin chloride manufacturing"},
{c:325180,d: "Tin compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Tin oxide manufacturing"},
{c:325180,d: "Tin salts manufacturing"},
{c:325180,d: "Titanium dioxide manufacturing"},
{c:325180,d: "Tungstates (e.g., ammonium tungstate, sodium tungstate) manufacturing"},
{c:325180,d: "Tungsten compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Uranium compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Uranium oxide manufacturing"},
{c:325180,d: "Uranium, enriched, manufacturing"},
{c:325180,d: "Zinc ammonium chloride manufacturing"},
{c:325180,d: "Zinc chloride manufacturing"},
{c:325180,d: "Zinc compounds, not specified elsewhere by process, manufacturing"},
{c:325180,d: "Zinc hydrosulfite (i.e., zinc dithionite) manufacturing"},
{c:325180,d: "Zinc oxide (except pigments) manufacturing"},
{c:325180,d: "Zinc sulfide manufacturing"},
{c:325193,d: "Denatured alcohol manufacturing"},
{c:325193,d: "Ethanol, nonpotable, manufacturing"},
{c:325193,d: "Ethyl alcohol, nonpotable, manufacturing"},
{c:325193,d: "Grain alcohol, nonpotable, manufacturing"},
{c:325194,d: "Acetate of lime, natural, made by distillation of wood"},
{c:325194,d: "Acetone, natural, manufacturing"},
{c:325194,d: "Aminoanthraquinone manufacturing"},
{c:325194,d: "Aminoazobenzene manufacturing"},
{c:325194,d: "Aminoazotoluene manufacturing"},
{c:325194,d: "Aminophenol manufacturing"},
{c:325194,d: "Aniline manufacturing"},
{c:325194,d: "Annatto extract manufacturing"},
{c:325194,d: "Anthracene manufacturing"},
{c:325194,d: "Azobenzene manufacturing"},
{c:325194,d: "Benzaldehyde manufacturing"},
{c:325194,d: "Benzoic acid manufacturing"},
{c:325194,d: "Brazilwood extract manufacturing"},
{c:325194,d: "Brewers' pitch made by distillation of wood"},
{c:325194,d: "Camphor, natural, manufacturing"},
{c:325194,d: "Charcoal (except activated) manufacturing"},
{c:325194,d: "Charcoal briquettes, wood, manufacturing"},
{c:325194,d: "Chestnut extract manufacturing"},
{c:325194,d: "Chlorobenzene manufacturing"},
{c:325194,d: "Chloronaphthalene manufacturing"},
{c:325194,d: "Chlorophenol manufacturing"},
{c:325194,d: "Chlorotoluene manufacturing"},
{c:325194,d: "Coal tar distillates manufacturing"},
{c:325194,d: "Creosote made by distillation of coal tar"},
{c:325194,d: "Creosote made by distillation of wood tar"},
{c:325194,d: "Cresols made by distillation of coal tar"},
{c:325194,d: "Cresylic acids made from refined petroleum or natural gas"},
{c:325194,d: "Cyclic crudes made by distillation of coal tar"},
{c:325194,d: "Cyclic intermediates made from refined petroleum or natural gas (except aromatic petrochemicals)"},
{c:325194,d: "Cyclohexane manufacturing"},
{c:325194,d: "Cyclopentane made from refined petroleum or natural gas"},
{c:325194,d: "Cyclopropane made from refined petroleum or natural gas"},
{c:325194,d: "Cycloterpenes manufacturing"},
{c:325194,d: "Diethylcyclohexane manufacturing"},
{c:325194,d: "Diphenylamine manufacturing"},
{c:325194,d: "Distillates, wood, manufacturing"},
{c:325194,d: "Dragon's blood manufacturing"},
{c:325194,d: "Dyes, natural, manufacturing"},
{c:325194,d: "Ethyl acetate, natural, manufacturing"},
{c:325194,d: "Extracts, natural dyeing and tanning, manufacturing"},
{c:325194,d: "Fustic wood extract manufacturing"},
{c:325194,d: "Gambier extract manufacturing"},
{c:325194,d: "Gum and wood chemicals manufacturing"},
{c:325194,d: "Halogenated aromatic hydrocarbon derivatives manufacturing"},
{c:325194,d: "Hardwood distillates manufacturing"},
{c:325194,d: "Hemlock extract manufacturing"},
{c:325194,d: "Hydroquinone manufacturing"},
{c:325194,d: "Isocyanates manufacturing"},
{c:325194,d: "Logwood extract manufacturing"},
{c:325194,d: "Maleic anhydride manufacturing"},
{c:325194,d: "Mangrove extract manufacturing"},
{c:325194,d: "Methyl acetone manufacturing"},
{c:325194,d: "Methyl alcohol (methanol), natural, manufacturing"},
{c:325194,d: "Myrobalans extract manufacturing"},
{c:325194,d: "Naphtha made by distillation of coal tar"},
{c:325194,d: "Naphtha, solvent, made by distillation of coal tar"},
{c:325194,d: "Naphthalene made from refined petroleum or natural gas"},
{c:325194,d: "Naphthalenesulfonic acid manufacturing"},
{c:325194,d: "Naphthenic acids made from refined petroleum or natural gas"},
{c:325194,d: "Naphthol sulfonic acids manufacturing"},
{c:325194,d: "Naphthol, alpha and beta, manufacturing"},
{c:325194,d: "Naval stores, gum or wood, manufacturing"},
{c:325194,d: "Nitrated hydrocarbon derivatives manufacturing"},
{c:325194,d: "Nitroaniline manufacturing"},
{c:325194,d: "Nitrobenzene manufacturing"},
{c:325194,d: "Nitrophenol manufacturing"},
{c:325194,d: "Nitrosated hydrocarbon derivatives manufacturing"},
{c:325194,d: "Oak extract manufacturing"},
{c:325194,d: "Oils made by distillation of coal tar"},
{c:325194,d: "Oils, wood, made by distillation of wood"},
{c:325194,d: "Orthodichlorobenzene manufacturing"},
{c:325194,d: "Pentachlorophenol manufacturing"},
{c:325194,d: "Phenol manufacturing"},
{c:325194,d: "Phthalic anhydride manufacturing"},
{c:325194,d: "Pine oil manufacturing"},
{c:325194,d: "Pinene manufacturing"},
{c:325194,d: "Pitch made by distillation of coal tar"},
{c:325194,d: "Pitch, wood, manufacturing"},
{c:325194,d: "Pyroligneous acids manufacturing"},
{c:325194,d: "Quebracho extracts manufacturing"},
{c:325194,d: "Quercitron extracts manufacturing"},
{c:325194,d: "Resorcinol manufacturing"},
{c:325194,d: "Rosins made by distillation of pine gum or pine wood"},
{c:325194,d: "Softwood distillates manufacturing"},
{c:325194,d: "Sulfonated naphthalene manufacturing"},
{c:325194,d: "Sulphonated derivatives manufacturing"},
{c:325194,d: "Sumac extract manufacturing"},
{c:325194,d: "Tall oil (except skimmings) manufacturing"},
{c:325194,d: "Tannic acid (i.e., tannins) manufacturing"},
{c:325194,d: "Tanning extracts and materials, natural, manufacturing"},
{c:325194,d: "Tar and tar oils made by distillation of wood"},
{c:325194,d: "Tar made by distillation of coal tar"},
{c:325194,d: "Toluidines manufacturing"},
{c:325194,d: "Turpentine made by distillation of pine gum or pine wood"},
{c:325194,d: "Valonia extract manufacturing"},
{c:325194,d: "Wattle extract manufacturing"},
{c:325194,d: "Witch hazel extract manufacturing"},
{c:325194,d: "Wood alcohol, natural, manufacturing"},
{c:325194,d: "Wood distillates manufacturing"},
{c:325194,d: "Wood oils manufacturing"},
{c:325199,d: "Accelerators (i.e., basic synthetic chemical) manufacturing"},
{c:325199,d: "Acetaldehyde manufacturing"},
{c:325199,d: "Acetates, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Acetic acid manufacturing"},
{c:325199,d: "Acetic anhydride manufacturing"},
{c:325199,d: "Acetin manufacturing"},
{c:325199,d: "Acetone, synthetic, manufacturing"},
{c:325199,d: "Acid esters, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Acids, organic, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Acrolein manufacturing"},
{c:325199,d: "Acrylonitrile manufacturing"},
{c:325199,d: "Adipic acid esters or amines manufacturing"},
{c:325199,d: "Adipic acid manufacturing"},
{c:325199,d: "Adiponitrile manufacturing"},
{c:325199,d: "Aldehydes manufacturing"},
{c:325199,d: "Alginates (e.g., calcium, potassium, sodium) manufacturing"},
{c:325199,d: "Alginic acid manufacturing"},
{c:325199,d: "Amyl acetate manufacturing"},
{c:325199,d: "Biodiesel fuels not made in petroleum refineries and not blended with petroleum"},
{c:325199,d: "Bleaching agents, organic, manufacturing"},
{c:325199,d: "Bromochloromethane manufacturing"},
{c:325199,d: "Butadiene made from alcohol"},
{c:325199,d: "Butyl acetate manufacturing"},
{c:325199,d: "Calcium citrate manufacturing"},
{c:325199,d: "Calcium organic compounds, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Calcium oxalate manufacturing"},
{c:325199,d: "Camphor, synthetic, manufacturing"},
{c:325199,d: "Caprolactam manufacturing"},
{c:325199,d: "Carbinol manufacturing"},
{c:325199,d: "Carbon organic compounds, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Carbon tetrachloride manufacturing"},
{c:325199,d: "Cellulose acetate (except resins) manufacturing"},
{c:325199,d: "Cetyl alcohol manufacturing"},
{c:325199,d: "Chloral manufacturing"},
{c:325199,d: "Chloroacetic acid manufacturing"},
{c:325199,d: "Chloroform manufacturing"},
{c:325199,d: "Chloropicrin manufacturing"},
{c:325199,d: "Citral manufacturing"},
{c:325199,d: "Citrates, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Citric acid manufacturing"},
{c:325199,d: "Citronellal manufacturing"},
{c:325199,d: "Coumarin manufacturing"},
{c:325199,d: "Cream of tartar manufacturing"},
{c:325199,d: "Decahydronaphthalene manufacturing"},
{c:325199,d: "Diethylene glycol manufacturing"},
{c:325199,d: "Dimethyl divinyl acetylene (di-isopropenyl acetylene) manufacturing"},
{c:325199,d: "Dimethylhydrazine manufacturing"},
{c:325199,d: "Enzyme proteins (i.e., basic synthetic chemicals) (except pharmaceutical use) manufacturing"},
{c:325199,d: "Essential oils, synthetic, manufacturing"},
{c:325199,d: "Esters, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Ethyl acetate, synthetic, manufacturing"},
{c:325199,d: "Ethyl butyrate manufacturing"},
{c:325199,d: "Ethyl cellulose (except resins) manufacturing"},
{c:325199,d: "Ethyl chloride manufacturing"},
{c:325199,d: "Ethyl ether manufacturing"},
{c:325199,d: "Ethyl formate manufacturing"},
{c:325199,d: "Ethyl nitrite manufacturing"},
{c:325199,d: "Ethyl perhydrophenanthrene manufacturing"},
{c:325199,d: "Ethylene glycol ether manufacturing"},
{c:325199,d: "Ethylene glycol manufacturing"},
{c:325199,d: "Ethylene oxide manufacturing"},
{c:325199,d: "Fatty acid esters and amines manufacturing"},
{c:325199,d: "Fatty acids (e.g., margaric, oleic, stearic) manufacturing"},
{c:325199,d: "Fatty alcohols manufacturing"},
{c:325199,d: "Flavoring materials (i.e., basic synthetic chemicals such as coumarin) manufacturing"},
{c:325199,d: "Formaldehyde manufacturing"},
{c:325199,d: "Formalin manufacturing"},
{c:325199,d: "Formic acid manufacturing"},
{c:325199,d: "Fuel propellants, solid organic, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Geraniol manufacturing"},
{c:325199,d: "Glycerin (i.e., glycerol), synthetic, manufacturing"},
{c:325199,d: "Halogenated hydrocarbon derivatives (except aromatic) manufacturing"},
{c:325199,d: "Heterocyclic chemicals, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Hexadecanol manufacturing"},
{c:325199,d: "Hexamethylenediamine manufacturing"},
{c:325199,d: "Hexamethylenetetramine manufacturing"},
{c:325199,d: "Hexanol manufacturing"},
{c:325199,d: "Ionone manufacturing"},
{c:325199,d: "Isopropyl alcohol manufacturing"},
{c:325199,d: "Ketone compounds, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Lactic acid manufacturing"},
{c:325199,d: "Lauric acid esters and amines manufacturing"},
{c:325199,d: "Linoleic acid esters and amines manufacturing"},
{c:325199,d: "Malonic dinitrile manufacturing"},
{c:325199,d: "Margaric acid manufacturing"},
{c:325199,d: "Metallic soap manufacturing"},
{c:325199,d: "Methyl alcohol (i.e., methanol), synthetic, manufacturing"},
{c:325199,d: "Methyl chloride manufacturing"},
{c:325199,d: "Methyl perhydrofluorine manufacturing"},
{c:325199,d: "Methyl salicylate manufacturing"},
{c:325199,d: "Methylamine manufacturing"},
{c:325199,d: "Methylene chloride manufacturing"},
{c:325199,d: "Monomethylparaminophenol sulfate manufacturing"},
{c:325199,d: "Monosodium glutamate manufacturing"},
{c:325199,d: "Naphthenic acid soaps manufacturing"},
{c:325199,d: "Natural nonfood coloring manufacturing"},
{c:325199,d: "Nitrous ether manufacturing"},
{c:325199,d: "Oleic acid (i.e., red oil) manufacturing"},
{c:325199,d: "Oleic acid esters manufacturing"},
{c:325199,d: "Organo-inorganic compound manufacturing"},
{c:325199,d: "Oxalates (e.g., ammonium oxalate, ethyl oxalate, sodium oxalate) manufacturing"},
{c:325199,d: "Oxalic acid manufacturing"},
{c:325199,d: "Palmitic acid esters and amines manufacturing"},
{c:325199,d: "Pentaerythritol manufacturing"},
{c:325199,d: "Perchloroethylene manufacturing"},
{c:325199,d: "Perfume materials (i.e., basic synthetic chemicals, such as terpineol) manufacturing"},
{c:325199,d: "Peroxides, organic, manufacturing"},
{c:325199,d: "Phosgene manufacturing"},
{c:325199,d: "Phosphoric acid esters manufacturing"},
{c:325199,d: "Phthalate acid manufacturing"},
{c:325199,d: "Plasticizers (i.e., basic synthetic chemicals) manufacturing"},
{c:325199,d: "Polyhydric alcohol esters and amines manufacturing"},
{c:325199,d: "Polyhydric alcohols manufacturing"},
{c:325199,d: "Potassium bitartrate manufacturing"},
{c:325199,d: "Potassium organic compounds, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Propylcarbinol manufacturing"},
{c:325199,d: "Propylene glycol manufacturing"},
{c:325199,d: "Red oil (i.e., oleic acid) manufacturing"},
{c:325199,d: "Saccharin manufacturing"},
{c:325199,d: "Salicylic acid (except medicinal) manufacturing"},
{c:325199,d: "Sebacic acid esters manufacturing"},
{c:325199,d: "Sebacic acid manufacturing"},
{c:325199,d: "Silicone (except resins) manufacturing"},
{c:325199,d: "Sodium acetate manufacturing"},
{c:325199,d: "Sodium alginate manufacturing"},
{c:325199,d: "Sodium benzoate manufacturing"},
{c:325199,d: "Sodium glutamate manufacturing"},
{c:325199,d: "Sodium organic compounds, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Sodium pentachlorophenate manufacturing"},
{c:325199,d: "Sodium sulfoxalate formaldehyde manufacturing"},
{c:325199,d: "Sorbitol manufacturing"},
{c:325199,d: "Stearic acid esters manufacturing"},
{c:325199,d: "Stearic acid manufacturing"},
{c:325199,d: "Stearic acid salts manufacturing"},
{c:325199,d: "Sugar substitutes (i.e., synthetic sweeteners blended with other ingredients) made in synthetic sweetener establishments"},
{c:325199,d: "Synthetic sweeteners (i.e., sweetening agents) manufacturing"},
{c:325199,d: "Tanning agents, synthetic organic, manufacturing"},
{c:325199,d: "Tartaric acid manufacturing"},
{c:325199,d: "Tartrates, not specified elsewhere by process, manufacturing"},
{c:325199,d: "Tear gas manufacturing"},
{c:325199,d: "Terpineol manufacturing"},
{c:325199,d: "Tert-butylated bis (p-phenoxyphenyl) ether fluid manufacturing"},
{c:325199,d: "Tetrachloroethylene manufacturing"},
{c:325199,d: "Tetraethyl lead manufacturing"},
{c:325199,d: "Thioglycolic acid manufacturing"},
{c:325199,d: "Transesterification of vegetable oils to produce fuels or fuel additives"},
{c:325199,d: "Trichloroethylene manufacturing"},
{c:325199,d: "Trichlorophenoxyacetic acid manufacturing"},
{c:325199,d: "Tricresyl phosphate manufacturing"},
{c:325199,d: "Tridecyl alcohol manufacturing"},
{c:325199,d: "Triphenyl phosphate manufacturing"},
{c:325199,d: "Vanillin, synthetic, manufacturing"},
{c:325199,d: "Vinyl acetate (except resins) manufacturing"},
{c:325199,d: "Wood alcohol, synthetic, manufacturing"},
{c:325211,d: "Acetal resins manufacturing"},
{c:325211,d: "Acrylic resins manufacturing"},
{c:325211,d: "Acrylonitrile-butadiene-styrene (ABS) resins manufacturing"},
{c:325211,d: "Alkyd resins manufacturing"},
{c:325211,d: "Allyl resins manufacturing"},
{c:325211,d: "Amino resins manufacturing"},
{c:325211,d: "Amino-aldehyde resins manufacturing"},
{c:325211,d: "Butadiene copolymers containing less than 50 percent butadiene manufacturing"},
{c:325211,d: "Carbohydrate plastics manufacturing"},
{c:325211,d: "Casein plastics manufacturing"},
{c:325211,d: "Cellulose acetate resins manufacturing"},
{c:325211,d: "Cellulose nitrate resins manufacturing"},
{c:325211,d: "Cellulose propionate resins manufacturing"},
{c:325211,d: "Cellulose resins manufacturing"},
{c:325211,d: "Cellulose xanthate (viscose) manufacturing"},
{c:325211,d: "Coal tar resins manufacturing"},
{c:325211,d: "Coumarone-indene resins manufacturing"},
{c:325211,d: "Cresol resins manufacturing"},
{c:325211,d: "Cresol-furfural resins manufacturing"},
{c:325211,d: "Dicyandiamine resins manufacturing"},
{c:325211,d: "Diisocyanate resins manufacturing"},
{c:325211,d: "Elastomers (except synthetic rubber) manufacturing"},
{c:325211,d: "Epichlorohydrin bisphenol manufacturing"},
{c:325211,d: "Epichlorohydrin diphenol manufacturing"},
{c:325211,d: "Epoxy resins manufacturing"},
{c:325211,d: "Ester gum manufacturing"},
{c:325211,d: "Ethylcellulose plastics manufacturing"},
{c:325211,d: "Ethylene-vinyl acetate resins manufacturing"},
{c:325211,d: "Fluorohydrocarbon resins manufacturing"},
{c:325211,d: "Fluoro-polymer resins manufacturing"},
{c:325211,d: "Ion exchange resins manufacturing"},
{c:325211,d: "Ionomer resins manufacturing"},
{c:325211,d: "Isobutylene polymer resins manufacturing"},
{c:325211,d: "Lignin plastics manufacturing"},
{c:325211,d: "Melamine resins manufacturing"},
{c:325211,d: "Methyl acrylate resins manufacturing"},
{c:325211,d: "Methyl cellulose resins manufacturing"},
{c:325211,d: "Methyl methacrylate resins manufacturing"},
{c:325211,d: "Nitrocellulose (i.e., pyroxylin) resins manufacturing"},
{c:325211,d: "Nylon resins manufacturing"},
{c:325211,d: "Petroleum polymer resins manufacturing"},
{c:325211,d: "Phenol-formaldehyde resins manufacturing"},
{c:325211,d: "Phenol-furfural resins manufacturing"},
{c:325211,d: "Phenolic resins manufacturing"},
{c:325211,d: "Phenoxy resins manufacturing"},
{c:325211,d: "Phthalic alkyd resins manufacturing"},
{c:325211,d: "Phthalic anhydride resins manufacturing"},
{c:325211,d: "Plastics and synthetic resins regenerating, precipitating, and coagulating"},
{c:325211,d: "Polyacrylonitrile resins manufacturing"},
{c:325211,d: "Polyamide resins manufacturing"},
{c:325211,d: "Polycarbonate resins manufacturing"},
{c:325211,d: "Polyester resins manufacturing"},
{c:325211,d: "Polyethylene resins manufacturing"},
{c:325211,d: "Polyethylene terephthalate (PET) resins manufacturing"},
{c:325211,d: "Polyhexamethylenediamine adipamide resins manufacturing"},
{c:325211,d: "Polyisobutylene resins manufacturing"},
{c:325211,d: "Polymethacrylate resins manufacturing"},
{c:325211,d: "Polypropylene resins manufacturing"},
{c:325211,d: "Polystyrene resins manufacturing"},
{c:325211,d: "Polytetrafluoroethylene resins manufacturing"},
{c:325211,d: "Polyurethane resins manufacturing"},
{c:325211,d: "Polyvinyl alcohol resins manufacturing"},
{c:325211,d: "Polyvinyl chloride (PVC) resins manufacturing"},
{c:325211,d: "Polyvinyl halide resins manufacturing"},
{c:325211,d: "Polyvinyl resins manufacturing"},
{c:325211,d: "Propylene resins manufacturing"},
{c:325211,d: "Protein plastics manufacturing"},
{c:325211,d: "Pyroxylin (i.e., nitrocellulose) resins manufacturing"},
{c:325211,d: "Resins, plastics (except custom compounding purchased resins), manufacturing"},
{c:325211,d: "Rosins (i.e., modified resins) manufacturing"},
{c:325211,d: "Silicone resins manufacturing"},
{c:325211,d: "Soybean plastics manufacturing"},
{c:325211,d: "Styrene resins manufacturing"},
{c:325211,d: "Styrene-acrylonitrile resins manufacturing"},
{c:325211,d: "Tar acid resins manufacturing"},
{c:325211,d: "Thermoplastic resins and plastics materials manufacturing"},
{c:325211,d: "Thermosetting plastics resins manufacturing"},
{c:325211,d: "Urea resins manufacturing"},
{c:325211,d: "Urea-formaldehyde resins manufacturing"},
{c:325211,d: "Vinyl acetate resins manufacturing"},
{c:325211,d: "Vinyl chloride resins manufacturing"},
{c:325211,d: "Vinyl resins manufacturing"},
{c:325211,d: "Vinylidene resins manufacturing"},
{c:325212,d: "Acrylate rubber manufacturing"},
{c:325212,d: "Acrylate-butadiene rubber manufacturing"},
{c:325212,d: "Acrylic rubber manufacturing"},
{c:325212,d: "Butadiene copolymers containing more than 50 percent butadiene manufacturing"},
{c:325212,d: "Butadiene rubber (i.e., polybutadiene) manufacturing"},
{c:325212,d: "Butyl rubber manufacturing"},
{c:325212,d: "Chlorinated rubber, synthetic, manufacturing"},
{c:325212,d: "Chloroprene rubber manufacturing"},
{c:325212,d: "Chlorosulfonated polyethylenes manufacturing"},
{c:325212,d: "Cyclo rubber, synthetic, manufacturing"},
{c:325212,d: "Elastomers, synthetic rubber, manufacturing"},
{c:325212,d: "Epichlorohydrin elastomers manufacturing"},
{c:325212,d: "Ethylene-propylene rubber manufacturing"},
{c:325212,d: "Ethylene-propylene-nonconjugated diene (EPDM) rubber manufacturing"},
{c:325212,d: "Fluoro rubbers manufacturing"},
{c:325212,d: "Fluorocarbon derivative rubbers manufacturing"},
{c:325212,d: "Isobutylene-isoprene rubber manufacturing"},
{c:325212,d: "Isocyanate rubber manufacturing"},
{c:325212,d: "Latex rubber, synthetic, manufacturing"},
{c:325212,d: "Neoprene manufacturing"},
{c:325212,d: "Nitrile rubber manufacturing"},
{c:325212,d: "Nitrile-butadiene rubber manufacturing"},
{c:325212,d: "Nitrile-chloroprene rubbers manufacturing"},
{c:325212,d: "N-type rubber manufacturing"},
{c:325212,d: "Polyethylene rubber manufacturing"},
{c:325212,d: "Polyisobutylene rubber manufacturing"},
{c:325212,d: "Polyisobutylene-isoprene rubber manufacturing"},
{c:325212,d: "Polymethylene rubber manufacturing"},
{c:325212,d: "Polysulfide rubber manufacturing"},
{c:325212,d: "Rubber, synthetic, manufacturing"},
{c:325212,d: "Silicone rubber manufacturing"},
{c:325212,d: "Stereo rubber manufacturing"},
{c:325212,d: "S-type rubber manufacturing"},
{c:325212,d: "Styrene-butadiene rubber containing less than 50 percent styrene manufacturing"},
{c:325212,d: "Styrene-chloroprene rubber manufacturing"},
{c:325212,d: "Styrene-isoprene rubber manufacturing"},
{c:325212,d: "Synthetic rubber (i.e., vulcanizable elastomers) manufacturing"},
{c:325212,d: "Thermosetting vulcanizable elastomers manufacturing"},
{c:325212,d: "Thiol rubber manufacturing"},
{c:325212,d: "Urethane rubber manufacturing"},
{c:325212,d: "Vulcanized oils manufacturing"},
{c:325220,d: "Acetate fibers and filaments manufacturing"},
{c:325220,d: "Acrylic fibers and filaments manufacturing"},
{c:325220,d: "Acrylonitrile fibers and filaments manufacturing"},
{c:325220,d: "Anidex fibers and filaments manufacturing"},
{c:325220,d: "Casein fibers and filaments manufacturing"},
{c:325220,d: "Cellophane film or sheet manufacturing"},
{c:325220,d: "Cellulosic fibers and filaments manufacturing"},
{c:325220,d: "Cellulosic filament yarn manufacturing"},
{c:325220,d: "Cellulosic staple fibers manufacturing"},
{c:325220,d: "Cigarette tow, cellulosic fiber, manufacturing"},
{c:325220,d: "Elastomeric fibers and filaments manufacturing"},
{c:325220,d: "Fibers and filaments, cellulosic, manufacturing and texturizing"},
{c:325220,d: "Fibers and filaments, noncellulosic, manufacturing and texturizing"},
{c:325220,d: "Fluorocarbon fibers and filaments manufacturing"},
{c:325220,d: "Linear esters fibers and filaments manufacturing"},
{c:325220,d: "Manmade cellulosic fibers manufacturing"},
{c:325220,d: "Manmade noncellulosic fibers and filaments manufacturing"},
{c:325220,d: "Modacrylic fibers and filaments manufacturing"},
{c:325220,d: "Nitrocellulose fibers manufacturing"},
{c:325220,d: "Noncellulosic fibers and filaments manufacturing"},
{c:325220,d: "Noncellulosic filament yarn manufacturing"},
{c:325220,d: "Noncellulosic staple fibers and filaments manufacturing"},
{c:325220,d: "Nylon fibers and filaments manufacturing"},
{c:325220,d: "Olefin fibers and filaments manufacturing"},
{c:325220,d: "Organic noncellulosic fibers and filaments manufacturing"},
{c:325220,d: "Polyester fibers and filaments manufacturing"},
{c:325220,d: "Polyethylene terephthalate (PET) fibers and filaments manufacturing"},
{c:325220,d: "Polyolefin fibers and filaments manufacturing"},
{c:325220,d: "Polyvinyl ester fibers and filaments manufacturing"},
{c:325220,d: "Polyvinylidene chloride (i.e., saran) fibers and filaments manufacturing"},
{c:325220,d: "Protein fibers and filaments manufacturing"},
{c:325220,d: "Rayon fibers and filaments manufacturing"},
{c:325220,d: "Regenerated cellulosic fibers manufacturing"},
{c:325220,d: "Saran (i.e., polyvinylidene chloride) fibers and filaments manufacturing"},
{c:325220,d: "Soybean fibers and filaments manufacturing"},
{c:325220,d: "Spandex fiber, filaments, and yarn manufacturing"},
{c:325220,d: "Texturizing cellulosic yarn made in the same establishment"},
{c:325220,d: "Texturizing noncellulosic yarn made in the same establishment"},
{c:325220,d: "Throwing cellulosic yarn made in the same establishment"},
{c:325220,d: "Throwing noncellulosic yarn made in the same establishment"},
{c:325220,d: "Triacetate fibers and yarns manufacturing"},
{c:325220,d: "Vinyl fibers and filaments manufacturing"},
{c:325220,d: "Vinylidene chloride fiber and filament manufacturing"},
{c:325220,d: "Viscose fibers, bands, strips, and yarn manufacturing"},
{c:325220,d: "Yarn, cellulosic filament, manufacturing"},
{c:325220,d: "Yarn, cellulosic filament, manufacturing and texturizing"},
{c:325220,d: "Yarn, noncellulosic fiber and filament, manufacturing"},
{c:325220,d: "Yarn, noncellulosic fiber and filament, manufacturing and texturizing"},
{c:325220,d: "Zein fibers and filaments manufacturing"},
{c:325311,d: "Ammonia, anhydrous and aqueous, manufacturing"},
{c:325311,d: "Ammonium nitrate manufacturing"},
{c:325311,d: "Ammonium sulfate manufacturing"},
{c:325311,d: "Anhydrous ammonia manufacturing"},
{c:325311,d: "Fertilizers, mixed, made in plants producing nitrogenous fertilizer materials"},
{c:325311,d: "Fertilizers, natural organic (except compost), manufacturing"},
{c:325311,d: "Fertilizers, of animal waste origin, manufacturing"},
{c:325311,d: "Fertilizers, of sewage origin, manufacturing"},
{c:325311,d: "Nitric acid manufacturing"},
{c:325311,d: "Nitrogenous fertilizer materials manufacturing"},
{c:325311,d: "Plant foods, mixed, made in plants producing nitrogenous fertilizer materials"},
{c:325311,d: "Urea manufacturing"},
{c:325312,d: "Ammonium phosphates manufacturing"},
{c:325312,d: "Defluorinated phosphates manufacturing"},
{c:325312,d: "Diammonium phosphates manufacturing"},
{c:325312,d: "Fertilizers, mixed, made in plants producing phosphatic fertilizer materials"},
{c:325312,d: "Phosphatic fertilizer materials manufacturing"},
{c:325312,d: "Phosphoric acid manufacturing"},
{c:325312,d: "Plant foods, mixed, made in plants producing phosphatic fertilizer materials"},
{c:325312,d: "Superphosphates manufacturing"},
{c:325314,d: "Compost manufacturing"},
{c:325314,d: "Fertilizers, mixed, made in plants not manufacturing fertilizer materials"},
{c:325314,d: "Mixing purchased fertilizer materials"},
{c:325314,d: "Nitrogenous fertilizers made by mixing purchased materials"},
{c:325314,d: "Phosphatic fertilizers made by mixing purchased materials"},
{c:325314,d: "Potassic fertilizers made by mixing purchased materials"},
{c:325314,d: "Potting soil manufacturing"},
{c:325320,d: "Ant poisons manufacturing"},
{c:325320,d: "Arsenate insecticides manufacturing"},
{c:325320,d: "Arsenite insecticides manufacturing"},
{c:325320,d: "Benzene hexachloride (BHC) insecticides manufacturing"},
{c:325320,d: "Bordeaux mixture insecticides manufacturing"},
{c:325320,d: "Botanical insecticides manufacturing"},
{c:325320,d: "Chlordane insecticides manufacturing"},
{c:325320,d: "Cholinesterase inhibitors used as insecticides manufacturing"},
{c:325320,d: "DDT (dichlorodiphenyltrichloroethane) insecticides manufacturing"},
{c:325320,d: "Defoliants manufacturing"},
{c:325320,d: "Dips (i.e., pesticides), cattle and sheep, manufacturing"},
{c:325320,d: "Endrin insecticides manufacturing"},
{c:325320,d: "Exterminating chemical products (e.g., fungicides, insecticides, pesticides) manufacturing"},
{c:325320,d: "Flea powders or sprays manufacturing"},
{c:325320,d: "Fly sprays manufacturing"},
{c:325320,d: "Fungicides manufacturing"},
{c:325320,d: "Herbicides manufacturing"},
{c:325320,d: "Household-type insecticides manufacturing"},
{c:325320,d: "Insecticides manufacturing"},
{c:325320,d: "Lime-sulfur fungicides manufacturing"},
{c:325320,d: "Lindane pesticides manufacturing"},
{c:325320,d: "Malathion insecticides manufacturing"},
{c:325320,d: "Methoxychlor insecticides manufacturing"},
{c:325320,d: "Moth repellents manufacturing"},
{c:325320,d: "Nicotine insecticides manufacturing"},
{c:325320,d: "Organo-phosphate based insecticides manufacturing"},
{c:325320,d: "Parathion insecticides manufacturing"},
{c:325320,d: "Paris green insecticides manufacturing"},
{c:325320,d: "Pest (e.g., ant, rat, roach, rodent) control poison manufacturing"},
{c:325320,d: "Pesticides manufacturing"},
{c:325320,d: "Plant growth regulants manufacturing"},
{c:325320,d: "Pyrethrin insecticides manufacturing"},
{c:325320,d: "Roach poisons manufacturing"},
{c:325320,d: "Rodent poisons manufacturing"},
{c:325320,d: "Rodenticides manufacturing"},
{c:325320,d: "Root removing chemicals manufacturing"},
{c:325320,d: "Rotenone insecticides manufacturing"},
{c:325320,d: "Seed treatment preparations manufacturing"},
{c:325320,d: "Sodium arsenite insecticides manufacturing"},
{c:325320,d: "Sulfur insecticides manufacturing"},
{c:325320,d: "Termite poisons manufacturing"},
{c:325320,d: "Tick powders or sprays manufacturing"},
{c:325320,d: "Xanthone insecticides manufacturing"},
{c:325411,d: "Acetylsalicylic acid manufacturing"},
{c:325411,d: "Adrenal derivatives, uncompounded, manufacturing"},
{c:325411,d: "Agar-agar manufacturing"},
{c:325411,d: "Amphetamines, uncompounded, manufacturing"},
{c:325411,d: "Anesthetics, uncompounded, manufacturing"},
{c:325411,d: "Antibiotics, uncompounded, manufacturing"},
{c:325411,d: "Anticholinergics, uncompounded, manufacturing"},
{c:325411,d: "Anticonvulsants, uncompounded, manufacturing"},
{c:325411,d: "Antidepressants, uncompounded, manufacturing"},
{c:325411,d: "Ascorbic acid (i.e., vitamin C), uncompounded, manufacturing"},
{c:325411,d: "Atropine and derivatives manufacturing"},
{c:325411,d: "Barbiturates, uncompounded, manufacturing"},
{c:325411,d: "Barbituric acid manufacturing"},
{c:325411,d: "Brucine manufacturing"},
{c:325411,d: "Caffeine and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Cephalosporin, uncompounded, manufacturing"},
{c:325411,d: "Cinchona and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Cocaine and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Cod liver oil, medicinal, uncompounded, manufacturing"},
{c:325411,d: "Codeine and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Concentrated medicinal chemicals, uncompounded, manufacturing"},
{c:325411,d: "Cortisone, uncompounded, manufacturing"},
{c:325411,d: "Dietary supplements, uncompounded, manufacturing"},
{c:325411,d: "Digitoxin, uncompounded, manufacturing"},
{c:325411,d: "Endocrine products, uncompounded, manufacturing"},
{c:325411,d: "Enzyme proteins (i.e., basic synthetic chemicals), pharmaceutical use, manufacturing"},
{c:325411,d: "Ephedrine and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Ergot alkaloids (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Fish liver oils, medicinal, uncompounded, manufacturing"},
{c:325411,d: "Glandular derivatives, uncompounded, manufacturing"},
{c:325411,d: "Glycosides, uncompounded, manufacturing"},
{c:325411,d: "Grinding and milling botanicals (i.e., for medicinal or dietary supplement use)"},
{c:325411,d: "Herbal supplements, uncompounded, manufacturing"},
{c:325411,d: "Hormones and derivatives, uncompounded, manufacturing"},
{c:325411,d: "Hypnotic drugs, uncompounded, manufacturing"},
{c:325411,d: "Insulin, uncompounded, manufacturing"},
{c:325411,d: "Magnesia, medicinal, uncompounded, manufacturing"},
{c:325411,d: "Medicinal chemicals, uncompounded, manufacturing"},
{c:325411,d: "Medicinal gelatins manufacturing"},
{c:325411,d: "Morphine and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Nicotine and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "N-methylpiperazine manufacturing"},
{c:325411,d: "Oils, vegetable and animal, medicinal, uncompounded, manufacturing"},
{c:325411,d: "Ophthalmic agents, uncompounded, manufacturing"},
{c:325411,d: "Opium and opium derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Penicillin, uncompounded, manufacturing"},
{c:325411,d: "Physostigmine and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Pituitary gland derivatives, uncompounded, manufacturing"},
{c:325411,d: "Procaine and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Quinine and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Reserpines (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Salicylic acid, medicinal, uncompounded, manufacturing"},
{c:325411,d: "Steroids, uncompounded, manufacturing"},
{c:325411,d: "Strychnine and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Sulfa drugs, uncompounded, manufacturing"},
{c:325411,d: "Sulfonamides, uncompounded, manufacturing"},
{c:325411,d: "Tetracycline, uncompounded, manufacturing"},
{c:325411,d: "Theobromine and derivatives (i.e., basic chemicals) manufacturing"},
{c:325411,d: "Vegetable alkaloids (i.e., basic chemicals) (e.g., caffeine, codeine, morphine, nicotine), manufacturing"},
{c:325411,d: "Vitamins, uncompounded, manufacturing"},
{c:325412,d: "Adrenal medicinal preparations manufacturing"},
{c:325412,d: "Analgesic preparations manufacturing"},
{c:325412,d: "Anesthetic preparations manufacturing"},
{c:325412,d: "Angiourographic diagnostic preparations manufacturing"},
{c:325412,d: "Antacid preparations manufacturing"},
{c:325412,d: "Anthelmintic preparations manufacturing"},
{c:325412,d: "Antibacterial preparations manufacturing"},
{c:325412,d: "Antibiotic preparations manufacturing"},
{c:325412,d: "Antidepressant preparations manufacturing"},
{c:325412,d: "Antihistamine preparations manufacturing"},
{c:325412,d: "Antineoplastic preparations manufacturing"},
{c:325412,d: "Antipyretic preparations manufacturing"},
{c:325412,d: "Antiseptic preparations manufacturing"},
{c:325412,d: "Antispasmodic preparations manufacturing"},
{c:325412,d: "Astringent preparations manufacturing"},
{c:325412,d: "Barbiturate preparations manufacturing"},
{c:325412,d: "Barium in-vivo diagnostic substances manufacturing"},
{c:325412,d: "Belladonna preparations manufacturing"},
{c:325412,d: "Birth control pills manufacturing"},
{c:325412,d: "Botanical extract preparations (except in-vitro diagnostics) manufacturing"},
{c:325412,d: "Cardiac preparations manufacturing"},
{c:325412,d: "Cathartic preparations manufacturing"},
{c:325412,d: "Central nervous system stimulant preparations manufacturing"},
{c:325412,d: "Coagulation in-vivo diagnostic substances manufacturing"},
{c:325412,d: "Cold remedies manufacturing"},
{c:325412,d: "Contraceptive preparations manufacturing"},
{c:325412,d: "Contrast media in-vivo diagnostic substances (e.g., iodine, barium) manufacturing"},
{c:325412,d: "Cough drops, medicated, manufacturing"},
{c:325412,d: "Cough medicines manufacturing"},
{c:325412,d: "Cyclopropane medicinal preparations manufacturing"},
{c:325412,d: "Dermatological preparations manufacturing"},
{c:325412,d: "Diagnostic biological preparations (except in-vitro) manufacturing"},
{c:325412,d: "Dietary supplements, compounded, manufacturing"},
{c:325412,d: "Digestive system preparations manufacturing"},
{c:325412,d: "Digitalis medicinal preparations manufacturing"},
{c:325412,d: "Diuretic preparations manufacturing"},
{c:325412,d: "Effervescent salts manufacturing"},
{c:325412,d: "Electrolyte in-vivo diagnostic substances manufacturing"},
{c:325412,d: "Eye and ear preparations manufacturing"},
{c:325412,d: "Fever remedy preparations manufacturing"},
{c:325412,d: "Glandular medicinal preparations manufacturing"},
{c:325412,d: "Hematology in-vivo diagnostic substances manufacturing"},
{c:325412,d: "Herbal supplements, compounded, manufacturing"},
{c:325412,d: "Hormone preparations (except in-vitro diagnostics) manufacturing"},
{c:325412,d: "Insulin preparations manufacturing"},
{c:325412,d: "Intravenous (IV) solution preparations manufacturing"},
{c:325412,d: "In-vivo diagnostic substances manufacturing"},
{c:325412,d: "Iodinated in-vivo diagnostic substances manufacturing"},
{c:325412,d: "Laxative preparations manufacturing"},
{c:325412,d: "Lip balms manufacturing"},
{c:325412,d: "Metabolite in-vivo diagnostic substances manufacturing"},
{c:325412,d: "Mouthwashes, medicated, manufacturing"},
{c:325412,d: "Muscle relaxant preparations manufacturing"},
{c:325412,d: "Nonprescription drug preparations manufacturing"},
{c:325412,d: "Nuclear medicine (e.g., radioactive isotopes) preparations manufacturing"},
{c:325412,d: "Oral contraceptive preparations manufacturing"},
{c:325412,d: "Patent medicine preparations manufacturing"},
{c:325412,d: "Penicillin preparations manufacturing"},
{c:325412,d: "Pharmaceutical preparations (e.g., capsules, liniments, ointments, tablets) manufacturing"},
{c:325412,d: "Pituitary gland preparations manufacturing"},
{c:325412,d: "Radioactive in-vivo diagnostic substances manufacturing"},
{c:325412,d: "Sedative preparations manufacturing"},
{c:325412,d: "Sodium chloride pharmaceutical preparations manufacturing"},
{c:325412,d: "Sodium salicylate preparations manufacturing"},
{c:325412,d: "Suppositories manufacturing"},
{c:325412,d: "Technetium medicinal preparations manufacturing"},
{c:325412,d: "Thyroid preparations manufacturing"},
{c:325412,d: "Tincture of iodine preparations manufacturing"},
{c:325412,d: "Tranquilizer preparations manufacturing"},
{c:325412,d: "Vermifuge preparations manufacturing"},
{c:325412,d: "Veterinary medicinal preparations manufacturing"},
{c:325412,d: "Vitamin preparations manufacturing"},
{c:325412,d: "Water (i.e., drinking) decontamination or purification tablets manufacturing"},
{c:325412,d: "Zinc oxide medicinal preparations manufacturing"},
{c:325413,d: "Blood derivative in-vitro diagnostic substances manufacturing"},
{c:325413,d: "Blood glucose test kits manufacturing"},
{c:325413,d: "Coagulation in-vitro diagnostic substances manufacturing"},
{c:325413,d: "Cytology and histology in-vitro diagnostic substances manufacturing"},
{c:325413,d: "Diagnostic substances, in-vitro, manufacturing"},
{c:325413,d: "Enzyme and isoenzyme in-vitro diagnostic substances manufacturing"},
{c:325413,d: "Hematology in-vitro diagnostic substances manufacturing"},
{c:325413,d: "HIV test kits manufacturing"},
{c:325413,d: "Hormone in-vitro diagnostic substances manufacturing"},
{c:325413,d: "In-vitro diagnostic substances manufacturing"},
{c:325413,d: "Microbiology, virology, and serology in-vitro diagnostic substances manufacturing"},
{c:325413,d: "Pregnancy test kits manufacturing"},
{c:325413,d: "Viral in-vitro diagnostic test substances manufacturing"},
{c:325414,d: "Agar culture media manufacturing"},
{c:325414,d: "Aggressins (except in-vitro) manufacturing"},
{c:325414,d: "Allergenic extracts (except diagnostic substances) manufacturing"},
{c:325414,d: "Allergens manufacturing"},
{c:325414,d: "Antigens manufacturing"},
{c:325414,d: "Antiserums manufacturing"},
{c:325414,d: "Antitoxins manufacturing"},
{c:325414,d: "Antivenoms manufacturing"},
{c:325414,d: "Bacterial vaccines manufacturing"},
{c:325414,d: "Bacterins (i.e., bacterial vaccines) manufacturing"},
{c:325414,d: "Blood derivatives manufacturing"},
{c:325414,d: "Blood fractions manufacturing"},
{c:325414,d: "Cholera serums manufacturing"},
{c:325414,d: "Culture media manufacturing"},
{c:325414,d: "Gene therapy preparations manufacturing"},
{c:325414,d: "Hematology products (except diagnostic substances) manufacturing"},
{c:325414,d: "Plasmas manufacturing"},
{c:325414,d: "Serums (except diagnostic substances) manufacturing"},
{c:325414,d: "Toxoids (e.g., diphtheria, tetanus) manufacturing"},
{c:325414,d: "Tuberculin (i.e., tuberculo-protein derived) manufacturing"},
{c:325414,d: "Vaccines (i.e., bacterial, virus) manufacturing"},
{c:325414,d: "Virus vaccines manufacturing"},
{c:325510,d: "Architectural coatings (i.e., paint) manufacturing"},
{c:325510,d: "Calcimines manufacturing"},
{c:325510,d: "Dispersions, pigment, manufacturing"},
{c:325510,d: "Dopes, paint, and lacquer manufacturing"},
{c:325510,d: "Driers, paint and varnish, manufacturing"},
{c:325510,d: "Enamel paints manufacturing"},
{c:325510,d: "Epoxy coatings made from purchased resins"},
{c:325510,d: "Fillers, wood (e.g., dry, liquid, paste), manufacturing"},
{c:325510,d: "Frit manufacturing"},
{c:325510,d: "Glaziers' putty manufacturing"},
{c:325510,d: "Industrial product finishes and coatings (i.e., paint) manufacturing"},
{c:325510,d: "Lacquers manufacturing"},
{c:325510,d: "Latex paint (i.e., water based) manufacturing"},
{c:325510,d: "Marine paints manufacturing"},
{c:325510,d: "Motor vehicle paints manufacturing"},
{c:325510,d: "Paint and varnish removers manufacturing"},
{c:325510,d: "Paint thinner and reducer preparations manufacturing"},
{c:325510,d: "Paintbrush cleaners manufacturing"},
{c:325510,d: "Paints (except artist's) manufacturing"},
{c:325510,d: "Paints, emulsion (i.e., latex paint), manufacturing"},
{c:325510,d: "Paints, oil and alkyd vehicle, manufacturing"},
{c:325510,d: "Plastic wood fillers manufacturing"},
{c:325510,d: "Plastisol coating compounds manufacturing"},
{c:325510,d: "Polyurethane coatings manufacturing"},
{c:325510,d: "Powder coatings manufacturing"},
{c:325510,d: "Primers, paint, manufacturing"},
{c:325510,d: "Shellac manufacturing"},
{c:325510,d: "Stains (except biological) manufacturing"},
{c:325510,d: "Varnishes manufacturing"},
{c:325510,d: "Water-repellent coatings for wood, concrete and masonry manufacturing"},
{c:325510,d: "Wood fillers manufacturing"},
{c:325520,d: "Adhesives (except asphalt, dental, gypsum base) manufacturing"},
{c:325520,d: "Caulking compounds (except gypsum base) manufacturing"},
{c:325520,d: "Cement, rubber, manufacturing"},
{c:325520,d: "Construction adhesives (except asphalt, gypsum base) manufacturing"},
{c:325520,d: "Dextrin glues manufacturing"},
{c:325520,d: "Epoxy adhesives manufacturing"},
{c:325520,d: "Glues (except dental) manufacturing"},
{c:325520,d: "Joint compounds (except gypsum base) manufacturing"},
{c:325520,d: "Mucilage adhesives manufacturing"},
{c:325520,d: "Pastes, adhesive, manufacturing"},
{c:325520,d: "Pipe sealing compounds manufacturing"},
{c:325520,d: "Plumbers' putty manufacturing"},
{c:325520,d: "Rubber cements manufacturing"},
{c:325520,d: "Sealing compounds for pipe threads and joints manufacturing"},
{c:325520,d: "Starch glues manufacturing"},
{c:325520,d: "Tile adhesives manufacturing"},
{c:325611,d: "Bar soaps manufacturing"},
{c:325611,d: "Dentifrices manufacturing"},
{c:325611,d: "Detergents (e.g., dishwashing, industrial, laundry) manufacturing"},
{c:325611,d: "Dishwasher detergents manufacturing"},
{c:325611,d: "Glycerin (i.e., glycerol), natural, manufacturing"},
{c:325611,d: "Hand soaps (e.g., hard, liquid, soft) manufacturing"},
{c:325611,d: "Laundry soap, chips, and powder manufacturing"},
{c:325611,d: "Mechanic's hand soaps and pastes manufacturing"},
{c:325611,d: "Presoaks manufacturing"},
{c:325611,d: "Scouring cleansers (e.g., pastes, powders) manufacturing"},
{c:325611,d: "Soaps (e.g., bar, chip, powder) manufacturing"},
{c:325611,d: "Teeth whiteners (e.g., pastes, gels) manufacturing"},
{c:325611,d: "Toilet soaps manufacturing"},
{c:325611,d: "Toothpastes, gels, and tooth powders manufacturing"},
{c:325611,d: "Waterless hand soaps manufacturing"},
{c:325612,d: "Air fresheners manufacturing"},
{c:325612,d: "Ammonia, household-type, manufacturing"},
{c:325612,d: "Automobile polishes and cleaners manufacturing"},
{c:325612,d: "Beeswax polishes and waxes manufacturing"},
{c:325612,d: "Bleaches, formulated for household use, manufacturing"},
{c:325612,d: "Brass polishes manufacturing"},
{c:325612,d: "Buffing compounds manufacturing"},
{c:325612,d: "Cloths, dusting and polishing, chemically treated, manufacturing"},
{c:325612,d: "Copper cleaners manufacturing"},
{c:325612,d: "Degreasing preparations, household-type, manufacturing"},
{c:325612,d: "Deodorants (except personal) manufacturing"},
{c:325612,d: "Disinfectants, household-type and industrial, manufacturing"},
{c:325612,d: "Drain pipe cleaners manufacturing"},
{c:325612,d: "Drycleaning preparations manufacturing"},
{c:325612,d: "Fabric softeners manufacturing"},
{c:325612,d: "Floor polishes and waxes manufacturing"},
{c:325612,d: "Furniture polishes and waxes manufacturing"},
{c:325612,d: "Glass and tile cleaning preparations manufacturing"},
{c:325612,d: "Gun cleaning preparations"},
{c:325612,d: "Ink eradicators manufacturing"},
{c:325612,d: "Kitchen degreasing and cleaning preparations manufacturing"},
{c:325612,d: "Laundry bluing manufacturing"},
{c:325612,d: "Lye, household-type, manufacturing"},
{c:325612,d: "Metal polishes (i.e., tarnish removers) manufacturing"},
{c:325612,d: "Oven cleaners manufacturing"},
{c:325612,d: "Polishes (e.g., automobile, furniture, metal, shoe) manufacturing"},
{c:325612,d: "Polishing preparations manufacturing"},
{c:325612,d: "Recycling drycleaning fluids"},
{c:325612,d: "Rug cleaning preparations manufacturing"},
{c:325612,d: "Rust removers manufacturing"},
{c:325612,d: "Saddle soaps manufacturing"},
{c:325612,d: "Shoe polishes and cleaners manufacturing"},
{c:325612,d: "Silver polishes manufacturing"},
{c:325612,d: "Soot removing chemicals manufacturing"},
{c:325612,d: "Spot removers (except laundry presoaks) manufacturing"},
{c:325612,d: "Starches, laundry, manufacturing"},
{c:325612,d: "Sweeping compounds, absorbent, manufacturing"},
{c:325612,d: "Toilet bowl cleaners manufacturing"},
{c:325612,d: "Tub and tile cleaning preparations manufacturing"},
{c:325612,d: "Wallpaper cleaners manufacturing"},
{c:325612,d: "Wax removers manufacturing"},
{c:325612,d: "Waxes, polishing (e.g., floor, furniture), manufacturing"},
{c:325612,d: "Window cleaning preparations manufacturing"},
{c:325612,d: "Windshield washer fluid manufacturing"},
{c:325613,d: "Assistants, textile and leather finishing, manufacturing"},
{c:325613,d: "Emulsifiers (i.e., surface active agents) manufacturing"},
{c:325613,d: "Finishing agents, textile and leather, manufacturing"},
{c:325613,d: "Mordants manufacturing"},
{c:325613,d: "Oils, soluble (i.e., textile finishing assistants), manufacturing"},
{c:325613,d: "Penetrants manufacturing"},
{c:325613,d: "Softeners, leather or textile, manufacturing"},
{c:325613,d: "Surface active agents manufacturing"},
{c:325613,d: "Textile finishing assistants manufacturing"},
{c:325613,d: "Textile scouring agents manufacturing"},
{c:325613,d: "Turkey-red oil manufacturing"},
{c:325613,d: "Wetting agents manufacturing"},
{c:325620,d: "After-shave preparations manufacturing"},
{c:325620,d: "Antiperspirants, personal, manufacturing"},
{c:325620,d: "Baby powder and baby oil manufacturing"},
{c:325620,d: "Bath salts manufacturing"},
{c:325620,d: "Blending and compounding perfume bases"},
{c:325620,d: "Blushes, face, manufacturing"},
{c:325620,d: "Bubble bath preparations manufacturing"},
{c:325620,d: "Colognes manufacturing"},
{c:325620,d: "Cosmetic creams, lotions, and oils manufacturing"},
{c:325620,d: "Dental floss manufacturing"},
{c:325620,d: "Denture adhesives manufacturing"},
{c:325620,d: "Denture cleaners, effervescent, manufacturing"},
{c:325620,d: "Deodorants, personal, manufacturing"},
{c:325620,d: "Depilatory preparations manufacturing"},
{c:325620,d: "Eye makeup (e.g., eye shadow, eyebrow pencil, mascara) manufacturing"},
{c:325620,d: "Face creams (e.g., cleansing, moisturizing) manufacturing"},
{c:325620,d: "Foundations (i.e., makeup) manufacturing"},
{c:325620,d: "Hair coloring preparations manufacturing"},
{c:325620,d: "Hair preparations (e.g., conditioners, dyes, rinses, shampoos) manufacturing"},
{c:325620,d: "Hair sprays manufacturing"},
{c:325620,d: "Hand lotions manufacturing"},
{c:325620,d: "Lipsticks manufacturing"},
{c:325620,d: "Lotions (e.g., body, face, hand) manufacturing"},
{c:325620,d: "Makeup (i.e., cosmetics) manufacturing"},
{c:325620,d: "Manicure preparations manufacturing"},
{c:325620,d: "Mouthwashes (except medicinal) manufacturing"},
{c:325620,d: "Nail polish remover manufacturing"},
{c:325620,d: "Nail polishes manufacturing"},
{c:325620,d: "Perfumes manufacturing"},
{c:325620,d: "Permanent wave preparations manufacturing"},
{c:325620,d: "Powders (e.g., baby, body, face, talcum, toilet) manufacturing"},
{c:325620,d: "Rouge, cosmetic, manufacturing"},
{c:325620,d: "Sachet, scented, manufacturing"},
{c:325620,d: "Shampoos and conditioners, hair, manufacturing"},
{c:325620,d: "Shaving preparations (e.g., creams, gels, lotions, powders) manufacturing"},
{c:325620,d: "Sunscreen lotions and oils manufacturing"},
{c:325620,d: "Suntan lotions and oils manufacturing"},
{c:325620,d: "Talcum powders manufacturing"},
{c:325620,d: "Tints, dyes, and rinses, hair, manufacturing"},
{c:325620,d: "Toilet preparations (e.g., cosmetics, deodorants, perfumes) manufacturing"},
{c:325620,d: "Toilet water manufacturing"},
{c:325620,d: "Towelettes, premoistened, manufacturing"},
{c:325910,d: "Bronze printing inks manufacturing"},
{c:325910,d: "Duplicating inks manufacturing"},
{c:325910,d: "Flexographic inks manufacturing"},
{c:325910,d: "Gold printing inks manufacturing"},
{c:325910,d: "Gravure inks manufacturing"},
{c:325910,d: "Inkjet cartridges manufacturing"},
{c:325910,d: "Inkjet inks manufacturing"},
{c:325910,d: "Inks, printing, manufacturing"},
{c:325910,d: "Letterpress inks manufacturing"},
{c:325910,d: "Lithographic inks manufacturing"},
{c:325910,d: "Offset inks manufacturing"},
{c:325910,d: "Printing inks manufacturing"},
{c:325910,d: "Screen process inks manufacturing"},
{c:325910,d: "Stencil inks manufacturing"},
{c:325910,d: "Textile printing inks manufacturing"},
{c:325920,d: "Amatols manufacturing"},
{c:325920,d: "Azides explosive materials manufacturing"},
{c:325920,d: "Blasting accessories (e.g., caps, fuses, ignitors, squibbs) manufacturing"},
{c:325920,d: "Blasting powders manufacturing"},
{c:325920,d: "Caps, blasting and detonating, manufacturing"},
{c:325920,d: "Cordite explosive materials manufacturing"},
{c:325920,d: "Detonating caps, cord, fuses, and primers manufacturing"},
{c:325920,d: "Detonators (except ammunition) manufacturing"},
{c:325920,d: "Dynamite manufacturing"},
{c:325920,d: "Explosives manufacturing"},
{c:325920,d: "Gunpowder manufacturing"},
{c:325920,d: "Mannitol hexanitrate explosive materials manufacturing"},
{c:325920,d: "Mercury fulminate explosive materials manufacturing"},
{c:325920,d: "Nitrocellulose explosive materials manufacturing"},
{c:325920,d: "Nitroglycerin explosive materials manufacturing"},
{c:325920,d: "Nitrostarch explosive materials manufacturing"},
{c:325920,d: "Pentolite explosive materials manufacturing"},
{c:325920,d: "Picric acid explosive materials manufacturing"},
{c:325920,d: "Safety fuses, blasting, manufacturing"},
{c:325920,d: "Styphnic acid explosive materials manufacturing"},
{c:325920,d: "Tetryl explosive materials manufacturing"},
{c:325920,d: "TNT (trinitrotoluene) manufacturing"},
{c:325920,d: "Trinitrotoluene (TNT) manufacturing"},
{c:325991,d: "Custom compounding (i.e., blending and mixing) of purchased plastics resins"},
{c:325991,d: "Plastics resins compounding from recycled materials"},
{c:325991,d: "Plastics resins, custom compounding of purchased"},
{c:325991,d: "Reformulating plastics resins from recycled plastics products"},
{c:325992,d: "Computer printer toner cartridges manufacturing"},
{c:325992,d: "Developers, prepared photographic, manufacturing"},
{c:325992,d: "Diazo (i.e., whiteprint) paper and cloth, sensitized, manufacturing"},
{c:325992,d: "Driers, photographic chemical, manufacturing"},
{c:325992,d: "Facsimile toner cartridges manufacturing"},
{c:325992,d: "Film, sensitized (e.g., camera, motion picture, X-ray), manufacturing"},
{c:325992,d: "Fixers, prepared photographic, manufacturing"},
{c:325992,d: "Graphic arts plates, sensitized, manufacturing"},
{c:325992,d: "Heat sensitized (i.e., thermal) paper made from purchased paper"},
{c:325992,d: "Motion picture film manufacturing"},
{c:325992,d: "Paper, photographic sensitized, manufacturing"},
{c:325992,d: "Photocopying toner cartridges manufacturing"},
{c:325992,d: "Photographic chemicals manufacturing"},
{c:325992,d: "Photographic film, cloth, paper, and plate, sensitized, manufacturing"},
{c:325992,d: "Photomasks manufacturing"},
{c:325992,d: "Photosensitized paper manufacturing"},
{c:325992,d: "Sensitized cloth or paper (e.g., blueprint, photographic) manufacturing"},
{c:325992,d: "Toner cartridges manufacturing"},
{c:325992,d: "Toner cartridges rebuilding"},
{c:325992,d: "Toners, electrostatic and photographic, manufacturing"},
{c:325992,d: "X-ray film and plates, sensitized, manufacturing"},
{c:325998,d: "Activated carbon or charcoal manufacturing"},
{c:325998,d: "Additive preparations for gasoline (e.g., antiknock preparations, detergents, gum inhibitors) manufacturing"},
{c:325998,d: "Aerosol can filling on a job-order or contract basis"},
{c:325998,d: "Aerosol packaging services"},
{c:325998,d: "Anise oil manufacturing"},
{c:325998,d: "Antifreeze preparations manufacturing"},
{c:325998,d: "Antiscaling compounds manufacturing"},
{c:325998,d: "Bay oil manufacturing"},
{c:325998,d: "Brake fluid, synthetic, manufacturing"},
{c:325998,d: "Caps for toy pistols manufacturing"},
{c:325998,d: "Capsules, gelatin, empty, manufacturing"},
{c:325998,d: "Carbon, activated, manufacturing"},
{c:325998,d: "Carburetor cleaners manufacturing"},
{c:325998,d: "Cat litter manufacturing"},
{c:325998,d: "Cedar oil manufacturing"},
{c:325998,d: "Charcoal, activated, manufacturing"},
{c:325998,d: "Citronella oil manufacturing"},
{c:325998,d: "Clove oil manufacturing"},
{c:325998,d: "Concrete additive preparations (e.g., curing, hardening) manufacturing"},
{c:325998,d: "Correction fluids (i.e., typewriter) manufacturing"},
{c:325998,d: "Crankcase additive preparations manufacturing"},
{c:325998,d: "Cutting oils, synthetic, manufacturing"},
{c:325998,d: "Defoamers and antifoaming agents manufacturing"},
{c:325998,d: "Degreasing preparations for machinery parts manufacturing"},
{c:325998,d: "Deicing preparations manufacturing"},
{c:325998,d: "Desalination kits manufacturing"},
{c:325998,d: "Distilled water manufacturing"},
{c:325998,d: "Drawing inks manufacturing"},
{c:325998,d: "Drilling mud compounds, conditioners, and additives (except bentonites) manufacturing"},
{c:325998,d: "Dye preparations, clothing, household-type, manufacturing"},
{c:325998,d: "Electronic cigarette vapor refills manufacturing"},
{c:325998,d: "Embalming fluids manufacturing"},
{c:325998,d: "Engine degreasers manufacturing"},
{c:325998,d: "Engine starting fluids manufacturing"},
{c:325998,d: "Essential oils manufacturing"},
{c:325998,d: "Eucalyptus oil manufacturing"},
{c:325998,d: "Fire extinguisher chemical preparations manufacturing"},
{c:325998,d: "Fire retardant chemical preparations manufacturing"},
{c:325998,d: "Fireworks manufacturing"},
{c:325998,d: "Flares manufacturing"},
{c:325998,d: "Fluxes (e.g., brazing, galvanizing, soldering, welding) manufacturing"},
{c:325998,d: "Foundry core oil, wash, and wax manufacturing"},
{c:325998,d: "Gelatin (except dessert preparations) manufacturing"},
{c:325998,d: "Gelatin capsules, empty, manufacturing"},
{c:325998,d: "Grapefruit oil manufacturing"},
{c:325998,d: "Greases, synthetic lubricating, manufacturing"},
{c:325998,d: "Hydraulic fluids, synthetic, manufacturing"},
{c:325998,d: "Incense manufacturing"},
{c:325998,d: "Indelible inks manufacturing"},
{c:325998,d: "India inks manufacturing"},
{c:325998,d: "Industrial salt manufacturing"},
{c:325998,d: "Inhibitors (e.g., corrosion, oxidation, polymerization) manufacturing"},
{c:325998,d: "Inks, writing, manufacturing"},
{c:325998,d: "Insulating oils manufacturing"},
{c:325998,d: "Lemon oil manufacturing"},
{c:325998,d: "Lighter fluids (e.g., charcoal, cigarette) manufacturing"},
{c:325998,d: "Lime oil manufacturing"},
{c:325998,d: "Lubricating oils and greases, synthetic, manufacturing"},
{c:325998,d: "Matches and match books manufacturing"},
{c:325998,d: "Motor oils, synthetic, manufacturing"},
{c:325998,d: "Napalm manufacturing"},
{c:325998,d: "Oil additive preparations manufacturing"},
{c:325998,d: "Oils (e.g., cutting, lubricating), synthetic, manufacturing"},
{c:325998,d: "Oils, lubricating, synthetic, manufacturing"},
{c:325998,d: "Orange oil manufacturing"},
{c:325998,d: "Orris oil manufacturing"},
{c:325998,d: "Ossein manufacturing"},
{c:325998,d: "Packer's fluids manufacturing"},
{c:325998,d: "Penetrating fluids, synthetic, manufacturing"},
{c:325998,d: "Peppermint oil manufacturing"},
{c:325998,d: "Pyrotechnics (e.g., flares, flashlight bombs, signals) manufacturing"},
{c:325998,d: "Radiator additive preparations manufacturing"},
{c:325998,d: "Recycling services for degreasing solvents (e.g., engine, machinery) manufacturing"},
{c:325998,d: "Retarders (e.g., flameproofing agents, mildewproofing agents) manufacturing"},
{c:325998,d: "Rubber processing preparations (e.g., accelerators, stabilizers) manufacturing"},
{c:325998,d: "Rust preventive preparations manufacturing"},
{c:325998,d: "Salt (except table) manufacturing"},
{c:325998,d: "Soil testing kits manufacturing"},
{c:325998,d: "Spearmint oil manufacturing"},
{c:325998,d: "Stamp pad ink manufacturing"},
{c:325998,d: "Sugar substitutes (i.e., synthetic sweeteners blended with other ingredients) made from purchased synthetic sweeteners"},
{c:325998,d: "Swimming pool chemical preparations manufacturing"},
{c:325998,d: "Tint and dye preparations, household-type (except hair), manufacturing"},
{c:325998,d: "Tire inflators, aerosol, manufacturing"},
{c:325998,d: "Transmission fluids, synthetic, manufacturing"},
{c:325998,d: "Water, distilled, manufacturing"},
{c:325998,d: "Wintergreen oil manufacturing"},
{c:325998,d: "Writing inks manufacturing"},
{c:326111,d: "Bags, plastics film, single wall or multiwall, manufacturing"},
{c:326111,d: "Food storage bags, plastics film, single wall or multiwall, manufacturing"},
{c:326111,d: "Frozen food bags, plastics film, single wall or multiwall, manufacturing"},
{c:326111,d: "Grocery bags, plastics film, single wall or multiwall, manufacturing"},
{c:326111,d: "Merchandise bags, plastics film, single wall or multiwall, manufacturing"},
{c:326111,d: "Trash bags, plastics film, single wall or multiwall, manufacturing"},
{c:326112,d: "Film, plastics, packaging, manufacturing"},
{c:326112,d: "Flexible packaging, plastics film, manufacturing"},
{c:326112,d: "Packaging film, plastics, single-web or multiweb, manufacturing"},
{c:326113,d: "Acrylic film and unlaminated sheet (except packaging) manufacturing"},
{c:326113,d: "Cellulosic plastics film and unlaminated sheet (except packaging) manufacturing"},
{c:326113,d: "Film, plastics (except packaging), manufacturing"},
{c:326113,d: "Photographic, micrographic, and X-ray plastics, sheet, and film (except sensitized), manufacturing"},
{c:326113,d: "Plastics film and unlaminated sheet (except packaging) manufacturing"},
{c:326113,d: "Polyester film and unlaminated sheet (except packaging) manufacturing"},
{c:326113,d: "Polyethylene film and unlaminated sheet (except packaging) manufacturing"},
{c:326113,d: "Polypropylene film and unlaminated sheet (except packaging) manufacturing"},
{c:326113,d: "Polyvinyl film and unlaminated sheet (except packaging) manufacturing"},
{c:326113,d: "Sheet, plastics, unlaminated (except packaging), manufacturing"},
{c:326113,d: "Vinyl and vinyl copolymer film and unlaminated sheet (except packaging) manufacturing"},
{c:326121,d: "Casings, sausage, nonrigid plastics, manufacturing"},
{c:326121,d: "Profile shapes (e.g., rod, tube), nonrigid plastics, manufacturing"},
{c:326121,d: "Rod, nonrigid plastics, manufacturing"},
{c:326121,d: "Sausage casings, plastics, manufacturing"},
{c:326121,d: "Tube, nonrigid plastics, manufacturing"},
{c:326122,d: "Fittings and unions, rigid plastics pipe, manufacturing"},
{c:326122,d: "Fittings, rigid plastics pipe, manufacturing"},
{c:326122,d: "Pipe fittings, rigid plastics, manufacturing"},
{c:326122,d: "Pipe, rigid plastics, manufacturing"},
{c:326122,d: "PVC pipe manufacturing"},
{c:326130,d: "Laminated plastics plate, rod, and sheet, manufacturing"},
{c:326130,d: "Plate, laminated plastics, manufacturing"},
{c:326130,d: "Profile shapes (e.g., plate, rod, sheet), laminated plastics, manufacturing"},
{c:326130,d: "Rod, laminated plastics, manufacturing"},
{c:326130,d: "Sheet, laminated plastics (except flexible packaging), manufacturing"},
{c:326140,d: "Coolers or ice chests, polystyrene foam, manufacturing"},
{c:326140,d: "Cups, polystyrene foam, manufacturing"},
{c:326140,d: "Dinnerware, polystyrene foam, manufacturing"},
{c:326140,d: "Foam polystyrene products manufacturing"},
{c:326140,d: "Food containers, polystyrene foam, manufacturing"},
{c:326140,d: "Ice buckets, polystyrene foam, manufacturing"},
{c:326140,d: "Ice chests or coolers, polystyrene foam, manufacturing"},
{c:326140,d: "Insulation and cushioning, polystyrene foam plastics, manufacturing"},
{c:326140,d: "Jugs, vacuum, polystyrene foam plastics, manufacturing"},
{c:326140,d: "Plates, polystyrene foam, manufacturing"},
{c:326140,d: "Polystyrene foam packaging manufacturing"},
{c:326140,d: "Sheet (i.e., board), polystyrene foam insulation, manufacturing"},
{c:326140,d: "Shipping pads and shaped cushioning, polystyrene foam, manufacturing"},
{c:326140,d: "Thermal insulation, polystyrene foam, manufacturing"},
{c:326150,d: "Cushions, carpet and rug, urethane and other foam plastics (except polystrene), manufacturing"},
{c:326150,d: "Foam plastics products (except polystrene) manufacturing"},
{c:326150,d: "Ice buckets, urethane or other plastics foam (except polystyrene), manufacturing"},
{c:326150,d: "Ice chests or coolers, urethane or other plastics foam (except polystyrene) manufacturing"},
{c:326150,d: "Insulation and cushioning, foam plastics (except polystrene), manufacturing"},
{c:326150,d: "Jugs, vacuum, foam plastics (except polystyrene), manufacturing"},
{c:326150,d: "Packaging, foam plastics (except polystyrene), manufacturing"},
{c:326150,d: "Polyurethane foam products manufacturing"},
{c:326150,d: "Seat cushions, foam plastics (except polystyrene), manufacturing"},
{c:326150,d: "Shipping pads and shaped cushioning, foam plastics (except polystyrene), manufacturing"},
{c:326150,d: "Urethane foam products manufacturing"},
{c:326160,d: "Bottles, plastics, manufacturing"},
{c:326191,d: "Bathtubs, plastics, manufacturing"},
{c:326191,d: "Chemical toilets, plastics, manufacturing"},
{c:326191,d: "Cultured marble plumbing fixtures manufacturing"},
{c:326191,d: "Drinking fountains (except mechanically refrigerated), plastics, manufacturing"},
{c:326191,d: "Hot tubs, plastics or fiberglass, manufacturing"},
{c:326191,d: "Laundry tubs, plastics, manufacturing"},
{c:326191,d: "Plumbing fixtures (e.g., shower stalls, toilets, urinals), plastics or fiberglass, manufacturing"},
{c:326191,d: "Portable toilets, plastics, manufacturing"},
{c:326191,d: "Shower stalls, plastics or fiberglass, manufacturing"},
{c:326191,d: "Sinks, plastics, manufacturing"},
{c:326191,d: "Toilet fixtures, plastics, manufacturing"},
{c:326191,d: "Urinals, plastics, manufacturing"},
{c:326199,d: "Awnings, rigid plastics or fiberglass, manufacturing"},
{c:326199,d: "Badges, plastics, manufacturing"},
{c:326199,d: "Balloons, plastics, manufacturing"},
{c:326199,d: "Bathroom and toilet accessories, plastics, manufacturing"},
{c:326199,d: "Bolts, nuts, and rivets, plastics, manufacturing"},
{c:326199,d: "Bottle caps and lids, plastics, manufacturing"},
{c:326199,d: "Bowls and bowl covers, plastics, manufacturing"},
{c:326199,d: "Bubble packaging materials, plastics, manufacturing"},
{c:326199,d: "Building materials (e.g., fascia, panels, siding, soffit), plastics, manufacturing"},
{c:326199,d: "Building panels, corrugated and flat, plastics, manufacturing"},
{c:326199,d: "Bushings, plastics, manufacturing"},
{c:326199,d: "Cigar and cigarette holders, plastics, manufacturing"},
{c:326199,d: "Clothes hangers, plastics, manufacturing"},
{c:326199,d: "Clothespins, plastics, manufacturing"},
{c:326199,d: "Combs, plastics, manufacturing"},
{c:326199,d: "Coolers or ice chests, plastics (except foam), manufacturing"},
{c:326199,d: "Countertops, plastics, manufacturing"},
{c:326199,d: "Credit and identification card stock, plastics, manufacturing"},
{c:326199,d: "Cultured marble products (except plumbing fixtures) manufacturing"},
{c:326199,d: "Cultured stone products (except plumbing fixtures) manufacturing"},
{c:326199,d: "Cups, plastics (except foam), manufacturing"},
{c:326199,d: "Dinnerware, plastics (except polystyrene foam), manufacturing"},
{c:326199,d: "Doormats, plastics, manufacturing"},
{c:326199,d: "Doors and door frames, plastics, manufacturing"},
{c:326199,d: "Drums, plastics (i.e., containers), manufacturing"},
{c:326199,d: "Floor coverings, linoleum, manufacturing"},
{c:326199,d: "Floor coverings, resilient, manufacturing"},
{c:326199,d: "Floor coverings, rubber, manufacturing"},
{c:326199,d: "Floor coverings, vinyl, manufacturing"},
{c:326199,d: "Footwear parts (e.g., heels, soles), plastics, manufacturing"},
{c:326199,d: "Fruits and vegetables, artificial, plastics, manufacturing"},
{c:326199,d: "Gloves, plastics, manufacturing"},
{c:326199,d: "Gutters and down spouts, plastics, manufacturing"},
{c:326199,d: "Hampers, laundry, plastics, manufacturing"},
{c:326199,d: "Handles (e.g., brush, tool, umbrella), plastics, manufacturing"},
{c:326199,d: "Hardware, plastics, manufacturing"},
{c:326199,d: "Ice buckets, plastics (except foam), manufacturing"},
{c:326199,d: "Ice chests or coolers, plastics (except plastics foam), manufacturing"},
{c:326199,d: "Jars, plastics, manufacturing"},
{c:326199,d: "Kitchen utensils, plastics, manufacturing"},
{c:326199,d: "Ladders, fiberglass, manufacturing"},
{c:326199,d: "Lamp shades, plastics, manufacturing"},
{c:326199,d: "Lens blanks, plastics ophthalmic or optical, manufacturing"},
{c:326199,d: "Linoleum floor coverings manufacturing"},
{c:326199,d: "Mattresses, air, plastics, manufacturing"},
{c:326199,d: "Microwaveware, plastics, manufacturing"},
{c:326199,d: "Motor vehicle moldings and extrusions, plastics, manufacturing"},
{c:326199,d: "Netting, plastics, manufacturing"},
{c:326199,d: "Nozzles, aerosol spray, plastics, manufacturing"},
{c:326199,d: "Organizers for closets, drawers, and shelves, plastics, manufacturing"},
{c:326199,d: "Packaging, plastics (e.g., blister, bubble), manufacturing"},
{c:326199,d: "Pails, plastics, manufacturing"},
{c:326199,d: "Paint sticks, plastics, manufacturing"},
{c:326199,d: "Picnic jugs, plastics (except foam), manufacturing"},
{c:326199,d: "Prefabricated buildings, plastics, manufacturing"},
{c:326199,d: "Radio housings, plastics, manufacturing"},
{c:326199,d: "Reels, plastics, manufacturing"},
{c:326199,d: "Reflectors, plastics, manufacturing"},
{c:326199,d: "Resilient floor coverings (e.g., sheet, tile) manufacturing"},
{c:326199,d: "Rubber floor coverings manufacturing"},
{c:326199,d: "Rulers, plastics, manufacturing"},
{c:326199,d: "Screening, window, plastics, manufacturing"},
{c:326199,d: "Septic tanks, plastics or fiberglass, manufacturing"},
{c:326199,d: "Shoe parts (e.g., heels, soles), plastics, manufacturing"},
{c:326199,d: "Shutters, plastics, manufacturing"},
{c:326199,d: "Siding, plastics, manufacturing"},
{c:326199,d: "Sponges, plastics, manufacturing"},
{c:326199,d: "Swimming pool covers and liners, plastics, manufacturing"},
{c:326199,d: "Swimming pools, fiberglass, manufacturing"},
{c:326199,d: "Tanks, storage, plastics or fiberglass, manufacturing"},
{c:326199,d: "Television housings, plastics, manufacturing"},
{c:326199,d: "Tiles, floor (i.e., linoleum, rubber, vinyl), manufacturing"},
{c:326199,d: "Tires, plastics, manufacturing"},
{c:326199,d: "Trash containers, plastics, manufacturing"},
{c:326199,d: "Tumblers, plastics, manufacturing"},
{c:326199,d: "Utility containers (e.g., baskets, bins, boxes, buckets, dishpans, pails), plastics (except foam), manufacturing"},
{c:326199,d: "Vinyl floor coverings manufacturing"},
{c:326199,d: "Watch crystals, plastics, manufacturing"},
{c:326199,d: "Watering cans, plastics, manufacturing"},
{c:326199,d: "Window sashes, vinyl, manufacturing"},
{c:326199,d: "Windows and window frames, plastics, manufacturing"},
{c:326199,d: "Windows and window frames, vinyl, manufacturing"},
{c:326199,d: "Windshields, plastics, manufacturing"},
{c:326211,d: "Aircraft tire manufacturing"},
{c:326211,d: "Camelback (i.e., retreading material) manufacturing"},
{c:326211,d: "Inner tubes manufacturing"},
{c:326211,d: "Motor vehicle tires manufacturing"},
{c:326211,d: "Retreading materials, tire, manufacturing"},
{c:326211,d: "Tire repair materials manufacturing"},
{c:326211,d: "Tires (e.g., pneumatic, semi-pneumatic, solid rubber) manufacturing"},
{c:326211,d: "Tread rubber (i.e., camelback) manufacturing"},
{c:326212,d: "Rebuilding tires"},
{c:326212,d: "Recapping tires"},
{c:326212,d: "Retreading tires"},
{c:326212,d: "Tire retreading, recapping or rebuilding"},
{c:326220,d: "Belting, rubber (e.g., conveyor, elevator, transmission), manufacturing"},
{c:326220,d: "Conveyor belts, rubber, manufacturing"},
{c:326220,d: "Fan belts, rubber or plastics, manufacturing"},
{c:326220,d: "Garden hose, rubber or plastics, manufacturing"},
{c:326220,d: "Hoses, reinforced, rubber or plastics, manufacturing"},
{c:326220,d: "Hoses, rubberized fabric, manufacturing"},
{c:326220,d: "Hydraulic hoses (without fitting), rubber or plastics, manufacturing"},
{c:326220,d: "Motor vehicle belts, rubber or plastics, manufacturing"},
{c:326220,d: "Motor vehicle hoses, rubber or plastics, manufacturing"},
{c:326220,d: "Plastics and rubber belts and hoses (without fittings) manufacturing"},
{c:326220,d: "Pneumatic hose (without fittings), rubber or plastics, manufacturing"},
{c:326220,d: "Radiator and heater hoses, rubber or plastics, manufacturing"},
{c:326220,d: "Rubber and plastics belts and hoses (without fittings) manufacturing"},
{c:326220,d: "Timing belt, rubber or plastics, manufacturing"},
{c:326220,d: "Transmission belts, rubber, manufacturing"},
{c:326220,d: "Vacuum cleaner belts, rubber or plastics, manufacturing"},
{c:326220,d: "V-belts, rubber or plastics, manufacturing"},
{c:326220,d: "Water hoses, rubber or plastics, manufacturing"},
{c:326291,d: "Extruded, molded or lathe-cut rubber goods manufacturing"},
{c:326291,d: "Mechanical rubber goods (i.e., extruded, lathe-cut, molded) manufacturing"},
{c:326291,d: "Rubber goods, mechanical (i.e.,  extruded, lathe-cut, molded), manufacturing"},
{c:326299,d: "Balloons, rubber, manufacturing"},
{c:326299,d: "Bath mats, rubber, manufacturing"},
{c:326299,d: "Birth control devices (i.e., diaphragms, prophylactics) manufacturing"},
{c:326299,d: "Brushes, rubber, manufacturing"},
{c:326299,d: "Combs, rubber, manufacturing"},
{c:326299,d: "Condom manufacturing"},
{c:326299,d: "Curlers, hair, rubber, manufacturing"},
{c:326299,d: "Diaphragms (i.e., birth control device), rubber, manufacturing"},
{c:326299,d: "Doormats, rubber, manufacturing"},
{c:326299,d: "Erasers, rubber or rubber and abrasive combined, manufacturing"},
{c:326299,d: "Floor mats (e.g., bath, door), rubber, manufacturing"},
{c:326299,d: "Footwear parts (e.g., heels, soles, soling strips), rubber, manufacturing"},
{c:326299,d: "Fuel bladders, rubber, manufacturing"},
{c:326299,d: "Grips and handles, rubber, manufacturing"},
{c:326299,d: "Grommets, rubber, manufacturing"},
{c:326299,d: "Hair care products (e.g., combs, curlers), rubber, manufacturing"},
{c:326299,d: "Hairpins, rubber, manufacturing"},
{c:326299,d: "Hot water bottles, rubber, manufacturing"},
{c:326299,d: "Latex foam rubber manufacturing"},
{c:326299,d: "Latex foam rubber products manufacturing"},
{c:326299,d: "Mattress protectors, rubber, manufacturing"},
{c:326299,d: "Mattresses, air, rubber, manufacturing"},
{c:326299,d: "Nipples and teething rings, rubber, manufacturing"},
{c:326299,d: "Pacifiers, rubber, manufacturing"},
{c:326299,d: "Pipe bits and stems, tobacco, hard rubber, manufacturing"},
{c:326299,d: "Prophylactics manufacturing"},
{c:326299,d: "Rafts, swimming pool-type, rubber inflatable, manufacturing"},
{c:326299,d: "Reclaiming rubber from waste or scrap"},
{c:326299,d: "Rods, hard rubber, manufacturing"},
{c:326299,d: "Rolls and roll coverings, rubber (e.g., industrial, papermill, painters', steelmill) manufacturing"},
{c:326299,d: "Roofing (i.e., single ply rubber membrane) manufacturing"},
{c:326299,d: "Rubber bands manufacturing"},
{c:326299,d: "Rubber tubing manufacturing"},
{c:326299,d: "Sheeting, rubber, manufacturing"},
{c:326299,d: "Shoe and boot parts (e.g., heels, soles, soling strips), rubber, manufacturing"},
{c:326299,d: "Spatulas, rubber, manufacturing"},
{c:326299,d: "Sponges, rubber, manufacturing"},
{c:326299,d: "Stair treads, rubber, manufacturing"},
{c:326299,d: "Stoppers, rubber, manufacturing"},
{c:326299,d: "Thread, rubber (except fabric covered), manufacturing"},
{c:327110,d: "Advanced and technical ceramic products manufacturing"},
{c:327110,d: "Alumina porcelain insulators manufacturing"},
{c:327110,d: "Architectural sculptures, clay, manufacturing"},
{c:327110,d: "Ashtrays, pottery, manufacturing"},
{c:327110,d: "Bathroom accessories, vitreous china and earthenware, manufacturing"},
{c:327110,d: "Beryllia porcelain insulators manufacturing"},
{c:327110,d: "Bidets, vitreous china, manufacturing"},
{c:327110,d: "Bone china manufacturing"},
{c:327110,d: "Chemical stoneware (i.e., pottery products) manufacturing"},
{c:327110,d: "China cooking ware manufacturing"},
{c:327110,d: "China tableware, vitreous, manufacturing"},
{c:327110,d: "Clay and ceramic statuary manufacturing"},
{c:327110,d: "Cones, pyrometric, earthenware, manufacturing"},
{c:327110,d: "Cooking ware (e.g., stoneware, coarse earthenware, pottery), manufacturing"},
{c:327110,d: "Cooking ware, china, manufacturing"},
{c:327110,d: "Cooking ware, fine earthenware, manufacturing"},
{c:327110,d: "Crockery manufacturing"},
{c:327110,d: "Decalcomania on china and glass for the trade"},
{c:327110,d: "Decorating china (e.g., encrusting gold, silver, other metal on china) for the trade"},
{c:327110,d: "Dishes, pottery, manufacturing"},
{c:327110,d: "Drinking fountains, vitreous china, non-refrigerated, manufacturing"},
{c:327110,d: "Earthenware table and kitchen articles, coarse, manufacturing"},
{c:327110,d: "Earthenware, commercial and household, semivitreous, manufacturing"},
{c:327110,d: "Ecclesiastical statuary, clay, manufacturing"},
{c:327110,d: "Electrical insulators, ceramic, manufacturing"},
{c:327110,d: "Electrical supplies, ceramic, manufacturing"},
{c:327110,d: "Faucet handles, vitreous china and earthenware, manufacturing"},
{c:327110,d: "Filtering media, pottery, manufacturing"},
{c:327110,d: "Firing china for the trade"},
{c:327110,d: "Florists' articles, red earthenware, manufacturing"},
{c:327110,d: "Flower pots, red earthenware, manufacturing"},
{c:327110,d: "Forms for dipped rubber products, pottery, manufacturing"},
{c:327110,d: "Garden pottery manufacturing"},
{c:327110,d: "Handles, faucet, vitreous china and earthenware, manufacturing"},
{c:327110,d: "Hotel tableware and kitchen articles, vitreous china, manufacturing"},
{c:327110,d: "Household-type earthenware, semivitreous, manufacturing"},
{c:327110,d: "Household-type tableware and kitchen articles, vitreous china, manufacturing"},
{c:327110,d: "Insulators, electrical porcelain, manufacturing"},
{c:327110,d: "Kitchen articles, coarse earthenware, manufacturing"},
{c:327110,d: "Kitchenware, commercial and household-type, vitreous china, manufacturing"},
{c:327110,d: "Kitchenware, semivitreous earthenware, manufacturing"},
{c:327110,d: "Lamp bases, pottery, manufacturing"},
{c:327110,d: "Lavatories, vitreous china, manufacturing"},
{c:327110,d: "Magnets, permanent, ceramic or ferrite, manufacturing"},
{c:327110,d: "Plumbing fixtures, vitreous china, manufacturing"},
{c:327110,d: "Porcelain parts, electrical and electronic device, molded, manufacturing"},
{c:327110,d: "Porcelain, chemical, manufacturing"},
{c:327110,d: "Pottery made and sold on site"},
{c:327110,d: "Pottery products manufacturing"},
{c:327110,d: "Pyrometer tubes manufacturing"},
{c:327110,d: "Pyrometric cones, earthenware, manufacturing"},
{c:327110,d: "Sculptures, architectural, clay, manufacturing"},
{c:327110,d: "Sinks, vitreous china, manufacturing"},
{c:327110,d: "Soap dishes, vitreous china and earthenware, manufacturing"},
{c:327110,d: "Spark plug insulators, porcelain, manufacturing"},
{c:327110,d: "Stationery articles, pottery, manufacturing"},
{c:327110,d: "Statuary, clay and ceramic, manufacturing"},
{c:327110,d: "Steatite porcelain insulators manufacturing"},
{c:327110,d: "Stoneware (i.e., pottery products) manufacturing"},
{c:327110,d: "Table articles, coarse earthenware, manufacturing"},
{c:327110,d: "Table articles, earthenware, manufacturing"},
{c:327110,d: "Table articles, fine earthenware (i.e., whiteware), manufacturing"},
{c:327110,d: "Table articles, vitreous china, manufacturing"},
{c:327110,d: "Tableware, vitreous china, manufacturing"},
{c:327110,d: "Tanks, flush, vitreous china, manufacturing"},
{c:327110,d: "Textile guides, porcelain, manufacturing"},
{c:327110,d: "Titania porcelain insulators manufacturing"},
{c:327110,d: "Toilet fixtures, vitreous china, manufacturing"},
{c:327110,d: "Towel bar holders, vitreous china and earthenware, manufacturing"},
{c:327110,d: "Urinals, vitreous china, manufacturing"},
{c:327110,d: "Vases, pottery (e.g., china, earthenware, stoneware), manufacturing"},
{c:327110,d: "Water closet bowls, vitreous china, manufacturing"},
{c:327120,d: "Adobe bricks manufacturing"},
{c:327120,d: "Alumina fused refractories manufacturing"},
{c:327120,d: "Aluminous refractory cement manufacturing"},
{c:327120,d: "Architectural terra cotta manufacturing"},
{c:327120,d: "Bauxite brick manufacturing"},
{c:327120,d: "Blocks, fire clay, manufacturing"},
{c:327120,d: "Bricks (i.e., common, face, glazed, hollow, vitrified), clay, manufacturing"},
{c:327120,d: "Bricks, adobe, manufacturing"},
{c:327120,d: "Bricks, clay refractory, manufacturing"},
{c:327120,d: "Bricks, nonclay refractory, manufacturing"},
{c:327120,d: "Building tile, clay, manufacturing"},
{c:327120,d: "Carbon brick manufacturing"},
{c:327120,d: "Castable refractories, clay, manufacturing"},
{c:327120,d: "Castable refractories, nonclay, manufacturing"},
{c:327120,d: "Cement, clay refractory, manufacturing"},
{c:327120,d: "Ceramic tiles, floor and wall, manufacturing"},
{c:327120,d: "Clay refractories (e.g., mortar, brick, tile, block) manufacturing"},
{c:327120,d: "Conduit, vitrified clay, manufacturing"},
{c:327120,d: "Coping, wall, clay, manufacturing"},
{c:327120,d: "Crucibles, fire clay, manufacturing"},
{c:327120,d: "Crucibles, graphite, magnesite, chrome, silica, or other nonclay materials, manufacturing"},
{c:327120,d: "Drain tile, clay, manufacturing"},
{c:327120,d: "Firebrick, clay refractories, manufacturing"},
{c:327120,d: "Floaters, glasshouse, clay, manufacturing"},
{c:327120,d: "Floor tile, ceramic, manufacturing"},
{c:327120,d: "Flue lining, clay, manufacturing"},
{c:327120,d: "Glasshouse refractories manufacturing"},
{c:327120,d: "Insulating firebrick and shapes, clay, manufacturing"},
{c:327120,d: "Kiln furniture, clay, manufacturing"},
{c:327120,d: "Liner brick and plates, vitrified clay, manufacturing"},
{c:327120,d: "Magnesia refractory cement manufacturing"},
{c:327120,d: "Mortar, nonclay refractory, manufacturing"},
{c:327120,d: "Mortars, clay refractory, manufacturing"},
{c:327120,d: "Mosaic tile, ceramic, manufacturing"},
{c:327120,d: "Nonclay refractories (e.g., block, brick, mortar, tile) manufacturing"},
{c:327120,d: "Paving brick, clay, manufacturing"},
{c:327120,d: "Pots, glasshouse, clay refractory, manufacturing"},
{c:327120,d: "Quarry tiles, clay, manufacturing"},
{c:327120,d: "Refractories (e.g., block, brick, mortar, tile), clay, manufacturing"},
{c:327120,d: "Refractories (e.g., block, brick, mortar, tile), nonclay, manufacturing"},
{c:327120,d: "Refractory cement, nonclay, manufacturing"},
{c:327120,d: "Roofing tile, clay, manufacturing"},
{c:327120,d: "Sewer pipe and fittings, clay, manufacturing"},
{c:327120,d: "Stove lining, clay, manufacturing"},
{c:327120,d: "Structural clay tile manufacturing"},
{c:327120,d: "Tile, ceramic wall and floor, manufacturing"},
{c:327120,d: "Tile, clay, refractory, manufacturing"},
{c:327120,d: "Tile, clay, structural, manufacturing"},
{c:327120,d: "Tile, roofing and drain, clay, manufacturing"},
{c:327120,d: "Tile, sewer, clay, manufacturing"},
{c:327120,d: "Wall tile, ceramic, manufacturing"},
{c:327211,d: "Flat glass (e.g., float, plate) manufacturing"},
{c:327211,d: "Glass, plate, made in glass making plants"},
{c:327211,d: "Insulating glass, sealed units, made in glass making plants"},
{c:327211,d: "Laminated glass made in glass making plants"},
{c:327211,d: "Stained glass and stained glass products made in glass making plants"},
{c:327212,d: "Ashtrays, glass, made in glass making plants"},
{c:327212,d: "Blanks for electric light bulbs, glass, made in glass making plants"},
{c:327212,d: "Blanks, ophthalmic lens and optical glass, made in glass making plants"},
{c:327212,d: "Blocks, glass, made in glass making plants"},
{c:327212,d: "Bricks, glass, made in glass making plants"},
{c:327212,d: "Christmas tree ornaments, glass, made in glass making plants"},
{c:327212,d: "Cooking utensils, glass and glass ceramic, made in glass making plants"},
{c:327212,d: "Cooking ware made in glass making plants"},
{c:327212,d: "Decorative glassware made in glass making plants"},
{c:327212,d: "Electron tube parts, glass blanks, made in glass making plants"},
{c:327212,d: "Fibers, glass, textile, made in glass making plants"},
{c:327212,d: "Fruits, artificial, made in glass making plants"},
{c:327212,d: "Glass blanks for electric light bulbs made in glass making plants"},
{c:327212,d: "Glass fiber, optical, made in glass making plants"},
{c:327212,d: "Glass fiber, textile type, made in glass making plants"},
{c:327212,d: "Glass fiber, unsheathed, made in glass making plants"},
{c:327212,d: "Glass making and blowing by hand"},
{c:327212,d: "Glass products (except packaging containers) made in a glass making plants"},
{c:327212,d: "Glass, automotive, made in glass making plants"},
{c:327212,d: "Glassware for industrial, scientific, and technical use made in glass making plants"},
{c:327212,d: "Glassware for lighting fixtures made in glass making plants"},
{c:327212,d: "Glassware, art, decorative, and novelty made in glass making plants"},
{c:327212,d: "Industrial glassware and glass products, pressed or blown, made in glass making plants"},
{c:327212,d: "Insulators, electrical, glass, made in glass making plants"},
{c:327212,d: "Laboratory glassware (e.g., beakers, test tubes, vials) made in glass making plants"},
{c:327212,d: "Lamp shades made in glass making plants"},
{c:327212,d: "Lens blanks, optical and ophthalmic, made in glass making plants"},
{c:327212,d: "Medical glassware made in glass making plants"},
{c:327212,d: "Ornaments, Christmas tree, glass, made in glass making plants"},
{c:327212,d: "Ovenware, glass, made in glass making plants"},
{c:327212,d: "Photomask blanks, glass, made in glass making plants"},
{c:327212,d: "Scientific glassware, pressed or blown, made in glass making plants"},
{c:327212,d: "Stemware, glass, made in glass making plants"},
{c:327212,d: "Tableware made in glass making plants"},
{c:327212,d: "Teakettles, glass and glass ceramic, made in glass making plants"},
{c:327212,d: "Technical glassware and glass products, pressed or blown, made in glass making plants"},
{c:327212,d: "Textile glass fibers made in glass making plants"},
{c:327212,d: "Vacuum tube blanks, glass, made in glass making plants"},
{c:327212,d: "Vases, glass, made in glass making plants"},
{c:327212,d: "Yarn, fiberglass, made in glass making plants"},
{c:327213,d: "Beer bottles, glass, manufacturing"},
{c:327213,d: "Beverage containers, glass, manufacturing"},
{c:327213,d: "Bottles (i.e., bottling, canning, packaging), glass, manufacturing"},
{c:327213,d: "Containers for packaging, bottling, and canning, glass, manufacturing"},
{c:327213,d: "Food packaging, glass, manufacturing"},
{c:327213,d: "Glass packaging containers manufacturing"},
{c:327213,d: "Jars for packaging, bottling, and canning, glass, manufacturing"},
{c:327213,d: "Soda bottles, glass, manufacturing"},
{c:327215,d: "Aquariums made from purchased glass"},
{c:327215,d: "Ashtrays made from purchased glass"},
{c:327215,d: "Blanks, ophthalmic lens and optical glass, made from purchased glass"},
{c:327215,d: "Bricks, glass, made from purchased glass"},
{c:327215,d: "Christmas tree ornaments made from purchased glass"},
{c:327215,d: "Cooking ware made from purchased glass"},
{c:327215,d: "Cut and engraved glassware made from purchased glass"},
{c:327215,d: "Cutting, engraving, etching, painting or polishing purchased glass"},
{c:327215,d: "Decorated glassware made from purchased glass"},
{c:327215,d: "Doors, unframed glass, made from purchased glass"},
{c:327215,d: "Electron tube parts, glass blanks, made from purchased glass"},
{c:327215,d: "Fruits, artificial, made from purchased glass"},
{c:327215,d: "Furniture tops, glass (e.g., beveled, cut, polished), made from purchased glass"},
{c:327215,d: "Glass blanks for electric light bulbs made from purchased glass"},
{c:327215,d: "Glass products (except packaging containers) made from purchased glass"},
{c:327215,d: "Glass, automotive, made from purchased glass"},
{c:327215,d: "Glassware for industrial, scientific, and technical use made from purchased glass"},
{c:327215,d: "Glassware for lighting fixtures made from purchased glass"},
{c:327215,d: "Glassware, art decorative and novelty, made from purchased glass"},
{c:327215,d: "Glassware, cutting and engraving, made from purchased glass"},
{c:327215,d: "Hand blowing purchased glass"},
{c:327215,d: "Industrial glassware made from purchased glass"},
{c:327215,d: "Insulating glass, sealed units, made from purchased glass"},
{c:327215,d: "Insulators, electrical, glass, made from purchased glass"},
{c:327215,d: "Laboratory glassware (e.g., beakers, test tubes, vials) made from purchased glass"},
{c:327215,d: "Laminated glass made from purchased glass"},
{c:327215,d: "Lamp shades made from purchased glass"},
{c:327215,d: "Lens blanks, optical and ophthalmic, made from purchased glass"},
{c:327215,d: "Medical glassware made from purchased glass"},
{c:327215,d: "Mirrors, framed (except automotive) or unframed, made from purchased glass"},
{c:327215,d: "Ornaments, Christmas tree, made from purchased glass"},
{c:327215,d: "Ovenware made from purchased glass"},
{c:327215,d: "Safety glass (including motor vehicle) made from purchased glass"},
{c:327215,d: "Scientific apparatus glassware made from purchased glass"},
{c:327215,d: "Scientific glassware made from purchased glass"},
{c:327215,d: "Stained glass products made from purchased glass"},
{c:327215,d: "Stemware made from purchased glass"},
{c:327215,d: "Tableware made from purchased glass"},
{c:327215,d: "Technical glassware made from purchased glass"},
{c:327215,d: "Tempered glass made from purchased glass"},
{c:327215,d: "Vacuum tube blanks, glass, made from purchased glass"},
{c:327215,d: "Vases, glass, made from purchased glass"},
{c:327215,d: "Watch crystals made from purchased glass"},
{c:327310,d: "Cement (e.g., hydraulic, masonry, portland, pozzolana) manufacturing"},
{c:327310,d: "Cement clinker manufacturing"},
{c:327310,d: "Natural (i.e., calcined earth) cement manufacturing"},
{c:327320,d: "Central-mixed concrete manufacturing"},
{c:327320,d: "Concrete batch plants (including temporary)"},
{c:327320,d: "Ready-mix concrete manufacturing and distributing"},
{c:327320,d: "Transit-mixed concrete manufacturing"},
{c:327320,d: "Truck-mixed concrete manufacturing"},
{c:327331,d: "Architectural block, concrete (e.g., fluted, ground face, screen, slump, split), manufacturing"},
{c:327331,d: "Blocks, concrete and cinder, manufacturing"},
{c:327331,d: "Bricks, concrete, manufacturing"},
{c:327331,d: "Cinder (clinker) block, concrete, manufacturing"},
{c:327331,d: "Patio block, concrete, manufacturing"},
{c:327331,d: "Paving blocks, concrete, manufacturing"},
{c:327331,d: "Plinth blocks, precast terrazzo, manufacturing"},
{c:327331,d: "Precast concrete block and brick manufacturing"},
{c:327331,d: "Prestressed concrete blocks or bricks manufacturing"},
{c:327331,d: "Slumped brick manufacturing"},
{c:327332,d: "Conduits, concrete, manufacturing"},
{c:327332,d: "Culvert pipe, concrete, manufacturing"},
{c:327332,d: "Cylinder pipe, prestressed concrete, manufacturing"},
{c:327332,d: "Irrigation pipe, concrete, manufacturing"},
{c:327332,d: "Pipe, concrete, manufacturing"},
{c:327332,d: "Precast concrete pipe manufacturing"},
{c:327332,d: "Pressure pipe, reinforced concrete, manufacturing"},
{c:327332,d: "Prestressed concrete pipes manufacturing"},
{c:327332,d: "Sewer pipe, concrete, manufacturing"},
{c:327390,d: "Architectural wall panels, precast concrete, manufacturing"},
{c:327390,d: "Burial vaults, concrete and precast terrazzo, manufacturing"},
{c:327390,d: "Cast stone, concrete (except structural), manufacturing"},
{c:327390,d: "Chimney caps, concrete, manufacturing"},
{c:327390,d: "Concrete furniture (e.g., benches, tables) manufacturing"},
{c:327390,d: "Concrete products, precast (except block, brick and pipe), manufacturing"},
{c:327390,d: "Concrete tanks manufacturing"},
{c:327390,d: "Copings, concrete, manufacturing"},
{c:327390,d: "Garbage incinerators, precast concrete, manufacturing"},
{c:327390,d: "Garden furniture, precast concrete, manufacturing"},
{c:327390,d: "Girders and beams, prestressed concrete, manufacturing"},
{c:327390,d: "Girders, prestressed concrete, manufacturing"},
{c:327390,d: "Incinerators, precast concrete, manufacturing"},
{c:327390,d: "Lintels, concrete, manufacturing"},
{c:327390,d: "Ornamental and statuary precast concrete products manufacturing"},
{c:327390,d: "Poles, concrete, manufacturing"},
{c:327390,d: "Posts, concrete, manufacturing"},
{c:327390,d: "Precast concrete products (except brick, block, pipe) manufacturing"},
{c:327390,d: "Prestressed concrete products (except blocks, bricks, pipes) manufacturing"},
{c:327390,d: "Roofing tile, concrete, manufacturing"},
{c:327390,d: "Sills, concrete, manufacturing"},
{c:327390,d: "Silos, prefabricated concrete, manufacturing"},
{c:327390,d: "Tanks, concrete, manufacturing"},
{c:327390,d: "Terrazzo products, precast (except brick, block and pipe), manufacturing"},
{c:327390,d: "Ties, concrete, railroad, manufacturing"},
{c:327410,d: "Agricultural lime manufacturing"},
{c:327410,d: "Calcium hydroxide (i.e., hydrated lime) manufacturing"},
{c:327410,d: "Calcium oxide (i.e., quicklime) manufacturing"},
{c:327410,d: "Dolomite, dead-burned, manufacturing"},
{c:327410,d: "Dolomitic lime manufacturing"},
{c:327410,d: "Hydrated lime (i.e., calcium hydroxide) manufacturing"},
{c:327410,d: "Lime production"},
{c:327410,d: "Quicklime (i.e., calcium oxide) manufacturing"},
{c:327420,d: "Art goods (e.g., gypsum, plaster of paris) manufacturing"},
{c:327420,d: "Board, gypsum, manufacturing"},
{c:327420,d: "Cement, Keene's (i.e., tiling plaster), manufacturing"},
{c:327420,d: "Columns, architectural or ornamental plaster work, manufacturing"},
{c:327420,d: "Ecclesiastical statuary, gypsum, manufacturing"},
{c:327420,d: "Flower boxes, plaster of paris, manufacturing"},
{c:327420,d: "Fountains, plaster of paris, manufacturing"},
{c:327420,d: "Gypsum building products manufacturing"},
{c:327420,d: "Gypsum products (e.g., block, board, plaster, lath, rock, tile) manufacturing"},
{c:327420,d: "Gypsum statuary manufacturing"},
{c:327420,d: "Images, small gypsum, manufacturing"},
{c:327420,d: "Joint compounds, gypsum based, manufacturing"},
{c:327420,d: "Keene's cement manufacturing"},
{c:327420,d: "Lath, gypsum, manufacturing"},
{c:327420,d: "Ornamental and architectural plaster work (e.g., columns, mantels, molding) manufacturing"},
{c:327420,d: "Orthopedic plaster, gypsum, manufacturing"},
{c:327420,d: "Plaster and plasterboard, gypsum, manufacturing"},
{c:327420,d: "Plaster of paris manufacturing"},
{c:327420,d: "Plaster of paris products (e.g., columns, statuary, urns) manufacturing"},
{c:327420,d: "Plaster, gypsum, manufacturing"},
{c:327420,d: "Sculptures (e.g., gypsum, plaster of paris) manufacturing"},
{c:327420,d: "Statuary (e.g., gypsum, plaster of paris) manufacturing"},
{c:327420,d: "Urns (e.g., gypsum, plaster of paris) manufacturing"},
{c:327420,d: "Vases (e.g., gypsum, plaster of paris) manufacturing"},
{c:327420,d: "Wallboard, gypsum, manufacturing"},
{c:327910,d: "Abrasive products manufacturing"},
{c:327910,d: "Aluminum oxide (fused) abrasives manufacturing"},
{c:327910,d: "Buffing and polishing wheels, abrasive and nonabrasive, manufacturing"},
{c:327910,d: "Cloth (e.g., aluminum oxide, garnet, emery, silicon carbide) coated manufacturing"},
{c:327910,d: "Diamond dressing wheels manufacturing"},
{c:327910,d: "Grains, abrasive, natural and artificial, manufacturing"},
{c:327910,d: "Grinding balls, ceramic, manufacturing"},
{c:327910,d: "Grinding wheels manufacturing"},
{c:327910,d: "Paper (e.g., aluminum oxide, emery, garnet, silicon carbide), abrasive-coated, made from purchased paper"},
{c:327910,d: "Polishing wheels manufacturing"},
{c:327910,d: "Pumice and pumicite abrasives manufacturing"},
{c:327910,d: "Sandpaper manufacturing"},
{c:327910,d: "Silicon carbide abrasives manufacturing"},
{c:327910,d: "Steel shot abrasives manufacturing"},
{c:327910,d: "Wheels, abrasive, manufacturing"},
{c:327910,d: "Wheels, polishing and grinding, manufacturing"},
{c:327910,d: "Whetstones manufacturing"},
{c:327991,d: "Architectural sculptures, stone, manufacturing"},
{c:327991,d: "Baptismal fonts, cut stone, manufacturing"},
{c:327991,d: "Blackboards, unframed, slate, manufacturing"},
{c:327991,d: "Burial vaults, stone, manufacturing"},
{c:327991,d: "Countertops, stone, manufacturing"},
{c:327991,d: "Curbing, granite and stone, manufacturing"},
{c:327991,d: "Cut stone bases (e.g., desk sets pedestals, lamps, plaques and similar small particles) manufacturing"},
{c:327991,d: "Cut stone products (e.g., blocks, statuary) manufacturing"},
{c:327991,d: "Dimension stone dressing and manufacturing"},
{c:327991,d: "Dimension stone for buildings manufacturing"},
{c:327991,d: "Ecclesiastical statuary, stone, manufacturing"},
{c:327991,d: "Flagstones cutting"},
{c:327991,d: "Furniture, cut stone (i.e., benches, tables, church), manufacturing"},
{c:327991,d: "Garden furniture, stone, manufacturing"},
{c:327991,d: "Monuments and tombstone, cut stone (except finishing or lettering to order only), manufacturing"},
{c:327991,d: "Slate products manufacturing"},
{c:327991,d: "Statuary, marble, manufacturing"},
{c:327991,d: "Table tops, marble, manufacturing"},
{c:327992,d: "Barite processing beyond beneficiation"},
{c:327992,d: "Barium processing beyond beneficiation"},
{c:327992,d: "Blast furnace slag processing"},
{c:327992,d: "Desiccants, activated clay, manufacturing"},
{c:327992,d: "Diatomaceous earth processing beyond beneficiation"},
{c:327992,d: "Feldspar processing beyond beneficiation"},
{c:327992,d: "Flint processing beyond beneficiation"},
{c:327992,d: "Fuller's earth processing beyond beneficiating"},
{c:327992,d: "Graphite, natural (e.g., ground, pulverized, refined, blended), manufacturing"},
{c:327992,d: "Kaolin, processing beyond beneficiation"},
{c:327992,d: "Lead, black (i.e., natural graphite), ground, refined, or blended, manufacturing"},
{c:327992,d: "Magnesite, crude (e.g., calcined, dead-burned, ground), manufacturing"},
{c:327992,d: "Mica processing beyond beneficiation"},
{c:327992,d: "Perlite aggregates manufacturing"},
{c:327992,d: "Perlite, expanded, manufacturing"},
{c:327992,d: "Pumice (except abrasives) processing beyond beneficiation"},
{c:327992,d: "Pyrophyllite processing beyond beneficiation"},
{c:327992,d: "Shale, expanded, manufacturing"},
{c:327992,d: "Silicon, ultra high purity, manufacturing"},
{c:327992,d: "Talc processing beyond beneficiation"},
{c:327992,d: "Vermiculite, exfoliated, manufacturing"},
{c:327993,d: "Fiberglass insulation products manufacturing"},
{c:327993,d: "Insulating batts, fills, or blankets, fiberglass, manufacturing"},
{c:327993,d: "Mineral wool insulation materials manufacturing"},
{c:327993,d: "Mineral wool products (e.g., board, insulation, tile) manufacturing"},
{c:327999,d: "Asbestos products (except brake shoes and clutches) manufacturing"},
{c:327999,d: "Asphalt concrete crushing and grinding (except at construction site)"},
{c:327999,d: "Built-up mica manufacturing"},
{c:327999,d: "Ceramic fiber manufacturing"},
{c:327999,d: "Concrete crushing and grinding (except at construction site)"},
{c:327999,d: "Concrete, dry mixture, manufacturing"},
{c:327999,d: "Dry mix concrete manufacturing"},
{c:327999,d: "Ecclesiastical statuary, paper mache, manufacturing"},
{c:327999,d: "Images, small papier-mache, manufacturing"},
{c:327999,d: "Manmade and engineered proppants (e.g., resin-coated sand, ceramic materials) manufacturing"},
{c:327999,d: "Mica products manufacturing"},
{c:327999,d: "Papier-mache statuary and related art goods (e.g., urns, vases) manufacturing"},
{c:327999,d: "Peat pots, molded pulp, manufacturing"},
{c:327999,d: "Statuary, vases, and urns, papier-mache, manufacturing"},
{c:327999,d: "Stones, synthetic, for gem stones and industrial use, manufacturing"},
{c:327999,d: "Stucco and stucco products manufacturing"},
{c:327999,d: "Synthetic stones, for gem stones and industrial use, manufacturing"},
{c:331110,d: "Armor plate made in iron and steel mills"},
{c:331110,d: "Axles, rolled or forged, made in iron and steel mills"},
{c:331110,d: "Balls, steel, made in iron and steel mills"},
{c:331110,d: "Bars, concrete reinforcing (rebar) made in steel mills"},
{c:331110,d: "Bars, iron, made in iron and steel mills"},
{c:331110,d: "Bars, steel, made in iron and steel mills"},
{c:331110,d: "Billets, steel, made in iron and steel mills"},
{c:331110,d: "Blackplate made in iron and steel mills"},
{c:331110,d: "Blast furnaces"},
{c:331110,d: "Blooms, steel, made in iron and steel mills"},
{c:331110,d: "Car wheels, rolled steel, made in iron and steel mills"},
{c:331110,d: "Coke oven products made in iron and steel mills"},
{c:331110,d: "Concrete reinforcing bar (rebar) made in iron and steel mills"},
{c:331110,d: "Direct reduction of iron ore"},
{c:331110,d: "Electrometallurgical ferroalloy manufacturing"},
{c:331110,d: "Electrometallurgical steel manufacturing"},
{c:331110,d: "Fence posts, iron or steel, made in iron and steel mills"},
{c:331110,d: "Ferroalloys manufacturing"},
{c:331110,d: "Ferrochromium manufacturing"},
{c:331110,d: "Ferromanganese manufacturing"},
{c:331110,d: "Ferromolybdenum manufacturing"},
{c:331110,d: "Ferrophosphorus manufacturing"},
{c:331110,d: "Ferrosilicon manufacturing"},
{c:331110,d: "Ferrotitanium manufacturing"},
{c:331110,d: "Ferrotungsten manufacturing"},
{c:331110,d: "Ferrovanadium manufacturing"},
{c:331110,d: "Flakes, iron or steel, made in iron and steel mills"},
{c:331110,d: "Flats, iron or steel, made in iron and steel mills"},
{c:331110,d: "Forgings, iron or steel, made in iron and steel mills"},
{c:331110,d: "Frogs, iron or steel, made in iron and steel mills"},
{c:331110,d: "Galvanizing metals and metal formed products made in iron and steel mills"},
{c:331110,d: "Gun forgings made in iron and steel mills"},
{c:331110,d: "High percentage nonferrous alloying elements (i.e., ferroalloys) manufacturing"},
{c:331110,d: "Hoops made in iron and steel mills"},
{c:331110,d: "Hoops, galvanized, made in iron and steel mills"},
{c:331110,d: "Hot-rolling iron or steel products in iron and steel mills"},
{c:331110,d: "Ingot made in iron and steel mills"},
{c:331110,d: "Iron ore recovery from open hearth slag"},
{c:331110,d: "Iron sinter made in iron and steel mills"},
{c:331110,d: "Iron, pig, manufacturing"},
{c:331110,d: "Manganese metal ferroalloys manufacturing"},
{c:331110,d: "Mesh, wire, made in iron and steel mills"},
{c:331110,d: "Mini-mills, steel"},
{c:331110,d: "Molybdenum silicon ferroalloys manufacturing"},
{c:331110,d: "Nut rods, iron or steel, made in iron and steel mills"},
{c:331110,d: "Paste, iron or steel, made in iron and steel mills"},
{c:331110,d: "Pig iron manufacturing"},
{c:331110,d: "Pilings, iron or steel plain sheet, made in iron and steel mills"},
{c:331110,d: "Pipe, iron or steel, made in iron and steel mills"},
{c:331110,d: "Plate, iron or steel, made in iron and steel mills"},
{c:331110,d: "Powder, iron or steel, made in iron and steel mills"},
{c:331110,d: "Rail joints and fastenings made in iron and steel mills"},
{c:331110,d: "Railroad crossings, iron or steel, made in iron and steel mills"},
{c:331110,d: "Rails rerolled or renewed in iron and steel mills"},
{c:331110,d: "Rails, iron or steel, made in iron and steel mills"},
{c:331110,d: "Rods, iron or steel, made in iron and steel mills"},
{c:331110,d: "Rounds, tube, steel, made in iron and steel mills"},
{c:331110,d: "Sheet pilings, plain, iron or steel, made in iron and steel mills"},
{c:331110,d: "Sheets, steel, made in iron and steel mills"},
{c:331110,d: "Shell slugs, steel, made in iron and steel mills"},
{c:331110,d: "Silicomanganese ferroalloys manufacturing"},
{c:331110,d: "Skelp, iron or steel, made in iron and steel mills"},
{c:331110,d: "Slab, steel, made in iron and steel mills"},
{c:331110,d: "Spiegeleisen ferroalloys manufacturing"},
{c:331110,d: "Spike rods made in iron and steel mills"},
{c:331110,d: "Sponge iron"},
{c:331110,d: "Stainless steel made in iron and steel mills"},
{c:331110,d: "Steel balls made in iron and steel mills"},
{c:331110,d: "Steel manufacturing"},
{c:331110,d: "Steel mill products (e.g., bar, plate, rod, sheet, structural shapes) manufacturing"},
{c:331110,d: "Steel mills"},
{c:331110,d: "Steel, from pig iron, manufacturing"},
{c:331110,d: "Strip, galvanized iron or steel, made in iron and steel mills"},
{c:331110,d: "Strip, iron or steel, made in iron and steel mills"},
{c:331110,d: "Structural shapes, iron or steel, made in iron and steel mills"},
{c:331110,d: "Superalloys, iron or steel, manufacturing"},
{c:331110,d: "Template, made in iron and steel mills, manufacturing"},
{c:331110,d: "Terneplate made in iron and steel mills"},
{c:331110,d: "Ternes, iron or steel, long or short, made in iron and steel mills"},
{c:331110,d: "Tie plates, iron or steel, made in iron and steel mills"},
{c:331110,d: "Tin-free steel made in iron and steel mills"},
{c:331110,d: "Tinplate made in iron and steel mills"},
{c:331110,d: "Tool steel made in iron and steel mills"},
{c:331110,d: "Tube rounds, iron or steel, made in iron and steel mills"},
{c:331110,d: "Tube, iron or steel, made in iron and steel mills"},
{c:331110,d: "Tubing, seamless steel, made in iron and steel mills"},
{c:331110,d: "Tubing, wrought iron or steel, made in iron and steel mills"},
{c:331110,d: "Well casings, iron or steel, made in iron and steel mills"},
{c:331110,d: "Wheels, car and locomotive, iron or steel, made in iron and steel mills"},
{c:331110,d: "Wire products, iron or steel, made in iron and steel mills"},
{c:331110,d: "Wrought iron or steel pipe and tubing made in iron and steel mills"},
{c:331210,d: "Boiler tubes, wrought, made from purchased iron"},
{c:331210,d: "Conduit, welded and lock joint, made from purchased iron or steel"},
{c:331210,d: "Pipe (e.g., heavy riveted, lock joint, seamless, welded) made from purchased iron or steel"},
{c:331210,d: "Tube (e.g., heavy riveted, lock joint, seamless, welded) made from purchased iron or steel"},
{c:331210,d: "Tubing, mechanical and hypodermic sizes, cold-drawn stainless steel, made from purchased steel"},
{c:331210,d: "Well casings (e.g., heavy riveted, lock joint, welded, wrought) made from purchased iron or steel"},
{c:331221,d: "Bars, concrete reinforcing (rebar), made from purchased steel in steel rolling mills"},
{c:331221,d: "Bars, steel, made from purchased steel in cold rolling mills"},
{c:331221,d: "Cold rolling steel shapes (e.g., bar, plate, rod, sheet, strip) made from purchased steel"},
{c:331221,d: "Concrete reinforcing bar (rebar), made from purchased steel in cold rolling mills"},
{c:331221,d: "Concrete reinforcing bar (rebar), made from purchased steel in steel rolling mills"},
{c:331221,d: "Corrugating iron or steel in cold rolling mills made from purchased iron or steel"},
{c:331221,d: "Ferrous metal powder, paste, and flake made from purchased iron or steel"},
{c:331221,d: "Flakes made from purchased iron or steel"},
{c:331221,d: "Flat bright steel strip made in cold rolling mills made from purchased steel"},
{c:331221,d: "Hot-rolling purchased steel"},
{c:331221,d: "Metal powder and flake made from purchased iron or steel"},
{c:331221,d: "Nut rods, iron or steel, made in cold rolling mills"},
{c:331221,d: "Paste made from purchased iron or steel"},
{c:331221,d: "Powder made from purchased iron or steel"},
{c:331221,d: "Razor blade strip steel made in cold rolling mills"},
{c:331221,d: "Wire, flat, rolled strip, made in cold rolling mills"},
{c:331222,d: "Barbed and twisted wire made in wire drawing plants"},
{c:331222,d: "Baskets, iron or steel, made in wire drawing plants"},
{c:331222,d: "Brads, iron or steel, wire or cut, made in wire drawing plants"},
{c:331222,d: "Cable, iron or steel, insulated or armored, made in wire drawing plants"},
{c:331222,d: "Chain link fencing, iron or steel, made in wire drawing plants"},
{c:331222,d: "Drawing iron or steel wire from purchased iron or steel"},
{c:331222,d: "Drawing iron or steel wire from purchased iron or steel and fabricating wire products"},
{c:331222,d: "Fence gates, posts, and fittings, iron or steel, made in wire drawing plants"},
{c:331222,d: "Form ties made in wire drawing plants"},
{c:331222,d: "Horseshoe nails, iron or steel, made in wire drawing plants"},
{c:331222,d: "Mesh, wire, made in wire drawing mills"},
{c:331222,d: "Nails, iron or steel, made in wire drawing plants"},
{c:331222,d: "Paper clips, iron or steel, made in wire drawing plants"},
{c:331222,d: "Spikes, iron or steel, made in wire drawing plants"},
{c:331222,d: "Staples, iron or steel, made in wire drawing plants"},
{c:331222,d: "Tacks, iron or steel, made in wire drawing plants"},
{c:331222,d: "Tie wires made in wire drawing plants"},
{c:331222,d: "Welded iron or steel wire fabric made in wire drawing plants"},
{c:331222,d: "Wire cages, iron or steel, made in wire drawing plants"},
{c:331222,d: "Wire carts (e.g., grocery, household, industrial), iron or steel, made in wire drawing plants"},
{c:331222,d: "Wire cloth, iron or steel, made in wire drawing plants"},
{c:331222,d: "Wire garment hangers, iron or steel, made in wire drawing plants"},
{c:331222,d: "Wire products, iron or steel, made in wire drawing plants"},
{c:331222,d: "Wire, iron or steel (e.g., armored, bare, insulated), made in wire drawing plants"},
{c:331313,d: "Alumina refining"},
{c:331313,d: "Aluminum alloys made from bauxite or alumina producing primary aluminum and manufacturing"},
{c:331313,d: "Aluminum ingot and other primary aluminum production shapes made from bauxite or alumina"},
{c:331313,d: "Aluminum oxide refining"},
{c:331313,d: "Aluminum producing from alumina"},
{c:331313,d: "Aluminum shapes (e.g., bar, ingot, rod, sheet) made by producing primary aluminum and manufacturing"},
{c:331313,d: "Extrusion ingot, primary aluminum, manufacturing"},
{c:331313,d: "Ingot, primary aluminum, manufacturing"},
{c:331313,d: "Primary aluminum production and manufacturing aluminum alloys"},
{c:331313,d: "Primary aluminum production and manufacturing aluminum shapes (e.g., bar, ingot, rod, sheet)"},
{c:331313,d: "Primary refining of aluminum"},
{c:331313,d: "Primary smelting of aluminum"},
{c:331313,d: "Refining aluminum, primary"},
{c:331313,d: "Slab, primary aluminum, manufacturing"},
{c:331314,d: "Alloying purchased aluminum metals"},
{c:331314,d: "Aluminum alloys made from scrap or dross"},
{c:331314,d: "Aluminum billet made from purchased aluminum"},
{c:331314,d: "Aluminum billet made in integrated secondary smelting and rolling mills"},
{c:331314,d: "Aluminum extrusion ingot (i.e., billet), secondary"},
{c:331314,d: "Aluminum flakes made from purchased aluminum"},
{c:331314,d: "Aluminum ingot made from purchased aluminum"},
{c:331314,d: "Aluminum ingot, secondary smelting of aluminum and manufacturing"},
{c:331314,d: "Aluminum ingot, secondary, manufacturing"},
{c:331314,d: "Aluminum recovering from scrap and making ingot and billet (except by rolling)"},
{c:331314,d: "Aluminum smelting, secondary, and making ingot and billet (except by rolling)"},
{c:331314,d: "Flakes, aluminum, made from purchased aluminum"},
{c:331314,d: "Metal powder and flake made from purchased aluminum"},
{c:331314,d: "Paste made from purchased aluminum"},
{c:331314,d: "Powder made from purchased aluminum"},
{c:331314,d: "Refining aluminum, secondary"},
{c:331315,d: "Aluminum foil made by flat rolling purchased aluminum"},
{c:331315,d: "Aluminum foil made in integrated secondary smelting and flat rolling mills"},
{c:331315,d: "Aluminum plate made by continuous casting purchased aluminum"},
{c:331315,d: "Aluminum plate made by flat rolling purchased aluminum"},
{c:331315,d: "Aluminum plate made in integrated secondary smelting and continuous casting mills"},
{c:331315,d: "Aluminum plate made in integrated secondary smelting and flat rolling mills"},
{c:331315,d: "Aluminum sheet made by flat rolling purchased aluminum"},
{c:331315,d: "Aluminum sheet made in integrated secondary smelting and flat rolling mills"},
{c:331315,d: "Aluminum welded tube made by flat rolling purchased aluminum"},
{c:331315,d: "Aluminum welded tube made in integrated secondary smelting and flat rolling mills"},
{c:331315,d: "Foil, aluminum, made by flat rolling purchased aluminum"},
{c:331315,d: "Foil, aluminum, made in integrated secondary smelting and flat rolling mills"},
{c:331315,d: "Plate, aluminum, made by continuous casting purchased aluminum"},
{c:331315,d: "Plate, aluminum, made by flat rolling purchased aluminum"},
{c:331315,d: "Plate, aluminum, made in integrated secondary smelting and continuous casting mills"},
{c:331315,d: "Plate, aluminum, made in integrated secondary smelting and flat rolling mills"},
{c:331315,d: "Sheet, aluminum, made by flat rolling purchased aluminum"},
{c:331315,d: "Sheet, aluminum, made in integrated secondary smelting and flat rolling mills"},
{c:331315,d: "Tube, welded, aluminum, made by flat rolling purchased aluminum"},
{c:331315,d: "Tube, welded, aluminum, made in integrated secondary smelting and flat rolling mills"},
{c:331318,d: "Aluminum bar made by extruding purchased aluminum"},
{c:331318,d: "Aluminum bar made in integrated secondary smelting and extruding mills"},
{c:331318,d: "Aluminum pipe made by extruding purchased aluminum"},
{c:331318,d: "Aluminum pipe made in integrated secondary smelting and extruding mills"},
{c:331318,d: "Aluminum rod made by extruding purchased aluminum"},
{c:331318,d: "Aluminum rod made in integrated secondary smelting and extruding mills"},
{c:331318,d: "Aluminum tube blooms made by extruding purchased aluminum"},
{c:331318,d: "Aluminum tube blooms made in integrated secondary smelting and extruding mills"},
{c:331318,d: "Aluminum tube made by drawing or extruding purchased aluminum"},
{c:331318,d: "Aluminum tube made in integrated secondary smelting and drawing plants"},
{c:331318,d: "Aluminum tube made in integrated secondary smelting and extruding mills"},
{c:331318,d: "Apparatus wire or cord made in aluminum wire drawing plants"},
{c:331318,d: "Automotive or aircraft wire and cable made in aluminum wire drawing plants"},
{c:331318,d: "Bar made by extruding purchased aluminum"},
{c:331318,d: "Bar made by rolling purchased aluminum"},
{c:331318,d: "Bar, aluminum, made in integrated secondary smelting and extruding mills"},
{c:331318,d: "Bar, aluminum, made in integrated secondary smelting and rolling mills"},
{c:331318,d: "Coaxial cable made in aluminum wire drawing plants"},
{c:331318,d: "Communications wire or cable made in aluminum wire drawing plants"},
{c:331318,d: "Cord sets, flexible, made in aluminum wire drawing plants"},
{c:331318,d: "Energy wire or cable made in aluminum wire drawing plants"},
{c:331318,d: "Extrusion billet made by rolling purchased aluminum"},
{c:331318,d: "Extrusion billet, aluminum, made in integrated secondary smelting and rolling mills"},
{c:331318,d: "Extrusion ingot made by rolling purchased aluminum"},
{c:331318,d: "Extrusion ingot, aluminum, made in integrated secondary smelting and rolling mills"},
{c:331318,d: "Ingot made by rolling purchased aluminum"},
{c:331318,d: "Ingot, aluminum, made in integrated secondary smelting and rolling mills"},
{c:331318,d: "Insulated wire or cable made in aluminum wire drawing plants"},
{c:331318,d: "Magnet wire, insulated, made in aluminum wire drawing plants"},
{c:331318,d: "Mesh, wire, made in aluminum wire drawing plants"},
{c:331318,d: "Nails, aluminum, made in wire drawing plants"},
{c:331318,d: "Pipe made by extruding purchased aluminum"},
{c:331318,d: "Pipe, aluminum, made in integrated secondary smelting and extruding mills"},
{c:331318,d: "Rails made by rolling or drawing purchased aluminum"},
{c:331318,d: "Rails, aluminum, made in integrated secondary smelting and drawing plants"},
{c:331318,d: "Rails, aluminum, made in integrated secondary smelting and rolling mills"},
{c:331318,d: "Rod made by extruding purchased aluminum"},
{c:331318,d: "Rod made by rolling purchased aluminum"},
{c:331318,d: "Rod, aluminum, made in integrated secondary smelting and extruding mills"},
{c:331318,d: "Rod, aluminum, made in integrated secondary smelting and rolling mills"},
{c:331318,d: "Shipboard cable made in aluminum wire drawing plants"},
{c:331318,d: "Signal and control cable made in aluminum wire drawing plants"},
{c:331318,d: "Structural shapes made by rolling purchased aluminum"},
{c:331318,d: "Structural shapes, aluminum, made in integrated secondary smelting and rolling mills"},
{c:331318,d: "Tube blooms made by extruding purchased aluminum"},
{c:331318,d: "Tube blooms, aluminum, made in integrated secondary smelting and extruding mills"},
{c:331318,d: "Tube made by drawing or extruding purchased aluminum"},
{c:331318,d: "Tube, aluminum, made in integrated secondary smelting and drawing plants"},
{c:331318,d: "Tube, aluminum, made in integrated secondary smelting and extruding mills"},
{c:331318,d: "Weatherproof wire or cable made in aluminum wire drawing plants"},
{c:331318,d: "Wire cloth made in aluminum wire drawing plants"},
{c:331318,d: "Wire screening, aluminum, made in integrated secondary smelting and drawing plants"},
{c:331318,d: "Wire, armored, made in aluminum wire drawing plants"},
{c:331318,d: "Wire, bare, made in aluminum wire drawing plants"},
{c:331318,d: "Wire, insulated, made in aluminum wire drawing plants"},
{c:331410,d: "Antimony refining, primary"},
{c:331410,d: "Bars made in primary copper smelting and refining mills"},
{c:331410,d: "Beryllium refining, primary"},
{c:331410,d: "Bismuth refining, primary"},
{c:331410,d: "Blister copper manufacturing"},
{c:331410,d: "Cadmium refining, primary"},
{c:331410,d: "Chromium refining, primary"},
{c:331410,d: "Cobalt refining, primary"},
{c:331410,d: "Copper alloys made in primary copper smelting and refining mills"},
{c:331410,d: "Copper concentrate refining"},
{c:331410,d: "Copper shapes (e.g., bar, billet, ingot, plate, sheet) made in primary copper smelting and refining mills"},
{c:331410,d: "Copper smelting and refining, primary"},
{c:331410,d: "Germanium refining, primary"},
{c:331410,d: "Gold bullion or dore bar produced at primary metal refineries"},
{c:331410,d: "Gold refining, primary"},
{c:331410,d: "Ingot, primary, nonferrous metals (except aluminum), manufacturing"},
{c:331410,d: "Iridium refining, primary"},
{c:331410,d: "Lead smelting and refining, primary"},
{c:331410,d: "Magnesium refining, primary"},
{c:331410,d: "Nickel refining, primary"},
{c:331410,d: "Niobium refining, primary"},
{c:331410,d: "Nonferrous metal (except aluminum) shapes made in primary nonferrous metal smelting and refining mills"},
{c:331410,d: "Nonferrous metals (except aluminum) made in primary nonferrous metal smelting and refining mills"},
{c:331410,d: "Nonferrous metals (except aluminum) smelting and refining, primary"},
{c:331410,d: "Platinum refining, primary"},
{c:331410,d: "Precious metals refining, primary"},
{c:331410,d: "Primary refining of copper"},
{c:331410,d: "Primary refining of nonferrous metals (except aluminum)"},
{c:331410,d: "Primary smelting of copper"},
{c:331410,d: "Primary smelting of nonferrous metals (except aluminum)"},
{c:331410,d: "Refining copper, primary"},
{c:331410,d: "Refining nonferrous metals and alloys (except aluminum), primary"},
{c:331410,d: "Rhenium refining, primary"},
{c:331410,d: "Selenium refining, primary"},
{c:331410,d: "Silver bullion or dore bar produced at primary metal refineries"},
{c:331410,d: "Silver refining, primary"},
{c:331410,d: "Slab, nonferrous metals (except aluminum) primary"},
{c:331410,d: "Smelting of nonferrous metals (except aluminum), primary"},
{c:331410,d: "Tantalum refining, primary"},
{c:331410,d: "Tellurium refining, primary"},
{c:331410,d: "Tin base alloys made in primary tin smelting and refining mills"},
{c:331410,d: "Tin refining, primary"},
{c:331410,d: "Titanium refining, primary"},
{c:331410,d: "Uranium refining, primary"},
{c:331410,d: "Zinc refining, primary"},
{c:331410,d: "Zirconium refining, primary"},
{c:331420,d: "Aircraft and automotive wire or cable made from purchased copper in wire drawing plants"},
{c:331420,d: "Alloying purchased copper"},
{c:331420,d: "Alloying purchased copper metals"},
{c:331420,d: "Apparatus wire or cord made from purchased copper in wire drawing plants"},
{c:331420,d: "Armored cable made from purchased copper in wire drawing plants"},
{c:331420,d: "Armored cable, copper, made in integrated secondary smelting and drawing plants"},
{c:331420,d: "Bar, copper and copper alloy, made from purchased copper or in integrated secondary smelting and rolling, drawing or extruding plants"},
{c:331420,d: "Brass products, rolling, drawing, or extruding, made from purchased copper or in integrated secondary smelting and rolling, drawing or extruding plants"},
{c:331420,d: "Bronze products, rolling, drawing, or extruding, made from purchased copper or in integrated secondary smelting and rolling, drawing or extruding plants"},
{c:331420,d: "Cable, copper (e.g., armored, bare, insulated), made from purchased copper in wire drawing plants"},
{c:331420,d: "Cable, copper (e.g., armored, bare, insulated), made in integrated secondary smelting and wire drawing plants"},
{c:331420,d: "Cartridge cups, discs, and sheets, copper and copper alloy, made from purchased copper or in integrated secondary smelting and rolling, drawing or extruding plants"},
{c:331420,d: "Coaxial cable, copper, made from purchased copper in wire drawing plants"},
{c:331420,d: "Communications wire or cable, copper, made from purchased copper in wire drawing plants"},
{c:331420,d: "Copper alloys (e.g., brass, bronze) made from purchased metal or scrap"},
{c:331420,d: "Copper and copper-based shapes (e.g., cake, ingot, slag, wire bar) made from purchased metal or scrap"},
{c:331420,d: "Copper foil made from purchased metal or scrap"},
{c:331420,d: "Copper powder, flakes, and paste made from purchased copper"},
{c:331420,d: "Copper products made by drawing purchased copper"},
{c:331420,d: "Copper products made by rolling, drawing, or extruding purchased copper"},
{c:331420,d: "Copper products made in integrated secondary smelting and extruding mills"},
{c:331420,d: "Copper products made in integrated secondary smelting mills and drawing plants"},
{c:331420,d: "Copper secondary smelting and alloying"},
{c:331420,d: "Copper secondary smelting and refining from purchased metal or scrap"},
{c:331420,d: "Cord sets, flexible, made from purchased copper in wire drawing plants"},
{c:331420,d: "Energy wire or cable, copper, made from purchased copper in wire drawing plants"},
{c:331420,d: "Foil, copper, made from purchased metal or scrap"},
{c:331420,d: "Insulated wire or cable made from purchased copper in wire drawing plants"},
{c:331420,d: "Insulated wire or cable, copper, made in integrated secondary smelting and wire drawing plants"},
{c:331420,d: "Magnet wire, insulated, made from purchased copper in wire drawing plants"},
{c:331420,d: "Mesh, wire, made from purchased copper in wire drawing plants"},
{c:331420,d: "Metal powder and flake made from purchased copper"},
{c:331420,d: "Paste made from purchased copper"},
{c:331420,d: "Pipe, extruded and drawn, brass, bronze, and copper, made from purchased copper or in integrated secondary smelting and rolling, drawing or extruding plants"},
{c:331420,d: "Plate, copper and copper alloy, made from purchased copper or in integrated secondary smelting and rolling, drawing or extruding plants"},
{c:331420,d: "Powder made from purchased copper"},
{c:331420,d: "Refining copper, secondary"},
{c:331420,d: "Rod, copper and copper alloy, made from purchased copper or in integrated secondary smelting and rolling, drawing or extruding plants"},
{c:331420,d: "Sheet, copper and copper alloy, made from purchased copper or in integrated secondary smelting and rolling, drawing or extruding plants"},
{c:331420,d: "Shipboard cable made from purchased copper in wire drawing plants"},
{c:331420,d: "Signal and control cable made from purchased copper in wire drawing plants"},
{c:331420,d: "Strip, copper and copper alloy, made from purchased copper or in integrated secondary smelting and rolling, drawing or extruding plants"},
{c:331420,d: "Tubing, copper and copper alloy, made from purchased copper or in integrated secondary smelting and rolling, drawing or extruding plants"},
{c:331420,d: "Weatherproof wire or cable made from purchased copper in wire drawing plants"},
{c:331420,d: "Wire cloth made from purchased copper in wire drawing plants"},
{c:331420,d: "Wire cloth, copper, made in integrated secondary smelting and wire drawing plants"},
{c:331420,d: "Wire, copper  (e.g., armored, bare, insulated), made from purchased copper in wire drawing plants"},
{c:331420,d: "Wire, copper (e.g., armored, bare, insulated), made in integrated secondary smelting and wire drawing plants"},
{c:331420,d: "Wire, mechanical, copper and copper alloy, made from purchased copper or in integrated secondary smelting and rolling, drawing or extruding plants"},
{c:331491,d: "Aircraft and automotive wire and cable (except aluminum, copper) made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Apparatus wire and cord (except aluminum, copper) made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Bar, nonferrous metals (except aluminum, copper), made from purchased metals in wire drawing plants or in integrated secondary smelting and rolling, drawing, or extruding plants"},
{c:331491,d: "Coaxial cable, nonferrous metals (except aluminum, copper), made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Communications wire or cable, nonferrous metals (except aluminum, copper), made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Cord sets, flexible, nonferrous metals (except aluminum, copper), made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Energy wire or cable, nonferrous metals (except aluminum, copper), made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Foil, gold, made by rolling purchased metals or scrap"},
{c:331491,d: "Foil, nickel, made by rolling purchased metals or scrap"},
{c:331491,d: "Foil, silver, made by rolling purchased metals or scrap"},
{c:331491,d: "Gold and gold alloy bar, sheet, strip, and tubing made from purchased metals or scrap"},
{c:331491,d: "Gold foil made by rolling purchased metals or scrap"},
{c:331491,d: "Gold rolling and drawing purchased metals or scrap"},
{c:331491,d: "Iridium bar, rod, sheet, strip and tubing made from purchased metals or scrap"},
{c:331491,d: "Lead and lead alloy bar, pipe, plate, rod, sheet, strip, and tubing made from purchased metals or scrap"},
{c:331491,d: "Lead rolling, drawing, or extruding purchased metals or scrap"},
{c:331491,d: "Magnesium and magnesium alloy bar, rod, shape, sheet, strip, and tubing made from purchased metals or scrap"},
{c:331491,d: "Magnesium foil made by rolling purchased metals or scrap"},
{c:331491,d: "Magnesium rolling, drawing, or extruding purchased metals or scrap"},
{c:331491,d: "Magnet wire, nonferrous metals (except aluminum, copper), made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Mesh, wire, nonferrous metals (except aluminum, copper), made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Molybdenum and molybdenum alloy bar, plate, pipe, rod, sheet, tubing, and wire made from purchased metals or scrap"},
{c:331491,d: "Molybdenum rolling, drawing, or extruding purchased metals or scrap"},
{c:331491,d: "Nails, nonferrous metals (except aluminum, copper), made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Nickel and nickel alloy pipe, plate, sheet, strip, and tubing made from purchased metals or scrap"},
{c:331491,d: "Nickel rolling, drawing, or extruding purchased metals or scrap"},
{c:331491,d: "Nonferrous metal shapes (except aluminum, copper) made by rolling, drawing, or extruding purchased nonferrous metal"},
{c:331491,d: "Nonferrous metal shapes (except aluminum, copper) made in integrated secondary smelting and extruding mills"},
{c:331491,d: "Nonferrous metal shapes (except aluminum, copper) made in integrated secondary smelting and rolling mills"},
{c:331491,d: "Nonferrous metal shapes (except aluminum, copper) made in integrated secondary smelting mills and wire drawing plants"},
{c:331491,d: "Nonferrous wire (except aluminum, copper) made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Nonferrous wire (except aluminum, copper) made in integrated secondary smelting mills and wire drawing plants"},
{c:331491,d: "Pipe, nonferrous metals (except aluminum, copper), made from purchased metals or scrap"},
{c:331491,d: "Plate, nonferrous metals (except aluminum, copper), made from purchased metals or scrap"},
{c:331491,d: "Platinum and platinum alloy rolling, drawing, or extruding from purchased metals or scrap"},
{c:331491,d: "Platinum and platinum alloy sheet and tubing made from purchased metals or scrap"},
{c:331491,d: "Precious metal bar, rod, sheet, strip, and tubing made from purchased metals or scrap"},
{c:331491,d: "Rod, nonferrous metals (except aluminum, copper), made from purchased metals or scrap"},
{c:331491,d: "Selenium bar, rod, sheet, strip, and tubing made from purchased metals or scrap"},
{c:331491,d: "Silver and silver alloy bar, rod, sheet, strip, and tubing made from purchased metals or scrap"},
{c:331491,d: "Silver foil made by rolling purchased metals or scrap"},
{c:331491,d: "Silver rolling, drawing, or extruding purchased metals or scrap"},
{c:331491,d: "Solder wire, nonferrous metals (except aluminum, copper), made from purchased metals or scrap"},
{c:331491,d: "Strip, nonferrous metals (except aluminum, copper), made from purchased metals or scrap"},
{c:331491,d: "Tin and tin alloy bar, pipe, rod, sheet, strip, and tubing made from purchased metals or scrap"},
{c:331491,d: "Tin rolling, drawing, or extruding purchased metals or scrap"},
{c:331491,d: "Titanium and titanium alloy bar, billet, rod, sheet, strip, and tubing made from purchased metals or scrap"},
{c:331491,d: "Titanium rolling, drawing, or extruding purchased metals or scrap"},
{c:331491,d: "Tubing, nonferrous metals (except aluminum, copper), made from purchased metals or scrap"},
{c:331491,d: "Tungsten bar, rod, sheet, strip, and tubing made by rolling, drawing, or extruding purchased metals or scrap"},
{c:331491,d: "Welding rod, uncoated, nonferrous metals (except aluminum, copper), made from purchased metals or scrap"},
{c:331491,d: "Wire cloth, nonferrous metals (except aluminum, copper), made from purchased metals or scrap"},
{c:331491,d: "Wire screening, nonferrous metals (except aluminum, copper), made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Wire, nonferrous metals (except aluminum, copper), made from purchased nonferrous metals (except aluminum, copper) in wire drawing plants"},
{c:331491,d: "Wire, nonferrous metals (except aluminum, copper), made in integrated secondary smelting mills and wire drawing plants"},
{c:331491,d: "Zinc and zinc alloy bar, plate, pipe, rod, sheet, tubing, and wire made from purchased metals or scrap"},
{c:331491,d: "Zinc rolling, drawing, or extruding purchased metals or scrap"},
{c:331491,d: "Zirconium and zirconium alloy bar, rod, billet, sheet, strip, and tubing made from purchased metals or scrap"},
{c:331491,d: "Zirconium rolling, drawing, or extruding purchased metals or scrap"},
{c:331492,d: "Alloying purchased nonferrous metals (except aluminum, copper)"},
{c:331492,d: "Detinning scrap (e.g., cans)"},
{c:331492,d: "Germanium recovering from scrap and/or alloying purchased metals"},
{c:331492,d: "Gold recovering from scrap and/or alloying purchased metals"},
{c:331492,d: "Ingot, nonferrous metals (except aluminum, copper), secondary smelting and refining"},
{c:331492,d: "Iridium recovering from scrap and/or alloying purchased metals"},
{c:331492,d: "Lead recovering from scrap and/or alloying purchased metals"},
{c:331492,d: "Magnesium recovering from scrap and/or alloying purchased metals"},
{c:331492,d: "Metal powder and flake nonferrous (except aluminum, copper) made from purchased metal"},
{c:331492,d: "Nickel recovering from scrap and/or alloying purchased metals"},
{c:331492,d: "Nonferrous alloys (except aluminum, copper) made from purchased nonferrous metals"},
{c:331492,d: "Nonferrous alloys (except aluminum, copper) made in integrated secondary smelting and alloying plants"},
{c:331492,d: "Nonferrous metals (except aluminum, copper) secondary smelting and refining"},
{c:331492,d: "Paste, nonferrous metals (except aluminum, copper), made from purchased metal"},
{c:331492,d: "Platinum recovering from scrap and/or alloying purchased metals"},
{c:331492,d: "Powder, nonferrous metals (except aluminum, copper), made from purchased metal"},
{c:331492,d: "Precious metals recovering from scrap and/or alloying purchased metals"},
{c:331492,d: "Recovering and refining of nonferrous metals (except aluminum, copper) from scrap"},
{c:331492,d: "Recovering silver from used photographic film or X-ray plates"},
{c:331492,d: "Refining nonferrous metals and alloys (except aluminum, copper), secondary"},
{c:331492,d: "Secondary refining of nonferrous metals (except aluminum, copper)"},
{c:331492,d: "Secondary smelting of nonferrous metals (except aluminum, copper)"},
{c:331492,d: "Selenium recovering from scrap and/or alloying purchased metals"},
{c:331492,d: "Silver recovering from scrap and/or alloying purchased metals"},
{c:331492,d: "Silver recovering from used photographic film or X-ray plates"},
{c:331492,d: "Smelting and refining of nonferrous metals (except aluminum, copper), secondary"},
{c:331492,d: "Smelting nonferrous metals (except aluminum, copper), secondary"},
{c:331492,d: "Superalloys, nonferrous based, made from purchased metals or scrap"},
{c:331492,d: "Tin recovering from scrap and/or alloying purchased metals"},
{c:331492,d: "Tungsten carbide powder made by metallurgical process"},
{c:331492,d: "Zinc dust reclaiming"},
{c:331492,d: "Zinc recovering from scrap and/or alloying purchased metals"},
{c:331511,d: "Cast iron brake shoes, railroad, manufacturing"},
{c:331511,d: "Cast iron pipe and pipe fittings manufacturing"},
{c:331511,d: "Cast iron railroad car wheels manufacturing"},
{c:331511,d: "Castings, compacted graphite iron, unfinished, manufacturing"},
{c:331511,d: "Castings, malleable iron, unfinished, manufacturing"},
{c:331511,d: "Castings, unfinished iron (e.g., ductile, gray, malleable, semisteel), manufacturing"},
{c:331511,d: "Cooking utensils, cast iron, manufacturing"},
{c:331511,d: "Ductile iron castings, unfinished, manufacturing"},
{c:331511,d: "Ductile iron foundries"},
{c:331511,d: "Fittings, soil and pressure pipe, cast iron, manufacturing"},
{c:331511,d: "Foundries, iron (i.e., ductile, gray, malleable, semisteel)"},
{c:331511,d: "Gray iron foundries"},
{c:331511,d: "Industrial molds, steel ingot, manufacturing"},
{c:331511,d: "Iron castings, unfinished, manufacturing"},
{c:331511,d: "Iron foundries"},
{c:331511,d: "Malleable iron foundries"},
{c:331511,d: "Manhole covers, cast iron, manufacturing"},
{c:331511,d: "Molds for casting steel ingots manufacturing"},
{c:331511,d: "Molds, steel ingot, industrial, manufacturing"},
{c:331511,d: "Pearlitic castings, malleable iron, unfinished, manufacturing"},
{c:331511,d: "Pipe and pipe fittings, cast iron, manufacturing"},
{c:331511,d: "Pipe couplings, cast iron, manufacturing"},
{c:331511,d: "Rolling mill rolls, iron, manufacturing"},
{c:331511,d: "Semisteel foundries"},
{c:331511,d: "Sewer pipe, cast iron, manufacturing"},
{c:331511,d: "Soil pipe, cast iron, manufacturing"},
{c:331511,d: "Water pipe, cast iron, manufacturing"},
{c:331512,d: "Foundries, steel investment"},
{c:331512,d: "Investment castings, steel, unfinished, manufacturing"},
{c:331512,d: "Steel investment castings, unfinished, manufacturing"},
{c:331512,d: "Steel investment foundries"},
{c:331513,d: "Alloy steel castings (except investment), unfinished, manufacturing"},
{c:331513,d: "Cast steel railroad car wheels, unfinished, manufacturing"},
{c:331513,d: "Castings, steel (except investment), unfinished, manufacturing"},
{c:331513,d: "Foundries, steel (except investment)"},
{c:331513,d: "Rolling mill rolls, steel, manufacturing"},
{c:331513,d: "Stainless steel castings (except investment), unfinished, manufacturing"},
{c:331513,d: "Steel castings (except investment), unfinished, manufacturing"},
{c:331513,d: "Steel foundries (except investment)"},
{c:331523,d: "Aluminum die-casting foundries"},
{c:331523,d: "Aluminum die-castings, unfinished, manufacturing"},
{c:331523,d: "Beryllium die-castings, unfinished, manufacturing"},
{c:331523,d: "Brass die-castings, unfinished, manufacturing"},
{c:331523,d: "Bronze die-castings, unfinished, manufacturing"},
{c:331523,d: "Copper die-casting foundries"},
{c:331523,d: "Copper die-castings, unfinished, manufacturing"},
{c:331523,d: "Die-castings, aluminum, unfinished, manufacturing"},
{c:331523,d: "Die-castings, nonferrous metals, unfinished, manufacturing"},
{c:331523,d: "Foundries, die-casting, aluminum"},
{c:331523,d: "Foundries, die-casting, nonferrous metals"},
{c:331523,d: "Lead die-castings, unfinished, manufacturing"},
{c:331523,d: "Magnesium die-castings, unfinished, manufacturing"},
{c:331523,d: "Nickel alloy die-castings, unfinished, manufacturing"},
{c:331523,d: "Nickel die-castings, unfinished, manufacturing"},
{c:331523,d: "Nonferrous die-casting foundries"},
{c:331523,d: "Titanium die-castings, unfinished, manufacturing"},
{c:331523,d: "Zinc die-castings, unfinished, manufacturing"},
{c:331524,d: "Aluminum castings (except die-castings), unfinished, manufacturing"},
{c:331524,d: "Aluminum foundries (except die-casting)"},
{c:331524,d: "Castings (except die-castings), unfinished, aluminum, manufacturing"},
{c:331524,d: "Foundries, aluminum (except die-casting)"},
{c:331524,d: "Investment castings, aluminum, unfinished, manufacturing"},
{c:331524,d: "Permanent mold castings, aluminum, unfinished, manufacturing"},
{c:331524,d: "Sand castings, aluminum, unfinished, manufacturing"},
{c:331529,d: "Beryllium castings (except die-castings), unfinished manufacturing"},
{c:331529,d: "Brass foundries (except die-casting)"},
{c:331529,d: "Bronze foundries (except die-casting)"},
{c:331529,d: "Castings (except die-castings), nonferrous metals (except aluminum), unfinished, manufacturing"},
{c:331529,d: "Copper alloy castings (except die-castings), unfinished, manufacturing"},
{c:331529,d: "Copper foundries (except die-casting)"},
{c:331529,d: "Foundries (except die-casting), nonferrous metals (except aluminum)"},
{c:331529,d: "Investment castings, nonferrous metal (except aluminum), unfinished, manufacturing"},
{c:331529,d: "Lead castings (except die-castings), unfinished, manufacturing"},
{c:331529,d: "Magnesium castings (except die-castings), unfinished, manufacturing"},
{c:331529,d: "Nickel castings (except die-castings), unfinished, manufacturing"},
{c:331529,d: "Nonferrous metals (except aluminum) foundries (except die-casting)"},
{c:331529,d: "Nonferrous metals (except aluminum) unfinished castings (except die-castings) manufacturing"},
{c:331529,d: "Permanent mold castings, nonferrous metal (except aluminum), unfinished, manufacturing"},
{c:331529,d: "Sand castings, nonferrous metals (except aluminum), unfinished, manufacturing"},
{c:331529,d: "Ship and boat propellers, cast brass, bronze and copper (except die-casting), unfinished, manufacturing"},
{c:331529,d: "Titanium castings (except die-castings), unfinished, manufacturing"},
{c:331529,d: "White metal castings (except die-castings), unfinished, manufacturing"},
{c:331529,d: "Zinc castings (except die-castings), unfinished, manufacturing"},
{c:332111,d: "Cold forgings made from purchased iron or steel, unfinished"},
{c:332111,d: "Drop forgings made from purchased iron or steel, unfinished"},
{c:332111,d: "Ferrous forgings made from purchased iron or steel, unfinished"},
{c:332111,d: "Forgings made from purchased iron or steel, unfinished"},
{c:332111,d: "Gun forgings made from purchased iron or steel, unfinished"},
{c:332111,d: "Hammer forgings made from purchased iron or steel, unfinished"},
{c:332111,d: "Horseshoes, ferrous forged, made from purchased iron or steel"},
{c:332111,d: "Hot forgings made from purchased iron or steel, unfinished"},
{c:332111,d: "Iron forgings made from purchased iron, unfinished"},
{c:332111,d: "Press forgings made from purchased iron or steel, unfinished"},
{c:332111,d: "Steel forgings made from purchased steel, unfinished"},
{c:332111,d: "Upset forgings made from purchased iron or steel, unfinished"},
{c:332112,d: "Aluminum forgings made from purchased metals, unfinished"},
{c:332112,d: "Cold forgings made from purchased nonferrous metals, unfinished"},
{c:332112,d: "Copper forgings made from purchased metals, unfinished"},
{c:332112,d: "Hammer forgings made from purchased nonferrous metals, unfinished"},
{c:332112,d: "Hot forgings made from purchased nonferrous metals, unfinished"},
{c:332112,d: "Press forgings made from purchased nonferrous metals, unfinished"},
{c:332112,d: "Titanium forgings made from purchased metals, unfinished"},
{c:332112,d: "Upset forgings made from purchased nonferrous metals, unfinished"},
{c:332114,d: "Custom roll forming metal products"},
{c:332114,d: "Gutters and down spouts sheet metal, custom roll formed, manufacturing"},
{c:332117,d: "Powder metallurgy products manufactured on a job or order basis"},
{c:332119,d: "Bottle caps and tops, metal, stamping"},
{c:332119,d: "Caps and tops, bottle, metal, stamping"},
{c:332119,d: "Closures, metal, stamping"},
{c:332119,d: "Crowns, metal (e.g., bottle, can), stamping"},
{c:332119,d: "Home canning lids and rings, metal stamping"},
{c:332119,d: "Lids, jar, metal, stamping"},
{c:332119,d: "Metal stampings (except automotive, cans, coins), unfinished, manufacturing"},
{c:332119,d: "Spinning unfinished metal products"},
{c:332119,d: "Stampings (except automotive, cans, coins), metal, unfinished, manufacturing"},
{c:332215,d: "Barber's scissors, manufacturing"},
{c:332215,d: "Blades, knife and razor, manufacturing"},
{c:332215,d: "Butcher's knives manufacturing"},
{c:332215,d: "Carving sets manufacturing"},
{c:332215,d: "Cleavers manufacturing"},
{c:332215,d: "Clippers, fingernail and toenail, manufacturing"},
{c:332215,d: "Cooking utensils, fabricated metal, manufacturing"},
{c:332215,d: "Cookware, fabricated metal, manufacturing"},
{c:332215,d: "Cutlery, nonprecious and precious plated metal, manufacturing"},
{c:332215,d: "Enameled metal cutting utensils"},
{c:332215,d: "Fishing knives manufacturing"},
{c:332215,d: "Flatware, nonprecious and precious plated metal, manufacturing"},
{c:332215,d: "Forks, table, nonprecious and precious plated metal, manufacturing"},
{c:332215,d: "Hair clippers for human use, nonelectric, manufacturing"},
{c:332215,d: "Hunting knives manufacturing"},
{c:332215,d: "Kitchen cutlery, nonprecious and precious plated metal, manufacturing"},
{c:332215,d: "Kitchen utensils, fabricated metal (e.g., colanders, garlic presses, ice cream scoops, spatulas), manufacturing"},
{c:332215,d: "Knife blades manufacturing"},
{c:332215,d: "Knife blanks manufacturing"},
{c:332215,d: "Knives (e.g., hunting, pocket, table nonprecious, table precious plated) manufacturing"},
{c:332215,d: "Nonstick metal cooking utensils"},
{c:332215,d: "Plated metal cutlery manufacturing"},
{c:332215,d: "Plated metal flatware manufacturing"},
{c:332215,d: "Pocket knives manufacturing"},
{c:332215,d: "Potato mashers manufacturing"},
{c:332215,d: "Pots and pans, fabricated metal, manufacturing"},
{c:332215,d: "Pressure cookers, household-type, manufacturing"},
{c:332215,d: "Razor blades manufacturing"},
{c:332215,d: "Razors (except electric) manufacturing"},
{c:332215,d: "Safety razor blades manufacturing"},
{c:332215,d: "Safety razors manufacturing"},
{c:332215,d: "Scissors, nonelectric, manufacturing"},
{c:332215,d: "Shears, nonelectric, household-type (e.g., kitchen, barber, tailor) manufacturing"},
{c:332215,d: "Spoons, table, nonprecious and precious plated metal, manufacturing"},
{c:332215,d: "Straight razors manufacturing"},
{c:332215,d: "Swords, nonprecious and precious plated metal, manufacturing"},
{c:332215,d: "Table cutlery, nonprecious and precious plated metal, manufacturing"},
{c:332215,d: "Tailors' scissors, nonelectric, manufacturing"},
{c:332215,d: "Teakettles and coffee pots, fabricated metal (except electric, glass), manufacturing"},
{c:332216,d: "Agricultural handtools (e.g., hay forks, hoes, rakes, spades), nonpowered, manufacturing"},
{c:332216,d: "Augers, nonpowered, manufacturing"},
{c:332216,d: "Awls manufacturing"},
{c:332216,d: "Axes manufacturing"},
{c:332216,d: "Bearing pullers, handtools, manufacturing"},
{c:332216,d: "Bits, edge tool, woodworking, manufacturing"},
{c:332216,d: "Blades, saw, all types, manufacturing"},
{c:332216,d: "Blow torches manufacturing"},
{c:332216,d: "Calipers and dividers, machinists' precision tools, manufacturing"},
{c:332216,d: "Can openers (except electric) manufacturing"},
{c:332216,d: "Carpenter's handtools, nonelectric, manufacturing"},
{c:332216,d: "Caulking guns, nonpowered, manufacturing"},
{c:332216,d: "C-clamps manufacturing"},
{c:332216,d: "Chain saw blades manufacturing"},
{c:332216,d: "Chisels manufacturing"},
{c:332216,d: "Clippers for animal use, nonelectric, manufacturing"},
{c:332216,d: "Coordinate and contour measuring machines, machinists' precision tools, manufacturing"},
{c:332216,d: "Counterbores and countersinking bits, woodworking, manufacturing"},
{c:332216,d: "Cutters, glass, manufacturing"},
{c:332216,d: "Cutting dies (e.g., paper, leather, textile) manufacturing"},
{c:332216,d: "Cutting dies (except metal cutting) manufacturing"},
{c:332216,d: "Dial indicators, machinists' precision tools, manufacturing"},
{c:332216,d: "Dies, cutting (except metal cutting), manufacturing"},
{c:332216,d: "Dies, steel rule (except metal cutting), manufacturing"},
{c:332216,d: "Dividers, machinists' precision tools, manufacturing"},
{c:332216,d: "Drawknives manufacturing"},
{c:332216,d: "Drill bits, woodworking, manufacturing"},
{c:332216,d: "Drills, handheld, nonelectric, manufacturing"},
{c:332216,d: "Edge tools, woodworking (e.g., augers, bits, countersinks), manufacturing"},
{c:332216,d: "Engraver's handtools, nonpowered, manufacturing"},
{c:332216,d: "Files, handheld, manufacturing"},
{c:332216,d: "Fish wire (i.e., electrical wiring tool) manufacturing"},
{c:332216,d: "Forks, handtools (e.g., garden, hay, manure), manufacturing"},
{c:332216,d: "Gauge blocks, machinists' precision tools, manufacturing"},
{c:332216,d: "Gauges, machinists' precision tools (except optical), manufacturing"},
{c:332216,d: "Gear pullers, handtools, manufacturing"},
{c:332216,d: "Gouges, woodworking, manufacturing"},
{c:332216,d: "Grass mowing equipment, nonpowered lawn and garden, manufacturing"},
{c:332216,d: "Guns, caulking, nonpowered, manufacturing"},
{c:332216,d: "Hair clippers for animal use, nonelectric, manufacturing"},
{c:332216,d: "Hammers, handtools, manufacturing"},
{c:332216,d: "Handheld edge tools (except scissors-type), nonelectric, manufacturing"},
{c:332216,d: "Handtool metal blades (e.g., putty knives, scrapers, screw drivers) manufacturing"},
{c:332216,d: "Handtools, machinists' precision, manufacturing"},
{c:332216,d: "Handtools, motor vehicle mechanics', manufacturing"},
{c:332216,d: "Hatchets manufacturing"},
{c:332216,d: "Hedge shears and trimmers, nonelectric, manufacturing"},
{c:332216,d: "Hoes, garden and mason's handtools, manufacturing"},
{c:332216,d: "Hooks, handtools (e.g., baling, bush, grass, husking), manufacturing"},
{c:332216,d: "Jacks (except hydraulic, pneumatic) manufacturing"},
{c:332216,d: "Jeweler's handtools, nonelectric, manufacturing"},
{c:332216,d: "Knives and bits for woodworking lathes, planers, and shapers manufacturing"},
{c:332216,d: "Lawn edgers, nonpowered, manufacturing"},
{c:332216,d: "Lawnmowers, nonpowered, manufacturing"},
{c:332216,d: "Leaf skimmers and rakes, nonpowered swimming pool, manufacturing"},
{c:332216,d: "Levels, carpenter's, manufacturing"},
{c:332216,d: "Machetes manufacturing"},
{c:332216,d: "Machine knives (except metal cutting) manufacturing"},
{c:332216,d: "Machinists' precision measuring tools (except optical) manufacturing"},
{c:332216,d: "Mallets (e.g., rubber, wood) manufacturing"},
{c:332216,d: "Mason's handtools manufacturing"},
{c:332216,d: "Mattocks (i.e., handtools) manufacturing"},
{c:332216,d: "Mauls, metal, manufacturing"},
{c:332216,d: "Measuring tools, machinist's (except optical), manufacturing"},
{c:332216,d: "Mechanic's handtools, nonpowered, manufacturing"},
{c:332216,d: "Metal cutting saw blades manufacturing"},
{c:332216,d: "Micrometers, machinist's precision tools, manufacturing"},
{c:332216,d: "Miter boxes manufacturing"},
{c:332216,d: "Picks (i.e., handtools) manufacturing"},
{c:332216,d: "Planes, handheld, nonpowered, manufacturing"},
{c:332216,d: "Pliers, handtools, manufacturing"},
{c:332216,d: "Plumbers' handtools, nonpowered, manufacturing"},
{c:332216,d: "Post hole diggers, nonpowered, manufacturing"},
{c:332216,d: "Precision tools, machinist's (except optical), manufacturing"},
{c:332216,d: "Pruners manufacturing"},
{c:332216,d: "Pry (i.e., crow) bars manufacturing"},
{c:332216,d: "Punches (except paper), nonpowered handtool, manufacturing"},
{c:332216,d: "Putty knives manufacturing"},
{c:332216,d: "Rakes, nonpowered handtool, manufacturing"},
{c:332216,d: "Rasps, handheld, manufacturing"},
{c:332216,d: "Ratchets, nonpowered, manufacturing"},
{c:332216,d: "Rulers, metal, manufacturing"},
{c:332216,d: "Saw blades, all types, manufacturing"},
{c:332216,d: "Saws, hand, nonpowered, manufacturing"},
{c:332216,d: "Scoops, metal (except kitchen-type), manufacturing"},
{c:332216,d: "Screw drivers, nonelectric, manufacturing"},
{c:332216,d: "Screwjacks manufacturing"},
{c:332216,d: "Scythes manufacturing"},
{c:332216,d: "Shears, nonelectric, tool-type (e.g., garden, pruners, tinsnip), manufacturing"},
{c:332216,d: "Shovels, handheld, manufacturing"},
{c:332216,d: "Sickles manufacturing"},
{c:332216,d: "Sledgehammers manufacturing"},
{c:332216,d: "Sockets and socket sets manufacturing"},
{c:332216,d: "Soldering guns and irons, handheld (including electric), manufacturing"},
{c:332216,d: "Soldering iron tips and tiplets manufacturing"},
{c:332216,d: "Spades and shovels, handheld, manufacturing"},
{c:332216,d: "Squares, carpenters', metal, manufacturing"},
{c:332216,d: "Stone cutting saw blades manufacturing"},
{c:332216,d: "Stonecutters' handtools, nonpowered, manufacturing"},
{c:332216,d: "Tape measures, metal, manufacturing"},
{c:332216,d: "Tinners' snips manufacturing"},
{c:332216,d: "Tools, hand, metal blade (e.g., putty knives, scrapers, screwdrivers)"},
{c:332216,d: "Tools, handheld, nonpowered (except kitchen-type), manufacturing"},
{c:332216,d: "Tools, woodworking edge (e.g., augers, bits, countersinks), manufacturing"},
{c:332216,d: "Trimmers, hedge, nonelectric, manufacturing"},
{c:332216,d: "Trowels manufacturing"},
{c:332216,d: "Vises (except machine tool attachments) manufacturing"},
{c:332216,d: "Wheel pullers, handtools, manufacturing"},
{c:332216,d: "Wood cutting saw blades manufacturing"},
{c:332216,d: "Wrenches, handtools, nonpowered, manufacturing"},
{c:332216,d: "Yardsticks, metal, manufacturing"},
{c:332311,d: "Buildings, prefabricated metal, manufacturing"},
{c:332311,d: "Carports, prefabricated metal, manufacturing"},
{c:332311,d: "Dwellings, prefabricated metal, manufacturing"},
{c:332311,d: "Farm buildings, prefabricated metal, manufacturing"},
{c:332311,d: "Garages, prefabricated metal, manufacturing"},
{c:332311,d: "Greenhouses, prefabricated metal, manufacturing"},
{c:332311,d: "Houses, prefabricated metal, manufacturing"},
{c:332311,d: "Panels, prefabricated metal building, manufacturing"},
{c:332311,d: "Portable buildings, prefabricated metal, manufacturing"},
{c:332311,d: "Prefabricated buildings, metal, manufacturing"},
{c:332311,d: "Prefabricated homes, metal, manufacturing"},
{c:332311,d: "Sections for prefabricated metal buildings manufacturing"},
{c:332311,d: "Sheds (e.g., garden, storage, utility), prefabricated metal, manufacturing"},
{c:332311,d: "Silos, prefabricated metal, manufacturing"},
{c:332311,d: "Utility buildings, prefabricated metal, manufacturing"},
{c:332311,d: "Warehouses, prefabricated metal, manufacturing"},
{c:332312,d: "Barge sections, prefabricated metal, manufacturing"},
{c:332312,d: "Bars, concrete reinforcing, manufacturing"},
{c:332312,d: "Boat sections, prefabricated metal, manufacturing"},
{c:332312,d: "Bridge sections, prefabricated metal, manufacturing"},
{c:332312,d: "Concrete reinforcing bar (rebar) assemblies, fabrication"},
{c:332312,d: "Dam gates, metal plate, manufacturing"},
{c:332312,d: "Expansion joints, metal, manufacturing"},
{c:332312,d: "Fabricated bar joists manufacturing"},
{c:332312,d: "Fabricated structural metal manufacturing"},
{c:332312,d: "Flood gates, metal plate, manufacturing"},
{c:332312,d: "Floor jacks, metal, manufacturing"},
{c:332312,d: "Floor posts, adjustable metal, manufacturing"},
{c:332312,d: "Highway bridge sections, prefabricated metal, manufacturing"},
{c:332312,d: "Joists, fabricated bar, manufacturing"},
{c:332312,d: "Landing mats, aircraft, metal, manufacturing"},
{c:332312,d: "Radio and television tower sections, fabricated structural metal, manufacturing"},
{c:332312,d: "Railway bridge sections, prefabricated metal, manufacturing"},
{c:332312,d: "Ship sections, prefabricated metal, manufacturing"},
{c:332312,d: "Steel joists manufacturing"},
{c:332312,d: "Steel railroad car racks manufacturing"},
{c:332312,d: "Structural steel, fabricated, manufacturing"},
{c:332312,d: "Television tower sections, fabricated structural metal, manufacturing"},
{c:332312,d: "Transmission tower sections, fabricated structural metal, manufacturing"},
{c:332313,d: "Airlocks, fabricated metal plate work, manufacturing"},
{c:332313,d: "Baffles, fabricated metal plate work, manufacturing"},
{c:332313,d: "Bins, fabricated metal plate work, manufacturing"},
{c:332313,d: "Breechings, fabricated metal plate work, manufacturing"},
{c:332313,d: "Buoys, fabricated plate work metal, manufacturing"},
{c:332313,d: "Casings, scroll, fabricated metal plate work, manufacturing"},
{c:332313,d: "Chutes, fabricated metal plate work, manufacturing"},
{c:332313,d: "Covers, annealing, fabricated metal plate work, manufacturing"},
{c:332313,d: "Covers, floating, fabricated metal plate work, manufacturing"},
{c:332313,d: "Culverts, fabricated metal plate work, manufacturing"},
{c:332313,d: "Cupolas, fabricated metal plate work, manufacturing"},
{c:332313,d: "Cyclones, industrial, fabricated metal plate work, manufacturing"},
{c:332313,d: "Ducting, fabricated metal plate work, manufacturing"},
{c:332313,d: "Fabricated plate work manufacturing"},
{c:332313,d: "Floating covers, fabricated metal plate work, manufacturing"},
{c:332313,d: "Flumes, fabricated metal plate work, manufacturing"},
{c:332313,d: "Fumigating chambers, fabricated metal plate work, manufacturing"},
{c:332313,d: "Hoods, industrial, fabricated metal plate work, manufacturing"},
{c:332313,d: "Hoppers, fabricated metal plate work, manufacturing"},
{c:332313,d: "Jackets, industrial, fabricated metal plate work, manufacturing"},
{c:332313,d: "Ladle bails, fabricated metal plate work, manufacturing"},
{c:332313,d: "Ladles, fabricated metal plate work, manufacturing"},
{c:332313,d: "Liners, industrial, fabricated metal plate work, manufacturing"},
{c:332313,d: "Nuclear shielding, fabricated metal plate work, manufacturing"},
{c:332313,d: "Penstocks, fabricated metal plate, manufacturing"},
{c:332313,d: "Pile shells, fabricated metal plate, manufacturing"},
{c:332313,d: "Pipe, fabricated metal plate, manufacturing"},
{c:332313,d: "Plate work (e.g., bending, cutting, punching, shaping, welding), fabricated metal, manufacturing"},
{c:332313,d: "Racks (e.g., trash), fabricated metal plate, manufacturing"},
{c:332313,d: "Reactor containment vessels, fabricated metal plate, manufacturing"},
{c:332313,d: "Rocket casings, fabricated metal work, manufacturing"},
{c:332313,d: "Smokestacks, fabricated metal boiler plate, manufacturing"},
{c:332313,d: "Space simulation chambers, fabricated metal plate work, manufacturing"},
{c:332313,d: "Sterilizing chambers, fabricated metal plate work, manufacturing"},
{c:332313,d: "Trash racks, fabricated metal plate work, manufacturing"},
{c:332313,d: "Troughs, industrial, fabricated metal plate work, manufacturing"},
{c:332313,d: "Truss plates, metal, manufacturing"},
{c:332313,d: "Tunnel lining, fabricated metal plate work, manufacturing"},
{c:332313,d: "Tunnels, wind, fabricated metal plate work, manufacturing"},
{c:332313,d: "Weldments manufacturing"},
{c:332321,d: "Baseboards, metal, manufacturing"},
{c:332321,d: "Casements, metal, manufacturing"},
{c:332321,d: "Door and jamb assemblies, metal, manufacturing"},
{c:332321,d: "Door frames and sash, metal, manufacturing"},
{c:332321,d: "Doors, metal, manufacturing"},
{c:332321,d: "Fire doors, metal, manufacturing"},
{c:332321,d: "Frames, door and window, metal, manufacturing"},
{c:332321,d: "Garage doors, metal, manufacturing"},
{c:332321,d: "Hangar doors, metal, manufacturing"},
{c:332321,d: "Jalousies, metal, manufacturing"},
{c:332321,d: "Louver windows, metal, manufacturing"},
{c:332321,d: "Molding and trim (except motor vehicle), metal, manufacturing"},
{c:332321,d: "Rolling doors for industrial buildings and warehouses, metal, manufacturing"},
{c:332321,d: "Sash, door and window, metal, manufacturing"},
{c:332321,d: "Screen doors, metal frame, manufacturing"},
{c:332321,d: "Screens, door and window, metal frame, manufacturing"},
{c:332321,d: "Shutters, door and window, metal, manufacturing"},
{c:332321,d: "Skylights, metal, manufacturing"},
{c:332321,d: "Storm doors and windows, metal, manufacturing"},
{c:332321,d: "Trim and molding (except motor vehicle), metal, manufacturing"},
{c:332321,d: "Trim, metal, manufacturing"},
{c:332321,d: "Weatherstrip, metal, manufacturing"},
{c:332321,d: "Window frames and sash, metal, manufacturing"},
{c:332321,d: "Window screens, metal frame, manufacturing"},
{c:332321,d: "Windows, metal, manufacturing"},
{c:332322,d: "Air cowls, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Awnings, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Canopies, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Casings, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Coal chutes, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Concrete forms, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Cornices, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Cowls, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Culverts, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Dampers, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Door hoods, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Downspouts, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Ducts, sheet metal, manufacturing"},
{c:332322,d: "Eaves, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Elbows for conductor pipe, hot air ducts, and stovepipe, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Flooring, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Flues, stove and furnace, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Flumes, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Forms, concrete, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Furnace casings, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Furnace flues, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Guardrails, highway, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Gutters, sheet metal (except custom roll formed), manufacturing"},
{c:332322,d: "Hampers, laundry, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Highway guardrails, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Hoods, range (except household-type), sheet metal (except stampings), manufacturing"},
{c:332322,d: "Irrigation pipe, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Joists, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Louvers, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Machine guards, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Mail chutes, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Pile shells, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Pipe, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Radiator shields and enclosures, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Roof deck, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Roofing, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Sheet metal work (except stampings) manufacturing"},
{c:332322,d: "Siding, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Spouts, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Stove boards, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Stove pipes and flues, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Studs, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Troughs, elevator, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Ventilators, sheet metal (except stampings), manufacturing"},
{c:332322,d: "Wells, light, sheet metal (except stampings), manufacturing"},
{c:332323,d: "Acoustical suspension systems, metal, manufacturing"},
{c:332323,d: "Architectural metalwork manufacturing"},
{c:332323,d: "Balcony railings, metal, manufacturing"},
{c:332323,d: "Bank fixtures, ornamental metal, manufacturing"},
{c:332323,d: "Bannisters, metal, manufacturing"},
{c:332323,d: "Barn stanchions and standards manufacturing"},
{c:332323,d: "Brasswork, ornamental, manufacturing"},
{c:332323,d: "Chain ladders, metal, manufacturing"},
{c:332323,d: "Channels, furring metal, manufacturing"},
{c:332323,d: "Corrals, metal, manufacturing"},
{c:332323,d: "Curtain wall, metal, manufacturing"},
{c:332323,d: "Elevator guide rails, metal, manufacturing"},
{c:332323,d: "Fences and gates (except wire), metal, manufacturing"},
{c:332323,d: "Fire escapes, metal, manufacturing"},
{c:332323,d: "Flagpoles, metal, manufacturing"},
{c:332323,d: "Flooring, open steel (i.e., grating), manufacturing"},
{c:332323,d: "Furring channels, sheet metal, manufacturing"},
{c:332323,d: "Gates, holding, sheet metal, manufacturing"},
{c:332323,d: "Gates, metal (except wire), manufacturing"},
{c:332323,d: "Gratings (i.e., open steel flooring) manufacturing"},
{c:332323,d: "Grills and grillwork, sheet metal, manufacturing"},
{c:332323,d: "Grillwork, ornamental metal, manufacturing"},
{c:332323,d: "Guards, bannisters, and railings, sheet metal, manufacturing"},
{c:332323,d: "Ladders, metal chain, manufacturing"},
{c:332323,d: "Ladders, permanently installed, metal, manufacturing"},
{c:332323,d: "Lath, expanded metal, manufacturing"},
{c:332323,d: "Ornamental metalwork manufacturing"},
{c:332323,d: "Partitions, ornamental metal, manufacturing"},
{c:332323,d: "Pipe bannisters, metal, manufacturing"},
{c:332323,d: "Pipe guards, metal, manufacturing"},
{c:332323,d: "Pipe railings, metal, manufacturing"},
{c:332323,d: "Purlins, metal, manufacturing"},
{c:332323,d: "Railings, metal, manufacturing"},
{c:332323,d: "Registers, metal air, manufacturing"},
{c:332323,d: "Scaffolds, metal, manufacturing"},
{c:332323,d: "Stair railings, metal, manufacturing"},
{c:332323,d: "Stair treads, metal, manufacturing"},
{c:332323,d: "Staircases, metal, manufacturing"},
{c:332323,d: "Stairs, metal, manufacturing"},
{c:332323,d: "Stalls, metal, manufacturing"},
{c:332323,d: "Treads, metal stair, manufacturing"},
{c:332323,d: "Wrought iron fences manufacturing"},
{c:332410,d: "Aftercoolers (i.e., heat exchangers) manufacturing"},
{c:332410,d: "Barometric condensers manufacturing"},
{c:332410,d: "Boiler casings manufacturing"},
{c:332410,d: "Boilers, power, manufacturing"},
{c:332410,d: "Condenser boxes, metal, manufacturing"},
{c:332410,d: "Condensers, steam, manufacturing"},
{c:332410,d: "Economizers (i.e., power boiler accessory) manufacturing"},
{c:332410,d: "Exchangers, heat, manufacturing"},
{c:332410,d: "Heat exchangers manufacturing"},
{c:332410,d: "Intercooler shells manufacturing"},
{c:332410,d: "Marine power boilers manufacturing"},
{c:332410,d: "Nuclear control drive mechanisms manufacturing"},
{c:332410,d: "Nuclear reactor steam supply systems manufacturing"},
{c:332410,d: "Nuclear reactors control rod drive mechanisms manufacturing"},
{c:332410,d: "Nuclear reactors manufacturing"},
{c:332410,d: "Power boilers manufacturing"},
{c:332410,d: "Reactors, nuclear, manufacturing"},
{c:332410,d: "Stationary power boilers manufacturing"},
{c:332410,d: "Steam condensers manufacturing"},
{c:332420,d: "Absorbers, gas, heavy gauge metal, manufacturing"},
{c:332420,d: "Accumulators, industrial pressure vessels, heavy gauge metal, manufacturing"},
{c:332420,d: "Acetylene cylinders, heavy gauge metal, manufacturing"},
{c:332420,d: "Air receiver tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Annealing vats, heavy gauge metal, manufacturing"},
{c:332420,d: "Autoclaves, industrial-type, heavy gauge metal, manufacturing"},
{c:332420,d: "Bulk storage tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Caissons, underwater work, heavy gauge metal, manufacturing"},
{c:332420,d: "Columns, fractionating, heavy gauge metal, manufacturing"},
{c:332420,d: "Cryogenic tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Cylinders, pressure, heavy gauge metal, manufacturing"},
{c:332420,d: "Digesters, industrial-type, heavy gauge metal, manufacturing"},
{c:332420,d: "Farm storage tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Fermentation tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Gas storage tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Kettles, heavy gauge metal, manufacturing"},
{c:332420,d: "Liquefied petroleum gas (LPG) cylinders manufacturing"},
{c:332420,d: "Liquid oxygen tanks manufacturing"},
{c:332420,d: "Nuclear waste casks, heavy gauge metal, manufacturing"},
{c:332420,d: "Oil storage tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Petroleum storage tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Pots (e.g., annealing, melting, smelting), heavy gauge metal, manufacturing"},
{c:332420,d: "Retorts, heavy gauge metal, manufacturing"},
{c:332420,d: "Septic tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Smelting pots and retorts manufacturing"},
{c:332420,d: "Stills, heavy gauge metal, manufacturing"},
{c:332420,d: "Storage tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Vacuum tanks, heavy gauge metal, manufacturing"},
{c:332420,d: "Vats, heavy gauge metal, manufacturing"},
{c:332420,d: "Vessels, heavy gauge metal, manufacturing"},
{c:332420,d: "Water tanks, heavy gauge metal, manufacturing"},
{c:332431,d: "Aerosol cans, light gauge metal, manufacturing"},
{c:332431,d: "Aluminum cans, light gauge metal, manufacturing"},
{c:332431,d: "Beer cans, light gauge metal, manufacturing"},
{c:332431,d: "Can lids and ends, light gauge metal, manufacturing"},
{c:332431,d: "Cans, aluminum, light gauge metal, manufacturing"},
{c:332431,d: "Cans, light gauge metal, manufacturing"},
{c:332431,d: "Cans, steel, light gauge metal, manufacturing"},
{c:332431,d: "Lids and ends, can, light gauge metal, manufacturing"},
{c:332431,d: "Metal cans, light gauge metal, manufacturing"},
{c:332431,d: "Soft drink cans manufacturing"},
{c:332431,d: "Soup cans, light gauge metal, manufacturing"},
{c:332431,d: "Steel cans, light gauge metal, manufacturing"},
{c:332431,d: "Tin plate cans, light gauge metal, manufacturing"},
{c:332439,d: "Air cargo containers, light gauge metal, manufacturing"},
{c:332439,d: "Ammunition boxes, light gauge metal, manufacturing"},
{c:332439,d: "Barrels, light gauge metal, manufacturing"},
{c:332439,d: "Beer kegs, light gauge metal, manufacturing"},
{c:332439,d: "Bins, light gauge metal, manufacturing"},
{c:332439,d: "Bottles, vacuum, light gauge metal, manufacturing"},
{c:332439,d: "Boxes, light gauge metal, manufacturing"},
{c:332439,d: "Cash boxes, light gauge metal, manufacturing"},
{c:332439,d: "Collapsible tubes (e.g., toothpaste, glue), light gauge metal, manufacturing"},
{c:332439,d: "Containers, air cargo, light gauge metal, manufacturing"},
{c:332439,d: "Containers, light gauge metal (except cans), manufacturing"},
{c:332439,d: "Drums, light gauge metal, manufacturing"},
{c:332439,d: "Fluid milk shipping containers, light gauge metal, manufacturing"},
{c:332439,d: "Garbage cans, light gauge metal, manufacturing"},
{c:332439,d: "Hoppers, light gauge metal, manufacturing"},
{c:332439,d: "Ice chests or coolers, light gauge metal, manufacturing"},
{c:332439,d: "Jugs, vacuum, light gauge metal, manufacturing"},
{c:332439,d: "Laundry hampers, light gauge metal, manufacturing"},
{c:332439,d: "Lunch boxes, light gauge metal, manufacturing"},
{c:332439,d: "Mailboxes, light gauge metal, manufacturing"},
{c:332439,d: "Shipping barrels, drums, kegs, and pails, light gauge metal, manufacturing"},
{c:332439,d: "Tool boxes, light gauge metal, manufacturing"},
{c:332439,d: "Vacuum bottles and jugs, light gauge metal, manufacturing"},
{c:332439,d: "Vats, light gauge metal, manufacturing"},
{c:332510,d: "Aircraft hardware, metal, manufacturing"},
{c:332510,d: "Appliance hardware, metal, manufacturing"},
{c:332510,d: "Automobile hardware, metal, manufacturing"},
{c:332510,d: "Brackets (i.e., builder's hardware-type), metal, manufacturing"},
{c:332510,d: "Builder's hardware, metal, manufacturing"},
{c:332510,d: "Cabinet hardware, metal, manufacturing"},
{c:332510,d: "Casket hardware, metal, manufacturing"},
{c:332510,d: "Casters, furniture, metal manufacturing"},
{c:332510,d: "Casters, industrial, metal, manufacturing"},
{c:332510,d: "Dead bolts, metal, manufacturing"},
{c:332510,d: "Door locks, metal, manufacturing"},
{c:332510,d: "Door opening and closing devices (except electrical), metal, manufacturing"},
{c:332510,d: "Furniture hardware, metal, manufacturing"},
{c:332510,d: "Gun trigger locks, metal, manufacturing"},
{c:332510,d: "Hinges, metal, manufacturing"},
{c:332510,d: "Key blanks, metal, manufacturing"},
{c:332510,d: "Locks (except coin-operated, time locks), metal, manufacturing"},
{c:332510,d: "Luggage hardware, metal, manufacturing"},
{c:332510,d: "Marine hardware, metal, manufacturing"},
{c:332510,d: "Motor vehicle hardware, metal, manufacturing"},
{c:332510,d: "Padlocks, metal, manufacturing"},
{c:332510,d: "Piano hardware, metal, manufacturing"},
{c:332510,d: "Suitcase hardware, metal, manufacturing"},
{c:332613,d: "Automobile suspension springs, heavy gauge metal, manufacturing"},
{c:332613,d: "Coiled springs (except clock, watch), light gauge, made from purchased wire or strip, manufacturing"},
{c:332613,d: "Coiled springs, heavy gauge metal, manufacturing"},
{c:332613,d: "Disk and ring springs, heavy gauge metal, manufacturing"},
{c:332613,d: "Flat springs (except clock, watch), light gauge, made from purchased wire or strip, manufacturing"},
{c:332613,d: "Flat springs, heavy gauge metal, manufacturing"},
{c:332613,d: "Furniture springs, light gauge, unassembled, made from purchased wire or strip"},
{c:332613,d: "Gun springs, light gauge, made from purchased wire or strip, manufacturing"},
{c:332613,d: "Hairsprings (except clock, watch), light gauge, made from purchased wire or strip, manufacturing"},
{c:332613,d: "Helical springs, hot wound heavy gauge metal, manufacturing"},
{c:332613,d: "Helical springs, light gauge, made from purchased wire or strip, manufacturing"},
{c:332613,d: "Instrument springs, precision (except clock, watch), light gauge, made from purchased wire or strip, manufacturing"},
{c:332613,d: "Leaf springs, heavy gauge metal, manufacturing"},
{c:332613,d: "Mattress springs and spring units, light gauge, made from purchased wire or strip"},
{c:332613,d: "Sash balance springs, light gauge, made from purchased wire or strip"},
{c:332613,d: "Springs and spring units for seats, light gauge, made from purchased wire or strip"},
{c:332613,d: "Springs, heavy gauge metal, manufacturing"},
{c:332613,d: "Springs, light gauge (except clock, watch), made from purchased wire or strip"},
{c:332613,d: "Springs, precision (except clock, watch), light gauge, made from purchased wire or strip"},
{c:332613,d: "Torsion bar, heavy gauge metal, manufacturing"},
{c:332613,d: "Upholstery springs and spring units, light gauge, made from purchased wire or strip"},
{c:332618,d: "Automobile skid chains made from purchased wire"},
{c:332618,d: "Bale ties made from purchased wire"},
{c:332618,d: "Barbed wire made from purchased wire"},
{c:332618,d: "Baskets, metal, made from purchased wire"},
{c:332618,d: "Belts, conveyor, made from purchased wire"},
{c:332618,d: "Belts, drying, made from purchased wire"},
{c:332618,d: "Brackets made from purchased wire"},
{c:332618,d: "Brads, metal, made from purchased wire"},
{c:332618,d: "Cable, noninsulated wire, made from purchased wire"},
{c:332618,d: "Cages made from purchased wire"},
{c:332618,d: "Can keys made from purchased wire"},
{c:332618,d: "Chain link fencing and fence gates made from purchased wire"},
{c:332618,d: "Chain made from purchased wire"},
{c:332618,d: "Chain, welded, made from purchased wire"},
{c:332618,d: "Chicken netting made from purchased wire"},
{c:332618,d: "Cloth, woven wire, made from purchased wire"},
{c:332618,d: "Coat hangers made from purchased wire"},
{c:332618,d: "Concrete reinforcing mesh made from purchased wire"},
{c:332618,d: "Crab traps made from purchased wire"},
{c:332618,d: "Cylinder wire cloth made from purchased wire"},
{c:332618,d: "Delivery cases made from purchased wire"},
{c:332618,d: "Diamond cloths made from purchased wire"},
{c:332618,d: "Fabrics, woven wire, made from purchased wire"},
{c:332618,d: "Fencing and fence gates made from purchased wire"},
{c:332618,d: "Fourdrinier wire cloth made from purchased wire"},
{c:332618,d: "Grilles and grillwork made from purchased wire"},
{c:332618,d: "Guards, wire, made from purchased wire"},
{c:332618,d: "Hardware cloth, woven wire, made from purchased wire"},
{c:332618,d: "Insect screening made from purchased wire"},
{c:332618,d: "Key rings made from purchased wire"},
{c:332618,d: "Lamp frames, wire, made from purchased wire"},
{c:332618,d: "Mats and matting made from purchased wire"},
{c:332618,d: "Mesh made from purchased wire"},
{c:332618,d: "Nails, brads, and staples made from purchased wire"},
{c:332618,d: "Netting, woven, made from purchased wire"},
{c:332618,d: "Paper clips made from purchased wire"},
{c:332618,d: "Paper machine wire cloth made from purchased wire"},
{c:332618,d: "Poultry netting made from purchased wire"},
{c:332618,d: "Racks, household-type, made from purchased wire"},
{c:332618,d: "Reinforcing mesh, concrete, made from purchased wire"},
{c:332618,d: "Rope, wire, made from purchased wire"},
{c:332618,d: "Shelving, wire, made from purchased wire"},
{c:332618,d: "Sieves, made from purchased wire, manufacturing"},
{c:332618,d: "Slings, lifting, made from purchased wire"},
{c:332618,d: "Spikes made from purchased wire"},
{c:332618,d: "Spiral cloth made from purchased wire"},
{c:332618,d: "Staples made from purchased wire"},
{c:332618,d: "Stranded wire, uninsulated, made from purchased wire"},
{c:332618,d: "Tacks, metal, made from purchased wire"},
{c:332618,d: "Tire chains made from purchased wire"},
{c:332618,d: "Traps, animal and fish, made from purchased wire"},
{c:332618,d: "Trays, wire, made from purchased wire"},
{c:332618,d: "Window screening, woven, made from purchased wire"},
{c:332710,d: "Automotive machine shops"},
{c:332710,d: "Chemical milling job shops"},
{c:332710,d: "Machine shops"},
{c:332721,d: "Precision turned product manufacturing"},
{c:332722,d: "Bolts, metal, manufacturing"},
{c:332722,d: "Cotter pins, metal, manufacturing"},
{c:332722,d: "Dowel pins, metal, manufacturing"},
{c:332722,d: "Hook and eye latches, metal, manufacturing"},
{c:332722,d: "Hooks (i.e., general purpose fasteners), metal, manufacturing"},
{c:332722,d: "Hooks, metal screw, manufacturing"},
{c:332722,d: "Hose clamps, metal, manufacturing"},
{c:332722,d: "Lock washers, metal, manufacturing"},
{c:332722,d: "Machine keys, metal, manufacturing"},
{c:332722,d: "Nuts, metal, manufacturing"},
{c:332722,d: "Rivets, metal, manufacturing"},
{c:332722,d: "Screw eyes, metal, manufacturing"},
{c:332722,d: "Screws, metal, manufacturing"},
{c:332722,d: "Spring pins, metal, manufacturing"},
{c:332722,d: "Spring washers, metal, manufacturing"},
{c:332722,d: "Toggle bolts, metal, manufacturing"},
{c:332722,d: "Turnbuckles, metal, manufacturing"},
{c:332722,d: "Washers, metal, manufacturing"},
{c:332811,d: "Annealing metals and metal products for the trade"},
{c:332811,d: "Brazing (i.e., hardening) metals and metal products for the trade"},
{c:332811,d: "Burning metals and metal products for the trade"},
{c:332811,d: "Cold treating metals for the trade"},
{c:332811,d: "Cryogenic treating metals for the trade"},
{c:332811,d: "Hardening (i.e., heat treating) metals and metal products for the trade"},
{c:332811,d: "Heat treating metals and metal products for the trade"},
{c:332811,d: "Shot peening metal and metal products for the trade"},
{c:332811,d: "Tempering metals and metal products for the trade"},
{c:332812,d: "Aluminum coating of metal products for the trade"},
{c:332812,d: "Bonderizing metal and metal products for the trade"},
{c:332812,d: "Chasing metals and metal products (except printing plates) for the trade"},
{c:332812,d: "Coating metals and metal products for the trade"},
{c:332812,d: "Coating of metal and metal products with plastics for the trade"},
{c:332812,d: "Enameling metals and metal products for the trade"},
{c:332812,d: "Engraving metals and metal products (except printing plates) for the trade"},
{c:332812,d: "Etching metals and metal products (except printing plates) for the trade"},
{c:332812,d: "Flocking metals and metal products for the trade"},
{c:332812,d: "Galvanizing metals and metal products for the trade"},
{c:332812,d: "Glazing metals and metal products for the trade"},
{c:332812,d: "Hot dip galvanizing metals and metal products for the trade"},
{c:332812,d: "Japanning metals and metal products for the trade"},
{c:332812,d: "Lacquering metals and metal products for the trade"},
{c:332812,d: "Painting metals and metal products for the trade"},
{c:332812,d: "Parkerizing metals and metal products for the trade"},
{c:332812,d: "Powder coating metals and metal products for the trade"},
{c:332812,d: "Rustproofing metals and metal products for the trade"},
{c:332812,d: "Sherardizing of metals and metal products for the trade"},
{c:332812,d: "Varnishing metals and metal products for the trade"},
{c:332813,d: "Anodizing metals and metal products for the trade"},
{c:332813,d: "Buffing metals and metal products for the trade"},
{c:332813,d: "Chrome plating metals and metal products for the trade"},
{c:332813,d: "Cleaning and descaling metals and metal products for the trade"},
{c:332813,d: "Coloring metals and metal products (except coating) for the trade"},
{c:332813,d: "Depolishing metals and metal products for the trade"},
{c:332813,d: "Electroplating metals and formed products for the trade"},
{c:332813,d: "Gold and silver plating metals and metal products for the trade"},
{c:332813,d: "Laminating metals and metal formed products without fabricating"},
{c:332813,d: "Pickling metals and metal products for the trade"},
{c:332813,d: "Plastic, glass, or other media blasting services"},
{c:332813,d: "Plating metals and metal products for the trade"},
{c:332813,d: "Polishing metals and metal products for the trade"},
{c:332813,d: "Sandblasting metals and metal products for the trade"},
{c:332813,d: "Tumbling (i.e., cleaning and polishing) metal and metal products for the trade"},
{c:332911,d: "Angle valves, industrial-type, manufacturing"},
{c:332911,d: "Automatic (i.e., controlling-type, regulating) valves, industrial-type, manufacturing"},
{c:332911,d: "Ball valves, industrial-type, manufacturing"},
{c:332911,d: "Boiler gauge cocks, industrial-type, manufacturing"},
{c:332911,d: "Butterfly valves, industrial-type, manufacturing"},
{c:332911,d: "Check valves, industrial-type, manufacturing"},
{c:332911,d: "Compressed gas cylinder valves manufacturing"},
{c:332911,d: "Control valves, industrial-type, manufacturing"},
{c:332911,d: "Cross valves, industrial-type, manufacturing"},
{c:332911,d: "Fire hydrant valves manufacturing"},
{c:332911,d: "Fire hydrants, complete, manufacturing"},
{c:332911,d: "Flushing hydrant manufacturing"},
{c:332911,d: "Gas valves, industrial-type, manufacturing"},
{c:332911,d: "Gate valves, industrial-type, manufacturing"},
{c:332911,d: "Globe valves, industrial-type, manufacturing"},
{c:332911,d: "Nuclear application valves manufacturing"},
{c:332911,d: "Plug valves, industrial-type, manufacturing"},
{c:332911,d: "Pressure control valves (except fluid power), industrial-type, manufacturing"},
{c:332911,d: "Safety (i.e., pop-off) valves, industrial-type, manufacturing"},
{c:332911,d: "Solenoid valves (except fluid power), industrial-type, manufacturing"},
{c:332911,d: "Steam traps, industrial-type, manufacturing"},
{c:332911,d: "Stop valves, industrial-type, manufacturing"},
{c:332911,d: "Straightway (i.e., Y-type) valves, industrial-type, manufacturing"},
{c:332911,d: "Thermostatic traps, industrial-type, manufacturing"},
{c:332911,d: "Valves for nuclear applications manufacturing"},
{c:332911,d: "Valves for water works and municipal water systems manufacturing"},
{c:332911,d: "Valves, industrial-type (e.g., check, gate, globe, relief, safety), manufacturing"},
{c:332911,d: "Water sampling station manufacturing"},
{c:332911,d: "Waterworks and municipal water system valves manufacturing"},
{c:332912,d: "Control valves, fluid power, manufacturing"},
{c:332912,d: "Electrohydraulic servo valves, fluid power, manufacturing"},
{c:332912,d: "Fluid power aircraft subassemblies manufacturing"},
{c:332912,d: "Fluid power hose assemblies manufacturing"},
{c:332912,d: "Fluid power valves and hose fittings manufacturing"},
{c:332912,d: "Hose assemblies for fluid power systems manufacturing"},
{c:332912,d: "Hose couplings and fittings, fluid power, manufacturing"},
{c:332912,d: "Hydraulic aircraft subassemblies manufacturing"},
{c:332912,d: "Hydraulic hose fittings, fluid power, manufacturing"},
{c:332912,d: "Hydraulic valves, fluid power, manufacturing"},
{c:332912,d: "Pneumatic aircraft subassemblies manufacturing"},
{c:332912,d: "Pneumatic hose fittings, fluid power, manufacturing"},
{c:332912,d: "Pneumatic valves, fluid power, manufacturing"},
{c:332912,d: "Pressure control valves, fluid power, manufacturing"},
{c:332912,d: "Solenoid valves, fluid power, manufacturing"},
{c:332912,d: "Tube and hose fittings, fluid power, manufacturing"},
{c:332912,d: "Valves, hydraulic and pneumatic, fluid power, manufacturing"},
{c:332913,d: "Antiscald bath and shower valves, plumbing, manufacturing"},
{c:332913,d: "Backflow preventers, plumbing, manufacturing"},
{c:332913,d: "Cocks, drain, plumbing, manufacturing"},
{c:332913,d: "Drain cocks, plumbing, manufacturing"},
{c:332913,d: "Faucets, plumbing, manufacturing"},
{c:332913,d: "Flush valves, plumbing, manufacturing"},
{c:332913,d: "Plumbing fittings and couplings (e.g., compression fittings, metal elbows, metal unions) manufacturing"},
{c:332913,d: "Plumbing fixture fittings and trim, all materials, manufacturing"},
{c:332913,d: "Shower heads, plumbing, manufacturing"},
{c:332913,d: "Spigots, plumbing fixture fitting, manufacturing"},
{c:332913,d: "Stopcock drains, plumbing, manufacturing"},
{c:332913,d: "Supply line assemblies, plumbing (i.e., flexible hose with fittings), manufacturing"},
{c:332919,d: "Aerosol valves manufacturing"},
{c:332919,d: "Boiler couplings and drains, plumbing and heating-type, manufacturing"},
{c:332919,d: "Breakers, vacuum, plumbing, manufacturing"},
{c:332919,d: "Couplings, hose, metal (except fluid power), manufacturing"},
{c:332919,d: "Elbows, pipe, metal (except made from purchased pipe), manufacturing"},
{c:332919,d: "Flanges and flange unions, pipe, metal, manufacturing"},
{c:332919,d: "Hose couplings, metal (except fluid power), manufacturing"},
{c:332919,d: "Lawn hose nozzles and lawn sprinklers manufacturing"},
{c:332919,d: "Nozzles, firefighting, manufacturing"},
{c:332919,d: "Nozzles, lawn hose, manufacturing"},
{c:332919,d: "Plumbing and heating inline valves (e.g., check, cutoffs, stop) manufacturing"},
{c:332919,d: "Sprinklers, lawn, manufacturing"},
{c:332919,d: "Steam fittings, metal, manufacturing"},
{c:332919,d: "Traps, water, manufacturing"},
{c:332919,d: "Unions, pipe, metal (except made from purchased pipe), manufacturing"},
{c:332919,d: "Valves, inline plumbing and heating (e.g., cutoffs, stop), manufacturing"},
{c:332919,d: "Water traps manufacturing"},
{c:332991,d: "Annular ball bearings manufacturing"},
{c:332991,d: "Ball bearings manufacturing"},
{c:332991,d: "Bearings, ball and roller, manufacturing"},
{c:332991,d: "Cylindrical roller bearings manufacturing"},
{c:332991,d: "Flange units, ball or roller bearing, manufacturing"},
{c:332991,d: "Linear ball bearings manufacturing"},
{c:332991,d: "Linear roller bearings manufacturing"},
{c:332991,d: "Needle roller bearings manufacturing"},
{c:332991,d: "Pillow blocks with ball or roller bearings manufacturing"},
{c:332991,d: "Races, ball or roller bearings, manufacturing"},
{c:332991,d: "Roller bearings manufacturing"},
{c:332991,d: "Tapered roller bearings manufacturing"},
{c:332991,d: "Thrust roller bearings manufacturing"},
{c:332992,d: "Ammunition, small arms (i.e., 30 mm. or less, 1.18 inch or less), manufacturing"},
{c:332992,d: "BB shot manufacturing"},
{c:332992,d: "Blank cartridges (i.e., 30 mm. or less, 1.18 inch or less) manufacturing"},
{c:332992,d: "Bullet jackets and cores (i.e., 30 mm. or less, 1.18 inch or less) manufacturing"},
{c:332992,d: "Cartridge cases for ammunition (i.e., 30 mm. or less, 1.18 inch or less) manufacturing"},
{c:332992,d: "Cartridges (i.e., 30 mm. or less, 1.18 inch or less) manufacturing"},
{c:332992,d: "Cores, bullet (i.e., 30 mm. or less, 1.18 inch or less), manufacturing"},
{c:332992,d: "Jackets, bullet (i.e., 30 mm. or less, 1.18 inch or less), manufacturing"},
{c:332992,d: "Paper shells (i.e., 30 mm. or less, 1.18 inch or less) manufacturing"},
{c:332992,d: "Pellets, air rifle and pistol, manufacturing"},
{c:332992,d: "Percussion caps (i.e., 30 mm. or less, 1.18 inch or less), ammunition, manufacturing"},
{c:332992,d: "Shells, small arms (i.e., 30 mm. or less, 1.18 inch or less), manufacturing"},
{c:332992,d: "Shot, BB, manufacturing"},
{c:332992,d: "Shot, lead, manufacturing"},
{c:332992,d: "Shot, pellet, manufacturing"},
{c:332992,d: "Shot, steel, manufacturing"},
{c:332992,d: "Shotgun shells manufacturing"},
{c:332992,d: "Small arms ammunition (i.e., 30 mm. or less, 1.18 inch or less) manufacturing"},
{c:332992,d: "Wads, ammunition, manufacturing"},
{c:332993,d: "Adapters, bombcluster, manufacturing"},
{c:332993,d: "Ammunition (i.e., more than 30 mm., more than 1.18 inch) manufacturing"},
{c:332993,d: "Ammunition loading and assembling plants"},
{c:332993,d: "Arming and fusing devices, missile, manufacturing"},
{c:332993,d: "Artillery ammunition (i.e., more than 30 mm., more than 1.18 inch) manufacturing"},
{c:332993,d: "Bag loading plants, ammunition, manufacturing"},
{c:332993,d: "Bomb loading and assembling plants"},
{c:332993,d: "Bombcluster adapters manufacturing"},
{c:332993,d: "Bombs manufacturing"},
{c:332993,d: "Boosters and bursters, artillery, manufacturing"},
{c:332993,d: "Canisters, ammunition, manufacturing"},
{c:332993,d: "Caps, bomb, manufacturing"},
{c:332993,d: "Depth charges manufacturing"},
{c:332993,d: "Detonators, ammunition (i.e., more than 30 mm., more than 1.18 inch), manufacturing"},
{c:332993,d: "Fin assemblies, mortar, manufacturing"},
{c:332993,d: "Fin assemblies, torpedo and bomb, manufacturing"},
{c:332993,d: "Fuses ammunition (i.e., more than 30 mm., more than 1.18 inch) manufacturing"},
{c:332993,d: "Grenades, hand or projectile, manufacturing"},
{c:332993,d: "Igniters, ammunition tracer (i.e., more than 30 mm., more than 1.18 inch), manufacturing"},
{c:332993,d: "Jet propulsion projectiles (except guided missiles) manufacturing"},
{c:332993,d: "Loading and assembling bombs"},
{c:332993,d: "Mines, ammunition, manufacturing"},
{c:332993,d: "Missile warheads manufacturing"},
{c:332993,d: "Mortar shells manufacturing"},
{c:332993,d: "Primers (i.e., more than 30 mm., more than 1.18 inch), ammunition, manufacturing"},
{c:332993,d: "Projectiles (except guided missile), jet propulsion, manufacturing"},
{c:332993,d: "Rockets, ammunition (except guided missiles, pyrotechnic), manufacturing"},
{c:332993,d: "Shell loading and assembly plants"},
{c:332993,d: "Shells, artillery, manufacturing"},
{c:332993,d: "Torpedoes manufacturing"},
{c:332993,d: "Tracer igniters, ammunition (i.e., more than 30 mm., more than 1.18 inch), manufacturing"},
{c:332994,d: "Aircraft artillery manufacturing"},
{c:332994,d: "Ammunition carts manufacturing"},
{c:332994,d: "Antiaircraft artillery manufacturing"},
{c:332994,d: "Antisubmarine projectors manufacturing"},
{c:332994,d: "Antitank rocket launchers manufacturing"},
{c:332994,d: "Barrels, gun, manufacturing"},
{c:332994,d: "BB guns manufacturing"},
{c:332994,d: "Belts, machine gun, manufacturing"},
{c:332994,d: "Bofors guns manufacturing"},
{c:332994,d: "Cannons manufacturing"},
{c:332994,d: "Carbines manufacturing"},
{c:332994,d: "Catapult guns manufacturing"},
{c:332994,d: "Clips, gun, manufacturing"},
{c:332994,d: "Cylinders and clips, gun, manufacturing"},
{c:332994,d: "Dart guns manufacturing"},
{c:332994,d: "Depth charge projectors manufacturing"},
{c:332994,d: "Field artillery manufacturing"},
{c:332994,d: "Firearms, small, manufacturing"},
{c:332994,d: "Flame throwers manufacturing"},
{c:332994,d: "Generators, smoke, manufacturing"},
{c:332994,d: "Grenade launchers manufacturing"},
{c:332994,d: "Gun barrels manufacturing"},
{c:332994,d: "Gun cleaning kits manufacturing"},
{c:332994,d: "Gun magazines manufacturing"},
{c:332994,d: "Gun turrets manufacturing"},
{c:332994,d: "Guns manufacturing"},
{c:332994,d: "Guns, BB and pellet, manufacturing"},
{c:332994,d: "Howitzers manufacturing"},
{c:332994,d: "Links, ammunition, manufacturing"},
{c:332994,d: "Livens projectors (i.e., ordnance) manufacturing"},
{c:332994,d: "Machine gun belts manufacturing"},
{c:332994,d: "Machine guns manufacturing"},
{c:332994,d: "Mortars manufacturing"},
{c:332994,d: "Naval artillery manufacturing"},
{c:332994,d: "Oerlikon guns manufacturing"},
{c:332994,d: "Pellet guns manufacturing"},
{c:332994,d: "Pistols manufacturing"},
{c:332994,d: "Projectors (e.g., antisub, depth charge release, grenade, livens, rocket), ordnance, manufacturing"},
{c:332994,d: "Pyrotechnic pistols and projectors manufacturing"},
{c:332994,d: "Recoil mechanisms, gun, manufacturing"},
{c:332994,d: "Recoilless rifles manufacturing"},
{c:332994,d: "Revolvers manufacturing"},
{c:332994,d: "Rifles (except toy) manufacturing"},
{c:332994,d: "Rifles, BB and pellet, manufacturing"},
{c:332994,d: "Rifles, pneumatic, manufacturing"},
{c:332994,d: "Rifles, recoilless, manufacturing"},
{c:332994,d: "Rocket launchers manufacturing"},
{c:332994,d: "Shotguns manufacturing"},
{c:332994,d: "Small arms manufacturing"},
{c:332994,d: "Smoke generators manufacturing"},
{c:332994,d: "Submachine guns manufacturing"},
{c:332994,d: "Tampion guns manufacturing"},
{c:332994,d: "Tank artillery manufacturing"},
{c:332994,d: "Torpedo tubes manufacturing"},
{c:332994,d: "Tranquilizer guns, manufacturing"},
{c:332994,d: "Turrets, gun, manufacturing"},
{c:332996,d: "Bends, pipe, made from purchased metal pipe"},
{c:332996,d: "Coils, pipe, made from purchased metal pipe"},
{c:332996,d: "Couplings, pipe, made from purchased metal pipe"},
{c:332996,d: "Fabricated pipe and pipe fittings made from purchased pipe"},
{c:332996,d: "Manifolds, pipe, made from purchased metal pipe"},
{c:332996,d: "Nipples, metal, made from purchased pipe"},
{c:332996,d: "Pipe and pipe fittings made from purchased metal pipe"},
{c:332996,d: "Pipe couplings made from purchased metal pipe"},
{c:332996,d: "Pipe fabricating (i.e., bending, cutting, threading) made from purchased metal pipe"},
{c:332996,d: "Pipe headers made from purchased metal pipe"},
{c:332996,d: "Tubes made from purchased metal pipe"},
{c:332996,d: "Wrought iron or steel pipe and tubing made from purchased metal pipe"},
{c:332999,d: "Aluminum freezer foil not made in rolling mills"},
{c:332999,d: "Aluminum ladders manufacturing"},
{c:332999,d: "Andirons manufacturing"},
{c:332999,d: "Angle irons, metal, manufacturing"},
{c:332999,d: "Animal traps, metal (except wire), manufacturing"},
{c:332999,d: "Aquarium accessories, metal, manufacturing"},
{c:332999,d: "Badges, metal, manufacturing"},
{c:332999,d: "Bank chests, metal, manufacturing"},
{c:332999,d: "Barricades, metal, manufacturing"},
{c:332999,d: "Bathroom fixtures, metal, manufacturing"},
{c:332999,d: "Bathtubs, metal, manufacturing"},
{c:332999,d: "Bellows, hand, manufacturing"},
{c:332999,d: "Book ends, metal, manufacturing"},
{c:332999,d: "Car seals, metal, manufacturing"},
{c:332999,d: "Chain fittings manufacturing"},
{c:332999,d: "Chests, fire or burglary resistive, metal, manufacturing"},
{c:332999,d: "Chests, money, metal, manufacturing"},
{c:332999,d: "Chests, safe deposit, metal, manufacturing"},
{c:332999,d: "Combs, metal, manufacturing"},
{c:332999,d: "Containers, foil (except bags), manufacturing"},
{c:332999,d: "Copper foil not made in rolling mills"},
{c:332999,d: "Cores, sand foundry, manufacturing"},
{c:332999,d: "Cuffs, leg, iron, manufacturing"},
{c:332999,d: "Doors, safe and vault, metal, manufacturing"},
{c:332999,d: "Drain plugs, magnetic, metal, manufacturing"},
{c:332999,d: "Drill stands, metal, manufacturing"},
{c:332999,d: "Drinking fountains (except mechanically refrigerated), metal, manufacturing"},
{c:332999,d: "Ecclesiastical ware, precious plated metal, manufacturing"},
{c:332999,d: "Fireplace fixtures and equipment manufacturing"},
{c:332999,d: "Flexible metal hose and tubing manufacturing"},
{c:332999,d: "Flush tanks, metal, manufacturing"},
{c:332999,d: "Foil containers (except bags) manufacturing"},
{c:332999,d: "Foil not made in rolling mills"},
{c:332999,d: "Foundry cores manufacturing"},
{c:332999,d: "Foundry pattern making"},
{c:332999,d: "Fountains (except drinking), metal, manufacturing"},
{c:332999,d: "Fountains, drinking (except mechanically refrigerated), metal, manufacturing"},
{c:332999,d: "Frames and handles, handbag and luggage, metal, manufacturing"},
{c:332999,d: "Frames, metal, lamp shade, manufacturing"},
{c:332999,d: "Frames, metal, umbrella and parasol, manufacturing"},
{c:332999,d: "Gold beating (i.e., foil, leaf)"},
{c:332999,d: "Gold foil and leaf not made in rolling mills"},
{c:332999,d: "Hair curlers, metal, manufacturing"},
{c:332999,d: "Handcuffs manufacturing"},
{c:332999,d: "Handles (e.g., parasol, umbrella), metal, manufacturing"},
{c:332999,d: "Harness parts, metal, manufacturing"},
{c:332999,d: "Hollowware, precious plated metal, manufacturing"},
{c:332999,d: "Hoops, metal (except wire), fabricated from purchased metal"},
{c:332999,d: "Horse bits manufacturing"},
{c:332999,d: "Hose, flexible metal, manufacturing"},
{c:332999,d: "Identification plates, metal, manufacturing"},
{c:332999,d: "Industrial pattern manufacturing"},
{c:332999,d: "Ironing boards, metal, manufacturing"},
{c:332999,d: "Ladder jacks, metal, manufacturing"},
{c:332999,d: "Ladders, portable metal, manufacturing"},
{c:332999,d: "Lamp shade frames, metal, manufacturing"},
{c:332999,d: "Laundry tubs, metal, manufacturing"},
{c:332999,d: "Lavatories, metal, manufacturing"},
{c:332999,d: "Lead foil not made in rolling mills"},
{c:332999,d: "Leaf, metal, manufacturing"},
{c:332999,d: "Linings, metal safe and vault, manufacturing"},
{c:332999,d: "Machine bases, metal, manufacturing"},
{c:332999,d: "Magnesium foil not made in rolling mills"},
{c:332999,d: "Magnets, permanent, metallic, manufacturing"},
{c:332999,d: "Marine horns, compressed air or steam, metal, manufacturing"},
{c:332999,d: "Metal foil containers (except bags) manufacturing"},
{c:332999,d: "Military insignia, metal, manufacturing"},
{c:332999,d: "Money chests, metal, manufacturing"},
{c:332999,d: "Name plate blanks, metal, manufacturing"},
{c:332999,d: "Nickel foil not made in rolling mills"},
{c:332999,d: "Novelties and specialties, nonprecious metal and precious plated, manufacturing"},
{c:332999,d: "Pads, soap impregnated scouring, manufacturing"},
{c:332999,d: "Paint sticks, metal, manufacturing"},
{c:332999,d: "Pallet parts, metal, manufacturing"},
{c:332999,d: "Pallets, metal, manufacturing"},
{c:332999,d: "Patterns (except shoe), industrial, manufacturing"},
{c:332999,d: "Pipe hangers and supports, metal, manufacturing"},
{c:332999,d: "Plated ware (e.g., ecclesiastical ware, hollowware, toilet ware) manufacturing"},
{c:332999,d: "Platinum foil and leaf not made in rolling mills"},
{c:332999,d: "Plugs, magnetic metal drain, manufacturing"},
{c:332999,d: "Plumbing fixtures, metal, manufacturing"},
{c:332999,d: "Portable chemical toilets, metal, manufacturing"},
{c:332999,d: "Propellers, ship and boat, made from purchased metal"},
{c:332999,d: "Reels, metal, manufacturing"},
{c:332999,d: "Rope fittings manufacturing"},
{c:332999,d: "Saddlery parts, metal, manufacturing"},
{c:332999,d: "Safe deposit boxes and chests, metal, manufacturing"},
{c:332999,d: "Safe doors and linings, metal, manufacturing"},
{c:332999,d: "Safes, metal, manufacturing"},
{c:332999,d: "Sanitary ware (e.g., bathtubs, lavatories, sinks), metal, manufacturing"},
{c:332999,d: "Scouring pads, soap impregnated, manufacturing"},
{c:332999,d: "Shims, metal, manufacturing"},
{c:332999,d: "Shower receptors, metal, manufacturing"},
{c:332999,d: "Shower rods, metal, manufacturing"},
{c:332999,d: "Shower stalls, metal, manufacturing"},
{c:332999,d: "Silver beating (i.e., foil, leaf)"},
{c:332999,d: "Silver foil and leaf not made in rolling mills"},
{c:332999,d: "Sinks, metal, manufacturing"},
{c:332999,d: "Skids, metal, manufacturing"},
{c:332999,d: "Soap dispensers, metal, manufacturing"},
{c:332999,d: "Sponges, metal scouring, manufacturing"},
{c:332999,d: "Stabilizing bars, cargo, metal, manufacturing"},
{c:332999,d: "Steel wool manufacturing"},
{c:332999,d: "Stepladders, metal, manufacturing"},
{c:332999,d: "Strappings, metal, manufacturing"},
{c:332999,d: "Swatters, fly, metal, manufacturing"},
{c:332999,d: "Tablets, metal, manufacturing"},
{c:332999,d: "Thimbles for wire rope manufacturing"},
{c:332999,d: "Tinfoil not made in rolling mills"},
{c:332999,d: "Toilet fixtures, metal, manufacturing"},
{c:332999,d: "Toilet ware, precious plated metal, manufacturing"},
{c:332999,d: "Trophies, nonprecious and precious plated metal, manufacturing"},
{c:332999,d: "Tubing, flexible metal, manufacturing"},
{c:332999,d: "Tubs, laundry and bath, metal, manufacturing"},
{c:332999,d: "Urinals, metal, manufacturing"},
{c:332999,d: "Vault doors and linings, metal, manufacturing"},
{c:332999,d: "Vaults (except burial), metal, manufacturing"},
{c:332999,d: "Water closets, metal, manufacturing"},
{c:332999,d: "Zinc foil and leaf not made in rolling mills"},
{c:333111,d: "Bale throwers manufacturing"},
{c:333111,d: "Balers, farm-type (e.g., cotton, hay, straw), manufacturing"},
{c:333111,d: "Berry harvesting machines manufacturing"},
{c:333111,d: "Blowers, forage, manufacturing"},
{c:333111,d: "Cabs for agricultural machinery manufacturing"},
{c:333111,d: "Cattle feeding and watering equipment manufacturing"},
{c:333111,d: "Chicken brooders manufacturing"},
{c:333111,d: "Chicken feeders manufacturing"},
{c:333111,d: "Combines (i.e., harvester-threshers) manufacturing"},
{c:333111,d: "Corn heads for combines manufacturing"},
{c:333111,d: "Corn pickers and shellers manufacturing"},
{c:333111,d: "Cotton balers and presses manufacturing"},
{c:333111,d: "Cotton ginning machinery manufacturing"},
{c:333111,d: "Cotton picker and stripper harvesting machinery manufacturing"},
{c:333111,d: "Cream separators, farm-type, manufacturing"},
{c:333111,d: "Crop driers manufacturing"},
{c:333111,d: "Cultivators, farm-type, manufacturing"},
{c:333111,d: "Curers, tobacco, manufacturing"},
{c:333111,d: "Drags, farm-type equipment, manufacturing"},
{c:333111,d: "Dusters, farm-type, manufacturing"},
{c:333111,d: "Farm tractors and attachments manufacturing"},
{c:333111,d: "Farm wagons manufacturing"},
{c:333111,d: "Feed processing equipment, farm-type, manufacturing"},
{c:333111,d: "Fertilizing machinery, farm-type, manufacturing"},
{c:333111,d: "Fruit harvesting machines manufacturing"},
{c:333111,d: "Grading, cleaning, and sorting machinery, farm-type, manufacturing"},
{c:333111,d: "Grain drills manufacturing"},
{c:333111,d: "Grain stackers manufacturing"},
{c:333111,d: "Grass mowing equipment (except lawn and garden) manufacturing"},
{c:333111,d: "Hair clippers for animal use, electric, manufacturing"},
{c:333111,d: "Harrows (e.g., disc, spring, tine) manufacturing"},
{c:333111,d: "Harvesting machinery and equipment, agriculture, manufacturing"},
{c:333111,d: "Hay balers and presses manufacturing"},
{c:333111,d: "Haying machines manufacturing"},
{c:333111,d: "Hog feeding and watering equipment manufacturing"},
{c:333111,d: "Hulling machinery, farm-type, manufacturing"},
{c:333111,d: "Incubators, poultry, manufacturing"},
{c:333111,d: "Irrigation equipment, agriculture, manufacturing"},
{c:333111,d: "Lawnmowers, agricultural-type, powered, manufacturing"},
{c:333111,d: "Milking machines manufacturing"},
{c:333111,d: "Nut shellers, farm-type, manufacturing"},
{c:333111,d: "Peanut combines (i.e., diggers, packers, threshers) manufacturing"},
{c:333111,d: "Planting machines, farm-type, manufacturing"},
{c:333111,d: "Plows, farm-type, manufacturing"},
{c:333111,d: "Potato diggers, harvesters, and planters manufacturing"},
{c:333111,d: "Poultry brooders, feeders, and waterers manufacturing"},
{c:333111,d: "Presses, farm-type, manufacturing"},
{c:333111,d: "Rakes, hay, manufacturing"},
{c:333111,d: "Rotary hoes manufacturing"},
{c:333111,d: "Rotary tillers, farm-type, manufacturing"},
{c:333111,d: "Seeders, farm-type, manufacturing"},
{c:333111,d: "Shears, powered, for use on animals, manufacturing"},
{c:333111,d: "Sheep shears, powered, manufacturing"},
{c:333111,d: "Shredders, farm-type, manufacturing"},
{c:333111,d: "Sod harvesting machines manufacturing"},
{c:333111,d: "Sprayers and dusters, farm-type, manufacturing"},
{c:333111,d: "Spreaders, farm-type, manufacturing"},
{c:333111,d: "Stalk choppers (i.e., shredders) manufacturing"},
{c:333111,d: "Tobacco harvester machines manufacturing"},
{c:333111,d: "Tomato harvesting machines manufacturing"},
{c:333111,d: "Tractors and attachments, farm-type, manufacturing"},
{c:333111,d: "Transplanters, farm-type, manufacturing"},
{c:333111,d: "Tree shakers (e.g., citrus, nut, soft fruit) manufacturing"},
{c:333111,d: "Wagons, farm-type, manufacturing"},
{c:333111,d: "Weeding machines, farm-type, manufacturing"},
{c:333111,d: "Windmills, farm-type, manufacturing"},
{c:333112,d: "Attachments, powered lawn and garden equipment, manufacturing"},
{c:333112,d: "Blowers, leaf, manufacturing"},
{c:333112,d: "Carts, lawn and garden-type, manufacturing"},
{c:333112,d: "Chippers (i.e., shredders), lawn and garden-type, manufacturing"},
{c:333112,d: "Cultivators, powered, lawn and garden-type, manufacturing"},
{c:333112,d: "Garden machinery and equipment, powered, manufacturing"},
{c:333112,d: "Grass mowing equipment, powered lawn and garden, manufacturing"},
{c:333112,d: "Hedge trimmers, powered, manufacturing"},
{c:333112,d: "Lawn and garden equipment manufacturing"},
{c:333112,d: "Lawn edgers, powered, manufacturing"},
{c:333112,d: "Lawnmowers (except agricultural-type), powered, manufacturing"},
{c:333112,d: "Leaf blowers manufacturing"},
{c:333112,d: "Mulchers, lawn and garden-type, manufacturing"},
{c:333112,d: "Seeders, lawn and garden-type, manufacturing"},
{c:333112,d: "Snow plow attachments, lawn and garden-type, manufacturing"},
{c:333112,d: "Snowblowers and throwers, residential-type, manufacturing"},
{c:333112,d: "Spreaders, lawn and garden-type, manufacturing"},
{c:333112,d: "Tillers, lawn and garden-type, manufacturing"},
{c:333112,d: "Tractors and attachments, lawn and garden-type, manufacturing"},
{c:333112,d: "Trimmers, hedge, electric, manufacturing"},
{c:333112,d: "Trimmers, string, lawn and garden-type, manufacturing"},
{c:333112,d: "Vacuums, yard, manufacturing"},
{c:333112,d: "Wagons, lawn and garden-type, manufacturing"},
{c:333120,d: "Aggregate spreaders manufacturing"},
{c:333120,d: "Asphalt roofing construction machinery manufacturing"},
{c:333120,d: "Augers (except mining-type) manufacturing"},
{c:333120,d: "Backhoes manufacturing"},
{c:333120,d: "Bits, rock drill, construction and surface mining-type, manufacturing"},
{c:333120,d: "Blades for graders, scrapers, bulldozers, and snowplows manufacturing"},
{c:333120,d: "Bucket and scarifier teeth manufacturing"},
{c:333120,d: "Buckets, excavating (e.g., clamshell, concrete, drag scraper, dragline, shovel), manufacturing"},
{c:333120,d: "Bulldozers manufacturing"},
{c:333120,d: "Cabs for construction machinery manufacturing"},
{c:333120,d: "Chippers, portable, commercial (e.g., brush, limb, log), manufacturing"},
{c:333120,d: "Concrete finishing machinery manufacturing"},
{c:333120,d: "Concrete gunning equipment manufacturing"},
{c:333120,d: "Concrete mixing machinery, portable, manufacturing"},
{c:333120,d: "Construction machinery manufacturing"},
{c:333120,d: "Construction-type tractors and attachments manufacturing"},
{c:333120,d: "Cranes, construction-type, manufacturing"},
{c:333120,d: "Crushing machinery, portable, manufacturing"},
{c:333120,d: "Crushing, pulverizing, and screening machinery, portable, manufacturing"},
{c:333120,d: "Ditchers and trenchers, self-propelled, manufacturing"},
{c:333120,d: "Draglines, crawler, manufacturing"},
{c:333120,d: "Drags, road construction and road maintenance equipment, manufacturing"},
{c:333120,d: "Dredging machinery manufacturing"},
{c:333120,d: "Excavators (e.g., power shovels) manufacturing"},
{c:333120,d: "Extractors, piling, manufacturing"},
{c:333120,d: "Grader attachments manufacturing"},
{c:333120,d: "Graders, road, manufacturing"},
{c:333120,d: "Hammer mill machinery (i.e., rock and ore crushing machines), portable, manufacturing"},
{c:333120,d: "Highway line marking machinery manufacturing"},
{c:333120,d: "Jack hammers manufacturing"},
{c:333120,d: "Land preparation machinery, construction, manufacturing"},
{c:333120,d: "Loaders, shovel, manufacturing"},
{c:333120,d: "Log debarking machinery, portable, manufacturing"},
{c:333120,d: "Log splitters, portable, manufacturing"},
{c:333120,d: "Mixers, concrete, portable, manufacturing"},
{c:333120,d: "Mortar mixers, portable, manufacturing"},
{c:333120,d: "Off-highway trucks manufacturing"},
{c:333120,d: "Paving machinery manufacturing"},
{c:333120,d: "Pile-driving equipment manufacturing"},
{c:333120,d: "Planers, bituminous, manufacturing"},
{c:333120,d: "Plows, construction (e.g., excavating, grading), manufacturing"},
{c:333120,d: "Post hole diggers, powered, manufacturing"},
{c:333120,d: "Rock crushing machinery, portable, manufacturing"},
{c:333120,d: "Rock drills, construction and surface mining-type, manufacturing"},
{c:333120,d: "Rollers, road construction and maintenance machinery, manufacturing"},
{c:333120,d: "Sand mixers manufacturing"},
{c:333120,d: "Scarifiers, road, manufacturing"},
{c:333120,d: "Scrapers, construction-type, manufacturing"},
{c:333120,d: "Screening machinery, portable, manufacturing"},
{c:333120,d: "Shovel loaders manufacturing"},
{c:333120,d: "Shovels, power, manufacturing"},
{c:333120,d: "Slag mixers, portable, manufacturing"},
{c:333120,d: "Snow plow attachments (except lawn, garden-type) manufacturing"},
{c:333120,d: "Surface mining machinery (except drilling) manufacturing"},
{c:333120,d: "Tampers, powered, manufacturing"},
{c:333120,d: "Tractors and attachments, construction-type, manufacturing"},
{c:333120,d: "Tractors, crawler, manufacturing"},
{c:333120,d: "Trenching machines manufacturing"},
{c:333120,d: "Trucks, off-highway, manufacturing"},
{c:333120,d: "Vibrators, concrete, manufacturing"},
{c:333131,d: "Amalgamators (i.e., metallurgical and mining machinery) manufacturing"},
{c:333131,d: "Augers, mining-type, manufacturing"},
{c:333131,d: "Bits, rock drill, underground mining-type, manufacturing"},
{c:333131,d: "Breakers, coal, manufacturing"},
{c:333131,d: "Cars, mining, manufacturing"},
{c:333131,d: "Cleaning machinery, mining-type, manufacturing"},
{c:333131,d: "Coal breakers, cutters, and pulverizers manufacturing"},
{c:333131,d: "Concentration machinery, mining-type, manufacturing"},
{c:333131,d: "Core drills, underground mining-type, manufacturing"},
{c:333131,d: "Crushing machinery, stationary, manufacturing"},
{c:333131,d: "Cutters, coal, manufacturing"},
{c:333131,d: "Drilling equipment, underground mining-type, manufacturing"},
{c:333131,d: "Drills, core, underground mining-type, manufacturing"},
{c:333131,d: "Drills, rock, underground mining-type, manufacturing"},
{c:333131,d: "Dumpers, mining car, manufacturing"},
{c:333131,d: "Feeders, mineral beneficiating-type, manufacturing"},
{c:333131,d: "Flotation machinery, mining-type, manufacturing"},
{c:333131,d: "Hammer mill machinery (i.e., rock and ore crushing machines), stationary, manufacturing"},
{c:333131,d: "Loading machines, underground mining, manufacturing"},
{c:333131,d: "Mineral processing and beneficiating machinery manufacturing"},
{c:333131,d: "Mining cars manufacturing"},
{c:333131,d: "Ore crushing, washing, screening, and loading machinery manufacturing"},
{c:333131,d: "Pellet mills machinery, mining-type, manufacturing"},
{c:333131,d: "Quarrying machinery and equipment manufacturing"},
{c:333131,d: "Rock crushing machinery, stationary, manufacturing"},
{c:333131,d: "Rock drills, underground mining-type, manufacturing"},
{c:333131,d: "Scraper loaders, underground mining-type, manufacturing"},
{c:333131,d: "Screening machinery, stationary, manufacturing"},
{c:333131,d: "Sedimentary mineral machinery manufacturing"},
{c:333131,d: "Sieves and screening equipment, mineral beneficiating, manufacturing"},
{c:333131,d: "Stone beneficiating machinery manufacturing"},
{c:333131,d: "Underground mining machinery manufacturing"},
{c:333131,d: "Washers, aggregate and sand, stationary, manufacturing"},
{c:333132,d: "Bits, rock drill, oil and gas field-type, manufacturing"},
{c:333132,d: "Christmas tree assemblies, oil and gas field-type, manufacturing"},
{c:333132,d: "Derricks, oil and gas field-type, manufacturing"},
{c:333132,d: "Drilling equipment, oil and gas field-type, manufacturing"},
{c:333132,d: "Drilling rigs, oil and gas field-type, manufacturing"},
{c:333132,d: "Gas well machinery and equipment manufacturing"},
{c:333132,d: "Oil and gas field-type drilling machinery and equipment (except offshore floating platforms) manufacturing"},
{c:333132,d: "Rock drill bits, oil and gas field-type, manufacturing"},
{c:333132,d: "Water well drilling machinery manufacturing"},
{c:333132,d: "Well logging equipment manufacturing"},
{c:333241,d: "Bakery machinery and equipment manufacturing"},
{c:333241,d: "Bakery ovens manufacturing"},
{c:333241,d: "Bread slicing machinery manufacturing"},
{c:333241,d: "Brewery machinery manufacturing"},
{c:333241,d: "Butter processing machinery manufacturing"},
{c:333241,d: "Cheese processing machinery manufacturing"},
{c:333241,d: "Chewing gum machinery manufacturing"},
{c:333241,d: "Chocolate processing machinery manufacturing"},
{c:333241,d: "Cider presses manufacturing"},
{c:333241,d: "Coffee roasting and grinding machinery (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Condensed and evaporated milk machinery manufacturing"},
{c:333241,d: "Confectionery machinery manufacturing"},
{c:333241,d: "Corn popping machinery (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Cracker making machinery manufacturing"},
{c:333241,d: "Cream separators, industrial, manufacturing"},
{c:333241,d: "Dairy product plant machinery and equipment"},
{c:333241,d: "Distilling equipment, beverage, manufacturing"},
{c:333241,d: "Dough mixing machinery (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Dry milk processing machinery manufacturing"},
{c:333241,d: "Flour milling machinery manufacturing"},
{c:333241,d: "Food choppers, grinders, mixers, and slicers (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Food dehydrating equipment (except household-type) manufacturing"},
{c:333241,d: "Food product machinery manufacturing"},
{c:333241,d: "Grading, cleaning, and sorting machinery (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Grain milling machinery manufacturing"},
{c:333241,d: "Homogenizing machinery, food, manufacturing"},
{c:333241,d: "Ice cream making machinery manufacturing"},
{c:333241,d: "Juice extractors (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Malt milling machinery manufacturing"},
{c:333241,d: "Meat and poultry processing and preparation machinery"},
{c:333241,d: "Meat grinders, food-type, manufacturing"},
{c:333241,d: "Milk processing (except farm-type) machinery manufacturing"},
{c:333241,d: "Oilseed crushing and extracting machinery manufacturing"},
{c:333241,d: "Ovens, bakery, manufacturing"},
{c:333241,d: "Pasta making machinery (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Pasteurizing equipment, food, manufacturing"},
{c:333241,d: "Peanut roasting machines (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Presses (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Roasting machinery manufacturing"},
{c:333241,d: "Sieves and screening equipment (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Sifting machine (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Slicing machinery (i.e., food manufacturing-type) manufacturing"},
{c:333241,d: "Stuffer, sausage machinery, manufacturing"},
{c:333241,d: "Sugar refining machinery manufacturing"},
{c:333241,d: "Vegetable oil processing machinery manufacturing"},
{c:333242,d: "Etching equipment, semiconductor, manufacturing"},
{c:333242,d: "Micro-lithography equipment, semiconductor, manufacturing"},
{c:333242,d: "Nanoindentation equipment, semiconductor, manufacturing"},
{c:333242,d: "Semiconductor assembly and packaging machinery manufacturing"},
{c:333242,d: "Semiconductor making machinery manufacturing"},
{c:333242,d: "Surface mount machinery for making printed circuit boards manufacturing"},
{c:333242,d: "Thin layer deposition equipment, semiconductor, manufacturing"},
{c:333242,d: "Vapor deposition equipment, semiconductor, manufacturing"},
{c:333242,d: "Wafer processing equipment, semiconductor, manufacturing"},
{c:333243,d: "Bandsaws, woodworking-type, manufacturing"},
{c:333243,d: "Chippers, stationary (e.g., log), manufacturing"},
{c:333243,d: "Circular saws, woodworking-type, stationary, manufacturing"},
{c:333243,d: "Dovetailing machines, woodworking-type, manufacturing"},
{c:333243,d: "Drill presses, woodworking-type, manufacturing"},
{c:333243,d: "Envelope making machinery manufacturing"},
{c:333243,d: "Fourdrinier machinery manufacturing"},
{c:333243,d: "Jigsaws, woodworking-type, stationary, manufacturing"},
{c:333243,d: "Jointers, woodworking-type, manufacturing"},
{c:333243,d: "Lathes, woodworking-type, manufacturing"},
{c:333243,d: "Log debarking machinery, stationary, manufacturing"},
{c:333243,d: "Log splitters, stationary, manufacturing"},
{c:333243,d: "Mortisers, woodworking-type, manufacturing"},
{c:333243,d: "Paper and paperboard coating and finishing machinery manufacturing"},
{c:333243,d: "Paper and paperboard converting machinery manufacturing"},
{c:333243,d: "Paper and paperboard corrugating machinery manufacturing"},
{c:333243,d: "Paper and paperboard cutting and folding machinery manufacturing"},
{c:333243,d: "Paper and paperboard die-cutting and stamping machinery manufacturing"},
{c:333243,d: "Paper bag making machinery manufacturing"},
{c:333243,d: "Paper making machinery manufacturing"},
{c:333243,d: "Paperboard box making machinery manufacturing"},
{c:333243,d: "Paperboard making machinery manufacturing"},
{c:333243,d: "Planers woodworking-type, stationary, manufacturing"},
{c:333243,d: "Presses for making composite woods (e.g., hardboard, medium density fiberboard (MDF), particleboard, plywood) manufacturing"},
{c:333243,d: "Pulp making machinery manufacturing"},
{c:333243,d: "Pulp, paper, and paperboard molding machinery manufacturing"},
{c:333243,d: "Sanding machines, woodworking-type, stationary, manufacturing"},
{c:333243,d: "Sandpaper making machines manufacturing"},
{c:333243,d: "Sawmill equipment manufacturing"},
{c:333243,d: "Saws, bench and table, power-driven, woodworking-type, manufacturing"},
{c:333243,d: "Scarfing machines, woodworking-type, manufacturing"},
{c:333243,d: "Shapers, woodworking-type, manufacturing"},
{c:333243,d: "Veneer and plywood forming machinery manufacturing"},
{c:333243,d: "Wood veneer laminating and gluing machines manufacturing"},
{c:333243,d: "Woodworking machines (except handheld) manufacturing"},
{c:333244,d: "Bindery machinery manufacturing"},
{c:333244,d: "Bookbinding machines manufacturing"},
{c:333244,d: "Collating machinery for printing and bookbinding manufacturing"},
{c:333244,d: "Digital printing presses manufacturing"},
{c:333244,d: "Electrotyping machinery manufacturing"},
{c:333244,d: "Flexographic printing presses manufacturing"},
{c:333244,d: "Gravure printing presses manufacturing"},
{c:333244,d: "Letterpress printing presses manufacturing"},
{c:333244,d: "Linotype machines manufacturing"},
{c:333244,d: "Lithographic printing presses manufacturing"},
{c:333244,d: "Newspaper inserting equipment manufacturing"},
{c:333244,d: "Offset printing presses manufacturing"},
{c:333244,d: "Photoengraving machinery manufacturing"},
{c:333244,d: "Presses, printing (except textile), manufacturing"},
{c:333244,d: "Printing plate engraving machinery manufacturing"},
{c:333244,d: "Printing plates, blank (except photosentive), manufacturing"},
{c:333244,d: "Printing press rollers manufacturing"},
{c:333244,d: "Printing presses (except textile) manufacturing"},
{c:333244,d: "Stitchers and trimmers bookbinding equipment manufacturing"},
{c:333244,d: "Typesetting machinery manufacturing"},
{c:333249,d: "Additive manufacturing machinery manufacturing"},
{c:333249,d: "Anodizing equipment manufacturing"},
{c:333249,d: "Beaming machinery for yarn manufacturing"},
{c:333249,d: "Bleaching machinery for textiles manufacturing"},
{c:333249,d: "Blow molding machinery for plastics manufacturing"},
{c:333249,d: "Bobbins, textile machinery, manufacturing"},
{c:333249,d: "Boot making and repairing machinery manufacturing"},
{c:333249,d: "Braiding machinery for textiles manufacturing"},
{c:333249,d: "Buttonhole and eyelet machinery manufacturing"},
{c:333249,d: "Calendering machinery for plastics manufacturing"},
{c:333249,d: "Calendering machinery for textiles manufacturing"},
{c:333249,d: "Camelback (i.e., retreading materials) machinery manufacturing"},
{c:333249,d: "Carbonizing equipment for processing wool manufacturing"},
{c:333249,d: "Carding machinery for textiles manufacturing"},
{c:333249,d: "Cement kilns manufacturing"},
{c:333249,d: "Chemical kilns manufacturing"},
{c:333249,d: "Chemical processing machinery and equipment manufacturing"},
{c:333249,d: "Chip placement machinery manufacturing"},
{c:333249,d: "Cigarette making machinery manufacturing"},
{c:333249,d: "Circuit board making machinery manufacturing"},
{c:333249,d: "Circular knitting machinery manufacturing"},
{c:333249,d: "Clayworking and tempering machinery manufacturing"},
{c:333249,d: "Cloth spreading machinery manufacturing"},
{c:333249,d: "Combing machinery for textiles manufacturing"},
{c:333249,d: "Compression molding machinery for plastics manufacturing"},
{c:333249,d: "Concrete products forming machinery manufacturing"},
{c:333249,d: "Cordage and rope (except wire) making machines manufacturing"},
{c:333249,d: "Distilling equipment (except beverage), including laboratory-type, manufacturing"},
{c:333249,d: "Drawing machinery for textiles manufacturing"},
{c:333249,d: "Drying kilns, lumber, manufacturing"},
{c:333249,d: "Drying machinery for textiles manufacturing"},
{c:333249,d: "Dyeing machinery for textiles manufacturing"},
{c:333249,d: "Electron tube machinery manufacturing"},
{c:333249,d: "Electroplating machinery and equipment manufacturing"},
{c:333249,d: "Embroidery machinery manufacturing"},
{c:333249,d: "Extruding machinery for plastics and rubber manufacturing"},
{c:333249,d: "Extruding machinery for yarn manufacturing"},
{c:333249,d: "Fermentation equipment, chemical, manufacturing"},
{c:333249,d: "Finishing machinery for textile manufacturing"},
{c:333249,d: "Footwear making or repairing machinery manufacturing"},
{c:333249,d: "Fractionating equipment, chemical, manufacturing"},
{c:333249,d: "Frames for textile making machinery manufacturing"},
{c:333249,d: "Garnetting machinery for textiles manufacturing"},
{c:333249,d: "Gas liquefying machinery manufacturing"},
{c:333249,d: "Gem stone processing machinery manufacturing"},
{c:333249,d: "Glass making machinery (e.g., blowing, forming, molding) manufacturing"},
{c:333249,d: "Granulator and pelletizer machinery for plastics manufacturing"},
{c:333249,d: "Hosiery machines manufacturing"},
{c:333249,d: "Injection molding machinery for plastics manufacturing"},
{c:333249,d: "Jacquard card cutting machinery manufacturing"},
{c:333249,d: "Kilns (i.e., cement, chemical, wood) manufacturing"},
{c:333249,d: "Knitting machinery manufacturing"},
{c:333249,d: "Knot tying machinery for textiles manufacturing"},
{c:333249,d: "Lace and net making machinery manufacturing"},
{c:333249,d: "Leather working machinery manufacturing"},
{c:333249,d: "Light bulb and tube (i.e., electric lamp) machinery manufacturing"},
{c:333249,d: "Loom bobbins manufacturing"},
{c:333249,d: "Loom reeds manufacturing"},
{c:333249,d: "Looms for textiles manufacturing"},
{c:333249,d: "Loopers for textiles manufacturing"},
{c:333249,d: "Mercerizing machinery manufacturing"},
{c:333249,d: "Metal casting machinery and equipment manufacturing"},
{c:333249,d: "Napping machinery for textiles manufacturing"},
{c:333249,d: "Needles for knitting machinery manufacturing"},
{c:333249,d: "Net and lace making machinery manufacturing"},
{c:333249,d: "Optical lens making and grinding machinery manufacturing"},
{c:333249,d: "Petroleum refining machinery manufacturing"},
{c:333249,d: "Picker machinery for textiles manufacturing"},
{c:333249,d: "Picker sticks for looms manufacturing"},
{c:333249,d: "Plastics working machinery manufacturing"},
{c:333249,d: "Printer machinery, 3D, manufacturing"},
{c:333249,d: "Printing machinery for textiles manufacturing"},
{c:333249,d: "Rectifying equipment, chemical, manufacturing"},
{c:333249,d: "Roving machinery for textiles manufacturing"},
{c:333249,d: "Rubber working machinery manufacturing"},
{c:333249,d: "Schiffli machinery manufacturing"},
{c:333249,d: "Sewing machines (including household-type) manufacturing"},
{c:333249,d: "Shoe making and repairing machinery manufacturing"},
{c:333249,d: "Shuttles for textile weaving machinery manufacturing"},
{c:333249,d: "Sieves and screening equipment, chemical preparation-type, manufacturing"},
{c:333249,d: "Silk screens for textile fabrics manufacturing"},
{c:333249,d: "Spindles for textile machinery manufacturing"},
{c:333249,d: "Spinning machinery for textiles manufacturing"},
{c:333249,d: "Spools for textile machinery manufacturing"},
{c:333249,d: "Stone working machinery manufacturing"},
{c:333249,d: "Tannery machinery manufacturing"},
{c:333249,d: "Textile finishing machinery (e.g., bleaching, dyeing, mercerizing, printing) manufacturing"},
{c:333249,d: "Textile making machinery manufacturing"},
{c:333249,d: "Textile printing machinery manufacturing"},
{c:333249,d: "Texturizing machinery for textiles manufacturing"},
{c:333249,d: "Thermoforming machinery for plastics manufacturing"},
{c:333249,d: "Thread making machinery manufacturing"},
{c:333249,d: "Through-hole machinery, printed circuit board loading, manufacturing"},
{c:333249,d: "Tile making machinery (except kilns) manufacturing"},
{c:333249,d: "Tire making machinery manufacturing"},
{c:333249,d: "Tire recapping machinery manufacturing"},
{c:333249,d: "Tire shredding machinery manufacturing"},
{c:333249,d: "Tobacco processing machinery (except farm-type) manufacturing"},
{c:333249,d: "Tufting machinery for textiles manufacturing"},
{c:333249,d: "Vulcanizing machinery manufacturing"},
{c:333249,d: "Warping machinery manufacturing"},
{c:333249,d: "Weaving machinery manufacturing"},
{c:333249,d: "Winding machinery for textiles manufacturing"},
{c:333249,d: "Wire and cable insulating machinery manufacturing"},
{c:333249,d: "Wood drying kilns manufacturing"},
{c:333249,d: "Wool and worsted finishing machinery manufacturing"},
{c:333249,d: "Yarn texturizing machines manufacturing"},
{c:333249,d: "Zipper making machinery manufacturing"},
{c:333314,d: "Binoculars manufacturing"},
{c:333314,d: "Camera lenses manufacturing"},
{c:333314,d: "Comparators, optical, manufacturing"},
{c:333314,d: "Gauges, machinist's precision tool, optical, manufacturing"},
{c:333314,d: "Glasses, field or opera, manufacturing"},
{c:333314,d: "Gratings, diffraction, manufacturing"},
{c:333314,d: "Gun sighting and fire control equipment and instruments, optical, manufacturing"},
{c:333314,d: "Gun sights, optical, manufacturing"},
{c:333314,d: "Instrument lenses manufacturing"},
{c:333314,d: "Interferometers manufacturing"},
{c:333314,d: "Laboratory analytical optical instruments (e.g., microscopes) manufacturing"},
{c:333314,d: "Lens coating (except ophthalmic)"},
{c:333314,d: "Lens grinding (except ophthalmic)"},
{c:333314,d: "Lens mounting (except ophthalmic)"},
{c:333314,d: "Lens polishing (except ophthalmic)"},
{c:333314,d: "Lenses (except ophthalmic) manufacturing"},
{c:333314,d: "Loupes (e.g., jewelers) manufacturing"},
{c:333314,d: "Magnifying glasses (except corrective vision-type) manufacturing"},
{c:333314,d: "Magnifying instruments, optical, manufacturing"},
{c:333314,d: "Microscopes (except electron, proton) manufacturing"},
{c:333314,d: "Mirrors, optical, manufacturing"},
{c:333314,d: "Night vision optical devices manufacturing"},
{c:333314,d: "Optical alignment and display instruments (except photographic) manufacturing"},
{c:333314,d: "Optical gun sighting and fire control equipment and instruments manufacturing"},
{c:333314,d: "Optical test and inspection equipment manufacturing"},
{c:333314,d: "Periscopes manufacturing"},
{c:333314,d: "Photographic lenses manufacturing"},
{c:333314,d: "Prisms, optical, manufacturing"},
{c:333314,d: "Projection lenses manufacturing"},
{c:333314,d: "Reflectors, optical, manufacturing"},
{c:333314,d: "Sights, telescopic, manufacturing"},
{c:333314,d: "Telescopes manufacturing"},
{c:333314,d: "Theodolites manufacturing"},
{c:333316,d: "Aerial cameras manufacturing"},
{c:333316,d: "Blueprint equipment manufacturing"},
{c:333316,d: "Cameras (except television, video) manufacturing"},
{c:333316,d: "Densitometers (except laboratory analytical) manufacturing"},
{c:333316,d: "Developing equipment, film, manufacturing"},
{c:333316,d: "Digital cameras manufacturing"},
{c:333316,d: "Driers, photographic, manufacturing"},
{c:333316,d: "Editing equipment, motion picture (e.g., rewinders, splicers, titlers, viewers), manufacturing"},
{c:333316,d: "Enlargers, photographic, manufacturing"},
{c:333316,d: "Exposure meters, photographic, manufacturing"},
{c:333316,d: "Film developing equipment manufacturing"},
{c:333316,d: "Flash apparatus, photographic, manufacturing"},
{c:333316,d: "Lens hoods, camera, manufacturing"},
{c:333316,d: "Light meters, photographic, manufacturing"},
{c:333316,d: "Microfiche equipment (e.g., cameras, projectors, readers) manufacturing"},
{c:333316,d: "Microfilm equipment (e.g., cameras, projectors, readers) manufacturing"},
{c:333316,d: "Motion picture cameras manufacturing"},
{c:333316,d: "Motion picture projectors manufacturing"},
{c:333316,d: "Overhead projectors (except computer peripheral) manufacturing"},
{c:333316,d: "Photocopying machines manufacturing"},
{c:333316,d: "Photoflash equipment manufacturing"},
{c:333316,d: "Photographic equipment (except lenses) manufacturing"},
{c:333316,d: "Projection equipment (e.g., motion picture, slide), photographic, manufacturing"},
{c:333316,d: "Projection screens (i.e., motion picture, overhead, slide) manufacturing"},
{c:333316,d: "Range finders, photographic, manufacturing"},
{c:333316,d: "Readers, microfilm or microfiche, manufacturing"},
{c:333316,d: "Screens, projection (i.e., motion picture, overhead, slide), manufacturing"},
{c:333316,d: "Sensitometers, photographic, manufacturing"},
{c:333316,d: "Tanks, photographic developing, fixing, and washing, manufacturing"},
{c:333316,d: "Trays, photographic printing and processing, manufacturing"},
{c:333316,d: "Tripods, camera and projector, manufacturing"},
{c:333318,d: "Adding machines manufacturing"},
{c:333318,d: "Alignment equipment, motor vehicle, manufacturing"},
{c:333318,d: "Balancing equipment, motor vehicle, manufacturing"},
{c:333318,d: "Beauty and barber shop equipment (except chairs) manufacturing"},
{c:333318,d: "Binding equipment (i.e., plastics or tape binding), office-type, manufacturing"},
{c:333318,d: "Brake service equipment (except mechanic's handtools), motor vehicle, manufacturing"},
{c:333318,d: "Bundling machinery (e.g., box strapping, mail, newspaper) manufacturing"},
{c:333318,d: "Calculators manufacturing"},
{c:333318,d: "Canceling machinery, postal office-type, manufacturing"},
{c:333318,d: "Car washing machinery manufacturing"},
{c:333318,d: "Carnival and amusement park rides manufacturing"},
{c:333318,d: "Carnival and amusement park shooting gallery machinery manufacturing"},
{c:333318,d: "Carousels (i.e., merry-go-rounds) manufacturing"},
{c:333318,d: "Carpet and floor cleaning equipment, electric commercial-type, manufacturing"},
{c:333318,d: "Carpet sweepers, mechanical, manufacturing"},
{c:333318,d: "Cash registers (except point of sales terminals) manufacturing"},
{c:333318,d: "Central vacuuming systems, commercial-type, manufacturing"},
{c:333318,d: "Change making machines manufacturing"},
{c:333318,d: "Check writing machines manufacturing"},
{c:333318,d: "Cigarette vending machines manufacturing"},
{c:333318,d: "Coffee makers and urns, commercial-type, manufacturing"},
{c:333318,d: "Coin counting machinery manufacturing"},
{c:333318,d: "Coin wrapping machines manufacturing"},
{c:333318,d: "Coin-operated vending machines manufacturing"},
{c:333318,d: "Collating machinery, office-type, manufacturing"},
{c:333318,d: "Cooking equipment (i.e., fryers, microwave ovens, ovens, ranges), commercial-type, manufacturing"},
{c:333318,d: "Corn popping machines, commercial-type, manufacturing"},
{c:333318,d: "Currency counting machinery manufacturing"},
{c:333318,d: "Deep-fat fryers, commercial-type, manufacturing"},
{c:333318,d: "Dictating machines manufacturing"},
{c:333318,d: "Dishwashing machines, commercial-type, manufacturing"},
{c:333318,d: "Drycleaning equipment and machinery manufacturing"},
{c:333318,d: "Dryers, laundry (except household-type), manufacturing"},
{c:333318,d: "Envelope stuffing, sealing, and addressing machinery manufacturing"},
{c:333318,d: "Ferris wheels manufacturing"},
{c:333318,d: "Flight simulation machinery manufacturing"},
{c:333318,d: "Floor sanding, washing, and polishing machines, commercial-type, manufacturing"},
{c:333318,d: "Food warming equipment, commercial-type, manufacturing"},
{c:333318,d: "Frame and body alignment equipment, motor vehicle, manufacturing"},
{c:333318,d: "Garbage disposal units, commercial-type, manufacturing"},
{c:333318,d: "Gas ranges, commercial-type, manufacturing"},
{c:333318,d: "Hair dryers, beauty parlor-type, manufacturing"},
{c:333318,d: "Holepunchers (except hand operated), office-type, manufacturing"},
{c:333318,d: "Hot beverage vending machines manufacturing"},
{c:333318,d: "Hotplates, commercial-type, manufacturing"},
{c:333318,d: "Ice cream vending machines manufacturing"},
{c:333318,d: "Incoming mail handling equipment (e.g., opening, scanning, sorting) manufacturing"},
{c:333318,d: "Laundry extractors manufacturing"},
{c:333318,d: "Laundry machinery and equipment (except household-type) manufacturing"},
{c:333318,d: "Laundry pressing machines (except household-type) manufacturing"},
{c:333318,d: "Letter folding, stuffing, and sealing machinery manufacturing"},
{c:333318,d: "Locks, coin-operated, manufacturing"},
{c:333318,d: "Mail handling machinery, post office-type, manufacturing"},
{c:333318,d: "Mechanisms for coin-operated machines manufacturing"},
{c:333318,d: "Microwave ovens, commercial-type, manufacturing"},
{c:333318,d: "Mop wringers manufacturing"},
{c:333318,d: "Oil water separators manufacturing"},
{c:333318,d: "Ovens, commercial-type, manufacturing"},
{c:333318,d: "Ozone machines for water purification manufacturing"},
{c:333318,d: "Postage meters manufacturing"},
{c:333318,d: "Postage stamp vending machines manufacturing"},
{c:333318,d: "Power washer cleaning equipment manufacturing"},
{c:333318,d: "Ranges, commercial-type, manufacturing"},
{c:333318,d: "Sanding machines, floor, manufacturing"},
{c:333318,d: "Sewage treatment equipment manufacturing"},
{c:333318,d: "Snack and confection vending machines manufacturing"},
{c:333318,d: "Soft drink vending machines manufacturing"},
{c:333318,d: "Steam cookers, commercial-type, manufacturing"},
{c:333318,d: "Steam tables manufacturing"},
{c:333318,d: "Stenography machinery manufacturing"},
{c:333318,d: "Stoves, commercial-type, manufacturing"},
{c:333318,d: "Swimming pool filter systems manufacturing"},
{c:333318,d: "Teaching machines (e.g., flight simulators) manufacturing"},
{c:333318,d: "Tire mounting machines, motor vehicle, manufacturing"},
{c:333318,d: "Trash and garbage compactors, commercial-type, manufacturing"},
{c:333318,d: "Typewriters manufacturing"},
{c:333318,d: "Vacuum cleaners, industrial and commercial-type, manufacturing"},
{c:333318,d: "Vending machines manufacturing"},
{c:333318,d: "Voting machines manufacturing"},
{c:333318,d: "Wash water recycling machinery manufacturing"},
{c:333318,d: "Washing machines, laundry (except household-type), manufacturing"},
{c:333318,d: "Water heaters (except boilers), commercial-type, manufacturing"},
{c:333318,d: "Water purification equipment manufacturing"},
{c:333318,d: "Water softening equipment manufacturing"},
{c:333318,d: "Water treatment equipment manufacturing"},
{c:333318,d: "Word processing equipment, dedicated, manufacturing"},
{c:333413,d: "Air purification equipment, stationary, manufacturing"},
{c:333413,d: "Air scrubbing systems manufacturing"},
{c:333413,d: "Air washers (i.e., air scrubbers) manufacturing"},
{c:333413,d: "Aircurtains manufacturing"},
{c:333413,d: "Attic fans manufacturing"},
{c:333413,d: "Blower filter units manufacturing"},
{c:333413,d: "Dust and fume collecting equipment manufacturing"},
{c:333413,d: "Electrostatic precipitation equipment manufacturing"},
{c:333413,d: "Exhaust fans, industrial and commercial-type, manufacturing"},
{c:333413,d: "Fans, industrial and commercial-type, manufacturing"},
{c:333413,d: "Filters, air-conditioner, manufacturing"},
{c:333413,d: "Filters, furnace, manufacturing"},
{c:333413,d: "Furnace filters manufacturing"},
{c:333413,d: "Ventilating fans, industrial and commercial-type, manufacturing"},
{c:333414,d: "Baseboard heating equipment manufacturing"},
{c:333414,d: "Boilers, heating, manufacturing"},
{c:333414,d: "Burners, heating, manufacturing"},
{c:333414,d: "Fireplace inserts (i.e., heat directing) manufacturing"},
{c:333414,d: "Furnaces (except forced air), heating, manufacturing"},
{c:333414,d: "Furnaces, floor and wall, manufacturing"},
{c:333414,d: "Gas burners, heating, manufacturing"},
{c:333414,d: "Gas fireplaces manufacturing"},
{c:333414,d: "Gas space heaters manufacturing"},
{c:333414,d: "Gas-oil burners, combination, manufacturing"},
{c:333414,d: "Heaters, space (except portable electric), manufacturing"},
{c:333414,d: "Heaters, swimming pool, manufacturing"},
{c:333414,d: "Heating equipment, hot water (except hot water heaters), manufacturing"},
{c:333414,d: "Heating units, baseboard, manufacturing"},
{c:333414,d: "Hydronic heating equipment manufacturing"},
{c:333414,d: "Kerosene space heaters manufacturing"},
{c:333414,d: "Logs, gas fireplace, manufacturing"},
{c:333414,d: "Oil burners, heating, manufacturing"},
{c:333414,d: "Radiators (except motor vehicle, portable electric) manufacturing"},
{c:333414,d: "Room heaters (except portable electric) manufacturing"},
{c:333414,d: "Solar energy heating equipment manufacturing"},
{c:333414,d: "Solar heating systems manufacturing"},
{c:333414,d: "Space heaters (except portable electric) manufacturing"},
{c:333414,d: "Steam heating equipment manufacturing"},
{c:333414,d: "Swimming pool heaters manufacturing"},
{c:333414,d: "Unit heaters (except portable electric) manufacturing"},
{c:333414,d: "Wood stoves manufacturing"},
{c:333415,d: "Air-conditioners, unit (e.g., motor home, travel trailer, window), manufacturing"},
{c:333415,d: "Air-conditioning and warm air heating combination units manufacturing"},
{c:333415,d: "Air-conditioning compressors (except motor vehicle) manufacturing"},
{c:333415,d: "Air-conditioning condensers and condensing units manufacturing"},
{c:333415,d: "Air-conditioning equipment (except motor vehicle) manufacturing"},
{c:333415,d: "Beer cooling and dispensing equipment manufacturing"},
{c:333415,d: "Coolers, refrigeration, manufacturing"},
{c:333415,d: "Coolers, water, manufacturing"},
{c:333415,d: "Cooling towers manufacturing"},
{c:333415,d: "Counters and display cases, refrigerated, manufacturing"},
{c:333415,d: "Dehumidifiers (except portable electric) manufacturing"},
{c:333415,d: "Display cases, refrigerated, manufacturing"},
{c:333415,d: "Drinking fountains, refrigerated, manufacturing"},
{c:333415,d: "Electric warm air (i.e., forced air) furnaces manufacturing"},
{c:333415,d: "Evaporative condensers (i.e., heat transfer equipment) manufacturing"},
{c:333415,d: "Fountains, refrigerated drinking, manufacturing"},
{c:333415,d: "Freezing equipment, industrial and commercial-type, manufacturing"},
{c:333415,d: "Furnaces, warm air (i.e., forced air), manufacturing"},
{c:333415,d: "Heat pumps manufacturing"},
{c:333415,d: "Heating and air-conditioning combination units manufacturing"},
{c:333415,d: "Heating equipment, warm air (i.e., forced air), manufacturing"},
{c:333415,d: "Humidifying equipment (except portable) manufacturing"},
{c:333415,d: "Ice making machinery manufacturing"},
{c:333415,d: "Laboratory-type freezers manufacturing"},
{c:333415,d: "Lockers, refrigerated, manufacturing"},
{c:333415,d: "Refrigerated lockers manufacturing"},
{c:333415,d: "Refrigeration compressors manufacturing"},
{c:333415,d: "Refrigeration equipment, industrial and commercial-type, manufacturing"},
{c:333415,d: "Refrigeration units, truck-type, manufacturing"},
{c:333415,d: "Room air-conditioners manufacturing"},
{c:333415,d: "Showcases, refrigerated, manufacturing"},
{c:333415,d: "Snow making machinery manufacturing"},
{c:333415,d: "Soda fountain cooling and dispensing equipment manufacturing"},
{c:333415,d: "Water (i.e., drinking) coolers, mechanical, manufacturing"},
{c:333511,d: "Die-casting dies manufacturing"},
{c:333511,d: "Foundry casting molds manufacturing"},
{c:333511,d: "Industrial molds (except steel ingot) manufacturing"},
{c:333511,d: "Molds (except steel ingot), industrial, manufacturing"},
{c:333511,d: "Molds for forming materials (e.g., glass, plastics, rubber) manufacturing"},
{c:333511,d: "Molds for metal casting (except steel ingot) manufacturing"},
{c:333511,d: "Molds for plastics and rubber working machinery manufacturing"},
{c:333514,d: "Cutting dies, metalworking, manufacturing"},
{c:333514,d: "Diamond dies, metalworking, manufacturing"},
{c:333514,d: "Die sets for metal stamping presses manufacturing"},
{c:333514,d: "Dies and die holders for metal cutting and forming (except threading) manufacturing"},
{c:333514,d: "Dies, metalworking (except threading), manufacturing"},
{c:333514,d: "Dies, plastics forming, manufacturing"},
{c:333514,d: "Dies, steel rule, metal cutting, manufacturing"},
{c:333514,d: "Extrusion dies for use with all materials manufacturing"},
{c:333514,d: "Jigs (e.g., checking, gauging, inspection) manufacturing"},
{c:333514,d: "Jigs and fixtures for use with machine tools manufacturing"},
{c:333514,d: "Punches for use with machine tools manufacturing"},
{c:333514,d: "Subpresses, machine tool, manufacturing"},
{c:333514,d: "Welding positioners (i.e., jigs) manufacturing"},
{c:333514,d: "Wire drawing and straightening dies manufacturing"},
{c:333515,d: "Angle rings (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Arbors (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Bits and knives for metalworking lathes, planers, and shapers manufacturing"},
{c:333515,d: "Bits, drill, metalworking, manufacturing"},
{c:333515,d: "Blanks, cutting tool, manufacturing"},
{c:333515,d: "Broaches (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Cams (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Chasers (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Chucks (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Clamps (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Collars (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Collets (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Counterbores (i.e., a machine tool accessory), metalworking, manufacturing"},
{c:333515,d: "Countersinks (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Cutters, metal milling, manufacturing"},
{c:333515,d: "Dies and taps (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Drill bits, metalworking, manufacturing"},
{c:333515,d: "Files (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Hobs (i.e., metal gear cutting tool) manufacturing"},
{c:333515,d: "Honing heads (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Hopper feed devices (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Indexing, rotary tables (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Inserts, cutting tool, manufacturing"},
{c:333515,d: "Knives and bits for metalworking lathes, planers, and shapers manufacturing"},
{c:333515,d: "Letter pins (e.g., gauging, measuring) manufacturing"},
{c:333515,d: "Machine knives, metal cutting, manufacturing"},
{c:333515,d: "Machine tool attachments and accessories manufacturing"},
{c:333515,d: "Mandrels (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Measuring attachments (e.g., sine bars) for machine tool manufacturing"},
{c:333515,d: "Pushers (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Reamers (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Sine bars (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Taps and dies (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Thread cutting dies (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Toolholders (i.e., a machine tool accessory) manufacturing"},
{c:333515,d: "Tools and accessories for machine tools manufacturing"},
{c:333515,d: "Tracer and tapering machine tool attachments manufacturing"},
{c:333517,d: "Arbor presses, metalworking, manufacturing"},
{c:333517,d: "Automatic screw machines, metal cutting type, manufacturing"},
{c:333517,d: "Beader machines, metalworking, manufacturing"},
{c:333517,d: "Bending and forming machines, metalworking, manufacturing"},
{c:333517,d: "Boring machines, metalworking, manufacturing"},
{c:333517,d: "Boring, drilling, and milling machine combinations, metalworking, manufacturing"},
{c:333517,d: "Brakes, press, metalworking, manufacturing"},
{c:333517,d: "Broaching machines, metalworking, manufacturing"},
{c:333517,d: "Brushing machines, metalworking, manufacturing"},
{c:333517,d: "Buffing and polishing machines, metalworking, manufacturing"},
{c:333517,d: "Burnishing machines, metalworking, manufacturing"},
{c:333517,d: "Can forming machines, metalworking, manufacturing"},
{c:333517,d: "Centering machines, metalworking, manufacturing"},
{c:333517,d: "Chamfering machines, metalworking, manufacturing"},
{c:333517,d: "Chemical milling machines, metalworking, manufacturing"},
{c:333517,d: "Chucking machines, automatic, metalworking, manufacturing"},
{c:333517,d: "Crankshaft grinding machines metal cutting type, manufacturing"},
{c:333517,d: "Custom roll forming machines, metalworking, manufacturing"},
{c:333517,d: "Cut-off machines, metalworking, manufacturing"},
{c:333517,d: "Cutting machines, metalworking, manufacturing"},
{c:333517,d: "Cylinder boring machines metal cutting type, manufacturing"},
{c:333517,d: "Deburring machines, metalworking, manufacturing"},
{c:333517,d: "Die sinking machines, metalworking, manufacturing"},
{c:333517,d: "Die-casting machines, metalworking, manufacturing"},
{c:333517,d: "Drill presses, metalworking, manufacturing"},
{c:333517,d: "Drilling machines, metalworking, manufacturing"},
{c:333517,d: "Drop hammers, metal forging and shaping, manufacturing"},
{c:333517,d: "Duplicating machines (e.g., key cutting), metalworking, manufacturing"},
{c:333517,d: "Electrochemical milling machines, metalworking, manufacturing"},
{c:333517,d: "Electrode discharge metal cutting machines manufacturing"},
{c:333517,d: "Electrolytic metal cutting machines manufacturing"},
{c:333517,d: "Electron-discharge metal cutting machines manufacturing"},
{c:333517,d: "Extruding machines, metalworking, manufacturing"},
{c:333517,d: "Facing machines, metalworking, manufacturing"},
{c:333517,d: "Filing machines, metalworking, manufacturing"},
{c:333517,d: "Flange facing machines, metalworking, manufacturing"},
{c:333517,d: "Forging machinery and hammers manufacturing"},
{c:333517,d: "Forming machines (except drawing), metalworking, manufacturing"},
{c:333517,d: "Gear cutting and finishing machines, metalworking, manufacturing"},
{c:333517,d: "Gear rolling machines, metalworking, manufacturing"},
{c:333517,d: "Grinding machines, metalworking, manufacturing"},
{c:333517,d: "Home workshop metal cutting machine tools (except handtools, welding equipment) manufacturing"},
{c:333517,d: "Honing and lapping machines, metal cutting type, manufacturing"},
{c:333517,d: "Key cutting machines, metal cutting type, manufacturing"},
{c:333517,d: "Knurling machines, metalworking, manufacturing"},
{c:333517,d: "Laser boring, drilling, and cutting machines, metalworking, manufacturing"},
{c:333517,d: "Lathes, metalworking, manufacturing"},
{c:333517,d: "Machine tools, metal cutting, manufacturing"},
{c:333517,d: "Machine tools, metal forming, manufacturing"},
{c:333517,d: "Magnetic forming machines, metalworking, manufacturing"},
{c:333517,d: "Metal cutting machine tools manufacturing"},
{c:333517,d: "Metal deposit forming machines manufacturing"},
{c:333517,d: "Metal forming machine tools manufacturing"},
{c:333517,d: "Metalworking lathes manufacturing"},
{c:333517,d: "Milling machines, metalworking, manufacturing"},
{c:333517,d: "Nail heading machines manufacturing"},
{c:333517,d: "Numerically controlled metal cutting machine tools manufacturing"},
{c:333517,d: "Pipe cutting and threading machines, metalworking, manufacturing"},
{c:333517,d: "Planers, metalworking, manufacturing"},
{c:333517,d: "Plasma jet spray metal forming machines manufacturing"},
{c:333517,d: "Plasma process metal cutting machines (except welding equipment) manufacturing"},
{c:333517,d: "Polishing and buffing machines, metalworking, manufacturing"},
{c:333517,d: "Powder metal forming presses manufacturing"},
{c:333517,d: "Press brakes, metalworking, manufacturing"},
{c:333517,d: "Presses (e.g., bending, punching, shearing, stamping), metal forming, manufacturing"},
{c:333517,d: "Punching machines, metalworking, manufacturing"},
{c:333517,d: "Reaming machines, metalworking, manufacturing"},
{c:333517,d: "Rifling machines, metalworking, manufacturing"},
{c:333517,d: "Riveting machines, metalworking, manufacturing"},
{c:333517,d: "Sawing machines, metalworking, manufacturing"},
{c:333517,d: "Screw and nut slotting machines, metalworking, manufacturing"},
{c:333517,d: "Shaving machines, metalworking, manufacturing"},
{c:333517,d: "Shearing machines, metal forming, manufacturing"},
{c:333517,d: "Sheet metal forming machines manufacturing"},
{c:333517,d: "Slotting machines, metalworking, manufacturing"},
{c:333517,d: "Spinning machines, metalworking, manufacturing"},
{c:333517,d: "Spline rolling machines, metalworking, manufacturing"},
{c:333517,d: "Spring winding and forming machines, metalworking, manufacturing"},
{c:333517,d: "Stamping machines, metalworking, manufacturing"},
{c:333517,d: "Stretching machines, metalworking, manufacturing"},
{c:333517,d: "Swaging machines, metalworking, manufacturing"},
{c:333517,d: "Tapping machines, metalworking, manufacturing"},
{c:333517,d: "Thread rolling machines, metalworking, manufacturing"},
{c:333517,d: "Threading machines, metalworking, manufacturing"},
{c:333517,d: "Turning machines (i.e., lathes), metalworking, manufacturing"},
{c:333517,d: "Turret lathes, metalworking, manufacturing"},
{c:333517,d: "Ultrasonic boring, drilling, and cutting machines, metalworking, manufacturing"},
{c:333517,d: "Ultrasonic metal forming machines manufacturing"},
{c:333517,d: "Upsetters (i.e., forging machines) manufacturing"},
{c:333517,d: "Valve grinding machines, metalworking, manufacturing"},
{c:333519,d: "Assembly machines manufacturing"},
{c:333519,d: "Bar mill machinery, metalworking, manufacturing"},
{c:333519,d: "Billet mill machinery, metalworking, manufacturing"},
{c:333519,d: "Blooming and slabbing mill machinery, metalworking, manufacturing"},
{c:333519,d: "Coil winding and cutting machinery, metalworking, manufacturing"},
{c:333519,d: "Cold rolling mill machinery, metalworking, manufacturing"},
{c:333519,d: "Cradle assembly machinery (i.e., wire making equipment) manufacturing"},
{c:333519,d: "Draw bench machines manufacturing"},
{c:333519,d: "Galvanizing machinery manufacturing"},
{c:333519,d: "Hot strip mill machinery, metalworking, manufacturing"},
{c:333519,d: "Hot-rolling mill machinery, metalworking, manufacturing"},
{c:333519,d: "Marking machines, metal, manufacturing"},
{c:333519,d: "Picklers and pickling machinery, metalworking, manufacturing"},
{c:333519,d: "Pipe and tube rolling mill machinery, metalworking, manufacturing"},
{c:333519,d: "Plate rolling mill machinery, metalworking, manufacturing"},
{c:333519,d: "Propeller straightening presses manufacturing"},
{c:333519,d: "Rod rolling mill machinery, metalworking, manufacturing"},
{c:333519,d: "Rolling mill machinery and equipment, metalworking, manufacturing"},
{c:333519,d: "Rolling mill roll machines, metalworking, manufacturing"},
{c:333519,d: "Scarfing units, rolling mill machinery, metalworking, manufacturing"},
{c:333519,d: "Screwdowns and boxes machinery, metal, manufacturing"},
{c:333519,d: "Screwdriving machines manufacturing"},
{c:333519,d: "Structural rolling mill machinery, metalworking, manufacturing"},
{c:333519,d: "Tube rolling mill machinery, metalworking, manufacturing"},
{c:333519,d: "Wire drawing and fabricating machinery and equipment (except dies) manufacturing"},
{c:333611,d: "Gas turbine generator set units manufacturing"},
{c:333611,d: "Gas turbines (except aircraft) manufacturing"},
{c:333611,d: "Generator sets, turbine (e.g., gas, hydraulic, steam), manufacturing"},
{c:333611,d: "Governors, steam, manufacturing"},
{c:333611,d: "Hydraulic turbine generator set units manufacturing"},
{c:333611,d: "Hydraulic turbines manufacturing"},
{c:333611,d: "Motor generator sets, turbo generators, manufacturing"},
{c:333611,d: "Steam turbine generator set units manufacturing"},
{c:333611,d: "Steam turbines manufacturing"},
{c:333611,d: "Turbine generator set units manufacturing"},
{c:333611,d: "Turbines (except aircraft) manufacturing"},
{c:333611,d: "Water turbines manufacturing"},
{c:333611,d: "Wind powered turbine generator sets manufacturing"},
{c:333611,d: "Wind turbines (i.e., windmill) manufacturing"},
{c:333611,d: "Windmills, electric power, generation-type, manufacturing"},
{c:333612,d: "Drives, high-speed industrial (except hydrostatic), manufacturing"},
{c:333612,d: "Gearmotors (i.e., power transmission equipment) manufacturing"},
{c:333612,d: "Gears, power transmission (except aircraft, motor vehicle), manufacturing"},
{c:333612,d: "Motors, gear, manufacturing"},
{c:333612,d: "Reducers, speed, manufacturing"},
{c:333612,d: "Reduction gears and gear units (except aircraft power transmission equipment, automotive) manufacturing"},
{c:333612,d: "Speed changers (i.e., power transmission equipment) manufacturing"},
{c:333612,d: "Speed reducers (i.e., power transmission equipment) manufacturing"},
{c:333613,d: "Ball joints (except aircraft, motor vehicle) manufacturing"},
{c:333613,d: "Bearings, plain (except internal combustion engine), manufacturing"},
{c:333613,d: "Bushings, plain (except internal combustion engine), manufacturing"},
{c:333613,d: "Chains, power transmission, manufacturing"},
{c:333613,d: "Collars, shaft for power transmission equipment, manufacturing"},
{c:333613,d: "Couplings, mechanical power transmission, manufacturing"},
{c:333613,d: "Drive chains, bicycle and motorcycle, manufacturing"},
{c:333613,d: "Joints, swivel (except aircraft, motor vehicle), manufacturing"},
{c:333613,d: "Joints, universal (except aircraft, motor vehicle), manufacturing"},
{c:333613,d: "Pulleys, power transmission, manufacturing"},
{c:333613,d: "Railroad car journal bearings, plain, manufacturing"},
{c:333613,d: "Sheaves, mechanical power transmission, manufacturing"},
{c:333613,d: "Sprockets, power transmission equipment, manufacturing"},
{c:333613,d: "Universal joints (except aircraft, motor vehicle) manufacturing"},
{c:333618,d: "Diesel and semidiesel engines manufacturing"},
{c:333618,d: "Electric outboard motors manufacturing"},
{c:333618,d: "Engines, diesel and semidiesel, manufacturing"},
{c:333618,d: "Engines, diesel locomotive, manufacturing"},
{c:333618,d: "Engines, internal combustion (except aircraft, nondiesel automotive), manufacturing"},
{c:333618,d: "Engines, natural gas, manufacturing"},
{c:333618,d: "Gasoline engines (except aircraft, automotive, truck) manufacturing"},
{c:333618,d: "Governors, diesel engine, manufacturing"},
{c:333618,d: "Governors, gasoline engine (except automotive), manufacturing"},
{c:333618,d: "Internal combustion engines (except aircraft, nondiesel automotive, nondiesel truck) manufacturing"},
{c:333618,d: "Internal combustion engines for hybrid drive systems (except automotive) manufacturing"},
{c:333618,d: "Locomotive diesel engines manufacturing"},
{c:333618,d: "Marine engines manufacturing"},
{c:333618,d: "Motors, outboard, manufacturing"},
{c:333618,d: "Natural gas engines manufacturing"},
{c:333618,d: "Outboard motors manufacturing"},
{c:333618,d: "Semidiesel engines manufacturing"},
{c:333912,d: "Air compressors (except air-conditioning, refrigeration) manufacturing"},
{c:333912,d: "Bicycle pumps manufacturing"},
{c:333912,d: "Compressors, air and gas, general purpose-type, manufacturing"},
{c:333912,d: "Paint sprayers (i.e., compressor and spray gun unit) manufacturing"},
{c:333912,d: "Power (i.e., pressure) washer units manufacturing"},
{c:333912,d: "Sprayers, manual pump, general purpose-type, manufacturing"},
{c:333912,d: "Vacuum pumps (except laboratory) manufacturing"},
{c:333914,d: "Centrifugal pumps manufacturing"},
{c:333914,d: "Dispensing and measuring pumps (e.g., gasoline, lubricants) manufacturing"},
{c:333914,d: "Gasoline measuring and dispensing pumps manufacturing"},
{c:333914,d: "Oil measuring and dispensing pumps manufacturing"},
{c:333914,d: "Oil well and oil field pumps manufacturing"},
{c:333914,d: "Pumps (except fluid power), general purpose, manufacturing"},
{c:333914,d: "Pumps for railroad equipment lubrication systems manufacturing"},
{c:333914,d: "Pumps, industrial and commercial-type, general purpose, manufacturing"},
{c:333914,d: "Pumps, measuring and dispensing (e.g., gasoline), manufacturing"},
{c:333914,d: "Pumps, oil field or well, manufacturing"},
{c:333914,d: "Pumps, sump or water, residential-type, manufacturing"},
{c:333914,d: "Sump pumps, residential-type, manufacturing"},
{c:333921,d: "Automobile lifts (i.e., garage-type, service station) manufacturing"},
{c:333921,d: "Dumbwaiters manufacturing"},
{c:333921,d: "Elevators, passenger and freight, manufacturing"},
{c:333921,d: "Escalators manufacturing"},
{c:333921,d: "Stairways, moving, manufacturing"},
{c:333921,d: "Walkways, moving, manufacturing"},
{c:333922,d: "Belt conveyor systems manufacturing"},
{c:333922,d: "Buckets, elevator or conveyor, manufacturing"},
{c:333922,d: "Carousel conveyors (e.g., luggage) manufacturing"},
{c:333922,d: "Coal and ore conveyors manufacturing"},
{c:333922,d: "Conveyors and conveying equipment manufacturing"},
{c:333922,d: "Elevators, farm, manufacturing"},
{c:333922,d: "Farm-type conveyors manufacturing"},
{c:333922,d: "Mine conveyors manufacturing"},
{c:333922,d: "Overhead conveyors manufacturing"},
{c:333922,d: "Passenger baggage belt loaders (except industrial truck) manufacturing"},
{c:333922,d: "Pneumatic tube conveyors manufacturing"},
{c:333922,d: "Screw conveyors manufacturing"},
{c:333923,d: "Aerial work platforms manufacturing"},
{c:333923,d: "Automobile wrecker (i.e., tow truck) hoists manufacturing"},
{c:333923,d: "Block and tackle manufacturing"},
{c:333923,d: "Boat lifts manufacturing"},
{c:333923,d: "Chain hoists manufacturing"},
{c:333923,d: "Cranes, overhead traveling, manufacturing"},
{c:333923,d: "Davits manufacturing"},
{c:333923,d: "Hoists (except aircraft loading) manufacturing"},
{c:333923,d: "Locomotive cranes manufacturing"},
{c:333923,d: "Monorail systems (except passenger-type) manufacturing"},
{c:333923,d: "Overhead traveling cranes manufacturing"},
{c:333923,d: "Pulleys (except power transmission), metal, manufacturing"},
{c:333923,d: "Ship cranes and derricks manufacturing"},
{c:333923,d: "Winches manufacturing"},
{c:333923,d: "Wire rope hoists manufacturing"},
{c:333924,d: "Aircraft engine cradles manufacturing"},
{c:333924,d: "Aircraft loading hoists manufacturing"},
{c:333924,d: "Bomb lifts manufacturing"},
{c:333924,d: "Cabs for industrial trucks manufacturing"},
{c:333924,d: "Carts, grocery, made from purchased wire"},
{c:333924,d: "Cranes, industrial truck, manufacturing"},
{c:333924,d: "Dollies manufacturing"},
{c:333924,d: "Drum cradles manufacturing"},
{c:333924,d: "Forklifts manufacturing"},
{c:333924,d: "Grocery carts made from purchased wire"},
{c:333924,d: "Hand trucks manufacturing"},
{c:333924,d: "Hoists, aircraft loading, manufacturing"},
{c:333924,d: "Industrial trucks and tractors manufacturing"},
{c:333924,d: "Mechanic's creepers manufacturing"},
{c:333924,d: "Mobile straddle carriers manufacturing"},
{c:333924,d: "Pallet movers manufacturing"},
{c:333924,d: "Pallet or skid jacks manufacturing"},
{c:333924,d: "Stackers, industrial, truck-type, manufacturing"},
{c:333924,d: "Stackers, portable (except farm), manufacturing"},
{c:333924,d: "Straddle carriers, mobile, manufacturing"},
{c:333924,d: "Tractors, industrial, manufacturing"},
{c:333924,d: "Trucks, industrial, manufacturing"},
{c:333924,d: "Wheelbarrows manufacturing"},
{c:333991,d: "Buffing machines, handheld power-driven, manufacturing"},
{c:333991,d: "Cartridge (i.e., powder) handheld power-driven tools manufacturing"},
{c:333991,d: "Chain saws, handheld power-driven, manufacturing"},
{c:333991,d: "Chisels, handheld power-driven, manufacturing"},
{c:333991,d: "Circular saws, handheld power-driven, manufacturing"},
{c:333991,d: "Drills, handheld power-driven (except heavy construction and mining-type), manufacturing"},
{c:333991,d: "Grinders, handheld power-driven, manufacturing"},
{c:333991,d: "Handtools, power-driven, manufacturing"},
{c:333991,d: "Impact wrenches, handheld power-driven, manufacturing"},
{c:333991,d: "Jigsaws, handheld power-driven, manufacturing"},
{c:333991,d: "Nail guns, handheld power-driven, manufacturing"},
{c:333991,d: "Nailers and staplers, handheld power-driven, manufacturing"},
{c:333991,d: "Paint spray guns, handheld pneumatic, manufacturing"},
{c:333991,d: "Planers, handheld power-driven, manufacturing"},
{c:333991,d: "Polishers, handheld power-driven, manufacturing"},
{c:333991,d: "Powder actuated handheld power tools manufacturing"},
{c:333991,d: "Power-driven handtools manufacturing"},
{c:333991,d: "Riveting guns, handheld power-driven, manufacturing"},
{c:333991,d: "Routers, handheld power-driven, manufacturing"},
{c:333991,d: "Sanders, handheld power-driven, manufacturing"},
{c:333991,d: "Saws, handheld power-driven, manufacturing"},
{c:333991,d: "Screwdrivers and nut drivers, handheld power-driven, manufacturing"},
{c:333991,d: "Shears and nibblers, handheld power-driven, manufacturing"},
{c:333991,d: "Staplers and nailers, handheld power-driven, manufacturing"},
{c:333991,d: "Tools, handheld power-driven, manufacturing"},
{c:333991,d: "Wrenches, impact, handheld power-driven, manufacturing"},
{c:333992,d: "Arc-welding equipment manufacturing"},
{c:333992,d: "Electrode holders, welding, manufacturing"},
{c:333992,d: "Electrodes, welding, manufacturing"},
{c:333992,d: "Electron beam welding equipment manufacturing"},
{c:333992,d: "Gas welding equipment manufacturing"},
{c:333992,d: "Gas welding rods, coated or cored, manufacturing"},
{c:333992,d: "Generating apparatus and parts, welding, electrical, manufacturing"},
{c:333992,d: "Laser welding equipment manufacturing"},
{c:333992,d: "Plasma welding equipment manufacturing"},
{c:333992,d: "Resistance welding equipment manufacturing"},
{c:333992,d: "Seam welding equipment manufacturing"},
{c:333992,d: "Soldering equipment (except handheld) manufacturing"},
{c:333992,d: "Spot welding equipment manufacturing"},
{c:333992,d: "Ultrasonic welding equipment manufacturing"},
{c:333992,d: "Welding equipment manufacturing"},
{c:333992,d: "Welding wire or rods (i.e., coated, cored) manufacturing"},
{c:333993,d: "Bag opening, filling, and closing machines manufacturing"},
{c:333993,d: "Bottle washers, packaging machinery, manufacturing"},
{c:333993,d: "Bottling machinery (e.g., capping, filling, labeling, sterilizing, washing) manufacturing"},
{c:333993,d: "Bread wrapping machines manufacturing"},
{c:333993,d: "Canning machinery manufacturing"},
{c:333993,d: "Capping, sealing, and lidding packaging machinery manufacturing"},
{c:333993,d: "Carton filling machinery manufacturing"},
{c:333993,d: "Coding, dating, and imprinting packaging machinery manufacturing"},
{c:333993,d: "Food packaging machinery manufacturing"},
{c:333993,d: "Labeling (i.e., packaging) machinery manufacturing"},
{c:333993,d: "Packaging machinery manufacturing"},
{c:333993,d: "Testing, weighing, inspecting, packaging machinery manufacturing"},
{c:333993,d: "Thermoform, blister, and skin packaging machinery manufacturing"},
{c:333993,d: "Wrapping (i.e., packaging) machinery manufacturing"},
{c:333994,d: "Ceramic kilns and furnaces manufacturing"},
{c:333994,d: "Core baking and mold drying ovens manufacturing"},
{c:333994,d: "Dielectric industrial heating equipment manufacturing"},
{c:333994,d: "Distillation ovens, charcoal and coke, manufacturing"},
{c:333994,d: "Enameling ovens manufacturing"},
{c:333994,d: "Furnaces and ovens for drying and redrying, industrial process-type, manufacturing"},
{c:333994,d: "Furnaces and ovens, semiconductor wafer, manufacturing"},
{c:333994,d: "Furnaces, industrial and laboratory-type (except dental), manufacturing"},
{c:333994,d: "Garbage incinerators (except precast concrete) manufacturing"},
{c:333994,d: "Heat treating ovens, industrial process-type, manufacturing"},
{c:333994,d: "Incinerators (except precast concrete) manufacturing"},
{c:333994,d: "Induction heating equipment, industrial process-type, manufacturing"},
{c:333994,d: "Infrared ovens, industrial, manufacturing"},
{c:333994,d: "Japanning ovens manufacturing"},
{c:333994,d: "Kilns (except cement, chemical, wood) manufacturing"},
{c:333994,d: "Lacquering ovens manufacturing"},
{c:333994,d: "Metal melting furnaces, industrial, manufacturing"},
{c:333994,d: "Ovens, industrial process and laboratory-type, manufacturing"},
{c:333994,d: "Paint baking and drying ovens manufacturing"},
{c:333994,d: "Smelting ovens manufacturing"},
{c:333994,d: "Tilemaking kilns manufacturing"},
{c:333995,d: "Actuators, fluid power, manufacturing"},
{c:333995,d: "Cylinders, fluid power, manufacturing"},
{c:333995,d: "Fluid power actuators manufacturing"},
{c:333995,d: "Fluid power cylinders manufacturing"},
{c:333995,d: "Hydraulic cylinders, fluid power, manufacturing"},
{c:333995,d: "Pneumatic cylinders, fluid power, manufacturing"},
{c:333996,d: "Fluid power motors manufacturing"},
{c:333996,d: "Fluid power pumps manufacturing"},
{c:333996,d: "Hydraulic pumps, fluid power, manufacturing"},
{c:333996,d: "Hydrostatic drives manufacturing"},
{c:333996,d: "Hydrostatic transmissions manufacturing"},
{c:333996,d: "Motors, fluid power, manufacturing"},
{c:333996,d: "Pneumatic pumps, fluid power, manufacturing"},
{c:333996,d: "Pumps, fluid power, manufacturing"},
{c:333997,d: "Baby scales manufacturing"},
{c:333997,d: "Balances, including laboratory-type, manufacturing"},
{c:333997,d: "Bathroom scales manufacturing"},
{c:333997,d: "Delicatessen scales manufacturing"},
{c:333997,d: "Industrial scales manufacturing"},
{c:333997,d: "Motor truck scales manufacturing"},
{c:333997,d: "Parcel post scales manufacturing"},
{c:333997,d: "Post office-type scales manufacturing"},
{c:333997,d: "Railroad track scales manufacturing"},
{c:333997,d: "Retail scales (e.g., butcher, delicatessen, produce) manufacturing"},
{c:333997,d: "Scales, including laboratory-type, manufacturing"},
{c:333997,d: "Vehicle scales manufacturing"},
{c:333999,d: "Aircraft carrier catapults manufacturing"},
{c:333999,d: "Baling machinery (e.g., paper, scrap metal) manufacturing"},
{c:333999,d: "Bellows, industrial-type, manufacturing"},
{c:333999,d: "Bridge and gate lifting machinery manufacturing"},
{c:333999,d: "Centrifuges, industrial and laboratory-type, manufacturing"},
{c:333999,d: "Cremating ovens manufacturing"},
{c:333999,d: "Filters, industrial and general purpose-type (except internal combustion engine, warm air furnace), manufacturing"},
{c:333999,d: "Gas generating machinery, general purpose-type, manufacturing"},
{c:333999,d: "Gas separating machinery manufacturing"},
{c:333999,d: "Gate and bridge lifting machinery manufacturing"},
{c:333999,d: "Ice crushers, industrial and commercial-type, manufacturing"},
{c:333999,d: "Jacks, hydraulic and pneumatic, manufacturing"},
{c:333999,d: "Presses, metal baling, manufacturing"},
{c:333999,d: "Screening and sifting machinery for general industrial use manufacturing"},
{c:333999,d: "Sieves and screening equipment, general purpose-type, manufacturing"},
{c:333999,d: "Sludge tables manufacturing"},
{c:333999,d: "Sprinkler systems, automatic fire, manufacturing"},
{c:333999,d: "Steam separating machinery manufacturing"},
{c:333999,d: "Strainers, pipeline, manufacturing"},
{c:333999,d: "Vapor separating machinery manufacturing"},
{c:333999,d: "Weather vanes manufacturing"},
{c:334111,d: "Analog computers manufacturing"},
{c:334111,d: "Computer servers manufacturing"},
{c:334111,d: "Computers manufacturing"},
{c:334111,d: "Digital computers manufacturing"},
{c:334111,d: "Hybrid computers manufacturing"},
{c:334111,d: "Laptop computers manufacturing"},
{c:334111,d: "Mainframe computers manufacturing"},
{c:334111,d: "Microcomputers manufacturing"},
{c:334111,d: "Minicomputers manufacturing"},
{c:334111,d: "Notebook computers manufacturing"},
{c:334111,d: "Personal computers manufacturing"},
{c:334111,d: "Portable computers manufacturing"},
{c:334111,d: "Tablet computers manufacturing"},
{c:334111,d: "Workstations, computer, manufacturing"},
{c:334112,d: "CD-ROM drives manufacturing"},
{c:334112,d: "Direct access storage devices manufacturing"},
{c:334112,d: "Disk drives, computer, manufacturing"},
{c:334112,d: "DVD (digital video disc) drives, computer peripheral equipment, manufacturing"},
{c:334112,d: "Flexible (i.e., floppy) magnetic disk drives manufacturing"},
{c:334112,d: "Floppy disk drives manufacturing"},
{c:334112,d: "Hard disk drives manufacturing"},
{c:334112,d: "Magnetic/optical combination storage units for computers manufacturing"},
{c:334112,d: "Optical disk drives manufacturing"},
{c:334112,d: "Storage devices, computer, manufacturing"},
{c:334112,d: "Tape storage units (e.g., drive backups), computer peripheral equipment, manufacturing"},
{c:334118,d: "ATMs (automatic teller machines) manufacturing"},
{c:334118,d: "Automatic teller machines (ATM) manufacturing"},
{c:334118,d: "Biometrics system input devices (e.g., retinal scan, iris pattern recognition, hand geometry) manufacturing"},
{c:334118,d: "Computer input/output equipment manufacturing"},
{c:334118,d: "Computer terminals manufacturing"},
{c:334118,d: "Flat panel displays (i.e., complete units), computer peripheral equipment, manufacturing"},
{c:334118,d: "Funds transfer devices manufacturing"},
{c:334118,d: "Input/output equipment, computer, manufacturing"},
{c:334118,d: "Joystick devices manufacturing"},
{c:334118,d: "Keyboards, computer peripheral equipment, manufacturing"},
{c:334118,d: "Lottery ticket sales terminals manufacturing"},
{c:334118,d: "Magnetic ink recognition devices, computer peripheral equipment, manufacturing"},
{c:334118,d: "Monitors, computer peripheral equipment, manufacturing"},
{c:334118,d: "Mouse devices, computer peripheral equipment, manufacturing"},
{c:334118,d: "Optical readers and scanners manufacturing"},
{c:334118,d: "Overhead projectors, computer peripheral-type, manufacturing"},
{c:334118,d: "Plotters, computer, manufacturing"},
{c:334118,d: "Point of sale terminals manufacturing"},
{c:334118,d: "Pointing devices, computer peripheral equipment, manufacturing"},
{c:334118,d: "Printers, computer, manufacturing"},
{c:334118,d: "Teleprinters (i.e., computer terminals) manufacturing"},
{c:334118,d: "Terminals, computer, manufacturing"},
{c:334210,d: "Carrier equipment (i.e., analog, digital), telephone, manufacturing"},
{c:334210,d: "Central office and switching equipment, telephone, manufacturing"},
{c:334210,d: "Communications headgear, telephone, manufacturing"},
{c:334210,d: "Cordless telephones (except cellular) manufacturing"},
{c:334210,d: "Data communications equipment (e.g., bridges, gateways, routers) manufacturing"},
{c:334210,d: "Facsimile equipment, stand-alone, manufacturing"},
{c:334210,d: "Keysets, telephone, manufacturing"},
{c:334210,d: "Local area network (LAN) communications equipment (e.g., bridges, gateways, routers) manufacturing"},
{c:334210,d: "Modems, carrier equipment, manufacturing"},
{c:334210,d: "Multiplex equipment, telephone, manufacturing"},
{c:334210,d: "PBX (private branch exchange) equipment manufacturing"},
{c:334210,d: "Private branch exchange (PBX) equipment manufacturing"},
{c:334210,d: "Repeater and transceiver equipment, carrier line, manufacturing"},
{c:334210,d: "Subscriber loop equipment, telephone, manufacturing"},
{c:334210,d: "Switching equipment, telephone, manufacturing"},
{c:334210,d: "Telephone answering machines manufacturing"},
{c:334210,d: "Telephone carrier line equipment manufacturing"},
{c:334210,d: "Telephone carrier switching equipment manufacturing"},
{c:334210,d: "Telephones (except cellular telephone) manufacturing"},
{c:334210,d: "Telephones, coin-operated, manufacturing"},
{c:334210,d: "Toll switching equipment, telephone, manufacturing"},
{c:334210,d: "Wide area network communications equipment (e.g., bridges, gateways, routers) manufacturing"},
{c:334220,d: "Airborne radio communications equipment manufacturing"},
{c:334220,d: "Amplifiers (e.g., RF power and IF), broadcast and studio equipment, manufacturing"},
{c:334220,d: "Antennas, satellite, manufacturing"},
{c:334220,d: "Antennas, transmitting and receiving, manufacturing"},
{c:334220,d: "Automobile antennas manufacturing"},
{c:334220,d: "Broadcast equipment (including studio), for radio and television, manufacturing"},
{c:334220,d: "Cable decoders manufacturing"},
{c:334220,d: "Cable television transmission and receiving equipment manufacturing"},
{c:334220,d: "Cameras, television, manufacturing"},
{c:334220,d: "CB (citizens' band) radios manufacturing"},
{c:334220,d: "Cellular telephones manufacturing"},
{c:334220,d: "Citizens' band (CB) radios manufacturing"},
{c:334220,d: "Closed-circuit television equipment manufacturing"},
{c:334220,d: "Communications equipment, mobile and microwave, manufacturing"},
{c:334220,d: "Earth station communications equipment manufacturing"},
{c:334220,d: "Global positioning system (GPS) equipment manufacturing"},
{c:334220,d: "GPS (global positioning system) equipment manufacturing"},
{c:334220,d: "Marine radio communications equipment manufacturing"},
{c:334220,d: "Microphones, broadcast and studio equipment, manufacturing"},
{c:334220,d: "Microwave communications equipment manufacturing"},
{c:334220,d: "Mobile communications equipment manufacturing"},
{c:334220,d: "Pagers manufacturing"},
{c:334220,d: "Radio transmitting antennas and ground equipment manufacturing"},
{c:334220,d: "Receiver-transmitter units (i.e., transceivers) manufacturing"},
{c:334220,d: "Satellite antennas manufacturing"},
{c:334220,d: "Satellite communications equipment manufacturing"},
{c:334220,d: "Smartphones manufacturing"},
{c:334220,d: "Space satellites, communications, manufacturing"},
{c:334220,d: "Studio equipment, radio and television broadcasting, manufacturing"},
{c:334220,d: "Telephones, cellular, manufacturing"},
{c:334220,d: "Television transmitting antennas and ground equipment manufacturing"},
{c:334220,d: "Television, closed-circuit equipment, manufacturing"},
{c:334220,d: "Transceivers (i.e., transmitter-receiver units) manufacturing"},
{c:334220,d: "Video cameras (except household-type) manufacturing"},
{c:334290,d: "Alarm system central monitoring equipment manufacturing"},
{c:334290,d: "Alarm systems and equipment manufacturing"},
{c:334290,d: "Automotive theft alarm systems manufacturing"},
{c:334290,d: "Burglar alarm systems and equipment manufacturing"},
{c:334290,d: "Car alarm manufacturing"},
{c:334290,d: "Carbon monoxide detectors manufacturing"},
{c:334290,d: "Controlling equipment, street light, manufacturing"},
{c:334290,d: "Fire detection and alarm systems manufacturing"},
{c:334290,d: "Glass breakage detection and signaling devices"},
{c:334290,d: "Intercom systems and equipment manufacturing"},
{c:334290,d: "Motion alarms (e.g., swimming pool, perimeter) manufacturing"},
{c:334290,d: "Motion detectors, security system, manufacturing"},
{c:334290,d: "Portable intrusion detection and signaling devices manufacturing"},
{c:334290,d: "Railroad signaling equipment manufacturing"},
{c:334290,d: "Remote control units (e.g., garage door, television) manufacturing"},
{c:334290,d: "Signals (e.g., highway, pedestrian, railway, traffic) manufacturing"},
{c:334290,d: "Sirens (e.g., air raid, industrial, marine, vehicle) manufacturing"},
{c:334290,d: "Smoke detectors manufacturing"},
{c:334290,d: "Theft prevention signaling devices (e.g., door entrance annunciation, holdup signaling devices, personal duress signaling devices), manufacturing"},
{c:334290,d: "Traffic advisory and signalling systems manufacturing"},
{c:334290,d: "Traffic signals manufacturing"},
{c:334290,d: "Video-based stadium displays manufacturing"},
{c:334310,d: "Amplifiers (e.g., auto, home, musical instrument, public address) manufacturing"},
{c:334310,d: "Automobile radio receivers manufacturing"},
{c:334310,d: "Camcorders manufacturing"},
{c:334310,d: "Car stereos manufacturing"},
{c:334310,d: "Clock radios manufacturing"},
{c:334310,d: "Coin-operated jukebox manufacturing"},
{c:334310,d: "Compact disc players (e.g., automotive, household-type) manufacturing"},
{c:334310,d: "Digital video disc players manufacturing"},
{c:334310,d: "DVD (digital video disc) players manufacturing"},
{c:334310,d: "Home stereo systems manufacturing"},
{c:334310,d: "Home tape recorders and players (e.g., cartridge, cassette, reel) manufacturing"},
{c:334310,d: "Home theater audio and video equipment manufacturing"},
{c:334310,d: "Jukeboxes manufacturing"},
{c:334310,d: "Loudspeakers manufacturing"},
{c:334310,d: "Microphones (except broadcast and studio equipment) manufacturing"},
{c:334310,d: "Portable stereo systems manufacturing"},
{c:334310,d: "Projection television manufacturing"},
{c:334310,d: "Public address systems and equipment manufacturing"},
{c:334310,d: "Radio headphones manufacturing"},
{c:334310,d: "Radio receiving sets manufacturing"},
{c:334310,d: "Speaker systems manufacturing"},
{c:334310,d: "Tape players and recorders, household-type, manufacturing"},
{c:334310,d: "Television (TV) sets manufacturing"},
{c:334310,d: "TV (television) sets manufacturing"},
{c:334310,d: "VCR (video cassette recorder) manufacturing"},
{c:334310,d: "Video cameras, household-type, manufacturing"},
{c:334310,d: "Video cassette recorders (VCR) manufacturing"},
{c:334412,d: "Circuit boards, printed, bare, manufacturing"},
{c:334412,d: "Flexible wiring boards, bare, manufacturing"},
{c:334412,d: "Printed circuit boards, bare, manufacturing"},
{c:334413,d: "Diodes, solid-state (e.g., germanium, silicon), manufacturing"},
{c:334413,d: "Fuel cells, solid-state, manufacturing"},
{c:334413,d: "Gunn effect devices manufacturing"},
{c:334413,d: "Hall effect devices manufacturing"},
{c:334413,d: "Hybrid integrated circuits manufacturing"},
{c:334413,d: "Infrared sensors, solid-state, manufacturing"},
{c:334413,d: "Integrated microcircuits manufacturing"},
{c:334413,d: "Laser diodes manufacturing"},
{c:334413,d: "LED (light emitting diode) manufacturing"},
{c:334413,d: "Light emitting diodes (LED) manufacturing"},
{c:334413,d: "Metal oxide silicon (MOS) devices manufacturing"},
{c:334413,d: "Microcontroller chip manufacturing"},
{c:334413,d: "Microprocessor chip manufacturing"},
{c:334413,d: "Monolithic integrated circuits (solid-state) manufacturing"},
{c:334413,d: "MOS (metal oxide silicon) devices manufacturing"},
{c:334413,d: "Optoelectronic devices manufacturing"},
{c:334413,d: "Photoelectric cells, solid-state (e.g., electronic eye), manufacturing"},
{c:334413,d: "Photonic integrated circuits manufacturing"},
{c:334413,d: "Photovoltaic cells manufacturing"},
{c:334413,d: "Photovoltaic devices, solid-state, manufacturing"},
{c:334413,d: "Rectifiers, semiconductor, manufacturing"},
{c:334413,d: "Semiconductor circuit networks (i.e., solid-state integrated circuits) manufacturing"},
{c:334413,d: "Semiconductor devices manufacturing"},
{c:334413,d: "Semiconductor dice and wafers manufacturing"},
{c:334413,d: "Semiconductor memory chips manufacturing"},
{c:334413,d: "Silicon wafers, chemically doped, manufacturing"},
{c:334413,d: "Silicon wave guides manufacturing"},
{c:334413,d: "Solar cells manufacturing"},
{c:334413,d: "Static converters, integrated circuits, manufacturing"},
{c:334413,d: "Thin film integrated circuits manufacturing"},
{c:334413,d: "Thyristors manufacturing"},
{c:334413,d: "Transistors manufacturing"},
{c:334413,d: "Voltage regulators, integrated circuits, manufacturing"},
{c:334413,d: "Wafers (semiconductor devices) manufacturing"},
{c:334416,d: "Capacitors, electronic, fixed and variable, manufacturing"},
{c:334416,d: "Chokes for electronic circuitry manufacturing"},
{c:334416,d: "Condensers, electronic, manufacturing"},
{c:334416,d: "Inductors, electronic component-type (e.g., chokes, coils, transformers), manufacturing"},
{c:334416,d: "Resistors, electronic, manufacturing"},
{c:334416,d: "Telephone and telegraph transformers, electronic component-type, manufacturing"},
{c:334416,d: "Thermistors manufacturing"},
{c:334416,d: "Transformers, electronic component-types, manufacturing"},
{c:334416,d: "Varistors manufacturing"},
{c:334417,d: "Coaxial connectors manufacturing"},
{c:334417,d: "Connectors, electronic (e.g., coaxial, cylindrical, printed circuit, rack and panel), manufacturing"},
{c:334417,d: "Cylindrical connectors, electronic, manufacturing"},
{c:334417,d: "Fiber optic connectors manufacturing"},
{c:334417,d: "Planar cable connectors manufacturing"},
{c:334417,d: "Rack and panel connectors manufacturing"},
{c:334418,d: "Loaded computer boards manufacturing"},
{c:334418,d: "Loading printed circuit boards"},
{c:334418,d: "Memory boards manufacturing"},
{c:334418,d: "Peripheral controller boards manufacturing"},
{c:334418,d: "Personal computer modems manufacturing"},
{c:334418,d: "Plotter controller boards manufacturing"},
{c:334418,d: "Printed circuit assemblies manufacturing"},
{c:334418,d: "Printed circuit boards loading"},
{c:334419,d: "Cathode ray tubes (CRT) manufacturing"},
{c:334419,d: "Computer cable sets (e.g., monitor, printer) manufacturing"},
{c:334419,d: "CRT (cathode ray tube) manufacturing"},
{c:334419,d: "Crystals and crystal assemblies, electronic, manufacturing"},
{c:334419,d: "Electron tube parts (e.g., bases, getters, guns) (except glass blanks) manufacturing"},
{c:334419,d: "Electron tubes manufacturing"},
{c:334419,d: "Filters, electronic component-type, manufacturing"},
{c:334419,d: "Harness assemblies for electronic use manufacturing"},
{c:334419,d: "Heads (e.g., recording, read/write) manufacturing"},
{c:334419,d: "Klystron tubes manufacturing"},
{c:334419,d: "LCD (liquid crystal display) unit screens manufacturing"},
{c:334419,d: "Magnetron tubes manufacturing"},
{c:334419,d: "Microwave components manufacturing"},
{c:334419,d: "Needles, phonograph and styli, manufacturing"},
{c:334419,d: "Piezoelectric crystals manufacturing"},
{c:334419,d: "Piezoelectric devices manufacturing"},
{c:334419,d: "Printed circuit laminates manufacturing"},
{c:334419,d: "Quartz crystals, electronic application, manufacturing"},
{c:334419,d: "Radio frequency identification (RFID) devices manufacturing"},
{c:334419,d: "Rectifiers, electronic component-type (except semiconductor), manufacturing"},
{c:334419,d: "Resonant reed devices, electronic, manufacturing"},
{c:334419,d: "Rheostats, electronic, manufacturing"},
{c:334419,d: "Screens for liquid crystal display (LCD) manufacturing"},
{c:334419,d: "Solenoids for electronic applications manufacturing"},
{c:334419,d: "Switches for electronic applications manufacturing"},
{c:334419,d: "Television picture tubes manufacturing"},
{c:334419,d: "Transducers (except pressure) manufacturing"},
{c:334419,d: "Traveling wave tubes manufacturing"},
{c:334419,d: "Tubes, cathode ray, manufacturing"},
{c:334419,d: "Tubes, electron, manufacturing"},
{c:334419,d: "Tubes, electronic, manufacturing"},
{c:334419,d: "Tubes, klystron, manufacturing"},
{c:334419,d: "Vacuum tubes manufacturing"},
{c:334510,d: "Arc lamp units, electrotherapeutic (except infrared, ultraviolet), manufacturing"},
{c:334510,d: "Audiological equipment, electromedical, manufacturing"},
{c:334510,d: "Automated blood and body fluid analyzers (except laboratory) manufacturing"},
{c:334510,d: "Bronchoscopes, electromedical, manufacturing"},
{c:334510,d: "Carbon arc lamp units, electrotherapeutic (except infrared and ultraviolet), manufacturing"},
{c:334510,d: "Cardiodynameter manufacturing"},
{c:334510,d: "Cardiographs manufacturing"},
{c:334510,d: "Cardiophone, electric, manufacturing"},
{c:334510,d: "Cardioscope manufacturing"},
{c:334510,d: "Cardiotachometer manufacturing"},
{c:334510,d: "Colonscopes, electromedical, manufacturing"},
{c:334510,d: "Cystoscopes, electromedical, manufacturing"},
{c:334510,d: "Defibrillators manufacturing"},
{c:334510,d: "Diagnostic equipment, electromedical, manufacturing"},
{c:334510,d: "Diagnostic equipment, MRI (magnetic resonance imaging), manufacturing"},
{c:334510,d: "Dialysis equipment, electromedical, manufacturing"},
{c:334510,d: "Diathermy apparatus, electromedical, manufacturing"},
{c:334510,d: "Diathermy units manufacturing"},
{c:334510,d: "Electrocardiographs manufacturing"},
{c:334510,d: "Electroencephalographs manufacturing"},
{c:334510,d: "Electrogastrograph manufacturing"},
{c:334510,d: "Electromedical diagnostic equipment manufacturing"},
{c:334510,d: "Electromedical equipment manufacturing"},
{c:334510,d: "Electromedical therapy equipment manufacturing"},
{c:334510,d: "Electromyographs manufacturing"},
{c:334510,d: "Electrotherapeutic apparatus manufacturing"},
{c:334510,d: "Electrotherapy units manufacturing"},
{c:334510,d: "Endoscopic equipment, electromedical (e.g., bronchoscopes, colonoscopes, cystoscopes), manufacturing"},
{c:334510,d: "Gastroscopes, electromedical, manufacturing"},
{c:334510,d: "Hearing aids, electronic, manufacturing"},
{c:334510,d: "Heart-lung machine manufacturing"},
{c:334510,d: "Laser equipment, electromedical, manufacturing"},
{c:334510,d: "Laser systems and equipment, medical, manufacturing"},
{c:334510,d: "Lithotripters manufacturing"},
{c:334510,d: "Magnetic resonance imaging (MRI) medical diagnostic equipment manufacturing"},
{c:334510,d: "Medical cleaning equipment, ultrasonic, manufacturing"},
{c:334510,d: "Medical ultrasound equipment manufacturing"},
{c:334510,d: "MRI (magnetic resonance imaging) medical diagnostic equipment manufacturing"},
{c:334510,d: "Otoscopes, electromedical, manufacturing"},
{c:334510,d: "Pacemakers manufacturing"},
{c:334510,d: "Patient monitoring equipment (e.g., intensive care, coronary care unit) manufacturing"},
{c:334510,d: "PET (positron emission tomography) scanners manufacturing"},
{c:334510,d: "Phonocardiographs manufacturing"},
{c:334510,d: "Positron emission tomography (PET) scanners manufacturing"},
{c:334510,d: "Probes, electric medical, manufacturing"},
{c:334510,d: "Respiratory analysis equipment, electromedical, manufacturing"},
{c:334510,d: "Retinoscopes, electromedical, manufacturing"},
{c:334510,d: "Sentinel, cardiac, manufacturing"},
{c:334510,d: "Surgical support systems (e.g., heart-lung machines) (except iron lungs) manufacturing"},
{c:334510,d: "TENS (transcutaneous electrical nerve stimulator) manufacturing"},
{c:334510,d: "Transcutaneous electrical nerve stimulators (TENS) manufacturing"},
{c:334510,d: "Ultrasonic medical equipment manufacturing"},
{c:334510,d: "Ultrasonic scanning devices, medical, manufacturing"},
{c:334511,d: "Acceleration indicators and systems components, aerospace type, manufacturing"},
{c:334511,d: "Aeronautical systems and instruments manufacturing"},
{c:334511,d: "Aeronautical systems and instruments overhauling, conversion, or rebuilding"},
{c:334511,d: "Air traffic control radar systems and equipment manufacturing"},
{c:334511,d: "Airborne navigational systems manufacturing"},
{c:334511,d: "Aircraft flight instruments (except engine instruments) manufacturing"},
{c:334511,d: "Airframe equipment instruments manufacturing"},
{c:334511,d: "Airspeed instruments (aeronautical) manufacturing"},
{c:334511,d: "Altimeters, aeronautical, manufacturing"},
{c:334511,d: "Angle-of-attack instrumentation manufacturing"},
{c:334511,d: "Angle-of-yaw instrumentation manufacturing"},
{c:334511,d: "Artificial horizon instrumentation manufacturing"},
{c:334511,d: "Bank and turn indicators and components (aeronautical instruments) manufacturing"},
{c:334511,d: "Cabin environment indicators, transmitters, and sensors manufacturing"},
{c:334511,d: "Compasses, gyroscopic and magnetic (except portable), manufacturing"},
{c:334511,d: "Countermeasure sets (e.g., active countermeasures, jamming equipment) manufacturing"},
{c:334511,d: "Distance measuring equipment (DME), aeronautical, manufacturing"},
{c:334511,d: "Driftmeters, aeronautical, manufacturing"},
{c:334511,d: "Electronic guidance systems and equipment manufacturing"},
{c:334511,d: "Fathometers manufacturing"},
{c:334511,d: "Fish finders (i.e., sonar) manufacturing"},
{c:334511,d: "Flight and navigation sensors, transmitters, and displays manufacturing"},
{c:334511,d: "Flight recorders (i.e., black boxes) manufacturing"},
{c:334511,d: "Glide slope instrumentation manufacturing"},
{c:334511,d: "Gyrocompasses manufacturing"},
{c:334511,d: "Gyrogimbals manufacturing"},
{c:334511,d: "Gyroscopes manufacturing"},
{c:334511,d: "Heads-up display (HUD) systems, aeronautical, manufacturing"},
{c:334511,d: "Horizon situation instrumentation manufacturing"},
{c:334511,d: "HUD (heads-up display) systems, aeronautical, manufacturing"},
{c:334511,d: "Hydrophones manufacturing"},
{c:334511,d: "Inertial navigation systems, aeronautical, manufacturing"},
{c:334511,d: "Infrared homing systems, aeronautical, manufacturing"},
{c:334511,d: "Instrument landing system instrumentation, airborne or airport, manufacturing"},
{c:334511,d: "Instruments, aeronautical, manufacturing"},
{c:334511,d: "Light reconnaissance and surveillance systems and equipment manufacturing"},
{c:334511,d: "Machmeters manufacturing"},
{c:334511,d: "Nautical systems and  instruments manufacturing"},
{c:334511,d: "Navigational instruments manufacturing"},
{c:334511,d: "Omnibearing instrumentation manufacturing"},
{c:334511,d: "Pictorial situation instrumentation manufacturing"},
{c:334511,d: "Position indicators (e.g., for landing gear, stabilizers), airframe equipment, manufacturing"},
{c:334511,d: "Proximity warning (i.e., collision avoidance) equipment manufacturing"},
{c:334511,d: "Radar detectors manufacturing"},
{c:334511,d: "Radar systems and equipment manufacturing"},
{c:334511,d: "Radio magnetic instrumentation (RMI) manufacturing"},
{c:334511,d: "Rate-of-climb instrumentation manufacturing"},
{c:334511,d: "Search and detection systems and instruments manufacturing"},
{c:334511,d: "Sextants (except surveying) manufacturing"},
{c:334511,d: "Sonabuoys manufacturing"},
{c:334511,d: "Sonar fish finders manufacturing"},
{c:334511,d: "Sonar systems and equipment manufacturing"},
{c:334511,d: "Space vehicle guidance systems and equipment manufacturing"},
{c:334511,d: "Speed, pitch, and roll navigational instruments and systems manufacturing"},
{c:334511,d: "Taffrail logs manufacturing"},
{c:334511,d: "Underwater navigational systems manufacturing"},
{c:334511,d: "Warfare countermeasures equipment manufacturing"},
{c:334511,d: "Wheel position indicators and transmitters, aircraft, manufacturing"},
{c:334512,d: "Air flow controllers (except valves), air-conditioning and refrigeration, manufacturing"},
{c:334512,d: "Appliance controls manufacturing"},
{c:334512,d: "Appliance regulators (except switches) manufacturing"},
{c:334512,d: "Building services monitoring controls, automatic, manufacturing"},
{c:334512,d: "Clothes dryer controls, including dryness controls, manufacturing"},
{c:334512,d: "Combination limit and fan controls manufacturing"},
{c:334512,d: "Combination oil and hydronic controls manufacturing"},
{c:334512,d: "Computerized environmental control systems for buildings manufacturing"},
{c:334512,d: "Damper operators (e.g., electric, pneumatic, thermostatic) manufacturing"},
{c:334512,d: "Electric air cleaner controls, automatic, manufacturing"},
{c:334512,d: "Electric heat proportioning controls, modulating controls, manufacturing"},
{c:334512,d: "Electric space heater controls, automatic, manufacturing"},
{c:334512,d: "Energy cutoff controls, residential and commercial types, manufacturing"},
{c:334512,d: "Fan controls, temperature responsive, manufacturing"},
{c:334512,d: "Flame safety controls for furnaces and boilers manufacturing"},
{c:334512,d: "Float controls, residential and commercial types, manufacturing"},
{c:334512,d: "Gas burner automatic controls (except valves) manufacturing"},
{c:334512,d: "Gradual switches, pneumatic, manufacturing"},
{c:334512,d: "Heating and cooling system controls, residential and commercial, manufacturing"},
{c:334512,d: "Heating regulators manufacturing"},
{c:334512,d: "Humidistats (e.g., duct, skeleton, wall) manufacturing"},
{c:334512,d: "Humidity controls, air-conditioning-type, manufacturing"},
{c:334512,d: "Hydronic circulator control, automatic, manufacturing"},
{c:334512,d: "Hydronic limit control manufacturing"},
{c:334512,d: "Hydronic limit, pressure, and temperature controls, manufacturing"},
{c:334512,d: "Ice bank controls manufacturing"},
{c:334512,d: "Ice maker controls manufacturing"},
{c:334512,d: "Ignition controls for gas appliances and furnaces, automatic, manufacturing"},
{c:334512,d: "In-built thermostats, filled system and bimetal types, manufacturing"},
{c:334512,d: "Incinerator control systems, residential and commercial-type, manufacturing"},
{c:334512,d: "Light responsive appliance controls manufacturing"},
{c:334512,d: "Limit controls (e.g., air-conditioning, appliance, heating) manufacturing"},
{c:334512,d: "Line or limit control for electric heat manufacturing"},
{c:334512,d: "Liquid level controls, residential and commercial heating-type, manufacturing"},
{c:334512,d: "Oven temperature controls, nonindustrial, manufacturing"},
{c:334512,d: "Pneumatic relays, air-conditioning-type, manufacturing"},
{c:334512,d: "Pressure controllers, air-conditioning system-type, manufacturing"},
{c:334512,d: "Pressurestats manufacturing"},
{c:334512,d: "Primary oil burner controls (e.g., cadmium cells, stack controls) manufacturing"},
{c:334512,d: "Refrigeration controls, residential and commercial-type, manufacturing"},
{c:334512,d: "Refrigeration thermostats manufacturing"},
{c:334512,d: "Refrigeration/air-conditioning defrost controls manufacturing"},
{c:334512,d: "Room thermostats manufacturing"},
{c:334512,d: "Sequencing controls for electric heating equipment manufacturing"},
{c:334512,d: "Static pressure regulators manufacturing"},
{c:334512,d: "Steam pressure controls, residential and commercial heating-type, manufacturing"},
{c:334512,d: "Surface burner controls, temperature, manufacturing"},
{c:334512,d: "Switches, pneumatic positioning remote, manufacturing"},
{c:334512,d: "Switches, thermostatic, manufacturing"},
{c:334512,d: "Temperature controls, automatic, residential and commercial-types, manufacturing"},
{c:334512,d: "Temperature sensors for motor windings manufacturing"},
{c:334512,d: "Thermocouples, glass vacuum, manufacturing"},
{c:334512,d: "Thermostats (e.g., air-conditioning, appliance, comfort heating, refrigeration) manufacturing"},
{c:334512,d: "Time program controls, air-conditioning systems, manufacturing"},
{c:334512,d: "Vapor heating controls manufacturing"},
{c:334512,d: "Water heater controls manufacturing"},
{c:334513,d: "Absorption analyzers, industrial process type (e.g., infrared), manufacturing"},
{c:334513,d: "Acidity (i.e., pH) instruments, industrial process type, manufacturing"},
{c:334513,d: "Analyzers, industrial process control type, manufacturing"},
{c:334513,d: "Annunciators, relay and solid-state types, industrial display, manufacturing"},
{c:334513,d: "Boiler controls, industrial, power, and marine-type, manufacturing"},
{c:334513,d: "Buoyancy instruments, industrial process-type, manufacturing"},
{c:334513,d: "Chromatographs, industrial process-type, manufacturing"},
{c:334513,d: "Combustion control instruments (except commercial, household furnace-type) manufacturing"},
{c:334513,d: "Controllers for process variables (e.g., electric, electronic, mechanical, pneumatic operation) manufacturing"},
{c:334513,d: "Coulometric analyzers, industrial process-type, manufacturing"},
{c:334513,d: "Data loggers, industrial process-type, manufacturing"},
{c:334513,d: "Density and specific gravity instruments, industrial process-type, manufacturing"},
{c:334513,d: "Differential pressure instruments, industrial process-type, manufacturing"},
{c:334513,d: "Digital displays of process variables manufacturing"},
{c:334513,d: "Display instruments, industrial process control-type, manufacturing"},
{c:334513,d: "Draft gauges, industrial process-type, manufacturing"},
{c:334513,d: "Electric and electronic controllers, industrial process-type, manufacturing"},
{c:334513,d: "Electrodes used in industrial process measurement manufacturing"},
{c:334513,d: "Electrolytic conductivity instruments, industrial process-type, manufacturing"},
{c:334513,d: "Electromagnetic flowmeters manufacturing"},
{c:334513,d: "Flow instruments, industrial process-type, manufacturing"},
{c:334513,d: "Fluidic devices, circuits, and systems for process control, manufacturing"},
{c:334513,d: "Gas analyzers, industrial process-type, manufacturing"},
{c:334513,d: "Gas and liquid analysis instruments, industrial process-type, manufacturing"},
{c:334513,d: "Gas chromatographic instruments, industrial process-type, manufacturing"},
{c:334513,d: "Gas flow instrumentation, industrial process-type, manufacturing"},
{c:334513,d: "Gauges (i.e., analog, digital), industrial process-type, manufacturing"},
{c:334513,d: "Humidity instruments, industrial process-type, manufacturing"},
{c:334513,d: "Hydrometers, industrial process-type, manufacturing"},
{c:334513,d: "Hygrometers, industrial process-type, manufacturing"},
{c:334513,d: "Indicators, industrial process control-type, manufacturing"},
{c:334513,d: "Industrial process control instruments manufacturing"},
{c:334513,d: "Infrared instruments, industrial process-type, manufacturing"},
{c:334513,d: "Instruments for industrial process control manufacturing"},
{c:334513,d: "Level and bulk measuring instruments, industrial process-type, manufacturing"},
{c:334513,d: "Liquid analysis instruments, industrial process-type, manufacturing"},
{c:334513,d: "Liquid concentration instruments, industrial process-type, manufacturing"},
{c:334513,d: "Liquid level instruments, industrial process-type, manufacturing"},
{c:334513,d: "Magnetic flow meters, industrial process-type, manufacturing"},
{c:334513,d: "Manometers, industrial process-type, manufacturing"},
{c:334513,d: "Measuring instruments, industrial process control-type, manufacturing"},
{c:334513,d: "Mechanical measuring instruments, industrial process-type, manufacturing"},
{c:334513,d: "Meters, industrial process control-type, manufacturing"},
{c:334513,d: "Moisture meters, industrial process-type, manufacturing"},
{c:334513,d: "Nanofluidic measurement and control devices manufacturing"},
{c:334513,d: "Panelboard indicators, recorders, and controllers, receiver industrial process-type, manufacturing"},
{c:334513,d: "Pneumatic controllers, industrial process type, manufacturing"},
{c:334513,d: "Potentiometric instruments (except X-Y recorders), industrial process-type, manufacturing"},
{c:334513,d: "Pressure gauges (e.g., dial, digital), industrial process-type, manufacturing"},
{c:334513,d: "Pressure instruments, industrial process-type, manufacturing"},
{c:334513,d: "Primary elements for process flow measurement (i.e., orifice plates) manufacturing"},
{c:334513,d: "Primary process temperature sensors manufacturing"},
{c:334513,d: "Process control instruments, industrial, manufacturing"},
{c:334513,d: "Programmers, process-type, manufacturing"},
{c:334513,d: "Pyrometers, industrial process-type, manufacturing"},
{c:334513,d: "Recorders, industrial process control-type, manufacturing"},
{c:334513,d: "Refractometers, industrial process-type, manufacturing"},
{c:334513,d: "Resistance thermometers and bulbs, industrial process-type, manufacturing"},
{c:334513,d: "Telemetering instruments, industrial process-type, manufacturing"},
{c:334513,d: "Temperature instruments, industrial process-type (except glass and bimetal thermometers), manufacturing"},
{c:334513,d: "Thermal conductivity instruments, industrial process-type, manufacturing"},
{c:334513,d: "Thermistors, industrial process-type, manufacturing"},
{c:334513,d: "Thermocouples, industrial process-type, manufacturing"},
{c:334513,d: "Thermometers, filled system industrial process-type, manufacturing"},
{c:334513,d: "Time cycle and program controllers, industrial process-type, manufacturing"},
{c:334513,d: "Transmitters, industrial process control-type, manufacturing"},
{c:334513,d: "Turbidity instruments, industrial process-type, manufacturing"},
{c:334513,d: "Turbine flow meters, industrial process-type, manufacturing"},
{c:334513,d: "Variable control instruments, industrial process-type, manufacturing"},
{c:334513,d: "Viscosimeters, industrial process-type, manufacturing"},
{c:334513,d: "Viscosity instruments, industrial process-type, manufacturing"},
{c:334513,d: "Water quality monitoring and control systems manufacturing"},
{c:334514,d: "Consumption meters (e.g., gas, water) manufacturing"},
{c:334514,d: "Controls, revolution and timing instruments, manufacturing"},
{c:334514,d: "Counter type registers manufacturing"},
{c:334514,d: "Counters (e.g., electrical, electronic, mechanical), totalizing, manufacturing"},
{c:334514,d: "Counters, revolution, manufacturing"},
{c:334514,d: "Counting devices manufacturing"},
{c:334514,d: "Electromechanical counters manufacturing"},
{c:334514,d: "Electronic totalizing counters manufacturing"},
{c:334514,d: "Fare collection equipment manufacturing"},
{c:334514,d: "Gasmeters, consumption registering, manufacturing"},
{c:334514,d: "Gasmeters, large capacity, domestic and industrial, manufacturing"},
{c:334514,d: "Gasoline dispensing meters (except pumps) manufacturing"},
{c:334514,d: "Gauges (e.g., oil pressure, water temperature, speedometer, tachometer), motor vehicle, manufacturing"},
{c:334514,d: "Gauges for computing pressure-temperature corrections manufacturing"},
{c:334514,d: "Impeller and counter driven flow meters manufacturing"},
{c:334514,d: "Instrument panels, assembling gauges made in the same establishment"},
{c:334514,d: "Integrating meters, nonelectric, manufacturing"},
{c:334514,d: "Linear counters manufacturing"},
{c:334514,d: "Liquid flow meters manufacturing"},
{c:334514,d: "Magnetic counters manufacturing"},
{c:334514,d: "Measuring wheels manufacturing"},
{c:334514,d: "Metering devices (except electrical and industrial process control) manufacturing"},
{c:334514,d: "Meters (except electrical and industrial process control) manufacturing"},
{c:334514,d: "Meters, parking, manufacturing"},
{c:334514,d: "Motor vehicle instruments (e.g., fuel level gauges, oil pressure, speedometers, tachometers, water temperature) manufacturing"},
{c:334514,d: "Parking meters manufacturing"},
{c:334514,d: "Pedometers manufacturing"},
{c:334514,d: "Positive displacement meters manufacturing"},
{c:334514,d: "Predetermined counters manufacturing"},
{c:334514,d: "Production counters manufacturing"},
{c:334514,d: "Propeller type meters with registers manufacturing"},
{c:334514,d: "Registers, linear tallying, manufacturing"},
{c:334514,d: "Rotary type meters, consumption registering, manufacturing"},
{c:334514,d: "Tally counters manufacturing"},
{c:334514,d: "Tallying meters (except clocks, electricity meters, watches) manufacturing"},
{c:334514,d: "Tank truck meters manufacturing"},
{c:334514,d: "Taximeters manufacturing"},
{c:334514,d: "Totalizing fluid meters manufacturing"},
{c:334514,d: "Totalizing meters (except aircraft), consumption registering, manufacturing"},
{c:334514,d: "Turbine meters, consumption registering, manufacturing"},
{c:334514,d: "Watermeters, consumption registering, manufacturing"},
{c:334515,d: "Alternator and generator testers manufacturing"},
{c:334515,d: "Ampere-hour meters manufacturing"},
{c:334515,d: "Analyzers for testing electrical characteristics manufacturing"},
{c:334515,d: "Audiofrequency oscillators manufacturing"},
{c:334515,d: "Audiometers (except medical) manufacturing"},
{c:334515,d: "Automotive ammeters and voltmeters manufacturing"},
{c:334515,d: "Automotive electrical engine diagnostic equipment manufacturing"},
{c:334515,d: "Battery testers, electrical, manufacturing"},
{c:334515,d: "Bridges, electrical (e.g., Kelvin, megohm, vacuum tube, Wheatstone), manufacturing"},
{c:334515,d: "Circuit testers manufacturing"},
{c:334515,d: "Communications signal testing and evaluation equipment manufacturing"},
{c:334515,d: "Current measuring equipment manufacturing"},
{c:334515,d: "Decade boxes (i.e., capacitance, inductance, resistance) manufacturing"},
{c:334515,d: "Demand meters, electric, manufacturing"},
{c:334515,d: "Digital panel meters, electricity measuring, manufacturing"},
{c:334515,d: "Digital test equipment (e.g., electronic and electrical circuits and equipment testing) manufacturing"},
{c:334515,d: "Diode and transistor testers manufacturing"},
{c:334515,d: "Distortion meters and analyzers manufacturing"},
{c:334515,d: "Elapsed time meters, electronic, manufacturing"},
{c:334515,d: "Electrical network analyzers manufacturing"},
{c:334515,d: "Electrical power measuring equipment manufacturing"},
{c:334515,d: "Electricity and electrical signal measuring instruments manufacturing"},
{c:334515,d: "Electricity and electrical signal testing equipment manufacturing"},
{c:334515,d: "Electron tube test equipment manufacturing"},
{c:334515,d: "Electronic test equipment for testing electrical characteristics manufacturing"},
{c:334515,d: "Energy measuring equipment, electrical, manufacturing"},
{c:334515,d: "Field strength and intensity measuring equipment, electrical, manufacturing"},
{c:334515,d: "Frequency meters (e.g., electrical, electronic, mechanical) manufacturing"},
{c:334515,d: "Frequency synthesizers manufacturing"},
{c:334515,d: "Function generators manufacturing"},
{c:334515,d: "Galvanometers (except geophysical) manufacturing"},
{c:334515,d: "Graphic recording meters, electric, manufacturing"},
{c:334515,d: "Ignition testing instruments manufacturing"},
{c:334515,d: "Impedance measuring equipment manufacturing"},
{c:334515,d: "Indicating instruments, electric, manufacturing"},
{c:334515,d: "Instrument shunts manufacturing"},
{c:334515,d: "Instruments for measuring electrical quantities manufacturing"},
{c:334515,d: "Instruments, electric (i.e., testing electrical characteristics), manufacturing"},
{c:334515,d: "Integrated-circuit testers manufacturing"},
{c:334515,d: "Integrating electricity meters manufacturing"},
{c:334515,d: "Internal combustion engine analyzers (i.e., testing electrical characteristics) manufacturing"},
{c:334515,d: "Kelvin bridges (i.e., electrical measuring instruments) manufacturing"},
{c:334515,d: "Laboratory standards testing instruments (e.g., capacitance, electrical resistance, inductance) manufacturing"},
{c:334515,d: "Logic circuit testers manufacturing"},
{c:334515,d: "Measuring equipment for electronic and electrical circuits and equipment manufacturing"},
{c:334515,d: "Measuring instruments and meters, electric, manufacturing"},
{c:334515,d: "Meters, electrical (i.e., graphic recording, panelboard, pocket, portable), manufacturing"},
{c:334515,d: "Meters, power factor and phase angle, manufacturing"},
{c:334515,d: "Microwave test equipment manufacturing"},
{c:334515,d: "Multimeters manufacturing"},
{c:334515,d: "Ohmmeters manufacturing"},
{c:334515,d: "Oscillators (e.g., instrument type audiofrequency and radiofrequency) manufacturing"},
{c:334515,d: "Oscilloscopes manufacturing"},
{c:334515,d: "Phase angle meters manufacturing"},
{c:334515,d: "Portable test meters manufacturing"},
{c:334515,d: "Potentiometric instruments (except industrial process-type) manufacturing"},
{c:334515,d: "Power factor meters manufacturing"},
{c:334515,d: "Power measuring equipment, electrical, manufacturing"},
{c:334515,d: "Pulse (i.e., signal) generators manufacturing"},
{c:334515,d: "Radar testing instruments, electric, manufacturing"},
{c:334515,d: "Radiofrequency measuring equipment manufacturing"},
{c:334515,d: "Radiofrequency oscillators manufacturing"},
{c:334515,d: "Recorders, oscillographic, manufacturing"},
{c:334515,d: "Relays (except electrical, electronic), instrument, manufacturing"},
{c:334515,d: "Resistance measuring equipment manufacturing"},
{c:334515,d: "Semiconductor test equipment manufacturing"},
{c:334515,d: "Shunts, instrument, manufacturing"},
{c:334515,d: "Signal generators and averagers manufacturing"},
{c:334515,d: "Spark plug testing instruments, electric, manufacturing"},
{c:334515,d: "Spectrum analyzers manufacturing"},
{c:334515,d: "Standards and calibration equipment for electrical measuring manufacturing"},
{c:334515,d: "Standing wave ratio measuring equipment manufacturing"},
{c:334515,d: "Stroboscopes manufacturing"},
{c:334515,d: "Sweep generators manufacturing"},
{c:334515,d: "Sweep oscillators manufacturing"},
{c:334515,d: "Synchroscopes manufacturing"},
{c:334515,d: "Tachometer generators manufacturing"},
{c:334515,d: "Test equipment for electronic and electrical circuits and equipment manufacturing"},
{c:334515,d: "Test sets, ignition harness, manufacturing"},
{c:334515,d: "Voltmeters manufacturing"},
{c:334515,d: "Watt-hour and demand meters, combined, manufacturing"},
{c:334515,d: "Watt-hour and time switch meters, combined, manufacturing"},
{c:334515,d: "Watt-hour meters, electric, manufacturing"},
{c:334515,d: "Wattmeters manufacturing"},
{c:334515,d: "Waveform measuring and/or analyzing equipment manufacturing"},
{c:334515,d: "Wheatstone bridges (i.e., electrical measuring instruments) manufacturing"},
{c:334515,d: "X-Y recorders (i.e., plotters (except computer peripheral equipment)) manufacturing"},
{c:334516,d: "Acidity (i.e., pH) measuring equipment, laboratory analysis-type, manufacturing"},
{c:334516,d: "Amino acid analyzers, laboratory-type, manufacturing"},
{c:334516,d: "Atomic force microscopes manufacturing"},
{c:334516,d: "Automatic chemical analyzers, laboratory-type, manufacturing"},
{c:334516,d: "Blood bank process equipment manufacturing"},
{c:334516,d: "Chromatographic instruments, laboratory-type, manufacturing"},
{c:334516,d: "Chronoscopes manufacturing"},
{c:334516,d: "Colorimeters, laboratory-type, manufacturing"},
{c:334516,d: "Coulometric analyzers, laboratory-type, manufacturing"},
{c:334516,d: "Densitometers, laboratory analytical, manufacturing"},
{c:334516,d: "Differential thermal analysis instruments, laboratory-type, manufacturing"},
{c:334516,d: "Electrolytic conductivity instruments, laboratory-type, manufacturing"},
{c:334516,d: "Electron microprobes, laboratory-type, manufacturing"},
{c:334516,d: "Electron microscopes manufacturing"},
{c:334516,d: "Electron paramagnetic spin-type apparatus manufacturing"},
{c:334516,d: "Electrophoresis instruments manufacturing"},
{c:334516,d: "Elemental analyzers manufacturing"},
{c:334516,d: "Flame photometers manufacturing"},
{c:334516,d: "Gas analyzers, laboratory-type, manufacturing"},
{c:334516,d: "Gas chromatographic instruments, laboratory-type, manufacturing"},
{c:334516,d: "Hematology instruments manufacturing"},
{c:334516,d: "Immunology instruments, laboratory, manufacturing"},
{c:334516,d: "Infrared analytical instruments, laboratory-type, manufacturing"},
{c:334516,d: "Laboratory analytical instruments (except optical) manufacturing"},
{c:334516,d: "Liquid chromatographic instruments, laboratory-type, manufacturing"},
{c:334516,d: "Magnetic resonance imaging (MRI) type apparatus (except medical diagnostic) manufacturing"},
{c:334516,d: "Mass spectrometers manufacturing"},
{c:334516,d: "Mass spectroscopy instrumentation manufacturing"},
{c:334516,d: "Microbiology instruments manufacturing"},
{c:334516,d: "Microprobes (e.g., electron, ion, laser, X-ray) manufacturing"},
{c:334516,d: "Microscopes, electron and proton, manufacturing"},
{c:334516,d: "Moisture analyzers, laboratory-type, manufacturing"},
{c:334516,d: "Monochrometers, laboratory-type, manufacturing"},
{c:334516,d: "Nanomanipulator equipment manufacturing"},
{c:334516,d: "Nanosensor instruments manufacturing"},
{c:334516,d: "Nephelometers (except meteorological) manufacturing"},
{c:334516,d: "Neutron activation analysis instruments manufacturing"},
{c:334516,d: "Osmometers manufacturing"},
{c:334516,d: "Particle beam excitation instruments, laboratory-type, manufacturing"},
{c:334516,d: "Particle size analyzers manufacturing"},
{c:334516,d: "Photometers (except photographic exposure meters) manufacturing"},
{c:334516,d: "Photonexcitation analyzers manufacturing"},
{c:334516,d: "Polariscopes manufacturing"},
{c:334516,d: "Polarizers manufacturing"},
{c:334516,d: "Polarographic equipment manufacturing"},
{c:334516,d: "Protein analyzers, laboratory-type, manufacturing"},
{c:334516,d: "Redox (i.e., oxidation-reduction potential) instruments manufacturing"},
{c:334516,d: "Refractometers, laboratory-type, manufacturing"},
{c:334516,d: "Resonance instruments (i.e., laboratory-type) manufacturing"},
{c:334516,d: "Sample analysis instruments (except medical) manufacturing"},
{c:334516,d: "Scanning tunneling microscopes manufacturing"},
{c:334516,d: "Specific ion measuring instruments, laboratory-type, manufacturing"},
{c:334516,d: "Spectrofluorometers manufacturing"},
{c:334516,d: "Spectrographs manufacturing"},
{c:334516,d: "Spectrometers (e.g., electron diffraction, mass, NMR, Raman) manufacturing"},
{c:334516,d: "Spectrophotometers (e.g., atomic absorption, atomic emission, flame, fluorescence, infrared, Raman, visible) manufacturing"},
{c:334516,d: "Surface area analyzers manufacturing"},
{c:334516,d: "Thermal analysis instruments, laboratory-type, manufacturing"},
{c:334516,d: "Thermal conductivity instruments and sensors manufacturing"},
{c:334516,d: "Thermogravimetric analyzers manufacturing"},
{c:334516,d: "Titrimeters manufacturing"},
{c:334516,d: "Turbidometers, laboratory-type, manufacturing"},
{c:334516,d: "Ultraviolet-type analytical instruments manufacturing"},
{c:334517,d: "Beta-ray irradiation equipment manufacturing"},
{c:334517,d: "Computerized axial tomography (CT/CAT) scanners manufacturing"},
{c:334517,d: "CT/CAT (computerized axial tomography) scanners manufacturing"},
{c:334517,d: "Fluoroscopes manufacturing"},
{c:334517,d: "Fluoroscopic X-ray apparatus and tubes manufacturing"},
{c:334517,d: "Gamma-ray irradiation equipment manufacturing"},
{c:334517,d: "Generators, X-ray, manufacturing"},
{c:334517,d: "Irradiation apparatus and tubes (e.g., industrial, medical diagnostic, medical therapeutic, research, scientific), manufacturing"},
{c:334517,d: "Irradiation equipment manufacturing"},
{c:334517,d: "Lamps, X-ray, manufacturing"},
{c:334517,d: "Medical radiation therapy equipment manufacturing"},
{c:334517,d: "Nuclear irradiation equipment manufacturing"},
{c:334517,d: "Radium equipment manufacturing"},
{c:334517,d: "Therapeutic X-ray apparatus and tubes (e.g., medical, industrial, research) manufacturing"},
{c:334517,d: "Tubes, X-ray, manufacturing"},
{c:334517,d: "X-ray apparatus and tubes (e.g., control, industrial, medical, research) manufacturing"},
{c:334517,d: "X-ray generators manufacturing"},
{c:334517,d: "X-ray irradiation equipment manufacturing"},
{c:334517,d: "X-ray tubes manufacturing"},
{c:334519,d: "Abrasion testing machines manufacturing"},
{c:334519,d: "Accelerometers (except aerospace type) manufacturing"},
{c:334519,d: "Actinometers, meteorological, manufacturing"},
{c:334519,d: "Aircraft engine instruments manufacturing"},
{c:334519,d: "Alarm clocks manufacturing"},
{c:334519,d: "Alidades, surveying, manufacturing"},
{c:334519,d: "Appliance timers manufacturing"},
{c:334519,d: "Automotive emissions testing equipment manufacturing"},
{c:334519,d: "Barographs manufacturing"},
{c:334519,d: "Barometers manufacturing"},
{c:334519,d: "Ceilometers manufacturing"},
{c:334519,d: "Chains, surveyor's, manufacturing"},
{c:334519,d: "Chronographs manufacturing"},
{c:334519,d: "Chronometers manufacturing"},
{c:334519,d: "Clock materials and parts (except crystals) manufacturing"},
{c:334519,d: "Clock or watch springs, precision, made from purchased wire"},
{c:334519,d: "Clocks assembling"},
{c:334519,d: "Clocks assembling from purchased components"},
{c:334519,d: "Commercial timing mechanisms manufacturing"},
{c:334519,d: "Compasses, portable magnetic-type, manufacturing"},
{c:334519,d: "Count rate meters, nuclear radiation, manufacturing"},
{c:334519,d: "Dating devices and machines (except rubber stamps) manufacturing"},
{c:334519,d: "Detectors, scintillation, manufacturing"},
{c:334519,d: "Dosimetry devices manufacturing"},
{c:334519,d: "Drafting instruments manufacturing"},
{c:334519,d: "Dynamometers manufacturing"},
{c:334519,d: "Evaporation meters manufacturing"},
{c:334519,d: "Fatigue testing machines, industrial, mechanical, manufacturing"},
{c:334519,d: "Fire detector systems, nonelectric, manufacturing"},
{c:334519,d: "Fuel densitometers, aircraft engine, manufacturing"},
{c:334519,d: "Fuel mixture indicators, aircraft engine, manufacturing"},
{c:334519,d: "Fuel system instruments, aircraft, manufacturing"},
{c:334519,d: "Fuel totalizers, aircraft engine, manufacturing"},
{c:334519,d: "Galvanometers, geophysical, manufacturing"},
{c:334519,d: "Gas leak detectors manufacturing"},
{c:334519,d: "Geiger counters manufacturing"},
{c:334519,d: "Geophysical instruments manufacturing"},
{c:334519,d: "Hand stamps (e.g., date, time), timing mechanism operated, manufacturing"},
{c:334519,d: "Hardness testing equipment manufacturing"},
{c:334519,d: "Household-type timing mechanisms manufacturing"},
{c:334519,d: "Humidity instruments (except industrial process and air-conditioning type) manufacturing"},
{c:334519,d: "Hydrometers (except industrial process-type) manufacturing"},
{c:334519,d: "Hygrometers (except industrial process-type) manufacturing"},
{c:334519,d: "Hygrothermographs manufacturing"},
{c:334519,d: "Indicator testers, turntable, manufacturing"},
{c:334519,d: "Instrumentation for reactor controls, auxiliary, manufacturing"},
{c:334519,d: "Ion chambers manufacturing"},
{c:334519,d: "Kinematic test and measuring equipment manufacturing"},
{c:334519,d: "Leak detectors, water, manufacturing"},
{c:334519,d: "Level gauges, radiation-type, manufacturing"},
{c:334519,d: "Levels and tapes, surveying, manufacturing"},
{c:334519,d: "Lie detectors manufacturing"},
{c:334519,d: "Magnetometers manufacturing"},
{c:334519,d: "Mechanisms, clockwork operated device, manufacturing"},
{c:334519,d: "Metal detectors manufacturing"},
{c:334519,d: "Meteorologic tracking systems manufacturing"},
{c:334519,d: "Meteorological instruments manufacturing"},
{c:334519,d: "Modules for clocks and watches manufacturing"},
{c:334519,d: "Movements, watch or clock, manufacturing"},
{c:334519,d: "Nephoscopes manufacturing"},
{c:334519,d: "Nuclear instrument modules manufacturing"},
{c:334519,d: "Physical properties testing and inspection equipment manufacturing"},
{c:334519,d: "Pitometers manufacturing"},
{c:334519,d: "Polygraph machines manufacturing"},
{c:334519,d: "Pressure and vacuum indicators, aircraft engine, manufacturing"},
{c:334519,d: "Pressure transducers manufacturing"},
{c:334519,d: "Pulse analyzers, nuclear monitoring, manufacturing"},
{c:334519,d: "Pyrheliometers manufacturing"},
{c:334519,d: "RADIAC (radioactivity detection, identification, and computation) equipment manufacturing"},
{c:334519,d: "Radiation detection and monitoring instruments manufacturing"},
{c:334519,d: "Radioactivity detection, identification, and computation (RADIAC) equipment manufacturing"},
{c:334519,d: "Rain gauges manufacturing"},
{c:334519,d: "Rods, surveyor's, manufacturing"},
{c:334519,d: "Rules, slide, manufacturing"},
{c:334519,d: "Sample changers, nuclear radiation, manufacturing"},
{c:334519,d: "Scalers, nuclear radiation, manufacturing"},
{c:334519,d: "Scintillation detectors manufacturing"},
{c:334519,d: "Seismographs manufacturing"},
{c:334519,d: "Seismometers manufacturing"},
{c:334519,d: "Seismoscopes manufacturing"},
{c:334519,d: "Sextants, surveying, manufacturing"},
{c:334519,d: "Solarimeters manufacturing"},
{c:334519,d: "Spectrometers (e.g., liquid scintillation, nuclear) manufacturing"},
{c:334519,d: "Springs, clock and watch, made from purchased wire"},
{c:334519,d: "Surveying instruments manufacturing"},
{c:334519,d: "Tapes, surveyor's, manufacturing"},
{c:334519,d: "Templates, drafting, manufacturing"},
{c:334519,d: "Tensile strength testing equipment manufacturing"},
{c:334519,d: "Testers for checking hydraulic controls on aircraft manufacturing"},
{c:334519,d: "Testing equipment (e.g., abrasion, shearing strength, tensile strength, torsion) manufacturing"},
{c:334519,d: "Theodolites, surveying, manufacturing"},
{c:334519,d: "Thermocouples (except industrial process, aircraft type, glass vacuum) manufacturing"},
{c:334519,d: "Thermometer, liquid-in-glass and bimetal types (except medical), manufacturing"},
{c:334519,d: "Thickness gauging instruments, ultrasonic, manufacturing"},
{c:334519,d: "Thrust power indicators, aircraft engine, manufacturing"},
{c:334519,d: "Time clocks and time recording devices manufacturing"},
{c:334519,d: "Time locks manufacturing"},
{c:334519,d: "Time stamps containing clock mechanisms manufacturing"},
{c:334519,d: "Timers for industrial use, clockwork mechanism, manufacturing"},
{c:334519,d: "Timing mechanisms, clockwork, manufacturing"},
{c:334519,d: "Torsion testing equipment manufacturing"},
{c:334519,d: "Transducers, pressure, manufacturing"},
{c:334519,d: "Transits, surveying, manufacturing"},
{c:334519,d: "T-squares (drafting) manufacturing"},
{c:334519,d: "Ultrasonic testing equipment (except medical) manufacturing"},
{c:334519,d: "Vibration meters, analyzers, and calibrators, manufacturing"},
{c:334519,d: "Viscosimeters (except industrial process type) manufacturing"},
{c:334519,d: "Wall clocks manufacturing"},
{c:334519,d: "Watch jewels manufacturing"},
{c:334519,d: "Watchcase manufacturing"},
{c:334519,d: "Watches and parts (except crystals) manufacturing"},
{c:334519,d: "Water leak detectors manufacturing"},
{c:334519,d: "Weather tracking equipment manufacturing"},
{c:334519,d: "Whole body counters, nuclear, manufacturing"},
{c:334519,d: "Wind direction indicators manufacturing"},
{c:334613,d: "Audiotape, blank, manufacturing"},
{c:334613,d: "Blank tapes, audio and video, manufacturing"},
{c:334613,d: "Compact discs, recordable or rewritable, blank, manufacturing"},
{c:334613,d: "Computer software tapes and disks, blank, rigid and floppy, manufacturing"},
{c:334613,d: "Diskettes, blank, manufacturing"},
{c:334613,d: "Hard drive media manufacturing"},
{c:334613,d: "Head cleaners for magnetic tape equipment, manufacturing"},
{c:334613,d: "Magnetic and optical media, blank, manufacturing"},
{c:334613,d: "Magnetic recording media for tapes, cassettes, and disks, manufacturing"},
{c:334613,d: "Magnetic tapes, cassettes and disks, blank, manufacturing"},
{c:334613,d: "Tapes, magnetic recording (i.e., audio, data, video), blank, manufacturing"},
{c:334613,d: "Video cassettes, blank, manufacturing"},
{c:334613,d: "Video tapes, blank, manufacturing"},
{c:334614,d: "Cassette tapes, prerecorded audio, mass reproducing"},
{c:334614,d: "CD-ROM, software, mass reproducing"},
{c:334614,d: "Compact discs (i.e., CD-ROM), software, mass reproducing"},
{c:334614,d: "Compact discs, prerecorded audio, mass reproducing"},
{c:334614,d: "Game cartridge software, mass reproducing"},
{c:334614,d: "Games, computer software, mass reproducing"},
{c:334614,d: "Laser disks, prerecorded video, mass reproducing"},
{c:334614,d: "Phonograph records manufacturing"},
{c:334614,d: "Prepackaged software, mass reproducing"},
{c:334614,d: "Prerecorded magnetic audio tapes and cassettes mass reproducing"},
{c:334614,d: "Software, packaged, mass reproducing"},
{c:334614,d: "Video cassettes, prerecorded, mass reproducing"},
{c:334614,d: "Video tape or disk mass reproducing"},
{c:335110,d: "Automotive light bulbs manufacturing"},
{c:335110,d: "Bulbs, electric light, complete, manufacturing"},
{c:335110,d: "Coating purchased light bulbs"},
{c:335110,d: "Cold cathode fluorescent lamp tubes manufacturing"},
{c:335110,d: "Compact fluorescent light bulbs manufacturing"},
{c:335110,d: "Decorative lamp bulbs manufacturing"},
{c:335110,d: "Electric lamp bulb parts (except glass blanks) manufacturing"},
{c:335110,d: "Electric lamps (i.e., light bulbs) manufacturing"},
{c:335110,d: "Electric light bulbs, complete, manufacturing"},
{c:335110,d: "Electrodes, cold cathode fluorescent lamp, manufacturing"},
{c:335110,d: "Electrotherapeutic lamp bulbs for ultraviolet and infrared radiation manufacturing"},
{c:335110,d: "Filaments for electric lamp bulbs manufacturing"},
{c:335110,d: "Flash bulbs, photographic, manufacturing"},
{c:335110,d: "Flashlight bulb manufacturing"},
{c:335110,d: "Fluorescent lamp electrodes, cold cathode, manufacturing"},
{c:335110,d: "Fluorescent lamp tubes, electric, manufacturing"},
{c:335110,d: "Glow lamp bulbs manufacturing"},
{c:335110,d: "Halogen light bulbs manufacturing"},
{c:335110,d: "Health lamp bulbs, infrared and ultraviolet radiation, manufacturing"},
{c:335110,d: "High intensity lamp bulbs manufacturing"},
{c:335110,d: "Incandescent filament lamp bulbs, complete, manufacturing"},
{c:335110,d: "Infrared lamp bulbs manufacturing"},
{c:335110,d: "Lamp bulb parts (except glass blanks), electric, manufacturing"},
{c:335110,d: "Lamp bulbs and tubes, electric (i.e., fluorescent, incandescent filament, vapor), manufacturing"},
{c:335110,d: "Lamp bulbs and tubes, health, infrared and ultraviolet radiation, manufacturing"},
{c:335110,d: "Lead-in wires, electric lamp, made from purchased wire"},
{c:335110,d: "Light bulbs manufacturing"},
{c:335110,d: "Light bulbs, sealed beam automotive, manufacturing"},
{c:335110,d: "Mercury halide lamp bulbs manufacturing"},
{c:335110,d: "Photoflash and photoflood lamp bulbs and tubes manufacturing"},
{c:335110,d: "Sealed beam automotive light bulbs manufacturing"},
{c:335110,d: "Sodium vapor lamp bulbs manufacturing"},
{c:335110,d: "Strobotrons manufacturing"},
{c:335110,d: "Ultraviolet lamp bulbs manufacturing"},
{c:335110,d: "Vapor lamps, electric, manufacturing"},
{c:335110,d: "X-mas tree light bulbs manufacturing"},
{c:335121,d: "Boudoir lamp fixtures manufacturing"},
{c:335121,d: "Ceiling lighting fixtures, residential, manufacturing"},
{c:335121,d: "Chandeliers, residential, manufacturing"},
{c:335121,d: "Decorative area lighting fixtures, residential, manufacturing"},
{c:335121,d: "Desk lamps, residential, electric, manufacturing"},
{c:335121,d: "Floor lamps (i.e., lighting fixtures), residential, manufacturing"},
{c:335121,d: "Fluorescent lighting fixtures, residential, manufacturing"},
{c:335121,d: "Grow light fixtures, residential, electric, manufacturing"},
{c:335121,d: "Lamp shades (except glass, plastics), residential, manufacturing"},
{c:335121,d: "Lamps (i.e., lighting fixtures), residential, electric, manufacturing"},
{c:335121,d: "Lighting fixtures, residential electric, manufacturing"},
{c:335121,d: "Low voltage lighting equipment, residential, electric, manufacturing"},
{c:335121,d: "Pendant lamps fixtures, residential electric, manufacturing"},
{c:335121,d: "Recessed lighting housings and trim, residential electric, manufacturing"},
{c:335121,d: "Shades, lamp (except glass, plastics), residential-type, manufacturing"},
{c:335121,d: "Solar lighting fixtures, residential, electric, manufacturing"},
{c:335121,d: "Table lamps (i.e., lighting fixtures) manufacturing"},
{c:335121,d: "Track lighting fixtures and equipment, residential, electric, manufacturing"},
{c:335121,d: "Wall lamps (i.e., lighting fixtures), residential electric, manufacturing"},
{c:335121,d: "Yard lights, residential electric, manufacturing"},
{c:335122,d: "Ceiling lighting fixtures, commercial, industrial, and institutional, manufacturing"},
{c:335122,d: "Chandeliers, commercial, industrial, and institutional electric, manufacturing"},
{c:335122,d: "Commercial lighting fixtures, electric, manufacturing"},
{c:335122,d: "Desk lamps, commercial, electric, manufacturing"},
{c:335122,d: "Emergency lighting (i.e., battery backup) manufacturing"},
{c:335122,d: "Fluorescent lighting fixtures, commercial, institutional, and industrial electric, manufacturing"},
{c:335122,d: "Grow light fixtures (except residential) manufacturing"},
{c:335122,d: "Illuminated indoor lighting fixtures (e.g., directional, exit) manufacturing"},
{c:335122,d: "Industrial lighting fixtures, electric, manufacturing"},
{c:335122,d: "Industrial mercury lighting fixtures, electric, manufacturing"},
{c:335122,d: "Institutional lighting fixtures, electric, manufacturing"},
{c:335122,d: "Lamps (i.e., lighting fixtures), commercial, industrial, and institutional, manufacturing"},
{c:335122,d: "Lighting fixtures, commercial electric, manufacturing"},
{c:335122,d: "Lighting fixtures, industrial electric, manufacturing"},
{c:335122,d: "Lighting fixtures, institutional electric, manufacturing"},
{c:335122,d: "Luminous panel ceilings, electric, manufacturing"},
{c:335122,d: "Pendant lamps (except residential), electric, manufacturing"},
{c:335122,d: "Recessed lighting housings and trim (except residential), electric, manufacturing"},
{c:335122,d: "Solar lighting fixtures (except residential), electric, manufacturing"},
{c:335122,d: "Wall lamps (i.e., lighting fixtures), commercial, institutional, and industrial electric, manufacturing"},
{c:335129,d: "Arc lighting fixtures (except electrotherapeutic), electric, manufacturing"},
{c:335129,d: "Area and sports luminaries (e.g., stadium lighting fixtures), electric, manufacturing"},
{c:335129,d: "Christmas tree lighting sets, electric, manufacturing"},
{c:335129,d: "Decorative area lighting fixtures (except residential) manufacturing"},
{c:335129,d: "Fireplace logs, electric, manufacturing"},
{c:335129,d: "Flashlights manufacturing"},
{c:335129,d: "Floodlights (i.e., lighting fixtures) manufacturing"},
{c:335129,d: "Flytraps, electrical, manufacturing"},
{c:335129,d: "Fountain lighting fixtures manufacturing"},
{c:335129,d: "Gas lighting fixtures manufacturing"},
{c:335129,d: "Infrared lamp fixtures manufacturing"},
{c:335129,d: "Insect lamps, electric, manufacturing"},
{c:335129,d: "Lamps, insect, electric fixture, manufacturing"},
{c:335129,d: "Lanterns (e.g., carbide, electric, gas, gasoline, kerosene) manufacturing"},
{c:335129,d: "Lighting fixtures, airport (e.g., approach, ramp, runway, taxi), manufacturing"},
{c:335129,d: "Lighting fixtures, nonelectric (e.g., propane, kerosene, carbide), manufacturing"},
{c:335129,d: "Miner's lamps manufacturing"},
{c:335129,d: "Ornaments, Christmas tree, electric, manufacturing"},
{c:335129,d: "Prewired poles, brackets, and accessories for electric lighting, manufacturing"},
{c:335129,d: "Reflectors for lighting equipment, metal, manufacturing"},
{c:335129,d: "Searchlights, electric and nonelectric, manufacturing"},
{c:335129,d: "Spotlights (except vehicular) manufacturing"},
{c:335129,d: "Stage lighting equipment manufacturing"},
{c:335129,d: "Street lighting fixtures (except traffic signals) manufacturing"},
{c:335129,d: "Swimming pool lighting fixtures manufacturing"},
{c:335129,d: "Trouble lights manufacturing"},
{c:335129,d: "Ultraviolet lamp fixtures manufacturing"},
{c:335129,d: "Underwater lighting fixtures manufacturing"},
{c:335210,d: "Air purification equipment, portable, manufacturing"},
{c:335210,d: "Bath fans with integral lighting fixture, residential, manufacturing"},
{c:335210,d: "Bath fans, residential, manufacturing"},
{c:335210,d: "Bedcoverings, electric, manufacturing"},
{c:335210,d: "Blankets, electric, manufacturing"},
{c:335210,d: "Blenders, household-type electric, manufacturing"},
{c:335210,d: "Blow dryers, household-type electric, manufacturing"},
{c:335210,d: "Bottle warmers, household-type electric, manufacturing"},
{c:335210,d: "Bread machines, household-type electric, manufacturing"},
{c:335210,d: "Can openers, household-type electric, manufacturing"},
{c:335210,d: "Carpet and floor cleaning equipment, household-type electric, manufacturing"},
{c:335210,d: "Casseroles, household-type electric, manufacturing"},
{c:335210,d: "Ceiling fans with integral lighting fixture, residential, manufacturing"},
{c:335210,d: "Ceiling fans, residential, manufacturing"},
{c:335210,d: "Central vacuuming systems, household-type, manufacturing"},
{c:335210,d: "Chafing dishes, household-type electric, manufacturing"},
{c:335210,d: "Cleaners, household-type electric vacuum, manufacturing"},
{c:335210,d: "Coffee makers, household-type electric, manufacturing"},
{c:335210,d: "Cooking appliances (except convection, microwave ovens), household-type electric portable, manufacturing"},
{c:335210,d: "Corn poppers, household-type electric, manufacturing"},
{c:335210,d: "Crock pots, household-type electric, manufacturing"},
{c:335210,d: "Curling irons, household-type electric, manufacturing"},
{c:335210,d: "Deep-fat fryers, household-type electric, manufacturing"},
{c:335210,d: "Dehumidifiers, portable electric, manufacturing"},
{c:335210,d: "Desk fans, electric, manufacturing"},
{c:335210,d: "Dry shavers (i.e., electric razors) manufacturing"},
{c:335210,d: "Egg cookers, household-type electric, manufacturing"},
{c:335210,d: "Electric blankets manufacturing"},
{c:335210,d: "Electric comfort heating equipment, portable, manufacturing"},
{c:335210,d: "Electric space heaters, portable, manufacturing"},
{c:335210,d: "Electrically heated bed coverings manufacturing"},
{c:335210,d: "Fans (except attic), household-type electric, manufacturing"},
{c:335210,d: "Fans, household-type kitchen, manufacturing"},
{c:335210,d: "Floor scrubbing and shampooing machines, household-type electric, manufacturing"},
{c:335210,d: "Floor standing fans, household-type electric, manufacturing"},
{c:335210,d: "Floor waxers and polishers, household-type electric, manufacturing"},
{c:335210,d: "Food mixers, household-type electric, manufacturing"},
{c:335210,d: "Fryers, household-type electric, manufacturing"},
{c:335210,d: "Griddles and grills, household-type portable electric, manufacturing"},
{c:335210,d: "Hair clippers for human use, electric, manufacturing"},
{c:335210,d: "Hair curlers, household-type electric, manufacturing"},
{c:335210,d: "Hair dryers, electric (except equipment designed for beauty parlor use), manufacturing"},
{c:335210,d: "Hassock fans, electric, manufacturing"},
{c:335210,d: "Heaters, portable electric space, manufacturing"},
{c:335210,d: "Heaters, tape, manufacturing"},
{c:335210,d: "Heating pads, electric, manufacturing"},
{c:335210,d: "Heating units for electric appliances manufacturing"},
{c:335210,d: "Hoods, range, household-type, manufacturing"},
{c:335210,d: "Hotplates, household-type electric, manufacturing"},
{c:335210,d: "Humidifiers, portable electric, manufacturing"},
{c:335210,d: "Ice crushers, household-type electric, manufacturing"},
{c:335210,d: "Immersion heaters, household-type electric, manufacturing"},
{c:335210,d: "Irons, household-type electric, manufacturing"},
{c:335210,d: "Juice extractors, household-type electric, manufacturing"},
{c:335210,d: "Knives, household-type electric carving, manufacturing"},
{c:335210,d: "Massage machines, electric (except designed for beauty and barber shop use), manufacturing"},
{c:335210,d: "Ovens, portable household-type (except microwave and convection ovens), manufacturing"},
{c:335210,d: "Percolators, household-type electric, manufacturing"},
{c:335210,d: "Popcorn poppers, household-type electric, manufacturing"},
{c:335210,d: "Portable cooking appliances (except convection, microwave ovens), household-type electric, manufacturing"},
{c:335210,d: "Portable electric space heaters manufacturing"},
{c:335210,d: "Portable hair dryers, electric, manufacturing"},
{c:335210,d: "Portable humidifiers and dehumidifiers manufacturing"},
{c:335210,d: "Radiators, portable electric, manufacturing"},
{c:335210,d: "Range hoods with integral lighting fixtures, household-type, manufacturing"},
{c:335210,d: "Range hoods, household-type, manufacturing"},
{c:335210,d: "Razors, electric, manufacturing"},
{c:335210,d: "Roasters (i.e., cooking appliances), household-type electric, manufacturing"},
{c:335210,d: "Room heaters, portable electric, manufacturing"},
{c:335210,d: "Sandwich toasters and grills, household-type electric, manufacturing"},
{c:335210,d: "Sauna heaters, electric, manufacturing"},
{c:335210,d: "Scissors, electric, manufacturing"},
{c:335210,d: "Shoe polishers, household-type electric, manufacturing"},
{c:335210,d: "Steam cookers, household-type, manufacturing"},
{c:335210,d: "Sweepers, household-type electric vacuum, manufacturing"},
{c:335210,d: "Teakettles, electric, manufacturing"},
{c:335210,d: "Toaster ovens, household-type electric, manufacturing"},
{c:335210,d: "Toasters, household-type electric, manufacturing"},
{c:335210,d: "Toothbrushes, electric, manufacturing"},
{c:335210,d: "Trouser pressers, household-type electric, manufacturing"},
{c:335210,d: "Unit heaters, portable electric, manufacturing"},
{c:335210,d: "Urns, household-type electric, manufacturing"},
{c:335210,d: "Vacuum cleaners (e.g., canister, handheld, upright) household-type electric, manufacturing"},
{c:335210,d: "Vacuum cleaners and sweepers, household-type electric, manufacturing"},
{c:335210,d: "Vaporizers, household-type electric, manufacturing"},
{c:335210,d: "Ventilating kitchen fans, household-type electric, manufacturing"},
{c:335210,d: "Ventilation and exhaust fans (except attic fans), household-type, manufacturing"},
{c:335210,d: "Waffle irons, household-type electric, manufacturing"},
{c:335210,d: "Warming trays, electric, manufacturing"},
{c:335210,d: "Water pulsating devices, household-type electric, manufacturing"},
{c:335210,d: "Whippers, household-type electric, manufacturing"},
{c:335220,d: "Barbecues, grills, and braziers manufacturing"},
{c:335220,d: "Braziers, barbecue, manufacturing"},
{c:335220,d: "Convection ovens (including portable), household-type, manufacturing"},
{c:335220,d: "Dishwashers, household-type, manufacturing"},
{c:335220,d: "Dishwashing machines, household-type, manufacturing"},
{c:335220,d: "Drycleaning and laundry machines, household-type, manufacturing"},
{c:335220,d: "Dryers, clothes, household-type, gas and electric, manufacturing"},
{c:335220,d: "Dryers, household-type laundry, manufacturing"},
{c:335220,d: "Food waste disposal units, household-type, manufacturing"},
{c:335220,d: "Freezers, chest and upright household-type, manufacturing"},
{c:335220,d: "Garbage disposal units, household-type, manufacturing"},
{c:335220,d: "Gas ranges, household-type, manufacturing"},
{c:335220,d: "Hot water heaters (including nonelectric), household-type, manufacturing"},
{c:335220,d: "Ice boxes, household-type, manufacturing"},
{c:335220,d: "Ironers and mangles, household-type (except portable irons), manufacturing"},
{c:335220,d: "Laundry equipment (e.g., dryers, washers), household-type, manufacturing"},
{c:335220,d: "Microwave ovens (including portable), household-type, manufacturing"},
{c:335220,d: "Ovens, freestanding household-type, manufacturing"},
{c:335220,d: "Ovens, portable household-type convection and microwave, manufacturing"},
{c:335220,d: "Ranges, household-type cooking, manufacturing"},
{c:335220,d: "Refrigerator/freezer combinations, household-type, manufacturing"},
{c:335220,d: "Refrigerators (e.g., absorption, mechanical), household-type, manufacturing"},
{c:335220,d: "Stoves, ceramic disk element, household-type, manufacturing"},
{c:335220,d: "Stoves, household-type cooking, manufacturing"},
{c:335220,d: "Trash and garbage compactors, household-type, manufacturing"},
{c:335220,d: "Washing machines, household-type, manufacturing"},
{c:335220,d: "Water heaters (including nonelectric), household-type, manufacturing"},
{c:335311,d: "Airport lighting transformers manufacturing"},
{c:335311,d: "Arc-welding transformers, separate solid-state, manufacturing"},
{c:335311,d: "Autotransformers for switchboards (except telephone switchboards) manufacturing"},
{c:335311,d: "Autotransformers manufacturing"},
{c:335311,d: "Ballasts (i.e., transformers) manufacturing"},
{c:335311,d: "Boosters, feeder voltage (i.e., electrical transformers), manufacturing"},
{c:335311,d: "Burner ignition transformers manufacturing"},
{c:335311,d: "Control transformers manufacturing"},
{c:335311,d: "Current limiting reactors, electrical, manufacturing"},
{c:335311,d: "Distribution transformers, electric, manufacturing"},
{c:335311,d: "Electric furnace transformers manufacturing"},
{c:335311,d: "Feeder voltage regulators and boosters (i.e., electrical transformers) manufacturing"},
{c:335311,d: "Fluorescent ballasts (i.e., transformers) manufacturing"},
{c:335311,d: "Fluorescent lighting transformers manufacturing"},
{c:335311,d: "Generator voltage regulators, electric induction and step-type (except engine electrical equipment), manufacturing"},
{c:335311,d: "Instrument transformers (except complete instruments) for metering or protective relaying use manufacturing"},
{c:335311,d: "Isolation transformers manufacturing"},
{c:335311,d: "Lamp ballasts manufacturing"},
{c:335311,d: "Lighting transformers manufacturing"},
{c:335311,d: "Lighting transformers, street and airport, manufacturing"},
{c:335311,d: "Line voltage regulators (i.e., electric transformers) manufacturing"},
{c:335311,d: "Luminous tube transformers manufacturing"},
{c:335311,d: "Machine tool transformers manufacturing"},
{c:335311,d: "Power transformers, electric, manufacturing"},
{c:335311,d: "Regulating transformers, power system-type, manufacturing"},
{c:335311,d: "Regulators (i.e., electric transformers), feeder voltage, manufacturing"},
{c:335311,d: "Saturable transformers manufacturing"},
{c:335311,d: "Signaling transformers, electric, manufacturing"},
{c:335311,d: "Specialty transformers, electric, manufacturing"},
{c:335311,d: "Substation transformers, electric power distribution, manufacturing"},
{c:335311,d: "Transformers, electric power, manufacturing"},
{c:335311,d: "Transformers, ignition, for use on domestic fuel burners, manufacturing"},
{c:335311,d: "Transformers, reactor, manufacturing"},
{c:335311,d: "Transformers, separate solid-state arc-welding, manufacturing"},
{c:335311,d: "Transmission and distribution voltage regulators manufacturing"},
{c:335311,d: "Voltage regulating transformers, electric power, manufacturing"},
{c:335311,d: "Voltage regulators, transmission and distribution, manufacturing"},
{c:335312,d: "Armature rewinding on a factory basis"},
{c:335312,d: "Armatures, industrial, manufacturing"},
{c:335312,d: "Coils for motors and generators manufacturing"},
{c:335312,d: "Collector rings for motors and generators manufacturing"},
{c:335312,d: "Commutators, electric motor, manufacturing"},
{c:335312,d: "Condensers, synchronous, electric, manufacturing"},
{c:335312,d: "Converters, phase and rotary, electrical equipment, manufacturing"},
{c:335312,d: "Dynamos, electric (except automotive), manufacturing"},
{c:335312,d: "Dynamotors manufacturing"},
{c:335312,d: "Exciter assemblies, motor and generator, manufacturing"},
{c:335312,d: "Fractional horsepower electric motors manufacturing"},
{c:335312,d: "Frequency converters (i.e., electric generators) manufacturing"},
{c:335312,d: "Generating apparatus and parts, electrical (except internal combustion engine and welding), manufacturing"},
{c:335312,d: "Generator sets, prime mover (except turbine generator sets), manufacturing"},
{c:335312,d: "Generators and sets, electric (except internal combustion engine, welding, turbine generator sets), manufacturing"},
{c:335312,d: "Generators for gas-electric and oil-electric vehicles, manufacturing"},
{c:335312,d: "Generators for storage battery chargers (except internal combustion engine and aircraft) manufacturing"},
{c:335312,d: "Ground Power Units (GPU) manufacturing"},
{c:335312,d: "Integral horsepower electric motors manufacturing"},
{c:335312,d: "Inverters, rotating electrical, manufacturing"},
{c:335312,d: "Land transportation motors and generators manufacturing"},
{c:335312,d: "Motor generator sets (except automotive, turbine generator sets) manufacturing"},
{c:335312,d: "Motors, electric (except engine starting motors, gearmotors, outboard), manufacturing"},
{c:335312,d: "Phase converters (i.e., electrical equipment) manufacturing"},
{c:335312,d: "Power generators manufacturing"},
{c:335312,d: "Prime mover generator sets (except turbine generator sets) manufacturing"},
{c:335312,d: "Railway motors and control equipment, electric, manufacturing"},
{c:335312,d: "Resolvers manufacturing"},
{c:335312,d: "Rotor retainers and housings manufacturing"},
{c:335312,d: "Rotors (i.e., for motors) manufacturing"},
{c:335312,d: "Servomotors manufacturing"},
{c:335312,d: "Slip rings for motors and generators manufacturing"},
{c:335312,d: "Stators for motors manufacturing"},
{c:335312,d: "Storage battery chargers (except internal combustion engine-type) manufacturing"},
{c:335312,d: "Synchronous condensers and timing motors, electric, manufacturing"},
{c:335312,d: "Synchronous motors manufacturing"},
{c:335312,d: "Timing motors, synchronous, electric, manufacturing"},
{c:335312,d: "Torque motors, electric, manufacturing"},
{c:335313,d: "Air circuit breakers manufacturing"},
{c:335313,d: "Bus bar structures, switchgear-type, manufacturing"},
{c:335313,d: "Circuit breakers, air, manufacturing"},
{c:335313,d: "Circuit breakers, power, manufacturing"},
{c:335313,d: "Connectors, power, manufacturing"},
{c:335313,d: "Control panels, electric power distribution, manufacturing"},
{c:335313,d: "Cubicles (i.e., electric switchboard equipment) manufacturing"},
{c:335313,d: "Distribution boards, electric, manufacturing"},
{c:335313,d: "Distribution cutouts manufacturing"},
{c:335313,d: "Ducts for electrical switchboard apparatus manufacturing"},
{c:335313,d: "Fuse clips and blocks, electric, manufacturing"},
{c:335313,d: "Fuse mountings, electric power, manufacturing"},
{c:335313,d: "Fuses, electrical, manufacturing"},
{c:335313,d: "Generator control and metering panels, switchgear-type, manufacturing"},
{c:335313,d: "Knife switches, electric power switchgear-type, manufacturing"},
{c:335313,d: "Metering panels, electric, manufacturing"},
{c:335313,d: "Panelboards, electric power distribution, manufacturing"},
{c:335313,d: "Panels, generator control and metering, manufacturing"},
{c:335313,d: "Power circuit breakers manufacturing"},
{c:335313,d: "Power connectors manufacturing"},
{c:335313,d: "Power fuses (i.e., 600 volts and over) manufacturing"},
{c:335313,d: "Power switchboards manufacturing"},
{c:335313,d: "Power switching equipment manufacturing"},
{c:335313,d: "Regulators, power, manufacturing"},
{c:335313,d: "Switchboards and parts, power, manufacturing"},
{c:335313,d: "Switches, electric power (except pushbutton, snap, solenoid, tumbler), manufacturing"},
{c:335313,d: "Switchgear and switchgear accessories manufacturing"},
{c:335313,d: "Switching equipment, power, manufacturing"},
{c:335313,d: "Time switches, electrical switchgear apparatus, manufacturing"},
{c:335314,d: "Armature relays manufacturing"},
{c:335314,d: "Brakes and clutches, electromagnetic, manufacturing"},
{c:335314,d: "Brakes, electromagnetic, manufacturing"},
{c:335314,d: "Control circuit devices, magnet and solid-state, manufacturing"},
{c:335314,d: "Control circuit relays, industrial, manufacturing"},
{c:335314,d: "Control equipment, electric, manufacturing"},
{c:335314,d: "Controls and control accessories, industrial, manufacturing"},
{c:335314,d: "Controls for adjustable speed drives manufacturing"},
{c:335314,d: "Crane and hoist controls, including metal mill, manufacturing"},
{c:335314,d: "Flow actuated electrical switches manufacturing"},
{c:335314,d: "Industrial controls (e.g., pushbutton, selector, and pilot switches) manufacturing"},
{c:335314,d: "Marine and navy auxiliary controls, manufacturing"},
{c:335314,d: "Motor control accessories (including overload relays) manufacturing"},
{c:335314,d: "Motor control centers, manufacturing"},
{c:335314,d: "Motor controls, electric, manufacturing"},
{c:335314,d: "Motor starters, contractors, and controllers, industrial, manufacturing"},
{c:335314,d: "Numerical controls, manufacturing"},
{c:335314,d: "Relays, electrical and electronic, manufacturing"},
{c:335314,d: "Rheostats, industrial control, manufacturing"},
{c:335314,d: "Solenoid switches, industrial, manufacturing"},
{c:335314,d: "Timing devices, mechanical and solid-state (except clockwork), manufacturing"},
{c:335314,d: "Vacuum relays manufacturing"},
{c:335911,d: "Alkaline cell storage batteries (i.e., nickel-cadmium, nickel-iron, silver oxide-zinc) manufacturing"},
{c:335911,d: "Automobile storage batteries manufacturing"},
{c:335911,d: "Batteries, rechargeable, manufacturing"},
{c:335911,d: "Batteries, storage, manufacturing"},
{c:335911,d: "Lead acid storage batteries manufacturing"},
{c:335911,d: "Lithium batteries, storage, manufacturing"},
{c:335911,d: "Marine storage batteries manufacturing"},
{c:335911,d: "Nickel-cadmium storage batteries manufacturing"},
{c:335911,d: "Rechargeable battery packs made from purchased battery cells and housings"},
{c:335911,d: "Rechargeable nickel-cadmium (NICAD) batteries manufacturing"},
{c:335911,d: "Storage batteries manufacturing"},
{c:335912,d: "Alkaline cell primary batteries manufacturing"},
{c:335912,d: "Alkaline manganese primary batteries manufacturing"},
{c:335912,d: "Batteries, primary, dry or wet, manufacturing"},
{c:335912,d: "Button cells, primary batteries, manufacturing"},
{c:335912,d: "Disposable flashlight batteries manufacturing"},
{c:335912,d: "Dry cell primary batteries, single and multiple cell, manufacturing"},
{c:335912,d: "Dry cells, primary (e.g., AAA, AA, C, D, 9V), manufacturing"},
{c:335912,d: "Flashlight batteries, disposable, manufacturing"},
{c:335912,d: "Lithium batteries, primary, manufacturing"},
{c:335912,d: "Mercuric oxide batteries manufacturing"},
{c:335912,d: "Primary batteries manufacturing"},
{c:335912,d: "Transistor radio batteries manufacturing"},
{c:335912,d: "Watch batteries manufacturing"},
{c:335912,d: "Zinc carbon batteries manufacturing"},
{c:335921,d: "Fiber optic cable made from purchased fiber optic strand"},
{c:335929,d: "Cable, nonferrous, insulated, or armored, made from purchased nonferrous wire"},
{c:335929,d: "Coaxial cable, nonferrous, made from purchased nonferrous wire"},
{c:335929,d: "Communications wire and cable, nonferrous, made from purchased nonferrous wire"},
{c:335929,d: "Insulating purchased nonferrous wire"},
{c:335931,d: "Arrestors and coils, lighting, manufacturing"},
{c:335931,d: "Automotive electrical switches manufacturing"},
{c:335931,d: "Bus bars, electrical conductors (except switchgear-type), manufacturing"},
{c:335931,d: "Caps and plugs, attachment, electric, manufacturing"},
{c:335931,d: "Conductor connectors, solderless connectors, sleeves, or soldering lugs, manufacturing"},
{c:335931,d: "Connectors and terminals for electrical devices manufacturing"},
{c:335931,d: "Connectors, electric cord, manufacturing"},
{c:335931,d: "Connectors, solderless (wiring devices), manufacturing"},
{c:335931,d: "Connectors, twist on wire (i.e., nuts), manufacturing"},
{c:335931,d: "Contacts, electrical (except carbon and graphite), manufacturing"},
{c:335931,d: "Convenience outlets, electric, manufacturing"},
{c:335931,d: "Cord connectors, electric, manufacturing"},
{c:335931,d: "Current taps, attachment plug and screw shell types, manufacturing"},
{c:335931,d: "Cutouts, switch and fuse, manufacturing"},
{c:335931,d: "Dimmer switches, outlet box mounting-type, manufacturing"},
{c:335931,d: "Duplex receptacles, electrical, manufacturing"},
{c:335931,d: "Fuse cutouts manufacturing"},
{c:335931,d: "GFCI (ground fault circuit interrupters) manufacturing"},
{c:335931,d: "Ground clamps (i.e., electric wiring devices) manufacturing"},
{c:335931,d: "Ground fault circuit interrupters (GFCI) manufacturing"},
{c:335931,d: "Lamp holders manufacturing"},
{c:335931,d: "Lamp sockets and receptacles (i.e., electric wiring devices) manufacturing"},
{c:335931,d: "Lightning arrestors and coils manufacturing"},
{c:335931,d: "Lightning protection equipment manufacturing"},
{c:335931,d: "Lugs and connectors, electrical, manufacturing"},
{c:335931,d: "Outlets (i.e., receptacles), electrical, manufacturing"},
{c:335931,d: "Outlets, convenience, electrical, manufacturing"},
{c:335931,d: "Plugs, electric cord, manufacturing"},
{c:335931,d: "Rail bonds, propulsion and signal circuit electric, manufacturing"},
{c:335931,d: "Receptacles (i.e., outlets), electrical, manufacturing"},
{c:335931,d: "Rheostats (i.e., dimmer switches), current-carrying wiring device, manufacturing"},
{c:335931,d: "Snap switches (i.e., electric wiring devices) manufacturing"},
{c:335931,d: "Sockets, electric, manufacturing"},
{c:335931,d: "Solderless connectors (electric wiring devices) manufacturing"},
{c:335931,d: "Switch cutouts manufacturing"},
{c:335931,d: "Switches for electrical wiring (e.g., pressure, pushbutton, snap, tumbler) manufacturing"},
{c:335931,d: "Switches, outlet box mounting-type, manufacturing"},
{c:335931,d: "Taps, current, attachment plug and screw shell types, manufacturing"},
{c:335931,d: "Terminals and connectors for electrical devices manufacturing"},
{c:335932,d: "Boxes, electrical wiring (e.g., junction, outlet, switch), manufacturing"},
{c:335932,d: "Conduits and fittings, electrical, manufacturing"},
{c:335932,d: "Electrical metallic tube (EMTs) manufacturing"},
{c:335932,d: "EMTs (electrical metallic tube) manufacturing"},
{c:335932,d: "Face plates (i.e., outlet or switch covers) manufacturing"},
{c:335932,d: "Hardware, transmission pole and line, manufacturing"},
{c:335932,d: "Insulators, electrical (except glass, porcelain), manufacturing"},
{c:335932,d: "Junction boxes, electrical wiring, manufacturing"},
{c:335932,d: "Outlet boxes, electrical wiring, manufacturing"},
{c:335932,d: "Plates (i.e., outlet or switch covers), face, manufacturing"},
{c:335932,d: "Raceways manufacturing"},
{c:335932,d: "Switch boxes, electrical wiring, manufacturing"},
{c:335932,d: "Transmission pole and line hardware manufacturing"},
{c:335991,d: "Brush blocks, carbon or molded graphite, manufacturing"},
{c:335991,d: "Brushes and brush stock contacts, electric, carbon and graphite, manufacturing"},
{c:335991,d: "Brushplates, carbon or graphite, manufacturing"},
{c:335991,d: "Carbon electrodes and contacts, electric, manufacturing"},
{c:335991,d: "Carbon specialties for aerospace use (except gaskets) manufacturing"},
{c:335991,d: "Carbon specialties for electrical use manufacturing"},
{c:335991,d: "Carbon specialties for mechanical use (except gaskets) manufacturing"},
{c:335991,d: "Carbons, electric, manufacturing"},
{c:335991,d: "Carbons, lighting, manufacturing"},
{c:335991,d: "Contacts, electrical, carbon and graphite, manufacturing"},
{c:335991,d: "Electrodes for thermal and electrolytic uses, carbon and graphite, manufacturing"},
{c:335991,d: "Fibers, carbon and graphite, manufacturing"},
{c:335991,d: "Graphite electrodes and contacts, electric, manufacturing"},
{c:335991,d: "Graphite specialties for aerospace use (except gaskets) manufacturing"},
{c:335991,d: "Graphite specialties for electrical use manufacturing"},
{c:335991,d: "Graphite specialties for mechanical use (except gaskets) manufacturing"},
{c:335991,d: "Heat shields, carbon or graphite, manufacturing"},
{c:335991,d: "Lighting carbons manufacturing"},
{c:335991,d: "Metal-graphite products manufacturing"},
{c:335999,d: "Amplifiers, magnetic, pulse, and maser, manufacturing"},
{c:335999,d: "Appliance cords made from purchased insulated wire"},
{c:335999,d: "Atom smashers (i.e., particle accelerators) manufacturing"},
{c:335999,d: "Battery chargers, solid-state, manufacturing"},
{c:335999,d: "Bells, electric, manufacturing"},
{c:335999,d: "Betatrons manufacturing"},
{c:335999,d: "Capacitors (except electronic), fixed and variable, manufacturing"},
{c:335999,d: "Cathodic protection equipment manufacturing"},
{c:335999,d: "Chimes, electric, manufacturing"},
{c:335999,d: "Cleaning equipment, ultrasonic (except dental, medical), manufacturing"},
{c:335999,d: "Condensers (except electronic), fixed and variable, manufacturing"},
{c:335999,d: "Cyclotrons manufacturing"},
{c:335999,d: "Door opening and closing devices, electrical, manufacturing"},
{c:335999,d: "Electric bells manufacturing"},
{c:335999,d: "Electric fence chargers manufacturing"},
{c:335999,d: "Electrochemical generators (i.e., fuel cells) manufacturing"},
{c:335999,d: "Electron linear accelerators manufacturing"},
{c:335999,d: "Electrostatic particle accelerators manufacturing"},
{c:335999,d: "Extension cords made from purchased insulated wire"},
{c:335999,d: "Fuel cells, electrochemical generators, manufacturing"},
{c:335999,d: "Garage door openers manufacturing"},
{c:335999,d: "Gongs, electric, manufacturing"},
{c:335999,d: "Inverters, solid-state, manufacturing"},
{c:335999,d: "Linear accelerators manufacturing"},
{c:335999,d: "Maser (i.e., microwave amplification by stimulated emission of radiation) amplifiers manufacturing"},
{c:335999,d: "Mercury arc rectifiers (i.e., electrical apparatus) manufacturing"},
{c:335999,d: "Particle accelerators, high-voltage, manufacturing"},
{c:335999,d: "Photovoltaic panels made from purchased cells"},
{c:335999,d: "Power converter units (i.e., AC to DC), static, manufacturing"},
{c:335999,d: "Power supplies, regulated and unregulated, manufacturing"},
{c:335999,d: "Rectifiers (except electronic component-type, semiconductor) manufacturing"},
{c:335999,d: "Semiconductor battery chargers manufacturing"},
{c:335999,d: "Semiconductor high-voltage power supplies manufacturing"},
{c:335999,d: "Series capacitors (except electronic) manufacturing"},
{c:335999,d: "Surge suppressors manufacturing"},
{c:335999,d: "Thermoelectric generators manufacturing"},
{c:335999,d: "Ultrasonic cleaning equipment (except dental, medical) manufacturing"},
{c:335999,d: "Ultrasonic generators sold separately for inclusion in tools and equipment manufacturing"},
{c:335999,d: "Uninterruptible power supplies (UPS) manufacturing"},
{c:335999,d: "UPS (uninterruptible power supplies) manufacturing"},
{c:336111,d: "Assembly plants, passenger car, on chassis of own manufacture"},
{c:336111,d: "Automobiles assembling on chassis of own manufacture"},
{c:336111,d: "Cars, electric, for highway use, assembling on chassis of own manufacture"},
{c:336111,d: "Chassis, automobile, manufacturing"},
{c:336111,d: "Electric automobiles for highway use manufacturing"},
{c:336111,d: "Hearses assembling on chassis of own manufacture"},
{c:336111,d: "Unmanned and robotic automobiles manufacturing"},
{c:336112,d: "Assembly plants, light trucks on chassis of own manufacture"},
{c:336112,d: "Assembly plants, minivans on chassis of own manufacture"},
{c:336112,d: "Assembly plants, sport utility vehicles on chassis of own manufacture"},
{c:336112,d: "Cab and chassis, light trucks and vans, manufacturing"},
{c:336112,d: "Chassis, light truck and utility, manufacturing"},
{c:336112,d: "Light utility trucks assembling on chassis of own manufacture"},
{c:336112,d: "Minivans assembling on chassis of own manufacture"},
{c:336112,d: "Motor homes, self-contained, mounted on light duty truck chassis of own manufacture"},
{c:336112,d: "Pick-up trucks, light duty, assembling on chassis of own manufacture"},
{c:336112,d: "Sport utility vehicles assembling on chassis of own manufacture"},
{c:336112,d: "Trucks, light duty, assembling on chassis of own manufacture"},
{c:336112,d: "Vans, commercial and passenger light duty, assembling on chassis of own manufacture"},
{c:336120,d: "Assembly plants, heavy trucks, and buses on chassis of own manufacture"},
{c:336120,d: "Buses (except trackless trolley) assembling on chassis of own manufacture"},
{c:336120,d: "Chassis, heavy truck, with or without cabs, manufacturing"},
{c:336120,d: "Garbage trucks assembling on chassis of own manufacture"},
{c:336120,d: "Heavy trucks assembling on chassis of own manufacture"},
{c:336120,d: "Highway tractors assembled on chassis of own manufacture"},
{c:336120,d: "Motor homes, self-contained, mounted on heavy truck chassis of own manufacture"},
{c:336120,d: "Special purpose highway vehicles (e.g., firefighting vehicles) assembling on heavy chassis of own manufacture"},
{c:336120,d: "Tractors, truck for highway use, assembled on chassis of own manufacture"},
{c:336120,d: "Truck tractors for highway use, assembling on chassis of own manufacture"},
{c:336120,d: "Trucks, heavy, assembling on chassis of own manufacture"},
{c:336211,d: "Ambulance bodies manufacturing"},
{c:336211,d: "Ambulances assembling on purchased chassis"},
{c:336211,d: "Automobile bodies, passenger car, manufacturing"},
{c:336211,d: "Automobile wrecker truck bodies manufacturing"},
{c:336211,d: "Automobile wreckers assembling on purchased chassis"},
{c:336211,d: "Boxes, truck (e.g., cargo, dump, utility, van), assembled on purchased chassis"},
{c:336211,d: "Bus bodies assembling on purchased chassis"},
{c:336211,d: "Bus bodies manufacturing"},
{c:336211,d: "Car bodies, kit, manufacturing"},
{c:336211,d: "Dump-truck lifting mechanisms manufacturing"},
{c:336211,d: "Fifth-wheel assemblies manufacturing"},
{c:336211,d: "Garbage truck bodies manufacturing"},
{c:336211,d: "Garbage trucks assembling on purchased chassis"},
{c:336211,d: "Hearse bodies manufacturing"},
{c:336211,d: "Hearses assembling on purchased chassis"},
{c:336211,d: "Heavy trucks assembling on purchased chassis"},
{c:336211,d: "Highway tractors assembling on purchased chassis"},
{c:336211,d: "Kit car bodies manufacturing"},
{c:336211,d: "Ready-mix concrete trucks assembling on purchased chassis"},
{c:336211,d: "School buses assembling on purchased chassis"},
{c:336211,d: "Special purpose highway vehicle (e.g., firefighting vehicles) assembling on purchased chassis"},
{c:336211,d: "Special purpose highway vehicle (e.g., firefighting vehicles) bodies manufacturing"},
{c:336211,d: "Stretch limousines assembling on purchased chassis"},
{c:336211,d: "Tank bodies for trucks manufacturing"},
{c:336211,d: "Tank trucks (e.g., fuel oil, milk, water) assembling on purchased chassis"},
{c:336211,d: "Tow trucks (including tilt and load) assembling on purchased chassis"},
{c:336211,d: "Truck bodies and cabs manufacturing"},
{c:336211,d: "Truck bodies assembling on purchased chassis"},
{c:336211,d: "Truck tractors for highway use, assembling on purchased chassis"},
{c:336212,d: "Automobile transporter trailers, multi-car, manufacturing"},
{c:336212,d: "Boat transporter trailers, multi-unit, manufacturing"},
{c:336212,d: "Demountable cargo containers manufacturing"},
{c:336212,d: "Dump trailers manufacturing"},
{c:336212,d: "Flatbed trailers, commercial, manufacturing"},
{c:336212,d: "Horse trailers, fifth-wheel-type, manufacturing"},
{c:336212,d: "Logging trailers manufacturing"},
{c:336212,d: "Semi-trailers manufacturing"},
{c:336212,d: "Tank trailers, liquid and dry bulk, manufacturing"},
{c:336212,d: "Trailers, fifth-wheel-type, for transporting horses, manufacturing"},
{c:336212,d: "Truck trailers manufacturing"},
{c:336213,d: "Assembly line conversions of purchased vans and minivans"},
{c:336213,d: "Motor homes, self-contained, assembling on purchased chassis"},
{c:336213,d: "Van and minivan conversions on purchased chassis"},
{c:336214,d: "Automobile transporter trailers, single car, manufacturing"},
{c:336214,d: "Boat transporter trailers, single-unit, manufacturing"},
{c:336214,d: "Camper units, slide-in, for pick-up trucks, manufacturing"},
{c:336214,d: "Camping trailers and chassis manufacturing"},
{c:336214,d: "Caps for pick-up trucks manufacturing"},
{c:336214,d: "Horse trailers (except fifth-wheel-type) manufacturing"},
{c:336214,d: "Pick-up canopies, caps, or covers manufacturing"},
{c:336214,d: "Tent trailers (hard top and soft top) manufacturing"},
{c:336214,d: "Trailers for transporting horses (except fifth-wheel-type) manufacturing"},
{c:336214,d: "Trailers, camping, manufacturing"},
{c:336214,d: "Travel trailers, recreational, manufacturing"},
{c:336214,d: "Truck campers (i.e., slide-in campers) manufacturing"},
{c:336214,d: "Utility trailers manufacturing"},
{c:336310,d: "Assembly line rebuilding of automotive and truck gasoline engines"},
{c:336310,d: "Bearings (e.g., camshaft, crankshaft, connecting rod), automotive and truck gasoline engine, manufacturing"},
{c:336310,d: "Carburetors, all types, manufacturing"},
{c:336310,d: "Connecting rods, automotive and truck gasoline engine, manufacturing"},
{c:336310,d: "Crankshaft assemblies, automotive and truck gasoline engine, manufacturing"},
{c:336310,d: "Cylinder heads, automotive and truck gasoline engine, manufacturing"},
{c:336310,d: "Engine block assemblies, automotive and truck gasoline, manufacturing"},
{c:336310,d: "Engine intake and exhaust valves manufacturing"},
{c:336310,d: "Engines and parts (except diesel), automotive and truck, manufacturing"},
{c:336310,d: "Flywheels and ring gears, automotive and truck gasoline engine, manufacturing"},
{c:336310,d: "Fuel injection systems and parts, automotive and truck gasoline engine, manufacturing"},
{c:336310,d: "Fuel pumps, mechanical, automotive and truck gasoline engine, manufacturing"},
{c:336310,d: "Gasoline engine parts, mechanical, automotive and truck, manufacturing"},
{c:336310,d: "Gasoline engines for hybrid automotive vehicles manufacturing"},
{c:336310,d: "Gasoline engines, automotive and truck, manufacturing"},
{c:336310,d: "Governors for automotive gasoline engines manufacturing"},
{c:336310,d: "Internal combustion engines, automotive and truck gasoline, manufacturing"},
{c:336310,d: "Manifolds (i.e., intake and exhaust), automotive and truck gasoline engine, manufacturing"},
{c:336310,d: "Pistons and piston rings manufacturing"},
{c:336310,d: "Positive crankcase ventilation (PCV) valves, engine, manufacturing"},
{c:336310,d: "Pumps (e.g., fuel, oil, water), mechanical, automotive and truck gasoline engine (except power steering), manufacturing"},
{c:336310,d: "Rebuilding automotive and truck gasoline engines"},
{c:336310,d: "Rings, piston, manufacturing"},
{c:336310,d: "Rocker arms and parts, automotive and truck gasoline engine, manufacturing"},
{c:336310,d: "Timing gears and chains, automotive and truck gasoline engine, manufacturing"},
{c:336310,d: "Valves, engine, intake and exhaust, manufacturing"},
{c:336320,d: "Aircraft lighting fixtures manufacturing"},
{c:336320,d: "Alternators and generators for internal combustion engines manufacturing"},
{c:336320,d: "Automotive harness and ignition sets manufacturing"},
{c:336320,d: "Automotive lighting fixtures manufacturing"},
{c:336320,d: "Boat and ship lighting fixtures manufacturing"},
{c:336320,d: "Coils, ignition, internal combustion engines, manufacturing"},
{c:336320,d: "Cruise control mechanisms, electronic, automotive, truck, and bus, manufacturing"},
{c:336320,d: "Distributor cap and rotor for internal combustion engines manufacturing"},
{c:336320,d: "Distributors for internal combustion engines manufacturing"},
{c:336320,d: "Electrical control chips (modules), motor vehicle, manufacturing"},
{c:336320,d: "Electrical ignition cable sets for internal combustion engines manufacturing"},
{c:336320,d: "Fans, electric cooling, automotive, truck, and bus, manufacturing"},
{c:336320,d: "Fuel pumps, electric, automotive, truck, and bus, manufacturing"},
{c:336320,d: "Generating apparatus and parts for internal combustion engines manufacturing"},
{c:336320,d: "Generators for internal combustion engines manufacturing"},
{c:336320,d: "Ignition points and condensers for internal combustion engines manufacturing"},
{c:336320,d: "Ignition wiring harness for internal combustion engines manufacturing"},
{c:336320,d: "Instrument control panels (i.e., assembling purchased gauges), automotive, truck, and bus, manufacturing"},
{c:336320,d: "Keyless entry systems, automotive, truck, and bus, manufacturing"},
{c:336320,d: "Locomotive and railroad car light fixtures manufacturing"},
{c:336320,d: "Motors, starter, for internal combustion engines, manufacturing"},
{c:336320,d: "Power window and door lock systems, automotive, truck, and bus, manufacturing"},
{c:336320,d: "Regulators, motor vehicle voltage for internal combustion engines manufacturing"},
{c:336320,d: "Spark plugs for internal combustion engines manufacturing"},
{c:336320,d: "Spotlights, vehicular, manufacturing"},
{c:336320,d: "Starter and starter parts for internal combustion engines manufacturing"},
{c:336320,d: "Vehicular lighting fixtures manufacturing"},
{c:336320,d: "Voltage regulators for internal combustion engines manufacturing"},
{c:336320,d: "Windshield washer pumps, automotive, truck, and bus, manufacturing"},
{c:336320,d: "Windshield wiper systems, automotive, truck, and bus, manufacturing"},
{c:336320,d: "Wiring harness and ignition sets for internal combustion engines manufacturing"},
{c:336330,d: "Automotive, truck and bus steering assemblies and parts manufacturing"},
{c:336330,d: "Automotive, truck and bus suspension assemblies and parts (except springs) manufacturing"},
{c:336330,d: "Power steering hose assemblies manufacturing"},
{c:336330,d: "Power steering pumps manufacturing"},
{c:336330,d: "Rack and pinion steering assemblies manufacturing"},
{c:336330,d: "Shock absorbers, automotive, truck, and bus, manufacturing"},
{c:336330,d: "Steering boxes, manual and power assist, manufacturing"},
{c:336330,d: "Steering columns, automotive, truck, and bus, manufacturing"},
{c:336330,d: "Steering wheels, automotive, truck, and bus, manufacturing"},
{c:336330,d: "Struts, automotive, truck, and bus, manufacturing"},
{c:336330,d: "Wheels, steering, automotive, truck, and bus, manufacturing"},
{c:336340,d: "Air brake systems and parts, automotive, truck, and bus, manufacturing"},
{c:336340,d: "Brake and brake parts, automotive, truck, and bus, manufacturing"},
{c:336340,d: "Brake caliper assemblies, automotive, truck, and bus, manufacturing"},
{c:336340,d: "Brake cylinders, master and wheel, automotive, truck, and bus, manufacturing"},
{c:336340,d: "Brake discs (rotor), automotive, truck, and bus, manufacturing"},
{c:336340,d: "Brake drums, automotive, truck, and bus, manufacturing"},
{c:336340,d: "Brake hose assemblies manufacturing"},
{c:336340,d: "Brake linings, automotive, truck, and bus, manufacturing"},
{c:336340,d: "Brake pads and shoes, automotive, truck, and bus, manufacturing"},
{c:336340,d: "Brake shoes and pads, asbestos, manufacturing"},
{c:336340,d: "Calipers, brake, automotive, truck, and bus, manufacturing"},
{c:336340,d: "Cylinders, master brake (new and rebuilt), manufacturing"},
{c:336340,d: "Hydraulic slave cylinders, automotive, truck, and bus clutch, manufacturing"},
{c:336340,d: "Vacuum brake booster, automotive, truck, and bus, manufacturing"},
{c:336350,d: "Assembly line rebuilding of automotive, truck, and bus transmissions"},
{c:336350,d: "Automatic transmissions, automotive, truck, and bus, manufacturing"},
{c:336350,d: "Axle bearings, automotive, truck, and bus, manufacturing"},
{c:336350,d: "Clutches and clutch discs, asbestos, manufacturing"},
{c:336350,d: "Constant velocity joints, automotive, truck, and bus, manufacturing"},
{c:336350,d: "Differential and rear axle assemblies, automotive, truck, and bus, manufacturing"},
{c:336350,d: "Drive shafts and half shafts, automotive, truck, and bus, manufacturing"},
{c:336350,d: "Gears (e.g., crown, pinion, spider), automotive, truck, and bus, manufacturing"},
{c:336350,d: "Joints, universal, automotive, truck, and bus, manufacturing"},
{c:336350,d: "Pressure and clutch plate assemblies, automotive, truck, and bus, manufacturing"},
{c:336350,d: "Scattershield, engine, manufacturing"},
{c:336350,d: "Torque converters, automotive, truck, and bus, manufacturing"},
{c:336350,d: "Transaxles, automotive, truck, and bus, manufacturing"},
{c:336350,d: "Transmissions and parts, automotive, truck, and bus, manufacturing"},
{c:336350,d: "Universal joints, automotive, truck, and bus, manufacturing"},
{c:336360,d: "Aircraft seats manufacturing"},
{c:336360,d: "Automobile seat covers manufacturing"},
{c:336360,d: "Automobile seat frames, metal, manufacturing"},
{c:336360,d: "Automobile trimmings, textile, manufacturing"},
{c:336360,d: "Motor vehicle interior systems (e.g., headliners, panels, seats, trims) manufacturing"},
{c:336360,d: "Motor vehicle seats manufacturing"},
{c:336360,d: "Motor vehicle seats, metal framed, manufacturing"},
{c:336360,d: "Motor vehicle trimmings manufacturing"},
{c:336360,d: "Railroad seating manufacturing"},
{c:336360,d: "Seat belts, motor vehicle and aircraft, manufacturing"},
{c:336360,d: "Seats for public conveyances, manufacturing"},
{c:336360,d: "Seats, railroad, manufacturing"},
{c:336360,d: "Tire covers made from purchased fabric"},
{c:336360,d: "Transportation equipment seating manufacturing"},
{c:336370,d: "Job stampings, automotive, metal, manufacturing"},
{c:336370,d: "Metal motor vehicle body parts stamping"},
{c:336370,d: "Moldings and trim, motor vehicle, stamping"},
{c:336370,d: "Motor vehicle metal bumper stampings"},
{c:336370,d: "Motor vehicle metal parts stamping"},
{c:336370,d: "Motor vehicle metal stampings (e.g., body parts, fenders, hub caps, tops, trim) manufacturing"},
{c:336370,d: "Stamping metal motor vehicle body parts"},
{c:336370,d: "Stamping metal motor vehicle moldings and trims"},
{c:336390,d: "Air bag assemblies manufacturing"},
{c:336390,d: "Air bag initiators manufacturing"},
{c:336390,d: "Air filters, automotive, truck, and bus, manufacturing"},
{c:336390,d: "Air-conditioners, motor vehicle, manufacturing"},
{c:336390,d: "Automotive mirrors, framed, manufacturing"},
{c:336390,d: "Bumpers and bumperettes assembled, automotive, truck, and bus, manufacturing"},
{c:336390,d: "Catalytic converters, engine exhaust, automotive, truck, and bus, manufacturing"},
{c:336390,d: "Compressors, motor vehicle air-conditioning, manufacturing"},
{c:336390,d: "Convertible tops for automotive, truck, and bus, manufacturing"},
{c:336390,d: "Exhaust and tail pipes, automotive, truck, and bus, manufacturing"},
{c:336390,d: "Exhaust systems and parts, automotive, truck, and bus, manufacturing"},
{c:336390,d: "Filters (e.g., air, engine oil, fuel) automotive, truck, and bus, manufacturing"},
{c:336390,d: "Gas tanks assembled, automotive, truck, and bus, manufacturing"},
{c:336390,d: "Hitches, trailer, automotive, truck, and bus, manufacturing"},
{c:336390,d: "Luggage racks, car top, automotive, truck, and bus, manufacturing"},
{c:336390,d: "Mufflers and resonators, automotive, truck, and buses manufacturing"},
{c:336390,d: "Oil filters, automotive, truck, and bus, manufacturing"},
{c:336390,d: "Racks (e.g., bicycle, luggage, ski, tire), automotive, truck, and buses manufacturing"},
{c:336390,d: "Radiators and cores manufacturing"},
{c:336390,d: "Rims, automotive, truck, and bus wheel, manufacturing"},
{c:336390,d: "Sunroofs and parts, automotive, truck, and bus, manufacturing"},
{c:336390,d: "Thermostats, automotive, truck, and bus, manufacturing"},
{c:336390,d: "Tops, convertible automotive, manufacturing"},
{c:336390,d: "Towing bars and systems manufacturing"},
{c:336390,d: "Trailer hitches, motor vehicle, manufacturing"},
{c:336390,d: "Transmission coolers manufacturing"},
{c:336390,d: "Wheels (i.e., rims), automotive, truck, and bus, manufacturing"},
{c:336390,d: "Windshield wiper blades and refills manufacturing"},
{c:336390,d: "Wipers, windshield, automotive, truck, and bus, manufacturing"},
{c:336411,d: "Aircraft conversions (i.e., major modifications to system)"},
{c:336411,d: "Aircraft manufacturing"},
{c:336411,d: "Aircraft overhauling"},
{c:336411,d: "Aircraft rebuilding (i.e., restoration to original design specifications)"},
{c:336411,d: "Autogiros manufacturing"},
{c:336411,d: "Blimps (i.e., aircraft) manufacturing"},
{c:336411,d: "Developing and producing prototypes for aircraft"},
{c:336411,d: "Gliders (i.e., aircraft) manufacturing"},
{c:336411,d: "Hang gliders manufacturing"},
{c:336411,d: "Helicopters manufacturing"},
{c:336411,d: "Target drones, aircraft, manufacturing"},
{c:336411,d: "Ultra light aircraft manufacturing"},
{c:336411,d: "Unmanned and robotic aircraft manufacturing"},
{c:336412,d: "Aircraft engine and engine parts (except carburetors, pistons, piston rings, valves) manufacturing"},
{c:336412,d: "Aircraft engine overhauling"},
{c:336412,d: "Aircraft engine rebuilding"},
{c:336412,d: "Aircraft turbines manufacturing"},
{c:336412,d: "Developing and producing prototypes for aircraft engines and engine parts"},
{c:336412,d: "Engines and engine parts, aircraft (except carburetors, pistons, piston rings, valves), manufacturing"},
{c:336412,d: "Gas turbines, aircraft, manufacturing"},
{c:336412,d: "Gasoline engine parts (except carburetors, pistons, piston rings, valves), aircraft, manufacturing"},
{c:336412,d: "Gasoline engines, aircraft, manufacturing"},
{c:336412,d: "Internal combustion engines, aircraft, manufacturing"},
{c:336412,d: "Jet propulsion and internal combustion engines and parts, aircraft, manufacturing"},
{c:336412,d: "Rocket engines, aircraft, manufacturing"},
{c:336413,d: "Aircraft assemblies, subassemblies, and parts (except engines) manufacturing"},
{c:336413,d: "Aircraft auxiliary parts (e.g., crop dusting, external fuel tanks, inflight refueling equipment) manufacturing"},
{c:336413,d: "Aircraft brakes manufacturing"},
{c:336413,d: "Aircraft control surface assemblies manufacturing"},
{c:336413,d: "Aircraft fuselage wing tail and similar assemblies manufacturing"},
{c:336413,d: "Aircraft propellers and parts manufacturing"},
{c:336413,d: "Aircraft wheels manufacturing"},
{c:336413,d: "Airframe assemblies (except for guided missiles) manufacturing"},
{c:336413,d: "Developing and producing prototypes for aircraft parts (except engines) and auxiliary equipment"},
{c:336413,d: "Joints, universal, aircraft, manufacturing"},
{c:336413,d: "Targets, trailer type, aircraft, manufacturing"},
{c:336413,d: "Tow targets, aircraft, manufacturing"},
{c:336413,d: "Universal joints, aircraft, manufacturing"},
{c:336414,d: "Developing and producing prototypes for complete guided missiles and space vehicles"},
{c:336414,d: "Guided missile and space vehicle manufacturing"},
{c:336414,d: "Guided missiles, complete, assembling"},
{c:336414,d: "Rockets (guided missiles), space and military, complete, manufacturing"},
{c:336414,d: "Space vehicles, complete, manufacturing"},
{c:336415,d: "Developing and producing prototypes for guided missile and space vehicle engines"},
{c:336415,d: "Guided missile and space vehicle engine manufacturing"},
{c:336415,d: "Propulsion units and parts, guided missile and space vehicle, manufacturing"},
{c:336415,d: "Rocket engines, guided missile, manufacturing"},
{c:336419,d: "Airframe assemblies for guided missiles manufacturing"},
{c:336419,d: "Developing and producing prototypes for guided missile and space vehicle components"},
{c:336419,d: "Guided missile and space vehicle parts (except engines) manufacturing"},
{c:336419,d: "Space capsules manufacturing"},
{c:336510,d: "Brakes and parts for railroad rolling stock manufacturing"},
{c:336510,d: "Buses, trackless trolley, manufacturing"},
{c:336510,d: "Industrial locomotives and parts manufacturing"},
{c:336510,d: "Light rail cars and equipment manufacturing"},
{c:336510,d: "Locomotives manufacturing"},
{c:336510,d: "Locomotives rebuilding"},
{c:336510,d: "Lubrication systems, locomotive (except pumps), manufacturing"},
{c:336510,d: "Mining locomotives and parts manufacturing"},
{c:336510,d: "Rail laying and tamping equipment manufacturing"},
{c:336510,d: "Railroad cars and car equipment manufacturing"},
{c:336510,d: "Railroad cars, self-propelled, manufacturing"},
{c:336510,d: "Railroad locomotives and parts (except diesel engines) manufacturing"},
{c:336510,d: "Railroad rolling stock manufacturing"},
{c:336510,d: "Rapid transit cars and equipment manufacturing"},
{c:336510,d: "Rolling stock, railroad, rebuilding"},
{c:336510,d: "Streetcars and car equipment, urban transit, manufacturing"},
{c:336510,d: "Subway cars manufacturing"},
{c:336611,d: "Barge building"},
{c:336611,d: "Cargo ship building"},
{c:336611,d: "Container ship building"},
{c:336611,d: "Dredge building"},
{c:336611,d: "Drilling and production platforms, floating, oil and gas, building"},
{c:336611,d: "Drydock, floating, building"},
{c:336611,d: "Ferryboat building"},
{c:336611,d: "Fireboat building"},
{c:336611,d: "Fishing boat, commercial, building"},
{c:336611,d: "Hydrofoil vessel building and repairing in shipyard"},
{c:336611,d: "Naval ship building"},
{c:336611,d: "Oil and gas offshore floating platforms manufacturing"},
{c:336611,d: "Passenger ship building"},
{c:336611,d: "Patrol boat building"},
{c:336611,d: "Sailing ships, commercial, manufacturing"},
{c:336611,d: "Ship dismantling at shipyards"},
{c:336611,d: "Ship repair done in a shipyard"},
{c:336611,d: "Ship scaling services done at a shipyard"},
{c:336611,d: "Ships (i.e., not suitable or intended for personal use) manufacturing"},
{c:336611,d: "Shipyard (i.e., facility capable of building ships)"},
{c:336611,d: "Submarine building"},
{c:336611,d: "Towboat building and repairing"},
{c:336611,d: "Tugboat building"},
{c:336611,d: "Underwater remotely operated vehicles (ROVs) manufacturing in shipyards"},
{c:336611,d: "Unmanned and robotic watercraft manufacturing in shipyards"},
{c:336611,d: "Yachts built in shipyards"},
{c:336612,d: "Air boat building"},
{c:336612,d: "Boat yards (i.e., boat manufacturing facilities)"},
{c:336612,d: "Boats (i.e., suitable or intended for personal use) manufacturing"},
{c:336612,d: "Boats, inflatable plastics (except toy-type), manufacturing"},
{c:336612,d: "Cabin cruiser building"},
{c:336612,d: "Dinghies manufacturing"},
{c:336612,d: "Dories building"},
{c:336612,d: "Hovercraft building"},
{c:336612,d: "Inflatable plastic boats, heavy-duty, manufacturing"},
{c:336612,d: "Inflatable rubber boats, heavy-duty, manufacturing"},
{c:336612,d: "Life rafts, inflatable, manufacturing"},
{c:336612,d: "Motorboat, inboard or outboard, building"},
{c:336612,d: "Pleasure boats manufacturing"},
{c:336612,d: "Rigid inflatable boats (RIBs) manufacturing"},
{c:336612,d: "Rowboats manufacturing"},
{c:336612,d: "Sailboat building, not done in shipyards"},
{c:336612,d: "Underwater remotely operated vehicles (ROVs) manufacturing in boat yards"},
{c:336612,d: "Unmanned and robotic watercraft manufacturing in boat yards"},
{c:336612,d: "Yacht building, not done in shipyards"},
{c:336991,d: "Bicycles and parts manufacturing"},
{c:336991,d: "Mopeds and parts manufacturing"},
{c:336991,d: "Motor scooters manufacturing"},
{c:336991,d: "Motorcycles and parts manufacturing"},
{c:336991,d: "Tricycles, metal, adult and children's, manufacturing"},
{c:336991,d: "Vehicle, children's, metal manufacturing"},
{c:336992,d: "Armored military vehicles (except tanks) and parts manufacturing"},
{c:336992,d: "Tanks, military (including factory rebuilding), manufacturing"},
{c:336992,d: "Weapons, self-propelled, manufacturing"},
{c:336999,d: "All-terrain vehicles (ATVs), wheeled or tracked, manufacturing"},
{c:336999,d: "Animal-drawn vehicles and parts manufacturing"},
{c:336999,d: "Food (vendor) carts on wheels manufacturing"},
{c:336999,d: "Gocarts (except children's) manufacturing"},
{c:336999,d: "Golf carts and similar motorized passenger carriers manufacturing"},
{c:336999,d: "Golf carts, powered, manufacturing"},
{c:336999,d: "Off-highway tracked vehicles (except construction, armored military) manufacturing"},
{c:336999,d: "Off-road all-terrain vehicles (ATVs), wheeled or tracked, manufacturing"},
{c:336999,d: "Personal watercraft manufacturing"},
{c:336999,d: "Race cars manufacturing"},
{c:336999,d: "Snowmobiles and parts manufacturing"},
{c:337110,d: "Bathroom vanities (except freestanding), stock or custom wood, manufacturing"},
{c:337110,d: "Cabinets, kitchen (except freestanding), stock or custom wood, manufacturing"},
{c:337110,d: "Countertops (i.e., kitchen, bathroom), wood or plastics laminated on wood, manufacturing"},
{c:337110,d: "Countertops, wood, manufacturing"},
{c:337110,d: "Drainboards, wood or plastics laminated on wood, manufacturing"},
{c:337110,d: "Kitchen cabinets (except freestanding), stock or custom wood, manufacturing"},
{c:337110,d: "Medicine cabinets (except freestanding), wood household-type, manufacturing"},
{c:337110,d: "Plastics laminated over particleboard (e.g., fixture tops) manufacturing"},
{c:337110,d: "Vanities (except freestanding), stock or custom wood, manufacturing"},
{c:337110,d: "Vanity tops, wood or plastics laminated on wood, manufacturing"},
{c:337121,d: "Chair and couch springs, assembled, manufacturing"},
{c:337121,d: "Chairs, upholstered household-type (except dining room, kitchen), manufacturing"},
{c:337121,d: "Convertible sofas (except futons) manufacturing"},
{c:337121,d: "Cot springs, assembled, manufacturing"},
{c:337121,d: "Couch springs, assembled, manufacturing"},
{c:337121,d: "Couches, upholstered, manufacturing"},
{c:337121,d: "Cushion springs, assembled, manufacturing"},
{c:337121,d: "Furniture, household-type, upholstered on frames of any material, manufacturing"},
{c:337121,d: "Futons with frames manufacturing"},
{c:337121,d: "Household-type furniture, upholstered, manufacturing"},
{c:337121,d: "Juvenile furniture, upholstered, manufacturing"},
{c:337121,d: "Living room furniture, upholstered, manufacturing"},
{c:337121,d: "Metal framed furniture, household-type, upholstered, manufacturing"},
{c:337121,d: "Ottomans, upholstered, manufacturing"},
{c:337121,d: "Recliners, upholstered, manufacturing"},
{c:337121,d: "Rockers, upholstered, manufacturing"},
{c:337121,d: "Sofa beds and chair beds, upholstered, manufacturing"},
{c:337121,d: "Sofas, convertible (except futons), manufacturing"},
{c:337121,d: "Sofas, upholstered, manufacturing"},
{c:337121,d: "Upholstered furniture, household-type, custom, manufacturing"},
{c:337121,d: "Upholstered furniture, household-type, on frames of any material, manufacturing"},
{c:337121,d: "Wood framed furniture, upholstered, household-type, manufacturing"},
{c:337122,d: "Bed frames, wood household-type, manufacturing"},
{c:337122,d: "Bedroom furniture (except upholstered), wood household-type, manufacturing"},
{c:337122,d: "Beds (except hospital), wood household-type, manufacturing"},
{c:337122,d: "Beds, wood dormitory-type, manufacturing"},
{c:337122,d: "Beds, wood hotel-type, manufacturing"},
{c:337122,d: "Bookcases, wood household-type, manufacturing"},
{c:337122,d: "Buffets (furniture), wood, manufacturing"},
{c:337122,d: "Cabinets, wood household-type, freestanding, manufacturing"},
{c:337122,d: "Camp furniture, wood, manufacturing"},
{c:337122,d: "Card table sets (furniture), wood, manufacturing"},
{c:337122,d: "Cedar chests manufacturing"},
{c:337122,d: "Chairs (except upholstered), wood household-type, manufacturing"},
{c:337122,d: "China closets, wood, manufacturing"},
{c:337122,d: "Coffee tables, wood, manufacturing"},
{c:337122,d: "Computer furniture, wood household-type, manufacturing"},
{c:337122,d: "Cots, wood household-type, manufacturing"},
{c:337122,d: "Cradles, wood, manufacturing"},
{c:337122,d: "Cribs (i.e., baby beds), wood, manufacturing"},
{c:337122,d: "Desks, wood household-type, manufacturing"},
{c:337122,d: "Dining room chairs (including upholstered), wood, manufacturing"},
{c:337122,d: "Dining room furniture, wood household-type, manufacturing"},
{c:337122,d: "Dressers, wood, manufacturing"},
{c:337122,d: "Dressing tables, wood, manufacturing"},
{c:337122,d: "End tables, wood, manufacturing"},
{c:337122,d: "Furniture, outdoor wood household-type (e.g., beach, garden, lawn, porch), manufacturing"},
{c:337122,d: "Furniture, unassembled or knock-down wood household-type, manufacturing"},
{c:337122,d: "Furniture, unfinished wood household-type, manufacturing"},
{c:337122,d: "Furniture, wood household-type, not upholstered (except TV and radio housings, and sewing machine cabinets), manufacturing"},
{c:337122,d: "Futon frames manufacturing"},
{c:337122,d: "Garden furniture, wood, manufacturing"},
{c:337122,d: "Hammocks, wood framed, manufacturing"},
{c:337122,d: "Headboards, wood, manufacturing"},
{c:337122,d: "High chairs, wood, children's, manufacturing"},
{c:337122,d: "Home entertainment centers, wood, manufacturing"},
{c:337122,d: "Household-type furniture, wood, not upholstered (except TV and radio housings and sewing machine cabinets), manufacturing"},
{c:337122,d: "Juvenile furniture (except upholstered), wood, manufacturing"},
{c:337122,d: "Kitchen chairs (e.g., upholstered), wood, manufacturing"},
{c:337122,d: "Kitchen furniture, wood household-type, manufacturing"},
{c:337122,d: "Knickknack shelves, wood, manufacturing"},
{c:337122,d: "Lawn furniture, wood, manufacturing"},
{c:337122,d: "Living room furniture (except upholstered), wood, manufacturing"},
{c:337122,d: "Magazine racks, wood, manufacturing"},
{c:337122,d: "Night stands, wood, manufacturing"},
{c:337122,d: "Nonupholstered, household-type, custom wood furniture, manufacturing"},
{c:337122,d: "Nursery furniture (except upholstered), wood, manufacturing"},
{c:337122,d: "Playpens, children's wood, manufacturing"},
{c:337122,d: "Porch furniture (except upholstered), wood, manufacturing"},
{c:337122,d: "Rockers (except upholstered), wood, manufacturing"},
{c:337122,d: "Room dividers, wood household-type, manufacturing"},
{c:337122,d: "Serving carts, wood household-type, manufacturing"},
{c:337122,d: "Stools, wood household-type (except upholstered), manufacturing"},
{c:337122,d: "Tables, wood household-type, manufacturing"},
{c:337122,d: "TV stands and similar stands for consumer electronics, wood, manufacturing"},
{c:337122,d: "Vanities, freestanding, wood, manufacturing"},
{c:337122,d: "Wardrobes, wood household-type, manufacturing"},
{c:337122,d: "Water bed frames, wood, manufacturing"},
{c:337124,d: "Beds (including cabinet and folding), metal household-type (except hospital), manufacturing"},
{c:337124,d: "Bookcases, metal household-type, manufacturing"},
{c:337124,d: "Cabinets, metal (i.e., bathroom, kitchen) (except freestanding), manufacturing"},
{c:337124,d: "Cabinets, metal household-type, freestanding, manufacturing"},
{c:337124,d: "Cabinets, metal, radio and television, manufacturing"},
{c:337124,d: "Camp furniture, metal, manufacturing"},
{c:337124,d: "Card table sets, metal, manufacturing"},
{c:337124,d: "Chairs, metal household-type (except upholstered), manufacturing"},
{c:337124,d: "Computer furniture, metal household-type, manufacturing"},
{c:337124,d: "Cots, metal household-type, manufacturing"},
{c:337124,d: "Cribs (i.e., baby beds), metal, manufacturing"},
{c:337124,d: "Dinette sets, metal household-type, manufacturing"},
{c:337124,d: "Dining room chairs (including upholstered), metal, manufacturing"},
{c:337124,d: "Dining room furniture, metal household-type, manufacturing"},
{c:337124,d: "Dressers, metal, manufacturing"},
{c:337124,d: "Dressing tables, metal, manufacturing"},
{c:337124,d: "End tables, metal, manufacturing"},
{c:337124,d: "Furniture (except upholstered), metal household-type, manufacturing"},
{c:337124,d: "Furniture, outdoor metal household-type (e.g., beach, garden, lawn, porch), manufacturing"},
{c:337124,d: "Hammocks, metal framed, manufacturing"},
{c:337124,d: "Home entertainment centers, metal, manufacturing"},
{c:337124,d: "Juvenile furniture (except upholstered), metal manufacturing"},
{c:337124,d: "Kitchen chairs (including upholstered), metal, manufacturing"},
{c:337124,d: "Kitchen furniture, household-type, metal, manufacturing"},
{c:337124,d: "Kitchen furniture, metal household-type, manufacturing"},
{c:337124,d: "Lawn furniture, metal, manufacturing"},
{c:337124,d: "Living room furniture (except upholstered), metal, manufacturing"},
{c:337124,d: "Medicine cabinets, metal household-type, manufacturing"},
{c:337124,d: "Nursery furniture, metal, manufacturing"},
{c:337124,d: "Playpens, children's metal, manufacturing"},
{c:337124,d: "Porch swings, metal, manufacturing"},
{c:337124,d: "Serving carts, metal household-type, manufacturing"},
{c:337124,d: "Stools, metal household-type (except upholstered), manufacturing"},
{c:337124,d: "Tables, metal household-type, manufacturing"},
{c:337124,d: "TV stands and similar stands for consumer electronics, metal, manufacturing"},
{c:337124,d: "Vanities, metal household-type, manufacturing"},
{c:337124,d: "Wardrobes, metal household-type, manufacturing"},
{c:337124,d: "Water bed frames, metal, manufacturing"},
{c:337124,d: "Wrought iron furniture (except upholstered), household-type, manufacturing"},
{c:337125,d: "Bassinets, reed and rattan, manufacturing"},
{c:337125,d: "Bookcases (except wood and metal), household-type, manufacturing"},
{c:337125,d: "Camp furniture, reed and rattan, manufacturing"},
{c:337125,d: "Car seats, infant (except metal), manufacturing"},
{c:337125,d: "Chairs, cane, wood household-type, manufacturing"},
{c:337125,d: "Dining room chairs (including upholstered), plastics manufacturing"},
{c:337125,d: "Fiber furniture (except upholstered), household-type, manufacturing"},
{c:337125,d: "Furniture (except wood, metal, upholstered) indoor and outdoor household-type, manufacturing"},
{c:337125,d: "Hampers, laundry, reed, wicker, rattan, manufacturing"},
{c:337125,d: "Juvenile furniture, rattan and reed, manufacturing"},
{c:337125,d: "Kitchen chairs (including upholstered), plastics manufacturing"},
{c:337125,d: "Laundry hampers, rattan, reed, wicker or willow, manufacturing"},
{c:337125,d: "Lawn furniture (except concrete, metal, stone, wood) manufacturing"},
{c:337125,d: "Malacca furniture (except upholstered), household-type, manufacturing"},
{c:337125,d: "Plastics (including fiberglass) furniture (except upholstered), household-type, manufacturing"},
{c:337125,d: "Rattan furniture, household-type, manufacturing"},
{c:337125,d: "Reed furniture (except upholstered), household-type, manufacturing"},
{c:337125,d: "TV stands and similar stands for consumer electronics, plastics, manufacturing"},
{c:337125,d: "Wicker furniture (except upholstered), household-type, manufacturing"},
{c:337125,d: "Willow furniture (except upholstered), household-type, manufacturing"},
{c:337127,d: "Benches, park-type (except concrete, stone), manufacturing"},
{c:337127,d: "Benches, public building-type, manufacturing"},
{c:337127,d: "Benches, work, manufacturing"},
{c:337127,d: "Bleacher seating manufacturing"},
{c:337127,d: "Bowling center furniture manufacturing"},
{c:337127,d: "Cafeteria furniture manufacturing"},
{c:337127,d: "Cafeteria tables and benches manufacturing"},
{c:337127,d: "Chairs, barber, beauty shop (i.e., hydraulic), manufacturing"},
{c:337127,d: "Chairs, portable folding, auditorium-type, manufacturing"},
{c:337127,d: "Chairs, stacking, auditorium-type, manufacturing"},
{c:337127,d: "Church furniture (except concrete, stone) manufacturing"},
{c:337127,d: "Drafting tables and boards manufacturing"},
{c:337127,d: "Drawing tables and boards, artist's, manufacturing"},
{c:337127,d: "Furniture, factory-type (e.g., cabinets, stools, tool stands, work benches), manufacturing"},
{c:337127,d: "Furniture, institutional, manufacturing"},
{c:337127,d: "Furniture, laboratory-type (e.g., benches, cabinets, stools, tables), manufacturing"},
{c:337127,d: "Furniture, public building (e.g., church, library, school, theater), manufacturing"},
{c:337127,d: "Furniture, restaurant-type, manufacturing"},
{c:337127,d: "Institutional furniture manufacturing"},
{c:337127,d: "Laboratory-type furniture (e.g., benches, cabinets, stools, tables) (except dental) manufacturing"},
{c:337127,d: "Lunchroom tables and chairs manufacturing"},
{c:337127,d: "Pews, church, manufacturing"},
{c:337127,d: "Prison bed manufacturing"},
{c:337127,d: "Religious furniture manufacturing"},
{c:337127,d: "Restaurant furniture (e.g., carts, chairs, foodwagons, tables) manufacturing"},
{c:337127,d: "School furniture manufacturing"},
{c:337127,d: "Ship furniture manufacturing"},
{c:337127,d: "Stadium seating manufacturing"},
{c:337127,d: "Theater seating manufacturing"},
{c:337127,d: "Tool stands, factory, manufacturing"},
{c:337127,d: "Tray trucks, restaurant, manufacturing"},
{c:337127,d: "Work benches manufacturing"},
{c:337211,d: "Bookcases, wood office-type, manufacturing"},
{c:337211,d: "Cabinets, wood office-type, freestanding, manufacturing"},
{c:337211,d: "Chairs, wood office-type, manufacturing"},
{c:337211,d: "Desks, wood office-type, manufacturing"},
{c:337211,d: "Filing cabinets, wood, office-type, manufacturing"},
{c:337211,d: "Furniture, office-type, padded, upholstered, or plain wood, manufacturing"},
{c:337211,d: "Modular furniture systems, wood frame office-type, manufacturing"},
{c:337211,d: "Office furniture, padded, upholstered, or plain wood, manufacturing"},
{c:337211,d: "Tables, wood, office-type, manufacturing"},
{c:337212,d: "Architectural woodwork and fixtures (i.e., custom designed interiors) manufacturing"},
{c:337212,d: "Custom architectural millwork and fixtures, manufacturing on a job shop basis"},
{c:337212,d: "Custom design interiors (i.e., coordinated furniture, architectural woodwork, fixtures), manufacturing"},
{c:337212,d: "Millwork, custom architectural, manufacturing"},
{c:337214,d: "Bookcases (except wood), office-type, manufacturing"},
{c:337214,d: "Cabinets (except wood), office-type, freestanding, manufacturing"},
{c:337214,d: "Chairs (except wood), office-type, manufacturing"},
{c:337214,d: "Desks (except wood), office-type, manufacturing"},
{c:337214,d: "Filing cabinets (except wood), office-type, manufacturing"},
{c:337214,d: "Furniture (except wood), office-type, padded, upholstered, or plain, manufacturing"},
{c:337214,d: "Modular furniture systems (except wood frame), office-type, manufacturing"},
{c:337214,d: "Office furniture (except wood), padded, upholstered, or plain (except wood), manufacturing"},
{c:337214,d: "Tables (except wood), office-type, manufacturing"},
{c:337215,d: "Box spring frames manufacturing"},
{c:337215,d: "Cafeteria fixtures manufacturing"},
{c:337215,d: "Chair glides manufacturing"},
{c:337215,d: "Chair seats for furniture manufacturing"},
{c:337215,d: "Counter units (except refrigerated) manufacturing"},
{c:337215,d: "Countertops (except kitchen and bathroom), wood or plastics laminated on wood, manufacturing"},
{c:337215,d: "Display cases and fixtures (except refrigerated) manufacturing"},
{c:337215,d: "Fixtures, store display, manufacturing"},
{c:337215,d: "Furniture frames and parts, metal, manufacturing"},
{c:337215,d: "Furniture frames, wood, manufacturing"},
{c:337215,d: "Furniture parts, finished metal, manufacturing"},
{c:337215,d: "Furniture parts, finished plastics, manufacturing"},
{c:337215,d: "Furniture parts, finished wood, manufacturing"},
{c:337215,d: "Lockers (except refrigerated) manufacturing"},
{c:337215,d: "Mail carrier cases and tables, wood, manufacturing"},
{c:337215,d: "Partitions for floor attachment, prefabricated, manufacturing"},
{c:337215,d: "Partitions, freestanding, prefabricated, manufacturing"},
{c:337215,d: "Postal service lock boxes manufacturing"},
{c:337215,d: "Shelving (except wire) manufacturing"},
{c:337215,d: "Showcases (except refrigerated) manufacturing"},
{c:337215,d: "Sleeper mechanisms, convertible bed, manufacturing"},
{c:337215,d: "Stands (except wire), merchandise display, manufacturing"},
{c:337215,d: "Store display fixtures manufacturing"},
{c:337215,d: "Table tops, wood, manufacturing"},
{c:337215,d: "Telephone booths manufacturing"},
{c:337910,d: "Beds, sleep-system ensembles (i.e., flotation, adjustable), manufacturing"},
{c:337910,d: "Box springs, assembled, made from purchased spring"},
{c:337910,d: "Innerspring cushions manufacturing"},
{c:337910,d: "Mattresses (i.e., box spring, innerspring, noninnerspring) manufacturing"},
{c:337910,d: "Mattresses made from felt, foam rubber, urethane and similar materials"},
{c:337910,d: "Springs, assembled bed and box, made from purchased springs"},
{c:337910,d: "Water bed mattresses manufacturing"},
{c:337920,d: "Blinds (e.g., mini, venetian, vertical), all materials, manufacturing"},
{c:337920,d: "Curtain or drapery fixtures (e.g., poles, rods, rollers) manufacturing"},
{c:337920,d: "Curtain rods and fittings manufacturing"},
{c:337920,d: "Fixtures (e.g., poles, rods, rollers), curtain and drapery, manufacturing"},
{c:337920,d: "Miniblinds manufacturing"},
{c:337920,d: "Porch shades, wood slat, manufacturing"},
{c:337920,d: "Shade pulls, window, manufacturing"},
{c:337920,d: "Shades, window (except outdoor canvas awnings), manufacturing"},
{c:337920,d: "Venetian blinds manufacturing"},
{c:337920,d: "Vertical blinds manufacturing"},
{c:337920,d: "Window shade rollers and fittings manufacturing"},
{c:337920,d: "Window shades (except awnings) manufacturing"},
{c:339112,d: "Anesthesia apparatus manufacturing"},
{c:339112,d: "Blood pressure apparatus manufacturing"},
{c:339112,d: "Blood transfusion equipment manufacturing"},
{c:339112,d: "Bone drills manufacturing"},
{c:339112,d: "Bone plates and screws manufacturing"},
{c:339112,d: "Bone rongeurs manufacturing"},
{c:339112,d: "Bronchoscopes (except electromedical) manufacturing"},
{c:339112,d: "Cannulae manufacturing"},
{c:339112,d: "Catheters manufacturing"},
{c:339112,d: "Clamps, surgical, manufacturing"},
{c:339112,d: "Corneal microscopes manufacturing"},
{c:339112,d: "Cystoscopes (except electromedical) manufacturing"},
{c:339112,d: "Eye examining instruments and apparatus manufacturing"},
{c:339112,d: "Forceps, surgical, manufacturing"},
{c:339112,d: "Gastroscopes (except electromedical) manufacturing"},
{c:339112,d: "Hypodermic needles and syringes manufacturing"},
{c:339112,d: "Inhalation therapy equipment manufacturing"},
{c:339112,d: "Inhalators, surgical and medical, manufacturing"},
{c:339112,d: "Instruments, mechanical microsurgical, manufacturing"},
{c:339112,d: "IV apparatus manufacturing"},
{c:339112,d: "Knives, surgical, manufacturing"},
{c:339112,d: "Medical thermometers manufacturing"},
{c:339112,d: "Needles, hypodermic and suture, manufacturing"},
{c:339112,d: "Ophthalmic instruments and apparatus (except laser surgical) manufacturing"},
{c:339112,d: "Ophthalmometers and ophthalmoscopes manufacturing"},
{c:339112,d: "Optometers manufacturing"},
{c:339112,d: "Oxygen tents manufacturing"},
{c:339112,d: "Pelvimeters manufacturing"},
{c:339112,d: "Physiotherapy equipment (except electrotherapeutic) manufacturing"},
{c:339112,d: "Probes, surgical, manufacturing"},
{c:339112,d: "Retinoscopes (except electromedical) manufacturing"},
{c:339112,d: "Retractors, medical, manufacturing"},
{c:339112,d: "Saws, surgical, manufacturing"},
{c:339112,d: "Skin grafting equipment manufacturing"},
{c:339112,d: "Speculums manufacturing"},
{c:339112,d: "Sphygmomanometers manufacturing"},
{c:339112,d: "Stethoscopes manufacturing"},
{c:339112,d: "Suction therapy apparatus manufacturing"},
{c:339112,d: "Surgical clamps manufacturing"},
{c:339112,d: "Surgical knife blades and handles manufacturing"},
{c:339112,d: "Surgical stapling devices manufacturing"},
{c:339112,d: "Syringes, hypodermic, manufacturing"},
{c:339112,d: "Thermometers, medical, manufacturing"},
{c:339112,d: "Tonometers, medical, manufacturing"},
{c:339112,d: "Trocars manufacturing"},
{c:339112,d: "Veterinarians' instruments and apparatus manufacturing"},
{c:339113,d: "Adhesive tape, medical, manufacturing"},
{c:339113,d: "Arch supports, orthopedic, manufacturing"},
{c:339113,d: "Artificial limbs manufacturing"},
{c:339113,d: "Autoclaves, laboratory-type (except dental), manufacturing"},
{c:339113,d: "Bandages and dressings, surgical and orthopedic, manufacturing"},
{c:339113,d: "Beds, hospital, manufacturing"},
{c:339113,d: "Biohazard protective clothing and accessories manufacturing"},
{c:339113,d: "Blood testing apparatus, laboratory-type, manufacturing"},
{c:339113,d: "Bulletproof vests manufacturing"},
{c:339113,d: "Bunsen burners manufacturing"},
{c:339113,d: "Cervical collars manufacturing"},
{c:339113,d: "Clean room suits and accessories manufacturing"},
{c:339113,d: "Colostomy appliances manufacturing"},
{c:339113,d: "Corn remover and bunion pad manufacturing"},
{c:339113,d: "Corsets, surgical, manufacturing"},
{c:339113,d: "Cotton and cotton balls, absorbent, manufacturing"},
{c:339113,d: "Cotton tipped applicators manufacturing"},
{c:339113,d: "Crutches and walkers manufacturing"},
{c:339113,d: "Depressors, tongue, manufacturing"},
{c:339113,d: "Drapes, surgical, disposable, manufacturing"},
{c:339113,d: "Dressings, surgical, manufacturing"},
{c:339113,d: "Dryers, laboratory-type, manufacturing"},
{c:339113,d: "Elastic hosiery, orthopedic, manufacturing"},
{c:339113,d: "Firefighting suits and accessories manufacturing"},
{c:339113,d: "First-aid, snake bite, or burn kits manufacturing"},
{c:339113,d: "Foot appliances, orthopedic, manufacturing"},
{c:339113,d: "Furniture, hospital, specialized (e.g., hospital beds, operating room furniture), manufacturing"},
{c:339113,d: "Gas masks manufacturing"},
{c:339113,d: "Gauze, surgical, made from purchased fabric"},
{c:339113,d: "Gloves, rubber (e.g., electrician's, examination, household-type, surgeon's), manufacturing"},
{c:339113,d: "Gynecological supplies and appliances manufacturing"},
{c:339113,d: "Hard hats manufacturing"},
{c:339113,d: "Helmets (except athletic), safety (e.g., motorized vehicle crash helmets, space helmets), manufacturing"},
{c:339113,d: "Hosiery, orthopedic support, manufacturing"},
{c:339113,d: "Hospital beds manufacturing"},
{c:339113,d: "Hospital furniture, specialized (e.g., hospital beds, operating room furniture)"},
{c:339113,d: "Hydrotherapy equipment manufacturing"},
{c:339113,d: "Implants, surgical, manufacturing"},
{c:339113,d: "Incubators, infant, manufacturing"},
{c:339113,d: "Incubators, laboratory-type, manufacturing"},
{c:339113,d: "Infant incubators manufacturing"},
{c:339113,d: "Intra ocular lenses manufacturing"},
{c:339113,d: "Intrauterine devices manufacturing"},
{c:339113,d: "Iron lungs manufacturing"},
{c:339113,d: "Laboratory-type evaporation apparatus manufacturing"},
{c:339113,d: "Laboratory-type sample preparation apparatus manufacturing"},
{c:339113,d: "Life preservers manufacturing"},
{c:339113,d: "Limbs, artificial, manufacturing"},
{c:339113,d: "Metal fabric and mesh safety gloves manufacturing"},
{c:339113,d: "Noise protectors, personal, manufacturing"},
{c:339113,d: "Nose and ear plugs manufacturing"},
{c:339113,d: "Operating room tables manufacturing"},
{c:339113,d: "Orthopedic canes manufacturing"},
{c:339113,d: "Orthopedic device manufacturing and sale in retail environment"},
{c:339113,d: "Orthopedic devices manufacturing"},
{c:339113,d: "Orthopedic extension shoes manufacturing"},
{c:339113,d: "Orthopedic hosiery, elastic, manufacturing"},
{c:339113,d: "Personal safety devices, not specified elsewhere, manufacturing"},
{c:339113,d: "Prosthetic appliances and supplies manufacturing"},
{c:339113,d: "Radiation shielding aprons, gloves, and sheeting manufacturing"},
{c:339113,d: "Respiratory protection mask manufacturing"},
{c:339113,d: "Restraints, patient, manufacturing"},
{c:339113,d: "Shoes, orthopedic extension, manufacturing"},
{c:339113,d: "Space suits manufacturing"},
{c:339113,d: "Splints manufacturing"},
{c:339113,d: "Sterilizers, hospital and surgical, manufacturing"},
{c:339113,d: "Sterilizers, laboratory-type (except dental), manufacturing"},
{c:339113,d: "Stretchers, medical, manufacturing"},
{c:339113,d: "Suits, firefighting, manufacturing"},
{c:339113,d: "Suits, space, manufacturing"},
{c:339113,d: "Supports, orthopedic (e.g., abdominal, ankle, arch, kneecap), manufacturing"},
{c:339113,d: "Surgical dressings manufacturing"},
{c:339113,d: "Surgical implants manufacturing"},
{c:339113,d: "Surgical supplies (except medical instruments) manufacturing"},
{c:339113,d: "Sutures, surgical, manufacturing"},
{c:339113,d: "Tapes, medical adhesive, manufacturing"},
{c:339113,d: "Tongue depressors manufacturing"},
{c:339113,d: "Traction apparatus manufacturing"},
{c:339113,d: "Ultrasonic medical cleaning equipment manufacturing"},
{c:339113,d: "Vacuum pumps, laboratory-type, manufacturing"},
{c:339113,d: "Welder's hoods manufacturing"},
{c:339113,d: "Wheelchairs manufacturing"},
{c:339113,d: "Whirlpool baths (i.e., hydrotherapy equipment) manufacturing"},
{c:339114,d: "Abrasive points, wheels, and disks, dental, manufacturing"},
{c:339114,d: "Amalgams, dental, manufacturing"},
{c:339114,d: "Autoclaves, dental, manufacturing"},
{c:339114,d: "Cements, dental, manufacturing"},
{c:339114,d: "Chairs, dentist's, manufacturing"},
{c:339114,d: "Cutting instruments, dental, manufacturing"},
{c:339114,d: "Dental alloys for amalgams manufacturing"},
{c:339114,d: "Dental chairs manufacturing"},
{c:339114,d: "Dental equipment and instruments manufacturing"},
{c:339114,d: "Dental glues and cements manufacturing"},
{c:339114,d: "Dental hand instruments (e.g., forceps) manufacturing"},
{c:339114,d: "Dental impression materials manufacturing"},
{c:339114,d: "Dental instrument delivery systems manufacturing"},
{c:339114,d: "Dental laboratory equipment manufacturing"},
{c:339114,d: "Dental wax manufacturing"},
{c:339114,d: "Denture materials manufacturing"},
{c:339114,d: "Drills, dental, manufacturing"},
{c:339114,d: "Enamels, dental, manufacturing"},
{c:339114,d: "Furnaces, dental laboratory, manufacturing"},
{c:339114,d: "Glue, dental, manufacturing"},
{c:339114,d: "Impression material, dental, manufacturing"},
{c:339114,d: "Orthodontic appliances manufacturing"},
{c:339114,d: "Points, abrasive dental, manufacturing"},
{c:339114,d: "Sterilizers, dental, manufacturing"},
{c:339114,d: "Teeth (except customized) manufacturing"},
{c:339114,d: "Tools, dentists', manufacturing"},
{c:339114,d: "Ultrasonic dental equipment manufacturing"},
{c:339115,d: "Contact lenses manufacturing"},
{c:339115,d: "Eyeglass frames (i.e., fronts and temples), ophthalmic, manufacturing"},
{c:339115,d: "Eyes, glass and plastics, manufacturing"},
{c:339115,d: "Goggles (e.g., industrial, safety, sun, underwater) manufacturing"},
{c:339115,d: "Lens coating, ophthalmic"},
{c:339115,d: "Lens grinding, ophthalmic (except in retail stores)"},
{c:339115,d: "Lens mounts, ophthalmic, manufacturing"},
{c:339115,d: "Lens polishing, ophthalmic"},
{c:339115,d: "Lenses, ophthalmic, manufacturing"},
{c:339115,d: "Magnifiers, corrective vision-type, manufacturing"},
{c:339115,d: "Sunglasses and goggles manufacturing"},
{c:339115,d: "Temples and fronts (i.e., eyeglass frames), ophthalmic, manufacturing"},
{c:339116,d: "Bridges, custom made in dental laboratories"},
{c:339116,d: "Dental laboratories"},
{c:339116,d: "Dentures, custom made in dental laboratories"},
{c:339116,d: "Orthodontic appliance, custom made in dental laboratories"},
{c:339116,d: "Teeth, custom made in dental laboratories"},
{c:339910,d: "Bracelets, precious metal, manufacturing"},
{c:339910,d: "Buttons, precious metal, precious stones, semiprecious stones, manufacturing"},
{c:339910,d: "Card cases, precious metal, manufacturing"},
{c:339910,d: "Cases, jewelry, metal, manufacturing"},
{c:339910,d: "Chains or necklace, precious metal, manufacturing"},
{c:339910,d: "Checkbook covers, precious metal, manufacturing"},
{c:339910,d: "Cigar cases, precious metal, manufacturing"},
{c:339910,d: "Cigarette cases, precious metal, manufacturing"},
{c:339910,d: "Coin purses, precious metal, manufacturing"},
{c:339910,d: "Comb cases, precious metal, manufacturing"},
{c:339910,d: "Compacts, precious metal, manufacturing"},
{c:339910,d: "Cosmetic bags, precious metal, manufacturing"},
{c:339910,d: "Costume jewelry manufacturing"},
{c:339910,d: "Cuff links, precious metal, manufacturing"},
{c:339910,d: "Cutlery, precious metal (except precious plated), manufacturing"},
{c:339910,d: "Diamond cutting and polishing"},
{c:339910,d: "Drilling pearls"},
{c:339910,d: "Engraving and etching precious metal flatware"},
{c:339910,d: "Engraving and etching precious metal jewelry"},
{c:339910,d: "Engraving and/or etching costume jewelry"},
{c:339910,d: "Findings, jeweler's, manufacturing"},
{c:339910,d: "Handbags, precious metal, manufacturing"},
{c:339910,d: "Hollowware, precious metal, manufacturing"},
{c:339910,d: "Jewel settings and mountings, precious metal, manufacturing"},
{c:339910,d: "Jeweler's findings and materials manufacturing"},
{c:339910,d: "Jewelry, costume, manufacturing"},
{c:339910,d: "Jewelry, natural or cultured pearls, manufacturing"},
{c:339910,d: "Jewelry, precious metal, manufacturing"},
{c:339910,d: "Key cases, precious metal, manufacturing"},
{c:339910,d: "Lapidary work manufacturing"},
{c:339910,d: "Lighters, cigar and cigarette, clad with precious metal, manufacturing"},
{c:339910,d: "Novelties, precious metal (except precious plated), manufacturing"},
{c:339910,d: "Pearl drilling, peeling, or sawing"},
{c:339910,d: "Pearls, costume, manufacturing"},
{c:339910,d: "Personal goods, metal, manufacturing"},
{c:339910,d: "Pewter ware manufacturing"},
{c:339910,d: "Pins and brooches, precious metal, manufacturing"},
{c:339910,d: "Pocketbooks, precious metal, men's or women's, manufacturing"},
{c:339910,d: "Purses, precious metal or clad with precious metal, manufacturing"},
{c:339910,d: "Rosaries and other small religious articles, precious metal, manufacturing"},
{c:339910,d: "Sewing cases, precious metal, manufacturing"},
{c:339910,d: "Stamping coins"},
{c:339910,d: "Straps, watch, precious metal, manufacturing"},
{c:339910,d: "Table cutlery, precious metal, manufacturing"},
{c:339910,d: "Tobacco pouches, precious metal, manufacturing"},
{c:339910,d: "Toilet kits and cases, precious metal, manufacturing"},
{c:339910,d: "Trophies, precious metal (except precious plated), manufacturing"},
{c:339910,d: "Wallets, precious metal, manufacturing"},
{c:339910,d: "Watch bands, metal, manufacturing"},
{c:339920,d: "Archery equipment manufacturing"},
{c:339920,d: "Athletic goods (except ammunition, clothing, footwear, small arms) manufacturing"},
{c:339920,d: "Badminton equipment manufacturing"},
{c:339920,d: "Bags, golf, manufacturing"},
{c:339920,d: "Bags, punching, manufacturing"},
{c:339920,d: "Bait, artificial, fishing, manufacturing"},
{c:339920,d: "Balls, baseball, basketball, football, golf, tennis, pool, and bowling, manufacturing"},
{c:339920,d: "Baseball equipment and supplies (except footwear, uniforms) manufacturing"},
{c:339920,d: "Basketball equipment and supplies (except footwear, uniforms) manufacturing"},
{c:339920,d: "Billiard equipment and supplies manufacturing"},
{c:339920,d: "Bobsleds manufacturing"},
{c:339920,d: "Boomerangs manufacturing"},
{c:339920,d: "Bowling pin machines, automatic, manufacturing"},
{c:339920,d: "Bows, archery, manufacturing"},
{c:339920,d: "Boxing equipment manufacturing"},
{c:339920,d: "Caddy carts manufacturing"},
{c:339920,d: "Carts, caddy, manufacturing"},
{c:339920,d: "Clubs, sporting goods (e.g., golf, Indian), manufacturing"},
{c:339920,d: "Croquet sets manufacturing"},
{c:339920,d: "Dumbbells manufacturing"},
{c:339920,d: "Exercise machines manufacturing"},
{c:339920,d: "Fencing equipment (sporting goods) manufacturing"},
{c:339920,d: "Fishing tackle and equipment (except lines, nets, seines) manufacturing"},
{c:339920,d: "Flies, artificial fishing, manufacturing"},
{c:339920,d: "Football equipment and supplies (except footwear, uniforms) manufacturing"},
{c:339920,d: "Gloves, sport and athletic (e.g., baseball, boxing, racketball, handball), manufacturing"},
{c:339920,d: "Golfing equipment (e.g., bags, balls, caddy carts, clubs, tees) manufacturing"},
{c:339920,d: "Gymnasium and playground equipment, manufacturing"},
{c:339920,d: "Helmets, athletic (except motorized vehicle crash helmets), manufacturing"},
{c:339920,d: "Hockey equipment (except apparel) manufacturing"},
{c:339920,d: "Hockey skates manufacturing"},
{c:339920,d: "Hooks, fishing, manufacturing"},
{c:339920,d: "Ice skates manufacturing"},
{c:339920,d: "Jogging machines, manufacturing"},
{c:339920,d: "Leather gloves, athletic, manufacturing"},
{c:339920,d: "Protectors, sports (e.g., baseball, basketball, hockey), manufacturing"},
{c:339920,d: "Reels, fishing, manufacturing"},
{c:339920,d: "Rods and rod parts, fishing, manufacturing"},
{c:339920,d: "Roller skates manufacturing"},
{c:339920,d: "Sailboards manufacturing"},
{c:339920,d: "Scuba diving equipment manufacturing"},
{c:339920,d: "Skateboards manufacturing"},
{c:339920,d: "Skates and parts, ice and roller, manufacturing"},
{c:339920,d: "Skis and skiing equipment (except apparel) manufacturing"},
{c:339920,d: "Snowshoes manufacturing"},
{c:339920,d: "Sporting goods (except ammunition, clothing, footwear, small arms) manufacturing"},
{c:339920,d: "Squash equipment (except apparel) manufacturing"},
{c:339920,d: "Sticks, sports (e.g., hockey, lacrosse), manufacturing"},
{c:339920,d: "Surfboards manufacturing"},
{c:339920,d: "Swimming pools, above ground, manufacturing"},
{c:339920,d: "Tackle, fishing (except line, nets, seines), manufacturing"},
{c:339920,d: "Tennis goods (e.g., balls, frames, rackets) manufacturing"},
{c:339920,d: "Toboggans manufacturing"},
{c:339920,d: "Track and field athletic equipment (except apparel, footwear) manufacturing"},
{c:339920,d: "Wet suits manufacturing"},
{c:339930,d: "Action figures manufacturing"},
{c:339930,d: "Automobiles, children's, manufacturing"},
{c:339930,d: "Balls, rubber (except athletic equipment), manufacturing"},
{c:339930,d: "Carriages, baby, manufacturing"},
{c:339930,d: "Carriages, doll, manufacturing"},
{c:339930,d: "Checkers and checkerboards manufacturing"},
{c:339930,d: "Chessmen and chessboards manufacturing"},
{c:339930,d: "Clothing, doll, manufacturing"},
{c:339930,d: "Craft and hobby kits and sets manufacturing"},
{c:339930,d: "Darts and dart games manufacturing"},
{c:339930,d: "Dishes, toy, manufacturing"},
{c:339930,d: "Doll carriages and carts manufacturing"},
{c:339930,d: "Doll clothing manufacturing"},
{c:339930,d: "Dolls, doll parts, and doll clothing (except wigs) manufacturing"},
{c:339930,d: "Electronic toys and games manufacturing"},
{c:339930,d: "Embroidery kits manufacturing"},
{c:339930,d: "Games (except coin-operated), children's and adult, manufacturing"},
{c:339930,d: "Gocarts, children's, manufacturing"},
{c:339930,d: "Hobbyhorses manufacturing"},
{c:339930,d: "Kites manufacturing"},
{c:339930,d: "Marbles manufacturing"},
{c:339930,d: "Model kits manufacturing"},
{c:339930,d: "Model railroad manufacturing"},
{c:339930,d: "Models, toy and hobby (e.g., airplane, boat, ship), manufacturing"},
{c:339930,d: "Musical instruments, toy, manufacturing"},
{c:339930,d: "Railroad models, hobby and toy, manufacturing"},
{c:339930,d: "Rifles, toy, manufacturing"},
{c:339930,d: "Rocking horses manufacturing"},
{c:339930,d: "Science kits (e.g., chemistry sets, microscopes, natural science sets) manufacturing"},
{c:339930,d: "Scooters, children's, manufacturing"},
{c:339930,d: "Sleds, children's, manufacturing"},
{c:339930,d: "Strollers, baby, manufacturing"},
{c:339930,d: "Structural toy sets manufacturing"},
{c:339930,d: "Stuffed toys (including animals) manufacturing"},
{c:339930,d: "Toy furniture and household-type equipment manufacturing"},
{c:339930,d: "Toys manufacturing"},
{c:339930,d: "Trains and equipment, toy, electric or mechanical, manufacturing"},
{c:339930,d: "Tricycles (except metal) manufacturing"},
{c:339930,d: "Vehicles, children's (except bicycles and metal tricycles), manufacturing"},
{c:339930,d: "Video game machines (except coin-operated) manufacturing"},
{c:339930,d: "Wagons, children's (e.g., coaster, express, and play), manufacturing"},
{c:339930,d: "Walkers, baby (vehicles), manufacturing"},
{c:339940,d: "Artist's paint manufacturing"},
{c:339940,d: "Artist's supplies (except paper) manufacturing"},
{c:339940,d: "Ball point pens manufacturing"},
{c:339940,d: "Blackboards, framed, manufacturing"},
{c:339940,d: "Branding irons (i.e., marking irons) manufacturing"},
{c:339940,d: "Brushes, artists', manufacturing"},
{c:339940,d: "Canvas board, artist's, manufacturing"},
{c:339940,d: "Canvas, artist's, prepared on frames, manufacturing"},
{c:339940,d: "Carbon paper manufacturing"},
{c:339940,d: "Chalk (e.g., artist's, blackboard, carpenter's, marking, tailor's), manufacturing"},
{c:339940,d: "Chalkboards, framed, manufacturing"},
{c:339940,d: "Clay, modeling, manufacturing"},
{c:339940,d: "Crayons manufacturing"},
{c:339940,d: "Date stamps, hand operated, manufacturing"},
{c:339940,d: "Drafting materials (except instruments and tables) manufacturing"},
{c:339940,d: "Easels, artists', manufacturing"},
{c:339940,d: "Embossing stamps manufacturing"},
{c:339940,d: "Felt tip markers manufacturing"},
{c:339940,d: "Fountain pens manufacturing"},
{c:339940,d: "Frames for artist's canvases (i.e., stretchers) manufacturing"},
{c:339940,d: "Hand operated stamps (e.g., canceling, postmark, shoe, textile marking) manufacturing"},
{c:339940,d: "Holepunchers, hand operated, manufacturing"},
{c:339940,d: "Inked ribbons manufacturing"},
{c:339940,d: "Label making equipment, handheld, manufacturing"},
{c:339940,d: "List finders and roledex address files manufacturing"},
{c:339940,d: "Marker boards (i.e., whiteboards) manufacturing"},
{c:339940,d: "Marking devices manufacturing"},
{c:339940,d: "Mechanical pencil refills manufacturing"},
{c:339940,d: "Mechanical pencils manufacturing"},
{c:339940,d: "Metal hand stamps manufacturing"},
{c:339940,d: "Modeling clay manufacturing"},
{c:339940,d: "Nibs (i.e., pen points) manufacturing"},
{c:339940,d: "Paints, artist's, manufacturing"},
{c:339940,d: "Palettes, artist's, manufacturing"},
{c:339940,d: "Paper cutters, office-type, manufacturing"},
{c:339940,d: "Paper, carbon, manufacturing"},
{c:339940,d: "Paper, stencil, manufacturing"},
{c:339940,d: "Pen refills and cartridges manufacturing"},
{c:339940,d: "Pencil leads manufacturing"},
{c:339940,d: "Pencil sharpeners manufacturing"},
{c:339940,d: "Pencils manufacturing"},
{c:339940,d: "Pens manufacturing"},
{c:339940,d: "Ribbons (e.g., cash register, printer, typewriter), inked, manufacturing"},
{c:339940,d: "Ribbons, inked, manufacturing"},
{c:339940,d: "Rubber stamps manufacturing"},
{c:339940,d: "Seal presses (e.g., notary), hand operated, manufacturing"},
{c:339940,d: "Stamp pads manufacturing"},
{c:339940,d: "Stamping devices, hand operated, manufacturing"},
{c:339940,d: "Staple removers manufacturing"},
{c:339940,d: "Staplers manufacturing"},
{c:339940,d: "Stencil paper manufacturing"},
{c:339940,d: "Stencils for painting and marking (e.g., cardboard, metal) manufacturing"},
{c:339940,d: "Tape dispensers manufacturing"},
{c:339940,d: "Typewriter ribbons manufacturing"},
{c:339940,d: "Water colors, artist's, manufacturing"},
{c:339950,d: "Billboards manufacturing"},
{c:339950,d: "Displays (e.g., counter, floor, point-of-purchase) manufacturing"},
{c:339950,d: "Electrical signs manufacturing"},
{c:339950,d: "Letters for signs manufacturing"},
{c:339950,d: "Neon signs manufacturing"},
{c:339950,d: "Scoreboards manufacturing"},
{c:339950,d: "Signs and signboards (except paper, paperboard) manufacturing"},
{c:339991,d: "Coaxial mechanical face seals manufacturing"},
{c:339991,d: "Compression packings manufacturing"},
{c:339991,d: "Gasket, packing, and sealing devices manufacturing"},
{c:339991,d: "Gaskets manufacturing"},
{c:339991,d: "Grease seals manufacturing"},
{c:339991,d: "Molded packings and seals manufacturing"},
{c:339991,d: "Oil seals manufacturing"},
{c:339991,d: "Seals, grease or oil, manufacturing"},
{c:339992,d: "Accordions and parts manufacturing"},
{c:339992,d: "Autophones (organs with perforated music rolls) manufacturing"},
{c:339992,d: "Banjos and parts manufacturing"},
{c:339992,d: "Bassoons manufacturing"},
{c:339992,d: "Bells (musical instruments) manufacturing"},
{c:339992,d: "Calliopes (steam organs) manufacturing"},
{c:339992,d: "Cellos and parts manufacturing"},
{c:339992,d: "Chimes and parts (musical instruments) manufacturing"},
{c:339992,d: "Clarinets and parts manufacturing"},
{c:339992,d: "Cornets and parts manufacturing"},
{c:339992,d: "Cymbals and parts manufacturing"},
{c:339992,d: "Drums (musical instruments), parts, and accessories manufacturing"},
{c:339992,d: "Electric musical instruments manufacturing"},
{c:339992,d: "Flutes and parts manufacturing"},
{c:339992,d: "Fretted instruments and parts manufacturing"},
{c:339992,d: "Guitars and parts, electric and nonelectric, manufacturing"},
{c:339992,d: "Hammers, piano, manufacturing"},
{c:339992,d: "Harmonicas manufacturing"},
{c:339992,d: "Harps and parts manufacturing"},
{c:339992,d: "Harpsichords manufacturing"},
{c:339992,d: "Instruments, musical, manufacturing"},
{c:339992,d: "Keyboards, piano or organ, manufacturing"},
{c:339992,d: "Knobs, organ, manufacturing"},
{c:339992,d: "Mandolins manufacturing"},
{c:339992,d: "Mouthpieces for musical instruments manufacturing"},
{c:339992,d: "Music rolls, perforated, manufacturing"},
{c:339992,d: "Musical instrument accessories (e.g., mouthpieces, reeds, stands, traps) manufacturing"},
{c:339992,d: "Musical instruments (except toy) manufacturing"},
{c:339992,d: "Oboes manufacturing"},
{c:339992,d: "Ocarinas manufacturing"},
{c:339992,d: "Octophones manufacturing"},
{c:339992,d: "Percussion musical instruments manufacturing"},
{c:339992,d: "Piano parts and materials (except piano hardware) manufacturing"},
{c:339992,d: "Piccolos and parts manufacturing"},
{c:339992,d: "Pipes, organ, manufacturing"},
{c:339992,d: "Reeds, musical instrument, manufacturing"},
{c:339992,d: "Saxophones and parts manufacturing"},
{c:339992,d: "Strings, musical instrument, manufacturing"},
{c:339992,d: "Synthesizers, music, manufacturing"},
{c:339992,d: "Trombones and parts manufacturing"},
{c:339992,d: "Trumpets and parts manufacturing"},
{c:339992,d: "Tuning forks manufacturing"},
{c:339992,d: "Ukuleles and parts manufacturing"},
{c:339992,d: "Vibraphones manufacturing"},
{c:339992,d: "Violas and parts manufacturing"},
{c:339992,d: "Violins and parts manufacturing"},
{c:339992,d: "Xylophones and parts manufacturing"},
{c:339992,d: "Zithers and parts manufacturing"},
{c:339993,d: "Buckles and buckle parts (including shoe) manufacturing"},
{c:339993,d: "Buttons (except precious metal, precious stones, semiprecious stones) manufacturing"},
{c:339993,d: "Cuff links (except precious) manufacturing"},
{c:339993,d: "Eyelets, metal, manufacturing"},
{c:339993,d: "Fasteners (e.g., glove, hook and eye, slide, snap) manufacturing"},
{c:339993,d: "Hairpins (except rubber) manufacturing"},
{c:339993,d: "Hook and eye fasteners (i.e., sewing accessories) manufacturing"},
{c:339993,d: "Needles (except hypodermic, phonograph, styli) manufacturing"},
{c:339993,d: "Pins (except precious) manufacturing"},
{c:339993,d: "Safety pins manufacturing"},
{c:339993,d: "Slide fasteners (i.e., zippers) manufacturing"},
{c:339993,d: "Zippers (i.e., slide fasteners) manufacturing"},
{c:339994,d: "Brooms, hand and machine, manufacturing"},
{c:339994,d: "Brushes, household-type and industrial, manufacturing"},
{c:339994,d: "Brushes, paint (except artists'), manufacturing"},
{c:339994,d: "Cloths (except chemically treated), dusting and polishing, manufacturing"},
{c:339994,d: "Hairbrushes manufacturing"},
{c:339994,d: "Mops, floor and dust, manufacturing"},
{c:339994,d: "Paint rollers manufacturing"},
{c:339994,d: "Paintbrushes manufacturing"},
{c:339994,d: "Shaving brushes manufacturing"},
{c:339994,d: "Toothbrushes (except electric) manufacturing"},
{c:339995,d: "Burial caskets and cases manufacturing"},
{c:339995,d: "Burial vaults (except concrete, stone) manufacturing"},
{c:339995,d: "Caskets, burial, manufacturing"},
{c:339995,d: "Vaults (except concrete) manufacturing"},
{c:339999,d: "Amusement machines, coin-operated, manufacturing"},
{c:339999,d: "Artificial flower arrangements assembled from purchased components"},
{c:339999,d: "Atomizers (e.g., perfumes) manufacturing"},
{c:339999,d: "Beach umbrellas manufacturing"},
{c:339999,d: "Bone novelties manufacturing"},
{c:339999,d: "Candles manufacturing"},
{c:339999,d: "Canes (except orthopedic) manufacturing"},
{c:339999,d: "Christmas tree ornaments (except electric, glass) manufacturing"},
{c:339999,d: "Christmas trees, artificial, manufacturing"},
{c:339999,d: "Cigarette holders manufacturing"},
{c:339999,d: "Cigarette lighter flints manufacturing"},
{c:339999,d: "Cigarette lighters (except precious metal) manufacturing"},
{c:339999,d: "Coin-operated amusement machines (except jukebox) manufacturing"},
{c:339999,d: "Coin-operated gambling devices manufacturing"},
{c:339999,d: "Decalcomania work (except on china, glass)"},
{c:339999,d: "Electronic cigarettes manufacturing"},
{c:339999,d: "Feather dusters manufacturing"},
{c:339999,d: "Feathers, preparing (i.e., for use in apparel and textile products)"},
{c:339999,d: "Fire extinguishers, portable, manufacturing"},
{c:339999,d: "Flints, lighter, manufacturing"},
{c:339999,d: "Flowers, artificial (except glass, plastics), manufacturing"},
{c:339999,d: "Fly swatters manufacturing"},
{c:339999,d: "Frames, mirror and picture, all materials, manufacturing"},
{c:339999,d: "Fruits, artificial (except glass, plastics), manufacturing"},
{c:339999,d: "Games, coin-operated, manufacturing"},
{c:339999,d: "Garden umbrellas manufacturing"},
{c:339999,d: "Globes, geographical, manufacturing"},
{c:339999,d: "Hair nets made from purchased netting"},
{c:339999,d: "Hairpieces (e.g., toupees, wigs, wiglets) manufacturing"},
{c:339999,d: "Hat blocks manufacturing"},
{c:339999,d: "Lighters, cigar and cigarette (except motor vehicle, precious metal), manufacturing"},
{c:339999,d: "Mannequins manufacturing"},
{c:339999,d: "Marionettes (i.e., puppets) manufacturing"},
{c:339999,d: "Models, anatomical, manufacturing"},
{c:339999,d: "Music boxes manufacturing"},
{c:339999,d: "Novelties, not specified elsewhere, manufacturing"},
{c:339999,d: "Ornaments, Christmas tree (except electric, glass), manufacturing"},
{c:339999,d: "Parasols manufacturing"},
{c:339999,d: "Patterns, shoe, manufacturing"},
{c:339999,d: "Pinball machines, coin-operated, manufacturing"},
{c:339999,d: "Pipe cleaners manufacturing"},
{c:339999,d: "Pipes, smoker's, manufacturing"},
{c:339999,d: "Potpourri manufacturing"},
{c:339999,d: "Puppets manufacturing"},
{c:339999,d: "Sewing and mending kits assembling"},
{c:339999,d: "Shell novelties"},
{c:339999,d: "Slot machines manufacturing"},
{c:339999,d: "Theatrical scenery manufacturing"},
{c:339999,d: "Tinsel manufacturing"},
{c:339999,d: "Tobacco pipes manufacturing"},
{c:339999,d: "Toupees manufacturing"},
{c:339999,d: "Trees and plants, artificial, manufacturing"},
{c:339999,d: "Umbrellas manufacturing"},
{c:339999,d: "Wax figures (i.e., mannequins) manufacturing"},
{c:339999,d: "Wigs, wiglets, toupees, hairpieces, manufacturing"},
{c:339999,d: "Wreaths, artificial, manufacturing"},
{c:423110,d: "All-terrain vehicles (ATVs) merchant wholesalers"},
{c:423110,d: "Ambulance merchant wholesalers"},
{c:423110,d: "Automobile auction merchant wholesalers"},
{c:423110,d: "Automobile merchant wholesalers"},
{c:423110,d: "Bicycle, motorized, merchant wholesalers"},
{c:423110,d: "Bodies, motor vehicle, merchant wholesalers"},
{c:423110,d: "Bus merchant wholesalers"},
{c:423110,d: "Camping trailer merchant wholesalers"},
{c:423110,d: "Cargo van merchant wholesalers"},
{c:423110,d: "Chassis, motor vehicle, merchant wholesalers"},
{c:423110,d: "Minivan merchant wholesalers"},
{c:423110,d: "Moped merchant wholesalers"},
{c:423110,d: "Motor home merchant wholesalers"},
{c:423110,d: "Motor vehicle merchant wholesalers"},
{c:423110,d: "Motorcycle merchant wholesalers"},
{c:423110,d: "Recreational vehicle merchant wholesalers"},
{c:423110,d: "School bus merchant wholesalers"},
{c:423110,d: "Snowmobile merchant wholesalers"},
{c:423110,d: "Special purpose highway vehicle merchant wholesalers"},
{c:423110,d: "Sport utility vehicle merchant wholesalers"},
{c:423110,d: "Taxicab merchant wholesalers"},
{c:423110,d: "Tractors, highway, merchant wholesalers"},
{c:423110,d: "Trailers, motor vehicle, merchant wholesalers"},
{c:423110,d: "Transportation equipment, motor vehicle, merchant wholesalers"},
{c:423110,d: "Travel trailer (e.g., tent trailers) merchant wholesalers"},
{c:423110,d: "Truck tractors, road, merchant wholesalers"},
{c:423110,d: "Truck trailer merchant wholesalers"},
{c:423110,d: "Trucks, road, merchant wholesalers"},
{c:423110,d: "Used car merchant wholesalers"},
{c:423110,d: "Utility trailer merchant wholesalers"},
{c:423110,d: "Vehicles, recreational, merchant wholesalers"},
{c:423120,d: "Aftermarket parts, automotive, merchant wholesalers"},
{c:423120,d: "Auto body shop supplies merchant wholesalers"},
{c:423120,d: "Automobile accessories (except tires, tubes) merchant wholesalers"},
{c:423120,d: "Automobile glass merchant wholesalers"},
{c:423120,d: "Automobile service station equipment merchant wholesalers"},
{c:423120,d: "Automotive brakes merchant wholesalers"},
{c:423120,d: "Automotive parts, new, merchant wholesalers"},
{c:423120,d: "Automotive stampings merchant wholesalers"},
{c:423120,d: "Balancing equipment, motor vehicle, merchant wholesalers"},
{c:423120,d: "Batteries, automotive, merchant wholesalers"},
{c:423120,d: "Engine testing equipment, motor vehicle, merchant wholesalers"},
{c:423120,d: "Engines and parts, automotive, new, merchant wholesalers"},
{c:423120,d: "Gasoline marketing equipment merchant wholesalers"},
{c:423120,d: "Gasoline measuring and dispensing pumps merchant wholesalers"},
{c:423120,d: "Gasoline service station equipment merchant wholesalers"},
{c:423120,d: "Generators, motor vehicle electrical, new, merchant wholesalers"},
{c:423120,d: "Glass, automotive, merchant wholesalers"},
{c:423120,d: "Handtools, motor vehicle mechanics', merchant wholesalers"},
{c:423120,d: "Hardware, motor vehicle, merchant wholesalers"},
{c:423120,d: "Hoists, automotive, merchant wholesalers"},
{c:423120,d: "Mechanic's tools merchant wholesalers"},
{c:423120,d: "Mirrors, automotive, merchant wholesalers"},
{c:423120,d: "Motor vehicle instruments, electric, merchant wholesalers"},
{c:423120,d: "Motor vehicle parts and accessories, new, merchant wholesalers"},
{c:423120,d: "Motorcycle parts, new, merchant wholesalers"},
{c:423120,d: "Mufflers, exhaust, merchant wholesalers"},
{c:423120,d: "Oil filters, automotive, merchant wholesalers"},
{c:423120,d: "Parts, new, motor vehicle, merchant wholesalers"},
{c:423120,d: "Pistons and valves, automotive, merchant wholesalers"},
{c:423120,d: "Pumps (e.g., fuel, oil, power steering, water), automotive, merchant wholesalers"},
{c:423120,d: "Radiators, motor vehicle, merchant wholesalers"},
{c:423120,d: "Seat belts, motor vehicle, merchant wholesalers"},
{c:423120,d: "Seat covers, automotive, merchant wholesalers"},
{c:423120,d: "Shop equipment, service station, merchant wholesalers"},
{c:423120,d: "Testing and measuring equipment, electrical, automotive, merchant wholesalers"},
{c:423120,d: "Tools and equipment, motor vehicle, merchant wholesalers"},
{c:423120,d: "Trailer parts, new, merchant wholesalers"},
{c:423120,d: "Truck parts, new, merchant wholesalers"},
{c:423120,d: "Voltage regulators, motor vehicle, merchant wholesalers"},
{c:423120,d: "Wheels, motor vehicle, new, merchant wholesalers"},
{c:423130,d: "Heavy truck tires and tubes merchant wholesalers"},
{c:423130,d: "Motor vehicle tire and tube merchant wholesalers"},
{c:423130,d: "Recapped tires merchant wholesalers"},
{c:423130,d: "Tire and tube repair materials merchant wholesalers"},
{c:423130,d: "Tire tubes, motor vehicle, merchant wholesalers"},
{c:423130,d: "Tires, new, motor vehicle, merchant wholesalers"},
{c:423130,d: "Tires, used (except scrap), merchant wholesalers"},
{c:423130,d: "Used tires, motor vehicle, merchant wholesalers"},
{c:423140,d: "Automotive parts, used, merchant wholesalers"},
{c:423140,d: "Generators, motor vehicle electrical, used, merchant wholesalers"},
{c:423140,d: "Junk yards, auto, merchant wholesalers"},
{c:423140,d: "Motor vehicle parts, used, merchant wholesalers"},
{c:423140,d: "Parts, used, motor vehicle, merchant wholesalers"},
{c:423140,d: "Used parts, motor vehicle, merchant wholesalers"},
{c:423210,d: "Antique furniture merchant wholesalers"},
{c:423210,d: "Baby furniture merchant wholesalers"},
{c:423210,d: "Beds (except hospital) merchant wholesalers"},
{c:423210,d: "Box springs merchant wholesalers"},
{c:423210,d: "Cabinets, kitchen, freestanding, merchant wholesalers"},
{c:423210,d: "Furniture (except drafting tables, hospital beds, medical furniture) merchant wholesalers"},
{c:423210,d: "Furniture parts merchant wholesalers"},
{c:423210,d: "Hotel furniture merchant wholesalers"},
{c:423210,d: "Household-type furniture merchant wholesalers"},
{c:423210,d: "Mattresses merchant wholesalers"},
{c:423210,d: "Office furniture merchant wholesalers"},
{c:423210,d: "Outdoor furniture merchant wholesalers"},
{c:423210,d: "Public building furniture merchant wholesalers"},
{c:423210,d: "Religious furniture merchant wholesalers"},
{c:423210,d: "Restaurant furniture merchant wholesalers"},
{c:423210,d: "School furniture merchant wholesalers"},
{c:423210,d: "Store furniture merchant wholesalers"},
{c:423210,d: "Theater seats merchant wholesalers"},
{c:423220,d: "Antique home furnishings merchant wholesalers"},
{c:423220,d: "Antique houseware merchant wholesalers"},
{c:423220,d: "Bathroom accessories merchant wholesalers"},
{c:423220,d: "Blankets (except electric) merchant wholesalers"},
{c:423220,d: "Blinds and shades, window, merchant wholesalers"},
{c:423220,d: "Brooms and brushes, household-type, merchant wholesalers"},
{c:423220,d: "Carpet merchant wholesalers"},
{c:423220,d: "Ceramic wall (except structural) and floor tile merchant wholesalers"},
{c:423220,d: "Chinaware, household-type, merchant wholesalers"},
{c:423220,d: "Closet accessories merchant wholesalers"},
{c:423220,d: "Containers, household (except paper and disposable plastics), merchant wholesalers"},
{c:423220,d: "Cooking utensils, household-type, merchant wholesalers"},
{c:423220,d: "Cups, plastics (except disposable), merchant wholesalers"},
{c:423220,d: "Curtains merchant wholesalers"},
{c:423220,d: "Dishes, household-type (except disposable plastics, paper), merchant wholesalers"},
{c:423220,d: "Draperies merchant wholesalers"},
{c:423220,d: "Fireplaces, prefabricated (except gas), merchant wholesalers"},
{c:423220,d: "Flatware (except plated, precious) merchant wholesalers"},
{c:423220,d: "Floor coverings merchant wholesalers"},
{c:423220,d: "Frames and pictures merchant wholesalers"},
{c:423220,d: "Furniture coverings and protectors merchant wholesalers"},
{c:423220,d: "Glassware, household-type, merchant wholesalers"},
{c:423220,d: "Hollowware (except precious metal) merchant wholesalers"},
{c:423220,d: "Home furnishings merchant wholesalers"},
{c:423220,d: "Housewares (except electric) merchant wholesalers"},
{c:423220,d: "Ironing boards merchant wholesalers"},
{c:423220,d: "Kitchen utensils, household-type, merchant wholesalers"},
{c:423220,d: "Lamps (i.e., lighting fixtures) merchant wholesalers"},
{c:423220,d: "Linens (e.g., bath, bed, table) merchant wholesalers"},
{c:423220,d: "Mirrors (except automotive) merchant wholesalers"},
{c:423220,d: "Mops, household, merchant wholesalers"},
{c:423220,d: "Napkins (except paper) merchant wholesalers"},
{c:423220,d: "Rugs merchant wholesalers"},
{c:423220,d: "Slipcovers merchant wholesalers"},
{c:423220,d: "Tableware (except disposable, plated, precious) merchant wholesalers"},
{c:423220,d: "Towels and washcloths merchant wholesalers"},
{c:423220,d: "Window shades and blinds merchant wholesalers"},
{c:423310,d: "Building board (e.g., fiber, flake, particle) merchant wholesalers"},
{c:423310,d: "Buildings, prefabricated wood, merchant wholesalers"},
{c:423310,d: "Cabinets, kitchen, built-in, merchant wholesalers"},
{c:423310,d: "Countertops (except granite) merchant wholesalers"},
{c:423310,d: "Doors and door frames merchant wholesalers"},
{c:423310,d: "Drywall board merchant wholesalers"},
{c:423310,d: "Fencing, wood, merchant wholesalers"},
{c:423310,d: "Fiberboard merchant wholesalers"},
{c:423310,d: "Flooring, wood, merchant wholesalers"},
{c:423310,d: "Kitchen cabinets, built-in, merchant wholesalers"},
{c:423310,d: "Laminates, wood, merchant wholesalers"},
{c:423310,d: "Lumber (e.g., dressed, finished, rough) merchant wholesalers"},
{c:423310,d: "Millwork merchant wholesalers"},
{c:423310,d: "Molding (e.g., sheet metal, wood) merchant wholesalers"},
{c:423310,d: "Paneling merchant wholesalers"},
{c:423310,d: "Particleboard merchant wholesalers"},
{c:423310,d: "Plywood merchant wholesalers"},
{c:423310,d: "Prefabricated buildings, wood, merchant wholesalers"},
{c:423310,d: "Railings, wood, merchant wholesalers"},
{c:423310,d: "Roofing materials, wood, merchant wholesalers"},
{c:423310,d: "Screens, window and door, merchant wholesalers"},
{c:423310,d: "Shingles, wood, merchant wholesalers"},
{c:423310,d: "Siding, wood, merchant wholesalers"},
{c:423310,d: "Stairs, wood, merchant wholesalers"},
{c:423310,d: "Structural assemblies, prefabricated wood, merchant wholesalers"},
{c:423310,d: "Veneer, wood, merchant wholesalers"},
{c:423310,d: "Wallboard merchant wholesalers"},
{c:423310,d: "Windows and window frames merchant wholesalers"},
{c:423310,d: "Wood fencing merchant wholesalers"},
{c:423310,d: "Wood flooring merchant wholesalers"},
{c:423310,d: "Wood shingles merchant wholesalers"},
{c:423310,d: "Wood siding merchant wholesalers"},
{c:423320,d: "Asphalt and concrete mixtures merchant wholesalers"},
{c:423320,d: "Bricks (except refractory) merchant wholesalers"},
{c:423320,d: "Building blocks (e.g., cinder, concrete) merchant wholesalers"},
{c:423320,d: "Building stone merchant wholesalers"},
{c:423320,d: "Cement merchant wholesalers"},
{c:423320,d: "Ceramic construction materials (except refractory and ceramic roofing tile) merchant wholesalers"},
{c:423320,d: "Clay construction materials (except refractory and clay roofing tile) merchant wholesalers"},
{c:423320,d: "Coal tar paving materials merchant wholesalers"},
{c:423320,d: "Concrete building products merchant wholesalers"},
{c:423320,d: "Concrete mixtures merchant wholesalers"},
{c:423320,d: "Conduit and pipe, concrete, merchant wholesalers"},
{c:423320,d: "Crushed stone merchant wholesalers"},
{c:423320,d: "Drywall supplies merchant wholesalers"},
{c:423320,d: "Fireplace linings merchant wholesalers"},
{c:423320,d: "Flue pipe and linings merchant wholesalers"},
{c:423320,d: "Gravel, construction, merchant wholesalers"},
{c:423320,d: "Lime (except agricultural) merchant wholesalers"},
{c:423320,d: "Mason's materials merchant wholesalers"},
{c:423320,d: "Paving mixtures merchant wholesalers"},
{c:423320,d: "Plaster merchant wholesalers"},
{c:423320,d: "Sand (except industrial) merchant wholesalers"},
{c:423320,d: "Septic tanks, concrete, merchant wholesalers"},
{c:423320,d: "Sewer pipe, clay (except refractory), merchant wholesalers"},
{c:423320,d: "Slate and slate products merchant wholesalers"},
{c:423320,d: "Stone, building or crushed, merchant wholesalers"},
{c:423320,d: "Structural clay tile (except refractory and clay roofing tile) merchant wholesalers"},
{c:423320,d: "Stucco merchant wholesalers"},
{c:423320,d: "Terra cotta merchant wholesalers"},
{c:423320,d: "Tile, structural clay (except refractory and clay roofing tile) merchant wholesalers"},
{c:423330,d: "Asphalt felts and coatings merchant wholesalers"},
{c:423330,d: "Asphalt roofing shingles merchant wholesalers"},
{c:423330,d: "Ceramic and clay roofing materials merchant wholesalers"},
{c:423330,d: "Eavestroughing merchant wholesalers"},
{c:423330,d: "Fascia, building (except wood), merchant wholesalers"},
{c:423330,d: "Gutters and down spouts (except wood) merchant wholesalers"},
{c:423330,d: "House wrapping insulation materials merchant wholesalers"},
{c:423330,d: "Insulation materials (except wood) merchant wholesalers"},
{c:423330,d: "Roofing materials (except wood) merchant wholesalers"},
{c:423330,d: "Sheet metal roofing materials merchant wholesalers"},
{c:423330,d: "Shingles (except wood) merchant wholesalers"},
{c:423330,d: "Siding (except wood) merchant wholesalers"},
{c:423330,d: "Soffit, building (except wood), merchant wholesalers"},
{c:423330,d: "Solar reflective film merchant wholesalers"},
{c:423330,d: "Sound insulation merchant wholesalers"},
{c:423330,d: "Tarred felts merchant wholesalers"},
{c:423330,d: "Thermal wrap, house, merchant wholesalers"},
{c:423330,d: "Vinyl siding merchant wholesalers"},
{c:423330,d: "Weather stripping merchant wholesalers"},
{c:423390,d: "Architectural metalwork merchant wholesalers"},
{c:423390,d: "Awnings (except canvas) merchant wholesalers"},
{c:423390,d: "Bailey bridges merchant wholesalers"},
{c:423390,d: "Bins, storage, merchant wholesalers"},
{c:423390,d: "Building materials, fiberglass (except insulation, roofing, siding), merchant wholesalers"},
{c:423390,d: "Building paper merchant wholesalers"},
{c:423390,d: "Buildings, prefabricated nonwood, merchant wholesalers"},
{c:423390,d: "Ceiling tile merchant wholesalers"},
{c:423390,d: "Fencing (except wood) merchant wholesalers"},
{c:423390,d: "Fencing and fencing accessories, wire, merchant wholesalers"},
{c:423390,d: "Fiberglass building materials (except insulation, roofing, siding) merchant wholesalers"},
{c:423390,d: "Flat glass merchant wholesalers"},
{c:423390,d: "Gate and fence hardware merchant wholesalers"},
{c:423390,d: "Glass, block and brick, merchant wholesalers"},
{c:423390,d: "Glass, plate, merchant wholesalers"},
{c:423390,d: "Gypsum building products merchant wholesalers"},
{c:423390,d: "Iron and steel architectural shapes merchant wholesalers"},
{c:423390,d: "Manufactured (i.e., mobile) homes merchant wholesalers"},
{c:423390,d: "Mastics, construction, merchant wholesalers"},
{c:423390,d: "Metal buildings merchant wholesalers"},
{c:423390,d: "Ornamental ironwork merchant wholesalers"},
{c:423390,d: "Paper, building, merchant wholesalers"},
{c:423390,d: "Plate glass merchant wholesalers"},
{c:423390,d: "Prefabricated buildings (except wood) merchant wholesalers"},
{c:423390,d: "Septic tanks (except concrete) merchant wholesalers"},
{c:423390,d: "Silt fence and other fabrics (e.g., for erosion control) merchant wholesalers"},
{c:423390,d: "Storage bins merchant wholesalers"},
{c:423390,d: "Structural assemblies, prefabricated (except wood), merchant wholesalers"},
{c:423390,d: "Wire fencing and fencing accessories merchant wholesalers"},
{c:423410,d: "Camcorders (except household-type) merchant wholesalers"},
{c:423410,d: "Camera equipment and supplies, photographic, merchant wholesalers"},
{c:423410,d: "Cameras, television, merchant wholesalers"},
{c:423410,d: "Cameras, video (except household-type), merchant wholesalers"},
{c:423410,d: "Charged coupled devices (CCD) merchant wholesalers"},
{c:423410,d: "Developing apparatus, photographic, merchant wholesalers"},
{c:423410,d: "Film developing equipment merchant wholesalers"},
{c:423410,d: "Film finishing equipment merchant wholesalers"},
{c:423410,d: "Film, camera, merchant wholesalers"},
{c:423410,d: "Film, photographic, merchant wholesalers"},
{c:423410,d: "Identity recorders merchant wholesalers"},
{c:423410,d: "Motion picture cameras, equipment, and supplies merchant wholesalers"},
{c:423410,d: "Photofinishing equipment merchant wholesalers"},
{c:423410,d: "Photographic equipment and supplies merchant wholesalers"},
{c:423410,d: "Photographic film and plates merchant wholesalers"},
{c:423410,d: "Printmaking apparatus, photographic, merchant wholesalers"},
{c:423410,d: "Processing and finishing equipment, photographic, merchant wholesalers"},
{c:423410,d: "Projection equipment (e.g., motion picture, slide), photographic, merchant wholesalers"},
{c:423410,d: "Television cameras merchant wholesalers"},
{c:423410,d: "Theater equipment (except seats) merchant wholesalers"},
{c:423410,d: "Video cameras (except household-type) merchant wholesalers"},
{c:423420,d: "Accounting machines merchant wholesalers"},
{c:423420,d: "Addressing machines merchant wholesalers"},
{c:423420,d: "Automatic teller machines (ATM) merchant wholesalers"},
{c:423420,d: "Bank equipment merchant wholesalers"},
{c:423420,d: "Blueprinting equipment merchant wholesalers"},
{c:423420,d: "Bookkeeping machines merchant wholesalers"},
{c:423420,d: "Business machines and equipment (except computers) merchant wholesalers"},
{c:423420,d: "Calculators and calculating machines merchant wholesalers"},
{c:423420,d: "Cash registers merchant wholesalers"},
{c:423420,d: "Check handling machines merchant wholesalers"},
{c:423420,d: "Coin sorting machines merchant wholesalers"},
{c:423420,d: "Copying machines merchant wholesalers"},
{c:423420,d: "Currency handling machines merchant wholesalers"},
{c:423420,d: "Dictating machines merchant wholesalers"},
{c:423420,d: "Forms handling machines merchant wholesalers"},
{c:423420,d: "Labeling machines merchant wholesalers"},
{c:423420,d: "Letter and envelope handling machines merchant wholesalers"},
{c:423420,d: "Machines, office, merchant wholesalers"},
{c:423420,d: "Mailing machines merchant wholesalers"},
{c:423420,d: "Microfilm equipment and supplies merchant wholesalers"},
{c:423420,d: "Office equipment merchant wholesalers"},
{c:423420,d: "Office machines merchant wholesalers"},
{c:423420,d: "Paper shredders merchant wholesalers"},
{c:423420,d: "Perforating machines merchant wholesalers"},
{c:423420,d: "Point of sale terminals merchant wholesalers"},
{c:423420,d: "Postage meters merchant wholesalers"},
{c:423420,d: "Safes, security, merchant wholesalers"},
{c:423420,d: "Security safes merchant wholesalers"},
{c:423420,d: "Stapling machines merchant wholesalers"},
{c:423420,d: "Tape dispensing machines merchant wholesalers"},
{c:423420,d: "Time recording machines merchant wholesalers"},
{c:423420,d: "Typewriters merchant wholesalers"},
{c:423430,d: "Computer boards, loaded, merchant wholesalers"},
{c:423430,d: "Computer data storage devices merchant wholesalers"},
{c:423430,d: "Computer peripheral equipment merchant wholesalers"},
{c:423430,d: "Computer printers merchant wholesalers"},
{c:423430,d: "Computer software, packaged, merchant wholesalers"},
{c:423430,d: "Computer terminals merchant wholesalers"},
{c:423430,d: "Computers merchant wholesalers"},
{c:423430,d: "Data keying equipment merchant wholesalers"},
{c:423430,d: "Data processing machines, computer, merchant wholesalers"},
{c:423430,d: "Digitizer and light pen tables merchant wholesalers"},
{c:423430,d: "Disk drives, computer, merchant wholesalers"},
{c:423430,d: "Diskettes, blank computer, merchant wholesalers"},
{c:423430,d: "Font cartridges merchant wholesalers"},
{c:423430,d: "Game software merchant wholesalers"},
{c:423430,d: "Keyboards, computer, merchant wholesalers"},
{c:423430,d: "Loaded computer boards merchant wholesalers"},
{c:423430,d: "Memory boards merchant wholesalers"},
{c:423430,d: "Microprocessors merchant wholesalers"},
{c:423430,d: "Monitor screen projection devices merchant wholesalers"},
{c:423430,d: "Monitors, computer, merchant wholesalers"},
{c:423430,d: "Motherboards, loaded, merchant wholesalers"},
{c:423430,d: "Peripheral equipment, computer, merchant wholesalers"},
{c:423430,d: "Plotters merchant wholesalers"},
{c:423430,d: "Printers, computer, merchant wholesalers"},
{c:423430,d: "Scanners, computer, merchant wholesalers"},
{c:423430,d: "Software, computer, packaged, merchant wholesalers"},
{c:423430,d: "Sound boards, computer, merchant wholesalers"},
{c:423430,d: "Video boards merchant wholesalers"},
{c:423430,d: "Voice recognition equipment merchant wholesalers"},
{c:423440,d: "Balances and scales (except laboratory) merchant wholesalers"},
{c:423440,d: "Bar equipment merchant wholesalers"},
{c:423440,d: "Chinaware, commercial, merchant wholesalers"},
{c:423440,d: "Coffee makers, commercial, merchant wholesalers"},
{c:423440,d: "Coin-operated merchandising machine merchant wholesalers"},
{c:423440,d: "Coin-operated phonographs and vending machines merchant wholesalers"},
{c:423440,d: "Cooking equipment, commercial, merchant wholesalers"},
{c:423440,d: "Cups, commercial (except paper and disposable plastics), merchant wholesalers"},
{c:423440,d: "Dishes, commercial (except paper and disposable plastics), merchant wholesalers"},
{c:423440,d: "Dishwashing equipment, commercial-type, merchant wholesalers"},
{c:423440,d: "Display cases (except refrigerated) merchant wholesalers"},
{c:423440,d: "Electrical signs merchant wholesalers"},
{c:423440,d: "Fixtures, store (except refrigerated), merchant wholesalers"},
{c:423440,d: "Food service equipment (except refrigerated), commercial, merchant wholesalers"},
{c:423440,d: "Garbage disposal units, commercial-type, merchant wholesalers"},
{c:423440,d: "Hotel equipment and supplies (except furniture) merchant wholesalers"},
{c:423440,d: "Kitchen appliances, commercial (except refrigerated), merchant wholesalers"},
{c:423440,d: "Kitchen utensils, commercial, merchant wholesalers"},
{c:423440,d: "Lockers (except refrigerated) merchant wholesalers"},
{c:423440,d: "Mannequins merchant wholesalers"},
{c:423440,d: "Merchandising machines, coin-operated, merchant wholesalers"},
{c:423440,d: "Microwave ovens, commercial, merchant wholesalers"},
{c:423440,d: "Ovens, commercial-type, merchant wholesalers"},
{c:423440,d: "Pans, commercial, merchant wholesalers"},
{c:423440,d: "Partitions merchant wholesalers"},
{c:423440,d: "Phonographs, coin-operated, merchant wholesalers"},
{c:423440,d: "Restaurant equipment (except furniture) merchant wholesalers"},
{c:423440,d: "Shelving, commercial, merchant wholesalers"},
{c:423440,d: "Showcases (except refrigerated) merchant wholesalers"},
{c:423440,d: "Signs, electrical, merchant wholesalers"},
{c:423440,d: "Soda fountain fixtures (except refrigerated) merchant wholesalers"},
{c:423440,d: "Store equipment (except furniture) merchant wholesalers"},
{c:423440,d: "Store fixtures (except refrigerated) merchant wholesalers"},
{c:423440,d: "Vending machines merchant wholesalers"},
{c:423440,d: "Window display equipment merchant wholesalers"},
{c:423440,d: "Woodenware, commercial, merchant wholesalers"},
{c:423450,d: "Appliances, surgical, merchant wholesalers"},
{c:423450,d: "Artificial limbs merchant wholesalers"},
{c:423450,d: "Beds, hospital, merchant wholesalers"},
{c:423450,d: "Crutches merchant wholesalers"},
{c:423450,d: "Dental chairs merchant wholesalers"},
{c:423450,d: "Dental equipment and supplies merchant wholesalers"},
{c:423450,d: "Dentists' professional supplies merchant wholesalers"},
{c:423450,d: "Diagnostic equipment, medical, merchant wholesalers"},
{c:423450,d: "Dressings, medical, merchant wholesalers"},
{c:423450,d: "Electromedical equipment merchant wholesalers"},
{c:423450,d: "First-aid kits, industrial, merchant wholesalers"},
{c:423450,d: "Glassware, medical, merchant wholesalers"},
{c:423450,d: "Hearing aids merchant wholesalers"},
{c:423450,d: "Home health care supplies merchant wholesalers"},
{c:423450,d: "Hospital beds merchant wholesalers"},
{c:423450,d: "Hospital equipment and supplies merchant wholesalers"},
{c:423450,d: "Hospital furniture merchant wholesalers"},
{c:423450,d: "Hospital gowns merchant wholesalers"},
{c:423450,d: "Industrial safety devices (e.g., eye shields, face shields, first-aid kits) merchant wholesalers"},
{c:423450,d: "Instruments, dental and medical, merchant wholesalers"},
{c:423450,d: "Laboratory equipment, dental and medical, merchant wholesalers"},
{c:423450,d: "Medical equipment merchant wholesalers"},
{c:423450,d: "Medical furniture merchant wholesalers"},
{c:423450,d: "Medical glassware merchant wholesalers"},
{c:423450,d: "Medical instruments merchant wholesalers"},
{c:423450,d: "Medical supplies (except household first-aid kits and non-surgical bandages) merchant wholesalers"},
{c:423450,d: "Orthopedic equipment and supplies merchant wholesalers"},
{c:423450,d: "Patient monitoring equipment merchant wholesalers"},
{c:423450,d: "Physicians' equipment and supplies merchant wholesalers"},
{c:423450,d: "Prosthetic appliances and supplies merchant wholesalers"},
{c:423450,d: "Surgical appliances merchant wholesalers"},
{c:423450,d: "Surgical dressings merchant wholesalers"},
{c:423450,d: "Surgical instruments and apparatus merchant wholesalers"},
{c:423450,d: "Surgical supplies merchant wholesalers"},
{c:423450,d: "Surgical towels merchant wholesalers"},
{c:423450,d: "Tapes, medical and surgical, merchant wholesalers"},
{c:423450,d: "Teeth, dental, merchant wholesalers"},
{c:423450,d: "Therapy equipment merchant wholesalers"},
{c:423450,d: "Thermometers merchant wholesalers"},
{c:423450,d: "Towels, surgical, merchant wholesalers"},
{c:423450,d: "Ultrasound equipment, medical, merchant wholesalers"},
{c:423450,d: "Wheelchairs merchant wholesalers"},
{c:423450,d: "Whirlpool baths, hospital, merchant wholesalers"},
{c:423450,d: "X-ray machines and parts, medical and dental, merchant wholesalers"},
{c:423460,d: "Binoculars merchant wholesalers"},
{c:423460,d: "Contact lenses merchant wholesalers"},
{c:423460,d: "Eyeglasses merchant wholesalers"},
{c:423460,d: "Frames, ophthalmic, merchant wholesalers"},
{c:423460,d: "Glasses, optical, merchant wholesalers"},
{c:423460,d: "Lens blanks, ophthalmic, merchant wholesalers"},
{c:423460,d: "Lenses, optical, merchant wholesalers"},
{c:423460,d: "Magnifying glasses merchant wholesalers"},
{c:423460,d: "Ophthalmic goods (except cameras) merchant wholesalers"},
{c:423460,d: "Optical goods (except cameras) merchant wholesalers"},
{c:423460,d: "Optometric equipment and supplies merchant wholesalers"},
{c:423460,d: "Professional equipment and supplies, optical, merchant wholesalers"},
{c:423460,d: "Sunglasses merchant wholesalers"},
{c:423490,d: "Analytical instruments (e.g., chromatographic, photometers, spectrographs) merchant wholesalers"},
{c:423490,d: "Architects' equipment and supplies merchant wholesalers"},
{c:423490,d: "Balances and scales, laboratory (except dental, medical), merchant wholesalers"},
{c:423490,d: "Barometers merchant wholesalers"},
{c:423490,d: "Blackboards merchant wholesalers"},
{c:423490,d: "Chemical and technical pottery products merchant wholesalers"},
{c:423490,d: "Church supplies (except plated ware, silverware) merchant wholesalers"},
{c:423490,d: "Drafting instruments merchant wholesalers"},
{c:423490,d: "Drafting tables merchant wholesalers"},
{c:423490,d: "Engineers' equipment and supplies merchant wholesalers"},
{c:423490,d: "Instruments, professional and scientific, merchant wholesalers"},
{c:423490,d: "Laboratory equipment (except dental, medical, ophthalmic) merchant wholesalers"},
{c:423490,d: "Professional equipment and supplies (except dental, medical, ophthalmic) merchant wholesalers"},
{c:423490,d: "Professional furniture (except dental, metal, ophthalmic, and school) merchant wholesalers"},
{c:423490,d: "Professional instruments merchant wholesalers"},
{c:423490,d: "Religious supplies merchant wholesalers"},
{c:423490,d: "Scales, laboratory (except dental and medical), merchant wholesalers"},
{c:423490,d: "School equipment and supplies (except books, furniture) merchant wholesalers"},
{c:423490,d: "Scientific instruments merchant wholesalers"},
{c:423490,d: "Scientific laboratory equipment merchant wholesalers"},
{c:423490,d: "Smartboards merchant wholesalers"},
{c:423490,d: "Statuary, religious, merchant wholesalers"},
{c:423490,d: "Surveying equipment and supplies merchant wholesalers"},
{c:423490,d: "Teaching machines (except computers), electronic, merchant wholesalers"},
{c:423490,d: "Vestments, religious, merchant wholesalers"},
{c:423490,d: "Veterinarians' equipment and supplies merchant wholesalers"},
{c:423490,d: "Wall maps merchant wholesalers"},
{c:423510,d: "Aluminum and aluminum alloy primary forms merchant wholesalers"},
{c:423510,d: "Aluminum foil, plate, sheet, coil, and roll products merchant wholesalers"},
{c:423510,d: "Bars, metal (except precious), merchant wholesalers"},
{c:423510,d: "Bearing piles, metal, merchant wholesalers"},
{c:423510,d: "Billets, metal, merchant wholesalers"},
{c:423510,d: "Black plate merchant wholesalers"},
{c:423510,d: "Blooms, metal, merchant wholesalers"},
{c:423510,d: "Cable, wire (except insulated), merchant wholesalers"},
{c:423510,d: "Carbon and alloy steel primary forms merchant wholesalers"},
{c:423510,d: "Cast iron pipe merchant wholesalers"},
{c:423510,d: "Castings, metal, merchant wholesalers"},
{c:423510,d: "Concrete reinforcing bars merchant wholesalers"},
{c:423510,d: "Copper and copper alloy primary forms merchant wholesalers"},
{c:423510,d: "Ferroalloys merchant wholesalers"},
{c:423510,d: "Ferrous metals merchant wholesalers"},
{c:423510,d: "Forgings, metal, merchant wholesalers"},
{c:423510,d: "Foundry products merchant wholesalers"},
{c:423510,d: "Galvanized iron and steel products merchant wholesalers"},
{c:423510,d: "Grinding balls merchant wholesalers"},
{c:423510,d: "Ingots (except precious) merchant wholesalers"},
{c:423510,d: "Lead primary forms merchant wholesalers"},
{c:423510,d: "Metal pipe merchant wholesalers"},
{c:423510,d: "Metal products (e.g., bars, ingots, plates, rods, shapes, sheets) merchant wholesalers"},
{c:423510,d: "Metals sales offices"},
{c:423510,d: "Metals service centers"},
{c:423510,d: "Metals, ferrous and nonferrous, merchant wholesalers"},
{c:423510,d: "Nails merchant wholesalers"},
{c:423510,d: "Nickel and nickel alloy primary forms merchant wholesalers"},
{c:423510,d: "Nonferrous metals (except precious) merchant wholesalers"},
{c:423510,d: "Noninsulated wire merchant wholesalers"},
{c:423510,d: "Pig iron merchant wholesalers"},
{c:423510,d: "Pilings, metal, merchant wholesalers"},
{c:423510,d: "Pipe, metal, merchant wholesalers"},
{c:423510,d: "Poles, metal, merchant wholesalers"},
{c:423510,d: "Posts, metal, merchant wholesalers"},
{c:423510,d: "Rails and accessories, metal, merchant wholesalers"},
{c:423510,d: "Reinforcement mesh and wire merchant wholesalers"},
{c:423510,d: "Rods, metal (except precious), merchant wholesalers"},
{c:423510,d: "Ropes, wire (except insulated), merchant wholesalers"},
{c:423510,d: "Semi-finished metal products merchant wholesalers"},
{c:423510,d: "Sewer pipe, metal, merchant wholesalers"},
{c:423510,d: "Spikes, metal, merchant wholesalers"},
{c:423510,d: "Springs, steel, merchant wholesalers"},
{c:423510,d: "Stainless steel merchant wholesalers"},
{c:423510,d: "Steel merchant wholesalers"},
{c:423510,d: "Steel wire cloth (screening) merchant wholesalers"},
{c:423510,d: "Steel wool merchant wholesalers"},
{c:423510,d: "Storage tanks, metal, merchant wholesalers"},
{c:423510,d: "Strips, metal (except precious), merchant wholesalers"},
{c:423510,d: "Structural assemblies, metal, merchant wholesalers"},
{c:423510,d: "Tanks, storage metal, merchant wholesalers"},
{c:423510,d: "Terneplate merchant wholesalers"},
{c:423510,d: "Tin and tin alloy primary forms merchant wholesalers"},
{c:423510,d: "Tin plate merchant wholesalers"},
{c:423510,d: "Tubing, metal, merchant wholesalers"},
{c:423510,d: "Wire (except insulated) merchant wholesalers"},
{c:423510,d: "Wire and cable (except electrical) merchant wholesalers"},
{c:423510,d: "Wire rope (except insulated) merchant wholesalers"},
{c:423510,d: "Wire screening merchant wholesalers"},
{c:423510,d: "Zinc and zinc alloy primary forms merchant wholesalers"},
{c:423520,d: "Bauxite merchant wholesalers"},
{c:423520,d: "Coal dust merchant wholesalers"},
{c:423520,d: "Coal merchant wholesalers"},
{c:423520,d: "Coke merchant wholesalers"},
{c:423520,d: "Concentrates, metallic, merchant wholesalers"},
{c:423520,d: "Fuel, coal and coke, merchant wholesalers"},
{c:423520,d: "Fuller's earth merchant wholesalers"},
{c:423520,d: "Magnesium ores merchant wholesalers"},
{c:423520,d: "Metal ores merchant wholesalers"},
{c:423520,d: "Metallic concentrates merchant wholesalers"},
{c:423520,d: "Minerals (except construction materials, petroleum) merchant wholesalers"},
{c:423520,d: "Nonmetallic minerals (except precious and semiprecious stones and minerals used in construction, such as sand and gravel)"},
{c:423520,d: "Nonmetallic ores merchant wholesalers"},
{c:423520,d: "Ore concentrates merchant wholesalers"},
{c:423520,d: "Ores (e.g., gold, iron, lead, silver, zinc) merchant wholesalers"},
{c:423520,d: "Precious metal ores merchant wholesalers"},
{c:423610,d: "Alarm apparatus, electric, merchant wholesalers"},
{c:423610,d: "Batteries (except automotive) merchant wholesalers"},
{c:423610,d: "Boxes and fittings, electrical, merchant wholesalers"},
{c:423610,d: "Busbars and trolley ducts merchant wholesalers"},
{c:423610,d: "Capacitors (except electronic) merchant wholesalers"},
{c:423610,d: "Circuit breakers merchant wholesalers"},
{c:423610,d: "Coaxial cable merchant wholesalers"},
{c:423610,d: "Conduit, electric wire and cable, merchant wholesalers"},
{c:423610,d: "Conduits and raceways, electrical, merchant wholesalers"},
{c:423610,d: "Connectors, electrical, merchant wholesalers"},
{c:423610,d: "Construction materials, electrical, merchant wholesalers"},
{c:423610,d: "Current-carrying wiring devices merchant wholesalers"},
{c:423610,d: "Distribution equipment, electrical, merchant wholesalers"},
{c:423610,d: "Electric light fixtures merchant wholesalers"},
{c:423610,d: "Electric motors, wiring supplies, and lighting fixtures merchant wholesalers"},
{c:423610,d: "Electric prime movers merchant wholesalers"},
{c:423610,d: "Electrical apparatus merchant wholesalers"},
{c:423610,d: "Fastening devices, electrical, merchant wholesalers"},
{c:423610,d: "Fittings, electrical, merchant wholesalers"},
{c:423610,d: "Fixtures, electric lighting, merchant wholesalers"},
{c:423610,d: "Flashlights merchant wholesalers"},
{c:423610,d: "Fuses, electric, merchant wholesalers"},
{c:423610,d: "Generators, electrical (except motor vehicle), merchant wholesalers"},
{c:423610,d: "Hanging devices, electrical, merchant wholesalers"},
{c:423610,d: "Hardware, transmission pole and line, merchant wholesalers"},
{c:423610,d: "Industrial controls, electrical, merchant wholesalers"},
{c:423610,d: "Instruments, electric measuring, merchant wholesalers"},
{c:423610,d: "Insulated wire or cable merchant wholesalers"},
{c:423610,d: "Insulators, electrical, merchant wholesalers"},
{c:423610,d: "Light bulbs merchant wholesalers"},
{c:423610,d: "Lighting fixtures, electric, merchant wholesalers"},
{c:423610,d: "Lightning arrestors merchant wholesalers"},
{c:423610,d: "Lugs and connectors, electrical, merchant wholesalers"},
{c:423610,d: "Meters, electrical, merchant wholesalers"},
{c:423610,d: "Motor controls, electric, merchant wholesalers"},
{c:423610,d: "Motors, electric, merchant wholesalers"},
{c:423610,d: "Panelboards, electric power distribution, merchant wholesalers"},
{c:423610,d: "Pole line hardware merchant wholesalers"},
{c:423610,d: "Power transmission equipment, electrical, merchant wholesalers"},
{c:423610,d: "Receptacles, electrical, merchant wholesalers"},
{c:423610,d: "Regulators, voltage (except motor vehicle), merchant wholesalers"},
{c:423610,d: "Relays merchant wholesalers"},
{c:423610,d: "Security systems merchant wholesalers"},
{c:423610,d: "Signal systems and devices merchant wholesalers"},
{c:423610,d: "Storage batteries (except automotive) merchant wholesalers"},
{c:423610,d: "Switchboards, electrical distribution, merchant wholesalers"},
{c:423610,d: "Switches, electrical, merchant wholesalers"},
{c:423610,d: "Transformers (except electronic) merchant wholesalers"},
{c:423610,d: "Transmission equipment, electrical, merchant wholesalers"},
{c:423610,d: "Unit substations, electrical, merchant wholesalers"},
{c:423610,d: "Voltage regulators (except motor vehicle) merchant wholesalers"},
{c:423610,d: "Wire, insulated, merchant wholesalers"},
{c:423610,d: "Wiring supplies merchant wholesalers"},
{c:423620,d: "Air-conditioners, room, merchant wholesalers"},
{c:423620,d: "Appliances, household-type (except water heaters, heating stoves (i.e., noncooking)), gas and electric, merchant wholesalers"},
{c:423620,d: "Audio equipment, household-type, merchant wholesalers"},
{c:423620,d: "Automotive radios merchant wholesalers"},
{c:423620,d: "Blankets, electric, merchant wholesalers"},
{c:423620,d: "Clothes dryers merchant wholesalers"},
{c:423620,d: "Coffee makers, household-type, merchant wholesalers"},
{c:423620,d: "Compact disc players merchant wholesalers"},
{c:423620,d: "Consumer electronics merchant wholesalers"},
{c:423620,d: "Cooking equipment, gas and electric, household-type, merchant wholesalers"},
{c:423620,d: "Curling irons, electric, merchant wholesalers"},
{c:423620,d: "Digital video disc (DVD) players merchant wholesalers"},
{c:423620,d: "Dishwashers, household-type, merchant wholesalers"},
{c:423620,d: "Dryers, clothes, merchant wholesalers"},
{c:423620,d: "Dryers, hair, merchant wholesalers"},
{c:423620,d: "Electric blankets merchant wholesalers"},
{c:423620,d: "Fans, household-type, merchant wholesalers"},
{c:423620,d: "Freezers, household-type, merchant wholesalers"},
{c:423620,d: "Garbage disposal units, household-type, merchant wholesalers"},
{c:423620,d: "Hair dryers, personal, merchant wholesalers"},
{c:423620,d: "Heaters, portable electric, merchant wholesalers"},
{c:423620,d: "Household-type appliances (except water heaters, heating stoves (i.e., noncooking)), gas and electric, merchant wholesalers"},
{c:423620,d: "Household-type laundry equipment (e.g., dryers, washers) merchant wholesalers"},
{c:423620,d: "Housewares, gas and electric, merchant wholesalers"},
{c:423620,d: "Humidifiers and dehumidifiers, portable, merchant wholesalers"},
{c:423620,d: "Insect control devices, electric, merchant wholesalers"},
{c:423620,d: "Irons, household-type, electric, merchant wholesalers"},
{c:423620,d: "Kitchen appliances, household-type, gas and electric, merchant wholesalers"},
{c:423620,d: "Laundry machinery and equipment, household-type (e.g., dryers, washers), merchant wholesalers"},
{c:423620,d: "Microwave ovens, household-type, merchant wholesalers"},
{c:423620,d: "Movie apparatus, home, merchant wholesalers"},
{c:423620,d: "Outdoor grills merchant wholesalers"},
{c:423620,d: "Ovens, gas and electric, household-type, merchant wholesalers"},
{c:423620,d: "Personal care appliances, electric, merchant wholesalers"},
{c:423620,d: "Radios, household-type, merchant wholesalers"},
{c:423620,d: "Ranges, gas and electric, merchant wholesalers"},
{c:423620,d: "Razors, electric, merchant wholesalers"},
{c:423620,d: "Recorders (e.g., tape, video), household-type, merchant wholesalers"},
{c:423620,d: "Refrigerators, household-type, merchant wholesalers"},
{c:423620,d: "Room air-conditioners merchant wholesalers"},
{c:423620,d: "Sewing machines, household-type, merchant wholesalers"},
{c:423620,d: "Smoke detectors, household-type, merchant wholesalers"},
{c:423620,d: "Sound equipment, household-type, merchant wholesalers"},
{c:423620,d: "Speaker systems merchant wholesalers"},
{c:423620,d: "Stereo equipment merchant wholesalers"},
{c:423620,d: "Stoves, cooking, household-type, merchant wholesalers"},
{c:423620,d: "Tape players and recorders, household-type, merchant wholesalers"},
{c:423620,d: "Telephone answering machines merchant wholesalers"},
{c:423620,d: "Television sets merchant wholesalers"},
{c:423620,d: "Toasters, electric, merchant wholesalers"},
{c:423620,d: "Toothbrushes, electric, merchant wholesalers"},
{c:423620,d: "Trash compactors, household-type, merchant wholesalers"},
{c:423620,d: "Vacuum cleaners, household-type, merchant wholesalers"},
{c:423620,d: "Video cameras, household-type, merchant wholesalers"},
{c:423620,d: "Washing machines, household-type, merchant wholesalers"},
{c:423690,d: "Amplifiers, audio (except household-type), merchant wholesalers"},
{c:423690,d: "Antennas merchant wholesalers"},
{c:423690,d: "Audiotapes, blank, merchant wholesalers"},
{c:423690,d: "Automatic call distributors merchant wholesalers"},
{c:423690,d: "Bipolar transistors merchant wholesalers"},
{c:423690,d: "Blank CDs and DVDs merchant wholesalers"},
{c:423690,d: "Blank diskettes merchant wholesalers"},
{c:423690,d: "Blank tapes, audio and video, merchant wholesalers"},
{c:423690,d: "Broadcasting equipment merchant wholesalers"},
{c:423690,d: "Capacitors, electronic, merchant wholesalers"},
{c:423690,d: "Carbon monoxide detectors, electronic, merchant wholesalers"},
{c:423690,d: "Cellular telephones merchant wholesalers"},
{c:423690,d: "Circuit boards merchant wholesalers"},
{c:423690,d: "Circuits, integrated, merchant wholesalers"},
{c:423690,d: "Citizens' band (CB) radios merchant wholesalers"},
{c:423690,d: "Coils, electronic, merchant wholesalers"},
{c:423690,d: "Communications equipment merchant wholesalers"},
{c:423690,d: "Computer boards, unloaded, merchant wholesalers"},
{c:423690,d: "Computer chips merchant wholesalers"},
{c:423690,d: "Condensers, electronic, merchant wholesalers"},
{c:423690,d: "Connectors, electronic, merchant wholesalers"},
{c:423690,d: "Diodes merchant wholesalers"},
{c:423690,d: "Diskettes, blank, merchant wholesalers"},
{c:423690,d: "Electronic aircraft instruments merchant wholesalers"},
{c:423690,d: "Electronic communications equipment merchant wholesalers"},
{c:423690,d: "Electronic parts (e.g., condensers, connectors, switches) merchant wholesalers"},
{c:423690,d: "Electronic sound equipment (except household-type and automotive) merchant wholesalers"},
{c:423690,d: "Electronic tubes (e.g., industrial, receiving, transmitting) merchant wholesalers"},
{c:423690,d: "Facsimile machines merchant wholesalers"},
{c:423690,d: "Integrated circuits merchant wholesalers"},
{c:423690,d: "Klystron tubes merchant wholesalers"},
{c:423690,d: "Liquid crystal displays merchant wholesalers"},
{c:423690,d: "Magnetic bubble memories merchant wholesalers"},
{c:423690,d: "Magnetic tapes, cassettes, and disks, blank, merchant wholesalers"},
{c:423690,d: "Modems merchant wholesalers"},
{c:423690,d: "Natural gas detectors, electronic, merchant wholesalers"},
{c:423690,d: "Navigational instruments, electronic (e.g., radar, sonar), merchant wholesalers"},
{c:423690,d: "Printed circuit boards merchant wholesalers"},
{c:423690,d: "Public address systems and equipment merchant wholesalers"},
{c:423690,d: "Radar equipment merchant wholesalers"},
{c:423690,d: "Radio frequency identification (RFID) equipment merchant wholesalers"},
{c:423690,d: "Radio parts and accessories (e.g., transistors, tubes) merchant wholesalers"},
{c:423690,d: "Radios (except household-type) merchant wholesalers"},
{c:423690,d: "Random access memory (RAM) chips merchant wholesalers"},
{c:423690,d: "Rectifiers, electronic, merchant wholesalers"},
{c:423690,d: "Resistors, electronic, merchant wholesalers"},
{c:423690,d: "Semiconductor devices merchant wholesalers"},
{c:423690,d: "Smartphones merchant wholesalers"},
{c:423690,d: "Solar cells merchant wholesalers"},
{c:423690,d: "Sonar equipment merchant wholesalers"},
{c:423690,d: "Switches, electronic, merchant wholesalers"},
{c:423690,d: "Tapes, blank, audio and video, merchant wholesalers"},
{c:423690,d: "Telecommunications equipment merchant wholesalers"},
{c:423690,d: "Teleconferencing equipment, audio or video, merchant wholesalers"},
{c:423690,d: "Telegraph equipment merchant wholesalers"},
{c:423690,d: "Telephone equipment merchant wholesalers"},
{c:423690,d: "Telephones merchant wholesalers"},
{c:423690,d: "Transformers, electronic, merchant wholesalers"},
{c:423690,d: "Transistors merchant wholesalers"},
{c:423690,d: "Transmitters merchant wholesalers"},
{c:423690,d: "Tubes, electronic (e.g., industrial, receiving, transmitting), merchant wholesalers"},
{c:423690,d: "Unloaded computer boards merchant wholesalers"},
{c:423690,d: "Video tapes, blank, merchant wholesalers"},
{c:423710,d: "Appliance hardware merchant wholesalers"},
{c:423710,d: "Blades (e.g., knife, saw) merchant wholesalers"},
{c:423710,d: "Bolts, nuts, rivets, screws, and other fasteners merchant wholesalers"},
{c:423710,d: "Brads merchant wholesalers"},
{c:423710,d: "Cabinet hardware and fittings merchant wholesalers"},
{c:423710,d: "Carpenters' tools merchant wholesalers"},
{c:423710,d: "Cutlery merchant wholesalers"},
{c:423710,d: "Drapery hardware merchant wholesalers"},
{c:423710,d: "Fasteners (e.g., bolts, nuts, rivets, screws) merchant wholesalers"},
{c:423710,d: "Handtools (except motor vehicle mechanics', machinists' precision) merchant wholesalers"},
{c:423710,d: "Hardware (except motor vehicle) merchant wholesalers"},
{c:423710,d: "Hinges merchant wholesalers"},
{c:423710,d: "Keys and locks merchant wholesalers"},
{c:423710,d: "Knives (except disposable plastics) merchant wholesalers"},
{c:423710,d: "Locks, security, merchant wholesalers"},
{c:423710,d: "Plumbers' tools and equipment merchant wholesalers"},
{c:423710,d: "Power handtools (e.g., drills, sanders, saws) merchant wholesalers"},
{c:423710,d: "Specialty-line hardware merchant wholesalers"},
{c:423710,d: "Staples merchant wholesalers"},
{c:423710,d: "Tacks merchant wholesalers"},
{c:423710,d: "Tools, hand (except motor vehicle, machinists' precision tools), merchant wholesalers"},
{c:423720,d: "Bathtubs merchant wholesalers"},
{c:423720,d: "Boilers (e.g., heating, hot water, power, steam) merchant wholesalers"},
{c:423720,d: "Brass goods, plumbers', merchant wholesalers"},
{c:423720,d: "Burners, fuel oil and distillate oil, merchant wholesalers"},
{c:423720,d: "Convectors merchant wholesalers"},
{c:423720,d: "Drinking fountains (except refrigerated) merchant wholesalers"},
{c:423720,d: "Fireplaces, gas, merchant wholesalers"},
{c:423720,d: "Fireplaces, prefabricated gas, merchant wholesalers"},
{c:423720,d: "Fittings and valves, plumbers', merchant wholesalers"},
{c:423720,d: "Fountains, drinking (except refrigerated), merchant wholesalers"},
{c:423720,d: "Furnaces (except forced air), heating, merchant wholesalers"},
{c:423720,d: "Gas fireplaces merchant wholesalers"},
{c:423720,d: "Gas hot water heaters merchant wholesalers"},
{c:423720,d: "Heating boilers, steam and hot water, merchant wholesalers"},
{c:423720,d: "Heating equipment, hot water, merchant wholesalers"},
{c:423720,d: "Hydronic heating equipment and supplies merchant wholesalers"},
{c:423720,d: "Lavatory fixtures merchant wholesalers"},
{c:423720,d: "Oil burners, heating, merchant wholesalers"},
{c:423720,d: "Pipe and boiler coverings merchant wholesalers"},
{c:423720,d: "Pipe fittings and valves, plumbers', merchant wholesalers"},
{c:423720,d: "Plumbers' brass goods merchant wholesalers"},
{c:423720,d: "Plumbing and heating valves merchant wholesalers"},
{c:423720,d: "Plumbing equipment merchant wholesalers"},
{c:423720,d: "Plumbing fixtures merchant wholesalers"},
{c:423720,d: "Plumbing supplies merchant wholesalers"},
{c:423720,d: "Radiators, heating, nonelectric, merchant wholesalers"},
{c:423720,d: "Sanitary ware, china or enameled iron, merchant wholesalers"},
{c:423720,d: "Sinks merchant wholesalers"},
{c:423720,d: "Solar heating panels and equipment merchant wholesalers"},
{c:423720,d: "Spa equipment merchant wholesalers"},
{c:423720,d: "Stoves (i.e., noncooking), heating, merchant wholesalers"},
{c:423720,d: "Toilet bowls and tanks merchant wholesalers"},
{c:423720,d: "Urinals merchant wholesalers"},
{c:423720,d: "Valves, plumbing and heating, merchant wholesalers"},
{c:423720,d: "Water heaters, gas and electric, merchant wholesaler"},
{c:423720,d: "Water purification equipment, household-type, merchant wholesalers"},
{c:423720,d: "Water softening and conditioning equipment merchant wholesalers"},
{c:423730,d: "Air pollution control equipment and supplies merchant wholesalers"},
{c:423730,d: "Air-conditioning equipment (except room units) merchant wholesalers"},
{c:423730,d: "Automotive air-conditioners merchant wholesalers"},
{c:423730,d: "Baseboard heaters, electric, non-portable, merchant wholesalers"},
{c:423730,d: "Central heating equipment, warm air, merchant wholesalers"},
{c:423730,d: "Compressors, air-conditioning, merchant wholesalers"},
{c:423730,d: "Condensing units, air-conditioning, merchant wholesalers"},
{c:423730,d: "Cooling equipment and supplies merchant wholesalers"},
{c:423730,d: "Dust collection equipment merchant wholesalers"},
{c:423730,d: "Furnaces, warm air (i.e., forced air), merchant wholesalers"},
{c:423730,d: "Heat pumps merchant wholesalers"},
{c:423730,d: "Heating equipment, warm air (i.e. forced air), merchant wholesalers"},
{c:423730,d: "Humidifiers and dehumidifiers (except portable) merchant wholesalers"},
{c:423730,d: "HVAC equipment merchant wholesalers"},
{c:423730,d: "Pollution control equipment, air, merchant wholesalers"},
{c:423730,d: "Sheet metal duct work (heating and air-conditioning) merchant wholesalers"},
{c:423730,d: "Ventilating equipment and supplies (except household-type fans) merchant wholesalers"},
{c:423730,d: "Warm air heating equipment merchant wholesalers"},
{c:423740,d: "Beverage coolers, mechanical, merchant wholesalers"},
{c:423740,d: "Cold storage machinery merchant wholesalers"},
{c:423740,d: "Compressors, refrigeration, merchant wholesalers"},
{c:423740,d: "Condensing units, refrigeration, merchant wholesalers"},
{c:423740,d: "Coolers, mechanical, merchant wholesalers"},
{c:423740,d: "Display cases, refrigerated, merchant wholesalers"},
{c:423740,d: "Drinking fountains, refrigerated, merchant wholesalers"},
{c:423740,d: "Fixtures, refrigerated, merchant wholesalers"},
{c:423740,d: "Fountains, drinking, refrigerated, merchant wholesalers"},
{c:423740,d: "Freezers, commercial-type, merchant wholesalers"},
{c:423740,d: "Ice making machines merchant wholesalers"},
{c:423740,d: "Lockers, refrigerated, merchant wholesalers"},
{c:423740,d: "Refrigerated display cases merchant wholesalers"},
{c:423740,d: "Refrigeration equipment and supplies, commercial-type, merchant wholesalers"},
{c:423740,d: "Refrigeration units, motor vehicle, merchant wholesalers"},
{c:423740,d: "Refrigerators (e.g., reach-in, walk-in), commercial-type, merchant wholesalers"},
{c:423740,d: "Showcases, refrigerated, merchant wholesalers"},
{c:423740,d: "Soda fountain fixtures, refrigerated, merchant wholesalers"},
{c:423740,d: "Water coolers, mechanical, merchant wholesalers"},
{c:423810,d: "Concrete processing equipment merchant wholesalers"},
{c:423810,d: "Construction machinery and equipment merchant wholesalers"},
{c:423810,d: "Cranes (except industrial) merchant wholesalers"},
{c:423810,d: "Cranes, mining, merchant wholesalers"},
{c:423810,d: "Crushing, pulverizing, and screening machinery, construction and mining, merchant wholesalers"},
{c:423810,d: "Dredges (except ships') merchant wholesalers"},
{c:423810,d: "Excavating machinery and equipment merchant wholesalers"},
{c:423810,d: "Forestry machinery and equipment merchant wholesalers"},
{c:423810,d: "Land preparation machinery, construction, merchant wholesalers"},
{c:423810,d: "Loaders merchant wholesalers"},
{c:423810,d: "Logging equipment merchant wholesalers"},
{c:423810,d: "Mining cranes merchant wholesalers"},
{c:423810,d: "Mining machinery and equipment (except petroleum) merchant wholesalers"},
{c:423810,d: "Mixers, construction and mining, merchant wholesalers"},
{c:423810,d: "Pavers merchant wholesalers"},
{c:423810,d: "Quarrying machinery and equipment merchant wholesalers"},
{c:423810,d: "Road construction and maintenance machinery merchant wholesalers"},
{c:423810,d: "Scaffolding merchant wholesalers"},
{c:423810,d: "Shovels, power, merchant wholesalers"},
{c:423810,d: "Snow plows merchant wholesalers"},
{c:423810,d: "Snowblowers (except household-type) merchant wholesalers"},
{c:423810,d: "Street sweeping and cleaning equipment merchant wholesalers"},
{c:423820,d: "Agricultural implements merchant wholesalers"},
{c:423820,d: "Agricultural machinery and equipment merchant wholesalers"},
{c:423820,d: "Blowers, snow and leaf, merchant wholesalers"},
{c:423820,d: "Combines merchant wholesalers"},
{c:423820,d: "Conveying equipment, farm, merchant wholesalers"},
{c:423820,d: "Crop preparation machinery (e.g., cleaning, conditioning, drying) merchant wholesalers"},
{c:423820,d: "Cultivating machinery and equipment merchant wholesalers"},
{c:423820,d: "Farm machinery and equipment merchant wholesalers"},
{c:423820,d: "Feeders, animal, merchant wholesalers"},
{c:423820,d: "Frost protection machinery merchant wholesalers"},
{c:423820,d: "Garden machinery and equipment merchant wholesalers"},
{c:423820,d: "Harvesting machinery and equipment, agriculture, merchant wholesalers"},
{c:423820,d: "Haying machines merchant wholesalers"},
{c:423820,d: "Irrigation equipment merchant wholesalers"},
{c:423820,d: "Land preparation machinery, agricultural, merchant wholesalers"},
{c:423820,d: "Lawn maintenance machinery and equipment merchant wholesalers"},
{c:423820,d: "Lawnmowers merchant wholesalers"},
{c:423820,d: "Milking machinery and equipment merchant wholesalers"},
{c:423820,d: "Planting machinery and equipment, farm-type, merchant wholesalers"},
{c:423820,d: "Plows, farm, merchant wholesalers"},
{c:423820,d: "Poultry equipment merchant wholesalers"},
{c:423820,d: "Snowblowers, household-type, merchant wholesalers"},
{c:423820,d: "Sprayers, farm-type, merchant wholesalers"},
{c:423820,d: "Spreaders, fertilizer, merchant wholesalers"},
{c:423820,d: "Sprinklers, agricultural, merchant wholesalers"},
{c:423820,d: "Sprinklers, garden, merchant wholesalers"},
{c:423820,d: "Tillers, farm and garden, merchant wholesalers"},
{c:423820,d: "Tractors, farm and garden, merchant wholesalers"},
{c:423830,d: "Actuators, fluid power, merchant wholesalers"},
{c:423830,d: "Air compressors (except air-conditioning, refrigeration) merchant wholesalers"},
{c:423830,d: "Bakery machinery and equipment merchant wholesalers"},
{c:423830,d: "Balancing machines (except automotive) merchant wholesalers"},
{c:423830,d: "Beverage bottling machinery merchant wholesalers"},
{c:423830,d: "Beverage processing machinery merchant wholesalers"},
{c:423830,d: "Blanks, tips, and inserts merchant wholesalers"},
{c:423830,d: "Blowers, industrial, merchant wholesalers"},
{c:423830,d: "Bottling machinery and equipment merchant wholesalers"},
{c:423830,d: "Broaches (i.e., a machine tool accessory) merchant wholesalers"},
{c:423830,d: "Burners, industrial, merchant wholesalers"},
{c:423830,d: "Cement-making machinery merchant wholesalers"},
{c:423830,d: "Chainsaws merchant wholesalers"},
{c:423830,d: "Chemical industries machinery and equipment merchant wholesalers"},
{c:423830,d: "Coil winding machines, spring, merchant wholesalers"},
{c:423830,d: "Commercial fishing equipment and supplies (except boats, ships) merchant wholesalers"},
{c:423830,d: "Compactors, trash, industrial, merchant wholesalers"},
{c:423830,d: "Compressors (except air-conditioning, refrigeration) merchant wholesalers"},
{c:423830,d: "Condensing units (except air-conditioning, refrigeration) merchant wholesalers"},
{c:423830,d: "Conveying equipment (except farm) merchant wholesalers"},
{c:423830,d: "Cranes, industrial, merchant wholesalers"},
{c:423830,d: "Crushing, pulverizing, and screening machinery, industrial, merchant wholesalers"},
{c:423830,d: "Cryogenic cooling devices merchant wholesalers"},
{c:423830,d: "Dies, metalworking, merchant wholesalers"},
{c:423830,d: "Diesel engines and parts, industrial, merchant wholesalers"},
{c:423830,d: "Distillery machinery merchant wholesalers"},
{c:423830,d: "Dyeing machinery, textile, merchant wholesalers"},
{c:423830,d: "Elevators merchant wholesalers"},
{c:423830,d: "Engines, internal combustion (except aircraft, automotive), merchant wholesalers"},
{c:423830,d: "Engraving machinery merchant wholesalers"},
{c:423830,d: "Environmental controlling instruments and equipment merchant wholesalers"},
{c:423830,d: "Escalators merchant wholesalers"},
{c:423830,d: "Fans, industrial, merchant wholesalers"},
{c:423830,d: "Fluid meters, industrial, merchant wholesalers"},
{c:423830,d: "Fluid power transmission equipment merchant wholesalers"},
{c:423830,d: "Food processing machinery and equipment merchant wholesalers"},
{c:423830,d: "Forklift trucks (except log) merchant wholesalers"},
{c:423830,d: "Foundry machinery and equipment merchant wholesalers"},
{c:423830,d: "Furnaces, industrial process, merchant wholesalers"},
{c:423830,d: "Gas detecting equipment and supplies (except household-type) merchant wholesalers"},
{c:423830,d: "General purpose industrial machinery and equipment merchant wholesalers"},
{c:423830,d: "Handtools, machinists' precision, merchant wholesalers"},
{c:423830,d: "Heat exchange equipment, industrial, merchant wholesalers"},
{c:423830,d: "Hoists (except automotive) merchant wholesalers"},
{c:423830,d: "Hydraulic power transmission equipment merchant wholesalers"},
{c:423830,d: "Hydraulic pumps and parts merchant wholesalers"},
{c:423830,d: "Industrial machinery and equipment (except electrical) merchant wholesalers"},
{c:423830,d: "Industrial trucks, tractors, or trailers merchant wholesalers"},
{c:423830,d: "Instruments (except electrical) (e.g., controlling, indicating, recording) merchant wholesalers"},
{c:423830,d: "Internal combustion engines (except aircraft, nondiesel automotive, nondiesel truck) merchant wholesalers"},
{c:423830,d: "Jigs merchant wholesalers"},
{c:423830,d: "Kilns, industrial, merchant wholesalers"},
{c:423830,d: "Ladders merchant wholesalers"},
{c:423830,d: "Lift trucks, industrial, merchant wholesalers"},
{c:423830,d: "Machine tools and accessories merchant wholesalers"},
{c:423830,d: "Machinists' precision measuring tools merchant wholesalers"},
{c:423830,d: "Manufacturing machinery and equipment, industrial, merchant wholesalers"},
{c:423830,d: "Material handling machinery and equipment merchant wholesalers"},
{c:423830,d: "Measuring and testing equipment (except electric measuring and automotive) merchant wholesalers"},
{c:423830,d: "Metalworking machinery and equipment merchant wholesalers"},
{c:423830,d: "Metalworking tools (drills, taps, dies, grinding wheels) merchant wholesalers"},
{c:423830,d: "Meters (except electrical, parking) merchant wholesalers"},
{c:423830,d: "Mining machinery and equipment, petroleum, merchant wholesalers"},
{c:423830,d: "Oil field equipment merchant wholesalers"},
{c:423830,d: "Oil well machinery and equipment merchant wholesalers"},
{c:423830,d: "Oil well supply houses merchant wholesalers"},
{c:423830,d: "Ovens, industrial, merchant wholesalers"},
{c:423830,d: "Packing machinery and equipment merchant wholesalers"},
{c:423830,d: "Pallets and skids merchant wholesalers"},
{c:423830,d: "Paper and pulp industries manufacturing machinery merchant wholesalers"},
{c:423830,d: "Patterns (except shoe), industrial, merchant wholesalers"},
{c:423830,d: "Petroleum production machinery and equipment merchant wholesalers"},
{c:423830,d: "Pipeline machinery and equipment merchant wholesalers"},
{c:423830,d: "Pistons, hydraulic and pneumatic, merchant wholesalers"},
{c:423830,d: "Plastics industries machinery, equipment, and supplies merchant wholesalers"},
{c:423830,d: "Pneumatic pumps and parts merchant wholesalers"},
{c:423830,d: "Pollution control equipment (except air) merchant wholesalers"},
{c:423830,d: "Poultry processing machinery merchant wholesalers"},
{c:423830,d: "Power plant machinery (except electrical) merchant wholesalers"},
{c:423830,d: "Printing trade machinery, equipment, and supplies merchant wholesalers"},
{c:423830,d: "Pulverizing machinery and equipment, industrial, merchant wholesalers"},
{c:423830,d: "Pumps and pumping equipment, industrial-type, merchant wholesalers"},
{c:423830,d: "Recapping machinery, tire, merchant wholesalers"},
{c:423830,d: "Refinery machinery and equipment merchant wholesalers"},
{c:423830,d: "Rolling mill machinery merchant wholesalers"},
{c:423830,d: "Sawmill machinery, equipment, and supplies merchant wholesalers"},
{c:423830,d: "Saws, industrial, merchant wholesalers"},
{c:423830,d: "Screening machinery and equipment, industrial, merchant wholesalers"},
{c:423830,d: "Sewing machines, industrial, merchant wholesalers"},
{c:423830,d: "Shoe manufacturing and repairing machinery merchant wholesalers"},
{c:423830,d: "Skids merchant wholesalers"},
{c:423830,d: "Smelting machinery and equipment merchant wholesalers"},
{c:423830,d: "Special purpose industrial machinery and equipment merchant wholesalers"},
{c:423830,d: "Spray painting equipment, industrial-type, merchant wholesalers"},
{c:423830,d: "Stackers, industrial, merchant wholesalers"},
{c:423830,d: "Tanks, pressure, merchant wholesalers"},
{c:423830,d: "Testing and measuring equipment, electrical (except automotive), merchant wholesalers"},
{c:423830,d: "Textile machinery and equipment merchant wholesalers"},
{c:423830,d: "Threading tools merchant wholesalers"},
{c:423830,d: "Tire recapping machinery merchant wholesalers"},
{c:423830,d: "Tools, machinists' precision, merchant wholesalers"},
{c:423830,d: "Tractors, industrial, merchant wholesalers"},
{c:423830,d: "Trailers, industrial, merchant wholesalers"},
{c:423830,d: "Trucks, industrial, merchant wholesalers"},
{c:423830,d: "Turbines (except transportation) merchant wholesalers"},
{c:423830,d: "Twist drills merchant wholesalers"},
{c:423830,d: "Vacuum pumps merchant wholesalers"},
{c:423830,d: "Valves, hydraulic and pneumatic, merchant wholesalers"},
{c:423830,d: "Water treatment equipment, industrial, merchant wholesalers"},
{c:423830,d: "Welding machinery and equipment merchant wholesalers"},
{c:423830,d: "Winches merchant wholesalers"},
{c:423830,d: "Woodworking machinery merchant wholesalers"},
{c:423840,d: "Abrasives merchant wholesalers"},
{c:423840,d: "Barrels, new and reconditioned, merchant wholesalers"},
{c:423840,d: "Bearings merchant wholesalers"},
{c:423840,d: "Belting, industrial, merchant wholesalers"},
{c:423840,d: "Bottles (except waste) merchant wholesalers"},
{c:423840,d: "Box shooks merchant wholesalers"},
{c:423840,d: "Boxes and crates, industrial (except disposable plastics, paperboard, waste), merchant wholesalers"},
{c:423840,d: "Brushes, industrial, merchant wholesalers"},
{c:423840,d: "Canning supplies merchant wholesalers"},
{c:423840,d: "Cartons (except paper and paperboard) merchant wholesalers"},
{c:423840,d: "Closures, industrial, merchant wholesalers"},
{c:423840,d: "Containers, industrial, merchant wholesalers"},
{c:423840,d: "Cooperage stock merchant wholesalers"},
{c:423840,d: "Cordage merchant wholesalers"},
{c:423840,d: "Cork merchant wholesalers"},
{c:423840,d: "Crowns and closures, metal, merchant wholesalers"},
{c:423840,d: "Diamonds, industrial, merchant wholesalers"},
{c:423840,d: "Drives and gears merchant wholesalers"},
{c:423840,d: "Drums, new and reconditioned, merchant wholesalers"},
{c:423840,d: "Excelsior (e.g., pads, wrappers) merchant wholesalers"},
{c:423840,d: "Fire hydrants merchant wholesalers"},
{c:423840,d: "Fittings, industrial, merchant wholesalers"},
{c:423840,d: "Gaskets merchant wholesalers"},
{c:423840,d: "Gears merchant wholesalers"},
{c:423840,d: "General-line industrial supplies merchant wholesalers"},
{c:423840,d: "Grommets merchant wholesalers"},
{c:423840,d: "Hose, industrial, merchant wholesalers"},
{c:423840,d: "Industrial containers merchant wholesalers"},
{c:423840,d: "Industrial diamonds merchant wholesalers"},
{c:423840,d: "Industrial leather products merchant wholesalers"},
{c:423840,d: "Industrial pottery products merchant wholesalers"},
{c:423840,d: "Industrial supplies (except disposable plastics, paper) merchant wholesalers"},
{c:423840,d: "Inks, printing, merchant wholesalers"},
{c:423840,d: "Lapidary equipment, industrial, merchant wholesalers"},
{c:423840,d: "Mechanical power transmission supplies (e.g., gears, pulleys, sprockets) merchant wholesalers"},
{c:423840,d: "Mechanical rubber goods merchant wholesalers"},
{c:423840,d: "Mill supplies merchant wholesalers"},
{c:423840,d: "Packaging materials merchant wholesalers"},
{c:423840,d: "Packing materials merchant wholesalers"},
{c:423840,d: "Pipe fittings and valves (except plumbing) merchant wholesalers"},
{c:423840,d: "Pistons and valves, industrial, merchant wholesalers"},
{c:423840,d: "Plastics foam packing and packaging materials merchant wholesalers"},
{c:423840,d: "Power transmission supplies (e.g., gears, pulleys, sprockets), mechanical, merchant wholesalers"},
{c:423840,d: "Printing inks merchant wholesalers"},
{c:423840,d: "Reconditioned barrels and drums merchant wholesalers"},
{c:423840,d: "Refractory materials (e.g., block, brick, mortar, tile) merchant wholesalers"},
{c:423840,d: "Ropes (except wire rope) merchant wholesalers"},
{c:423840,d: "Rubber goods, mechanical (i.e., extruded, lathe-cut, molded), merchant wholesalers"},
{c:423840,d: "Sand, industrial, merchant wholesalers"},
{c:423840,d: "Seals merchant wholesalers"},
{c:423840,d: "Shipping containers (except disposable plastics, paper) merchant wholesalers"},
{c:423840,d: "Shipping pails, metal, merchant wholesalers"},
{c:423840,d: "Speed changers merchant wholesalers"},
{c:423840,d: "Sprockets merchant wholesalers"},
{c:423840,d: "Thread, industrial, merchant wholesalers"},
{c:423840,d: "Towels, industrial, merchant wholesalers"},
{c:423840,d: "Twine merchant wholesalers"},
{c:423840,d: "Valves (except hydraulic, plumbing, pneumatic) merchant wholesalers"},
{c:423840,d: "Welding supplies (except welding gases) merchant wholesalers"},
{c:423840,d: "Wiping cloths merchant wholesalers"},
{c:423850,d: "Amusement park equipment merchant wholesalers"},
{c:423850,d: "Barber shop equipment and supplies merchant wholesalers"},
{c:423850,d: "Beauty parlor equipment and supplies merchant wholesalers"},
{c:423850,d: "Boot and shoe cut stock and findings merchant wholesalers"},
{c:423850,d: "Brooms (except household-type) merchant wholesalers"},
{c:423850,d: "Burial caskets merchant wholesalers"},
{c:423850,d: "Car wash equipment and supplies merchant wholesalers"},
{c:423850,d: "Carnival equipment merchant wholesalers"},
{c:423850,d: "Carpet cleaning equipment and supplies merchant wholesalers"},
{c:423850,d: "Caskets, burial, merchant wholesalers"},
{c:423850,d: "Chairs, hydraulic, beauty and barber shop, merchant wholesalers"},
{c:423850,d: "Clothes dryers (except household-type) merchant wholesalers"},
{c:423850,d: "Coffins merchant wholesalers"},
{c:423850,d: "Drycleaning equipment and supplies merchant wholesalers"},
{c:423850,d: "Fare boxes, public transit vehicle, merchant wholesalers"},
{c:423850,d: "Firefighting equipment and supplies merchant wholesalers"},
{c:423850,d: "Floor maintenance equipment merchant wholesalers"},
{c:423850,d: "Funeral home supplies merchant wholesalers"},
{c:423850,d: "Furriers equipment and supplies merchant wholesalers"},
{c:423850,d: "Hair preparations, professional, merchant wholesalers"},
{c:423850,d: "Janitorial equipment and supplies merchant wholesalers"},
{c:423850,d: "Laundry machinery, equipment, and supplies, commercial, merchant wholesalers"},
{c:423850,d: "Law enforcement equipment (except safety) merchant wholesalers"},
{c:423850,d: "Locksmith equipment and supplies merchant wholesalers"},
{c:423850,d: "Manicurists supplies merchant wholesalers"},
{c:423850,d: "Massage equipment merchant wholesalers"},
{c:423850,d: "Meters, parking, merchant wholesalers"},
{c:423850,d: "Mops, industrial, merchant wholesalers"},
{c:423850,d: "Padding, upholstery filling, merchant wholesalers"},
{c:423850,d: "Patterns, shoe, merchant wholesalers"},
{c:423850,d: "Pressure washers merchant wholesalers"},
{c:423850,d: "Service establishment equipment and supplies merchant wholesalers"},
{c:423850,d: "Shoe repair materials merchant wholesalers"},
{c:423850,d: "Sprinkler systems, fire, merchant wholesalers"},
{c:423850,d: "Tailors' supplies merchant wholesalers"},
{c:423850,d: "Taxidermy supplies merchant wholesalers"},
{c:423850,d: "Undertakers' equipment and supplies merchant wholesalers"},
{c:423850,d: "Upholsterers' equipment and supplies (except fabrics) merchant wholesalers"},
{c:423850,d: "Vacuum cleaning systems, commercial, merchant wholesalers"},
{c:423850,d: "Voting machines merchant wholesalers"},
{c:423850,d: "Water treatment equipment, municipal, merchant wholesalers"},
{c:423860,d: "Aeronautical equipment and supplies merchant wholesalers"},
{c:423860,d: "Aerospace equipment and supplies merchant wholesalers"},
{c:423860,d: "Aircraft engines and parts merchant wholesalers"},
{c:423860,d: "Aircraft equipment and supplies merchant wholesalers"},
{c:423860,d: "Aircraft merchant wholesalers"},
{c:423860,d: "Boats (except pleasure) merchant wholesalers"},
{c:423860,d: "Carts, golf, motorized passenger merchant wholesalers"},
{c:423860,d: "Draglines, ship, merchant wholesalers"},
{c:423860,d: "Dredges, ship, merchant wholesalers"},
{c:423860,d: "Dunnage, marine supplies, merchant wholesalers"},
{c:423860,d: "Engines and parts, aircraft, merchant wholesalers"},
{c:423860,d: "Engines and turbines, marine, merchant wholesalers"},
{c:423860,d: "Golf carts, motorized passenger, merchant wholesalers"},
{c:423860,d: "Guided missiles and space vehicles merchant wholesalers"},
{c:423860,d: "Locomotives merchant wholesalers"},
{c:423860,d: "Marine supplies (except pleasure) merchant wholesalers"},
{c:423860,d: "Military vehicles (except trucks) merchant wholesalers"},
{c:423860,d: "Motorized passenger golf carts merchant wholesalers"},
{c:423860,d: "Navigational instruments (except electronic) merchant wholesalers"},
{c:423860,d: "Propulsion systems, marine, merchant wholesalers"},
{c:423860,d: "Railroad cars merchant wholesalers"},
{c:423860,d: "Railroad equipment and supplies merchant wholesalers"},
{c:423860,d: "Ships merchant wholesalers"},
{c:423860,d: "Space propulsion units and parts merchant wholesalers"},
{c:423860,d: "Subway cars merchant wholesalers"},
{c:423860,d: "Tanks and tank components merchant wholesalers"},
{c:423860,d: "Transportation equipment and supplies (except marine pleasure craft, motor vehicles) merchant wholesalers"},
{c:423860,d: "Transportation machinery, equipment, and supplies (except marine pleasure craft, motor vehicles) merchant wholesalers"},
{c:423860,d: "Turbines, transportation, merchant wholesalers"},
{c:423860,d: "Yachts (except pleasure) merchant wholesalers"},
{c:423910,d: "Ammunition, sporting, merchant wholesalers"},
{c:423910,d: "Archery equipment merchant wholesalers"},
{c:423910,d: "Athletic goods (except apparel, footwear, nonspecialty) merchant wholesalers"},
{c:423910,d: "Athletic uniforms merchant wholesalers"},
{c:423910,d: "Bait, artificial, merchant wholesalers"},
{c:423910,d: "Baseball equipment and supplies merchant wholesalers"},
{c:423910,d: "Bicycle tires and tubes merchant wholesalers"},
{c:423910,d: "Bicycles (except motorized) merchant wholesalers"},
{c:423910,d: "Billiard equipment and supplies merchant wholesalers"},
{c:423910,d: "Boat supplies, pleasure, merchant wholesalers"},
{c:423910,d: "Boats, pleasure (e.g., canoes, motorboats, sailboats), merchant wholesalers"},
{c:423910,d: "Bowling equipment and supplies merchant wholesalers"},
{c:423910,d: "Camping equipment and supplies merchant wholesalers"},
{c:423910,d: "Casino supplies merchant wholesalers"},
{c:423910,d: "Firearms, sporting, merchant wholesalers"},
{c:423910,d: "Fishing equipment and supplies (except commercial) merchant wholesalers"},
{c:423910,d: "Fitness equipment and supplies merchant wholesalers"},
{c:423910,d: "Football equipment and supplies merchant wholesalers"},
{c:423910,d: "Gocarts merchant wholesalers"},
{c:423910,d: "Golf carts (except motorized passenger) merchant wholesalers"},
{c:423910,d: "Golf equipment and supplies merchant wholesalers"},
{c:423910,d: "Guns, sporting equipment, merchant wholesalers"},
{c:423910,d: "Gymnasium equipment merchant wholesalers"},
{c:423910,d: "Hockey equipment and supplies merchant wholesalers"},
{c:423910,d: "Hot tubs merchant wholesalers"},
{c:423910,d: "Hunting equipment and supplies merchant wholesalers"},
{c:423910,d: "Jet skis merchant wholesalers"},
{c:423910,d: "Marine supplies, pleasure, merchant wholesalers"},
{c:423910,d: "Motors, outboard, merchant wholesalers"},
{c:423910,d: "Outboard motors merchant wholesalers"},
{c:423910,d: "Playground equipment and supplies merchant wholesalers"},
{c:423910,d: "Pleasure boats merchant wholesalers"},
{c:423910,d: "Pool (billiards) tables and supplies merchant wholesalers"},
{c:423910,d: "Pool (swimming) and equipment merchant wholesalers"},
{c:423910,d: "Recreational equipment and supplies (except vehicles) merchant wholesalers"},
{c:423910,d: "Skiing equipment and supplies merchant wholesalers"},
{c:423910,d: "Sporting firearms and ammunition merchant wholesalers"},
{c:423910,d: "Sporting goods and supplies merchant wholesalers"},
{c:423910,d: "Sports equipment and supplies merchant wholesalers"},
{c:423910,d: "Swimming pools and equipment merchant wholesalers"},
{c:423910,d: "Tennis equipment and supplies merchant wholesalers"},
{c:423910,d: "Trapshooting equipment and supplies merchant wholesalers"},
{c:423910,d: "Yachts, pleasure, merchant wholesalers"},
{c:423920,d: "Board games merchant wholesalers"},
{c:423920,d: "Card games merchant wholesalers"},
{c:423920,d: "Children's vehicles (except bicycles) merchant wholesalers"},
{c:423920,d: "Coloring books merchant wholesalers"},
{c:423920,d: "Craft kits merchant wholesalers"},
{c:423920,d: "Dolls merchant wholesalers"},
{c:423920,d: "Electronic games merchant wholesalers"},
{c:423920,d: "Fireworks merchant wholesalers"},
{c:423920,d: "Games (except coin-operated) merchant wholesalers"},
{c:423920,d: "Gaming consoles merchant wholesalers"},
{c:423920,d: "Hobby craft kits merchant wholesalers"},
{c:423920,d: "Hobbyists' supplies merchant wholesalers"},
{c:423920,d: "Model kits merchant wholesalers"},
{c:423920,d: "Playing cards merchant wholesalers"},
{c:423920,d: "Puzzles merchant wholesalers"},
{c:423920,d: "Science kits and sets merchant wholesalers"},
{c:423920,d: "Stamps, philatelist, merchant wholesalers"},
{c:423920,d: "Toy furniture merchant wholesalers"},
{c:423920,d: "Toys (including electronic) merchant wholesalers"},
{c:423920,d: "Trading cards merchant wholesalers"},
{c:423920,d: "Vehicles, children's (except bicycles), merchant wholesalers"},
{c:423920,d: "Video games merchant wholesalers"},
{c:423930,d: "Bags, reclaimed, merchant wholesalers"},
{c:423930,d: "Bottles, waste, merchant wholesalers"},
{c:423930,d: "Boxes, waste, merchant wholesalers"},
{c:423930,d: "Electronics parts, recyclable, merchant wholesalers"},
{c:423930,d: "Fur cuttings and scraps merchant wholesalers"},
{c:423930,d: "General-line scrap merchant wholesalers"},
{c:423930,d: "Glass scrap merchant wholesalers"},
{c:423930,d: "Industrial wastes to be reclaimed merchant wholesalers"},
{c:423930,d: "Metal scrap and waste merchant wholesalers"},
{c:423930,d: "Oil, waste, merchant wholesalers"},
{c:423930,d: "Paper, scrap, merchant wholesalers"},
{c:423930,d: "Plastics scrap merchant wholesalers"},
{c:423930,d: "Rags merchant wholesalers"},
{c:423930,d: "Recyclable materials (e.g., glass, metal, paper) merchant wholesalers"},
{c:423930,d: "Rubber scrap and scrap tires merchant wholesalers"},
{c:423930,d: "Salvage, scrap, merchant wholesalers"},
{c:423930,d: "Scrap materials (e.g., automotive, industrial) merchant wholesalers"},
{c:423930,d: "Ship dismantling (except at floating drydocks and shipyards) merchant wholesalers"},
{c:423930,d: "Textile waste merchant wholesalers"},
{c:423930,d: "Tires, scrap, merchant wholesalers"},
{c:423930,d: "Waste materials merchant wholesalers"},
{c:423940,d: "Antique jewelry merchant wholesalers"},
{c:423940,d: "Clocks merchant wholesalers"},
{c:423940,d: "Coins merchant wholesalers"},
{c:423940,d: "Costume jewelry merchant wholesalers"},
{c:423940,d: "Diamonds (except industrial) merchant wholesalers"},
{c:423940,d: "Flatware, precious and plated, merchant wholesalers"},
{c:423940,d: "Gem stones merchant wholesalers"},
{c:423940,d: "Hollowware, precious metal, merchant wholesalers"},
{c:423940,d: "Ingots, precious, merchant wholesalers"},
{c:423940,d: "Jewelers' findings merchant wholesalers"},
{c:423940,d: "Jewelry merchant wholesalers"},
{c:423940,d: "Medallions merchant wholesalers"},
{c:423940,d: "Metals, precious, merchant wholesalers"},
{c:423940,d: "Numismatic goods merchant wholesalers"},
{c:423940,d: "Pearls merchant wholesalers"},
{c:423940,d: "Plated metal cutlery or flatware merchant wholesalers"},
{c:423940,d: "Precious and semiprecious stones merchant wholesalers"},
{c:423940,d: "Precious metals merchant wholesalers"},
{c:423940,d: "Silverware, precious and plated, merchant wholesalers"},
{c:423940,d: "Stones, precious and semiprecious, merchant wholesalers"},
{c:423940,d: "Tableware, precious and plated, merchant wholesalers"},
{c:423940,d: "Trophies merchant wholesalers"},
{c:423940,d: "Watchcases merchant wholesalers"},
{c:423940,d: "Watches and parts merchant wholesalers"},
{c:423990,d: "Ammunition (except sporting) merchant wholesalers"},
{c:423990,d: "Audio and video tapes and disks, prerecorded, merchant wholesalers"},
{c:423990,d: "Billboards merchant wholesalers"},
{c:423990,d: "Briefcases merchant wholesalers"},
{c:423990,d: "Bulletproof vests merchant wholesalers"},
{c:423990,d: "Cassettes, prerecorded audio and video, merchant wholesalers"},
{c:423990,d: "Coin-operated game machines merchant wholesalers"},
{c:423990,d: "Compact discs (CDs), prerecorded, merchant wholesalers"},
{c:423990,d: "Digital video discs (DVDs), prerecorded, merchant wholesalers"},
{c:423990,d: "Extinguishers, fire, merchant wholesalers"},
{c:423990,d: "Fire extinguisher sales combined with rental and/or service, merchant wholesalers"},
{c:423990,d: "Fire extinguishers merchant wholesalers"},
{c:423990,d: "Firearms (except sporting) merchant wholesalers"},
{c:423990,d: "Firewood merchant wholesalers"},
{c:423990,d: "Forest products (except lumber) merchant wholesalers"},
{c:423990,d: "Games, coin-operated, merchant wholesalers"},
{c:423990,d: "Gas lighting fixtures merchant wholesalers"},
{c:423990,d: "General merchandise, durable goods, merchant wholesalers"},
{c:423990,d: "Guns (except sporting) merchant wholesalers"},
{c:423990,d: "Instruments, musical, merchant wholesalers"},
{c:423990,d: "Lighting equipment, gas, merchant wholesalers"},
{c:423990,d: "Logs merchant wholesalers"},
{c:423990,d: "Luggage merchant wholesalers"},
{c:423990,d: "Mailboxes merchant wholesalers"},
{c:423990,d: "Monuments and grave markers merchant wholesalers"},
{c:423990,d: "Musical instrument accessories and supplies merchant wholesalers"},
{c:423990,d: "Musical instruments merchant wholesalers"},
{c:423990,d: "Musical recordings (e.g., compact discs, records, tapes) merchant wholesalers"},
{c:423990,d: "Ordnance and accessories merchant wholesalers"},
{c:423990,d: "Phonograph records merchant wholesalers"},
{c:423990,d: "Prerecorded audio and video tapes and discs merchant wholesalers"},
{c:423990,d: "Pulpwood merchant wholesalers"},
{c:423990,d: "Railroad ties, wood, merchant wholesalers"},
{c:423990,d: "Roundwood merchant wholesalers"},
{c:423990,d: "Safety deposit boxes merchant wholesalers"},
{c:423990,d: "Safety devices (e.g., eye shields, face shields) merchant wholesalers"},
{c:423990,d: "Signs (except electrical) merchant wholesalers"},
{c:423990,d: "Suitcases merchant wholesalers"},
{c:423990,d: "Tapes, prerecorded, audio or video, merchant wholesalers"},
{c:423990,d: "Ties, wood, merchant wholesalers"},
{c:423990,d: "Timber and timber products (except lumber) merchant wholesalers"},
{c:423990,d: "Tombstones merchant wholesalers"},
{c:423990,d: "Video tapes, prerecorded, merchant wholesalers"},
{c:423990,d: "Wood products (e.g., chips, posts, shavings, ties) merchant wholesalers"},
{c:424110,d: "Envelope paper, bulk, merchant wholesalers"},
{c:424110,d: "Fine paper, bulk, merchant wholesalers"},
{c:424110,d: "Groundwood paper, bulk, merchant wholesalers"},
{c:424110,d: "Newsprint merchant wholesalers"},
{c:424110,d: "Paper (e.g., fine, printing, writing), bulk, merchant wholesalers"},
{c:424110,d: "Printing paper, bulk, merchant wholesalers"},
{c:424120,d: "Albums, photo, merchant wholesalers"},
{c:424120,d: "Binders, looseleaf, merchant wholesalers"},
{c:424120,d: "Blankbooks merchant wholesalers"},
{c:424120,d: "Books, sales or receipt, merchant wholesalers"},
{c:424120,d: "Cards, greeting, merchant wholesalers"},
{c:424120,d: "Cartridge toner merchant wholesalers"},
{c:424120,d: "Cellophane tape merchant wholesalers"},
{c:424120,d: "Commercial stationery supplies merchant wholesalers"},
{c:424120,d: "Computer paper supplies merchant wholesalers"},
{c:424120,d: "Desk accessories, office, merchant wholesalers"},
{c:424120,d: "Envelopes merchant wholesalers"},
{c:424120,d: "File cards and folders merchant wholesalers"},
{c:424120,d: "Filler paper, looseleaf, merchant wholesalers"},
{c:424120,d: "Folders, file, merchant wholesalers"},
{c:424120,d: "Forms, paper (e.g., business, office, sales), merchant wholesalers"},
{c:424120,d: "Gift wrapping paper merchant wholesalers"},
{c:424120,d: "Greeting cards merchant wholesalers"},
{c:424120,d: "Gummed tapes, cellophane, merchant wholesalers"},
{c:424120,d: "Ink, writing, merchant wholesalers"},
{c:424120,d: "Inked ribbons merchant wholesalers"},
{c:424120,d: "Inks, pastes, and solvents, office, merchant wholesalers"},
{c:424120,d: "Looseleaf binders merchant wholesalers"},
{c:424120,d: "Manifold business forms merchant wholesalers"},
{c:424120,d: "Marking devices merchant wholesalers"},
{c:424120,d: "Notebooks merchant wholesalers"},
{c:424120,d: "Office supplies (except furniture, machines) merchant wholesalers"},
{c:424120,d: "Paper, office (e.g., carbon, computer, copier, typewriter), merchant wholesalers"},
{c:424120,d: "Pencils merchant wholesalers"},
{c:424120,d: "Pens, writing, merchant wholesalers"},
{c:424120,d: "Photo albums merchant wholesalers"},
{c:424120,d: "Photocopy supplies merchant wholesalers"},
{c:424120,d: "Postcards merchant wholesalers"},
{c:424120,d: "Printing paper (except bulk) merchant wholesalers"},
{c:424120,d: "Receipt books merchant wholesalers"},
{c:424120,d: "Ribbons, inked, merchant wholesalers"},
{c:424120,d: "Rubber stamps merchant wholesalers"},
{c:424120,d: "Sales books merchant wholesalers"},
{c:424120,d: "Scrapbooks merchant wholesalers"},
{c:424120,d: "Social stationery merchant wholesalers"},
{c:424120,d: "Stationery supplies merchant wholesalers"},
{c:424120,d: "Tapes, cellophane, merchant wholesalers"},
{c:424120,d: "Toner cartridges merchant wholesalers"},
{c:424120,d: "Typewriter paper merchant wholesalers"},
{c:424120,d: "Writing paper (except bulk) merchant wholesalers"},
{c:424130,d: "Bags, paper and disposable plastics, merchant wholesalers"},
{c:424130,d: "Boxes, paperboard and disposable plastics, merchant wholesalers"},
{c:424130,d: "Cardboard products merchant wholesalers"},
{c:424130,d: "Cartons, paper and paperboard, merchant wholesalers"},
{c:424130,d: "Closures, paper and disposable plastics, merchant wholesalers"},
{c:424130,d: "Containers, paper and disposable plastics, merchant wholesalers"},
{c:424130,d: "Converted paper (except stationery and office supplies) merchant wholesalers"},
{c:424130,d: "Corrugated paper merchant wholesalers"},
{c:424130,d: "Crates, paperboard and disposable plastics, merchant wholesalers"},
{c:424130,d: "Cups, paper and disposable plastics, merchant wholesalers"},
{c:424130,d: "Diapers, paper, merchant wholesalers"},
{c:424130,d: "Dishes, paper and disposable plastics, merchant wholesalers"},
{c:424130,d: "Disposable plastics products (e.g., boxes, cups, cutlery, dishes, sanitary food containers) merchant wholesalers"},
{c:424130,d: "Eating utensils, disposable plastics, merchant wholesalers"},
{c:424130,d: "Egg cartons, paper and disposable plastics, merchant wholesalers"},
{c:424130,d: "Facial tissue merchant wholesalers"},
{c:424130,d: "Fiber cans and drums merchant wholesalers"},
{c:424130,d: "Filter papers merchant wholesalers"},
{c:424130,d: "Foam plastic trays merchant wholesalers"},
{c:424130,d: "Gummed tapes (except cellophane) merchant wholesalers"},
{c:424130,d: "Industrial supplies, disposable plastics, paper, merchant wholesalers"},
{c:424130,d: "Knives, disposable plastics, merchant wholesalers"},
{c:424130,d: "Napkins, paper, merchant wholesalers"},
{c:424130,d: "Paper (except office supplies, printing paper, stationery, writing paper) merchant wholesalers"},
{c:424130,d: "Paper bags merchant wholesalers"},
{c:424130,d: "Paper towels merchant wholesalers"},
{c:424130,d: "Paperboard and paperboard products (except office supplies) merchant wholesalers"},
{c:424130,d: "Personal sanitary paper products merchant wholesalers"},
{c:424130,d: "Plastics bags merchant wholesalers"},
{c:424130,d: "Plastics foam products, disposable (except packaging, packing), merchant wholesalers"},
{c:424130,d: "Pressed and molded pulp goods (e.g., egg cartons, shipping supplies) merchant wholesalers"},
{c:424130,d: "Sacks, paper, merchant wholesalers"},
{c:424130,d: "Sanitary food containers (e.g., disposable plastics, paper, paperboard) merchant wholesalers"},
{c:424130,d: "Sanitary paper products merchant wholesalers"},
{c:424130,d: "Shipping supplies, paper and disposable plastics, merchant wholesalers"},
{c:424130,d: "Shopping bags, paper and plastics, merchant wholesalers"},
{c:424130,d: "Tableware, disposable, merchant wholesalers"},
{c:424130,d: "Tissue paper, toilet and facial, merchant wholesalers"},
{c:424130,d: "Toilet tissue merchant wholesalers"},
{c:424130,d: "Waxed paper merchant wholesalers"},
{c:424130,d: "Wrapping paper (except gift wrap) merchant wholesalers"},
{c:424210,d: "Antibiotics merchant wholesalers"},
{c:424210,d: "Antiseptics merchant wholesalers"},
{c:424210,d: "Bacteriological medicines merchant wholesalers"},
{c:424210,d: "Bandages (except surgical) merchant wholesalers"},
{c:424210,d: "Beauty preparations merchant wholesalers"},
{c:424210,d: "Beauty supplies merchant wholesalers"},
{c:424210,d: "Biologicals and allied products merchant wholesalers"},
{c:424210,d: "Blades, razor, merchant wholesalers"},
{c:424210,d: "Blood derivatives merchant wholesalers"},
{c:424210,d: "Blood plasma merchant wholesalers"},
{c:424210,d: "Botanical drugs and herbs merchant wholesalers"},
{c:424210,d: "Botanicals merchant wholesalers"},
{c:424210,d: "Colognes merchant wholesalers"},
{c:424210,d: "Cosmetics merchant wholesalers"},
{c:424210,d: "Dental care preparations merchant wholesalers"},
{c:424210,d: "Dentifrices merchant wholesalers"},
{c:424210,d: "Deodorants, personal, merchant wholesalers"},
{c:424210,d: "Dermatological medicines merchant wholesalers"},
{c:424210,d: "Diagnostic reagents merchant wholesalers"},
{c:424210,d: "Diagnostics, in-vitro and in-vivo, merchant wholesalers"},
{c:424210,d: "Dietary supplements merchant wholesalers"},
{c:424210,d: "Drug proprietaries merchant wholesalers"},
{c:424210,d: "Druggists' sundries merchant wholesalers"},
{c:424210,d: "Drugs merchant wholesalers"},
{c:424210,d: "Endocrine substances merchant wholesalers"},
{c:424210,d: "First-aid kits (except industrial) merchant wholesalers"},
{c:424210,d: "First-aid supplies merchant wholesalers"},
{c:424210,d: "Gauze merchant wholesalers"},
{c:424210,d: "General-line drugs merchant wholesalers"},
{c:424210,d: "Hair care products merchant wholesalers"},
{c:424210,d: "Hair preparations (except professional) merchant wholesalers"},
{c:424210,d: "Herbal supplements merchant wholesalers"},
{c:424210,d: "Hygiene products, oral, merchant wholesalers"},
{c:424210,d: "Medical sundries, rubber, merchant wholesalers"},
{c:424210,d: "Nonprescription drugs merchant wholesalers"},
{c:424210,d: "Perfumes merchant wholesalers"},
{c:424210,d: "Pharmaceuticals merchant wholesalers"},
{c:424210,d: "Plasmas, blood, merchant wholesalers"},
{c:424210,d: "Prescription drugs merchant wholesalers"},
{c:424210,d: "Radioactive pharmaceutical isotopes merchant wholesalers"},
{c:424210,d: "Radiopharmaceuticals merchant wholesalers"},
{c:424210,d: "Razor blades merchant wholesalers"},
{c:424210,d: "Razors (except electric) merchant wholesalers"},
{c:424210,d: "Rubber goods, medical, merchant wholesalers"},
{c:424210,d: "Salts, bath, merchant wholesalers"},
{c:424210,d: "Shaving preparations merchant wholesalers"},
{c:424210,d: "Skin care preparations merchant wholesalers"},
{c:424210,d: "Specialty-line pharmaceuticals merchant wholesalers"},
{c:424210,d: "Toilet preparations merchant wholesalers"},
{c:424210,d: "Toilet soaps merchant wholesalers"},
{c:424210,d: "Toiletries merchant wholesalers"},
{c:424210,d: "Toothbrushes (except electric) merchant wholesalers"},
{c:424210,d: "Toothpastes merchant wholesalers"},
{c:424210,d: "Vaccines merchant wholesalers"},
{c:424210,d: "Veterinarians' medicines merchant wholesalers"},
{c:424210,d: "Vitamins merchant wholesalers"},
{c:424310,d: "Apparel trimmings merchant wholesalers"},
{c:424310,d: "Automotive fabrics merchant wholesalers"},
{c:424310,d: "Belt and buckle assembly kits merchant wholesalers"},
{c:424310,d: "Binding, textile, merchant wholesalers"},
{c:424310,d: "Buttons merchant wholesalers"},
{c:424310,d: "Drapery material merchant wholesalers"},
{c:424310,d: "Embroidery products merchant wholesalers"},
{c:424310,d: "Fabrics, textile (except burlap, felt), merchant wholesalers"},
{c:424310,d: "Fasteners, clothing, merchant wholesalers"},
{c:424310,d: "Fiberglass fabrics merchant wholesalers"},
{c:424310,d: "Hair accessories merchant wholesalers"},
{c:424310,d: "Hat and cap materials merchant wholesalers"},
{c:424310,d: "Jute piece goods (except burlap) merchant wholesalers"},
{c:424310,d: "Labels, textile, merchant wholesalers"},
{c:424310,d: "Millinery supplies merchant wholesalers"},
{c:424310,d: "Needles, sewing, merchant wholesalers"},
{c:424310,d: "Net goods merchant wholesalers"},
{c:424310,d: "Notions merchant wholesalers"},
{c:424310,d: "Paddings, apparel, merchant wholesalers"},
{c:424310,d: "Piece goods (except burlap, felt) merchant wholesalers"},
{c:424310,d: "Remnants, piece goods, merchant wholesalers"},
{c:424310,d: "Ribbons, textile, merchant wholesalers"},
{c:424310,d: "Sewing accessories merchant wholesalers"},
{c:424310,d: "Tapes, textile, merchant wholesalers"},
{c:424310,d: "Textiles (except burlap, felt) merchant wholesalers"},
{c:424310,d: "Thread (except industrial) merchant wholesalers"},
{c:424310,d: "Upholstery fabrics merchant wholesalers"},
{c:424310,d: "Yard goods, textile (except burlap, felt), merchant wholesalers"},
{c:424310,d: "Yarns (except industrial) merchant wholesalers"},
{c:424310,d: "Zippers merchant wholesalers"},
{c:424320,d: "Accessories, clothing, men's and boy's, merchant wholesalers"},
{c:424320,d: "Clothing, men's and boys', merchant wholesalers"},
{c:424320,d: "Coats, men's and boys', merchant wholesalers"},
{c:424320,d: "Costumes, clothing, men's and boys', merchant wholesalers"},
{c:424320,d: "Furnishings (except shoes), men's and boys', merchant wholesalers"},
{c:424320,d: "Gloves, men's and boys', merchant wholesalers"},
{c:424320,d: "Hats and caps, men's and boys', merchant wholesalers"},
{c:424320,d: "Hosiery, men's and boys', merchant wholesalers"},
{c:424320,d: "Men's and boys' clothing merchant wholesalers"},
{c:424320,d: "Men's and boys' furnishings (except shoes) merchant wholesalers"},
{c:424320,d: "Neckties, men's and boys', merchant wholesalers"},
{c:424320,d: "Nightwear, men's and boys', merchant wholesalers"},
{c:424320,d: "Outerwear, men's and boys', merchant wholesalers"},
{c:424320,d: "Sleepwear, men's and boys', merchant wholesalers"},
{c:424320,d: "Socks, men's and boys', merchant wholesalers"},
{c:424320,d: "Sportswear, men's and boys', merchant wholesalers"},
{c:424320,d: "Suits, men's and boys', merchant wholesalers"},
{c:424320,d: "Swimwear, men's and boys', merchant wholesalers"},
{c:424320,d: "Umbrellas, men's and boys', merchant wholesalers"},
{c:424320,d: "Underwear, men's and boys', merchant wholesalers"},
{c:424320,d: "Uniforms, men's and boys', merchant wholesalers"},
{c:424320,d: "Waterproof garments, men's and boys', merchant wholesalers"},
{c:424320,d: "Work clothing, men's and boys', merchant wholesalers"},
{c:424330,d: "Baby and infant car seats merchant wholesalers"},
{c:424330,d: "Baby and infant high chairs merchant wholesalers"},
{c:424330,d: "Baby bottles merchant wholesalers"},
{c:424330,d: "Baby clothing merchant wholesalers"},
{c:424330,d: "Booster seats merchant wholesalers"},
{c:424330,d: "Children's clothing merchant wholesalers"},
{c:424330,d: "Clothing accessories, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Clothing, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Coats, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Costumes, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Diapers (except paper) merchant wholesalers"},
{c:424330,d: "Dresses merchant wholesalers"},
{c:424330,d: "Fur clothing merchant wholesalers"},
{c:424330,d: "Furnishings (except shoes), women's, girls', and infants', merchant wholesalers"},
{c:424330,d: "Gloves, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Handbags merchant wholesalers"},
{c:424330,d: "Hats and caps, women's, girls', and infants', merchant wholesalers"},
{c:424330,d: "Hosiery, women's and girls', merchant wholesalers"},
{c:424330,d: "Hosiery, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Infants' clothing merchant wholesalers"},
{c:424330,d: "Lingerie merchant wholesalers"},
{c:424330,d: "Millinery merchant wholesalers"},
{c:424330,d: "Nightwear, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Outerwear, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Purses merchant wholesalers"},
{c:424330,d: "Sleepwear, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Socks, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Strollers merchant wholesalers"},
{c:424330,d: "Swimwear, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Umbrellas, women's and girls', merchant wholesalers"},
{c:424330,d: "Underwear, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Uniforms, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Unisex clothing merchant wholesalers"},
{c:424330,d: "Waterproof garments, women's, children's, and infants', merchant wholesalers"},
{c:424330,d: "Women's and children's clothing accessories merchant wholesalers"},
{c:424330,d: "Women's clothing merchant wholesalers"},
{c:424340,d: "Athletic footwear merchant wholesalers"},
{c:424340,d: "Boots (e.g., hiking, western, work) merchant wholesalers"},
{c:424340,d: "Footwear merchant wholesalers"},
{c:424340,d: "Leather cut stock for shoe and boot merchant wholesalers"},
{c:424340,d: "Shoe accessories merchant wholesalers"},
{c:424340,d: "Shoes merchant wholesalers"},
{c:424340,d: "Slippers merchant wholesalers"},
{c:424410,d: "Food, general-line, merchant wholesalers"},
{c:424410,d: "General-line groceries merchant wholesalers"},
{c:424410,d: "Groceries, general-line, merchant wholesalers"},
{c:424420,d: "Bakery products, frozen, merchant wholesalers"},
{c:424420,d: "Dinners, frozen, merchant wholesalers"},
{c:424420,d: "Doughs, frozen, merchant wholesalers"},
{c:424420,d: "Fish, packaged frozen, merchant wholesalers"},
{c:424420,d: "Foods, prepared, packaged frozen, merchant wholesalers"},
{c:424420,d: "Frozen foods, packaged (except dairy products), merchant wholesalers"},
{c:424420,d: "Fruits, frozen, merchant wholesalers"},
{c:424420,d: "Juices, frozen, merchant wholesalers"},
{c:424420,d: "Meats, packaged frozen, merchant wholesalers"},
{c:424420,d: "Pies (e.g., fruit, meat, poultry), frozen, merchant wholesalers"},
{c:424420,d: "Pizzas, frozen, merchant wholesalers"},
{c:424420,d: "Poultry, packaged frozen, merchant wholesalers"},
{c:424420,d: "Prepared foods, frozen (except dairy products), merchant wholesalers"},
{c:424420,d: "Pretzels, frozen, merchant wholesalers"},
{c:424420,d: "Seafoods, packaged frozen, merchant wholesalers"},
{c:424420,d: "Soups, frozen, merchant wholesalers"},
{c:424420,d: "Vegetables, frozen, merchant wholesalers"},
{c:424430,d: "Butter merchant wholesalers"},
{c:424430,d: "Buttermilk merchant wholesalers"},
{c:424430,d: "Cheese merchant wholesalers"},
{c:424430,d: "Cream merchant wholesalers"},
{c:424430,d: "Cream stations merchant wholesalers"},
{c:424430,d: "Creamery products (except canned) merchant wholesalers"},
{c:424430,d: "Dairy depots merchant wholesalers"},
{c:424430,d: "Dairy products (except canned, dried) merchant wholesalers"},
{c:424430,d: "Dairy products, frozen, merchant wholesalers"},
{c:424430,d: "Desserts, dairy, merchant wholesalers"},
{c:424430,d: "Frozen yogurt merchant wholesalers"},
{c:424430,d: "Ice cream and ices merchant wholesalers"},
{c:424430,d: "Ice cream merchant wholesalers"},
{c:424430,d: "Ice milk merchant wholesalers"},
{c:424430,d: "Milk, fluid (except canned), merchant wholesalers"},
{c:424430,d: "Prepared foods, frozen dairy, merchant wholesalers"},
{c:424430,d: "Raw milk merchant wholesalers"},
{c:424430,d: "Yogurt merchant wholesalers"},
{c:424440,d: "Chicken and chicken products (except canned and packaged frozen) merchant wholesalers"},
{c:424440,d: "Eggs merchant wholesalers"},
{c:424440,d: "Fresh poultry merchant wholesalers"},
{c:424440,d: "Frozen poultry (except packaged) merchant wholesalers"},
{c:424440,d: "Packaged poultry (except canned and frozen) merchant wholesalers"},
{c:424440,d: "Poultry and poultry products (except canned, packaged frozen) merchant wholesalers"},
{c:424440,d: "Poultry pies (except packaged frozen) merchant wholesalers"},
{c:424440,d: "Poultry, live and dressed, merchant wholesalers"},
{c:424440,d: "Processed poultry (e.g., luncheon) merchant wholesalers"},
{c:424440,d: "Turkey and turkey products (except canned and packaged frozen) merchant wholesalers"},
{c:424450,d: "Candy merchant wholesalers"},
{c:424450,d: "Cheese confections (e.g., curls, puffs) merchant wholesalers"},
{c:424450,d: "Chewing gum merchant wholesalers"},
{c:424450,d: "Chips (e.g., corn, potato) merchant wholesalers"},
{c:424450,d: "Chocolate candy merchant wholesalers"},
{c:424450,d: "Concentrates, fountain (except soft drink), merchant wholesalers"},
{c:424450,d: "Confectionery merchant wholesalers"},
{c:424450,d: "Corn chips and related corn snacks merchant wholesalers"},
{c:424450,d: "Fountain fruits and syrups (except soft drink) merchant wholesalers"},
{c:424450,d: "Fountain syrups (except soft drink) merchant wholesalers"},
{c:424450,d: "Fruit concentrates, fountain, merchant wholesalers"},
{c:424450,d: "Gum, chewing, merchant wholesalers"},
{c:424450,d: "Nuts (e.g., canned, roasted, salted) merchant wholesalers"},
{c:424450,d: "Popcorn merchant wholesalers"},
{c:424450,d: "Potato chips and related snacks merchant wholesalers"},
{c:424450,d: "Seeds (e.g., canned, roasted, salted) merchant wholesalers"},
{c:424450,d: "Syrups, fountain (except soft drink), merchant wholesalers"},
{c:424450,d: "Toppings, fountain, merchant wholesalers"},
{c:424460,d: "Cured fish merchant wholesalers"},
{c:424460,d: "Fish (except canned, packaged frozen) merchant wholesalers"},
{c:424460,d: "Fish, salted or preserved (except canned), merchant wholesalers"},
{c:424460,d: "Fresh fish merchant wholesalers"},
{c:424460,d: "Fresh seafood merchant wholesalers"},
{c:424460,d: "Frozen fish (except packaged) merchant wholesalers"},
{c:424460,d: "Frozen seafood (except packaged) merchant wholesalers"},
{c:424460,d: "Seafood (except canned, packaged frozen) merchant wholesalers"},
{c:424470,d: "Cutting of purchased carcasses (except boxed meat cut on an assembly line basis) merchant wholesalers"},
{c:424470,d: "Deli meats merchant wholesalers"},
{c:424470,d: "Fresh meats merchant wholesalers"},
{c:424470,d: "Frozen meats (except packaged) merchant wholesalers"},
{c:424470,d: "Lard merchant wholesalers"},
{c:424470,d: "Meats and meat products (except canned, packaged frozen) merchant wholesalers"},
{c:424470,d: "Meats, cured or smoked, merchant wholesalers"},
{c:424470,d: "Meats, fresh, merchant wholesalers"},
{c:424470,d: "Meats, frozen (except packaged), merchant wholesalers"},
{c:424470,d: "Processed meats (e.g., luncheon, sausage) merchant wholesalers"},
{c:424480,d: "Berries, fresh, merchant wholesalers"},
{c:424480,d: "Fresh fruits, vegetables, and berries merchant wholesalers"},
{c:424480,d: "Fruits, fresh, merchant wholesalers"},
{c:424480,d: "Health foods, fresh fruits and vegetables, merchant wholesalers"},
{c:424480,d: "Produce, fresh, merchant wholesalers"},
{c:424480,d: "Salads, prepackaged, merchant wholesalers"},
{c:424480,d: "Vegetables, fresh, merchant wholesalers"},
{c:424490,d: "Baby foods, canned, merchant wholesalers"},
{c:424490,d: "Bakery products (except frozen) merchant wholesalers"},
{c:424490,d: "Beans, dry edible, merchant wholesalers"},
{c:424490,d: "Beverage bases merchant wholesalers"},
{c:424490,d: "Beverage concentrates merchant wholesalers"},
{c:424490,d: "Bottled water (except water treating) merchant wholesalers"},
{c:424490,d: "Bread, packaged (except frozen), merchant wholesalers"},
{c:424490,d: "Breakfast cereals merchant wholesalers"},
{c:424490,d: "Brewers' yeast merchant wholesalers"},
{c:424490,d: "Butter substitutes merchant wholesalers"},
{c:424490,d: "Cane sugar, refined, merchant wholesalers"},
{c:424490,d: "Canned foods (e.g., fish, meat, seafood, soups) merchant wholesalers"},
{c:424490,d: "Carbonated beverages merchant wholesalers"},
{c:424490,d: "Cereal products merchant wholesalers"},
{c:424490,d: "Chocolate (except candy) merchant wholesalers"},
{c:424490,d: "Chocolate coatings merchant wholesalers"},
{c:424490,d: "Chocolate syrups (except fountain) merchant wholesalers"},
{c:424490,d: "Coffee merchant wholesalers"},
{c:424490,d: "Cookies merchant wholesalers"},
{c:424490,d: "Cooking oils merchant wholesalers"},
{c:424490,d: "Corn milling products (except pet and livestock feeds) merchant wholesalers"},
{c:424490,d: "Cornmeal, edible, merchant wholesalers"},
{c:424490,d: "Crackers merchant wholesalers"},
{c:424490,d: "Creamery products, canned, merchant wholesalers"},
{c:424490,d: "Dairy products, dried or canned, merchant wholesalers"},
{c:424490,d: "Dessert powders merchant wholesalers"},
{c:424490,d: "Dried foods (e.g., fruits, milk, vegetables) merchant wholesalers"},
{c:424490,d: "Fish, canned, merchant wholesalers"},
{c:424490,d: "Flavoring extracts (except for fountain use) merchant wholesalers"},
{c:424490,d: "Flour merchant wholesalers"},
{c:424490,d: "Fruits, canned, merchant wholesalers"},
{c:424490,d: "Gelatin, edible, merchant wholesalers"},
{c:424490,d: "Health foods (except fresh fruits, vegetables) merchant wholesalers"},
{c:424490,d: "Honey merchant wholesalers"},
{c:424490,d: "Hop extract merchant wholesalers"},
{c:424490,d: "Ice cream cones merchant wholesalers"},
{c:424490,d: "Jellies and jams merchant wholesalers"},
{c:424490,d: "Juices, canned or fresh, merchant wholesalers"},
{c:424490,d: "Macaroni merchant wholesalers"},
{c:424490,d: "Malt extract merchant wholesalers"},
{c:424490,d: "Malt merchant wholesalers"},
{c:424490,d: "Maple syrup merchant wholesalers"},
{c:424490,d: "Margarine merchant wholesalers"},
{c:424490,d: "Marmalade merchant wholesalers"},
{c:424490,d: "Meats, canned, merchant wholesalers"},
{c:424490,d: "Milk, canned or dried, merchant wholesalers"},
{c:424490,d: "Mixes (e.g., cake, dessert, pie) merchant wholesalers"},
{c:424490,d: "Molasses merchant wholesalers"},
{c:424490,d: "Oils, cooking and salad, merchant wholesalers"},
{c:424490,d: "Pet food merchant wholesalers"},
{c:424490,d: "Pizzas (except frozen) merchant wholesalers"},
{c:424490,d: "Poultry, canned, merchant wholesalers"},
{c:424490,d: "Prepared foods (except frozen) merchant wholesalers"},
{c:424490,d: "Pretzels (except frozen) merchant wholesalers"},
{c:424490,d: "Rice, polished, merchant wholesalers"},
{c:424490,d: "Salad dressings merchant wholesalers"},
{c:424490,d: "Salad oils merchant wholesalers"},
{c:424490,d: "Salt, table, merchant wholesalers"},
{c:424490,d: "Sandwiches merchant wholesalers"},
{c:424490,d: "Sausage casings merchant wholesalers"},
{c:424490,d: "Seafood, canned, merchant wholesalers"},
{c:424490,d: "Shortening, vegetable, merchant wholesalers"},
{c:424490,d: "Soft drinks merchant wholesalers"},
{c:424490,d: "Soups (except frozen) merchant wholesalers"},
{c:424490,d: "Spaghetti merchant wholesalers"},
{c:424490,d: "Spices merchant wholesalers"},
{c:424490,d: "Sport energy drinks merchant wholesalers"},
{c:424490,d: "Sugar, refined, merchant wholesalers"},
{c:424490,d: "Syrup (except fountain) merchant wholesalers"},
{c:424490,d: "Tea merchant wholesalers"},
{c:424490,d: "Toppings (except fountain) merchant wholesalers"},
{c:424490,d: "Vegetables, canned, merchant wholesalers"},
{c:424490,d: "Water, bottled (except water treating), merchant wholesalers"},
{c:424490,d: "Yeast merchant wholesalers"},
{c:424510,d: "Beans, dry inedible, merchant wholesalers"},
{c:424510,d: "Corn, raw (except seed corn), merchant wholesalers"},
{c:424510,d: "Dry beans, inedible, merchant wholesalers"},
{c:424510,d: "Grain elevators merchant wholesalers"},
{c:424510,d: "Grain merchant wholesalers"},
{c:424510,d: "Rice, unpolished, merchant wholesalers"},
{c:424510,d: "Soybeans merchant wholesalers"},
{c:424510,d: "Wheat merchant wholesalers"},
{c:424520,d: "Auction markets, livestock (except horses, mules), merchant wholesalers"},
{c:424520,d: "Cattle merchant wholesalers"},
{c:424520,d: "Goats merchant wholesalers"},
{c:424520,d: "Hogs merchant wholesalers"},
{c:424520,d: "Livestock (except horses, mules, and feedlots) merchant wholesalers"},
{c:424520,d: "Sheep merchant wholesalers"},
{c:424520,d: "Swine merchant wholesalers"},
{c:424590,d: "Animal hair, wool, or mohair merchant wholesalers"},
{c:424590,d: "Auction markets, tobacco, horses, mules, merchant wholesalers"},
{c:424590,d: "Bees merchant wholesalers"},
{c:424590,d: "Bovine semen merchant wholesalers"},
{c:424590,d: "Bristles merchant wholesalers"},
{c:424590,d: "Broomcorn merchant wholesalers"},
{c:424590,d: "Chicks, live, merchant wholesalers"},
{c:424590,d: "Cocoa beans merchant wholesalers"},
{c:424590,d: "Cotton, raw, merchant wholesalers"},
{c:424590,d: "Feathers merchant wholesalers"},
{c:424590,d: "Fibers, vegetable, merchant wholesalers"},
{c:424590,d: "Furs, raw, merchant wholesalers"},
{c:424590,d: "Hides merchant wholesalers"},
{c:424590,d: "Hops merchant wholesalers"},
{c:424590,d: "Horses merchant wholesalers"},
{c:424590,d: "Leaf tobacco merchant wholesalers"},
{c:424590,d: "Marijuana merchant wholesalers"},
{c:424590,d: "Mohair, raw, merchant wholesalers"},
{c:424590,d: "Mules merchant wholesalers"},
{c:424590,d: "Nuts, unprocessed or shelled only, merchant wholesalers"},
{c:424590,d: "Oil kernels merchant wholesalers"},
{c:424590,d: "Oil nuts merchant wholesalers"},
{c:424590,d: "Oilseeds merchant wholesalers"},
{c:424590,d: "Peanuts, bulk, unprocessed, merchant wholesalers"},
{c:424590,d: "Pelts, raw, merchant wholesalers"},
{c:424590,d: "Raw farm products (except field beans, grains) merchant wholesalers"},
{c:424590,d: "Semen, bovine, merchant wholesalers"},
{c:424590,d: "Silk, raw, merchant wholesalers"},
{c:424590,d: "Skins, raw, merchant wholesalers"},
{c:424590,d: "Sod merchant wholesalers"},
{c:424590,d: "Spices, raw, merchant wholesalers"},
{c:424590,d: "Sugar, raw, merchant wholesalers"},
{c:424590,d: "Tobacco, leaf, merchant wholesalers"},
{c:424590,d: "Wool tops and noils merchant wholesalers"},
{c:424590,d: "Wool, raw, merchant wholesalers"},
{c:424610,d: "Cellulose film merchant wholesalers"},
{c:424610,d: "Foam, plastics, resins and shapes, merchant wholesalers"},
{c:424610,d: "Nylon resins merchant wholesalers"},
{c:424610,d: "Plasticizers merchant wholesalers"},
{c:424610,d: "Plastics basic shapes (e.g., film, rod, sheet, sheeting, tubing) merchant wholesalers"},
{c:424610,d: "Plastics foam merchant wholesalers"},
{c:424610,d: "Plastics materials merchant wholesalers"},
{c:424610,d: "Plastics resins merchant wholesalers"},
{c:424610,d: "Polyester resins merchant wholesalers"},
{c:424610,d: "Resins, plastics, merchant wholesalers"},
{c:424610,d: "Stabilizers, plastic, merchant wholesalers"},
{c:424690,d: "Acids merchant wholesalers"},
{c:424690,d: "Adhesives and sealants merchant wholesalers"},
{c:424690,d: "Alcohol, industrial, merchant wholesalers"},
{c:424690,d: "Alkalies merchant wholesalers"},
{c:424690,d: "Ammonia (except fertilizer material) merchant wholesalers"},
{c:424690,d: "Antifreeze merchant wholesalers"},
{c:424690,d: "Aromatic chemicals merchant wholesalers"},
{c:424690,d: "Automotive chemicals (except lubricating greases, lubrication oils) merchant wholesalers"},
{c:424690,d: "Bleaches merchant wholesalers"},
{c:424690,d: "Carbon black merchant wholesalers"},
{c:424690,d: "Caulking materials merchant wholesalers"},
{c:424690,d: "Caustic soda merchant wholesalers"},
{c:424690,d: "Chemical additives (e.g., concrete, food, fuel, oil) merchant wholesalers"},
{c:424690,d: "Chemical gases merchant wholesalers"},
{c:424690,d: "Chemicals (except agriculture) (e.g., automotive, household, industrial, photographic) merchant wholesalers"},
{c:424690,d: "Chlorine merchant wholesalers"},
{c:424690,d: "Cleaning compounds and preparations merchant wholesalers"},
{c:424690,d: "Coal tar distillates and resins merchant wholesalers"},
{c:424690,d: "Coal tar products, primary and intermediate, merchant wholesalers"},
{c:424690,d: "Compressed gases (except LP gas) merchant wholesalers"},
{c:424690,d: "Concrete additives merchant wholesalers"},
{c:424690,d: "Cyclic crudes and intermediates merchant wholesalers"},
{c:424690,d: "Deodorants (except personal) merchant wholesalers"},
{c:424690,d: "Detergents merchant wholesalers"},
{c:424690,d: "Detonators (except ammunition) merchant wholesalers"},
{c:424690,d: "Disinfectants (except agricultural) merchant wholesalers"},
{c:424690,d: "Drilling muds merchant wholesalers"},
{c:424690,d: "Dry ice merchant wholesalers"},
{c:424690,d: "Drycleaning solvents and chemicals merchant wholesalers"},
{c:424690,d: "Dyes, industrial, merchant wholesalers"},
{c:424690,d: "Dyestuffs merchant wholesalers"},
{c:424690,d: "Dynamite merchant wholesalers"},
{c:424690,d: "Essential oils merchant wholesalers"},
{c:424690,d: "Explosives (except ammunition, fireworks) merchant wholesalers"},
{c:424690,d: "Fabric softeners merchant wholesalers"},
{c:424690,d: "Fibers, manmade, merchant wholesalers"},
{c:424690,d: "Fire extinguisher preparations merchant wholesalers"},
{c:424690,d: "Food additives, chemical, merchant wholesalers"},
{c:424690,d: "Fuel additives merchant wholesalers"},
{c:424690,d: "Gases, compressed and liquefied (except liquefied petroleum gas), merchant wholesalers"},
{c:424690,d: "Gelatin, inedible, merchant wholesalers"},
{c:424690,d: "Glues merchant wholesalers"},
{c:424690,d: "Gum and wood chemicals merchant wholesalers"},
{c:424690,d: "Ice, dry, merchant wholesalers"},
{c:424690,d: "Industrial chemicals merchant wholesalers"},
{c:424690,d: "Industrial gases merchant wholesalers"},
{c:424690,d: "Industrial salts merchant wholesalers"},
{c:424690,d: "Inorganic chemicals merchant wholesalers"},
{c:424690,d: "Insecticides (except lawn and agricultural) merchant wholesalers"},
{c:424690,d: "Janitorial chemicals merchant wholesalers"},
{c:424690,d: "Laundry soap, chips, and powder, merchant wholesalers"},
{c:424690,d: "Liquefied gases (except LP) merchant wholesalers"},
{c:424690,d: "Manmade fibers merchant wholesalers"},
{c:424690,d: "Mastics (except construction) merchant wholesalers"},
{c:424690,d: "Medical gases merchant wholesalers"},
{c:424690,d: "Metal cyanides merchant wholesalers"},
{c:424690,d: "Metalworking compounds merchant wholesalers"},
{c:424690,d: "Nylon fibers merchant wholesalers"},
{c:424690,d: "Oil additives merchant wholesalers"},
{c:424690,d: "Oil drilling muds merchant wholesalers"},
{c:424690,d: "Organic chemicals merchant wholesalers"},
{c:424690,d: "Pesticides (except agricultural) merchant wholesalers"},
{c:424690,d: "Polishes (e.g., automobile, furniture, metal, shoe, stove) merchant wholesalers"},
{c:424690,d: "Polyester fibers merchant wholesalers"},
{c:424690,d: "Resins, synthetic rubber, merchant wholesalers"},
{c:424690,d: "Rosins merchant wholesalers"},
{c:424690,d: "Salts, industrial, merchant wholesalers"},
{c:424690,d: "Sealants merchant wholesalers"},
{c:424690,d: "Sulfuric acid merchant wholesalers"},
{c:424690,d: "Surface active agents merchant wholesalers"},
{c:424690,d: "Synthetic rubber merchant wholesalers"},
{c:424690,d: "Turpentine merchant wholesalers"},
{c:424690,d: "Water softening compounds merchant wholesalers"},
{c:424690,d: "Waxes (except petroleum) merchant wholesalers"},
{c:424690,d: "Welding gases merchant wholesalers"},
{c:424690,d: "Wood treating preparations merchant wholesalers"},
{c:424710,d: "Asphalt binder bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Asphalt, liquid, bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Bulk gasoline stations, merchant wholesalers"},
{c:424710,d: "Bulk stations, petroleum, merchant wholesalers"},
{c:424710,d: "Compressed liquefied petroleum gas (LPG) bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Crude oil terminals, merchant wholesalers"},
{c:424710,d: "Diesel fuel bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Fuel oil bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Gasohol bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Gasoline bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Heating oil bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Jet fuel bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Kerosene bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Liquefied petroleum gas (LPG) bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Lubricating oils and greases bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Oil, petroleum, bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Petroleum and petroleum products bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Propane bulk stations and terminals, merchant wholesalers"},
{c:424710,d: "Terminals, petroleum, merchant wholesalers"},
{c:424720,d: "Asphalt binder merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Asphalt, liquid, merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Butane gas merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Crude oil merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Diesel fuel merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Fuel oil merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Fuel oil truck jobbers"},
{c:424720,d: "Fuel, aircraft, merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Fueling aircraft (except on contract basis), merchant wholesalers"},
{c:424720,d: "Gasohol merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Gasoline merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Kerosene merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Liquefied petroleum gas (LPG) merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Lubricating oils and greases merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Oil, petroleum, merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Petroleum and petroleum products merchant wholesalers (except bulk stations, terminals)"},
{c:424720,d: "Petroleum coke merchant wholesalers"},
{c:424720,d: "Propane merchant wholesalers (except bulk stations, terminals)"},
{c:424810,d: "Alcoholic beverages (except distilled spirits, wine) merchant wholesalers"},
{c:424810,d: "Ale merchant wholesalers"},
{c:424810,d: "Beer merchant wholesalers"},
{c:424810,d: "Beverages, alcoholic (except distilled spirits, wine), merchant wholesalers"},
{c:424810,d: "Fermented malt beverages merchant wholesalers"},
{c:424810,d: "Malt liquor merchant wholesalers"},
{c:424810,d: "Porter merchant wholesalers"},
{c:424820,d: "Alcoholic beverages, wine, and distilled spirits merchant wholesalers"},
{c:424820,d: "Beverages, wine and distilled spirits, merchant wholesalers"},
{c:424820,d: "Brandy and brandy spirits merchant wholesalers"},
{c:424820,d: "Cocktails, alcoholic, premixed, merchant wholesalers"},
{c:424820,d: "Distilled alcoholic beverages merchant wholesalers"},
{c:424820,d: "Ethyl alcohol merchant wholesalers"},
{c:424820,d: "Hard cider merchant wholesalers"},
{c:424820,d: "Liquors merchant wholesalers"},
{c:424820,d: "Liquors, distilled, merchant wholesalers"},
{c:424820,d: "Neutral spirits merchant wholesalers"},
{c:424820,d: "Spirits, distilled, merchant wholesalers"},
{c:424820,d: "Wine coolers, alcoholic, merchant wholesalers"},
{c:424820,d: "Wines merchant wholesalers"},
{c:424910,d: "Agricultural chemicals merchant wholesalers"},
{c:424910,d: "Agricultural limestone merchant wholesalers"},
{c:424910,d: "Alfalfa merchant wholesalers"},
{c:424910,d: "Ammonia, fertilizer material, merchant wholesalers"},
{c:424910,d: "Animal feeds (except pet food) merchant wholesalers"},
{c:424910,d: "Beekeeping supplies merchant wholesalers"},
{c:424910,d: "Chemicals, agricultural, merchant wholesalers"},
{c:424910,d: "Farm supplies merchant wholesalers"},
{c:424910,d: "Feed additives merchant wholesalers"},
{c:424910,d: "Feeds (except pet) merchant wholesalers"},
{c:424910,d: "Fertilizer and fertilizer materials merchant wholesalers"},
{c:424910,d: "Flower bulbs merchant wholesalers"},
{c:424910,d: "Fungicides, agricultural, merchant wholesalers"},
{c:424910,d: "Garden supplies (e.g., fertilizers, pesticides) merchant wholesalers"},
{c:424910,d: "Harness equipment merchant wholesalers"},
{c:424910,d: "Hay merchant wholesalers"},
{c:424910,d: "Herbicides merchant wholesalers"},
{c:424910,d: "Horticultural products merchant wholesalers"},
{c:424910,d: "Insecticides, agricultural, merchant wholesalers"},
{c:424910,d: "Lawn care supplies (e.g., chemicals, fertilizers, pesticides) merchant wholesalers"},
{c:424910,d: "Lime, agricultural, merchant wholesalers"},
{c:424910,d: "Livestock feeds merchant wholesalers"},
{c:424910,d: "Mineral supplements, animal, merchant wholesalers"},
{c:424910,d: "Mulch merchant wholesalers"},
{c:424910,d: "Pesticides, agricultural, merchant wholesalers"},
{c:424910,d: "Phosphate rock, ground, merchant wholesalers"},
{c:424910,d: "Plant bulbs merchant wholesalers"},
{c:424910,d: "Produce containers merchant wholesalers"},
{c:424910,d: "Saddlery merchant wholesalers"},
{c:424910,d: "Seeds (e.g., field, flower, garden) merchant wholesalers"},
{c:424910,d: "Straw merchant wholesalers"},
{c:424910,d: "Tack (e.g., harnesses, saddlery) merchant wholesalers"},
{c:424910,d: "Vegetable dusts and sprays merchant wholesalers"},
{c:424910,d: "Weed killers merchant wholesalers"},
{c:424920,d: "Antique book merchant wholesalers"},
{c:424920,d: "Atlases merchant wholesalers"},
{c:424920,d: "Books merchant wholesalers"},
{c:424920,d: "Brochures merchant wholesalers"},
{c:424920,d: "Magazines merchant wholesalers"},
{c:424920,d: "Maps (except globe, school, wall) merchant wholesalers"},
{c:424920,d: "Newspaper agencies merchant wholesalers"},
{c:424920,d: "Newspapers merchant wholesalers"},
{c:424920,d: "Pamphlets merchant wholesalers"},
{c:424920,d: "Periodicals merchant wholesalers"},
{c:424920,d: "Textbooks merchant wholesalers"},
{c:424930,d: "Artificial flowers and plants merchant wholesalers"},
{c:424930,d: "Bedding plants merchant wholesalers"},
{c:424930,d: "Florist's supplies merchant wholesalers"},
{c:424930,d: "Flowers merchant wholesalers"},
{c:424930,d: "Nursery stock (except plant bulbs, seeds) merchant wholesalers"},
{c:424930,d: "Ornamental plants and flowers merchant wholesalers"},
{c:424930,d: "Plants, potted, merchant wholesalers"},
{c:424930,d: "Soil, top and potting, merchant wholesalers"},
{c:424930,d: "Trees merchant wholesalers"},
{c:424940,d: "Chewing tobacco merchant wholesalers"},
{c:424940,d: "Cigarettes merchant wholesalers"},
{c:424940,d: "Cigars merchant wholesalers"},
{c:424940,d: "Electronic cigarettes merchant wholesalers"},
{c:424940,d: "Pipe tobacco merchant wholesalers"},
{c:424940,d: "Snuff merchant wholesalers"},
{c:424940,d: "Tobacco (except leaf) merchant wholesalers"},
{c:424940,d: "Tobacco products merchant wholesalers"},
{c:424950,d: "Automotive paints merchant wholesalers"},
{c:424950,d: "Calcimines merchant wholesalers"},
{c:424950,d: "Enamels merchant wholesalers"},
{c:424950,d: "Lacquers merchant wholesalers"},
{c:424950,d: "Paint removers merchant wholesalers"},
{c:424950,d: "Paint rollers merchant wholesalers"},
{c:424950,d: "Paint thinners merchant wholesalers"},
{c:424950,d: "Paintbrushes merchant wholesalers"},
{c:424950,d: "Painter's supplies (except artists', turpentine) merchant wholesalers"},
{c:424950,d: "Paints (except artists') merchant wholesalers"},
{c:424950,d: "Pigments, paint, merchant wholesalers"},
{c:424950,d: "Shellac merchant wholesalers"},
{c:424950,d: "Spray painting equipment (except industrial-type) merchant wholesalers"},
{c:424950,d: "Stains merchant wholesalers"},
{c:424950,d: "Varnishes merchant wholesalers"},
{c:424950,d: "Wall coverings (e.g., fabric, plastics) merchant wholesalers"},
{c:424950,d: "Wallpaper merchant wholesalers"},
{c:424950,d: "Wood paste merchant wholesalers"},
{c:424990,d: "Aquarium fish and supplies merchant wholesalers"},
{c:424990,d: "Art goods merchant wholesalers"},
{c:424990,d: "Artificial Christmas trees merchant wholesalers"},
{c:424990,d: "Artists' supplies merchant wholesalers"},
{c:424990,d: "Awnings, canvas, merchant wholesalers"},
{c:424990,d: "Backpacks, textile, merchant wholesalers"},
{c:424990,d: "Bags, textile, merchant wholesalers"},
{c:424990,d: "Bait, live, merchant wholesalers"},
{c:424990,d: "Baskets merchant wholesalers"},
{c:424990,d: "Brushes, artists', merchant wholesalers"},
{c:424990,d: "Burlap merchant wholesalers"},
{c:424990,d: "Candles merchant wholesalers"},
{c:424990,d: "Canvas products merchant wholesalers"},
{c:424990,d: "Cats merchant wholesalers"},
{c:424990,d: "Chamois, leather, merchant wholesalers"},
{c:424990,d: "Charcoal merchant wholesalers"},
{c:424990,d: "Christmas ornaments merchant wholesalers"},
{c:424990,d: "Christmas trees (e.g., artificial, cut) merchant wholesalers"},
{c:424990,d: "Cigarette lighters merchant wholesalers"},
{c:424990,d: "Clothes hangers merchant wholesalers"},
{c:424990,d: "Cotton (except raw) merchant wholesalers"},
{c:424990,d: "Crude rubber merchant wholesalers"},
{c:424990,d: "Curios merchant wholesalers"},
{c:424990,d: "Dogs merchant wholesalers"},
{c:424990,d: "Dressed furs and skins merchant wholesalers"},
{c:424990,d: "Felt merchant wholesalers"},
{c:424990,d: "Fish, tropical, merchant wholesalers"},
{c:424990,d: "Foam rubber merchant wholesalers"},
{c:424990,d: "Furs, dressed, merchant wholesalers"},
{c:424990,d: "General merchandise, nondurable goods, merchant wholesalers"},
{c:424990,d: "Greases, inedible animal and vegetable, merchant wholesalers"},
{c:424990,d: "Hairbrushes merchant wholesalers"},
{c:424990,d: "Hairpieces (e.g., toupees, wigs, wiglets) merchant wholesalers"},
{c:424990,d: "Handles (e.g., broom, mop, paint) merchant wholesalers"},
{c:424990,d: "Ice (except dry ice) merchant wholesalers"},
{c:424990,d: "Jewelry boxes merchant wholesalers"},
{c:424990,d: "Leather cut stock (except boot, shoe) merchant wholesalers"},
{c:424990,d: "Leather goods (except belting, footwear, handbags, gloves, luggage) merchant wholesalers"},
{c:424990,d: "Lighters, cigar and cigarette, merchant wholesalers"},
{c:424990,d: "Live bait merchant wholesalers"},
{c:424990,d: "Matches and match books merchant wholesalers"},
{c:424990,d: "Music, sheet, merchant wholesalers"},
{c:424990,d: "Novelties merchant wholesalers"},
{c:424990,d: "Oils, inedible, animal or vegetable, merchant wholesalers"},
{c:424990,d: "Oilseed cake and meal merchant wholesalers"},
{c:424990,d: "Paints, artist's, merchant wholesalers"},
{c:424990,d: "Pet supplies (except pet food) merchant wholesalers"},
{c:424990,d: "Pets merchant wholesalers"},
{c:424990,d: "Piece goods, burlap and felt, merchant wholesalers"},
{c:424990,d: "Plant food merchant wholesalers"},
{c:424990,d: "Plastics foam products (except disposable and packaging) merchant wholesalers"},
{c:424990,d: "Pottery, novelty, merchant wholesalers"},
{c:424990,d: "Pre-paid calling card distribution, merchant wholesalers"},
{c:424990,d: "Rennets merchant wholesalers"},
{c:424990,d: "Rubber, crude, merchant wholesalers"},
{c:424990,d: "Sawdust merchant wholesalers"},
{c:424990,d: "Sheet music merchant wholesalers"},
{c:424990,d: "Ship chandler merchant wholesalers"},
{c:424990,d: "Skins, dressed, merchant wholesalers"},
{c:424990,d: "Smokers' supplies merchant wholesalers"},
{c:424990,d: "Sponges merchant wholesalers"},
{c:424990,d: "Statuary (except religious) merchant wholesalers"},
{c:424990,d: "Tents (except camping) merchant wholesalers"},
{c:424990,d: "Textile bags merchant wholesalers"},
{c:424990,d: "Toupees merchant wholesalers"},
{c:424990,d: "Tropical fish merchant wholesalers"},
{c:424990,d: "Vegetable cake and meal merchant wholesalers"},
{c:424990,d: "Vegetable oils (except cooking) merchant wholesalers"},
{c:424990,d: "Wigs merchant wholesalers"},
{c:424990,d: "Wood carvings merchant wholesalers"},
{c:424990,d: "Wood pulp merchant wholesalers"},
{c:424990,d: "Worms merchant wholesalers"},
{c:424990,d: "Yarns, industrial, merchant wholesalers"},
{c:425110,d: "Business to business electronic markets, durable goods, wholesale trade"},
{c:425110,d: "Business to business electronic markets, nondurable goods, wholesale trade"},
{c:425110,d: "Durable goods business to business electronic markets, wholesale trade"},
{c:425110,d: "Electronic markets, durable goods, business to business, wholesale trade"},
{c:425110,d: "Electronic markets, nondurable goods, business to business, wholesale trade"},
{c:425110,d: "Nondurable goods business to business electronic markets, wholesale trade"},
{c:425120,d: "Agents and brokers, durable goods, wholesale trade"},
{c:425120,d: "Agents and brokers, nondurable goods, wholesale trade"},
{c:425120,d: "Automobile auctions, wholesale"},
{c:425120,d: "Durable goods agents and brokers, wholesale trade"},
{c:425120,d: "Nondurable goods agents and brokers, wholesale trade"},
{c:425120,d: "Petroleum brokers"},
{c:441110,d: "Automobile dealers, new only or new and used"},
{c:441110,d: "Light utility truck dealers, new only or new and used"},
{c:441110,d: "New car dealers"},
{c:441120,d: "Antique auto dealers"},
{c:441120,d: "Automobile dealers, used only"},
{c:441120,d: "Light utility truck dealers, used only"},
{c:441120,d: "Used car dealers"},
{c:441210,d: "Camper dealers, recreational"},
{c:441210,d: "Camper parts and accessories stores"},
{c:441210,d: "Motor home dealers"},
{c:441210,d: "Recreational vehicle (RV) dealers"},
{c:441210,d: "Recreational vehicle (RV) parts and accessories stores"},
{c:441210,d: "RV dealers"},
{c:441210,d: "Travel trailer dealers"},
{c:441210,d: "Used recreational vehicle (RV) dealers"},
{c:441222,d: "Boat dealers, new and used"},
{c:441222,d: "Boat trailer dealers"},
{c:441222,d: "Marine supply dealers"},
{c:441222,d: "Outboard motor dealers"},
{c:441222,d: "Power boat dealers"},
{c:441222,d: "Sailboat dealers"},
{c:441222,d: "Used boat dealers"},
{c:441228,d: "Aircraft dealers"},
{c:441228,d: "All-terrain vehicle (ATV) dealers"},
{c:441228,d: "Bicycle shops, motorized"},
{c:441228,d: "Golf cart dealers, powered"},
{c:441228,d: "Horse trailer dealers"},
{c:441228,d: "Moped dealers"},
{c:441228,d: "Motor scooters dealers"},
{c:441228,d: "Motorbike dealers"},
{c:441228,d: "Motorcycle dealers"},
{c:441228,d: "Motorcycle parts and accessories dealers"},
{c:441228,d: "Off-road all-terrain vehicles (ATVs), wheeled or tracked, dealers"},
{c:441228,d: "Personal watercraft dealers"},
{c:441228,d: "Snowmobile dealers"},
{c:441228,d: "Used aircraft dealers"},
{c:441228,d: "Used motorcycle dealers"},
{c:441228,d: "Used utility trailer dealers"},
{c:441228,d: "Utility trailer dealers"},
{c:441310,d: "Auto supply stores"},
{c:441310,d: "Automobile parts dealers"},
{c:441310,d: "Automotive audio equipment stores"},
{c:441310,d: "Automotive parts and supply stores"},
{c:441310,d: "Automotive parts dealers, used"},
{c:441310,d: "Batteries, automotive, dealers"},
{c:441310,d: "Parts and accessories dealers, automotive"},
{c:441310,d: "Speed shops"},
{c:441310,d: "Stereo stores, automotive"},
{c:441310,d: "Truck cap stores"},
{c:441310,d: "Used automotive parts stores"},
{c:441320,d: "Automotive tire dealers"},
{c:441320,d: "Tire dealers, automotive"},
{c:441320,d: "Truck tires and tubes dealers (except heavy truck)"},
{c:441320,d: "Used automotive tire dealers"},
{c:441320,d: "Used tire dealers"},
{c:442110,d: "Bed stores, retail"},
{c:442110,d: "Furniture and appliance stores (i.e., primarily retailing furniture)"},
{c:442110,d: "Furniture stores (e.g., household, office, outdoor)"},
{c:442110,d: "Mattress stores (including waterbeds)"},
{c:442110,d: "Office furniture stores"},
{c:442210,d: "Carpet stores"},
{c:442210,d: "Floor covering stores (except wood or ceramic tile only)"},
{c:442210,d: "Rug stores"},
{c:442291,d: "Curtain and drapery stores, packaged"},
{c:442291,d: "Window treatment stores"},
{c:442299,d: "Bath shops"},
{c:442299,d: "Chinaware stores"},
{c:442299,d: "Custom picture frame shops"},
{c:442299,d: "Glassware stores"},
{c:442299,d: "Home furnishings stores"},
{c:442299,d: "Housewares stores"},
{c:442299,d: "Kitchenware stores"},
{c:442299,d: "Lamp shops, electric"},
{c:442299,d: "Linen stores"},
{c:442299,d: "Picture frame shops, custom"},
{c:442299,d: "Wood-burning stove stores"},
{c:443141,d: "Appliance stores, household-type"},
{c:443141,d: "Household-type appliance stores"},
{c:443141,d: "Sewing machine stores, household-type"},
{c:443141,d: "Vacuum cleaner stores, household-type"},
{c:443142,d: "Audio equipment stores (except automotive)"},
{c:443142,d: "Camera shops, photographic"},
{c:443142,d: "Cellular telephone accessories stores"},
{c:443142,d: "Citizens' band (CB) radio stores"},
{c:443142,d: "Computer equipment stores"},
{c:443142,d: "Computer stores"},
{c:443142,d: "Consumer-type electronic stores (e.g., televisions, computers, cameras)"},
{c:443142,d: "Electronic part and component stores"},
{c:443142,d: "Music stores (e.g., cassette, compact disc, record, tape)"},
{c:443142,d: "Photographic supply stores"},
{c:443142,d: "Radio and television stores"},
{c:443142,d: "Record stores, new"},
{c:443142,d: "Software stores, computer"},
{c:443142,d: "Stereo stores (except automotive)"},
{c:443142,d: "Television and radio stores"},
{c:443142,d: "TV (television) stores"},
{c:443142,d: "Video game software stores"},
{c:443142,d: "Video tape stores"},
{c:444110,d: "Home centers, building materials"},
{c:444110,d: "Home improvement centers"},
{c:444120,d: "Paint stores"},
{c:444120,d: "Wallpaper and wall coverings stores"},
{c:444130,d: "Handtools, power-driven, repair and maintenance services retailing new power-driven handtools"},
{c:444130,d: "Hardware stores"},
{c:444130,d: "Tool stores, power and hand (except outdoor)"},
{c:444190,d: "Building materials supply dealers"},
{c:444190,d: "Cabinet stores, kitchen (except custom), to be installed"},
{c:444190,d: "Ceiling fan stores"},
{c:444190,d: "Ceramic tile stores"},
{c:444190,d: "Door stores"},
{c:444190,d: "Electrical supply stores"},
{c:444190,d: "Fencing dealers"},
{c:444190,d: "Floor covering stores, wood or ceramic tile only"},
{c:444190,d: "Garage door dealers"},
{c:444190,d: "Glass stores"},
{c:444190,d: "Hardwood flooring dealers"},
{c:444190,d: "Kitchen cabinet (except custom) stores"},
{c:444190,d: "Lighting fixture stores"},
{c:444190,d: "Lumber retailing yards"},
{c:444190,d: "Masonry (e.g., block, brick, stone) dealers"},
{c:444190,d: "Plumbing supply stores"},
{c:444190,d: "Prefabricated building dealers"},
{c:444190,d: "Roofing material dealers"},
{c:444190,d: "Siding dealers"},
{c:444190,d: "Tile stores, ceramic"},
{c:444190,d: "Window stores"},
{c:444210,d: "Garden power equipment stores"},
{c:444210,d: "Lawn power equipment stores"},
{c:444210,d: "Power equipment stores, outdoor"},
{c:444220,d: "Farm supply stores"},
{c:444220,d: "Feed stores (except pet)"},
{c:444220,d: "Garden centers"},
{c:444220,d: "Lawn supply stores"},
{c:444220,d: "Nurseries, retail, stock primarily grown off premises"},
{c:444220,d: "Nursery and garden centers without tree production"},
{c:445110,d: "Commissaries, primarily groceries"},
{c:445110,d: "Delicatessens primarily retailing a range of grocery items and meats"},
{c:445110,d: "Food (i.e., groceries) stores"},
{c:445110,d: "Grocery stores"},
{c:445110,d: "Supermarkets"},
{c:445120,d: "Convenience food stores"},
{c:445210,d: "Baked ham stores"},
{c:445210,d: "Butcher shops"},
{c:445210,d: "Delicatessens (except grocery store, restaurants)"},
{c:445210,d: "Frozen meat stores"},
{c:445210,d: "Meat markets"},
{c:445210,d: "Poultry dealers"},
{c:445220,d: "Fish markets"},
{c:445220,d: "Seafood markets"},
{c:445230,d: "Fruit and vegetable stands, permanent"},
{c:445230,d: "Fruit markets"},
{c:445230,d: "Fruit stands, permanent"},
{c:445230,d: "Produce markets"},
{c:445230,d: "Produce stands, permanent"},
{c:445230,d: "Vegetable markets"},
{c:445291,d: "Baked goods stores, retailing only (except immediate consumption)"},
{c:445292,d: "Candy stores, packaged, retailing only"},
{c:445292,d: "Confectionery stores, packaged, retailing only"},
{c:445292,d: "Nut (i.e., packaged) stores"},
{c:445299,d: "Coffee and tea (i.e., packaged) stores"},
{c:445299,d: "Dairy product stores"},
{c:445299,d: "Fix-and-freeze meal stores"},
{c:445299,d: "Gourmet food stores"},
{c:445299,d: "Ice cream (i.e., packaged) stores"},
{c:445299,d: "Soft drink stores, bottled"},
{c:445299,d: "Specialty food stores"},
{c:445299,d: "Spice stores"},
{c:445299,d: "Take-and-bake meal stores"},
{c:445299,d: "Take-and-bake pizza shops"},
{c:445299,d: "Tea and coffee (i.e., packaged) stores"},
{c:445299,d: "Water stores, bottled"},
{c:445310,d: "Beer stores, packaged"},
{c:445310,d: "Duty free liquor shops"},
{c:445310,d: "Liquor stores, package"},
{c:445310,d: "Package stores (i.e., liquor)"},
{c:445310,d: "Wine shops, packaged"},
{c:446110,d: "Apothecaries"},
{c:446110,d: "Drug stores"},
{c:446110,d: "Institutional pharmacies, on-site"},
{c:446110,d: "Pharmacies"},
{c:446120,d: "Beauty supply stores"},
{c:446120,d: "Cosmetics stores"},
{c:446120,d: "Perfume stores"},
{c:446130,d: "Lens grinding, ophthalmic, in retail stores"},
{c:446130,d: "Optical goods stores (except offices of optometrists)"},
{c:446130,d: "Sunglass stores"},
{c:446191,d: "Food (i.e., health) supplement stores"},
{c:446191,d: "Herbal supplement stores"},
{c:446191,d: "Nutrition (i.e., food supplement) stores"},
{c:446191,d: "Vitamin stores"},
{c:446199,d: "Convalescent supply stores"},
{c:446199,d: "Hearing aid stores"},
{c:446199,d: "Medical equipment and supplies stores"},
{c:446199,d: "Personal mobility scooter dealers"},
{c:446199,d: "Prosthetic stores"},
{c:446199,d: "Sick room supply stores"},
{c:447110,d: "Convenience food with gasoline stations"},
{c:447110,d: "Gasoline stations with convenience stores"},
{c:447110,d: "Gasoline with convenience stores"},
{c:447190,d: "Gasoline stations without convenience stores"},
{c:447190,d: "Marine service stations"},
{c:447190,d: "Service stations, gasoline"},
{c:447190,d: "Truck stops"},
{c:448110,d: "Apparel stores, men's and boys' clothing"},
{c:448110,d: "Clothing stores, men's and boys'"},
{c:448120,d: "Apparel stores, women's and girls' clothing"},
{c:448120,d: "Clothing stores, women's and girls'"},
{c:448120,d: "Maternity shops"},
{c:448130,d: "Apparel stores, children's and infants' clothing"},
{c:448130,d: "Baby clothing shops"},
{c:448130,d: "Clothing stores, children's and infants'"},
{c:448140,d: "Clothing stores, family"},
{c:448140,d: "Family clothing stores"},
{c:448140,d: "Unisex clothing stores"},
{c:448140,d: "Western wear stores"},
{c:448150,d: "Apparel accessory stores"},
{c:448150,d: "Clothing accessories stores"},
{c:448150,d: "Costume jewelry stores"},
{c:448150,d: "Furnishings stores, men's and boys'"},
{c:448150,d: "Furnishings stores, women's and girls'"},
{c:448150,d: "Handbag stores"},
{c:448150,d: "Hat and cap stores"},
{c:448150,d: "Jewelry stores, costume"},
{c:448150,d: "Neckwear stores"},
{c:448150,d: "Tie shops"},
{c:448150,d: "Wig and hairpiece stores"},
{c:448190,d: "Bridal gown shops (except custom)"},
{c:448190,d: "Coat stores"},
{c:448190,d: "Costume stores (including theatrical)"},
{c:448190,d: "Dress shops"},
{c:448190,d: "Fur apparel stores"},
{c:448190,d: "Furriers"},
{c:448190,d: "Hosiery stores"},
{c:448190,d: "Leather coat stores"},
{c:448190,d: "Lingerie stores"},
{c:448190,d: "School uniform stores"},
{c:448190,d: "Sock shops"},
{c:448190,d: "Sports apparel stores (except uniforms)"},
{c:448190,d: "Swimwear stores"},
{c:448190,d: "T-shirt shops"},
{c:448190,d: "Uniform stores (except athletic)"},
{c:448210,d: "Athletic shoe (except bowling, golf, spiked) stores"},
{c:448210,d: "Children's shoe stores"},
{c:448210,d: "Orthopedic shoes stores"},
{c:448210,d: "Shoe (except bowling, golf, spiked) stores"},
{c:448210,d: "Shoe stores, orthopedic"},
{c:448310,d: "Clock shops"},
{c:448310,d: "Gem stone shops, precious and semi-precious"},
{c:448310,d: "Jewelry stores, precious"},
{c:448310,d: "Watch shops"},
{c:448320,d: "Leather goods stores"},
{c:448320,d: "Luggage stores"},
{c:451110,d: "Athletic equipment and supply stores (including uniforms)"},
{c:451110,d: "Bicycle (except motorized) shops"},
{c:451110,d: "Bowling equipment and supply stores"},
{c:451110,d: "Diving equipment stores"},
{c:451110,d: "Exercise equipment stores"},
{c:451110,d: "Fishing supply stores (e.g., bait)"},
{c:451110,d: "Footwear (e.g., bowling, golf, spiked), specialty sports, stores"},
{c:451110,d: "Golf pro shops"},
{c:451110,d: "Gun shops"},
{c:451110,d: "Outdoor sporting equipment stores"},
{c:451110,d: "Pro shops (e.g., golf, skiing, tennis)"},
{c:451110,d: "Saddlery stores"},
{c:451110,d: "Shoe stores, specialty sports footwear (e.g., bowling, golf, spiked)"},
{c:451110,d: "Sporting goods stores"},
{c:451110,d: "Sports gear stores (e.g., outdoors, scuba, skiing)"},
{c:451110,d: "Tack (e.g., harnesses, saddlery) shops"},
{c:451110,d: "Tackle shops (i.e., fishing)"},
{c:451110,d: "Uniform stores, athletic"},
{c:451120,d: "Craft supply stores (except needlecraft)"},
{c:451120,d: "Hobby shops"},
{c:451120,d: "Magic supply stores"},
{c:451120,d: "Pottery (unfinished pottery to be painted by customer on premises) stores"},
{c:451120,d: "Toy stores"},
{c:451120,d: "Toy stores, electronic"},
{c:451130,d: "Fabric shops"},
{c:451130,d: "Needlecraft sewing supply stores"},
{c:451130,d: "Piece goods stores"},
{c:451130,d: "Sewing supply stores"},
{c:451130,d: "Upholstery materials stores"},
{c:451140,d: "Music stores (i.e., instrument)"},
{c:451140,d: "Musical instrument stores"},
{c:451140,d: "Piano stores"},
{c:451140,d: "Sheet music stores"},
{c:451211,d: "Book stores"},
{c:451211,d: "Religious book stores"},
{c:451212,d: "Comic book stores"},
{c:451212,d: "Magazine stands (i.e., permanent)"},
{c:451212,d: "News dealers"},
{c:451212,d: "Newsstands (i.e., permanent)"},
{c:452210,d: "Department stores"},
{c:452311,d: "Supercenters"},
{c:452311,d: "Superstores (i.e., food and general merchandise)"},
{c:452311,d: "Warehouse clubs (i.e., food and general merchandise)"},
{c:452319,d: "Catalog showrooms, general merchandise (except catalog mail-order)"},
{c:452319,d: "Dollar stores"},
{c:452319,d: "General stores"},
{c:452319,d: "Home and auto supply stores"},
{c:452319,d: "Limited price variety stores"},
{c:452319,d: "Trading posts, general merchandise"},
{c:452319,d: "Variety stores"},
{c:453110,d: "Florists"},
{c:453110,d: "Flower shops, fresh"},
{c:453210,d: "Office supply stores"},
{c:453210,d: "School supply stores"},
{c:453210,d: "Stationery stores"},
{c:453220,d: "Balloon shops"},
{c:453220,d: "Card shops, greeting"},
{c:453220,d: "Christmas stores"},
{c:453220,d: "Collectible gift shops (e.g., crystal, pewter, porcelain)"},
{c:453220,d: "Craft (except craft supply) stores"},
{c:453220,d: "Curio shops"},
{c:453220,d: "Fruit basket or fruit bouquet stores (except exclusively by Internet)"},
{c:453220,d: "Gift shops"},
{c:453220,d: "Gift stands, permanent location"},
{c:453220,d: "Greeting card shops"},
{c:453220,d: "Novelty shops"},
{c:453220,d: "Party goods (e.g., paper supplies, decorations, novelties) stores"},
{c:453220,d: "Seasonal and holiday decoration stores"},
{c:453220,d: "Souvenir shops"},
{c:453310,d: "Antique dealers (except motor vehicles)"},
{c:453310,d: "Antique shops"},
{c:453310,d: "Apparel stores, used clothing"},
{c:453310,d: "Appliance stores, household-type, used"},
{c:453310,d: "Architectural salvage dealers"},
{c:453310,d: "Bicycle (except motorized) shops, used"},
{c:453310,d: "Book stores, used"},
{c:453310,d: "Clothing stores, used"},
{c:453310,d: "Consignment shops, used merchandise"},
{c:453310,d: "Flea markets, used merchandise, permanent"},
{c:453310,d: "Furniture stores, used"},
{c:453310,d: "Music stores (e.g., cassette, instrument, record, tape), used"},
{c:453310,d: "Rare manuscript stores"},
{c:453310,d: "Record stores, used"},
{c:453310,d: "Secondhand merchandise stores"},
{c:453310,d: "Sporting goods stores, used"},
{c:453310,d: "Thrift shops, used merchandise"},
{c:453310,d: "Used bicycle (except motorized) shops"},
{c:453310,d: "Used merchandise dealers (except motor vehicles and parts)"},
{c:453310,d: "Used merchandise stores"},
{c:453310,d: "Used rare collectors' items (e.g., autograph, coin, card, stamps) shops"},
{c:453910,d: "Feed stores, pet"},
{c:453910,d: "Pet shops"},
{c:453910,d: "Pet supply stores"},
{c:453920,d: "Art auctions"},
{c:453920,d: "Art dealers"},
{c:453920,d: "Art galleries retailing art"},
{c:453920,d: "Galleries, art, retail"},
{c:453930,d: "Manufactured (mobile) home dealers"},
{c:453930,d: "Mobile home dealers, manufactured"},
{c:453930,d: "Used manufactured (mobile) home dealers"},
{c:453991,d: "Cigar stores"},
{c:453991,d: "Cigarette stands, permanent"},
{c:453991,d: "Smokers' supply stores"},
{c:453991,d: "Tobacco stores"},
{c:453998,d: "Architectural supply stores"},
{c:453998,d: "Art supply stores"},
{c:453998,d: "Auction houses (general merchandise)"},
{c:453998,d: "Batteries, except automotive, dealers"},
{c:453998,d: "Beer making supply stores"},
{c:453998,d: "Binocular stores"},
{c:453998,d: "Cake decorating supply stores"},
{c:453998,d: "Calendar shops"},
{c:453998,d: "Candle shops"},
{c:453998,d: "Cemetery memorial dealers (e.g., headstones, markers, vaults)"},
{c:453998,d: "Closet organizer stores"},
{c:453998,d: "Collectors' items (e.g., autograph, card, coin, stamp) shops (except used rare items)"},
{c:453998,d: "Electronic cigarette stores"},
{c:453998,d: "Emergency preparedness supply stores"},
{c:453998,d: "Fireworks shops (i.e., permanent location)"},
{c:453998,d: "Flag and banner shops"},
{c:453998,d: "Flower shops, artificial or dried"},
{c:453998,d: "Home security equipment stores"},
{c:453998,d: "Hot tub stores"},
{c:453998,d: "Janitorial equipment and supplies stores"},
{c:453998,d: "Marijuana stores, medical or recreational"},
{c:453998,d: "Monument (i.e., burial marker) dealers"},
{c:453998,d: "Police supply stores"},
{c:453998,d: "Religious goods (except books) stores"},
{c:453998,d: "Swimming pool supply stores"},
{c:453998,d: "Trophy (including awards and plaques) shops"},
{c:453998,d: "Wine making supply stores"},
{c:454110,d: "Auctions, Internet retail"},
{c:454110,d: "Audio and video content downloading retail sales sites"},
{c:454110,d: "Book clubs, not publishing, mail-order"},
{c:454110,d: "Business to Consumer retail sales Internet sites"},
{c:454110,d: "Catalog (i.e., order-taking) offices of mail-order houses"},
{c:454110,d: "Collectors' items, mail-order houses"},
{c:454110,d: "Computer software, mail-order houses"},
{c:454110,d: "Direct mailers (i.e., selling own merchandise)"},
{c:454110,d: "Electronic auctions, retail"},
{c:454110,d: "E-tailers"},
{c:454110,d: "Institutional pharmacies, off-site, exclusively on Internet"},
{c:454110,d: "Institutional pharmacies, off-site, mail-order"},
{c:454110,d: "Internet auctions, retail"},
{c:454110,d: "Internet retail sales sites"},
{c:454110,d: "Mail-order houses"},
{c:454110,d: "Order-taking offices of mail-order houses"},
{c:454110,d: "Prerecorded tape, compact disc, and record mail-order houses"},
{c:454110,d: "Television order, home shopping"},
{c:454110,d: "Web retailers"},
{c:454210,d: "Automatic merchandising machine operators"},
{c:454210,d: "Vending machine merchandisers, sale of products"},
{c:454310,d: "Alternative fuels, direct selling"},
{c:454310,d: "Bottled gas dealers, direct selling"},
{c:454310,d: "Coal dealers, direct selling"},
{c:454310,d: "Firewood dealers, direct selling"},
{c:454310,d: "Fuel oil (i.e., heating) dealers, direct selling"},
{c:454310,d: "Heating oil dealers, direct selling"},
{c:454310,d: "Jet fuel bulk stations, selling for consumption"},
{c:454310,d: "Liquefied petroleum gas (LPG) dealers, direct selling"},
{c:454390,d: "Bazaars (i.e., temporary stands)"},
{c:454390,d: "Bottled water providers, direct selling"},
{c:454390,d: "Canvassers (door-to-door), headquarters for retail sale of merchandise, direct selling"},
{c:454390,d: "Christmas trees, cut, direct selling"},
{c:454390,d: "Cigarette stands, temporary"},
{c:454390,d: "Coffee-break supplies providers, direct selling"},
{c:454390,d: "Direct selling of merchandise (door-to-door)"},
{c:454390,d: "Door-to-door retailing of merchandise, direct selling"},
{c:454390,d: "Flea markets, temporary location, direct selling"},
{c:454390,d: "Frozen food and freezer meal plan providers, direct selling"},
{c:454390,d: "Fruit stands, temporary"},
{c:454390,d: "Home delivery newspaper routes, direct selling"},
{c:454390,d: "House-to-house direct selling"},
{c:454390,d: "In-home sales of merchandise, direct selling"},
{c:454390,d: "Locker meat provisioners, direct selling"},
{c:454390,d: "Party plan merchandisers, direct selling"},
{c:454390,d: "Produce stands, temporary"},
{c:454390,d: "Street vendors (except food)"},
{c:454390,d: "Water softener service providers, direct selling"},
{c:481111,d: "Air commuter carriers, scheduled"},
{c:481111,d: "Air passenger carriers, scheduled"},
{c:481111,d: "Commuter air carriers, scheduled"},
{c:481111,d: "Helicopter passenger carriers, scheduled"},
{c:481111,d: "Passenger air transportation, scheduled"},
{c:481111,d: "Passenger carriers, air, scheduled"},
{c:481111,d: "Scheduled air passenger carriers"},
{c:481111,d: "Scheduled air passenger transportation"},
{c:481112,d: "Air cargo carriers (except air couriers), scheduled"},
{c:481112,d: "Airmail carriers, scheduled"},
{c:481112,d: "Cargo carriers, air, scheduled"},
{c:481112,d: "Freight carriers (except air couriers), air, scheduled"},
{c:481112,d: "Helicopter freight carriers, scheduled"},
{c:481112,d: "Scheduled air freight carriers"},
{c:481112,d: "Scheduled air freight transportation"},
{c:481211,d: "Air passenger carriers, nonscheduled"},
{c:481211,d: "Air taxi services"},
{c:481211,d: "Aircraft charter services, passenger"},
{c:481211,d: "Charter air passenger services"},
{c:481211,d: "Helicopter passenger carriers (except scenic, sightseeing), nonscheduled"},
{c:481211,d: "Nonscheduled air passenger transportation"},
{c:481211,d: "Passenger air transportation, nonscheduled"},
{c:481211,d: "Passenger carriers, air, nonscheduled"},
{c:481212,d: "Air cargo carriers (except air couriers), nonscheduled"},
{c:481212,d: "Charter air freight services"},
{c:481212,d: "Freight charter services, air"},
{c:481212,d: "Freight transportation, air, charter services"},
{c:481212,d: "Freight transportation, air, nonscheduled"},
{c:481212,d: "Helicopter carriers, freight, nonscheduled"},
{c:481212,d: "Nonscheduled air freight transportation"},
{c:481212,d: "Space transportation, freight, nonscheduled"},
{c:481212,d: "Transportation by spacecraft, freight"},
{c:481219,d: "Aircraft charter services (i.e., general purpose aircraft used for a variety of specialty air and flying services)"},
{c:481219,d: "Aviation clubs providing a variety of air transportation activities to the general public"},
{c:482111,d: "Freight railways, line-haul"},
{c:482111,d: "Passenger railways, line-haul"},
{c:482111,d: "Railroad transportation, line-haul"},
{c:482111,d: "Railroads, line-haul"},
{c:482111,d: "Railway transportation, line-haul"},
{c:482112,d: "Beltline railroads"},
{c:482112,d: "Freight railways, short-line or beltline"},
{c:482112,d: "Logging railroads"},
{c:482112,d: "Railroad transportation, short-line or beltline"},
{c:482112,d: "Railroads, short-line or beltline"},
{c:482112,d: "Railway transportation, short-line or beltline"},
{c:482112,d: "Short-line railroads"},
{c:483111,d: "Deep sea freight transportation to or from foreign ports"},
{c:483111,d: "Freight transportation, deep sea, to or from foreign ports"},
{c:483111,d: "Ship chartering with crew, deep sea freight transportation to or from foreign ports"},
{c:483111,d: "Shipping freight to or from foreign ports, deep sea"},
{c:483111,d: "Transporting freight to or from foreign ports, deep sea"},
{c:483112,d: "Cruise lines (i.e., deep sea passenger transportation to or from foreign ports)"},
{c:483112,d: "Deep sea passenger transportation to or from foreign ports"},
{c:483112,d: "Passenger transportation, deep sea, to or from foreign ports"},
{c:483112,d: "Ship chartering with crew, deep sea passenger transportation to or from foreign ports"},
{c:483112,d: "Transporting passengers to or from foreign ports, deep sea"},
{c:483113,d: "Barge transportation, coastal or Great Lakes (including St. Lawrence Seaway)"},
{c:483113,d: "Coastal freight transportation to and from domestic ports"},
{c:483113,d: "Coastal shipping of freight to and from domestic ports"},
{c:483113,d: "Deep sea freight transportation to or from domestic ports (including Puerto Rico)"},
{c:483113,d: "Freight shipping on the Great Lakes system (including St. Lawrence Seaway)"},
{c:483113,d: "Freight transportation, deep sea, to and from domestic ports"},
{c:483113,d: "Great Lakes freight transportation (including St. Lawrence Seaway)"},
{c:483113,d: "Intercoastal freight transportation to and from domestic ports"},
{c:483113,d: "Lake freight transportation, Great Lakes (including St. Lawrence Seaway)"},
{c:483113,d: "Ship chartering with crew, coastal or Great Lakes freight transportation (including St. Lawrence Seaway)"},
{c:483113,d: "Shipping freight to and from domestic ports (i.e., coastal, deep sea (including Puerto Rico), Great Lakes system (including St. Lawrence Seaway))"},
{c:483114,d: "Coastal passenger transportation to and from domestic ports"},
{c:483114,d: "Cruise lines (i.e., deep sea passenger transportation to and from domestic ports, including Puerto Rico)"},
{c:483114,d: "Deep sea passenger transportation to and from domestic ports (including Puerto Rico)"},
{c:483114,d: "Ferry passenger transportation, Great Lakes (including St. Lawrence Seaway)"},
{c:483114,d: "Great Lakes passenger transportation (including St. Lawrence Seaway)"},
{c:483114,d: "Intercoastal transportation of passengers to and from domestic ports"},
{c:483114,d: "Lake passenger transportation, Great Lakes (including St. Lawrence Seaway)"},
{c:483114,d: "Passenger transportation, coastal or Great Lakes (including St. Lawrence Seaway)"},
{c:483114,d: "Passenger transportation, deep sea, to and from domestic ports (including Puerto Rico)"},
{c:483114,d: "Ship chartering with crew, coastal or Great Lakes passenger transportation (including St. Lawrence Seaway)"},
{c:483211,d: "Barge transportation, canal (freight)"},
{c:483211,d: "Canal barge transportation (freight)"},
{c:483211,d: "Freight transportation, inland waters (except on Great Lakes system)"},
{c:483211,d: "Intracoastal transportation of freight"},
{c:483211,d: "Lake freight transportation (except on Great Lakes system)"},
{c:483211,d: "Lighterage (i.e., freight transportation except vessel supply services)"},
{c:483211,d: "River freight transportation"},
{c:483211,d: "Ship chartering with crew, freight transportation, inland waters (except on Great Lakes system)"},
{c:483211,d: "Shipping freight, inland waters (except on Great Lakes system)"},
{c:483211,d: "Towing service, inland waters (except on Great Lakes system)"},
{c:483212,d: "Canal passenger transportation"},
{c:483212,d: "Car lighters (i.e., ferries), inland waters (except on Great Lakes system)"},
{c:483212,d: "Intracoastal transportation of passengers"},
{c:483212,d: "Lake passenger transportation (except on Great Lakes system)"},
{c:483212,d: "Passenger transportation, inland waters (except on Great Lakes system)"},
{c:483212,d: "River passenger transportation"},
{c:483212,d: "Ship chartering with crew, passenger transportation, inland waters (except on Great Lakes system)"},
{c:483212,d: "Water shuttle services"},
{c:483212,d: "Water taxi services"},
{c:484110,d: "Bulk mail truck transportation, contract, local"},
{c:484110,d: "Container trucking services, local"},
{c:484110,d: "General freight trucking, local"},
{c:484110,d: "Motor freight carrier, general, local"},
{c:484110,d: "Transfer (trucking) services, general freight, local"},
{c:484110,d: "Trucking, general freight, local"},
{c:484121,d: "Bulk mail truck transportation, contract, long-distance (TL)"},
{c:484121,d: "Container trucking services, long-distance (TL)"},
{c:484121,d: "General freight trucking, long-distance, truckload (TL)"},
{c:484121,d: "Motor freight carrier, general, long-distance, truckload (TL)"},
{c:484121,d: "Trucking, general freight, long-distance, truckload (TL)"},
{c:484122,d: "General freight trucking, long-distance, less-than-truckload (LTL)"},
{c:484122,d: "LTL (less-than-truckload) long-distance freight trucking"},
{c:484122,d: "Motor freight carrier, general, long-distance, less-than-truckload (LTL)"},
{c:484122,d: "Trucking, general freight, long-distance, less-than-truckload (LTL)"},
{c:484210,d: "Furniture moving, used"},
{c:484210,d: "Motor freight carrier, used household goods"},
{c:484210,d: "Trucking used household, office, or institutional furniture and equipment"},
{c:484210,d: "Used household and office goods moving"},
{c:484210,d: "Van lines, moving and storage services"},
{c:484220,d: "Agricultural products trucking, local"},
{c:484220,d: "Ash, garbage, recyclable material, refuse, rubbish, trash, or waste hauling (except collection or disposal)"},
{c:484220,d: "Automobile carrier trucking, local"},
{c:484220,d: "Boat hauling, truck, local"},
{c:484220,d: "Bulk liquids trucking, local"},
{c:484220,d: "Coal hauling, truck, local"},
{c:484220,d: "Dry bulk trucking (except garbage collection, garbage hauling), local"},
{c:484220,d: "Dump trucking (e.g., gravel, sand, top-soil)"},
{c:484220,d: "Farm products hauling, local"},
{c:484220,d: "Flatbed trucking, local"},
{c:484220,d: "Grain hauling, local"},
{c:484220,d: "Gravel hauling, local"},
{c:484220,d: "Livestock trucking, local"},
{c:484220,d: "Log hauling, local"},
{c:484220,d: "Milk hauling, local"},
{c:484220,d: "Mobile home towing services, local"},
{c:484220,d: "Oil and gas field equipment trucking, local"},
{c:484220,d: "Refrigerated products trucking, local"},
{c:484220,d: "Sand hauling, local"},
{c:484220,d: "Tanker trucking (e.g., chemical, juice, milk, petroleum), local"},
{c:484220,d: "Top-soil hauling, local"},
{c:484220,d: "Tracked vehicle freight transportation, local"},
{c:484220,d: "Trucking, specialized freight (except used goods), local"},
{c:484230,d: "Automobile carrier trucking, long-distance"},
{c:484230,d: "Boat hauling, truck, long-distance"},
{c:484230,d: "Bulk liquids trucking, long-distance"},
{c:484230,d: "Dry bulk carrier, truck, long-distance"},
{c:484230,d: "Farm products trucking, long-distance"},
{c:484230,d: "Flatbed trucking, long-distance"},
{c:484230,d: "Forest products trucking, long-distance"},
{c:484230,d: "Grain hauling, long-distance"},
{c:484230,d: "Gravel hauling, long-distance"},
{c:484230,d: "Livestock trucking, long-distance"},
{c:484230,d: "Log hauling, long-distance"},
{c:484230,d: "Mobile home towing services, long-distance"},
{c:484230,d: "Oil and gas field equipment trucking, long-distance"},
{c:484230,d: "Radioactive waste hauling, long-distance"},
{c:484230,d: "Recyclable material hauling, long-distance"},
{c:484230,d: "Refrigerated products trucking, long-distance"},
{c:484230,d: "Refuse hauling, long-distance"},
{c:484230,d: "Rubbish hauling without collection or disposal, truck, long-distance"},
{c:484230,d: "Sand hauling, long-distance"},
{c:484230,d: "Tanker trucking (e.g., chemical, juice, milk, petroleum), long-distance"},
{c:484230,d: "Tracked vehicle freight transportation, long-distance"},
{c:484230,d: "Trash hauling, long-distance"},
{c:484230,d: "Trucking, specialized freight (except used goods), long-distance"},
{c:484230,d: "Waste hauling, hazardous, long-distance"},
{c:484230,d: "Waste hauling, nonhazardous, long-distance"},
{c:485111,d: "Commuter transit systems, mixed mode (e.g., bus, commuter rail, subway combination)"},
{c:485111,d: "Local transit systems, mixed mode (e.g., bus, commuter rail, subway combinations)"},
{c:485111,d: "Mixed mode transit systems (e.g., bus, commuter rail, subway combinations)"},
{c:485111,d: "Suburban transit systems, mixed mode (e.g., bus, commuter rail, subway combinations)"},
{c:485111,d: "Transit systems, mixed mode (e.g., bus, commuter rail, subway combinations)"},
{c:485111,d: "Urban transit systems, mixed mode (e.g., bus, commuter rail, subway combinations)"},
{c:485112,d: "Commuter rail systems (except mixed mode)"},
{c:485112,d: "Local passenger rail systems (except mixed mode)"},
{c:485112,d: "Rail transportation (except mixed mode), commuter"},
{c:485112,d: "Railway systems (except mixed mode), commuter"},
{c:485112,d: "Suburban commuter rail systems (except mixed mode)"},
{c:485112,d: "Urban commuter rail systems (except mixed mode)"},
{c:485113,d: "Bus line, local (except mixed mode)"},
{c:485113,d: "Bus services, urban and suburban (except mixed mode)"},
{c:485113,d: "Bus transit systems (except mixed mode)"},
{c:485113,d: "City bus services (except mixed mode)"},
{c:485113,d: "Commuter bus operation (except mixed mode)"},
{c:485113,d: "Local bus services (except mixed mode)"},
{c:485113,d: "Suburban bus line services (except mixed mode)"},
{c:485113,d: "Urban bus line services (except mixed mode)"},
{c:485119,d: "Cable car systems (except mixed mode), commuter"},
{c:485119,d: "Light rail systems (except mixed mode), commuter"},
{c:485119,d: "Monorail transit systems (except mixed mode), commuter"},
{c:485119,d: "Street railway systems (except mixed mode), commuter"},
{c:485119,d: "Streetcar systems (except mixed mode), commuter"},
{c:485119,d: "Subway systems (except mixed mode), commuter"},
{c:485119,d: "Tramway systems (except mixed mode), commuter"},
{c:485119,d: "Trolley systems (except mixed mode), commuter"},
{c:485210,d: "Bus line operation, intercity"},
{c:485210,d: "Intercity bus line operation"},
{c:485210,d: "Interstate bus line operation"},
{c:485210,d: "Interurban bus line operation"},
{c:485210,d: "Motor coach operation, interurban and rural"},
{c:485210,d: "Rural bus services"},
{c:485310,d: "Cab (i.e., taxi) services"},
{c:485310,d: "Taxicab dispatch services"},
{c:485310,d: "Taxicab fleet operators"},
{c:485310,d: "Taxicab organizations"},
{c:485310,d: "Taxicab owner-operators"},
{c:485310,d: "Taxicab services"},
{c:485320,d: "Automobile rental with driver (except shuttle service, taxis)"},
{c:485320,d: "Hearse rental with driver"},
{c:485320,d: "Limousine services (except shuttle services)"},
{c:485320,d: "Limousines for hire with driver (except taxis)"},
{c:485320,d: "Luxury automobiles for hire with driver (except taxis)"},
{c:485320,d: "Passenger limousine rental with driver (except shuttle service, taxi)"},
{c:485320,d: "Passenger van rental with driver (except shuttle service, taxi)"},
{c:485410,d: "Bus operation, school and employee"},
{c:485410,d: "Employee bus services"},
{c:485410,d: "School bus services"},
{c:485510,d: "Bus charter services (except scenic, sightseeing)"},
{c:485510,d: "Charter bus services (except scenic, sightseeing)"},
{c:485991,d: "Handicapped passenger transportation services"},
{c:485991,d: "Paratransit transportation services"},
{c:485991,d: "Pet transportation services"},
{c:485991,d: "Senior citizens transportation services"},
{c:485991,d: "Special needs passenger transportation services"},
{c:485999,d: "Airport limousine services (i.e., shuttle)"},
{c:485999,d: "Airport shuttle services"},
{c:485999,d: "Car pool operation"},
{c:485999,d: "Shuttle services (except employee bus)"},
{c:485999,d: "Vanpool operation"},
{c:486110,d: "Booster pumping station, crude oil transportation"},
{c:486110,d: "Crude oil pipeline transportation"},
{c:486110,d: "Petroleum pipelines, crude"},
{c:486110,d: "Pipeline transportation, crude oil"},
{c:486210,d: "Booster pumping station, natural gas transportation"},
{c:486210,d: "Gas, natural, pipeline operation"},
{c:486210,d: "Natural gas pipeline transportation"},
{c:486210,d: "Natural gas transmission (i.e., processing plants to local distribution systems)"},
{c:486210,d: "Pipeline transportation, natural gas"},
{c:486210,d: "Storage of natural gas"},
{c:486210,d: "Transmission of natural gas via pipeline (i.e., processing plants to local distribution systems)"},
{c:486910,d: "Booster pumping station, refined petroleum products transportation"},
{c:486910,d: "Gasoline pipeline transportation"},
{c:486910,d: "Natural gas liquids pipeline transportation"},
{c:486910,d: "Petroleum pipelines, refined"},
{c:486910,d: "Pipeline transportation, gasoline and other refined petroleum products"},
{c:486910,d: "Refined petroleum products pipeline transportation"},
{c:486990,d: "Booster pumping station (except natural gas, petroleum)"},
{c:486990,d: "Coal pipeline transportation"},
{c:486990,d: "Pipeline transportation (except crude oil, natural gas, refined petroleum products)"},
{c:486990,d: "Slurry pipeline transportation"},
{c:487110,d: "Buses, scenic and sightseeing operation"},
{c:487110,d: "Cable car, land, scenic and sightseeing operation"},
{c:487110,d: "Carriage, horse-drawn, operation"},
{c:487110,d: "Cog railway, scenic and sightseeing, operation"},
{c:487110,d: "Horse-drawn carriage operation"},
{c:487110,d: "Monorail, scenic and sightseeing, operation"},
{c:487110,d: "Railroad transportation, scenic and sightseeing"},
{c:487110,d: "Railroad, scenic and sightseeing, operation"},
{c:487110,d: "Railway transportation, scenic and sightseeing"},
{c:487110,d: "Scenic and sightseeing excursions, land"},
{c:487110,d: "Sightseeing bus operation"},
{c:487110,d: "Sightseeing operation, human-drawn vehicle"},
{c:487110,d: "Steam train excursions"},
{c:487110,d: "Tour bus, scenic and sightseeing, operation"},
{c:487110,d: "Tracked vehicle sightseeing operation"},
{c:487110,d: "Trolley, scenic and sightseeing, operation"},
{c:487210,d: "Airboat (i.e., swamp buggy) operation"},
{c:487210,d: "Boat, fishing charter, operation"},
{c:487210,d: "Charter fishing boat operation"},
{c:487210,d: "Dinner cruises"},
{c:487210,d: "Excursion boat operation"},
{c:487210,d: "Fishing boat charter operation"},
{c:487210,d: "Harbor sightseeing tours"},
{c:487210,d: "Hovercraft sightseeing operation"},
{c:487210,d: "Scenic and sightseeing excursions, water"},
{c:487210,d: "Sightseeing boat operation"},
{c:487210,d: "Swamp buggy operation"},
{c:487210,d: "Whale watching excursions"},
{c:487990,d: "Aerial cable car, scenic and sightseeing, operation"},
{c:487990,d: "Aerial tramway, scenic and sightseeing, operation"},
{c:487990,d: "Glider excursions"},
{c:487990,d: "Helicopter ride, scenic and sightseeing, operation"},
{c:487990,d: "Hot air balloon ride, scenic and sightseeing, operation"},
{c:487990,d: "Scenic and sightseeing excursions, aerial"},
{c:487990,d: "Tramway, aerial, scenic and sightseeing operation"},
{c:488111,d: "Air traffic control services (except military)"},
{c:488119,d: "Aircraft hangar rental"},
{c:488119,d: "Aircraft parking service"},
{c:488119,d: "Airport baggage handling services"},
{c:488119,d: "Airport cargo handling services"},
{c:488119,d: "Airport operators (e.g., civil, international, national)"},
{c:488119,d: "Airport runway maintenance services"},
{c:488119,d: "Airports, civil, operation and maintenance"},
{c:488119,d: "Aviation clubs, primarily providing flying field services to the general public"},
{c:488119,d: "Fixed base operators"},
{c:488119,d: "Flying field operators"},
{c:488119,d: "Hangar rental, aircraft"},
{c:488119,d: "Maintenance services, runway"},
{c:488119,d: "Parking services, aircraft"},
{c:488119,d: "Runway maintenance services"},
{c:488119,d: "Vacuuming of airport runways"},
{c:488190,d: "Aircraft ferrying services"},
{c:488190,d: "Aircraft inspection services"},
{c:488190,d: "Aircraft maintenance and repair services (except factory conversion, factory overhaul, factory rebuilding)"},
{c:488190,d: "Aircraft testing services"},
{c:488190,d: "Airport passenger screening security services"},
{c:488190,d: "Fueling aircraft on a contract or fee basis"},
{c:488190,d: "Independent pilot, air (except owner-operators)"},
{c:488190,d: "Inspection services, aircraft"},
{c:488190,d: "Maintenance and repair services, aircraft (except factory conversion, factory overhaul, factory rebuilding)"},
{c:488190,d: "Testing services, aircraft"},
{c:488210,d: "Freight car cleaning services"},
{c:488210,d: "Grain leveling and trimming in railroad cars"},
{c:488210,d: "Loading and unloading services at rail terminals"},
{c:488210,d: "Locomotive and rail car repair (except factory conversion, factory overhaul, factory rebuilding)"},
{c:488210,d: "Maintenance of rights of way and structures, railway"},
{c:488210,d: "Railroad switching services"},
{c:488210,d: "Railroad terminals, independent operation"},
{c:488210,d: "Railway terminals, independent operation"},
{c:488210,d: "Shunting trailers in rail terminals"},
{c:488210,d: "Switching services, railroad"},
{c:488310,d: "Canal maintenance services (except dredging)"},
{c:488310,d: "Canal operation"},
{c:488310,d: "Docking facility operations"},
{c:488310,d: "Harbor maintenance services (except dredging)"},
{c:488310,d: "Harbor operation"},
{c:488310,d: "Lighthouse operation"},
{c:488310,d: "Maintenance services, waterfront terminal (except dredging)"},
{c:488310,d: "Port facility operation"},
{c:488310,d: "Seaway operation"},
{c:488310,d: "Waterfront terminal operation (e.g., docks, piers, wharves)"},
{c:488310,d: "Wharf operation"},
{c:488320,d: "Loading and unloading services at ports and harbors"},
{c:488320,d: "Longshoremen services"},
{c:488320,d: "Marine cargo handling services"},
{c:488320,d: "Ship hold cleaning services"},
{c:488320,d: "Stevedoring services"},
{c:488330,d: "Cargo salvaging, marine"},
{c:488330,d: "Docking and undocking marine vessel services"},
{c:488330,d: "Harbor tugboat services"},
{c:488330,d: "Marine salvaging services"},
{c:488330,d: "Marine vessel traffic reporting services"},
{c:488330,d: "Piloting services, water transportation"},
{c:488330,d: "Radio beacon (i.e., ship navigation) services"},
{c:488330,d: "Tugboat services, harbor operation"},
{c:488390,d: "Cargo checkers, marine"},
{c:488390,d: "Cargo surveyors, marine"},
{c:488390,d: "Drydocks, floating (i.e., routine repair and maintenance of ships)"},
{c:488390,d: "Marine cargo checkers and surveyors"},
{c:488390,d: "Ship dismantling at floating drydock"},
{c:488390,d: "Ship scaling services not done at a shipyard"},
{c:488410,d: "Emergency road services (i.e., tow service)"},
{c:488410,d: "Motor vehicle towing services"},
{c:488410,d: "Tow truck services"},
{c:488410,d: "Towing services, motor vehicle"},
{c:488410,d: "Wrecker services (i.e., towing services), motor vehicle"},
{c:488490,d: "Bridge, tunnel, and highway operations"},
{c:488490,d: "Bus terminal operation, independent"},
{c:488490,d: "Cargo surveyors, truck transportation"},
{c:488490,d: "Driving services (e.g., automobile, truck delivery)"},
{c:488490,d: "Independent truck driver (except owner-operators)"},
{c:488490,d: "Inspection or weighing services, truck transportation"},
{c:488490,d: "Loading and unloading at truck terminals"},
{c:488490,d: "Pilot car services (i.e., wide load warning services)"},
{c:488490,d: "Shunting of trailers in truck terminals"},
{c:488490,d: "Snow clearing, highways and bridges, road transportation"},
{c:488490,d: "Snow removal, highway"},
{c:488490,d: "Street cleaning service"},
{c:488490,d: "Toll road operations, highway"},
{c:488490,d: "Truck weighing station operation"},
{c:488490,d: "Trucking terminals, independently operated"},
{c:488510,d: "Agents, shipping"},
{c:488510,d: "Customs brokers"},
{c:488510,d: "Freight forwarding"},
{c:488510,d: "Marine shipping agency"},
{c:488510,d: "Shipping agents (freight forwarding)"},
{c:488991,d: "Crating goods for shipping"},
{c:488991,d: "Packing and preparing goods for shipping"},
{c:488991,d: "Preparing goods for transportation (i.e., crating, packing)"},
{c:488999,d: "Arrangement of car pools and vanpools"},
{c:488999,d: "Car pools, arrangement of"},
{c:488999,d: "Liquefied natural gas (LNG) plants"},
{c:488999,d: "Pipeline terminal facilities, independently operated"},
{c:488999,d: "Precooling of fruits and vegetables in connection with transportation"},
{c:488999,d: "Stockyards (i.e., not for fattening or selling livestock), transportation"},
{c:488999,d: "Vanpools, arrangement of"},
{c:491110,d: "Postal delivery services, local, operated by U.S. Postal Service"},
{c:491110,d: "Postal delivery services, local, operated on a contract basis"},
{c:491110,d: "Postal services operated by U.S. Postal Service"},
{c:491110,d: "Postal stations operated by U.S. Postal Service"},
{c:491110,d: "Postal stations operated on a contract basis"},
{c:492110,d: "Air courier services (except establishments operating under a universal service obligation)"},
{c:492110,d: "Courier services (i.e., intercity network) (except establishments operating under a universal service obligation)"},
{c:492110,d: "Express delivery services (except establishments operating under a universal service obligation)"},
{c:492110,d: "Local letter and parcel delivery services as part of intercity courier network"},
{c:492210,d: "Alcoholic beverage delivery service"},
{c:492210,d: "Bicycle courier"},
{c:492210,d: "Delivery service (except as part of intercity courier network, U.S. Postal Service)"},
{c:492210,d: "Grocery delivery services (i.e., independent service from grocery store)"},
{c:492210,d: "Local letter and parcel delivery services (except as part of intercity courier network, U.S. Postal Service)"},
{c:492210,d: "Messenger service"},
{c:492210,d: "Restaurant meals delivery services (i.e., independent delivery services)"},
{c:493110,d: "Bonded warehousing, general merchandise"},
{c:493110,d: "General warehousing and storage"},
{c:493110,d: "Private warehousing and storage, general merchandise"},
{c:493110,d: "Public warehousing and storage (except self-storage), general merchandise"},
{c:493110,d: "Warehousing (including foreign trade zones), general merchandise"},
{c:493110,d: "Warehousing and storage, general merchandise"},
{c:493120,d: "Bonded warehousing, refrigerated"},
{c:493120,d: "Cold storage locker services"},
{c:493120,d: "Cold storage warehousing"},
{c:493120,d: "Farm product warehousing and storage, refrigerated"},
{c:493120,d: "Fur storage warehousing for the trade"},
{c:493120,d: "Private warehousing and storage, refrigerated"},
{c:493120,d: "Public warehousing and storage, refrigerated"},
{c:493120,d: "Refrigerated warehousing"},
{c:493120,d: "Warehousing, refrigerated"},
{c:493130,d: "Bonded warehousing, farm products (except refrigerated)"},
{c:493130,d: "Farm product warehousing and storage (except refrigerated)"},
{c:493130,d: "Grain elevators, storage only"},
{c:493130,d: "Private warehousing and storage, farm products (except refrigerated)"},
{c:493130,d: "Public warehousing and storage, farm products (except refrigerated)"},
{c:493130,d: "Warehousing, farm products (except refrigerated)"},
{c:493190,d: "Automobile dead storage"},
{c:493190,d: "Bonded warehousing (except farm products, general merchandise, refrigerated)"},
{c:493190,d: "Bulk petroleum storage"},
{c:493190,d: "Document storage and warehousing"},
{c:493190,d: "Lumber storage terminals"},
{c:493190,d: "Private warehousing and storage (except farm products, general merchandise, refrigerated)"},
{c:493190,d: "Public warehousing and storage (except farm products, general merchandise, refrigerated, self-storage)"},
{c:493190,d: "Warehousing (except farm products, general merchandise, refrigerated)"},
{c:493190,d: "Whiskey warehousing"},
{c:511110,d: "Newspaper branch offices"},
{c:511110,d: "Newspaper publishers (except exclusive Internet publishing)"},
{c:511110,d: "Newspaper publishers and printing combined"},
{c:511110,d: "Publishers, newspaper (except exclusive Internet publishing)"},
{c:511110,d: "Publishers, newspaper, combined with printing"},
{c:511120,d: "Advertising periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Advertising periodical publishers and printing combined"},
{c:511120,d: "Agricultural magazine and periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Agricultural magazine and periodical publishers and printing combined"},
{c:511120,d: "Comic book publishers (except exclusive Internet publishing)"},
{c:511120,d: "Comic book publishers and printing combined (except exclusive Internet publishing)"},
{c:511120,d: "Financial magazine and periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Financial magazine and periodical publishers and printing combined"},
{c:511120,d: "Juvenile magazine and periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Juvenile magazine and periodical publishers and printing combined"},
{c:511120,d: "Magazine publishers (except exclusive Internet publishing)"},
{c:511120,d: "Magazine publishers and printing combined"},
{c:511120,d: "Medical journal and periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Medical journal and periodical publishers and printing combined"},
{c:511120,d: "Newsletter publishers (except exclusive Internet publishing)"},
{c:511120,d: "Newsletter publishers and printing combined (except Internet)"},
{c:511120,d: "Periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Periodical publishers and printing combined"},
{c:511120,d: "Professional magazine and periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Professional magazine and periodical publishers and printing combined"},
{c:511120,d: "Publishers (except exclusive Internet publishing), magazine"},
{c:511120,d: "Publishers (except exclusive Internet publishing), periodical"},
{c:511120,d: "Publishers, magazine, combined with printing"},
{c:511120,d: "Publishers, periodical, combined with printing"},
{c:511120,d: "Radio guide publishers (except exclusive Internet publishing)"},
{c:511120,d: "Radio guide publishers and printing combined"},
{c:511120,d: "Radio schedule publishers (except exclusive Internet publishing)"},
{c:511120,d: "Radio schedule publishers and printing combined"},
{c:511120,d: "Religious magazine and periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Religious magazine and periodical publishers and printing combined"},
{c:511120,d: "Scholarly journal publishers (except exclusive Internet publishing)"},
{c:511120,d: "Scholarly journal publishers and printing combined"},
{c:511120,d: "Scholastic magazine and periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Scholastic magazine and periodical publishers and printing combined"},
{c:511120,d: "Scientific journal and periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Scientific journal and periodical publishers and printing combined"},
{c:511120,d: "Technical magazine and periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Technical magazine and periodical publishers and printing combined"},
{c:511120,d: "Television guide publishers (except exclusive Internet publishing)"},
{c:511120,d: "Television guide publishers and printing combined"},
{c:511120,d: "Trade journal publishers (except exclusive Internet publishing)"},
{c:511120,d: "Trade journal publishers and printing combined"},
{c:511120,d: "Trade magazine and periodical publishers (except exclusive Internet publishing)"},
{c:511120,d: "Trade magazine and periodical publishers and printing combined"},
{c:511130,d: "Almanac publishers (except exclusive Internet publishing)"},
{c:511130,d: "Almanac publishers and printing combined"},
{c:511130,d: "Atlas publishers (except exclusive Internet publishing)"},
{c:511130,d: "Atlas publishers and printing combined"},
{c:511130,d: "Book (e.g., hardback, paperback, audio) publishers (except exclusive Internet publishing)"},
{c:511130,d: "Book publishers and printing combined"},
{c:511130,d: "Book publishers, university press (except exclusive Internet publishing)"},
{c:511130,d: "Dictionary publishers (except exclusive Internet publishing)"},
{c:511130,d: "Dictionary publishers and printing combined"},
{c:511130,d: "Encyclopedia publishers (except exclusive Internet publishing)"},
{c:511130,d: "Encyclopedia publishers and printing combined"},
{c:511130,d: "Fiction book publishers (except exclusive Internet publishing)"},
{c:511130,d: "Fiction book publishers and printing combined"},
{c:511130,d: "Globe cover publishers"},
{c:511130,d: "Globe cover publishers and printing combined"},
{c:511130,d: "Guide, street map, publishers (except exclusive Internet publishing)"},
{c:511130,d: "Guide, street map, publishers and printing combined"},
{c:511130,d: "Map publishers (except exclusive Internet publishing)"},
{c:511130,d: "Map publishers and printing combined"},
{c:511130,d: "Nonfiction book publishers (except exclusive Internet publishing)"},
{c:511130,d: "Nonfiction book publishers and printing combined"},
{c:511130,d: "Pamphlet publishers (except exclusive Internet publishing)"},
{c:511130,d: "Pamphlet publishers and printing combined"},
{c:511130,d: "Professional book publishers (except exclusive Internet publishing)"},
{c:511130,d: "Professional book publishers and printing combined"},
{c:511130,d: "Publishers (except exclusive Internet publishing), book"},
{c:511130,d: "Publishers (except exclusive Internet publishing), map"},
{c:511130,d: "Publishers, book, combined with printing"},
{c:511130,d: "Religious book publishers (except exclusive Internet publishing)"},
{c:511130,d: "Religious book publishers and printing combined"},
{c:511130,d: "School book publishers (except exclusive Internet publishing)"},
{c:511130,d: "School book publishers and printing combined"},
{c:511130,d: "School textbook publishers (except exclusive Internet publishing)"},
{c:511130,d: "School textbook publishers and printing combined"},
{c:511130,d: "Street map guide publishers (except exclusive Internet publishing)"},
{c:511130,d: "Technical manual and paperback book publishers (except exclusive Internet publishing)"},
{c:511130,d: "Technical manual and paperback book publishers and printing combined"},
{c:511130,d: "Technical manual publishers (except exclusive Internet publishing)"},
{c:511130,d: "Travel guide book publishers (except exclusive Internet publishing)"},
{c:511130,d: "Travel guide book publishers and printing combined"},
{c:511130,d: "University press publishers (except exclusive Internet publishing)"},
{c:511140,d: "Address list publishers (except exclusive Internet publishing)"},
{c:511140,d: "Address list publishers and printing combined"},
{c:511140,d: "Business directory publishers (except exclusive Internet publishing)"},
{c:511140,d: "Business directory publishers and printing combined"},
{c:511140,d: "Catalog of collections publishers (except exclusive Internet publishing)"},
{c:511140,d: "Catalog of collections publishers and printing combined"},
{c:511140,d: "Directory and mailing list publishers (except exclusive Internet publishing)"},
{c:511140,d: "Directory and mailing list publishers and printing combined"},
{c:511140,d: "Directory publishers (except exclusive Internet publishing)"},
{c:511140,d: "Directory publishers and printing combined"},
{c:511140,d: "Electronic directory publishers (except exclusive Internet publishing)"},
{c:511140,d: "Mailing list publishers (except exclusive Internet publishing)"},
{c:511140,d: "Publishers (except exclusive Internet publishing), directory"},
{c:511140,d: "Telephone directory publishers (except exclusive Internet publishing)"},
{c:511140,d: "Telephone directory publishers and printing combined"},
{c:511191,d: "Greeting card publishers (except exclusive Internet publishing)"},
{c:511191,d: "Greeting card publishers and printing combined"},
{c:511191,d: "Publishers (except exclusive Internet publishing), greeting card"},
{c:511191,d: "Publishers, greeting card, combined with printing"},
{c:511199,d: "Art print (except exclusive Internet publishing) publishers"},
{c:511199,d: "Art print publishers and printing combined"},
{c:511199,d: "Art publishers (except exclusive Internet publishing)"},
{c:511199,d: "Calendar publishers (except exclusive Internet publishing)"},
{c:511199,d: "Calendar publishers and printing combined"},
{c:511199,d: "Catalog (i.e., mail-order, store merchandise) publishers (except exclusive Internet publishing)"},
{c:511199,d: "Catalog (i.e., mail-order, store merchandise) publishers and printing combined"},
{c:511199,d: "Children's coloring book publishers (except exclusive Internet publishing)"},
{c:511199,d: "Diary and time scheduler publishers (except exclusive Internet publishing)"},
{c:511199,d: "Discount coupon book publishers (except exclusive Internet publishing)"},
{c:511199,d: "Discount coupon book publishers and printing combined"},
{c:511199,d: "Limited editions art print publishers (except exclusive Internet publishing)"},
{c:511199,d: "Pattern and plan (e.g., clothing patterns) publishers (except exclusive Internet publishing)"},
{c:511199,d: "Pattern and plan (e.g., clothing patterns) publishers and printing combined"},
{c:511199,d: "Postcard publishers (except exclusive Internet publishing)"},
{c:511199,d: "Postcard publishers and printing combined"},
{c:511199,d: "Poster publishers (except exclusive Internet publishing)"},
{c:511199,d: "Poster publishers and printing combined"},
{c:511199,d: "Publishers (except exclusive Internet publishing), racing form"},
{c:511199,d: "Racetrack program publishers (except Internet)"},
{c:511199,d: "Racetrack program publishers and printing combined"},
{c:511199,d: "Racing form publishers (except exclusive Internet publishing)"},
{c:511199,d: "Racing form publishers and printing combined"},
{c:511199,d: "Yearbook (e.g., high school, college, university) publishers (except exclusive Internet publishing)"},
{c:511199,d: "Yearbook (e.g., high school, college, university) publishers and printing combined"},
{c:511210,d: "Applications development and publishing, except on a custom basis"},
{c:511210,d: "Applications software, computer, packaged"},
{c:511210,d: "Computer software publishers, packaged"},
{c:511210,d: "Computer software publishing and reproduction"},
{c:511210,d: "Games, computer software, publishing"},
{c:511210,d: "Operating systems software, computer, packaged"},
{c:511210,d: "Packaged computer software publishers"},
{c:511210,d: "Programming language and compiler software publishers, packaged"},
{c:511210,d: "Publishers, packaged computer software"},
{c:511210,d: "Software computer, packaged, publishers"},
{c:511210,d: "Software publishers"},
{c:511210,d: "Software publishers, packaged"},
{c:511210,d: "Utility software, computer, packaged"},
{c:512110,d: "Animated cartoon production"},
{c:512110,d: "Animated cartoon production and distribution"},
{c:512110,d: "Commercials, television, production"},
{c:512110,d: "Film studios producing films"},
{c:512110,d: "Films, motion picture production"},
{c:512110,d: "Films, motion picture production and distribution"},
{c:512110,d: "Instructional video production"},
{c:512110,d: "Motion picture and video production"},
{c:512110,d: "Motion picture and video production and distribution"},
{c:512110,d: "Motion picture production"},
{c:512110,d: "Motion picture production and distribution"},
{c:512110,d: "Motion picture studios, producing motion pictures"},
{c:512110,d: "Movie production and distribution"},
{c:512110,d: "Music video production"},
{c:512110,d: "Music video production and distribution"},
{c:512110,d: "Program producing, television"},
{c:512110,d: "Television commercial production"},
{c:512110,d: "Television show production"},
{c:512110,d: "Video production"},
{c:512110,d: "Video production and distribution"},
{c:512120,d: "Animated cartoon distribution"},
{c:512120,d: "Film distribution agencies"},
{c:512120,d: "Film distribution, motion picture and video"},
{c:512120,d: "Film libraries, commercial distribution"},
{c:512120,d: "Motion picture distribution exclusive of production"},
{c:512120,d: "Motion picture film distributors"},
{c:512120,d: "Motion picture film libraries"},
{c:512120,d: "Tape distribution for television"},
{c:512120,d: "Television show syndicators"},
{c:512131,d: "Cinemas"},
{c:512131,d: "Film festivals exhibitors"},
{c:512131,d: "Motion picture exhibition"},
{c:512131,d: "Motion picture exhibitors for airlines"},
{c:512131,d: "Motion picture exhibitors, itinerant"},
{c:512131,d: "Motion picture theaters, indoor"},
{c:512131,d: "Movie theaters (except drive-in)"},
{c:512131,d: "Theaters, motion picture (except drive-in)"},
{c:512131,d: "Theaters, motion picture, indoor"},
{c:512132,d: "Drive-in motion picture theaters"},
{c:512132,d: "Motion picture theaters, drive-in"},
{c:512132,d: "Movie theaters, drive-in"},
{c:512132,d: "Open air motion picture theaters"},
{c:512132,d: "Theaters, motion picture, drive-in"},
{c:512132,d: "Theaters, outdoor motion picture"},
{c:512191,d: "Closed captioning services, taped material"},
{c:512191,d: "Film or tape closed captioning"},
{c:512191,d: "Film or video transfer services"},
{c:512191,d: "Motion picture animation, postproduction"},
{c:512191,d: "Motion picture or video editing services"},
{c:512191,d: "Motion picture or video postproduction services"},
{c:512191,d: "Motion picture or video titling"},
{c:512191,d: "Motion picture production special effects, postproduction"},
{c:512191,d: "Postproduction facilities, motion picture or video"},
{c:512191,d: "Post-synchronization sound dubbing"},
{c:512191,d: "Sound dubbing services, motion picture"},
{c:512191,d: "Special effects for motion picture production, postproduction"},
{c:512191,d: "Subtitling of motion picture film or video"},
{c:512191,d: "Tape transfer service"},
{c:512191,d: "Teleproduction services"},
{c:512191,d: "Titling of motion picture film or video"},
{c:512191,d: "Video conversion services (i.e., between formats)"},
{c:512191,d: "Video postproduction services"},
{c:512199,d: "Booking agencies, motion picture"},
{c:512199,d: "Booking agencies, motion picture or video productions"},
{c:512199,d: "Film libraries, motion picture or video, stock footage"},
{c:512199,d: "Film processing laboratories, motion picture"},
{c:512199,d: "Film restoration services"},
{c:512199,d: "Laboratory services, motion picture"},
{c:512199,d: "Libraries, motion picture stock footage film"},
{c:512199,d: "Libraries, video tape, stock footage"},
{c:512199,d: "Motion picture booking agencies"},
{c:512199,d: "Motion picture film laboratories"},
{c:512199,d: "Motion picture film libraries, stock footage"},
{c:512199,d: "Motion picture film reproduction for theatrical distribution"},
{c:512199,d: "Motion picture laboratories"},
{c:512199,d: "Reproduction of motion picture films for theatrical distribution"},
{c:512199,d: "Stock footage film libraries"},
{c:512199,d: "Video tape libraries, stock footage"},
{c:512230,d: "Music book (i.e., bound sheet music) publishers"},
{c:512230,d: "Music book (i.e., bound sheet music) publishers and printing combined"},
{c:512230,d: "Music copyright authorizing use"},
{c:512230,d: "Music copyright buying and licensing"},
{c:512230,d: "Music publishers"},
{c:512230,d: "Music, sheet, publishers and printing combined"},
{c:512230,d: "Publishers, music"},
{c:512230,d: "Sheet music publishers"},
{c:512230,d: "Sheet music publishers and printing combined"},
{c:512230,d: "Song publishers"},
{c:512230,d: "Song publishers and printing combined"},
{c:512240,d: "Audio recording postproduction services"},
{c:512240,d: "Audio recording restoration services"},
{c:512240,d: "Recording studios, sound, operating on a contract or fee basis"},
{c:512240,d: "Sound recording studios (except integrated record companies)"},
{c:512250,d: "Integrated record companies (i.e., releasing, promoting, distributing)"},
{c:512250,d: "Integrated record production and distribution"},
{c:512250,d: "Master recording leasing and licensing"},
{c:512250,d: "Musical recording, releasing, promoting, and distributing"},
{c:512250,d: "Prerecorded audio tapes and compact discs integrated manufacture, release, and distribution"},
{c:512250,d: "Record producers (except independent)"},
{c:512250,d: "Record production (except independent record producers) without duplication or distribution"},
{c:512250,d: "Record releasing, promoting, and distributing combined with mass duplication"},
{c:512250,d: "Sound recording, integrated production, reproduction, release, and distribution"},
{c:512250,d: "Sound recording, releasing, promoting, and distributing"},
{c:512290,d: "Audio recording of meetings or conferences"},
{c:512290,d: "Music program distribution, prerecorded"},
{c:512290,d: "Producers, recorded radio shows (except independent producers)"},
{c:512290,d: "Radio program recording production (except independent producers)"},
{c:512290,d: "Recording books on tape or disc (except publishers)"},
{c:512290,d: "Recording seminars and conferences, audio"},
{c:512290,d: "Stock music and other audio services"},
{c:512290,d: "Stock sound library (e.g., general background sounds, stock music)"},
{c:515111,d: "Broadcasting networks, radio"},
{c:515111,d: "Network broadcasting service, radio"},
{c:515111,d: "Network radio broadcasting"},
{c:515111,d: "Radio broadcasting network services"},
{c:515111,d: "Radio broadcasting networks"},
{c:515111,d: "Radio broadcasting syndicates"},
{c:515111,d: "Satellite radio networks"},
{c:515112,d: "Advertising sales offices of independent and public radio broadcast stations"},
{c:515112,d: "AM radio stations"},
{c:515112,d: "Broadcasting stations (except exclusively on Internet), radio"},
{c:515112,d: "Broadcasting studio, radio station"},
{c:515112,d: "FM radio stations"},
{c:515112,d: "Music program distribution (except exclusively on Internet), radio"},
{c:515112,d: "Piped-in music services, radio transmitted"},
{c:515112,d: "Radio broadcasting (except exclusively on Internet) stations (e.g., AM, FM, shortwave)"},
{c:515112,d: "Radio stations (except exclusively on Internet)"},
{c:515112,d: "Rebroadcast radio stations (except exclusively on Internet)"},
{c:515120,d: "Broadcasting networks, television"},
{c:515120,d: "Broadcasting stations, television"},
{c:515120,d: "Network television broadcasting"},
{c:515120,d: "Television broadcasting networks"},
{c:515120,d: "Television broadcasting stations"},
{c:515210,d: "Cable broadcasting networks"},
{c:515210,d: "Cable television networks"},
{c:515210,d: "Networks, cable television"},
{c:515210,d: "Pay television networks"},
{c:515210,d: "Pay-per-view cable programming"},
{c:515210,d: "Satellite television networks"},
{c:515210,d: "Specialty television (e.g., music, sports, news) cable networks"},
{c:515210,d: "Subscription television networks"},
{c:515210,d: "Television subscription services"},
{c:517311,d: "Cable program distribution operators"},
{c:517311,d: "Cable television distribution services"},
{c:517311,d: "Cable TV providers (except networks)"},
{c:517311,d: "Closed-circuit television (CCTV) services"},
{c:517311,d: "Direct broadcast satellite (DBS) services"},
{c:517311,d: "Direct-to-home satellite system (DTH) services"},
{c:517311,d: "Internet service providers, using own operated wired telecommunications infrastructure (e.g., cable, DSL)"},
{c:517311,d: "Local telephone carriers, wired"},
{c:517311,d: "Long-distance telephone carriers, wired"},
{c:517311,d: "Multichannel multipoint distribution services (MMDS)"},
{c:517311,d: "Music program distribution, cable or satellite"},
{c:517311,d: "On-line access service providers, using own operated wired telecommunications infrastructure"},
{c:517311,d: "Satellite master antenna television service (SMATV)"},
{c:517311,d: "Satellite television distribution systems"},
{c:517311,d: "Telecommunications carriers, wired"},
{c:517311,d: "Television operations, closed-circuit"},
{c:517311,d: "VoIP service providers, using own operated wired telecommunications infrastructure"},
{c:517312,d: "Beeper (i.e., radio pager) communication carriers"},
{c:517312,d: "Cellular telephone communication carriers"},
{c:517312,d: "Cellular telephone services"},
{c:517312,d: "Cellular telephone stores, primarily selling cellular phone service plans"},
{c:517312,d: "Mobile phone stores, primarily selling mobile phone service plans"},
{c:517312,d: "Mobile telephone communication carriers (except satellite)"},
{c:517312,d: "Paging services (except satellite)"},
{c:517312,d: "Radio paging services communications carriers"},
{c:517312,d: "Ship-to-shore broadcasting communication carriers (except satellite)"},
{c:517312,d: "Telecommunications carriers, cellular telephone"},
{c:517312,d: "Telephone communications carriers, wireless (except satellite)"},
{c:517312,d: "Two-way paging communication carriers (except satellite)"},
{c:517312,d: "Wireless data communication carriers (except satellite)"},
{c:517312,d: "Wireless Internet service providers (except satellite)"},
{c:517312,d: "Wireless phone stores, primarily selling wireless phone service plans"},
{c:517312,d: "Wireless telephone communications carriers (except satellite)"},
{c:517312,d: "Wireless video services (except satellite)"},
{c:517410,d: "Earth stations for satellite communication carriers"},
{c:517410,d: "Long-distance telephone satellite communication carriers"},
{c:517410,d: "Resellers, satellite telecommunication"},
{c:517410,d: "Satellite telecommunication carriers"},
{c:517410,d: "Satellite telecommunication resellers"},
{c:517410,d: "Telephone communications carriers, satellite"},
{c:517911,d: "Long-distance telecommunication resellers (except satellite)"},
{c:517911,d: "Microwave telecommunication resellers"},
{c:517911,d: "Pre-paid calling cards, telecommunications resellers"},
{c:517911,d: "Resellers, telecommunication (except satellite)"},
{c:517911,d: "Telecommunications resellers"},
{c:517911,d: "Telephone communications resellers (except satellite)"},
{c:517911,d: "Wired telecommunication resellers"},
{c:517911,d: "Wireless telecommunication resellers (except satellite)"},
{c:517919,d: "Dial-up Internet service providers, using client-supplied telecommunications connections"},
{c:517919,d: "Earth stations (except satellite telecommunication carriers)"},
{c:517919,d: "Internet service providers, using client-supplied telecommunications (e.g., dial-up ISPs)"},
{c:517919,d: "On-line access service providers, using client-supplied telecommunications (e.g., dial-up ISPs)"},
{c:517919,d: "Radar station operations"},
{c:517919,d: "Satellite telemetry operations on a contract or fee basis"},
{c:517919,d: "Satellite tracking stations"},
{c:517919,d: "Telemetry and tracking system operations on a contract or fee basis"},
{c:517919,d: "VoIP service providers, using client-supplied telecommunications connections"},
{c:518210,d: "Application hosting"},
{c:518210,d: "Automated data processing services"},
{c:518210,d: "Computer data storage services"},
{c:518210,d: "Computer input preparation services"},
{c:518210,d: "Computer time leasing"},
{c:518210,d: "Computer time rental"},
{c:518210,d: "Computer time sharing services"},
{c:518210,d: "Data capture imaging services"},
{c:518210,d: "Data entry services"},
{c:518210,d: "Data processing computer services"},
{c:518210,d: "Data processing services (except payroll services, financial transaction processing services)"},
{c:518210,d: "Disk and diskette conversion services"},
{c:518210,d: "Disk and diskette recertification services"},
{c:518210,d: "Electronic data processing services"},
{c:518210,d: "Media streaming services"},
{c:518210,d: "Microfiche recording and imaging services"},
{c:518210,d: "Microfilm recording and imaging services"},
{c:518210,d: "Optical scanning services"},
{c:518210,d: "Scanning services, optical"},
{c:518210,d: "Video and audio streaming services"},
{c:518210,d: "Web hosting"},
{c:519110,d: "Feature syndicates (i.e., advice columns, comic, news)"},
{c:519110,d: "News picture gathering and distributing services"},
{c:519110,d: "News reporting services"},
{c:519110,d: "News service syndicates"},
{c:519110,d: "News ticker services"},
{c:519110,d: "Newspaper feature syndicates"},
{c:519110,d: "Syndicates, news"},
{c:519110,d: "Traffic reporting services"},
{c:519110,d: "Wire photo services"},
{c:519110,d: "Wire services, news"},
{c:519120,d: "Archives"},
{c:519120,d: "Bookmobiles"},
{c:519120,d: "Centers for documentation (i.e., archives)"},
{c:519120,d: "Circulating libraries"},
{c:519120,d: "Film archives"},
{c:519120,d: "Lending libraries"},
{c:519120,d: "Libraries (except motion picture stock footage, motion picture commercial distribution)"},
{c:519120,d: "Motion picture film libraries, archives"},
{c:519120,d: "Music archives"},
{c:519120,d: "Reference libraries"},
{c:519130,d: "Advertising periodical publishers, exclusively on Internet"},
{c:519130,d: "Agricultural magazine and periodical publishers, exclusively on Internet"},
{c:519130,d: "Almanac publishers, exclusively on Internet"},
{c:519130,d: "Art publishers, exclusively on Internet"},
{c:519130,d: "Atlas publishers, exclusively on Internet"},
{c:519130,d: "Book publishers, exclusively on Internet"},
{c:519130,d: "Broadcasting exclusively on Internet, audio"},
{c:519130,d: "Broadcasting exclusively on Internet, video"},
{c:519130,d: "Business directory publishers, exclusively on Internet"},
{c:519130,d: "Calendar publishers, exclusively on Internet"},
{c:519130,d: "Catalog of collections publishers, exclusively on Internet"},
{c:519130,d: "Children's coloring book publishers, exclusively on Internet"},
{c:519130,d: "Comic book publishers, exclusively on Internet"},
{c:519130,d: "Diary and time scheduler publishers, exclusively on Internet"},
{c:519130,d: "Dictionary publishers, exclusively on Internet"},
{c:519130,d: "Directory publishers, exclusively on Internet"},
{c:519130,d: "Discount coupon book publishers, exclusively on Internet"},
{c:519130,d: "Electronic directory publishers, exclusively on Internet"},
{c:519130,d: "Encyclopedia publishers, exclusively on Internet"},
{c:519130,d: "Entertainment sites, Internet"},
{c:519130,d: "Fiction book publishers, exclusively on Internet"},
{c:519130,d: "Financial magazine and periodical publishers, exclusively on Internet"},
{c:519130,d: "Greeting card publishers, exclusively on Internet"},
{c:519130,d: "Guide, street map, publishers, exclusively on Internet"},
{c:519130,d: "Internet book publishers"},
{c:519130,d: "Internet broadcasting"},
{c:519130,d: "Internet comic book publishing"},
{c:519130,d: "Internet entertainment sites"},
{c:519130,d: "Internet magazine publishing"},
{c:519130,d: "Internet news publishers"},
{c:519130,d: "Internet newsletter publishing"},
{c:519130,d: "Internet newspaper publishing"},
{c:519130,d: "Internet periodical publishers"},
{c:519130,d: "Internet radio stations"},
{c:519130,d: "Internet search portals"},
{c:519130,d: "Internet search Web sites"},
{c:519130,d: "Internet sports sites"},
{c:519130,d: "Internet video broadcast sites"},
{c:519130,d: "Juvenile magazine and periodical publishers, exclusively on Internet"},
{c:519130,d: "Magazine publishers, exclusively on Internet"},
{c:519130,d: "Map publishers, exclusively on Internet"},
{c:519130,d: "Medical journal and periodical publishers, exclusively on Internet"},
{c:519130,d: "Newsletter publishers, exclusively on Internet"},
{c:519130,d: "Newspaper publishing, exclusively on Internet"},
{c:519130,d: "Nonfiction book publishers, exclusively on Internet"},
{c:519130,d: "Pamphlet publishers, exclusively on Internet"},
{c:519130,d: "Pattern and plan (e.g., clothing patterns) publishers, exclusively on Internet"},
{c:519130,d: "Periodical publishers, exclusively on Internet"},
{c:519130,d: "Portals, web search"},
{c:519130,d: "Postcard publishers, exclusively on Internet"},
{c:519130,d: "Poster publishers, exclusively on Internet"},
{c:519130,d: "Professional book publishers, exclusively on Internet"},
{c:519130,d: "Professional magazine and periodical publishers, exclusively on Internet"},
{c:519130,d: "Publishers, Internet greeting card"},
{c:519130,d: "Publishers, Internet map"},
{c:519130,d: "Publishers, Internet racing form"},
{c:519130,d: "Racetrack program publishers, exclusively on Internet"},
{c:519130,d: "Racing form publishers, exclusively on Internet"},
{c:519130,d: "Radio guide publishers, exclusively on Internet"},
{c:519130,d: "Radio schedule publishers, exclusively on Internet"},
{c:519130,d: "Religious book publishers, exclusively on Internet"},
{c:519130,d: "Religious magazine and periodical publishers, exclusively on Internet"},
{c:519130,d: "Scholarly journal publishers, exclusively on Internet"},
{c:519130,d: "Scholastic magazine and periodical publishers, exclusively on Internet"},
{c:519130,d: "School book publishers, exclusively on Internet"},
{c:519130,d: "School textbook publishers, exclusively on Internet"},
{c:519130,d: "Scientific journal and periodical publishers, exclusively on Internet"},
{c:519130,d: "Search portals, Internet"},
{c:519130,d: "Social networking sites, Internet"},
{c:519130,d: "Special interest portals (e.g., parents sharing information about child rearing, etc.), Internet"},
{c:519130,d: "Street map and guide publishers, exclusively on Internet"},
{c:519130,d: "Technical magazine and periodical publishers, exclusively on Internet"},
{c:519130,d: "Technical manual publishers, exclusively on Internet"},
{c:519130,d: "Telephone directory publishers, exclusively on Internet"},
{c:519130,d: "Television guide publishers, exclusively on Internet"},
{c:519130,d: "Trade journal publishers, exclusively on Internet"},
{c:519130,d: "Trade magazine and periodical publishers, exclusively on Internet"},
{c:519130,d: "Travel guide book publishers, exclusively on Internet"},
{c:519130,d: "University press publishers, exclusively on Internet"},
{c:519130,d: "Video broadcasting, exclusively on Internet"},
{c:519130,d: "Web broadcasting"},
{c:519130,d: "Web communities"},
{c:519130,d: "Web search portals"},
{c:519130,d: "Yearbook (e.g., high school, college, university) publishers, exclusively on Internet"},
{c:519190,d: "Clipping services, news"},
{c:519190,d: "News clipping services"},
{c:519190,d: "Press clipping services"},
{c:519190,d: "Stock photo agencies"},
{c:519190,d: "Telephone-based recorded information services"},
{c:519190,d: "Title search services (except real estate)"},
{c:521110,d: "Banking, central"},
{c:521110,d: "Banks, Federal Reserve"},
{c:521110,d: "Branches, Federal Reserve Bank"},
{c:521110,d: "Central bank, monetary authorities"},
{c:521110,d: "Check clearing activities of the central bank"},
{c:521110,d: "Federal Reserve Banks or Branches"},
{c:521110,d: "Financial transactions processing of the central bank"},
{c:521110,d: "Monetary authorities, central bank"},
{c:522110,d: "Banks, commercial"},
{c:522110,d: "Branches of foreign banks"},
{c:522110,d: "Commercial banking"},
{c:522110,d: "Commercial banks"},
{c:522110,d: "Depository trust companies"},
{c:522110,d: "National commercial banks"},
{c:522110,d: "State commercial banks"},
{c:522120,d: "Associations, savings and loan"},
{c:522120,d: "Banks, savings"},
{c:522120,d: "Federal savings and loan associations (S&L)"},
{c:522120,d: "Federal savings banks"},
{c:522120,d: "Institutions, savings"},
{c:522120,d: "Mutual savings banks"},
{c:522120,d: "Savings and loan associations (S&L)"},
{c:522120,d: "Savings banks"},
{c:522120,d: "Savings institutions"},
{c:522120,d: "State savings and loan associations"},
{c:522120,d: "State savings banks"},
{c:522130,d: "Corporate credit unions"},
{c:522130,d: "Credit unions"},
{c:522130,d: "Federal credit unions"},
{c:522130,d: "State credit unions"},
{c:522130,d: "Unions, credit"},
{c:522190,d: "Banks, industrial (i.e., known as), depository"},
{c:522190,d: "Banks, private (i.e., unincorporated)"},
{c:522190,d: "Industrial banks (i.e., known as), depository"},
{c:522190,d: "Morris Plans (i.e., known as), depository"},
{c:522190,d: "Plans, Morris (i.e., known as), depository"},
{c:522190,d: "Private banks (i.e., unincorporated)"},
{c:522210,d: "Banks, credit card"},
{c:522210,d: "Charge card issuing"},
{c:522210,d: "Credit card banks"},
{c:522210,d: "Credit card issuing"},
{c:522210,d: "Issuing, credit card"},
{c:522220,d: "Automobile finance leasing companies"},
{c:522220,d: "Automobile financing"},
{c:522220,d: "Commercial and inventory financing (except international trade financing)"},
{c:522220,d: "Equipment finance leasing"},
{c:522220,d: "Financing, sales"},
{c:522220,d: "Installment sales financing"},
{c:522220,d: "Insurance premium financing"},
{c:522220,d: "Leasing in combination with sales financing"},
{c:522220,d: "Machinery finance leasing"},
{c:522220,d: "Sales financing"},
{c:522220,d: "Truck finance leasing"},
{c:522291,d: "Consumer finance companies (i.e., unsecured cash loans)"},
{c:522291,d: "Consumer lending"},
{c:522291,d: "Finance companies (i.e., unsecured cash loans)"},
{c:522291,d: "Loan companies (i.e., consumer, personal, small, student)"},
{c:522291,d: "Personal credit institutions (i.e., unsecured cash loans)"},
{c:522291,d: "Personal finance companies (i.e., unsecured cash loans)"},
{c:522291,d: "Small loan companies (i.e., unsecured cash loans)"},
{c:522291,d: "Student loan companies"},
{c:522292,d: "Construction lending"},
{c:522292,d: "Farm mortgage lending"},
{c:522292,d: "Federal Land Banks"},
{c:522292,d: "Home equity credit lending"},
{c:522292,d: "Loan correspondents (i.e., lending funds with real estate as collateral)"},
{c:522292,d: "Mortgage banking (i.e., nondepository mortgage lending)"},
{c:522292,d: "Mortgage companies"},
{c:522292,d: "Real estate credit lending"},
{c:522292,d: "Reverse mortgage lending"},
{c:522293,d: "Agencies of foreign banks (i.e., trade financing)"},
{c:522293,d: "Agreement corporations (i.e., international trade financing)"},
{c:522293,d: "Banks, trade (i.e., international trade financing)"},
{c:522293,d: "Edge Act corporations (i.e., international trade financing)"},
{c:522293,d: "Export trading companies (i.e., international trade financing)"},
{c:522293,d: "Export-Import banks"},
{c:522293,d: "International trade financing"},
{c:522293,d: "Trade banks (i.e., international trade financing)"},
{c:522293,d: "Trade financing, international"},
{c:522294,d: "Collateralized mortgage obligation (CMO) issuing, private"},
{c:522294,d: "Federal Agricultural Mortgage Corporation"},
{c:522294,d: "Federal Home Loan Mortgage Corporation (FHLMC)"},
{c:522294,d: "Federal Intermediate Credit Bank"},
{c:522294,d: "Federal National Mortgage Association (FNMA)"},
{c:522294,d: "FHLMC (Federal Home Loan Mortgage Corporation)"},
{c:522294,d: "Financing, secondary market"},
{c:522294,d: "FNMA (Federal National Mortgage Association)"},
{c:522294,d: "GNMA (Government National Mortgage Association)"},
{c:522294,d: "Government National Mortgage Association (GNMA)"},
{c:522294,d: "Government-sponsored enterprises providing secondary market financing"},
{c:522294,d: "Real estate mortgage investment conduits (REMICs) issuing, private"},
{c:522294,d: "REMICs (real estate mortgage investment conduits) issuing, private"},
{c:522294,d: "Repackaging loans for sale to others (i.e., private conduits)"},
{c:522294,d: "Secondary market financing (i.e., buying, pooling, repackaging loans for sale to others)"},
{c:522294,d: "SLMA (Student Loan Marketing Association)"},
{c:522294,d: "Student Loan Marketing Association (SLMA)"},
{c:522298,d: "Agricultural credit institutions, making loans or extending credit (except real estate, sales financing)"},
{c:522298,d: "Agricultural lending (except real estate, sales financing)"},
{c:522298,d: "Banks, industrial (i.e., known as), nondepository"},
{c:522298,d: "Car title lending"},
{c:522298,d: "Commodity Credit Corporation"},
{c:522298,d: "Edge Act corporations (except international trade financing)"},
{c:522298,d: "Factoring accounts receivable"},
{c:522298,d: "Federal Home Loan Banks (FHLB)"},
{c:522298,d: "Industrial banks (i.e., known as), nondepository"},
{c:522298,d: "Industrial loan companies, nondepository"},
{c:522298,d: "Morris Plans (i.e., known as), nondepository"},
{c:522298,d: "National Credit Union Administration (NCUA)"},
{c:522298,d: "Pawnshops"},
{c:522298,d: "Plans, Morris (i.e., known as), nondepository"},
{c:522298,d: "Purchasing of accounts receivable"},
{c:522298,d: "Short-term inventory credit lending"},
{c:522310,d: "Agencies, loan"},
{c:522310,d: "Brokerages, loan"},
{c:522310,d: "Brokerages, mortgage"},
{c:522310,d: "Brokers' offices, loan"},
{c:522310,d: "Brokers' offices, mortgage"},
{c:522310,d: "Loan brokerages"},
{c:522310,d: "Loan brokers' or agents' offices (i.e., independent)"},
{c:522310,d: "Mortgage brokerages"},
{c:522310,d: "Mortgage brokers' or agents' offices (i.e., independent)"},
{c:522320,d: "Automated clearinghouses, bank or check (except central bank)"},
{c:522320,d: "Automated Teller Machine (ATM) or Automated Loan Machine (ALM) network operation"},
{c:522320,d: "Bank clearinghouse associations"},
{c:522320,d: "Central reserve financial institutions (except central bank)"},
{c:522320,d: "Check clearing services (except central banks)"},
{c:522320,d: "Check clearinghouse services (except central banks)"},
{c:522320,d: "Check validation services"},
{c:522320,d: "Clearinghouses, bank or check"},
{c:522320,d: "Credit card processing services"},
{c:522320,d: "Electronic financial payment services"},
{c:522320,d: "Electronic funds transfer services"},
{c:522320,d: "Financial transactions processing (except central bank)"},
{c:522320,d: "Processing financial transactions"},
{c:522320,d: "Reserve and liquidity services (except central bank)"},
{c:522390,d: "Check cashing services"},
{c:522390,d: "Loan servicing"},
{c:522390,d: "Money order issuance services"},
{c:522390,d: "Money transmission services"},
{c:522390,d: "Payday lending services"},
{c:522390,d: "Travelers' check issuance services"},
{c:523110,d: "Banking, investment"},
{c:523110,d: "Bond dealing (i.e., acting as a principal in dealing securities to investors)"},
{c:523110,d: "Commercial paper dealing (i.e., acting as a principal in dealing securities to investors)"},
{c:523110,d: "Investment banking"},
{c:523110,d: "Making markets for securities"},
{c:523110,d: "Market making for securities"},
{c:523110,d: "Paper, dealing of commercial (i.e., acting as principal in dealing securities to investors)"},
{c:523110,d: "Securities dealers (i.e., acting as a principal in dealing securities to investors)"},
{c:523110,d: "Securities dealing (i.e., acting as a principal in dealing securities to investors)"},
{c:523110,d: "Securities distributing (i.e., acting as a principal in dealing securities to investors)"},
{c:523110,d: "Securities floor traders (i.e., acting as a principal in dealing securities to investors)"},
{c:523110,d: "Securities flotation companies"},
{c:523110,d: "Securities originating (i.e., acting as a principal in dealing securities to investors)"},
{c:523110,d: "Securities trading (i.e., acting as a principal in dealing securities to investors)"},
{c:523110,d: "Securities underwriting"},
{c:523110,d: "Stock options dealing (i.e., acting as a principal in dealing securities to investors)"},
{c:523110,d: "Trading securities (i.e., acting as a principal in dealing securities to investors)"},
{c:523110,d: "Underwriting securities"},
{c:523120,d: "Bond brokerages"},
{c:523120,d: "Brokerages, securities"},
{c:523120,d: "Certificate of deposit (CD) brokers' offices"},
{c:523120,d: "Commercial note brokers' offices"},
{c:523120,d: "Mutual fund agencies (i.e., brokerages)"},
{c:523120,d: "Mutual fund agents' (i.e., brokers') offices"},
{c:523120,d: "Securities brokerages"},
{c:523120,d: "Securities brokers' offices"},
{c:523120,d: "Securities floor brokers"},
{c:523120,d: "Stock brokerages"},
{c:523120,d: "Stock brokers' offices"},
{c:523120,d: "Stock options brokerages"},
{c:523130,d: "Commodity contract trading companies"},
{c:523130,d: "Commodity contracts dealing (i.e., acting as a principal in dealing commodities to investors)"},
{c:523130,d: "Commodity contracts floor traders (i.e., acting as a principal in dealing commodities to investors)"},
{c:523130,d: "Commodity contracts floor trading (i.e., acting as a principal in dealing commodities to investors)"},
{c:523130,d: "Commodity contracts options dealing (i.e., acting as a principal in dealing commodities to investors)"},
{c:523130,d: "Commodity contracts traders (i.e., acting as a principal in dealing commodities to investors)"},
{c:523130,d: "Foreign currency exchange dealing (i.e., acting as a principal in dealing commodities to investors)"},
{c:523130,d: "Foreign currency exchange services (i.e., selling to the public)"},
{c:523130,d: "Futures commodity contracts dealing (i.e., acting as a principal in dealing commodities to investors)"},
{c:523130,d: "Trading companies, commodity contracts"},
{c:523130,d: "Virtual currency exchange services (i.e., selling to the public)"},
{c:523140,d: "Brokerages, commodity contracts"},
{c:523140,d: "Commodity contract pool operators"},
{c:523140,d: "Commodity contracts brokerages"},
{c:523140,d: "Commodity contracts brokers' offices"},
{c:523140,d: "Commodity contracts floor brokers"},
{c:523140,d: "Commodity contracts options brokerages"},
{c:523140,d: "Commodity futures brokerages"},
{c:523140,d: "Financial futures brokerages"},
{c:523140,d: "Foreign currency exchange brokering services"},
{c:523140,d: "Futures commodity contracts brokerages"},
{c:523140,d: "Futures commodity contracts brokers' offices"},
{c:523210,d: "Commodity contracts exchanges"},
{c:523210,d: "Exchanges, commodity contracts"},
{c:523210,d: "Exchanges, securities"},
{c:523210,d: "Futures commodity contracts exchanges"},
{c:523210,d: "Securities exchanges"},
{c:523210,d: "Stock exchanges"},
{c:523210,d: "Stock or commodity options exchanges"},
{c:523910,d: "Individuals investing in financial contracts on own account"},
{c:523910,d: "Investment clubs"},
{c:523910,d: "Mineral royalties or leases dealing (i.e., acting as a principal in dealing royalties or leases to investors)"},
{c:523910,d: "Mortgage dealers, buying and selling"},
{c:523910,d: "Oil royalty dealing (i.e., acting as a principal in dealing royalties to investors)"},
{c:523910,d: "Securities speculators for own account"},
{c:523910,d: "Tax liens dealing (i.e., acting as a principal in dealing tax liens to investors)"},
{c:523910,d: "Venture capital companies"},
{c:523910,d: "Viatical settlement companies"},
{c:523920,d: "Investment management"},
{c:523920,d: "Managing investment funds"},
{c:523920,d: "Managing mutual funds"},
{c:523920,d: "Managing personal investment trusts"},
{c:523920,d: "Managing trusts"},
{c:523920,d: "Mutual fund managing"},
{c:523920,d: "Pension fund managing"},
{c:523920,d: "Personal investments trusts, managing"},
{c:523920,d: "Portfolio fund managing"},
{c:523920,d: "Private equity fund managing"},
{c:523930,d: "Certified financial planners, customized, fees paid by client"},
{c:523930,d: "Financial investment advice services, customized, fees paid by client"},
{c:523930,d: "Financial planning services, customized, fees paid by client"},
{c:523930,d: "Investment advice consulting services, customized, fees paid by client"},
{c:523930,d: "Investment advice counseling services, customized, fees paid by client"},
{c:523930,d: "Investment advisory services, customized, fees paid by client"},
{c:523991,d: "Administrators of private estates"},
{c:523991,d: "Bank trust offices"},
{c:523991,d: "Escrow agencies (except real estate)"},
{c:523991,d: "Fiduciary agencies (except real estate)"},
{c:523991,d: "Personal investments trust administration"},
{c:523991,d: "Securities custodians"},
{c:523991,d: "Trust administration, personal investment"},
{c:523991,d: "Trust companies, nondepository"},
{c:523999,d: "Clearinghouses, commodity exchange or securities exchange"},
{c:523999,d: "Deposit brokers"},
{c:523999,d: "Exchange clearinghouses, commodities or securities"},
{c:523999,d: "Gas lease brokers' offices"},
{c:523999,d: "Oil lease brokers' offices"},
{c:523999,d: "Protective committees, security holders"},
{c:523999,d: "Quotation services, securities"},
{c:523999,d: "Quotation services, stock"},
{c:523999,d: "Securities holders' protective services"},
{c:523999,d: "Securities transfer agencies"},
{c:523999,d: "Stock quotation services"},
{c:523999,d: "Stock transfer agencies"},
{c:523999,d: "Transfer agencies, securities"},
{c:524113,d: "Accidental death and dismemberment insurance carriers, direct"},
{c:524113,d: "Accidental death and dismemberment insurance underwriting, direct"},
{c:524113,d: "Annuities underwriting"},
{c:524113,d: "Cooperative life insurance organizations"},
{c:524113,d: "Credit life insurance carriers, direct"},
{c:524113,d: "Disability insurance carriers, direct"},
{c:524113,d: "Disability insurance underwriting, direct"},
{c:524113,d: "Fraternal life insurance organizations"},
{c:524113,d: "Insurance carriers, disability, direct"},
{c:524113,d: "Insurance carriers, life, direct"},
{c:524113,d: "Insurance underwriting, disability, direct"},
{c:524113,d: "Insurance underwriting, life, direct"},
{c:524113,d: "Life insurance carriers, direct"},
{c:524113,d: "Savings bank life insurance carriers, direct"},
{c:524114,d: "Dental insurance carriers, direct"},
{c:524114,d: "Group hospitalization plans without providing health care services"},
{c:524114,d: "Health insurance carriers, direct"},
{c:524114,d: "Hospital and medical service plans, direct, without providing health care services"},
{c:524114,d: "Hospitalization insurance carriers, direct, without providing health care services"},
{c:524114,d: "Insurance carriers, health, direct"},
{c:524114,d: "Insurance underwriting, health and medical, direct"},
{c:524114,d: "Medical insurance carriers, direct"},
{c:524114,d: "Medical service plans without providing health care services"},
{c:524126,d: "Agricultural (i.e., crop, livestock) insurance carriers, direct"},
{c:524126,d: "Automobile insurance carriers, direct"},
{c:524126,d: "Bonding, fidelity or surety insurance, direct"},
{c:524126,d: "Burglary and theft insurance carriers, direct"},
{c:524126,d: "Casualty insurance carriers, direct"},
{c:524126,d: "Credit and other financial responsibility insurance carriers, direct"},
{c:524126,d: "Crop insurance carrier, direct"},
{c:524126,d: "Fidelity insurance carriers, direct"},
{c:524126,d: "Fire insurance carriers, direct"},
{c:524126,d: "Homeowners' insurance carriers, direct"},
{c:524126,d: "Insurance carriers, fidelity, direct"},
{c:524126,d: "Insurance carriers, property and casualty, direct"},
{c:524126,d: "Insurance carriers, surety, direct"},
{c:524126,d: "Insurance underwriting, property and casualty, direct"},
{c:524126,d: "Liability insurance carriers, direct"},
{c:524126,d: "Malpractice insurance carriers, direct"},
{c:524126,d: "Marine insurance carriers, direct"},
{c:524126,d: "Mortgage guaranty insurance carriers, direct"},
{c:524126,d: "Plate glass insurance carriers, direct"},
{c:524126,d: "Property and casualty insurance carriers, direct"},
{c:524126,d: "Property damage insurance carriers, direct"},
{c:524126,d: "Surety insurance carriers, direct"},
{c:524126,d: "Workers' compensation insurance underwriting"},
{c:524127,d: "Guaranteeing titles"},
{c:524127,d: "Insurance carriers, title, direct"},
{c:524127,d: "Insurance underwriting, title, direct"},
{c:524127,d: "Real estate title insurance carriers, direct"},
{c:524127,d: "Title insurance carriers, real estate, direct"},
{c:524128,d: "Bank deposit insurance carriers, direct"},
{c:524128,d: "Burial insurance carriers, direct"},
{c:524128,d: "Contact lens insurance, direct"},
{c:524128,d: "Deposit or share insurance carriers, direct"},
{c:524128,d: "Funeral insurance carriers, direct"},
{c:524128,d: "Homeowners' warranty insurance carriers, direct"},
{c:524128,d: "Pet health insurance carriers, direct"},
{c:524128,d: "Product warranty insurance carriers, direct"},
{c:524128,d: "Warranty insurance carriers (e.g., appliance, automobile, homeowners', product), direct"},
{c:524130,d: "Accidental and health reinsurance carriers"},
{c:524130,d: "Life reinsurance carriers"},
{c:524130,d: "Marine reinsurance carriers"},
{c:524130,d: "Medical reinsurance carriers"},
{c:524130,d: "Property and casualty reinsurance carriers"},
{c:524130,d: "Reinsurance carriers"},
{c:524210,d: "Agencies, insurance"},
{c:524210,d: "Brokerages, insurance"},
{c:524210,d: "Brokers' offices, insurance"},
{c:524210,d: "Insurance agencies"},
{c:524210,d: "Insurance brokerages"},
{c:524210,d: "Life insurance agencies"},
{c:524291,d: "Cause-of-loss investigators, insurance"},
{c:524291,d: "Claims adjusting, insurance"},
{c:524291,d: "Fire investigators"},
{c:524291,d: "Insurance claims adjusting"},
{c:524291,d: "Insurance claims investigation services"},
{c:524291,d: "Insurance settlement offices"},
{c:524291,d: "Loss control consultants"},
{c:524292,d: "Claims processing services, insurance, third party"},
{c:524292,d: "Employee benefit plans, third party administrative processing services"},
{c:524292,d: "Insurance claims processing services, third party"},
{c:524292,d: "Insurance fund, third party administrative services (except claims adjusting only)"},
{c:524292,d: "Insurance plan administrative services (except claims adjusting only), third party"},
{c:524292,d: "Pension fund, third party administrative services"},
{c:524298,d: "Actuarial services, insurance"},
{c:524298,d: "Insurance actuarial services"},
{c:524298,d: "Insurance advisory services"},
{c:524298,d: "Insurance coverage consulting services"},
{c:524298,d: "Insurance exchanges"},
{c:524298,d: "Insurance investigation services (except claims investigation)"},
{c:524298,d: "Insurance loss prevention services"},
{c:524298,d: "Insurance rate making services"},
{c:524298,d: "Insurance reporting services"},
{c:524298,d: "Insurance underwriters laboratories and standards services"},
{c:524298,d: "Medical cost evaluation services"},
{c:524298,d: "Ratemaking services, insurance"},
{c:525110,d: "Employee benefit pension plans"},
{c:525110,d: "Funds, employee benefit pension"},
{c:525110,d: "Funds, pension"},
{c:525110,d: "Pension funds"},
{c:525110,d: "Pension plans (e.g., employee benefit, retirement)"},
{c:525110,d: "Plans, pension"},
{c:525110,d: "Retirement pension plans"},
{c:525110,d: "Union pension funds"},
{c:525120,d: "Employee benefit plans (except pension)"},
{c:525120,d: "Funds, health and welfare"},
{c:525120,d: "Health and welfare funds"},
{c:525120,d: "Plans, health- and welfare-related employee benefit"},
{c:525120,d: "Union health and welfare funds"},
{c:525190,d: "Compensation, workers, insurance funds"},
{c:525190,d: "Funds, self-insurance (except employee benefit funds)"},
{c:525190,d: "Self-insurance funds (except employee benefit funds)"},
{c:525190,d: "Workers' compensation insurance funds"},
{c:525910,d: "Funds, mutual, open-ended"},
{c:525910,d: "Investment funds, open-ended"},
{c:525910,d: "Management investment offices, open-ended"},
{c:525910,d: "Money market mutual funds, open-ended"},
{c:525910,d: "Mutual funds, open-ended"},
{c:525920,d: "Bankruptcy estates"},
{c:525920,d: "Personal estates (i.e., managing assets)"},
{c:525920,d: "Personal investment trusts"},
{c:525920,d: "Personal trusts"},
{c:525920,d: "Private estates (i.e., administering on behalf of beneficiaries)"},
{c:525920,d: "Settlement trust funds"},
{c:525920,d: "Testamentary trusts"},
{c:525920,d: "Trusts, estates, and agency accounts"},
{c:525990,d: "Closed-end investment funds"},
{c:525990,d: "CMOs (collateralized mortgage obligations)"},
{c:525990,d: "Collateralized mortgage obligations (CMOs)"},
{c:525990,d: "Face-amount certificate funds"},
{c:525990,d: "Funds, mutual, closed-end"},
{c:525990,d: "Investment funds, closed-end"},
{c:525990,d: "Money market mutual funds, closed-end"},
{c:525990,d: "Mortgage real estate investment trusts (REITs)"},
{c:525990,d: "Mortgage-backed securities"},
{c:525990,d: "Mutual funds, closed-end"},
{c:525990,d: "Profit-sharing funds"},
{c:525990,d: "Real estate mortgage investment conduits (REMICs)"},
{c:525990,d: "REMICs (real estate mortgage investment conduits)"},
{c:525990,d: "Special purpose financial vehicles"},
{c:525990,d: "Unit investment trust funds"},
{c:531110,d: "Apartment building rental or leasing"},
{c:531110,d: "Apartment hotel rental or leasing"},
{c:531110,d: "Apartment rental or leasing"},
{c:531110,d: "Building, apartment, rental or leasing"},
{c:531110,d: "Building, residential, rental or leasing"},
{c:531110,d: "Cottage rental or leasing"},
{c:531110,d: "Duplex houses (i.e., single-family) rental or leasing"},
{c:531110,d: "Dwelling rental or leasing"},
{c:531110,d: "Equity real estate investment trusts (REITs), primarily leasing residential buildings and dwellings"},
{c:531110,d: "Houses rental or leasing"},
{c:531110,d: "Housing authorities owning and operating residential buildings"},
{c:531110,d: "Lessors of residential buildings and dwellings"},
{c:531110,d: "Mobile (manufactured) home rental or leasing, on-site"},
{c:531110,d: "Real estate rental or leasing of residential building"},
{c:531110,d: "Residential building rental or leasing"},
{c:531110,d: "Residential hotel rental or leasing"},
{c:531110,d: "Retirement hotel rental or leasing"},
{c:531110,d: "Single-family house rental or leasing"},
{c:531110,d: "Town house rental or leasing"},
{c:531120,d: "Amusement facility rental or leasing"},
{c:531120,d: "Arena, no promotion of events, rental or leasing"},
{c:531120,d: "Auditorium rental or leasing"},
{c:531120,d: "Bank building rental or leasing"},
{c:531120,d: "Banquet hall rental or leasing"},
{c:531120,d: "Building, nonresidential (except miniwarehouse), rental or leasing"},
{c:531120,d: "Commercial building rental or leasing"},
{c:531120,d: "Concert hall, no promotion of events, rental or leasing"},
{c:531120,d: "Conference center, no promotion of events, rental or leasing"},
{c:531120,d: "Convention center, no promotion of events, rental or leasing"},
{c:531120,d: "Dance hall rental or leasing"},
{c:531120,d: "Dock and associated building rental or leasing"},
{c:531120,d: "Equity real estate investment trusts (REITs), primarily leasing nonresidential buildings (except miniwarehouses)"},
{c:531120,d: "Executive suites (i.e., full service office space provision)"},
{c:531120,d: "Exhibition hall, no promotion of events, rental or leasing"},
{c:531120,d: "Flea market space, under roof, rental or leasing"},
{c:531120,d: "Full service office space provision"},
{c:531120,d: "Hall and banquet room, nonresidential, rental or leasing"},
{c:531120,d: "Hall, nonresidential, rental or leasing"},
{c:531120,d: "Hotel building rental or leasing, not operating hotel"},
{c:531120,d: "Industrial building rental or leasing"},
{c:531120,d: "Insurance building rental or leasing"},
{c:531120,d: "Lessors of nonresidential buildings (except miniwarehouses)"},
{c:531120,d: "Mall property operation (i.e., not operating contained businesses) rental or leasing"},
{c:531120,d: "Manufacturing building rental or leasing"},
{c:531120,d: "Medical building rental or leasing"},
{c:531120,d: "Meeting hall and room rental or leasing"},
{c:531120,d: "Mobile office building rental or leasing, on-site"},
{c:531120,d: "Motel building rental or leasing, not operating motel"},
{c:531120,d: "Nonresidential building (except miniwarehouse) rental or leasing"},
{c:531120,d: "Office building rental or leasing"},
{c:531120,d: "Piers and associated building rental or leasing"},
{c:531120,d: "Professional office building rental or leasing"},
{c:531120,d: "Real estate rental or leasing of nonresidential building (except miniwarehouse)"},
{c:531120,d: "Reception hall rental or leasing"},
{c:531120,d: "Shopping center (i.e., not operating contained businesses) rental or leasing"},
{c:531120,d: "Stadium rental or leasing without promotion of events"},
{c:531120,d: "Theater property rental or leasing, not operating theater"},
{c:531130,d: "Equity real estate investment trusts (REITs), primarily leasing miniwarehouses and self-storage units"},
{c:531130,d: "Lessors of miniwarehouses"},
{c:531130,d: "Lessors of self-storage units"},
{c:531130,d: "Miniwarehouse rental or leasing"},
{c:531130,d: "Real estate rental or leasing of miniwarehouses and self-storage units"},
{c:531130,d: "Self-storage unit rental or leasing"},
{c:531130,d: "Self-storage warehousing"},
{c:531130,d: "U-lock storage"},
{c:531130,d: "Warehousing, self-storage"},
{c:531190,d: "Agricultural property rental or leasing"},
{c:531190,d: "Airport leasing, not operating airport, rental or leasing"},
{c:531190,d: "Equity real estate investment trusts (REITs), primarily leasing real estate (except residential buildings and dwellings, nonresidential buildings, miniwarehouses, and self-storage units)"},
{c:531190,d: "Farmland rental or leasing"},
{c:531190,d: "Flea market space (except under roof) rental or leasing"},
{c:531190,d: "Forest land rental or leasing"},
{c:531190,d: "Grazing land rental or leasing"},
{c:531190,d: "Land rental or leasing"},
{c:531190,d: "Manufactured (mobile) home parks"},
{c:531190,d: "Manufactured (mobile) home sites rental or leasing"},
{c:531190,d: "Mining property leasing"},
{c:531190,d: "Mobile (manufactured) home parks"},
{c:531190,d: "Mobile (manufactured) home sites rental or leasing"},
{c:531190,d: "Railroad right of way leasing"},
{c:531190,d: "Real estate (except building) rental or leasing"},
{c:531190,d: "Residential trailer parks"},
{c:531190,d: "Trailer park or court, residential"},
{c:531190,d: "Vacant lot rental or leasing"},
{c:531190,d: "Vacation and recreation land rental or leasing"},
{c:531210,d: "Agencies, real estate"},
{c:531210,d: "Agents, real estate"},
{c:531210,d: "Auctioning real estate for others (i.e., agents, brokers)"},
{c:531210,d: "Brokerages, real estate"},
{c:531210,d: "Brokers' offices, real estate"},
{c:531210,d: "Buyers' agents, real estate, offices"},
{c:531210,d: "Buying agencies, real estate"},
{c:531210,d: "Buying real estate for others (i.e., agents, brokers)"},
{c:531210,d: "Commercial real estate agencies"},
{c:531210,d: "Commercial real estate agents' offices"},
{c:531210,d: "Exclusive buyers' agencies"},
{c:531210,d: "Exclusive buyers' agents, offices of"},
{c:531210,d: "Real estate agencies"},
{c:531210,d: "Real estate agents' offices"},
{c:531210,d: "Real estate brokerages"},
{c:531210,d: "Real estate brokers' offices"},
{c:531210,d: "Renting real estate for others (i.e., agents, brokers)"},
{c:531210,d: "Residential real estate agencies"},
{c:531210,d: "Residential real estate agents' offices"},
{c:531210,d: "Residential real estate brokerages"},
{c:531210,d: "Residential real estate brokers' offices"},
{c:531210,d: "Selling real estate for others (i.e., agents, brokers)"},
{c:531210,d: "Selling time-share condominiums for others (i.e., agents, brokers)"},
{c:531311,d: "Apartment managers' offices"},
{c:531311,d: "Condominium managers' offices, residential"},
{c:531311,d: "Cooperative apartment managers' offices"},
{c:531311,d: "Managers' offices, residential condominium"},
{c:531311,d: "Managers' offices, residential real estate"},
{c:531311,d: "Managing cooperative apartments"},
{c:531311,d: "Managing residential condominiums"},
{c:531311,d: "Managing residential real estate"},
{c:531311,d: "Property managers' offices, residential real estate"},
{c:531311,d: "Property managing, residential real estate"},
{c:531311,d: "Real estate property managers' offices, residential"},
{c:531311,d: "Residential property managing"},
{c:531311,d: "Residential real estate property managers' offices"},
{c:531311,d: "Resort or vacation property managers' offices"},
{c:531312,d: "Commercial property managing"},
{c:531312,d: "Commercial real estate property managers' offices"},
{c:531312,d: "Condominium managers' offices, commercial"},
{c:531312,d: "Managers' offices, commercial condominium"},
{c:531312,d: "Managers' offices, commercial real estate"},
{c:531312,d: "Managers' offices, nonresidential real estate"},
{c:531312,d: "Managing commercial condominiums"},
{c:531312,d: "Managing commercial real estate"},
{c:531312,d: "Nonresidential property managing"},
{c:531312,d: "Property managers' offices, commercial real estate"},
{c:531312,d: "Property managers' offices, nonresidential real estate"},
{c:531312,d: "Property managing, commercial real estate"},
{c:531312,d: "Property managing, nonresidential real estate"},
{c:531312,d: "Real estate property managers' offices, commercial"},
{c:531320,d: "Appraisal services, real estate"},
{c:531320,d: "Appraisers' offices, real estate"},
{c:531320,d: "Real estate appraisal services"},
{c:531320,d: "Real estate appraisers' offices"},
{c:531390,d: "Agencies, real estate escrow"},
{c:531390,d: "Agents' offices, real estate escrow"},
{c:531390,d: "Consultants', real estate (except appraisers), offices"},
{c:531390,d: "Escrow agencies, real estate"},
{c:531390,d: "Fiduciaries', real estate, offices"},
{c:531390,d: "Landman services"},
{c:531390,d: "Listing services, real estate"},
{c:531390,d: "Real estate asset management services (except property management)"},
{c:531390,d: "Real estate consultants' (except agents, appraisers) offices"},
{c:531390,d: "Real estate escrow agencies"},
{c:531390,d: "Real estate escrow agents' offices"},
{c:531390,d: "Real estate fiduciaries' offices"},
{c:531390,d: "Real estate listing services"},
{c:532111,d: "Automobile rental"},
{c:532111,d: "Car rental"},
{c:532111,d: "Car rental agencies"},
{c:532111,d: "Hearse rental without driver"},
{c:532111,d: "Limousine rental without driver"},
{c:532111,d: "Luxury automobile rental without driver"},
{c:532111,d: "Passenger car rental"},
{c:532111,d: "Passenger van rental"},
{c:532111,d: "Passenger van rental agencies"},
{c:532111,d: "Sport utility vehicle rental"},
{c:532111,d: "Van (passenger) rental"},
{c:532112,d: "Automobile leasing"},
{c:532112,d: "Car leasing"},
{c:532112,d: "Fleet leasing, passenger vehicle"},
{c:532112,d: "Passenger car leasing"},
{c:532112,d: "Passenger van leasing"},
{c:532112,d: "Passenger vehicle fleet leasing"},
{c:532112,d: "Sport utility vehicle leasing"},
{c:532112,d: "Van (passenger) leasing"},
{c:532120,d: "Bus rental or leasing"},
{c:532120,d: "Camper rental"},
{c:532120,d: "Dump truck rental or leasing without operator"},
{c:532120,d: "Mobile home rental, off-site"},
{c:532120,d: "Motor home rental, off-site"},
{c:532120,d: "Popup camper rental"},
{c:532120,d: "Recreational trailer rental"},
{c:532120,d: "Recreational vehicle (RV) rental or leasing"},
{c:532120,d: "RV (recreational vehicle) rental or leasing"},
{c:532120,d: "School bus rental or leasing"},
{c:532120,d: "Semi-trailer rental or leasing"},
{c:532120,d: "Trailer rental or leasing"},
{c:532120,d: "Truck (except industrial) rental or leasing"},
{c:532120,d: "Truck tractor rental or leasing without driver"},
{c:532120,d: "Utility trailer rental or leasing"},
{c:532120,d: "Van (except passenger) rental or leasing without driver"},
{c:532210,d: "Air-conditioner rental"},
{c:532210,d: "Appliance rental"},
{c:532210,d: "Camcorder rental"},
{c:532210,d: "Consumer electronics rental"},
{c:532210,d: "Dryer, clothes, rental"},
{c:532210,d: "Photographic equipment rental"},
{c:532210,d: "Refrigerator rental"},
{c:532210,d: "Stereo equipment rental"},
{c:532210,d: "Tape recorder rental"},
{c:532210,d: "Television rental"},
{c:532210,d: "Video cassette recorder rental"},
{c:532210,d: "Video disc player rental"},
{c:532210,d: "Video recorder rental"},
{c:532210,d: "Video tape player rental"},
{c:532210,d: "Washer, clothes, rental"},
{c:532281,d: "Bridal wear rental"},
{c:532281,d: "Clothing rental (except industrial launderer, linen supply)"},
{c:532281,d: "Costume rental"},
{c:532281,d: "Dress suit rental"},
{c:532281,d: "Formal wear rental"},
{c:532281,d: "Fur rental"},
{c:532281,d: "Gown rental"},
{c:532281,d: "Graduation cap and gown rental"},
{c:532281,d: "Motion picture wardrobe and costume rental"},
{c:532281,d: "Suit rental"},
{c:532281,d: "Theatrical wardrobe and costume rental"},
{c:532281,d: "Tuxedo rental"},
{c:532281,d: "Wardrobe rental"},
{c:532282,d: "Cassette, prerecorded video, rental"},
{c:532282,d: "Computer video game rental"},
{c:532282,d: "Laser disc, video, rental"},
{c:532282,d: "Video disc rental for home electronic equipment (e.g., DVD)"},
{c:532282,d: "Video game rental"},
{c:532282,d: "Video tape rental for home electronic equipment (e.g., VCR)"},
{c:532282,d: "Video tape rental stores"},
{c:532283,d: "Crutches, invalid, rental"},
{c:532283,d: "Furniture, home health, rental"},
{c:532283,d: "Home health furniture and equipment rental"},
{c:532283,d: "Hospital bed rental and leasing (i.e., home use)"},
{c:532283,d: "Hospital equipment rental (i.e. home use)"},
{c:532283,d: "Hospital furniture and equipment rental (i.e. home use)"},
{c:532283,d: "Invalid equipment rental (i.e., home use)"},
{c:532283,d: "Oxygen equipment rental (i.e., home use)"},
{c:532283,d: "Walker, invalid, rental"},
{c:532283,d: "Wheel chair rental"},
{c:532284,d: "Beach chair rental"},
{c:532284,d: "Beach umbrella rental"},
{c:532284,d: "Bicycle rental"},
{c:532284,d: "Boat rental, pleasure"},
{c:532284,d: "Canoe rental"},
{c:532284,d: "Exercise equipment rental"},
{c:532284,d: "Golf cart rental"},
{c:532284,d: "Houseboat rental"},
{c:532284,d: "Moped rental"},
{c:532284,d: "Motorcycle rental"},
{c:532284,d: "Personal watercraft rental"},
{c:532284,d: "Pleasure boat rental"},
{c:532284,d: "Recreational goods rental"},
{c:532284,d: "Rowboat rental"},
{c:532284,d: "Sailboat rental"},
{c:532284,d: "Ski equipment rental"},
{c:532284,d: "Snow ski equipment rental"},
{c:532284,d: "Sporting goods rental"},
{c:532284,d: "Sports equipment rental"},
{c:532284,d: "Surfboard rental"},
{c:532284,d: "Tent, camping, rental"},
{c:532284,d: "Water ski rental"},
{c:532284,d: "Yacht rental without crew"},
{c:532289,d: "Carpet and rug, residential, rental"},
{c:532289,d: "Furniture (i.e., residential) rental centers"},
{c:532289,d: "Furniture, residential, rental or leasing"},
{c:532289,d: "Musical instrument rental"},
{c:532289,d: "Party (i.e., banquet) equipment rental"},
{c:532289,d: "Party rental supply centers"},
{c:532289,d: "Piano rental"},
{c:532289,d: "Rug and carpet rental"},
{c:532289,d: "Silverware rental"},
{c:532289,d: "Table and banquet accessory rental"},
{c:532289,d: "Tableware rental"},
{c:532289,d: "Tent, party, rental"},
{c:532289,d: "Tool rental or leasing for home use"},
{c:532310,d: "General rental centers"},
{c:532310,d: "Home and garden equipment rental centers"},
{c:532310,d: "Rent-all centers"},
{c:532411,d: "Aircraft rental or leasing without operator"},
{c:532411,d: "Airplane rental or leasing without operator"},
{c:532411,d: "Barge rental or leasing without crew"},
{c:532411,d: "Boat rental (except pleasure) without crew"},
{c:532411,d: "Boat rental or leasing, commercial"},
{c:532411,d: "Railroad car rental or leasing"},
{c:532411,d: "Ship rental or leasing without crew"},
{c:532411,d: "Steamship rental or leasing without crew"},
{c:532411,d: "Tanker (boat) rental or leasing without crew"},
{c:532411,d: "Towboat rental or leasing without crew"},
{c:532411,d: "Tugboat rental or leasing without crew"},
{c:532412,d: "Bulldozer rental or leasing without operator"},
{c:532412,d: "Construction form rental"},
{c:532412,d: "Construction machinery and equipment rental or leasing without operator"},
{c:532412,d: "Crane rental or leasing without operator"},
{c:532412,d: "Earthmoving equipment rental or leasing without operator"},
{c:532412,d: "Forestry machinery and equipment rental or leasing"},
{c:532412,d: "Heavy construction equipment rental without operator"},
{c:532412,d: "Logging equipment rental or leasing without operator"},
{c:532412,d: "Mining machinery and equipment rental or leasing"},
{c:532412,d: "Oil field machinery and equipment rental or leasing"},
{c:532412,d: "Oil well drilling machinery and equipment rental or leasing"},
{c:532412,d: "Welding equipment rental or leasing"},
{c:532412,d: "Well drilling machinery and equipment rental or leasing"},
{c:532420,d: "Cash register rental or leasing"},
{c:532420,d: "Computer peripheral equipment rental or leasing"},
{c:532420,d: "Computer rental or leasing"},
{c:532420,d: "Copier rental or leasing"},
{c:532420,d: "Duplicating machine (e.g., copier) rental or leasing"},
{c:532420,d: "Facsimile machine rental or leasing"},
{c:532420,d: "Fax machine rental or leasing"},
{c:532420,d: "Furniture, office, rental or leasing"},
{c:532420,d: "Mailing equipment rental or leasing"},
{c:532420,d: "Office furniture rental or leasing"},
{c:532420,d: "Office machinery and equipment rental or leasing"},
{c:532490,d: "Agricultural machinery and equipment rental or leasing"},
{c:532490,d: "Audio visual equipment rental or leasing"},
{c:532490,d: "Carpentry equipment rental or leasing"},
{c:532490,d: "Carpet and rug cleaning equipment rental or leasing"},
{c:532490,d: "Compressor, air and gas, rental or leasing"},
{c:532490,d: "Farm equipment rental or leasing"},
{c:532490,d: "Farm tractor rental or leasing"},
{c:532490,d: "Floor sanding machine rental or leasing"},
{c:532490,d: "Floor waxing equipment rental or leasing"},
{c:532490,d: "Furniture, institutional (i.e. public building), rental or leasing"},
{c:532490,d: "Garden tractor rental or leasing"},
{c:532490,d: "Generator rental or leasing"},
{c:532490,d: "Industrial truck rental or leasing"},
{c:532490,d: "Lawnmower rental or leasing"},
{c:532490,d: "Manufacturing machinery and equipment rental or leasing"},
{c:532490,d: "Material handling machinery and equipment rental or leasing"},
{c:532490,d: "Medical equipment (except home health furniture and equipment) rental or leasing"},
{c:532490,d: "Metalworking machinery and equipment rental or leasing"},
{c:532490,d: "Mobile office building rental or leasing, off-site"},
{c:532490,d: "Modular building rental or leasing, off-site"},
{c:532490,d: "Motion picture equipment rental or leasing"},
{c:532490,d: "Pallet rental or leasing"},
{c:532490,d: "Plumbing equipment rental or leasing"},
{c:532490,d: "Power washer rental or leasing"},
{c:532490,d: "Public address system rental or leasing"},
{c:532490,d: "Renting coin-operated amusement devices (except concession operators)"},
{c:532490,d: "Sawmill machinery rental or leasing"},
{c:532490,d: "Scaffolding rental or leasing"},
{c:532490,d: "Scenery, theatrical, rental or leasing"},
{c:532490,d: "Skid rental or leasing"},
{c:532490,d: "Sound and lighting equipment rental or leasing"},
{c:532490,d: "Telecommunications equipment rental or leasing"},
{c:532490,d: "Textile machinery rental or leasing"},
{c:532490,d: "Theatrical equipment (except costumes) rental or leasing"},
{c:532490,d: "Tractor, farm, rental or leasing"},
{c:532490,d: "Tractor, garden, rental or leasing"},
{c:532490,d: "Truck, industrial, rental or leasing"},
{c:532490,d: "TV broadcasting and studio equipment rental or leasing"},
{c:532490,d: "Vending machine rental"},
{c:532490,d: "Woodworking machinery and equipment rental or leasing"},
{c:533110,d: "Brand name licensing"},
{c:533110,d: "Franchise agreements, leasing, selling or licensing, without providing other services"},
{c:533110,d: "Industrial design licensing"},
{c:533110,d: "Oil royalty companies"},
{c:533110,d: "Oil royalty leasing"},
{c:533110,d: "Oil royalty traders (except for own account)"},
{c:533110,d: "Patent buying and licensing"},
{c:533110,d: "Patent leasing"},
{c:533110,d: "Performance rights, licensing of"},
{c:533110,d: "Trademark licensing"},
{c:541110,d: "Attorneys' offices"},
{c:541110,d: "Attorneys' private practices"},
{c:541110,d: "Barristers' offices"},
{c:541110,d: "Barristers' private practices"},
{c:541110,d: "Corporate law offices"},
{c:541110,d: "Counselors' at law offices"},
{c:541110,d: "Counselors' at law private practices"},
{c:541110,d: "Criminal law offices"},
{c:541110,d: "Estate law offices"},
{c:541110,d: "Family law offices"},
{c:541110,d: "Law firms"},
{c:541110,d: "Law offices"},
{c:541110,d: "Law practices"},
{c:541110,d: "Lawyers' offices"},
{c:541110,d: "Lawyers' private practices"},
{c:541110,d: "Legal aid services"},
{c:541110,d: "Patent attorneys' offices"},
{c:541110,d: "Patent attorneys' private practices"},
{c:541110,d: "Real estate law offices"},
{c:541110,d: "Solicitors' offices, private"},
{c:541110,d: "Solicitors' private practices"},
{c:541110,d: "Tax law attorneys' offices"},
{c:541110,d: "Tax law attorneys' private practices"},
{c:541120,d: "Offices of notaries"},
{c:541191,d: "Settlement offices, real estate"},
{c:541191,d: "Title abstract companies, real estate"},
{c:541191,d: "Title companies, real estate"},
{c:541191,d: "Title search companies, real estate"},
{c:541199,d: "Jury consulting services"},
{c:541199,d: "Notary public services"},
{c:541199,d: "Notary publics' private practices"},
{c:541199,d: "Paralegal services"},
{c:541199,d: "Patent agent services (i.e., patent filing and searching services)"},
{c:541199,d: "Process server services"},
{c:541199,d: "Process serving services"},
{c:541199,d: "Trial consulting services"},
{c:541211,d: "Accountants' (i.e., CPAs) offices, certified public"},
{c:541211,d: "Accountants' (i.e., CPAs) private practices, certified public"},
{c:541211,d: "Accounting (i.e., CPAs) services, certified public"},
{c:541211,d: "Auditing accountants' (i.e., CPAs) offices"},
{c:541211,d: "Auditing accountants' (i.e., CPAs) private practices"},
{c:541211,d: "Auditing services (i.e., CPA services), accounts"},
{c:541211,d: "Auditors' (i.e., CPAs) offices, accounts"},
{c:541211,d: "Auditors' (i.e., CPAs) private practices, accounts"},
{c:541211,d: "Certified accountants' offices"},
{c:541211,d: "Certified public accountants' (CPAs) offices"},
{c:541211,d: "CPAs' (certified public accountants) offices"},
{c:541211,d: "Public accountants' (CPAs) offices, certified"},
{c:541211,d: "Public accountants' (CPAs) private practices, certified"},
{c:541213,d: "Income tax compilation services"},
{c:541213,d: "Income tax return preparation services"},
{c:541213,d: "Tax return preparation services"},
{c:541214,d: "Payroll processing services"},
{c:541214,d: "Talent payment services"},
{c:541219,d: "Accountants' (except CPAs) offices"},
{c:541219,d: "Accountants' (except CPAs) private practices"},
{c:541219,d: "Accounting services (except CPAs)"},
{c:541219,d: "Billing services"},
{c:541219,d: "Bookkeepers' offices"},
{c:541219,d: "Bookkeepers' private practices"},
{c:541219,d: "Bookkeeping services"},
{c:541219,d: "Medical coding services combined with accounting services (except CPA services)"},
{c:541219,d: "Public accountants' (except CPAs) offices"},
{c:541219,d: "Public accountants' (except CPAs) private practices"},
{c:541310,d: "Architects' (except landscape) offices"},
{c:541310,d: "Architects' (except landscape) private practices"},
{c:541310,d: "Architectural (except landscape) consultants' offices"},
{c:541310,d: "Architectural (except landscape) design services"},
{c:541310,d: "Architectural (except landscape) services"},
{c:541310,d: "Building architectural design services"},
{c:541320,d: "Architects' offices, landscape"},
{c:541320,d: "Architects' private practices, landscape"},
{c:541320,d: "Architectural services, landscape"},
{c:541320,d: "City planning services"},
{c:541320,d: "Garden planning services"},
{c:541320,d: "Golf course design services"},
{c:541320,d: "Industrial land use planning services"},
{c:541320,d: "Land use design services"},
{c:541320,d: "Land use planning services"},
{c:541320,d: "Landscape architects' offices"},
{c:541320,d: "Landscape architects' private practices"},
{c:541320,d: "Landscape architectural services"},
{c:541320,d: "Landscape consulting services"},
{c:541320,d: "Landscape design services"},
{c:541320,d: "Landscape planning services"},
{c:541320,d: "Ski area design services"},
{c:541320,d: "Ski area planning services"},
{c:541320,d: "Town planners' offices"},
{c:541320,d: "Town planning services"},
{c:541320,d: "Urban planners' offices"},
{c:541320,d: "Urban planning services"},
{c:541330,d: "Acoustical engineering consulting services"},
{c:541330,d: "Acoustical system engineering design services"},
{c:541330,d: "Boat engineering design services"},
{c:541330,d: "Chemical engineering services"},
{c:541330,d: "Civil engineering services"},
{c:541330,d: "Combustion engineering consulting services"},
{c:541330,d: "Construction engineering services"},
{c:541330,d: "Consulting engineers' offices"},
{c:541330,d: "Consulting engineers' private practices"},
{c:541330,d: "Electrical engineering services"},
{c:541330,d: "Engineering consulting services"},
{c:541330,d: "Engineering design services"},
{c:541330,d: "Engineering services"},
{c:541330,d: "Engineers' offices"},
{c:541330,d: "Engineers' private practices"},
{c:541330,d: "Environmental engineering services"},
{c:541330,d: "Erosion control engineering services"},
{c:541330,d: "Geological engineering services"},
{c:541330,d: "Geophysical engineering services"},
{c:541330,d: "Heating engineering consulting services"},
{c:541330,d: "Industrial engineering services"},
{c:541330,d: "Logging engineering services"},
{c:541330,d: "Marine engineering services"},
{c:541330,d: "Maritime technology engineering services"},
{c:541330,d: "Mechanical engineering services"},
{c:541330,d: "Mining engineering services"},
{c:541330,d: "Petroleum engineering services"},
{c:541330,d: "Traffic engineering consulting services"},
{c:541340,d: "Blueprint drafting services"},
{c:541340,d: "Computer-aided design drafting (CADD) services"},
{c:541340,d: "Drafting services"},
{c:541340,d: "Draftsmen's offices"},
{c:541350,d: "Building inspection bureaus"},
{c:541350,d: "Building inspection services"},
{c:541350,d: "Energy efficiency inspection services"},
{c:541350,d: "Home inspection services"},
{c:541350,d: "Inspection bureaus, building"},
{c:541350,d: "Inspection services, building or home"},
{c:541350,d: "Prepurchase home inspection services"},
{c:541360,d: "Aerial geophysical surveying services"},
{c:541360,d: "Electrical geophysical surveying services"},
{c:541360,d: "Electromagnetic geophysical surveying services"},
{c:541360,d: "Geological surveying services"},
{c:541360,d: "Geophysical mapping services"},
{c:541360,d: "Geophysical surveying services"},
{c:541360,d: "Gravity geophysical surveying services"},
{c:541360,d: "Magnetic geophysical surveying services"},
{c:541360,d: "Mapping services, geophysical"},
{c:541360,d: "Radioactive geophysical surveying services"},
{c:541360,d: "Remote sensing geophysical surveying services"},
{c:541360,d: "Seismic geophysical surveying services"},
{c:541360,d: "Surveying services, geophysical"},
{c:541370,d: "Aerial surveying (except geophysical) services"},
{c:541370,d: "Cadastral surveying services"},
{c:541370,d: "Cartographic surveying services"},
{c:541370,d: "Construction surveying services"},
{c:541370,d: "Geodetic surveying services"},
{c:541370,d: "Geographic information system (GIS) base mapping services"},
{c:541370,d: "Geospatial mapping services"},
{c:541370,d: "Hydrographic mapping services"},
{c:541370,d: "Hydrographic surveying services"},
{c:541370,d: "Land surveying services"},
{c:541370,d: "Mapping (except geophysical) services"},
{c:541370,d: "Photogrammetric mapping services"},
{c:541370,d: "Surveying and mapping services (except geophysical)"},
{c:541370,d: "Topographic mapping services"},
{c:541370,d: "Topographic surveying services"},
{c:541380,d: "Acoustics testing laboratories or services"},
{c:541380,d: "Assaying services"},
{c:541380,d: "Automobile proving and testing grounds"},
{c:541380,d: "Biological (except medical, veterinary) testing laboratories or services"},
{c:541380,d: "Calibration and certification testing laboratories or services"},
{c:541380,d: "Electrical testing laboratories or services"},
{c:541380,d: "Electronic testing laboratories or services"},
{c:541380,d: "Environmental testing laboratories or services"},
{c:541380,d: "Film badge testing (i.e., radiation testing) laboratories or services"},
{c:541380,d: "Fire insurance underwriters' laboratories"},
{c:541380,d: "Food testing laboratories or services"},
{c:541380,d: "Forensic (except medical) laboratories or services"},
{c:541380,d: "Geotechnical testing laboratories or services"},
{c:541380,d: "Hydrostatic testing laboratories or services"},
{c:541380,d: "Industrial testing laboratories or services"},
{c:541380,d: "Laboratory testing (except medical, veterinary) services"},
{c:541380,d: "Mechanical testing laboratories or services"},
{c:541380,d: "Metallurgical testing laboratories or services"},
{c:541380,d: "Nondestructive testing laboratories or services"},
{c:541380,d: "Pollution testing (except automotive emissions testing) services"},
{c:541380,d: "Product testing laboratories or services"},
{c:541380,d: "Radiation dosimetry (i.e., radiation testing) laboratories or services"},
{c:541380,d: "Radiation testing laboratories or services"},
{c:541380,d: "Radiographic testing laboratories or services"},
{c:541380,d: "Radiographing welded joints on pipes and fittings"},
{c:541380,d: "Radiography inspection services"},
{c:541380,d: "Radon testing laboratories or services"},
{c:541380,d: "Seed testing laboratories or services"},
{c:541380,d: "Soil testing laboratories or services"},
{c:541380,d: "Testing laboratories (except medical, veterinary)"},
{c:541380,d: "Thermal testing laboratories or services"},
{c:541380,d: "Vibration testing laboratories or services"},
{c:541380,d: "X-ray inspection services"},
{c:541410,d: "Decorating consulting services, interior"},
{c:541410,d: "Interior decorating consultant services"},
{c:541410,d: "Interior decorating consulting services"},
{c:541410,d: "Interior design consulting services"},
{c:541410,d: "Interior design services"},
{c:541410,d: "Interior designer services"},
{c:541420,d: "Automobile industrial design services"},
{c:541420,d: "Furniture design services"},
{c:541420,d: "Handtool industrial design services"},
{c:541420,d: "Industrial design consulting services"},
{c:541420,d: "Industrial design services"},
{c:541420,d: "Packaging industrial design services"},
{c:541420,d: "Tool industrial design services"},
{c:541430,d: "Art services, commercial"},
{c:541430,d: "Art services, graphic"},
{c:541430,d: "Art studios, commercial"},
{c:541430,d: "Artists, independent commercial"},
{c:541430,d: "Artists, independent graphic"},
{c:541430,d: "Artists, independent medical"},
{c:541430,d: "Commercial art services"},
{c:541430,d: "Commercial artists, independent"},
{c:541430,d: "Commercial illustration services"},
{c:541430,d: "Commercial illustrators, independent"},
{c:541430,d: "Communication design services, visual"},
{c:541430,d: "Corporate identification (i.e., logo) design services"},
{c:541430,d: "Graphic art and related design services"},
{c:541430,d: "Graphic artists, independent"},
{c:541430,d: "Graphic design services"},
{c:541430,d: "Illustrators, independent commercial"},
{c:541430,d: "Medical art services"},
{c:541430,d: "Medical artists, independent"},
{c:541430,d: "Medical illustration services"},
{c:541430,d: "Medical illustrators, independent"},
{c:541430,d: "Silk screen design services"},
{c:541430,d: "Studios, commercial art"},
{c:541490,d: "Clothing design services"},
{c:541490,d: "Costume design services (except independent theatrical costume designers)"},
{c:541490,d: "Fashion design services"},
{c:541490,d: "Fashion designer services"},
{c:541490,d: "Float design services"},
{c:541490,d: "Fur design services"},
{c:541490,d: "Jewelry design services"},
{c:541490,d: "Lighting design services"},
{c:541490,d: "Shoe design services"},
{c:541490,d: "Textile design services"},
{c:541511,d: "Applications software programming services, custom computer"},
{c:541511,d: "Computer program or software development, custom"},
{c:541511,d: "Computer programming services, custom"},
{c:541511,d: "Computer software analysis and design services, custom"},
{c:541511,d: "Computer software programming services, custom"},
{c:541511,d: "Computer software support services, custom"},
{c:541511,d: "Programming services, custom computer"},
{c:541511,d: "Software analysis and design services, custom computer"},
{c:541511,d: "Software programming services, custom computer"},
{c:541511,d: "Web (i.e., Internet) page design services, custom"},
{c:541512,d: "CAD (computer-aided design) systems integration design services"},
{c:541512,d: "CAE (computer-aided engineering) systems integration design services"},
{c:541512,d: "CAM (computer-aided manufacturing) systems integration design services"},
{c:541512,d: "Computer hardware consulting services or consultants"},
{c:541512,d: "Computer software consulting services or consultants"},
{c:541512,d: "Computer systems integration analysis and design services"},
{c:541512,d: "Computer systems integration design consulting services"},
{c:541512,d: "Computer systems integrator services"},
{c:541512,d: "Computer-aided design (CAD) systems integration design services"},
{c:541512,d: "Computer-aided engineering (CAE) systems integration design services"},
{c:541512,d: "Computer-aided manufacturing (CAM) systems integration design services"},
{c:541512,d: "Information management computer systems integration design services"},
{c:541512,d: "Local area network (LAN) computer systems integration design services"},
{c:541512,d: "Network systems integration design services, computer"},
{c:541512,d: "Office automation computer systems integration design services"},
{c:541512,d: "Systems integration design consulting services, computer"},
{c:541512,d: "Systems integration design services, computer"},
{c:541513,d: "Computer systems facilities (i.e., clients' facilities) management and operation services"},
{c:541513,d: "Data processing facilities (i.e., clients' facilities) management and operation services"},
{c:541513,d: "Facilities (i.e., clients' facilities) management and operation services, computer systems or data processing"},
{c:541513,d: "Facilities (i.e., clients' facilities) support services, computer systems or data processing"},
{c:541519,d: "Computer disaster recovery services"},
{c:541519,d: "Software installation services, computer"},
{c:541611,d: "Administrative management consulting services"},
{c:541611,d: "Business management consulting services"},
{c:541611,d: "Business start-up consulting services"},
{c:541611,d: "Financial management consulting (except investment advice) services"},
{c:541611,d: "General management consulting services"},
{c:541611,d: "Medical office management consulting services or consultants"},
{c:541611,d: "Records management consulting services"},
{c:541611,d: "Reorganizational consulting services"},
{c:541611,d: "Site location consulting services"},
{c:541611,d: "Site selection consulting services"},
{c:541611,d: "Strategic planning consulting services"},
{c:541612,d: "Actuarial consulting services (except insurance actuarial services)"},
{c:541612,d: "Benefit consulting services"},
{c:541612,d: "Compensation consulting services"},
{c:541612,d: "Compensation planning services"},
{c:541612,d: "Employee assessment consulting services"},
{c:541612,d: "Employee benefit consulting services"},
{c:541612,d: "Employee compensation consulting services"},
{c:541612,d: "Human resource consulting services"},
{c:541612,d: "Labor relations consulting services"},
{c:541612,d: "Organization development consulting services"},
{c:541612,d: "Personnel management consulting services"},
{c:541613,d: "Customer service management consulting services"},
{c:541613,d: "Marketing consulting services"},
{c:541613,d: "Marketing management consulting services"},
{c:541613,d: "New product development consulting services"},
{c:541613,d: "Sales management consulting services"},
{c:541614,d: "Customs consulting services"},
{c:541614,d: "Efficiency management (i.e., efficiency expert) consulting services"},
{c:541614,d: "Freight rate auditor services"},
{c:541614,d: "Freight rate consulting services"},
{c:541614,d: "Freight traffic consulting services"},
{c:541614,d: "Inventory planning and control management consulting services"},
{c:541614,d: "Logistics and integrated supply chain management consulting services"},
{c:541614,d: "Logistics management consulting services"},
{c:541614,d: "Manufacturing management consulting services"},
{c:541614,d: "Manufacturing operations improvement consulting services"},
{c:541614,d: "Materials management consulting services"},
{c:541614,d: "Operations research consulting services"},
{c:541614,d: "Physical distribution consulting services"},
{c:541614,d: "Production planning and control consulting services"},
{c:541614,d: "Productivity improvement consulting services"},
{c:541614,d: "Tariff rate consulting services"},
{c:541614,d: "Tariff rate information services"},
{c:541614,d: "Transportation management consulting services"},
{c:541618,d: "Telecommunications management consulting services"},
{c:541618,d: "Utilities management consulting services"},
{c:541620,d: "Ecological restoration consulting services"},
{c:541620,d: "Environmental consulting services"},
{c:541620,d: "Environmental reclamation planning services"},
{c:541620,d: "Sanitation consulting services"},
{c:541620,d: "Site remediation consulting services"},
{c:541620,d: "Wetland restoration planning services"},
{c:541690,d: "Agricultural consulting services"},
{c:541690,d: "Agrology consulting services"},
{c:541690,d: "Agronomy consulting services"},
{c:541690,d: "Biological consulting services"},
{c:541690,d: "Building envelope consulting services"},
{c:541690,d: "Chemical consulting services"},
{c:541690,d: "Dairy herd consulting services"},
{c:541690,d: "Economic consulting services"},
{c:541690,d: "Energy consulting services"},
{c:541690,d: "Entomology consulting services"},
{c:541690,d: "Geochemical consulting services"},
{c:541690,d: "Horticultural consulting services"},
{c:541690,d: "Hydrology consulting services"},
{c:541690,d: "Livestock breeding consulting services"},
{c:541690,d: "Motion picture consulting services"},
{c:541690,d: "Nuclear energy consulting services"},
{c:541690,d: "Physics consulting services"},
{c:541690,d: "Radio consulting services"},
{c:541690,d: "Roof consulting services"},
{c:541690,d: "Safety consulting services"},
{c:541690,d: "Security consulting services"},
{c:541690,d: "Waterproof consulting services"},
{c:541713,d: "Nanobiotechnologies research and experimental development laboratories"},
{c:541713,d: "Nanotechnology research and development laboratories or services, all fields of science"},
{c:541714,d: "Biotechnology research and development laboratories or services (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in agriculture (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in bacteriology (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in biology (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in botany (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in chemical sciences (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in entomology (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in environmental science (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in food science (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in genetics (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in health sciences (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in industrial research (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in the medical sciences (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in the physical sciences (except nanobiotechnology research and development)"},
{c:541714,d: "Biotechnology research and development laboratories or services in the veterinary sciences (except nanobiotechnology research and development)"},
{c:541714,d: "Cloning research and experimental development laboratories"},
{c:541714,d: "DNA technologies (e.g., microarrays) research and experimental development laboratories"},
{c:541714,d: "Nucleic acid chemistry research and experimental development laboratories"},
{c:541714,d: "Protein engineering research and experimental development laboratories"},
{c:541714,d: "Recombinant DNA research and experimental development laboratories"},
{c:541715,d: "Aerospace research and development (except prototype production)"},
{c:541715,d: "Agriculture research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Bacteriological research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Biology research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Botany research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Chemical research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Computer and related hardware research and development laboratories or services (except nanotechnology research and development)"},
{c:541715,d: "Dental research and development laboratories or services"},
{c:541715,d: "Electronic research and development laboratories or services (except nanotechnology research and development)"},
{c:541715,d: "Engineering research and development laboratories or services (except nanotechnology research and development)"},
{c:541715,d: "Entomological research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Environmental research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Experimental farms"},
{c:541715,d: "Fisheries research and development laboratories or services"},
{c:541715,d: "Food research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Forestry research and development laboratories or services"},
{c:541715,d: "Genetics research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Geological research and development laboratories or services (except nanotechnology research and development)"},
{c:541715,d: "Guided missile and space vehicle engine research and development"},
{c:541715,d: "Guided missile and space vehicle parts (except engines) research and development"},
{c:541715,d: "Health research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Industrial research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Life sciences research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Mathematics research and development laboratories or services"},
{c:541715,d: "Medical research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Observatories, research institutions"},
{c:541715,d: "Oceanographic research and development laboratories or services"},
{c:541715,d: "Photonics research and development services (except nanotechnology research and development)"},
{c:541715,d: "Physical science research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541715,d: "Physics research and development laboratories or services (except nanotechnology research and development)"},
{c:541715,d: "Veterinary research and development laboratories or services (except biotechnology and nanotechnology research and development)"},
{c:541720,d: "Archeological research and development services"},
{c:541720,d: "Behavioral research and development services"},
{c:541720,d: "Business research and development services"},
{c:541720,d: "Cognitive research and development services"},
{c:541720,d: "Demographic research and development services"},
{c:541720,d: "Economic research and development services"},
{c:541720,d: "Historic and cultural preservation research and development services"},
{c:541720,d: "Humanities research and development services"},
{c:541720,d: "Language research and development services"},
{c:541720,d: "Learning disabilities research and development services"},
{c:541720,d: "Psychology research and development services"},
{c:541720,d: "Social science research and development services"},
{c:541720,d: "Sociological research and development services"},
{c:541720,d: "Sociology research and development services"},
{c:541810,d: "Advertising agencies"},
{c:541810,d: "Advertising agency consulting services"},
{c:541820,d: "Lobbying services"},
{c:541820,d: "Lobbyists' offices"},
{c:541820,d: "Political consulting services"},
{c:541820,d: "Public relations agencies"},
{c:541820,d: "Public relations consulting services"},
{c:541820,d: "Public relations services"},
{c:541830,d: "Media buying agencies"},
{c:541830,d: "Media buying services"},
{c:541840,d: "Advertising media representatives (i.e., independent of media owners)"},
{c:541840,d: "Magazine advertising representatives (i.e., independent of media owners)"},
{c:541840,d: "Media advertising representatives (i.e., independent of media owners)"},
{c:541840,d: "Media representatives (i.e., independent of media owners)"},
{c:541840,d: "Newspaper advertising representatives (i.e., independent of media owners)"},
{c:541840,d: "Publishers' advertising representatives (i.e., independent of media owners)"},
{c:541840,d: "Radio advertising representatives (i.e., independent of media owners)"},
{c:541840,d: "Television advertising representatives (i.e., independent of media owners)"},
{c:541850,d: "Advertising services, indoor or outdoor display"},
{c:541850,d: "Advertising, aerial"},
{c:541850,d: "Billboard display advertising services"},
{c:541850,d: "Bus card advertising services"},
{c:541850,d: "Bus display advertising services"},
{c:541850,d: "Display advertising services"},
{c:541850,d: "Indoor display advertising services"},
{c:541850,d: "Outdoor display advertising services"},
{c:541850,d: "Out-of-home media (i.e., display) advertising services"},
{c:541850,d: "Store display advertising services"},
{c:541850,d: "Subway card display advertising services"},
{c:541850,d: "Taxicab card advertising services"},
{c:541850,d: "Transit advertising services"},
{c:541860,d: "Advertising material preparation services for mailing or other direct distribution"},
{c:541860,d: "Direct mail advertising services"},
{c:541860,d: "Direct mail or other direct distribution advertising campaign services"},
{c:541870,d: "Advertising material (e.g., coupons, flyers, samples) direct distribution services"},
{c:541870,d: "Circular direct distribution services"},
{c:541870,d: "Coupon direct distribution services"},
{c:541870,d: "Directory, telephone, distribution on a contract basis"},
{c:541870,d: "Door-to-door distribution of advertising materials (e.g., coupons, flyers, samples)"},
{c:541870,d: "Electronic marketing services"},
{c:541870,d: "Flyer direct distribution (except direct mail) services"},
{c:541870,d: "Handbill direct distribution services"},
{c:541870,d: "Sample direct distribution services"},
{c:541870,d: "Telephone directory distribution services, door-to-door"},
{c:541890,d: "Advertising specialty (e.g., keychain, magnet, pen) distribution services"},
{c:541890,d: "Demonstration services, merchandise"},
{c:541890,d: "Display lettering services"},
{c:541890,d: "Mannequin decorating services"},
{c:541890,d: "Sign lettering and painting services"},
{c:541890,d: "Welcoming services (i.e., advertising services)"},
{c:541890,d: "Window dressing or trimming services, store"},
{c:541910,d: "Broadcast media rating services"},
{c:541910,d: "Marketing analysis services"},
{c:541910,d: "Marketing research services"},
{c:541910,d: "Opinion research services"},
{c:541910,d: "Political opinion polling services"},
{c:541910,d: "Public opinion polling services"},
{c:541910,d: "Public opinion research services"},
{c:541910,d: "Sampling services, statistical"},
{c:541921,d: "Passport photography services"},
{c:541921,d: "Photography services, portrait (e.g., still, video)"},
{c:541921,d: "Photography studios, portrait"},
{c:541921,d: "Portrait photography services"},
{c:541921,d: "Portrait photography studios"},
{c:541921,d: "School photography (i.e., portrait photography) services"},
{c:541921,d: "Video photography services, portrait"},
{c:541921,d: "Video taping services, special events (e.g., birthdays, weddings)"},
{c:541921,d: "Wedding photography services"},
{c:541922,d: "Commercial photography services"},
{c:541922,d: "Medical photography services"},
{c:541922,d: "Photographers specializing in aerial photography"},
{c:541922,d: "Photography services, commercial"},
{c:541922,d: "Photography studios, commercial"},
{c:541922,d: "Video taping services for legal depositions"},
{c:541930,d: "Interpretation services, language"},
{c:541930,d: "Language interpretation services"},
{c:541930,d: "Language services (e.g., interpretation, sign, translation)"},
{c:541930,d: "Language translation services"},
{c:541930,d: "Sign language services"},
{c:541930,d: "Speech (i.e., language) interpretation services"},
{c:541930,d: "Translation services, language"},
{c:541940,d: "Animal hospitals"},
{c:541940,d: "Disease testing services, veterinary"},
{c:541940,d: "Hospitals, animal"},
{c:541940,d: "Internists' offices, veterinary"},
{c:541940,d: "Laboratory testing services, veterinary"},
{c:541940,d: "Livestock inspecting and testing services, veterinary"},
{c:541940,d: "Livestock veterinary services"},
{c:541940,d: "Pet hospitals"},
{c:541940,d: "Small animal veterinary services"},
{c:541940,d: "Surgeons' offices, veterinary"},
{c:541940,d: "Surgery services, veterinary"},
{c:541940,d: "Testing laboratories, veterinary"},
{c:541940,d: "Testing services for veterinarians"},
{c:541940,d: "Vaccination services, veterinary"},
{c:541940,d: "Veterinarians' offices"},
{c:541940,d: "Veterinarians' practices"},
{c:541940,d: "Veterinary clinics"},
{c:541940,d: "Veterinary services"},
{c:541940,d: "Veterinary services, livestock"},
{c:541940,d: "Veterinary services, pets and other animal specialties"},
{c:541940,d: "Veterinary testing laboratories"},
{c:541990,d: "Appraisal (except real estate) services"},
{c:541990,d: "Arbitration and conciliation services (except by attorney, paralegal)"},
{c:541990,d: "Business brokers (except real estate brokers)"},
{c:541990,d: "Commodity inspection services"},
{c:541990,d: "Construction estimation services"},
{c:541990,d: "Consumer credit counseling services"},
{c:541990,d: "Credit repair (i.e., counseling) services, consumer"},
{c:541990,d: "Electronic communication content verification services"},
{c:541990,d: "Estate assessment (i.e., appraisal) services"},
{c:541990,d: "Fire extinguisher testing and/or inspection, without sales, service, or installation"},
{c:541990,d: "Handwriting analysis services"},
{c:541990,d: "Handwriting expert services"},
{c:541990,d: "Marine forecasting services"},
{c:541990,d: "Marine surveyor (i.e., ship appraiser) services"},
{c:541990,d: "Mediation product services (except by lawyer, attorney, paralegal offices, family and social services)"},
{c:541990,d: "Meteorological services"},
{c:541990,d: "Patent broker services (i.e., patent marketing services)"},
{c:541990,d: "Patrolling (i.e., visual inspection) of electric transmission or gas lines"},
{c:541990,d: "Pipeline inspection (i.e., visual) services"},
{c:541990,d: "Power line inspection (i.e., visual) services"},
{c:541990,d: "Quantity surveyor services"},
{c:541990,d: "Waterless fire suppression system testing and/or inspection, without sales, service, or installation"},
{c:541990,d: "Weather forecasting services"},
{c:551111,d: "Bank holding companies (except managing)"},
{c:551111,d: "Holding companies, bank (except managing)"},
{c:551111,d: "Offices of bank holding companies"},
{c:551112,d: "Agreement corporation (except international trade financing)"},
{c:551112,d: "Financial holding companies"},
{c:551112,d: "Holding companies (except bank, managing)"},
{c:551112,d: "Insurance holding companies"},
{c:551112,d: "Nonbank holding companies (except managing)"},
{c:551112,d: "Personal holding companies"},
{c:551112,d: "Public utility holding companies"},
{c:551112,d: "Railroad holding companies"},
{c:551112,d: "Savings and loan holding companies"},
{c:551112,d: "Thrift holding companies"},
{c:551114,d: "Centralized administrative offices"},
{c:551114,d: "Corporate offices"},
{c:551114,d: "District and regional offices"},
{c:551114,d: "Head offices"},
{c:551114,d: "Headquarters offices"},
{c:551114,d: "Holding companies that manage"},
{c:551114,d: "Subsidiary management offices"},
{c:561110,d: "Administrative management services"},
{c:561110,d: "Business management services"},
{c:561110,d: "Executive management services"},
{c:561110,d: "Hotel management services (except complete operation of client's business)"},
{c:561110,d: "Industrial management services"},
{c:561110,d: "Management services (except complete operation of client's business)"},
{c:561110,d: "Managing offices of dentists"},
{c:561110,d: "Managing offices of physicians and surgeons"},
{c:561110,d: "Managing offices of professionals (e.g., dentists, physicians, surgeons)"},
{c:561110,d: "Medical office management services"},
{c:561110,d: "Motel management services (except complete operation of client's business)"},
{c:561110,d: "Office administration services"},
{c:561110,d: "Office management services"},
{c:561210,d: "Base facilities operation support services"},
{c:561210,d: "Correctional facilities, privately operated"},
{c:561210,d: "Correctional facility operation on a contract or fee basis"},
{c:561210,d: "Facilities (except computer operation) support services"},
{c:561210,d: "Government base facilities operation support services"},
{c:561210,d: "Jail operation on a contract or fee basis"},
{c:561210,d: "Jails, privately operated"},
{c:561210,d: "Military base support services"},
{c:561311,d: "Babysitting bureaus (i.e., registries)"},
{c:561311,d: "Casting agencies (i.e., motion picture, theatrical, video)"},
{c:561311,d: "Casting agencies, motion picture or video"},
{c:561311,d: "Casting agencies, theatrical"},
{c:561311,d: "Casting bureaus (e.g., motion picture, theatrical, video)"},
{c:561311,d: "Casting bureaus, motion picture or video"},
{c:561311,d: "Casting bureaus, theatrical"},
{c:561311,d: "Chauffeur registries"},
{c:561311,d: "Employment agencies"},
{c:561311,d: "Employment agencies, motion picture or video"},
{c:561311,d: "Employment agencies, radio or television"},
{c:561311,d: "Employment agencies, theatrical"},
{c:561311,d: "Employment placement agencies or services"},
{c:561311,d: "Employment referral agencies or services"},
{c:561311,d: "Employment registries"},
{c:561311,d: "Internet job listing services"},
{c:561311,d: "Internet resume listing services"},
{c:561311,d: "Maid registries"},
{c:561311,d: "Model registries"},
{c:561311,d: "Nurse registries"},
{c:561311,d: "Placement agencies or services, employment"},
{c:561311,d: "Referral agencies or services, employment"},
{c:561311,d: "Registries, employment"},
{c:561311,d: "Registries, teacher"},
{c:561311,d: "Ship crew employment agencies"},
{c:561311,d: "Ship crew registries"},
{c:561311,d: "State operated employment job services offices"},
{c:561311,d: "Teacher registries"},
{c:561311,d: "Television employment agencies"},
{c:561311,d: "Theatrical employment agencies"},
{c:561312,d: "Executive placement consulting services"},
{c:561312,d: "Executive placement services"},
{c:561312,d: "Executive search consulting services"},
{c:561312,d: "Executive search services"},
{c:561312,d: "Senior executive search services"},
{c:561320,d: "Contract staffing services"},
{c:561320,d: "Help supply services"},
{c:561320,d: "Labor (except farm) contractors (i.e., personnel suppliers)"},
{c:561320,d: "Labor (except farm) pools"},
{c:561320,d: "Manpower pools"},
{c:561320,d: "Model supply services"},
{c:561320,d: "Office help supply services"},
{c:561320,d: "Office supply pools"},
{c:561320,d: "Outplacement consulting services"},
{c:561320,d: "Outplacement services"},
{c:561320,d: "Personnel (e.g., industrial, office) suppliers"},
{c:561320,d: "Temporary employment services"},
{c:561320,d: "Temporary help services"},
{c:561320,d: "Temporary staffing services"},
{c:561330,d: "Co-employment staffing services"},
{c:561330,d: "Employee leasing services"},
{c:561330,d: "Labor leasing services"},
{c:561330,d: "PEO (professional employer organizations)"},
{c:561330,d: "Professional employer organizations (PEO)"},
{c:561330,d: "Staff leasing services"},
{c:561410,d: "Desktop publishing services (i.e., document preparation services)"},
{c:561410,d: "Dictation services"},
{c:561410,d: "Document preparation services"},
{c:561410,d: "Document transcription services"},
{c:561410,d: "Editing services"},
{c:561410,d: "Letter writing services"},
{c:561410,d: "Proofreading services"},
{c:561410,d: "Radio transcription services"},
{c:561410,d: "Resume writing services"},
{c:561410,d: "Secretarial services"},
{c:561410,d: "Stenographic services (except court or stenographic reporting)"},
{c:561410,d: "Technical editing services"},
{c:561410,d: "Transcription services"},
{c:561410,d: "Typing services"},
{c:561410,d: "Word processing services"},
{c:561421,d: "Answering services, telephone"},
{c:561421,d: "Emergency telephone dispatch (i.e., contractor) services"},
{c:561421,d: "Local call centers"},
{c:561421,d: "Message services, telephone answering"},
{c:561421,d: "Telephone answering services"},
{c:561421,d: "Telephone call forwarding services"},
{c:561421,d: "Voice mailbox services"},
{c:561421,d: "Wakeup call services"},
{c:561422,d: "Customer service call centers"},
{c:561422,d: "Floral wire services (i.e., telemarketing services)"},
{c:561422,d: "On-line customer service centers"},
{c:561422,d: "Order-taking for clients over the Internet"},
{c:561422,d: "Telemarketing bureaus"},
{c:561422,d: "Telemarketing services on a contract or fee basis"},
{c:561422,d: "Telephone call centers"},
{c:561422,d: "Telephone solicitation services on a contract or fee basis"},
{c:561422,d: "Wire services (i.e., telemarketing services), floral"},
{c:561431,d: "Mailbox rental centers, private"},
{c:561431,d: "Mailbox rental services combined with one or more other office support services, private"},
{c:561431,d: "Parcel mailing services combined with one or more other office support services, private"},
{c:561431,d: "Parcel mailing services, private"},
{c:561431,d: "Private mail centers"},
{c:561431,d: "Private mailbox rental centers"},
{c:561439,d: "Blueprinting services"},
{c:561439,d: "Business service centers (except private mail centers)"},
{c:561439,d: "Business service centers (except private mail centers) providing range of office support services (except printing)"},
{c:561439,d: "Copy centers (except combined with printing services)"},
{c:561439,d: "Copy shops (except combined with printing services)"},
{c:561439,d: "Document copying services (except combined with printing services)"},
{c:561439,d: "Document duplicating services (except combined with printing services)"},
{c:561439,d: "Internet cafes (i.e., not serving food and beverages)"},
{c:561439,d: "Photocopying services (except combined with printing services)"},
{c:561439,d: "Reprographic services"},
{c:561440,d: "Account collection services"},
{c:561440,d: "Adjustment agencies (except insurance)"},
{c:561440,d: "Bill collection services"},
{c:561440,d: "Collection agencies"},
{c:561440,d: "Collection agencies, accounts"},
{c:561440,d: "Credit arrears collection services"},
{c:561440,d: "Debt collection services"},
{c:561440,d: "Delinquent account collection services"},
{c:561440,d: "Tax collection services on a contract or fee basis"},
{c:561450,d: "Commercial credit reporting bureaus"},
{c:561450,d: "Consumer credit reporting bureaus"},
{c:561450,d: "Credit agencies"},
{c:561450,d: "Credit bureaus"},
{c:561450,d: "Credit clearinghouses"},
{c:561450,d: "Credit investigation services"},
{c:561450,d: "Credit rating services"},
{c:561450,d: "Credit reporting bureaus"},
{c:561450,d: "Investigation services, credit"},
{c:561450,d: "Mercantile credit reporting bureaus"},
{c:561491,d: "Automobile repossession services"},
{c:561491,d: "Repossession services"},
{c:561492,d: "Closed captioning services, real-time (i.e., simultaneous)"},
{c:561492,d: "Court reporting services"},
{c:561492,d: "Deposition services"},
{c:561492,d: "Public stenography services"},
{c:561492,d: "Real-time (i.e., simultaneous) closed captioning of live television performances, meetings, conferences, and so forth"},
{c:561492,d: "Stenography services, public"},
{c:561492,d: "Stenotype recording services"},
{c:561499,d: "Address bar coding services"},
{c:561499,d: "Bar code imprinting services"},
{c:561499,d: "Fundraising campaign organization services on a contract or fee basis"},
{c:561499,d: "Mail consolidation services"},
{c:561499,d: "Mail presorting services"},
{c:561499,d: "Teleconferencing services"},
{c:561499,d: "Videoconferencing services"},
{c:561510,d: "Travel agencies"},
{c:561510,d: "Travel management services"},
{c:561520,d: "Tour operators (i.e., arranging and assembling tours)"},
{c:561520,d: "Travel tour operators"},
{c:561520,d: "Wholesale tour operators"},
{c:561591,d: "Convention and visitors bureaus"},
{c:561591,d: "Convention bureaus"},
{c:561591,d: "Tourism bureaus"},
{c:561591,d: "Tourist information bureaus"},
{c:561591,d: "Visitor information centers"},
{c:561591,d: "Visitors bureaus"},
{c:561599,d: "Airline reservation services"},
{c:561599,d: "Airline ticket offices"},
{c:561599,d: "Automobile clubs, road and travel services"},
{c:561599,d: "Booking (e.g., airline, car rental, hotel, restaurant) services"},
{c:561599,d: "Bus ticket offices"},
{c:561599,d: "Car rental reservation services"},
{c:561599,d: "Concert ticket offices"},
{c:561599,d: "Condominium time-share exchange services"},
{c:561599,d: "Cruise reservation services"},
{c:561599,d: "Cruise ship ticket offices"},
{c:561599,d: "Hotel reservation services"},
{c:561599,d: "Motor travel clubs"},
{c:561599,d: "Performing arts ticket offices"},
{c:561599,d: "Railroad ticket offices"},
{c:561599,d: "Reservation (e.g., airline, car rental, hotel, restaurant) services"},
{c:561599,d: "Sports ticket offices"},
{c:561599,d: "Theatrical ticket offices"},
{c:561599,d: "Ticket (e.g., airline, bus, cruise ship, sports, theatrical) offices"},
{c:561599,d: "Ticket (e.g., airline, bus, cruise ship, sports, theatrical) sales offices"},
{c:561599,d: "Ticket (e.g., amusement, sports, theatrical) agencies"},
{c:561599,d: "Ticket (e.g., amusement, sports, theatrical) sales agencies"},
{c:561599,d: "Ticket agencies, amusement"},
{c:561599,d: "Ticket agencies, sports"},
{c:561599,d: "Ticket agencies, theatrical"},
{c:561599,d: "Ticket offices for foreign cruise ship companies"},
{c:561599,d: "Time-share exchange services, condominium"},
{c:561611,d: "Background check services"},
{c:561611,d: "Bounty hunting services"},
{c:561611,d: "Detective agencies"},
{c:561611,d: "Fingerprint services"},
{c:561611,d: "Investigation services (except credit), private"},
{c:561611,d: "Investigators, private"},
{c:561611,d: "Lie detection services"},
{c:561611,d: "Missing person tracing services"},
{c:561611,d: "Polygraph services"},
{c:561611,d: "Private detective services"},
{c:561611,d: "Private investigation services (except credit)"},
{c:561611,d: "Skip tracing services"},
{c:561612,d: "Body guard services"},
{c:561612,d: "Guard dog services"},
{c:561612,d: "Guard services"},
{c:561612,d: "Parking security services"},
{c:561612,d: "Patrol services, security"},
{c:561612,d: "Personal protection services (except security systems services)"},
{c:561612,d: "Property protection services (except armored car, security systems)"},
{c:561612,d: "Protection services (except armored car, security systems), personal or property"},
{c:561612,d: "Protective guard services"},
{c:561612,d: "Security guard services"},
{c:561612,d: "Security patrol services"},
{c:561613,d: "Armored car services"},
{c:561621,d: "Alarm system monitoring services"},
{c:561621,d: "Alarm systems sales combined with installation, repair, or monitoring services"},
{c:561621,d: "Burglar alarm monitoring services"},
{c:561621,d: "Burglar alarm sales combined with installation, repair, or monitoring services"},
{c:561621,d: "Fire alarm monitoring services"},
{c:561621,d: "Fire alarm sales combined with installation, repair, or monitoring services"},
{c:561621,d: "Security alarm systems sales combined with installation, repair, or monitoring services"},
{c:561621,d: "Security system monitoring services"},
{c:561622,d: "Lock rekeying services"},
{c:561622,d: "Locksmith services"},
{c:561622,d: "Locksmith services with or without sales of locking devices, safes, and security vaults"},
{c:561622,d: "Locksmith shops"},
{c:561710,d: "Bird proofing services"},
{c:561710,d: "Exterminating services"},
{c:561710,d: "Fumigating services (except crop fumigating)"},
{c:561710,d: "Mosquito eradication services"},
{c:561710,d: "Pest (e.g., termite) inspection services"},
{c:561710,d: "Pest control (except agricultural, forestry) services"},
{c:561710,d: "Termite control services"},
{c:561720,d: "Aircraft janitorial services"},
{c:561720,d: "Building cleaning services, interior"},
{c:561720,d: "Building cleaning services, janitorial"},
{c:561720,d: "Cleaning homes"},
{c:561720,d: "Cleaning offices"},
{c:561720,d: "Cleaning shopping centers"},
{c:561720,d: "Custodial services"},
{c:561720,d: "Deodorant servicing of rest rooms"},
{c:561720,d: "Deodorizing services"},
{c:561720,d: "Disinfecting services"},
{c:561720,d: "Housekeeping services (i.e., cleaning services)"},
{c:561720,d: "Janitorial services"},
{c:561720,d: "Janitorial services, aircraft"},
{c:561720,d: "Maid services (i.e., cleaning services)"},
{c:561720,d: "Office cleaning services"},
{c:561720,d: "Residential cleaning services"},
{c:561720,d: "Rest room cleaning services"},
{c:561720,d: "Restaurant kitchen cleaning services"},
{c:561720,d: "Service station cleaning and degreasing services"},
{c:561720,d: "Venetian blind cleaning services"},
{c:561720,d: "Washroom sanitation services"},
{c:561720,d: "Window cleaning services"},
{c:561730,d: "Arborist services"},
{c:561730,d: "Cemetery plot care services"},
{c:561730,d: "Fertilizing lawns"},
{c:561730,d: "Garden maintenance services"},
{c:561730,d: "Hydroseeding services (e.g., decorative, erosion control purposes)"},
{c:561730,d: "Interior landscaping services"},
{c:561730,d: "Landscape care and maintenance services"},
{c:561730,d: "Landscape contractors (except construction)"},
{c:561730,d: "Landscape installation services"},
{c:561730,d: "Landscaping services (except planning)"},
{c:561730,d: "Lawn care services (e.g., fertilizing, mowing, seeding, spraying)"},
{c:561730,d: "Lawn fertilizing services"},
{c:561730,d: "Lawn maintenance services"},
{c:561730,d: "Lawn mowing services"},
{c:561730,d: "Lawn mulching services"},
{c:561730,d: "Lawn seeding services"},
{c:561730,d: "Lawn spraying services"},
{c:561730,d: "Line slash (i.e., rights of way) maintenance services"},
{c:561730,d: "Maintenance of plants and shrubs in buildings"},
{c:561730,d: "Mowing services (e.g., highway, lawn, road strip)"},
{c:561730,d: "Ornamental tree and shrub services"},
{c:561730,d: "Plant and shrub maintenance in buildings"},
{c:561730,d: "Plant maintenance services"},
{c:561730,d: "Pruning services, ornamental tree and shrub"},
{c:561730,d: "Seasonal property maintenance services (i.e., snow plowing in winter, landscaping during other seasons)"},
{c:561730,d: "Seeding lawns"},
{c:561730,d: "Shrub services (e.g., bracing, planting, pruning, removal, spraying, surgery, trimming)"},
{c:561730,d: "Snow plowing services combined with landscaping services (i.e., seasonal property maintenance services)"},
{c:561730,d: "Sod laying services"},
{c:561730,d: "Spraying lawns"},
{c:561730,d: "Tree and brush trimming, overhead utility line"},
{c:561730,d: "Tree pruning services"},
{c:561730,d: "Tree removal services"},
{c:561730,d: "Tree services (e.g., bracing, planting, pruning, removal, spraying, surgery, trimming)"},
{c:561730,d: "Tree surgery services"},
{c:561730,d: "Tree trimming services"},
{c:561730,d: "Tropical plant maintenance services"},
{c:561730,d: "Turf (except artificial) installation services"},
{c:561730,d: "Weed control and fertilizing services (except crop)"},
{c:561740,d: "Carpet cleaning on customers' premises"},
{c:561740,d: "Carpet cleaning plants"},
{c:561740,d: "Carpet cleaning services"},
{c:561740,d: "Cleaning carpets"},
{c:561740,d: "Cleaning plants, carpet and rug"},
{c:561740,d: "Cleaning rugs"},
{c:561740,d: "Cleaning services, carpet and rug"},
{c:561740,d: "Furniture cleaning on customers' premises"},
{c:561740,d: "Furniture cleaning services"},
{c:561740,d: "Rug cleaning plants"},
{c:561740,d: "Rug cleaning services"},
{c:561740,d: "Upholstery cleaning on customers' premises"},
{c:561740,d: "Upholstery cleaning services"},
{c:561790,d: "Building exterior cleaning services (except sandblasting, window cleaning)"},
{c:561790,d: "Building gas systems conversion (e.g., from manufactured to natural gas) services"},
{c:561790,d: "Chimney cleaning services"},
{c:561790,d: "Chimney sweep (i.e., cleaning) services"},
{c:561790,d: "Cleaning (e.g., power sweeping, washing) driveways and parking lots"},
{c:561790,d: "Cleaning building exteriors (except sandblasting, window cleaning)"},
{c:561790,d: "Cleaning swimming pools"},
{c:561790,d: "Drain cleaning services"},
{c:561790,d: "Driveway cleaning (e.g., power sweeping, washing) services"},
{c:561790,d: "Duct cleaning services"},
{c:561790,d: "Gutter cleaning services"},
{c:561790,d: "Lighting maintenance services (e.g., bulb and fuse replacement and cleaning)"},
{c:561790,d: "Parking lot cleaning (e.g., power sweeping, washing) services"},
{c:561790,d: "Power washing building exteriors"},
{c:561790,d: "Pressure washing (e.g., buildings, decks, fences)"},
{c:561790,d: "Snow plowing driveways and parking lots (i.e., not combined with any other service)"},
{c:561790,d: "Steam cleaning building exteriors"},
{c:561790,d: "Swimming pool cleaning and maintenance services"},
{c:561790,d: "Ventilation duct cleaning services"},
{c:561910,d: "Apparel folding and packaging services"},
{c:561910,d: "Blister packaging services"},
{c:561910,d: "Cosmetic kit assembling and packaging services"},
{c:561910,d: "Folding and packaging services, textile and apparel"},
{c:561910,d: "Gift wrapping services"},
{c:561910,d: "Kit assembling and packaging services"},
{c:561910,d: "Labeling services"},
{c:561910,d: "Mounting merchandise on cards"},
{c:561910,d: "Packaging services (except packing and crating for transportation)"},
{c:561910,d: "Parcel packing services"},
{c:561910,d: "Product sterilization and packaging services"},
{c:561910,d: "Shrink wrapping services"},
{c:561910,d: "Skin blister packaging services"},
{c:561910,d: "Textile folding and packaging services"},
{c:561920,d: "Automobile show managers"},
{c:561920,d: "Automobile show organizers"},
{c:561920,d: "Automobile show promoters"},
{c:561920,d: "Convention decorators"},
{c:561920,d: "Convention managers"},
{c:561920,d: "Convention or trade show event planners"},
{c:561920,d: "Convention organizers"},
{c:561920,d: "Convention promoters"},
{c:561920,d: "Convention services"},
{c:561920,d: "Craft fair managers"},
{c:561920,d: "Craft fair organizers"},
{c:561920,d: "Craft fair promoters"},
{c:561920,d: "Event and meeting planning services"},
{c:561920,d: "Flower show managers"},
{c:561920,d: "Flower show organizers"},
{c:561920,d: "Flower show promoters"},
{c:561920,d: "Home show managers"},
{c:561920,d: "Home show organizers"},
{c:561920,d: "Home show promoters"},
{c:561920,d: "Managers, convention"},
{c:561920,d: "Managers, trade fair or show"},
{c:561920,d: "Promoters of conventions with or without facilities"},
{c:561920,d: "Promoters of trade fairs or shows with or without facilities"},
{c:561920,d: "Trade fair managers"},
{c:561920,d: "Trade fair organizers"},
{c:561920,d: "Trade fair promoters"},
{c:561920,d: "Trade show managers"},
{c:561920,d: "Trade show organizers"},
{c:561920,d: "Trade show promoters"},
{c:561990,d: "Auctioneers, independent"},
{c:561990,d: "Bartering services"},
{c:561990,d: "Bottle exchanges"},
{c:561990,d: "Cloth cutting, bolting, or winding for the trade"},
{c:561990,d: "Coin pick-up services, parking meter"},
{c:561990,d: "Coupon processing services"},
{c:561990,d: "Coupon redemption services (i.e., clearinghouse)"},
{c:561990,d: "Diving services on a contract or fee basis"},
{c:561990,d: "Document shredding services"},
{c:561990,d: "Electrical meter reading services, contract"},
{c:561990,d: "Firefighting services as a commercial activity"},
{c:561990,d: "Flagging (i.e., traffic control) services"},
{c:561990,d: "Float decorating services"},
{c:561990,d: "Gas meter reading services, contract"},
{c:561990,d: "Inventory computing services"},
{c:561990,d: "Inventory taking services"},
{c:561990,d: "License issuing services (except government), motor vehicle"},
{c:561990,d: "Locating underground utility lines prior to digging"},
{c:561990,d: "Lumber grading services"},
{c:561990,d: "Meter reading services, contract"},
{c:561990,d: "Motor vehicle license issuing services, private franchise"},
{c:561990,d: "Museum cataloging services"},
{c:561990,d: "Printing brokers"},
{c:561990,d: "Private volunteer firefighting"},
{c:561990,d: "Tape slitting (e.g., cutting plastic or leather into widths) for the trade"},
{c:561990,d: "Textile cutting services"},
{c:561990,d: "Trading stamp promotion and sale to stores"},
{c:561990,d: "Trading stamp redemption services"},
{c:561990,d: "Water softening and conditioning services"},
{c:562111,d: "Ash collection services"},
{c:562111,d: "Ash hauling, local"},
{c:562111,d: "Garbage collection services"},
{c:562111,d: "Garbage hauling, local"},
{c:562111,d: "Garbage pick-up services"},
{c:562111,d: "Recyclable material collection services"},
{c:562111,d: "Recyclable material hauling, local"},
{c:562111,d: "Refuse collection services"},
{c:562111,d: "Refuse hauling, local"},
{c:562111,d: "Rubbish (i.e., nonhazardous solid waste) hauling, local"},
{c:562111,d: "Rubbish collection services"},
{c:562111,d: "Trash collection services"},
{c:562111,d: "Trash hauling, local"},
{c:562111,d: "Waste collection services, nonhazardous solid"},
{c:562111,d: "Waste hauling, local, nonhazardous solid"},
{c:562111,d: "Waste transfer stations, nonhazardous solid"},
{c:562112,d: "Hazardous waste collection services"},
{c:562112,d: "Radioactive waste collecting and/or local hauling"},
{c:562112,d: "Waste collection services, hazardous"},
{c:562112,d: "Waste hauling, local, hazardous"},
{c:562112,d: "Waste transfer stations, hazardous"},
{c:562119,d: "Brush collection services"},
{c:562119,d: "Brush hauling, local"},
{c:562119,d: "Brush removal services"},
{c:562119,d: "Debris removal services"},
{c:562119,d: "Dump trucking of rubble or brush with collection or disposal"},
{c:562119,d: "Rubble hauling, local"},
{c:562119,d: "Rubble removal services"},
{c:562119,d: "Waste (except solid and hazardous) collection services"},
{c:562119,d: "Waste (except solid and hazardous) hauling, local"},
{c:562211,d: "Acid waste disposal facilities"},
{c:562211,d: "Acid waste treatment facilities"},
{c:562211,d: "Combustors, hazardous waste"},
{c:562211,d: "Hazardous waste disposal facilities"},
{c:562211,d: "Hazardous waste disposal facilities combined with collection and/or local hauling of hazardous waste"},
{c:562211,d: "Hazardous waste material disposal facilities"},
{c:562211,d: "Hazardous waste material treatment facilities"},
{c:562211,d: "Hazardous waste treatment facilities"},
{c:562211,d: "Hazardous waste treatment facilities combined with collection and/or local hauling of hazardous waste"},
{c:562211,d: "Incinerators, hazardous waste, operating"},
{c:562211,d: "Medical waste treatment facilities, hazardous"},
{c:562211,d: "Radioactive waste collecting and/or local hauling in combination with disposal and/or treatment facilities"},
{c:562211,d: "Radioactive waste disposal facilities"},
{c:562211,d: "Radioactive waste treatment facilities"},
{c:562211,d: "Waste disposal facilities, hazardous"},
{c:562211,d: "Waste treatment facilities, hazardous"},
{c:562211,d: "Waste treatment plants, hazardous"},
{c:562212,d: "Dumps, nonhazardous solid waste (e.g., trash)"},
{c:562212,d: "Garbage disposal landfills"},
{c:562212,d: "Garbage dumps"},
{c:562212,d: "Landfills"},
{c:562212,d: "Refuse collecting and operating solid waste landfills"},
{c:562212,d: "Refuse disposal landfills"},
{c:562212,d: "Rubbish disposal landfills"},
{c:562212,d: "Sanitary landfills"},
{c:562212,d: "Sludge disposal sites"},
{c:562212,d: "Solid waste landfills combined with collection and/or local hauling of nonhazardous waste materials"},
{c:562212,d: "Solid waste landfills, nonhazardous"},
{c:562212,d: "Trash disposal landfills"},
{c:562212,d: "Waste disposal landfills, nonhazardous solid"},
{c:562213,d: "Combustors, nonhazardous solid waste"},
{c:562213,d: "Garbage disposal combustors or incinerators"},
{c:562213,d: "Incinerators, nonhazardous solid waste"},
{c:562213,d: "Power generation, nonhazardous solid waste combustor or incinerator electric"},
{c:562213,d: "Refuse disposal combustors or incinerators"},
{c:562213,d: "Rubbish disposal combustors or incinerators"},
{c:562213,d: "Solid waste combustors or incinerators, nonhazardous"},
{c:562213,d: "Trash disposal combustors or incinerators"},
{c:562213,d: "Waste disposal combustors or incinerators, nonhazardous solid"},
{c:562219,d: "Compost dumps"},
{c:562219,d: "Dumps, compost"},
{c:562219,d: "Lawn waste disposal facilities"},
{c:562219,d: "Nonhazardous waste treatment and disposal facilities (except combustors, incinerators, landfills, sewer systems, sewage treatment facilities)"},
{c:562219,d: "Waste (except sewage) treatment facilities, nonhazardous"},
{c:562910,d: "Asbestos abatement services"},
{c:562910,d: "Asbestos removal contractors"},
{c:562910,d: "Biohazard cleanup services"},
{c:562910,d: "Crime scene cleanup services"},
{c:562910,d: "Environmental remediation services"},
{c:562910,d: "Hazardous material storage tank removal and disposal services"},
{c:562910,d: "Lead paint abatement services"},
{c:562910,d: "Lead paint removal contractors"},
{c:562910,d: "Mine reclamation services, integrated (e.g., demolition, hazardous material removal, soil remediation, revegetation)"},
{c:562910,d: "Mold remediation services"},
{c:562910,d: "Oil spill cleanup services"},
{c:562910,d: "Remediation and cleanup of contaminated buildings, mine sites, soil, or ground water"},
{c:562910,d: "Remediation services, environmental"},
{c:562910,d: "Site remediation services"},
{c:562910,d: "Soil remediation services"},
{c:562910,d: "Toxic material abatement services"},
{c:562910,d: "Toxic material removal contractors"},
{c:562920,d: "Materials recovery facilities (MRF)"},
{c:562920,d: "MRF (materials recovery facilities)"},
{c:562920,d: "Recyclable materials recovery facilities"},
{c:562920,d: "Removal of recyclable materials from a waste stream"},
{c:562920,d: "Waste recovery facilities"},
{c:562991,d: "Cesspool cleaning services"},
{c:562991,d: "Portable toilet pumping (i.e., cleaning) services"},
{c:562991,d: "Portable toilet renting and/or servicing"},
{c:562991,d: "Pumping (i.e., cleaning) cesspools and septic tanks"},
{c:562991,d: "Pumping (i.e., cleaning) portable toilets"},
{c:562991,d: "Septic tank cleaning services"},
{c:562991,d: "Septic tank pumping (i.e., cleaning) services"},
{c:562991,d: "Tank cleaning services, septic"},
{c:562991,d: "Toilet renting and/or servicing, portable"},
{c:562998,d: "Beach maintenance and cleaning services"},
{c:562998,d: "Catch basin cleaning services"},
{c:562998,d: "Grease trap cleaning"},
{c:562998,d: "Lake maintenance and cleaning services"},
{c:562998,d: "Pond maintenance and cleaning services"},
{c:562998,d: "Reservoir maintenance and cleaning services"},
{c:562998,d: "Sewer cleaning and rodding services"},
{c:562998,d: "Sewer cleanout services"},
{c:562998,d: "Storm basin cleanout services"},
{c:562998,d: "Tank cleaning and disposal services, commercial or industrial"},
{c:611110,d: "Academies, elementary or secondary"},
{c:611110,d: "Boarding schools, elementary or secondary"},
{c:611110,d: "Charter schools"},
{c:611110,d: "Elementary and secondary schools"},
{c:611110,d: "Elementary schools"},
{c:611110,d: "Finishing schools, secondary"},
{c:611110,d: "Handicapped, schools for, elementary or secondary"},
{c:611110,d: "High schools"},
{c:611110,d: "High schools offering both academic and technical courses"},
{c:611110,d: "High schools offering both academic and vocational courses"},
{c:611110,d: "Junior high schools"},
{c:611110,d: "Kindergartens"},
{c:611110,d: "Kindergartens, combined with preschools"},
{c:611110,d: "Middle schools"},
{c:611110,d: "Military academies, elementary or secondary"},
{c:611110,d: "Montessori schools, elementary or secondary"},
{c:611110,d: "Parochial schools, elementary or secondary"},
{c:611110,d: "Preparatory schools, elementary or secondary"},
{c:611110,d: "Primary schools"},
{c:611110,d: "Private schools, elementary or secondary"},
{c:611110,d: "Public schools, elementary or secondary"},
{c:611110,d: "School boards, elementary and secondary"},
{c:611110,d: "School districts, elementary or secondary"},
{c:611110,d: "Schools for the handicapped, elementary or secondary"},
{c:611110,d: "Schools for the intellectually and developmentally disabled (except preschool, job training, vocational rehabilitation)"},
{c:611110,d: "Schools for the physically disabled, elementary or secondary"},
{c:611110,d: "Schools, elementary"},
{c:611110,d: "Schools, secondary"},
{c:611110,d: "Secondary schools offering both academic and technical courses"},
{c:611110,d: "Seminaries, below university grade"},
{c:611210,d: "Academies, junior college"},
{c:611210,d: "Colleges, community"},
{c:611210,d: "Colleges, junior"},
{c:611210,d: "Community colleges"},
{c:611210,d: "Community colleges offering a wide variety of academic and technical training"},
{c:611210,d: "Junior colleges"},
{c:611210,d: "Junior colleges offering a wide variety of academic and technical training"},
{c:611210,d: "Schools, junior college"},
{c:611210,d: "Schools, junior college vocational"},
{c:611310,d: "Academies, college or university"},
{c:611310,d: "Academies, military service (college)"},
{c:611310,d: "Business colleges or schools offering baccalaureate or graduate degrees"},
{c:611310,d: "Colleges (except junior colleges)"},
{c:611310,d: "Colleges, universities, and professional schools"},
{c:611310,d: "Conservatories of music (colleges or universities)"},
{c:611310,d: "Dental schools"},
{c:611310,d: "Hospital management schools offering baccalaureate or graduate degrees"},
{c:611310,d: "Hospitality management schools offering baccalaureate or graduate degrees"},
{c:611310,d: "Law schools"},
{c:611310,d: "Medical schools"},
{c:611310,d: "Military academies, college level"},
{c:611310,d: "Military service academies (college)"},
{c:611310,d: "Parochial schools, college level"},
{c:611310,d: "Private colleges (except community or junior college)"},
{c:611310,d: "Professional schools (e.g., business administration, dental, law, medical)"},
{c:611310,d: "Schools, correspondence, college level"},
{c:611310,d: "Schools, medical"},
{c:611310,d: "Schools, music (colleges or universities)"},
{c:611310,d: "Schools, professional (colleges or universities)"},
{c:611310,d: "Seminaries, theological, offering baccalaureate or graduate degrees"},
{c:611310,d: "Theological seminaries offering baccalaureate or graduate degrees"},
{c:611310,d: "Universities"},
{c:611410,d: "Business colleges or schools not offering academic degrees"},
{c:611410,d: "Business schools not offering academic degrees"},
{c:611410,d: "Clerical schools"},
{c:611410,d: "Court reporting schools"},
{c:611410,d: "Schools, business, not offering academic degrees"},
{c:611410,d: "Secretarial schools"},
{c:611420,d: "Computer operator training"},
{c:611420,d: "Computer programming schools"},
{c:611420,d: "Computer software training"},
{c:611420,d: "Computer training (except repair)"},
{c:611420,d: "Local area network (LAN) management training"},
{c:611420,d: "Software application training"},
{c:611430,d: "Continuing education seminars or conferences"},
{c:611430,d: "Management development training"},
{c:611430,d: "Professional development training"},
{c:611430,d: "Quality assurance training"},
{c:611511,d: "Barber colleges"},
{c:611511,d: "Beauty schools"},
{c:611511,d: "Colleges, barber and beauty"},
{c:611511,d: "Cosmetic art schools (e.g., makeup, skin care)"},
{c:611511,d: "Cosmetology schools"},
{c:611511,d: "Manicure and pedicure schools"},
{c:611511,d: "Schools, barber"},
{c:611511,d: "Schools, beauty"},
{c:611511,d: "Schools, cosmetology"},
{c:611512,d: "Aviation schools"},
{c:611512,d: "Flight simulation training"},
{c:611512,d: "Flight training schools"},
{c:611512,d: "Flying instruction"},
{c:611512,d: "Military flight instruction training"},
{c:611512,d: "Schools, aviation"},
{c:611513,d: "Apprenticeship training programs"},
{c:611513,d: "Carpenters' apprenticeship training"},
{c:611513,d: "Craft union apprenticeship training programs"},
{c:611513,d: "Electricians' apprenticeship training"},
{c:611513,d: "Mechanic's apprenticeship training"},
{c:611513,d: "Plumbers' apprenticeship training"},
{c:611513,d: "Sheet metal workers' apprenticeship training"},
{c:611513,d: "Steam fitters' apprenticeship training"},
{c:611513,d: "Trade union apprenticeship training programs"},
{c:611513,d: "Vocational apprenticeship training"},
{c:611519,d: "Acupuncture training"},
{c:611519,d: "Air traffic control schools"},
{c:611519,d: "Art schools, commercial or graphic"},
{c:611519,d: "Banking schools (training in banking)"},
{c:611519,d: "Bartending schools"},
{c:611519,d: "Broadcasting schools"},
{c:611519,d: "Bus driver training"},
{c:611519,d: "Chauffeur training"},
{c:611519,d: "Computer repair training"},
{c:611519,d: "Cooking schools"},
{c:611519,d: "Culinary arts schools"},
{c:611519,d: "Dental hygienist schools"},
{c:611519,d: "Dental technician schools"},
{c:611519,d: "Electronic equipment repair training"},
{c:611519,d: "Fire fighter training schools"},
{c:611519,d: "Flight attendant schools"},
{c:611519,d: "Graphic arts schools"},
{c:611519,d: "Heavy equipment operation schools"},
{c:611519,d: "Heavy equipment repair training"},
{c:611519,d: "Home health aid schools"},
{c:611519,d: "Hospital management schools (except academic)"},
{c:611519,d: "Hospitality management schools (except academic)"},
{c:611519,d: "Marine navigational schools"},
{c:611519,d: "Massage therapist instruction"},
{c:611519,d: "Mechanic's schools (except apprenticeship)"},
{c:611519,d: "Medical technician schools"},
{c:611519,d: "Modeling schools"},
{c:611519,d: "Nurse's aides schools"},
{c:611519,d: "Nursing schools (except academic)"},
{c:611519,d: "Personal fitness instructor training"},
{c:611519,d: "Photography schools, commercial"},
{c:611519,d: "Police training schools"},
{c:611519,d: "Real estate schools"},
{c:611519,d: "Restaurant management schools (except academic)"},
{c:611519,d: "Security guard training"},
{c:611519,d: "Specialized military training (except flight instruction, academies, and basic training)"},
{c:611519,d: "Truck driving schools"},
{c:611519,d: "Yoga instructor training"},
{c:611610,d: "Art (except commercial or graphic) instruction"},
{c:611610,d: "Art schools (except academic), fine"},
{c:611610,d: "Ballet schools (except academic)"},
{c:611610,d: "Ceramics instruction"},
{c:611610,d: "Conservatory of music (except academic)"},
{c:611610,d: "Dance instruction"},
{c:611610,d: "Dance schools"},
{c:611610,d: "Dance studios"},
{c:611610,d: "Drama schools (except academic)"},
{c:611610,d: "Fine arts schools (except academic)"},
{c:611610,d: "Handicrafts instruction"},
{c:611610,d: "Music instruction (e.g., guitar, piano)"},
{c:611610,d: "Music schools (except academic)"},
{c:611610,d: "Painting instruction"},
{c:611610,d: "Performing arts schools (except academic)"},
{c:611610,d: "Photography schools, art"},
{c:611610,d: "Schools, drama (except academic)"},
{c:611610,d: "Schools, music (except academic)"},
{c:611610,d: "Sculpture instruction"},
{c:611610,d: "Singing instruction"},
{c:611610,d: "Theater schools"},
{c:611610,d: "Voice instruction"},
{c:611620,d: "Academies, riding instruction"},
{c:611620,d: "Aerobic dance and exercise instruction"},
{c:611620,d: "Automobile racing schools"},
{c:611620,d: "Baseball instruction, camps, or schools"},
{c:611620,d: "Basketball instruction, camps, or schools"},
{c:611620,d: "Baton instruction"},
{c:611620,d: "Bowling instruction"},
{c:611620,d: "Boys' camps, sports instruction"},
{c:611620,d: "Boys' camps, sports instructor"},
{c:611620,d: "Camps, sports instruction"},
{c:611620,d: "Cheerleading instruction, camps, or schools"},
{c:611620,d: "Football instruction, camps, or schools"},
{c:611620,d: "Girls' camps, sports instruction"},
{c:611620,d: "Golf instruction, camps, or schools"},
{c:611620,d: "Gymnastics instruction, camps, or schools"},
{c:611620,d: "Hockey instruction, camps, or schools"},
{c:611620,d: "Jai alai instruction, camps, or schools"},
{c:611620,d: "Judo instruction, camps, or schools"},
{c:611620,d: "Karate instruction, camps or schools"},
{c:611620,d: "Martial arts instruction, camps, or schools"},
{c:611620,d: "Professional sports (e.g., golf, skiing, swimming, tennis) instructors (i.e., not participating in sporting events)"},
{c:611620,d: "Riding instruction academies or schools"},
{c:611620,d: "Schools, sports instruction"},
{c:611620,d: "Scuba instruction, camps, or schools"},
{c:611620,d: "Skiing instruction, camps, or schools"},
{c:611620,d: "Skin diving instruction, camps, or schools"},
{c:611620,d: "Sky diving instruction, camps, or schools"},
{c:611620,d: "Soccer instruction, camps, or schools"},
{c:611620,d: "Sports camps (e.g., baseball, basketball, football), instructional"},
{c:611620,d: "Sports instruction, camps, or schools"},
{c:611620,d: "Sports instructors, independent (i.e., not participating in sporting events)"},
{c:611620,d: "Swimming instruction"},
{c:611620,d: "Tennis instruction, camps, or schools"},
{c:611630,d: "Foreign language schools"},
{c:611630,d: "Language schools"},
{c:611630,d: "Schools, language"},
{c:611630,d: "Second language instruction"},
{c:611630,d: "Sign language instruction"},
{c:611630,d: "Sign language schools"},
{c:611691,d: "Academic tutoring services"},
{c:611691,d: "Adult literacy instruction"},
{c:611691,d: "College board preparation centers"},
{c:611691,d: "College entrance exam preparation instruction"},
{c:611691,d: "Exam preparation services"},
{c:611691,d: "High school equivalency (e.g., GED) exam instruction"},
{c:611691,d: "Learning centers offering remedial courses"},
{c:611691,d: "Professional examination review instruction"},
{c:611691,d: "Tutoring,  academic"},
{c:611692,d: "Automobile driving schools"},
{c:611692,d: "Driver education"},
{c:611692,d: "Driver training schools (except bus, heavy equipment, truck)"},
{c:611692,d: "Motorcycle driving schools"},
{c:611699,d: "Bible schools (except degree granting)"},
{c:611699,d: "Bridge and other card game instruction"},
{c:611699,d: "Charm schools"},
{c:611699,d: "CPR (cardiopulmonary resuscitation) training and certification"},
{c:611699,d: "Diction schools"},
{c:611699,d: "Firearms training"},
{c:611699,d: "First-aid instruction"},
{c:611699,d: "Life guard training"},
{c:611699,d: "Personal development schools"},
{c:611699,d: "Public speaking training"},
{c:611699,d: "Self defense (except martial arts) instruction"},
{c:611699,d: "Speed reading instruction"},
{c:611699,d: "Survival training instruction"},
{c:611699,d: "Yoga instruction, camps, or schools"},
{c:611710,d: "Career and vocational counseling services (except rehabilitative)"},
{c:611710,d: "College selection services"},
{c:611710,d: "Educational consultants"},
{c:611710,d: "Educational curriculum development services"},
{c:611710,d: "Educational guidance counseling services"},
{c:611710,d: "Educational support services"},
{c:611710,d: "Educational testing evaluation services"},
{c:611710,d: "Educational testing services"},
{c:611710,d: "School bus attendant services"},
{c:611710,d: "Student exchange programs"},
{c:611710,d: "Study abroad programs"},
{c:611710,d: "Test development and evaluation services, educational"},
{c:611710,d: "Testing services, educational"},
{c:621111,d: "Acupuncturists' (MDs or DOs) offices (e.g., centers, clinics)"},
{c:621111,d: "Allergists' offices (e.g., centers, clinics)"},
{c:621111,d: "Anesthesiologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Cardiologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Clinical pathologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Dermatologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Doctors of osteopathy (DOs, except mental health) offices (e.g., centers, clinics)"},
{c:621111,d: "DOs' (doctors of osteopathy, except mental health) offices (e.g., centers, clinics)"},
{c:621111,d: "Family physicians' offices (e.g., centers, clinics)"},
{c:621111,d: "Forensic pathologists' offices"},
{c:621111,d: "Gastroenterologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Gynecologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Health screening services in physicians' offices"},
{c:621111,d: "Immunologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Internists' offices (e.g., centers, clinics)"},
{c:621111,d: "MDs' (medical doctors, except mental health) offices (e.g., centers, clinics)"},
{c:621111,d: "Medical doctors' (MDs, except mental health) offices (e.g., centers, clinics)"},
{c:621111,d: "Nephrologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Neurologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Neuropathologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Obstetricians' offices (e.g., centers, clinics)"},
{c:621111,d: "Oncologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Ophthalmologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Orthopedic physicians' offices (e.g., centers, clinics)"},
{c:621111,d: "Orthopedic surgeons' offices (e.g., centers, clinics)"},
{c:621111,d: "Osteopathic physicians' (except mental health) offices (e.g., centers, clinics)"},
{c:621111,d: "Otolaryngologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Pathologists' (except oral, speech, voice) offices (e.g., centers, clinics)"},
{c:621111,d: "Pathologists', forensic, offices (e.g., centers, clinics)"},
{c:621111,d: "Pathologists', neuropathological, offices (e.g., centers, clinics)"},
{c:621111,d: "Pathologists', surgical, offices (e.g., centers, clinics)"},
{c:621111,d: "Pediatricians' (except mental health) offices (e.g., centers, clinics)"},
{c:621111,d: "Physicians' (except mental health) offices (e.g., centers, clinics)"},
{c:621111,d: "Plastic surgeons' offices (e.g., centers, clinics)"},
{c:621111,d: "Proctologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Pulmonary specialists' offices (e.g., centers, clinics)"},
{c:621111,d: "Radiologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Surgeons' (except dental) offices (e.g., centers, clinics)"},
{c:621111,d: "Surgical pathologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Urologists' offices (e.g., centers, clinics)"},
{c:621111,d: "Walk-in physicians' offices (e.g., centers, clinics)"},
{c:621112,d: "Doctors of osteopathy (DOs), mental health, offices (e.g., centers, clinics)"},
{c:621112,d: "DOs' (doctors of osteopathy), mental health, offices (e.g., centers, clinics)"},
{c:621112,d: "MDs' (medical doctors), mental health, offices (e.g., centers, clinics)"},
{c:621112,d: "Medical doctors' (MDs), mental health, offices (e.g., centers, clinics)"},
{c:621112,d: "Mental health physicians' offices (e.g., centers, clinics)"},
{c:621112,d: "Pediatricians', mental health, offices (e.g., centers, clinics)"},
{c:621112,d: "Physicians', mental health, offices (e.g., centers, clinics)"},
{c:621112,d: "Psychiatrists' offices (e.g., centers, clinics)"},
{c:621112,d: "Psychoanalysts' (MDs or DOs) offices (e.g., centers, clinics)"},
{c:621112,d: "Psychotherapists' (MDs or DOs) offices (e.g., centers, clinics)"},
{c:621210,d: "DDSs' (doctors of dental surgery) offices (e.g., centers, clinics)"},
{c:621210,d: "Dental surgeons' offices (e.g., centers, clinics)"},
{c:621210,d: "Dentists' offices (e.g., centers, clinics)"},
{c:621210,d: "DMDs' (doctors of dental medicine) offices (e.g., centers, clinics)"},
{c:621210,d: "Doctors of dental medicine (DMDs) offices (e.g., centers, clinics)"},
{c:621210,d: "Doctors of dental surgery (DDSs) offices (e.g., centers, clinics)"},
{c:621210,d: "Endodontists' offices (e.g., centers, clinics)"},
{c:621210,d: "Family dentists' offices (e.g., centers, clinics)"},
{c:621210,d: "Oral and maxillofacial surgeons' offices (e.g., centers, clinics)"},
{c:621210,d: "Oral pathologists' offices (e.g., centers, clinics)"},
{c:621210,d: "Orthodontists' offices (e.g., centers, clinics)"},
{c:621210,d: "Pathologists', oral, offices (e.g., centers, clinics)"},
{c:621210,d: "Periodontists' offices (e.g., centers, clinics)"},
{c:621210,d: "Prosthodontists' offices (e.g., centers, clinics)"},
{c:621210,d: "Surgeons', dental, offices (e.g., centers, clinics)"},
{c:621310,d: "Chiropractors' offices (e.g., centers, clinics)"},
{c:621310,d: "DCs' (doctors of chiropractic) offices (e.g., centers, clinics)"},
{c:621310,d: "Doctors of chiropractic (DCs) offices (e.g., centers, clinics)"},
{c:621320,d: "Doctors of optometry (ODs) offices (e.g., centers, clinics)"},
{c:621320,d: "ODs' (doctors of optometry) offices (e.g., centers, clinics)"},
{c:621320,d: "Optometrists' offices (e.g., centers, clinics)"},
{c:621330,d: "Clinical psychologists' offices (e.g., centers, clinics)"},
{c:621330,d: "Doctors of psychology offices (e.g., centers, clinics)"},
{c:621330,d: "Psychoanalysts' (except MDs or DOs) offices (e.g., centers, clinics)"},
{c:621330,d: "Psychologists' offices (e.g., centers, clinics), clinical"},
{c:621330,d: "Psychotherapists' (except MDs or DOs) offices (e.g., centers, clinics)"},
{c:621330,d: "Social workers', mental health, offices (e.g., centers, clinics)"},
{c:621340,d: "Art therapists' offices (e.g., centers, clinics)"},
{c:621340,d: "Audiologists' offices (e.g., centers, clinics)"},
{c:621340,d: "Dance therapists' offices (e.g., centers, clinics)"},
{c:621340,d: "Equestrian physical therapists' offices (e.g., centers, clinics)"},
{c:621340,d: "Exercise physiologists' offices (e.g., centers, clinics)"},
{c:621340,d: "Hearing testing services by offices of audiologists"},
{c:621340,d: "Industrial therapists' offices (e.g., centers, clinics)"},
{c:621340,d: "Music therapists' offices (e.g., centers, clinics)"},
{c:621340,d: "Occupational therapists' offices (e.g., centers, clinics)"},
{c:621340,d: "Pathologists', speech or voice, offices (e.g., centers, clinics)"},
{c:621340,d: "Physical therapists' offices (e.g., centers, clinics)"},
{c:621340,d: "Physical therapy offices (e.g., centers, clinics)"},
{c:621340,d: "Physical-integration practitioners' offices (e.g., centers, clinics)"},
{c:621340,d: "Physiotherapists' offices (e.g., centers, clinics)"},
{c:621340,d: "Recreational (e.g., art, dance, music) therapists' offices (e.g., centers, clinics)"},
{c:621340,d: "Speech clinicians' offices (e.g., centers, clinics)"},
{c:621340,d: "Speech defect clinics"},
{c:621340,d: "Speech pathologists' offices (e.g., centers, clinics)"},
{c:621340,d: "Speech therapists' offices (e.g., centers, clinics)"},
{c:621340,d: "Sports physical therapists' offices (e.g., centers, clinics)"},
{c:621340,d: "Voice pathologists' offices (e.g., centers, clinics)"},
{c:621391,d: "Doctors of podiatry (DPs) offices (e.g., centers, clinics)"},
{c:621391,d: "DPs' (doctors of podiatry) offices (e.g., centers, clinics)"},
{c:621391,d: "Foot specialists' (podiatry) offices (e.g., centers, clinics)"},
{c:621391,d: "Podiatrists' offices (e.g., centers, clinics)"},
{c:621391,d: "Popopediatricians' offices (e.g., centers, clinics)"},
{c:621399,d: "Acupuncturists' (except MDs or DOs) offices (e.g., centers, clinics)"},
{c:621399,d: "Advanced practicing registered nurses (APRNs) offices (e.g., centers, clinics)"},
{c:621399,d: "Certified registered nurse anesthetists (CRNAs) offices (e.g., centers, clinics)"},
{c:621399,d: "Christian Science practitioners' offices (e.g., centers, clinics)"},
{c:621399,d: "Clinical nurse specialists (CNSs) offices (e.g., centers, clinics)"},
{c:621399,d: "Clinical pharmacists' offices (e.g., centers, clinics)"},
{c:621399,d: "Dental hygienists' offices (e.g., centers, clinics)"},
{c:621399,d: "Denturists' offices (e.g., centers, clinics)"},
{c:621399,d: "Dietitians' offices (e.g., centers, clinics)"},
{c:621399,d: "Herbalists' offices (e.g., centers, clinics)"},
{c:621399,d: "Home nursing services, private practice"},
{c:621399,d: "Homeopaths' offices (e.g., centers, clinics)"},
{c:621399,d: "Hypnotherapists' offices (e.g., centers, clinics)"},
{c:621399,d: "Inhalation therapists' offices (e.g., centers, clinics)"},
{c:621399,d: "Licensed practical nurses' (LPNs) offices (e.g., centers, clinics)"},
{c:621399,d: "LPNs' (licensed practical nurses) offices (e.g., centers, clinics)"},
{c:621399,d: "Manual-arts therapists' offices (e.g., centers, clinics)"},
{c:621399,d: "Massage therapists' offices (e.g., centers, clinics)"},
{c:621399,d: "Midwives' offices (e.g., clinics)"},
{c:621399,d: "Naturopaths' offices (e.g., centers, clinics)"},
{c:621399,d: "Nurse practitioners offices (e.g., centers, clinics)"},
{c:621399,d: "Nurses', licensed practical or registered, offices (e.g., centers, clinics)"},
{c:621399,d: "Nursing call centers"},
{c:621399,d: "Nutritionists' offices (e.g., centers, clinics)"},
{c:621399,d: "Ocularists' offices (e.g., centers, clinics)"},
{c:621399,d: "Orthotists' offices (e.g., centers, clinics)"},
{c:621399,d: "Paramedics' offices (e.g.,  centers, clinics)"},
{c:621399,d: "Pedorthics' offices (e.g., centers, clinics)"},
{c:621399,d: "Physicians' assistants' offices (e.g., centers, clinics)"},
{c:621399,d: "Practical nurses' offices (e.g., centers, clinics), licensed"},
{c:621399,d: "Prosthetists' offices (e.g., centers, clinics)"},
{c:621399,d: "Registered nurses' (RNs) offices (e.g., centers, clinics)"},
{c:621399,d: "Respiratory therapists' offices (e.g., centers, clinics)"},
{c:621399,d: "RNs' (registered nurses) offices (e.g., centers, clinics)"},
{c:621410,d: "Abortion clinics"},
{c:621410,d: "Birth control clinics"},
{c:621410,d: "Childbirth preparation classes"},
{c:621410,d: "Counseling services, family planning"},
{c:621410,d: "Family planning centers"},
{c:621410,d: "Family planning counseling services"},
{c:621410,d: "Fertility clinics"},
{c:621410,d: "Pregnancy counseling centers"},
{c:621410,d: "Reproductive health services centers"},
{c:621420,d: "Alcoholism treatment centers and clinics (except hospitals), outpatient"},
{c:621420,d: "Detoxification centers and clinics (except hospitals), outpatient"},
{c:621420,d: "Drug addiction treatment centers and clinics (except hospitals), outpatient"},
{c:621420,d: "Mental health centers and clinics (except hospitals), outpatient"},
{c:621420,d: "Outpatient mental health centers and clinics (except hospitals)"},
{c:621420,d: "Outpatient treatment centers and clinics (except hospitals) for substance abuse (i.e., alcoholism, drug addiction)"},
{c:621420,d: "Outpatient treatment centers and clinics for alcoholism"},
{c:621420,d: "Outpatient treatment centers and clinics for drug addiction"},
{c:621420,d: "Psychiatric centers and clinics (except hospitals), outpatient"},
{c:621420,d: "Substance abuse treatment centers and clinics (except hospitals), outpatient"},
{c:621491,d: "Group hospitalization plans providing health care services"},
{c:621491,d: "Health maintenance organization (HMO) medical centers and clinics"},
{c:621491,d: "HMO (health maintenance organization) medical centers and clinics"},
{c:621492,d: "Dialysis centers and clinics"},
{c:621492,d: "Hemodialysis centers and clinics"},
{c:621492,d: "Kidney dialysis centers and clinics"},
{c:621492,d: "Renal dialysis centers and clinics"},
{c:621493,d: "Ambulatory surgical centers and clinics, freestanding"},
{c:621493,d: "Emergency medical centers and clinics, freestanding"},
{c:621493,d: "Freestanding ambulatory surgical centers and clinics"},
{c:621493,d: "Freestanding emergency medical centers and clinics"},
{c:621493,d: "Laser surgery centers, freestanding"},
{c:621493,d: "Trauma centers (except hospitals), freestanding"},
{c:621493,d: "Urgent medical care centers and clinics (except hospitals), freestanding"},
{c:621498,d: "Biofeedback centers and clinics, outpatient"},
{c:621498,d: "Clinics/centers of health practitioners from more than one industry practicing within the same establishment"},
{c:621498,d: "Clinics/centers of health practitioners with multi-industry degrees"},
{c:621498,d: "Community health centers and clinics, outpatient"},
{c:621498,d: "Freestanding birth centers, outpatient"},
{c:621498,d: "Infusion therapy centers and clinics, outpatient"},
{c:621498,d: "Pain therapy centers and clinics, outpatient"},
{c:621498,d: "Sleep disorder centers and clinics, outpatient"},
{c:621511,d: "Bacteriological laboratories, diagnostic"},
{c:621511,d: "Bacteriological laboratories, medical"},
{c:621511,d: "Biological laboratories, diagnostic"},
{c:621511,d: "Blood analysis laboratories"},
{c:621511,d: "Cytology health laboratories"},
{c:621511,d: "DNA testing laboratories"},
{c:621511,d: "Forensic laboratories, medical"},
{c:621511,d: "Genetic testing laboratories"},
{c:621511,d: "Laboratories, medical (except radiological, X-ray)"},
{c:621511,d: "Laboratory testing services, medical (except radiological, X-ray)"},
{c:621511,d: "Medical laboratories (except radiological, X-ray)"},
{c:621511,d: "Medical pathology laboratories"},
{c:621511,d: "Mycology health laboratories"},
{c:621511,d: "Parasitology health laboratories"},
{c:621511,d: "Pathological analysis laboratories"},
{c:621511,d: "Pathology laboratories, medical"},
{c:621511,d: "Testing laboratories, medical"},
{c:621511,d: "Toxicology health laboratories"},
{c:621511,d: "Urinalysis laboratories"},
{c:621512,d: "CAT (computerized axial tomography) scanner centers"},
{c:621512,d: "Computer tomography (CT-SCAN) centers"},
{c:621512,d: "CT-SCAN (computer tomography) centers"},
{c:621512,d: "Dental X-ray laboratories"},
{c:621512,d: "Diagnostic imaging centers (medical)"},
{c:621512,d: "Laboratories, dental X-ray"},
{c:621512,d: "Laboratories, medical radiological or X-ray"},
{c:621512,d: "Laboratory testing services, medical radiological or X-ray"},
{c:621512,d: "Magnetic resonance imaging (MRI) centers"},
{c:621512,d: "Mammogram (i.e., breast imaging) centers"},
{c:621512,d: "Medical laboratories, radiological or X-ray"},
{c:621512,d: "Medical radiological laboratories"},
{c:621512,d: "Medical X-ray laboratories"},
{c:621512,d: "Mobile breast imaging centers"},
{c:621512,d: "Mobile X-ray facilities (medical)"},
{c:621512,d: "MRI (magnetic resonance imaging) centers"},
{c:621512,d: "Positron emission tomography (PET) scanner centers"},
{c:621512,d: "Radiological laboratories, medical"},
{c:621512,d: "Radiological laboratory services, medical"},
{c:621512,d: "Single photon emission computerized tomography (SPECT) centers"},
{c:621512,d: "Ultrasound imaging centers"},
{c:621512,d: "X-ray laboratories, medical or dental"},
{c:621610,d: "Home care of elderly, medical"},
{c:621610,d: "Home health agencies"},
{c:621610,d: "Home health care agencies"},
{c:621610,d: "Home infusion therapy services"},
{c:621610,d: "Home nursing services (except private practices)"},
{c:621610,d: "Hospice care services, in-home"},
{c:621610,d: "Nurse associations, visiting"},
{c:621610,d: "Nursing agencies, primarily providing home nursing services"},
{c:621610,d: "Visiting nurse associations"},
{c:621910,d: "Air ambulance services"},
{c:621910,d: "Ambulance services, air or ground"},
{c:621910,d: "Emergency medical transportation services, air or ground"},
{c:621910,d: "Rescue services, air"},
{c:621910,d: "Rescue services, medical"},
{c:621991,d: "Blood banks"},
{c:621991,d: "Blood donor stations"},
{c:621991,d: "Eye banks"},
{c:621991,d: "Human egg or ova banks"},
{c:621991,d: "Human embryo storage services"},
{c:621991,d: "Organ banks, body"},
{c:621991,d: "Organ donor centers, body"},
{c:621991,d: "Placenta banks"},
{c:621991,d: "Plasma collection services"},
{c:621991,d: "Plasmapheresis centers"},
{c:621991,d: "Sperm banks, human"},
{c:621999,d: "Blood pressure screening facilities"},
{c:621999,d: "Blood pressure screening services"},
{c:621999,d: "Employee drug testing services"},
{c:621999,d: "Health screening services (except by offices of health practitioners)"},
{c:621999,d: "Hearing testing services (except by offices of audiologists)"},
{c:621999,d: "Medical care management services"},
{c:621999,d: "Medical case management services"},
{c:621999,d: "Pacemaker monitoring services"},
{c:621999,d: "Physical fitness evaluation services (except by offices of health practitioners)"},
{c:621999,d: "Smoking cessation programs"},
{c:621999,d: "Stop smoking clinics"},
{c:622110,d: "Children's hospitals, general"},
{c:622110,d: "General medical and surgical hospitals"},
{c:622110,d: "Hospitals, general medical and surgical"},
{c:622110,d: "Hospitals, general pediatric"},
{c:622110,d: "Osteopathic hospitals"},
{c:622210,d: "Alcoholism rehabilitation hospitals"},
{c:622210,d: "Children's hospitals, psychiatric or substance abuse"},
{c:622210,d: "Detoxification hospitals"},
{c:622210,d: "Drug addiction rehabilitation hospitals"},
{c:622210,d: "Hospitals for alcoholics"},
{c:622210,d: "Hospitals, addiction"},
{c:622210,d: "Hospitals, mental (except intellectual and developmental disability)"},
{c:622210,d: "Hospitals, psychiatric (except convalescent)"},
{c:622210,d: "Hospitals, psychiatric pediatric"},
{c:622210,d: "Hospitals, substance abuse"},
{c:622210,d: "Mental (except intellectual and developmental disability) hospitals"},
{c:622210,d: "Mental health hospitals"},
{c:622210,d: "Psychiatric hospitals (except convalescent)"},
{c:622210,d: "Rehabilitation hospitals, alcoholism and drug addiction"},
{c:622310,d: "Cancer hospitals"},
{c:622310,d: "Children's hospitals, specialty (except psychiatric, substance abuse)"},
{c:622310,d: "Chronic disease hospitals"},
{c:622310,d: "Extended care hospitals (except mental, substance abuse)"},
{c:622310,d: "Eye, ear, nose, and throat hospitals"},
{c:622310,d: "Hospitals, specialty (except psychiatric, substance abuse)"},
{c:622310,d: "Leprosy hospitals"},
{c:622310,d: "Maternity hospitals"},
{c:622310,d: "Neurological hospitals"},
{c:622310,d: "Obstetrical hospital"},
{c:622310,d: "Orthopedic hospitals"},
{c:622310,d: "Physical rehabilitation hospitals"},
{c:622310,d: "Rehabilitation hospitals (except alcoholism, drug addiction)"},
{c:622310,d: "Tuberculosis and other respiratory illness hospitals"},
{c:623110,d: "Convalescent homes or convalescent hospitals (except psychiatric)"},
{c:623110,d: "Group homes for the disabled with nursing care"},
{c:623110,d: "Homes for the aged with nursing care"},
{c:623110,d: "Homes for the elderly with nursing care"},
{c:623110,d: "Hospices, inpatient care"},
{c:623110,d: "Nursing homes"},
{c:623110,d: "Rest homes with nursing care"},
{c:623110,d: "Retirement homes with nursing care"},
{c:623110,d: "Skilled nursing facilities"},
{c:623210,d: "Group homes, intellectual and developmental disability"},
{c:623210,d: "Homes with or without health care, intellectual and developmental disability"},
{c:623210,d: "Hospitals, intellectual and developmental disability"},
{c:623210,d: "Intellectual and developmental disability facilities (e.g., homes, hospitals, intermediate care facilities), residential"},
{c:623210,d: "Intellectual and developmental disability homes"},
{c:623210,d: "Intellectual and developmental disability hospitals"},
{c:623210,d: "Intellectual and developmental disability intermediate care facilities"},
{c:623210,d: "Intermediate care facilities, intellectual and developmental disability"},
{c:623220,d: "Alcoholism rehabilitation facilities (except licensed hospitals), residential"},
{c:623220,d: "Convalescent homes or hospitals for psychiatric patients"},
{c:623220,d: "Drug addiction rehabilitation facilities (except licensed hospitals), residential"},
{c:623220,d: "Halfway houses for patients with mental health illnesses"},
{c:623220,d: "Halfway houses, substance abuse (e.g., alcoholism, drug addiction)"},
{c:623220,d: "Homes for emotionally disturbed adults or children"},
{c:623220,d: "Homes, psychiatric convalescent"},
{c:623220,d: "Hospitals, psychiatric convalescent"},
{c:623220,d: "Mental health facilities, residential"},
{c:623220,d: "Mental health halfway houses"},
{c:623220,d: "Psychiatric convalescent homes or hospitals"},
{c:623220,d: "Residential group homes for the emotionally disturbed"},
{c:623220,d: "Substance abuse (i.e., alcoholism, drug addiction) halfway houses"},
{c:623220,d: "Substance abuse facilities, residential"},
{c:623311,d: "Assisted living facilities with on-site nursing facilities"},
{c:623311,d: "Continuing care retirement communities"},
{c:623311,d: "Retirement communities, continuing care"},
{c:623312,d: "Assisted living facilities without on-site nursing care facilities"},
{c:623312,d: "Homes for the aged without nursing care"},
{c:623312,d: "Homes for the elderly without nursing care"},
{c:623312,d: "Old age homes without nursing care"},
{c:623312,d: "Old soldiers' homes without nursing care"},
{c:623312,d: "Rest homes without nursing care"},
{c:623312,d: "Retirement homes without nursing care"},
{c:623312,d: "Senior citizens' homes without nursing care"},
{c:623990,d: "Boot camps for delinquent youth"},
{c:623990,d: "Boys' and girls' residential facilities (e.g., homes, ranches, villages)"},
{c:623990,d: "Camps, boot or disciplinary (except correctional), for delinquent youth"},
{c:623990,d: "Child group foster homes"},
{c:623990,d: "Children's villages"},
{c:623990,d: "Delinquent youth halfway group homes"},
{c:623990,d: "Disabled group homes without nursing care"},
{c:623990,d: "Disciplinary camps for delinquent youth"},
{c:623990,d: "Group foster homes for children"},
{c:623990,d: "Group homes for the disabled without nursing care"},
{c:623990,d: "Group homes for the hearing impaired"},
{c:623990,d: "Group homes for the visually impaired"},
{c:623990,d: "Halfway group homes for delinquents and ex-offenders"},
{c:623990,d: "Homes for children with health care incidental"},
{c:623990,d: "Homes for unwed mothers"},
{c:623990,d: "Juvenile halfway group homes"},
{c:623990,d: "Orphanages"},
{c:624110,d: "Adoption agencies"},
{c:624110,d: "Adoption services, child"},
{c:624110,d: "Aid to families with dependent children (AFDC)"},
{c:624110,d: "Child guidance agencies"},
{c:624110,d: "Child welfare services"},
{c:624110,d: "Community centers (except recreational only), youth"},
{c:624110,d: "Foster care placement agencies"},
{c:624110,d: "Foster home placement services"},
{c:624110,d: "Self-help organizations, youth"},
{c:624110,d: "Teen outreach services"},
{c:624110,d: "Youth centers (except recreational only)"},
{c:624110,d: "Youth guidance organizations"},
{c:624110,d: "Youth self-help organizations"},
{c:624120,d: "Activity centers for disabled persons, the elderly, and persons diagnosed with intellectual and developmental disabilities"},
{c:624120,d: "Adult day care centers"},
{c:624120,d: "Centers, senior citizens'"},
{c:624120,d: "Community centers (except recreational only), adult"},
{c:624120,d: "Companion services for disabled persons, the elderly, and persons diagnosed with intellectual and developmental disabilities"},
{c:624120,d: "Day care centers for disabled persons, the elderly, and persons diagnosed with intellectual and developmental disabilities"},
{c:624120,d: "Day care centers, adult"},
{c:624120,d: "Disability support groups"},
{c:624120,d: "Home care of elderly, non-medical"},
{c:624120,d: "Homemaker's service for elderly or disabled persons, non-medical"},
{c:624120,d: "Self-help organizations for disabled persons, the elderly, and persons diagnosed with intellectual and developmental disabilities"},
{c:624120,d: "Senior citizens activity centers"},
{c:624120,d: "Senior citizens centers"},
{c:624190,d: "Alcoholism and drug addiction self-help organizations"},
{c:624190,d: "Alcoholism counseling (except medical treatment), nonresidential"},
{c:624190,d: "Alcoholism self-help organizations"},
{c:624190,d: "Community action service agencies"},
{c:624190,d: "Community health education services (except health care services)"},
{c:624190,d: "Counseling services (except by psychiatrists, psychoanalysts, or psychotherapists)"},
{c:624190,d: "Crisis intervention centers"},
{c:624190,d: "Drug addiction self-help organizations"},
{c:624190,d: "Ex-offender rehabilitation agencies"},
{c:624190,d: "Ex-offender self-help organizations"},
{c:624190,d: "Family social service agencies"},
{c:624190,d: "Family welfare services"},
{c:624190,d: "Hotline centers"},
{c:624190,d: "Individual and family social services, multi-purpose"},
{c:624190,d: "Marriage counseling services (except by offices of mental health practitioners)"},
{c:624190,d: "Mediation, social service, family, agencies"},
{c:624190,d: "Multiservice centers, neighborhood"},
{c:624190,d: "Offender self-help organizations"},
{c:624190,d: "Parenting support services"},
{c:624190,d: "Parole offices, privately operated"},
{c:624190,d: "Probation offices, privately operated"},
{c:624190,d: "Rape crisis centers"},
{c:624190,d: "Referral services for personal and social problems"},
{c:624190,d: "Rehabilitation agencies for offenders"},
{c:624190,d: "Self-help organizations (except for disabled persons, the elderly, persons diagnosed with intellectual and developmental disabilities)"},
{c:624190,d: "Social service agencies, family"},
{c:624190,d: "Social service centers, multi-purpose"},
{c:624190,d: "Suicide crisis centers"},
{c:624190,d: "Support group services"},
{c:624190,d: "Telephone counseling services"},
{c:624190,d: "Travelers' aid centers"},
{c:624190,d: "Welfare service centers, multi-program"},
{c:624210,d: "Community meals, social services"},
{c:624210,d: "Food banks"},
{c:624210,d: "Food pantries"},
{c:624210,d: "Meal delivery programs"},
{c:624210,d: "Mobile soup kitchens"},
{c:624210,d: "Soup kitchens"},
{c:624221,d: "Battered women's shelters"},
{c:624221,d: "Emergency shelters (except for victims of domestic or international disasters or conflicts)"},
{c:624221,d: "Homeless shelters"},
{c:624221,d: "Runaway youth shelters"},
{c:624221,d: "Shelters (except for victims of domestic or international disasters or conflicts), emergency"},
{c:624221,d: "Shelters, battered women's"},
{c:624221,d: "Shelters, homeless"},
{c:624221,d: "Shelters, runaway youth"},
{c:624221,d: "Shelters, temporary (e.g., battered women's, homeless, runaway youth)"},
{c:624221,d: "Temporary housing for families of medical patients"},
{c:624221,d: "Temporary shelters (e.g., battered women's, homeless, runaway youth)"},
{c:624221,d: "Women's shelters, battered"},
{c:624229,d: "Energy assistance programs"},
{c:624229,d: "Home construction organizations, work (sweat) equity"},
{c:624229,d: "Housing assistance agencies"},
{c:624229,d: "Housing repair organizations, volunteer"},
{c:624229,d: "Transitional housing agencies"},
{c:624229,d: "Volunteer housing repair organizations"},
{c:624229,d: "Work (sweat) equity home construction organizations"},
{c:624230,d: "Disaster relief services"},
{c:624230,d: "Emergency relief services"},
{c:624230,d: "Emergency shelters for victims of domestic or international disasters or conflicts"},
{c:624230,d: "Immigrant resettlement services"},
{c:624230,d: "Refugee settlement services"},
{c:624230,d: "Relief services, disaster"},
{c:624230,d: "Relief services, emergency"},
{c:624230,d: "Shelters for victims of domestic or international disasters or conflicts, emergency"},
{c:624310,d: "Habilitation job counseling and training, vocational"},
{c:624310,d: "Job counseling, vocational rehabilitation or habilitation"},
{c:624310,d: "Job training, vocational rehabilitation or habilitation"},
{c:624310,d: "Rehabilitation job counseling and training, vocational"},
{c:624310,d: "Sheltered workshops (i.e., work experience centers)"},
{c:624310,d: "Vocational habilitation job counseling"},
{c:624310,d: "Vocational habilitation job training facilities (except schools)"},
{c:624310,d: "Vocational rehabilitation agencies"},
{c:624310,d: "Vocational rehabilitation job counseling"},
{c:624310,d: "Vocational rehabilitation job training facilities (except schools)"},
{c:624310,d: "Vocational rehabilitation or habilitation services (e.g., job counseling, job training, work experience)"},
{c:624310,d: "Work experience centers (i.e., sheltered workshops)"},
{c:624310,d: "Workshops for persons with disabilities"},
{c:624410,d: "Babysitting services in provider's own home, child day care"},
{c:624410,d: "Babysitting services, child day care"},
{c:624410,d: "Child day care centers"},
{c:624410,d: "Child day care services"},
{c:624410,d: "Child day care services in provider's own home"},
{c:624410,d: "Child day care, before or after school, separate from schools"},
{c:624410,d: "Day care centers, child or infant"},
{c:624410,d: "Day care services, child or infant"},
{c:624410,d: "Group day care centers, child or infant"},
{c:624410,d: "Head start programs, separate from schools"},
{c:624410,d: "Infant day care centers"},
{c:624410,d: "Infant day care services"},
{c:624410,d: "Nursery schools"},
{c:624410,d: "Pre-kindergarten centers (except part of elementary school system)"},
{c:624410,d: "Preschool centers"},
{c:711110,d: "Broadway theaters"},
{c:711110,d: "Burlesque companies"},
{c:711110,d: "Comedy troupes"},
{c:711110,d: "Community theaters"},
{c:711110,d: "Dinner theaters"},
{c:711110,d: "Improvisational theaters"},
{c:711110,d: "Mime theaters"},
{c:711110,d: "Musical theater companies or groups"},
{c:711110,d: "Musical theater productions, live"},
{c:711110,d: "Opera companies"},
{c:711110,d: "Puppet theaters"},
{c:711110,d: "Repertory companies, theatrical"},
{c:711110,d: "Road companies, theatrical"},
{c:711110,d: "Stock companies, theatrical"},
{c:711110,d: "Summer theaters"},
{c:711110,d: "Theater companies (except dance)"},
{c:711110,d: "Theater companies (except dance), amateur"},
{c:711110,d: "Theaters, dinner"},
{c:711110,d: "Theaters, live theatrical production (except dance)"},
{c:711110,d: "Theaters, musical"},
{c:711110,d: "Theatrical repertory companies"},
{c:711110,d: "Theatrical road companies"},
{c:711110,d: "Theatrical stock companies"},
{c:711110,d: "Vaudeville companies"},
{c:711120,d: "Ballet companies"},
{c:711120,d: "Ballet productions, live theatrical"},
{c:711120,d: "Classical dance companies"},
{c:711120,d: "Contemporary dance companies"},
{c:711120,d: "Dance companies"},
{c:711120,d: "Dance productions, live theatrical"},
{c:711120,d: "Dance theaters"},
{c:711120,d: "Dance troupes"},
{c:711120,d: "Folk dance companies"},
{c:711120,d: "Interpretive dance companies"},
{c:711120,d: "Jazz dance companies"},
{c:711120,d: "Modern dance companies"},
{c:711120,d: "Tap dance companies"},
{c:711120,d: "Theater companies, dance"},
{c:711120,d: "Theaters, dance"},
{c:711120,d: "Theatrical dance productions, live"},
{c:711130,d: "Bands"},
{c:711130,d: "Bands, dance"},
{c:711130,d: "Bands, musical"},
{c:711130,d: "Chamber musical groups"},
{c:711130,d: "Chamber orchestras"},
{c:711130,d: "Choirs"},
{c:711130,d: "Classical musical artists, independent"},
{c:711130,d: "Classical musical groups"},
{c:711130,d: "Concert artists, independent"},
{c:711130,d: "Country musical artists, independent"},
{c:711130,d: "Country musical groups"},
{c:711130,d: "Dance bands"},
{c:711130,d: "Drum and bugle corps (i.e., drill teams)"},
{c:711130,d: "Ensembles, musical"},
{c:711130,d: "Jazz musical artists, independent"},
{c:711130,d: "Jazz musical groups"},
{c:711130,d: "Musical artists, independent"},
{c:711130,d: "Musical groups (except musical theater groups)"},
{c:711130,d: "Musical productions (except musical theater productions), live"},
{c:711130,d: "Musicians, independent"},
{c:711130,d: "Opera singers, independent"},
{c:711130,d: "Orchestras"},
{c:711130,d: "Popular musical artists, independent"},
{c:711130,d: "Popular musical groups"},
{c:711130,d: "Rock musical artists, independent"},
{c:711130,d: "Rock musical groups"},
{c:711130,d: "Singers, independent"},
{c:711130,d: "Soloists, independent musical"},
{c:711130,d: "Symphony orchestras"},
{c:711130,d: "Vocalists, independent"},
{c:711190,d: "Carnival traveling shows"},
{c:711190,d: "Circus companies"},
{c:711190,d: "Circuses"},
{c:711190,d: "Horse shows"},
{c:711190,d: "Ice skating companies"},
{c:711190,d: "Ice skating shows"},
{c:711190,d: "Magic shows"},
{c:711190,d: "Traveling shows, carnival"},
{c:711211,d: "Baseball clubs, professional or semiprofessional"},
{c:711211,d: "Baseball teams, professional or semiprofessional"},
{c:711211,d: "Basketball clubs, professional or semiprofessional"},
{c:711211,d: "Basketball teams, professional or semiprofessional"},
{c:711211,d: "Boxing clubs, professional or semiprofessional"},
{c:711211,d: "Football clubs, professional or semiprofessional"},
{c:711211,d: "Football teams, professional or semiprofessional"},
{c:711211,d: "Hockey clubs, professional or semiprofessional"},
{c:711211,d: "Hockey teams, professional or semiprofessional"},
{c:711211,d: "Ice hockey clubs, professional or semiprofessional"},
{c:711211,d: "Jai alai teams, professional or semiprofessional"},
{c:711211,d: "Major league baseball clubs"},
{c:711211,d: "Minor league baseball clubs"},
{c:711211,d: "Professional baseball clubs"},
{c:711211,d: "Professional football clubs"},
{c:711211,d: "Professional sports clubs"},
{c:711211,d: "Roller hockey clubs, professional or semiprofessional"},
{c:711211,d: "Semiprofessional baseball clubs"},
{c:711211,d: "Semiprofessional football clubs"},
{c:711211,d: "Semiprofessional sports clubs"},
{c:711211,d: "Soccer clubs, professional or semiprofessional"},
{c:711211,d: "Soccer teams, professional or semiprofessional"},
{c:711211,d: "Sports clubs, professional or semiprofessional"},
{c:711211,d: "Sports teams, professional or semiprofessional"},
{c:711212,d: "Automobile racetracks"},
{c:711212,d: "Dog racetracks"},
{c:711212,d: "Drag strips"},
{c:711212,d: "Greyhound dog racetracks"},
{c:711212,d: "Harness racetracks"},
{c:711212,d: "Horse racetracks"},
{c:711212,d: "Motorcycle racetracks"},
{c:711212,d: "Racetracks (e.g., automobile, dog, horse)"},
{c:711212,d: "Snowmobile racetracks"},
{c:711212,d: "Speedways"},
{c:711212,d: "Stock car racetracks"},
{c:711212,d: "Thoroughbred racetracks"},
{c:711219,d: "Athletes, amateur, independent"},
{c:711219,d: "Athletes, independent (i.e., participating in live sports events)"},
{c:711219,d: "Automobile racing teams"},
{c:711219,d: "Boxers, independent professional"},
{c:711219,d: "Dog owners, race (i.e., racing dogs)"},
{c:711219,d: "Dog racing kennels"},
{c:711219,d: "Drivers, harness or race car"},
{c:711219,d: "Figure skaters, independent"},
{c:711219,d: "Golfers, independent professional (i.e., participating in sports events)"},
{c:711219,d: "Harness drivers"},
{c:711219,d: "Horse racing stables"},
{c:711219,d: "Jockeys, horse racing"},
{c:711219,d: "Kennels, dog racing"},
{c:711219,d: "Motorcycle racing teams"},
{c:711219,d: "Professional athletes, independent (i.e., participating in sports events)"},
{c:711219,d: "Race car drivers"},
{c:711219,d: "Race car owners (i.e., racing cars)"},
{c:711219,d: "Race dog owners (i.e., racing dogs)"},
{c:711219,d: "Racehorse owners (i.e., racing horses)"},
{c:711219,d: "Racehorse trainers"},
{c:711219,d: "Racehorse training"},
{c:711219,d: "Racing stables, horse"},
{c:711219,d: "Racing teams (e.g., automobile, motorcycle, snowmobile)"},
{c:711219,d: "Referees and umpires"},
{c:711219,d: "Skiers, independent (i.e., participating in sports events)"},
{c:711219,d: "Snowmobile racing teams"},
{c:711219,d: "Sports professionals, independent (i.e., participating in sports events)"},
{c:711219,d: "Sports trainers, independent"},
{c:711219,d: "Stables, horse racing"},
{c:711219,d: "Stock car racing teams"},
{c:711219,d: "Tennis professionals, independent (i.e., participating in sports events)"},
{c:711219,d: "Training race dogs"},
{c:711219,d: "Training racehorses"},
{c:711219,d: "Wrestlers, independent professional"},
{c:711310,d: "Air show managers with facilities"},
{c:711310,d: "Air show organizers with facilities"},
{c:711310,d: "Air show promoters with facilities"},
{c:711310,d: "Arena operators"},
{c:711310,d: "Arts event managers with facilities"},
{c:711310,d: "Arts event organizers with facilities"},
{c:711310,d: "Arts event promoters with facilities"},
{c:711310,d: "Arts festival managers with facilities"},
{c:711310,d: "Arts festival organizers with facilities"},
{c:711310,d: "Arts festival promoters with facilities"},
{c:711310,d: "Beauty pageant managers with facilities"},
{c:711310,d: "Beauty pageant organizers with facilities"},
{c:711310,d: "Beauty pageant promoters with facilities"},
{c:711310,d: "Boxing event managers with facilities"},
{c:711310,d: "Boxing event organizers with facilities"},
{c:711310,d: "Boxing event promoters with facilities"},
{c:711310,d: "Concert hall operators"},
{c:711310,d: "Concert managers with facilities"},
{c:711310,d: "Concert organizers with facilities"},
{c:711310,d: "Concert promoters with facilities"},
{c:711310,d: "Dance festival managers with facilities"},
{c:711310,d: "Dance festival organizers with facilities"},
{c:711310,d: "Dance festival promoters with facilities"},
{c:711310,d: "Ethnic festival promoters with facilities"},
{c:711310,d: "Fair managers with facilities, agricultural"},
{c:711310,d: "Fair organizers with facilities, agricultural"},
{c:711310,d: "Fair promoters with facilities"},
{c:711310,d: "Fair promoters with facilities, agricultural"},
{c:711310,d: "Festival managers with facilities"},
{c:711310,d: "Festival of arts managers with facilities"},
{c:711310,d: "Festival of arts organizers with facilities"},
{c:711310,d: "Festival of arts promoters with facilities"},
{c:711310,d: "Festival organizers with facilities"},
{c:711310,d: "Festival promoters with facilities"},
{c:711310,d: "Heritage festival managers with facilities"},
{c:711310,d: "Heritage festival organizers with facilities"},
{c:711310,d: "Heritage festival promoters with facilities"},
{c:711310,d: "Horse show managers with facilities"},
{c:711310,d: "Horse show organizers with facilities"},
{c:711310,d: "Horse show promoters with facilities"},
{c:711310,d: "Live arts center operators"},
{c:711310,d: "Live theater operators"},
{c:711310,d: "Managers of agricultural fairs with facilities"},
{c:711310,d: "Managers of arts events with facilities"},
{c:711310,d: "Managers of festivals with facilities"},
{c:711310,d: "Managers of live performing arts productions (e.g., concerts) with facilities"},
{c:711310,d: "Managers of sports events with facilities"},
{c:711310,d: "Music festival managers with facilities"},
{c:711310,d: "Music festival organizers with facilities"},
{c:711310,d: "Music festival promoters with facilities"},
{c:711310,d: "Organizers of agricultural fairs with facilities"},
{c:711310,d: "Organizers of arts events with facilities"},
{c:711310,d: "Organizers of festivals with facilities"},
{c:711310,d: "Organizers of live performing arts productions (e.g., concerts) with facilities"},
{c:711310,d: "Organizers of sports events with facilities"},
{c:711310,d: "Performing arts center operators"},
{c:711310,d: "Professional sports promoters with facilities"},
{c:711310,d: "Promoters of agricultural fairs with facilities"},
{c:711310,d: "Promoters of arts events with facilities"},
{c:711310,d: "Promoters of festivals with facilities"},
{c:711310,d: "Promoters of live performing arts productions (e.g., concerts) with facilities"},
{c:711310,d: "Promoters of sports events with facilities"},
{c:711310,d: "Rodeo managers with facilities"},
{c:711310,d: "Rodeo organizers with facilities"},
{c:711310,d: "Rodeo promoters with facilities"},
{c:711310,d: "Sports arena operators"},
{c:711310,d: "Sports event managers with facilities"},
{c:711310,d: "Sports event organizers with facilities"},
{c:711310,d: "Sports event promoters with facilities"},
{c:711310,d: "Sports stadium operators"},
{c:711310,d: "Stadium operators"},
{c:711310,d: "Theater festival managers with facilities"},
{c:711310,d: "Theater festival organizers with facilities"},
{c:711310,d: "Theater festival promoters with facilities"},
{c:711310,d: "Theater operators"},
{c:711310,d: "Theatrical production managers with facilities"},
{c:711310,d: "Theatrical production organizers with facilities"},
{c:711310,d: "Theatrical production promoters with facilities"},
{c:711310,d: "Wrestling event managers with facilities"},
{c:711310,d: "Wrestling event organizers with facilities"},
{c:711310,d: "Wrestling event promoters with facilities"},
{c:711320,d: "Agricultural fair managers without facilities"},
{c:711320,d: "Agricultural fair organizers without facilities"},
{c:711320,d: "Agricultural fair promoters without facilities"},
{c:711320,d: "Air show managers without facilities"},
{c:711320,d: "Air show organizers without facilities"},
{c:711320,d: "Air show promoters without facilities"},
{c:711320,d: "Arts event managers without facilities"},
{c:711320,d: "Arts event organizers without facilities"},
{c:711320,d: "Arts event promoters without facilities"},
{c:711320,d: "Arts festival managers without facilities"},
{c:711320,d: "Arts festival organizers without facilities"},
{c:711320,d: "Arts festival promoters without facilities"},
{c:711320,d: "Beauty pageant managers without facilities"},
{c:711320,d: "Beauty pageant organizers without facilities"},
{c:711320,d: "Beauty pageant promoters without facilities"},
{c:711320,d: "Booking agencies, theatrical (except motion picture)"},
{c:711320,d: "Boxing event managers without facilities"},
{c:711320,d: "Boxing event organizers without facilities"},
{c:711320,d: "Boxing event promoters without facilities"},
{c:711320,d: "Concert booking agencies"},
{c:711320,d: "Concert managers without facilities"},
{c:711320,d: "Concert organizers without facilities"},
{c:711320,d: "Concert promoters without facilities"},
{c:711320,d: "Dance festival managers without facilities"},
{c:711320,d: "Dance festival organizers without facilities"},
{c:711320,d: "Dance festival promoters without facilities"},
{c:711320,d: "Ethnic festival managers without facilities"},
{c:711320,d: "Ethnic festival organizers without facilities"},
{c:711320,d: "Ethnic festival promoters without facilities"},
{c:711320,d: "Fair managers without facilities, agricultural"},
{c:711320,d: "Fair organizers without facilities, agricultural"},
{c:711320,d: "Fair promoters without facilities"},
{c:711320,d: "Fair promoters without facilities, agricultural"},
{c:711320,d: "Festival managers without facilities"},
{c:711320,d: "Festival of arts managers without facilities"},
{c:711320,d: "Festival of arts organizers without facilities"},
{c:711320,d: "Festival of arts promoters without facilities"},
{c:711320,d: "Festival organizers without facilities"},
{c:711320,d: "Festival promoters without facilities"},
{c:711320,d: "Heritage festival managers without facilities"},
{c:711320,d: "Heritage festival organizers without facilities"},
{c:711320,d: "Heritage festival promoters without facilities"},
{c:711320,d: "Horse show managers without facilities"},
{c:711320,d: "Horse show organizers without facilities"},
{c:711320,d: "Horse show promoters without facilities"},
{c:711320,d: "Managers of agricultural fairs without facilities"},
{c:711320,d: "Managers of arts events without facilities"},
{c:711320,d: "Managers of festivals without facilities"},
{c:711320,d: "Managers of live performing arts productions (e.g., concerts) without facilities"},
{c:711320,d: "Managers of sports events without facilities"},
{c:711320,d: "Music festival managers without facilities"},
{c:711320,d: "Music festival organizers without facilities"},
{c:711320,d: "Music festival promoters without facilities"},
{c:711320,d: "Organizers of agricultural fairs without facilities"},
{c:711320,d: "Organizers of arts events without facilities"},
{c:711320,d: "Organizers of festivals without facilities"},
{c:711320,d: "Organizers of live performing arts productions (e.g., concerts) without facilities"},
{c:711320,d: "Organizers of sports events without facilities"},
{c:711320,d: "Professional sports promoters without facilities"},
{c:711320,d: "Promoters of agricultural fairs without facilities"},
{c:711320,d: "Promoters of arts events without facilities"},
{c:711320,d: "Promoters of festivals without facilities"},
{c:711320,d: "Promoters of live performing arts productions (e.g., concerts) without facilities"},
{c:711320,d: "Promoters of sports events without facilities"},
{c:711320,d: "Rodeo managers without facilities"},
{c:711320,d: "Rodeo organizers without facilities"},
{c:711320,d: "Rodeo promoters without facilities"},
{c:711320,d: "Sports event managers without facilities"},
{c:711320,d: "Sports event organizers without facilities"},
{c:711320,d: "Sports event promoters without facilities"},
{c:711320,d: "Theater festival managers without facilities"},
{c:711320,d: "Theater festival organizers without facilities"},
{c:711320,d: "Theater festival promoters without facilities"},
{c:711320,d: "Theatrical booking agencies (except motion picture)"},
{c:711320,d: "Theatrical production managers without facilities"},
{c:711320,d: "Theatrical production organizers without facilities"},
{c:711320,d: "Theatrical production promoters without facilities"},
{c:711320,d: "Wrestling event managers without facilities"},
{c:711320,d: "Wrestling event organizers without facilities"},
{c:711320,d: "Wrestling event promoters without facilities"},
{c:711410,d: "Agents, artists'"},
{c:711410,d: "Agents, authors'"},
{c:711410,d: "Agents, celebrities'"},
{c:711410,d: "Agents, entertainers'"},
{c:711410,d: "Agents, modeling"},
{c:711410,d: "Agents, public figures'"},
{c:711410,d: "Agents, sports figures'"},
{c:711410,d: "Agents, talent"},
{c:711410,d: "Agents, theatrical talent"},
{c:711410,d: "Artists' agents or managers"},
{c:711410,d: "Authors' agents or managers"},
{c:711410,d: "Celebrities' agents or managers"},
{c:711410,d: "Entertainers' agents or managers"},
{c:711410,d: "Lecture bureaus"},
{c:711410,d: "Literary agents"},
{c:711410,d: "Management agencies for artists, entertainers, and other public figures"},
{c:711410,d: "Managers, authors'"},
{c:711410,d: "Managers, celebrities'"},
{c:711410,d: "Managers, entertainers'"},
{c:711410,d: "Managers, public figures'"},
{c:711410,d: "Managers, sports figures'"},
{c:711410,d: "Modeling agents"},
{c:711410,d: "Models' agents or managers"},
{c:711410,d: "Public figures' agents or managers"},
{c:711410,d: "Speakers' bureaus"},
{c:711410,d: "Sports figures' agents or managers"},
{c:711410,d: "Talent agencies"},
{c:711410,d: "Talent agents"},
{c:711410,d: "Theatrical talent agents"},
{c:711510,d: "Actors, independent"},
{c:711510,d: "Actresses, independent"},
{c:711510,d: "Animated cartoon artists, independent"},
{c:711510,d: "Announcers, independent radio and television"},
{c:711510,d: "Art restorers, independent"},
{c:711510,d: "Artists (except commercial, musical), independent"},
{c:711510,d: "Artists (i.e., painters), independent"},
{c:711510,d: "Authors, independent"},
{c:711510,d: "Ballet dancers, independent"},
{c:711510,d: "Cameramen, independent (freelance)"},
{c:711510,d: "Cartoonists, independent"},
{c:711510,d: "Celebrity spokespersons, independent"},
{c:711510,d: "Choreographers, independent"},
{c:711510,d: "Cinematographers, independent"},
{c:711510,d: "Comedians, independent"},
{c:711510,d: "Composers, independent"},
{c:711510,d: "Conductors, independent"},
{c:711510,d: "Conservators (i.e., art, artifact restorers), independent"},
{c:711510,d: "Costume designers, independent theatrical"},
{c:711510,d: "Dancers, independent"},
{c:711510,d: "Directors (i.e., film, motion picture, music, theatrical), independent"},
{c:711510,d: "Directors, independent motion picture"},
{c:711510,d: "Directors, independent music"},
{c:711510,d: "Disc jockeys, independent"},
{c:711510,d: "Entertainers, independent"},
{c:711510,d: "Ethnic dancers, independent"},
{c:711510,d: "Film actors, independent"},
{c:711510,d: "Film producers, independent"},
{c:711510,d: "Folk dancers, independent"},
{c:711510,d: "Ghost writers, independent"},
{c:711510,d: "Independent technical writers"},
{c:711510,d: "Interpretive dancers, independent"},
{c:711510,d: "Jazz dancers, independent"},
{c:711510,d: "Journalists, independent (freelance)"},
{c:711510,d: "Lecturers, independent"},
{c:711510,d: "Lighting technicians, theatrical, independent"},
{c:711510,d: "Magicians, independent"},
{c:711510,d: "Models, independent"},
{c:711510,d: "Motion picture directors, independent"},
{c:711510,d: "Motion picture producers, independent"},
{c:711510,d: "Motivational speakers, independent"},
{c:711510,d: "Music arrangers, independent"},
{c:711510,d: "Music directors, independent"},
{c:711510,d: "News correspondents, independent (freelance)"},
{c:711510,d: "Newspaper columnists, independent (freelance)"},
{c:711510,d: "Orchestra conductors, independent"},
{c:711510,d: "Painters (i.e., artists), independent"},
{c:711510,d: "Painting restorers, independent"},
{c:711510,d: "Performers (i.e., entertainers), independent"},
{c:711510,d: "Performing artists, independent"},
{c:711510,d: "Photographers, independent artistic"},
{c:711510,d: "Photojournalists, independent (freelance)"},
{c:711510,d: "Playwrights, independent"},
{c:711510,d: "Poets, independent"},
{c:711510,d: "Political cartoonists, independent"},
{c:711510,d: "Producers, independent"},
{c:711510,d: "Radio commentators, independent"},
{c:711510,d: "Record producers, independent"},
{c:711510,d: "Recording technicians, independent"},
{c:711510,d: "Reporters, independent (freelance)"},
{c:711510,d: "Scenery designers, independent theatrical"},
{c:711510,d: "Screenplay writers, independent"},
{c:711510,d: "Script writers, independent"},
{c:711510,d: "Sculptors, independent"},
{c:711510,d: "Set designers, independent theatrical"},
{c:711510,d: "Sketch artists, independent"},
{c:711510,d: "Song writers, independent"},
{c:711510,d: "Speakers, independent"},
{c:711510,d: "Special effect technicians, independent"},
{c:711510,d: "Sports announcers, independent"},
{c:711510,d: "Stage set (e.g., concert, motion picture, television) erecting and dismantling, independent"},
{c:711510,d: "Standup comedians, independent"},
{c:711510,d: "Storytellers, independent"},
{c:711510,d: "Taxidermists, independent"},
{c:711510,d: "Technical writers, independent"},
{c:711510,d: "Television producers, independent"},
{c:711510,d: "Wildlife artists, independent"},
{c:711510,d: "Writers of advertising copy, independent"},
{c:711510,d: "Writers, independent (freelance)"},
{c:712110,d: "Art galleries (except retail)"},
{c:712110,d: "Art museums"},
{c:712110,d: "Community museums"},
{c:712110,d: "Contemporary art museums"},
{c:712110,d: "Decorative art museums"},
{c:712110,d: "Fine arts museums"},
{c:712110,d: "Galleries, art (except retail)"},
{c:712110,d: "Halls of fame"},
{c:712110,d: "Herbariums"},
{c:712110,d: "Historical museums"},
{c:712110,d: "Human history museums"},
{c:712110,d: "Interactive museums"},
{c:712110,d: "Marine museums"},
{c:712110,d: "Military museums"},
{c:712110,d: "Mobile museums"},
{c:712110,d: "Multidisciplinary museums"},
{c:712110,d: "Museums"},
{c:712110,d: "Natural history museums"},
{c:712110,d: "Natural science museums"},
{c:712110,d: "Observatories (except research institutions)"},
{c:712110,d: "Planetariums"},
{c:712110,d: "Science and technology museums"},
{c:712110,d: "Sports halls of fame"},
{c:712110,d: "Traveling museum exhibits"},
{c:712110,d: "War museums"},
{c:712110,d: "Wax museums"},
{c:712120,d: "Archeological sites (i.e., public display)"},
{c:712120,d: "Battlefields"},
{c:712120,d: "Heritage villages"},
{c:712120,d: "Historical forts"},
{c:712120,d: "Historical ships"},
{c:712120,d: "Historical sites"},
{c:712120,d: "Pioneer villages"},
{c:712130,d: "Animal exhibits, live"},
{c:712130,d: "Animal safari parks"},
{c:712130,d: "Aquariums"},
{c:712130,d: "Arboreta"},
{c:712130,d: "Arboretums"},
{c:712130,d: "Aviaries"},
{c:712130,d: "Botanical gardens"},
{c:712130,d: "Conservatories, botanical"},
{c:712130,d: "Gardens, zoological or botanical"},
{c:712130,d: "Menageries"},
{c:712130,d: "Parks, wild animal"},
{c:712130,d: "Petting zoos"},
{c:712130,d: "Reptile exhibits, live"},
{c:712130,d: "Wild animal parks"},
{c:712130,d: "Zoological gardens"},
{c:712130,d: "Zoos"},
{c:712190,d: "Bird sanctuaries"},
{c:712190,d: "Caverns (i.e., natural wonder tourist attractions)"},
{c:712190,d: "Conservation areas"},
{c:712190,d: "Interpretive centers, nature"},
{c:712190,d: "National parks"},
{c:712190,d: "Natural wonder tourist attractions (e.g., caverns, waterfalls)"},
{c:712190,d: "Nature centers"},
{c:712190,d: "Nature parks"},
{c:712190,d: "Nature preserves"},
{c:712190,d: "Nature reserves"},
{c:712190,d: "Parks, national"},
{c:712190,d: "Parks, nature"},
{c:712190,d: "Provincial parks"},
{c:712190,d: "Waterfalls (i.e., natural wonder tourist attractions)"},
{c:712190,d: "Wildlife sanctuaries"},
{c:713110,d: "Amusement parks (e.g., theme, water)"},
{c:713110,d: "Parks (e.g., theme, water), amusement"},
{c:713110,d: "Piers, amusement"},
{c:713110,d: "Theme parks, amusement"},
{c:713110,d: "Water parks, amusement"},
{c:713120,d: "Amusement arcades"},
{c:713120,d: "Amusement device (except gambling) parlors, coin-operated"},
{c:713120,d: "Amusement devices (except gambling) operated in own facilities"},
{c:713120,d: "Arcades, amusement"},
{c:713120,d: "Electronic game arcades"},
{c:713120,d: "Family fun centers"},
{c:713120,d: "Game rooms (except gambling)"},
{c:713120,d: "Indoor play areas"},
{c:713120,d: "Pinball arcades"},
{c:713120,d: "Video game arcades (except gambling)"},
{c:713210,d: "Casinos (except casino hotels)"},
{c:713210,d: "Cruises, gambling"},
{c:713210,d: "Floating casinos (i.e., gambling cruises, riverboat casinos)"},
{c:713210,d: "Gambling cruises"},
{c:713210,d: "Riverboat casinos"},
{c:713210,d: "Stand-alone casinos (except slot machine parlors)"},
{c:713290,d: "Betting information services"},
{c:713290,d: "Bingo halls"},
{c:713290,d: "Bingo parlors"},
{c:713290,d: "Bookies"},
{c:713290,d: "Bookmakers"},
{c:713290,d: "Card rooms (e.g., poker rooms)"},
{c:713290,d: "Coin-operated gambling device concession operators (i.e., supplying and servicing in others' facilities)"},
{c:713290,d: "Gambling control boards, operating gambling activities"},
{c:713290,d: "Gambling device arcades or parlors, coin-operated"},
{c:713290,d: "Gambling device concession operators (i.e., supplying and servicing in others' facilities), coin-operated"},
{c:713290,d: "Lottery control boards (i.e., operating lotteries)"},
{c:713290,d: "Lottery corporations"},
{c:713290,d: "Lottery ticket sales agents (except retail stores)"},
{c:713290,d: "Lottery ticket vendors (except retail stores)"},
{c:713290,d: "Off-track betting parlors"},
{c:713290,d: "Slot machine concession operators (i.e., supplying and servicing in others' facilities)"},
{c:713290,d: "Slot machine parlors"},
{c:713290,d: "Video gambling device concession operators (i.e., supplying and servicing in others' facilities)"},
{c:713290,d: "Video gaming device concession operators (i.e., supplying and servicing in others' facilities)"},
{c:713910,d: "Country clubs"},
{c:713910,d: "Golf and country clubs"},
{c:713910,d: "Golf courses (except miniature, pitch-n-putt)"},
{c:713920,d: "Alpine skiing facilities without accommodations"},
{c:713920,d: "Cross country skiing facilities without accommodations"},
{c:713920,d: "Downhill skiing facilities without accommodations"},
{c:713920,d: "Four season ski resorts without accommodations"},
{c:713920,d: "Ski lift and tow operators"},
{c:713920,d: "Ski resorts without accommodations"},
{c:713920,d: "Skiing facilities, cross country, without accommodations"},
{c:713920,d: "Skiing facilities, downhill, without accommodations"},
{c:713930,d: "Boating clubs with marinas"},
{c:713930,d: "Marinas"},
{c:713930,d: "Marine basins, operation of"},
{c:713930,d: "Sailing clubs with marinas"},
{c:713930,d: "Yacht basins"},
{c:713930,d: "Yacht clubs with marinas"},
{c:713940,d: "Aerobic dance and exercise centers"},
{c:713940,d: "Athletic club facilities, physical fitness"},
{c:713940,d: "Body building studios, physical fitness"},
{c:713940,d: "Dance centers, aerobic"},
{c:713940,d: "Exercise centers"},
{c:713940,d: "Fitness centers"},
{c:713940,d: "Fitness salons"},
{c:713940,d: "Fitness spas without accommodations"},
{c:713940,d: "Gymnasiums"},
{c:713940,d: "Gyms, physical fitness"},
{c:713940,d: "Handball club facilities"},
{c:713940,d: "Health club facilities, physical fitness"},
{c:713940,d: "Health spas without accommodations, physical fitness"},
{c:713940,d: "Health studios, physical fitness"},
{c:713940,d: "Ice skating rinks"},
{c:713940,d: "Physical fitness centers"},
{c:713940,d: "Physical fitness facilities"},
{c:713940,d: "Physical fitness studios"},
{c:713940,d: "Racquetball club facilities"},
{c:713940,d: "Recreational sports club facilities"},
{c:713940,d: "Rinks, ice or roller skating"},
{c:713940,d: "Roller skating rinks"},
{c:713940,d: "Spas without accommodations, fitness"},
{c:713940,d: "Sports club facilities, physical fitness"},
{c:713940,d: "Squash club facilities"},
{c:713940,d: "Strength development centers"},
{c:713940,d: "Swimming pools"},
{c:713940,d: "Tennis club facilities"},
{c:713940,d: "Tennis courts"},
{c:713940,d: "Wave pools"},
{c:713940,d: "Weight training centers"},
{c:713950,d: "Bowling alleys"},
{c:713950,d: "Bowling centers"},
{c:713950,d: "Candle pin bowling alleys"},
{c:713950,d: "Candle pin bowling centers"},
{c:713950,d: "Duck pin bowling alleys"},
{c:713950,d: "Duck pin bowling centers"},
{c:713950,d: "Five pin bowling centers"},
{c:713950,d: "Ten pin bowling alleys"},
{c:713950,d: "Ten pin bowling centers"},
{c:713990,d: "Amateur sports teams, recreational"},
{c:713990,d: "Amusement device (except gambling) concession operators (i.e., supplying and servicing in others' facilities)"},
{c:713990,d: "Amusement ride concession operators (i.e., supplying and servicing in others' facilities)"},
{c:713990,d: "Archery ranges"},
{c:713990,d: "Athletic clubs (i.e., sports teams) not operating sports facilities, recreational"},
{c:713990,d: "Aviation clubs, recreational"},
{c:713990,d: "Ballrooms"},
{c:713990,d: "Baseball clubs, recreational"},
{c:713990,d: "Basketball clubs, recreational"},
{c:713990,d: "Bathing beaches"},
{c:713990,d: "Beach clubs, recreational"},
{c:713990,d: "Beaches, bathing"},
{c:713990,d: "Billiard parlors"},
{c:713990,d: "Billiard rooms"},
{c:713990,d: "Boating clubs without marinas"},
{c:713990,d: "Boccie ball courts"},
{c:713990,d: "Bowling leagues or teams, recreational"},
{c:713990,d: "Boxing clubs, recreational"},
{c:713990,d: "Boys' day camps (except instructional)"},
{c:713990,d: "Bridge clubs, recreational"},
{c:713990,d: "Camps (except instructional), day"},
{c:713990,d: "Canoeing, recreational"},
{c:713990,d: "Carnival ride concession operators (i.e., supplying and servicing in others' facilities)"},
{c:713990,d: "Coin-operated nongambling amusement device concession operators (i.e., supplying and servicing in others' facilities)"},
{c:713990,d: "Concession operators, amusement device (except gambling) and ride"},
{c:713990,d: "Curling facilities"},
{c:713990,d: "Dance halls"},
{c:713990,d: "Discotheques (except those serving alcoholic beverages)"},
{c:713990,d: "Driving ranges, golf"},
{c:713990,d: "Fireworks display services"},
{c:713990,d: "Fishing clubs, recreational"},
{c:713990,d: "Fishing guide services"},
{c:713990,d: "Fishing piers"},
{c:713990,d: "Flying clubs, recreational"},
{c:713990,d: "Football clubs, recreational"},
{c:713990,d: "Galleries, shooting"},
{c:713990,d: "Girls' day camps (except instructional)"},
{c:713990,d: "Gocart raceways (i.e., amusement rides)"},
{c:713990,d: "Gocart tracks (i.e., amusement rides)"},
{c:713990,d: "Golf courses, miniature"},
{c:713990,d: "Golf courses, pitch-n-putt"},
{c:713990,d: "Golf driving ranges"},
{c:713990,d: "Golf practice ranges"},
{c:713990,d: "Guide services (i.e., fishing, hunting, tourist)"},
{c:713990,d: "Guide services, fishing"},
{c:713990,d: "Guide services, hunting"},
{c:713990,d: "Guide services, tourist"},
{c:713990,d: "Gun clubs, recreational"},
{c:713990,d: "Hockey clubs, recreational"},
{c:713990,d: "Hockey teams, recreational"},
{c:713990,d: "Hookah lounges (except primarily selling food and beverages)"},
{c:713990,d: "Horse rental services, recreational saddle"},
{c:713990,d: "Horseback riding, recreational"},
{c:713990,d: "Hunting clubs, recreational"},
{c:713990,d: "Hunting guide services"},
{c:713990,d: "Ice hockey clubs, recreational"},
{c:713990,d: "Jukebox concession operators (i.e., supplying and servicing in others' facilities)"},
{c:713990,d: "Kayaking, recreational"},
{c:713990,d: "Lawn bowling clubs"},
{c:713990,d: "Miniature golf courses"},
{c:713990,d: "Mountain hiking, recreational"},
{c:713990,d: "Nightclubs without alcoholic beverages"},
{c:713990,d: "Nudist camps without accommodations"},
{c:713990,d: "Observation towers"},
{c:713990,d: "Outdoor adventure operations (e.g., white water rafting) without accommodations"},
{c:713990,d: "Outfitters (i.e., providing trips and equipment)"},
{c:713990,d: "Pack trains (i.e., trail riding), recreational"},
{c:713990,d: "Paintball, laser tag, and similar fields and arenas"},
{c:713990,d: "Para sailing, recreational"},
{c:713990,d: "Picnic grounds"},
{c:713990,d: "Pinball machine concession operators (i.e., supplying and servicing in others' facilities)"},
{c:713990,d: "Ping pong parlors"},
{c:713990,d: "Pool halls"},
{c:713990,d: "Pool parlors"},
{c:713990,d: "Pool rooms"},
{c:713990,d: "Racetracks, slot car (i.e., amusement devices)"},
{c:713990,d: "Raceways, gocart (i.e., amusement rides)"},
{c:713990,d: "Recreational camps without accommodations"},
{c:713990,d: "Recreational day camps (except instructional)"},
{c:713990,d: "Recreational sports clubs (i.e., sports teams) not operating sports facilities"},
{c:713990,d: "Recreational sports teams and leagues"},
{c:713990,d: "Riding clubs, recreational"},
{c:713990,d: "Riding stables"},
{c:713990,d: "Rifle clubs, recreational"},
{c:713990,d: "River rafting, recreational"},
{c:713990,d: "Rowing clubs, recreational"},
{c:713990,d: "Saddle horse rental services, recreational"},
{c:713990,d: "Sailing clubs without marinas"},
{c:713990,d: "Sea kayaking, recreational"},
{c:713990,d: "Shooting clubs, recreational"},
{c:713990,d: "Shooting galleries"},
{c:713990,d: "Shooting ranges"},
{c:713990,d: "Skeet shooting facilities"},
{c:713990,d: "Slot car racetracks (i.e., amusement devices)"},
{c:713990,d: "Snowmobiling, recreational"},
{c:713990,d: "Soccer clubs, recreational"},
{c:713990,d: "Sports clubs (i.e., sports teams) not operating sports facilities, recreational"},
{c:713990,d: "Sports teams and leagues, recreational or youth"},
{c:713990,d: "Stables, riding"},
{c:713990,d: "Summer day camps (except instructional)"},
{c:713990,d: "Tourist guide services"},
{c:713990,d: "Trail riding, recreational"},
{c:713990,d: "Trampoline facilities, recreational"},
{c:713990,d: "Trapshooting facilities, recreational"},
{c:713990,d: "Waterslides (i.e., amusement rides)"},
{c:713990,d: "Wax figure exhibitions, amusement"},
{c:713990,d: "White water rafting, recreational"},
{c:713990,d: "Yacht clubs without marinas"},
{c:713990,d: "Youth sports league teams"},
{c:721110,d: "Alpine skiing facilities with accommodations (i.e., ski resorts)"},
{c:721110,d: "Auto courts, lodging"},
{c:721110,d: "Automobile courts, lodging"},
{c:721110,d: "Health spas (i.e., physical fitness facilities) with accommodations"},
{c:721110,d: "Hotel management services (i.e., providing management and operating staff to run hotel)"},
{c:721110,d: "Hotels (except casino hotels)"},
{c:721110,d: "Hotels (except casino hotels) with golf courses, tennis courts, and/or other health spa facilities (i.e., resorts)"},
{c:721110,d: "Hotels, membership"},
{c:721110,d: "Hotels, resort, without casinos"},
{c:721110,d: "Hotels, seasonal, without casinos"},
{c:721110,d: "Membership hotels"},
{c:721110,d: "Motels"},
{c:721110,d: "Motor courts"},
{c:721110,d: "Motor hotels without casinos"},
{c:721110,d: "Motor inns"},
{c:721110,d: "Motor lodges"},
{c:721110,d: "Resort hotels without casinos"},
{c:721110,d: "Seasonal hotels without casinos"},
{c:721110,d: "Ski lodges and resorts with accommodations"},
{c:721110,d: "Summer resort hotels without casinos"},
{c:721110,d: "Tourist lodges"},
{c:721120,d: "Casino hotels"},
{c:721120,d: "Hotels, casino"},
{c:721120,d: "Hotels, resort, with casinos"},
{c:721120,d: "Hotels, seasonal, with casinos"},
{c:721120,d: "Resort hotels with casinos"},
{c:721191,d: "Bed and breakfast inns"},
{c:721191,d: "Inns, bed and breakfast"},
{c:721199,d: "Cabins, housekeeping"},
{c:721199,d: "Cottages, housekeeping"},
{c:721199,d: "Guest houses"},
{c:721199,d: "Hostels"},
{c:721199,d: "Housekeeping cabins"},
{c:721199,d: "Housekeeping cottages"},
{c:721199,d: "Tourist courts"},
{c:721199,d: "Tourist homes"},
{c:721199,d: "Youth hostels"},
{c:721211,d: "Campgrounds"},
{c:721211,d: "Campsites"},
{c:721211,d: "Recreational vehicle parks"},
{c:721211,d: "RV (recreational vehicle) parks"},
{c:721211,d: "Travel trailer campsites"},
{c:721214,d: "Boys' camps (except day, instructional)"},
{c:721214,d: "Camps (except day, instructional)"},
{c:721214,d: "Children's camps (except day, instructional)"},
{c:721214,d: "Dude ranches"},
{c:721214,d: "Fishing camps with accommodation facilities"},
{c:721214,d: "Girls' camps (except day, instructional)"},
{c:721214,d: "Guest ranches with accommodation facilities"},
{c:721214,d: "Hunting camps with accommodation facilities"},
{c:721214,d: "Nudist camps with accommodation facilities"},
{c:721214,d: "Outdoor adventure retreats with accommodation facilities"},
{c:721214,d: "Recreational camps with accommodation facilities (except campgrounds)"},
{c:721214,d: "Summer camps (except day, instructional)"},
{c:721214,d: "Trail riding camps with accommodation facilities"},
{c:721214,d: "Vacation camps (except campgrounds, day, instructional)"},
{c:721214,d: "Wilderness camps"},
{c:721310,d: "Boarding houses"},
{c:721310,d: "Clubs, residential"},
{c:721310,d: "Corporate rooming and boarding houses"},
{c:721310,d: "Dormitories, off campus"},
{c:721310,d: "Fraternity houses"},
{c:721310,d: "Migrant workers' camps"},
{c:721310,d: "Off campus dormitories"},
{c:721310,d: "Residence clubs, organizational"},
{c:721310,d: "Residential clubs"},
{c:721310,d: "Rooming and boarding houses"},
{c:721310,d: "Sorority houses"},
{c:721310,d: "Workers' camps"},
{c:721310,d: "Workers' dormitories"},
{c:722310,d: "Airline food services contractors"},
{c:722310,d: "Cafeteria food services contractors (e.g., government office cafeterias, hospital cafeterias, school cafeterias)"},
{c:722310,d: "Food concession contractors (e.g., convention facilities, entertainment facilities, sporting facilities)"},
{c:722310,d: "Food service contractors, airline"},
{c:722310,d: "Food service contractors, cafeteria"},
{c:722310,d: "Food service contractors, concession operators (e.g., convention facilities, entertainment facilities, sporting facilities)"},
{c:722310,d: "Food service contractors, industrial"},
{c:722310,d: "Food service contractors, institutional"},
{c:722310,d: "Industrial caterers (i.e., providing food services on a contractual arrangement (except single-event basis))"},
{c:722320,d: "Banquet halls with catering staff"},
{c:722320,d: "Caterers"},
{c:722320,d: "Catering services, social"},
{c:722330,d: "Beverage stands, nonalcoholic, mobile"},
{c:722330,d: "Canteens, mobile"},
{c:722330,d: "Coffee carts, mobile"},
{c:722330,d: "Concession snack stands, mobile"},
{c:722330,d: "Food carts, mobile"},
{c:722330,d: "Food concession stands, mobile"},
{c:722330,d: "Ice cream truck vendors"},
{c:722330,d: "Lunch wagons"},
{c:722330,d: "Mobile food stands"},
{c:722330,d: "Refreshment stands, mobile"},
{c:722330,d: "Snack stands, mobile"},
{c:722330,d: "Street vendors, food"},
{c:722410,d: "Alcoholic beverage drinking places"},
{c:722410,d: "Bars (i.e., drinking places), alcoholic beverage"},
{c:722410,d: "Cocktail lounges"},
{c:722410,d: "Discotheques, alcoholic beverage"},
{c:722410,d: "Drinking places (i.e., bars, lounges, taverns), alcoholic"},
{c:722410,d: "Lounges, cocktail"},
{c:722410,d: "Nightclubs, alcoholic beverage"},
{c:722410,d: "Tap rooms (i.e., drinking places)"},
{c:722410,d: "Taverns (i.e., drinking places)"},
{c:722511,d: "Bagel shops, full service"},
{c:722511,d: "Diners, full service"},
{c:722511,d: "Doughnut shops, full service"},
{c:722511,d: "Family restaurants, full service"},
{c:722511,d: "Fine dining restaurants, full service"},
{c:722511,d: "Full service restaurants"},
{c:722511,d: "Pizza parlors, full service"},
{c:722511,d: "Pizzerias, full service"},
{c:722511,d: "Restaurants, full service"},
{c:722511,d: "Steak houses, full service"},
{c:722513,d: "Cafes, limited-service"},
{c:722513,d: "Carryout restaurants"},
{c:722513,d: "Delicatessen restaurants"},
{c:722513,d: "Diners, limited-service"},
{c:722513,d: "Drive-in restaurants"},
{c:722513,d: "Family restaurants, limited-service"},
{c:722513,d: "Fast casual restaurants"},
{c:722513,d: "Fast-food restaurants"},
{c:722513,d: "Pizza delivery shops"},
{c:722513,d: "Pizza parlors, limited-service"},
{c:722513,d: "Pizzerias, limited-service (e.g., takeout)"},
{c:722513,d: "Restaurants, carryout"},
{c:722513,d: "Restaurants, fast-food"},
{c:722513,d: "Sandwich shops, limited-service"},
{c:722513,d: "Steak houses, limited-service"},
{c:722513,d: "Sub shops, limited-service"},
{c:722513,d: "Takeout eating places"},
{c:722514,d: "Buffet eating places"},
{c:722514,d: "Cafeterias"},
{c:722515,d: "Bagel shops, on premise baking and carryout service"},
{c:722515,d: "Bakery cafes, on premise baking and selling for immediate consumption"},
{c:722515,d: "Beverage (e.g., coffee, juice, soft drink) bars, nonalcoholic, fixed location"},
{c:722515,d: "Canteens, fixed location"},
{c:722515,d: "Coffee shops, on premise brewing"},
{c:722515,d: "Confectionery snack shops, made on premises with carryout services"},
{c:722515,d: "Cookie shops, on premise baking and carryout service"},
{c:722515,d: "Doughnut shops, on premise baking and carryout service"},
{c:722515,d: "Fixed location refreshment stands"},
{c:722515,d: "Frozen custard stands, fixed location"},
{c:722515,d: "Ice cream parlors"},
{c:722515,d: "Pretzel shops, on premise baking and carryout service"},
{c:722515,d: "Refreshment stands, fixed location"},
{c:722515,d: "Snack bars (e.g., cookies, popcorn, pretzels), fixed location"},
{c:722515,d: "Soft drink beverage bars, nonalcoholic, fixed location"},
{c:811111,d: "Automotive engine repair and replacement shops"},
{c:811111,d: "Automotive fleet repair and maintenance services"},
{c:811111,d: "Automotive repair and replacement shops, general"},
{c:811111,d: "Car repair shops, general"},
{c:811111,d: "Diesel engine repair shops, automotive"},
{c:811111,d: "Engine repair and replacement shops, automotive"},
{c:811111,d: "Garages, general automotive repair (except gasoline service stations)"},
{c:811111,d: "General automotive repair shops"},
{c:811111,d: "Mobile automotive and truck repair services"},
{c:811111,d: "Truck repair shops, general"},
{c:811112,d: "Automotive exhaust system repair and replacement shops"},
{c:811112,d: "Exhaust system repair and replacement shops, automotive"},
{c:811112,d: "Muffler repair and replacement shops"},
{c:811113,d: "Automotive transmission repair shops"},
{c:811113,d: "Transmission repair shops, automotive"},
{c:811118,d: "Automotive brake repair shops"},
{c:811118,d: "Automotive electrical repair shops"},
{c:811118,d: "Automotive front end alignment shops"},
{c:811118,d: "Automotive radiator repair shops"},
{c:811118,d: "Automotive tune-up shops"},
{c:811118,d: "Brake repair shops, automotive"},
{c:811118,d: "Drive shaft repair shops, automotive"},
{c:811118,d: "Electrical repair shops, automotive"},
{c:811118,d: "Front end alignment shops, automotive"},
{c:811118,d: "Ignition and battery repair shops, automotive"},
{c:811118,d: "Radiator repair shops, automotive"},
{c:811118,d: "Suspension repair shops, automotive"},
{c:811118,d: "Tune-up shops, automotive"},
{c:811118,d: "Wheel alignment shops, automotive"},
{c:811121,d: "Antique and classic automotive restoration"},
{c:811121,d: "Automotive body shops"},
{c:811121,d: "Automotive paint shops"},
{c:811121,d: "Automotive upholstery shops"},
{c:811121,d: "Body conversion services, automotive"},
{c:811121,d: "Body shops, automotive"},
{c:811121,d: "Interior repair shops, automotive"},
{c:811121,d: "Paint shops, automotive"},
{c:811121,d: "Restoration shops, antique and classic automotive"},
{c:811121,d: "Truck trailer body shops"},
{c:811121,d: "Truck trailer paint and body repair"},
{c:811121,d: "Upholstery shops, automotive"},
{c:811121,d: "Van conversion shops (except on assembly line or factory basis)"},
{c:811122,d: "Automotive glass shops"},
{c:811122,d: "Glass installation, automotive repair"},
{c:811122,d: "Glass shops, automotive"},
{c:811122,d: "Glass tinting, automotive"},
{c:811122,d: "Glass work, automotive"},
{c:811122,d: "Window tinting, automotive"},
{c:811122,d: "Windshield repair shops, automotive"},
{c:811191,d: "Automotive oil change and lubrication shops"},
{c:811191,d: "Lubrication shops, automotive"},
{c:811191,d: "Oil change and lubrication shops, automotive"},
{c:811191,d: "Quick-lube shops"},
{c:811192,d: "Automotive detail shops"},
{c:811192,d: "Automotive detailing services (i.e., cleaning, polishing)"},
{c:811192,d: "Automotive washing and polishing"},
{c:811192,d: "Car detailers"},
{c:811192,d: "Car washes"},
{c:811192,d: "Detailing services (i.e., cleaning and polishing), automotive"},
{c:811192,d: "Mobile car and truck washes"},
{c:811192,d: "Self-service car washes"},
{c:811192,d: "Truck and bus washes"},
{c:811198,d: "Automotive air-conditioning repair shops"},
{c:811198,d: "Automotive emissions testing services"},
{c:811198,d: "Automotive rustproofing and undercoating shops"},
{c:811198,d: "Automotive safety inspection services"},
{c:811198,d: "Automotive tire repair (except retreading) shops"},
{c:811198,d: "Diagnostic centers without repair, automotive"},
{c:811198,d: "Emissions testing without repair, automotive"},
{c:811198,d: "Garages, do-it-yourself automotive repair"},
{c:811198,d: "Rustproofing shops, automotive"},
{c:811198,d: "Spray-on bedliner installation for trucks"},
{c:811198,d: "Tire repair shops (except retreading), automotive"},
{c:811198,d: "Undercoating shops, automotive"},
{c:811211,d: "Camera repair shops without retailing new cameras"},
{c:811211,d: "Consumer electronics repair and maintenance services without retailing new consumer electronics"},
{c:811211,d: "Photographic equipment repair shops without retailing new photographic equipment"},
{c:811211,d: "Radio (except two-way radio) repair and maintenance services without retailing new radios"},
{c:811211,d: "Radio repair, automotive, without retailing new"},
{c:811211,d: "Stereo equipment repair shops without retailing new stereo equipment"},
{c:811211,d: "Television repair services without retailing new televisions"},
{c:811211,d: "Video cassette recorder (VCR) repair services without retailing new video cassette recorders"},
{c:811212,d: "Computer equipment repair and maintenance services without retailing new computers"},
{c:811212,d: "Computer peripheral equipment repair and maintenance, without retailing new computer peripheral equipment"},
{c:811212,d: "Computer repair and maintenance services, without retailing new computers"},
{c:811212,d: "Office machine repair and maintenance services (except communication equipment)"},
{c:811212,d: "Photocopying machine repair and maintenance services without retailing new photocopying machines"},
{c:811212,d: "Recycling inkjet cartridges"},
{c:811212,d: "Typewriter repair and maintenance services"},
{c:811213,d: "Broadcast equipment repair and maintenance services"},
{c:811213,d: "Communication equipment repair and maintenance services"},
{c:811213,d: "Facsimile machine repair and maintenance services"},
{c:811213,d: "Fax machine repair and maintenance services"},
{c:811213,d: "Public address system repair and maintenance services"},
{c:811213,d: "Telephone equipment repair and maintenance services without retailing new telephone equipment"},
{c:811213,d: "Two-way radio repair and maintenance services"},
{c:811219,d: "Dental equipment repair and maintenance services"},
{c:811219,d: "Diagnostic imaging equipment repair and maintenance services"},
{c:811219,d: "Electrical measuring instrument repair and maintenance services"},
{c:811219,d: "Laboratory instrument repair and maintenance services"},
{c:811219,d: "Measuring instrument repair and maintenance services"},
{c:811219,d: "Medical and surgical equipment repair and maintenance services"},
{c:811219,d: "Meteorological instrument repair and maintenance services"},
{c:811219,d: "Navigational instruments (e.g., radar, sonar) repair and maintenance services"},
{c:811219,d: "Optical instrument repair and maintenance services (e.g., microscopes, telescopes)"},
{c:811219,d: "Precision equipment calibration"},
{c:811219,d: "Radar and sonar equipment repair and maintenance services"},
{c:811219,d: "Surgical instrument repair and maintenance services"},
{c:811219,d: "Surveying instrument repair and maintenance services"},
{c:811219,d: "Weighing equipment (e.g., balance, scales) repair and maintenance services"},
{c:811310,d: "Agricultural machinery and equipment repair and maintenance services"},
{c:811310,d: "Armature rewinding services (except on an assembly line or factory basis)"},
{c:811310,d: "Blade sharpening, commercial and industrial machinery and equipment"},
{c:811310,d: "Cargo container repair and maintenance services"},
{c:811310,d: "Coil rewinding (except on an assembly line or factory basis)"},
{c:811310,d: "Commercial and industrial machinery repair and maintenance services"},
{c:811310,d: "Commercial gaming machine repair and maintenance services"},
{c:811310,d: "Commercial refrigeration equipment repair and maintenance services"},
{c:811310,d: "Construction machinery and equipment repair and maintenance services"},
{c:811310,d: "Electric motor repair and maintenance services, commercial or industrial"},
{c:811310,d: "Electrical generating and transmission equipment repair and maintenance services"},
{c:811310,d: "Engine repair (except automotive, small engine)"},
{c:811310,d: "Farm machinery and equipment repair and maintenance services"},
{c:811310,d: "Fire extinguisher repair and maintenance, without installation"},
{c:811310,d: "Food machinery repair and maintenance services"},
{c:811310,d: "Forestry machinery and equipment repair and maintenance services"},
{c:811310,d: "Forklift repair and maintenance services"},
{c:811310,d: "Foundry machinery and equipment repair and maintenance services"},
{c:811310,d: "Freezer, commercial, repair and maintenance services"},
{c:811310,d: "Heavy machinery and equipment repair and maintenance services"},
{c:811310,d: "Hydraulic equipment repair and maintenance services"},
{c:811310,d: "Industrial equipment and machinery repair and maintenance services"},
{c:811310,d: "Industrial truck (e.g., forklifts) repair and maintenance services"},
{c:811310,d: "Machine tools repair and maintenance services"},
{c:811310,d: "Material handling equipment repair and maintenance services"},
{c:811310,d: "Mechanical power transmission equipment repair and maintenance services"},
{c:811310,d: "Mining machinery and equipment repair and maintenance services"},
{c:811310,d: "Motor repair and maintenance services, commercial or industrial"},
{c:811310,d: "Oil and gas field machinery and equipment repair and maintenance services"},
{c:811310,d: "Paper making machinery repair and maintenance services"},
{c:811310,d: "Printing trade machinery repair and maintenance services"},
{c:811310,d: "Reconditioning shipping barrels and drums"},
{c:811310,d: "Refrigeration equipment repair and maintenance services, industrial and commercial-type"},
{c:811310,d: "Rewinding armatures (except on an assembly line or factory basis)"},
{c:811310,d: "Service machinery and equipment repair and maintenance services"},
{c:811310,d: "Textile machinery repair and maintenance services"},
{c:811310,d: "Tractor, farm or construction equipment repair and maintenance services"},
{c:811310,d: "Truck refrigeration repair and maintenance services"},
{c:811310,d: "Waterless fire suppression system repair and maintenance, without installation"},
{c:811310,d: "Welding repair services (e.g., automotive, general)"},
{c:811411,d: "Engine repair, small"},
{c:811411,d: "Garden equipment repair and maintenance services without retailing new garden equipment"},
{c:811411,d: "Garden tool sharpening and repair services"},
{c:811411,d: "Handtools, power-driven, repair and maintenance services without retailing new power-driven handtools"},
{c:811411,d: "Lawn and garden equipment repair and maintenance services without retailing new lawn and garden equipment"},
{c:811411,d: "Lawnmower repair and maintenance shops without retailing new lawnmowers"},
{c:811411,d: "Saw repair and maintenance (except sawmills) without retailing new saws"},
{c:811411,d: "Small engine repair and maintenance shops"},
{c:811411,d: "Tool, home and garden, sharpening and repair services without retailing new home and garden tools"},
{c:811411,d: "Tractor, lawn and garden, repair and maintenance services without retailing new lawn and garden tractors"},
{c:811412,d: "Air-conditioner, window, repair and maintenance services"},
{c:811412,d: "Appliance, household-type, repair and maintenance services without retailing new appliances"},
{c:811412,d: "Clothes dryer, household-type, repair and maintenance services without retailing new clothes dryers"},
{c:811412,d: "Dishwasher, household-type, repair and maintenance services without retailing new dishwashers"},
{c:811412,d: "Microwave oven, household-type, repair and maintenance services, without retailing new microwave ovens"},
{c:811412,d: "Oven, household-type, repair and maintenance services without retailing new ovens"},
{c:811412,d: "Refrigerator, household-type, repair and maintenance services without retailing new refrigerators"},
{c:811412,d: "Sewing machine, household-type, repair shops without retailing new sewing machines"},
{c:811412,d: "Stove, household-type, repair and maintenance services without retailing new stoves"},
{c:811412,d: "Washing machine, household-type, repair and maintenance services without retailing new washing machine"},
{c:811412,d: "Water heater repair and maintenance services without retailing new water heaters"},
{c:811420,d: "Aircraft upholstery repair"},
{c:811420,d: "Antique furniture repair and restoration shops"},
{c:811420,d: "Furniture refinishing shops"},
{c:811420,d: "Furniture repair shops"},
{c:811420,d: "Furniture reupholstering shops"},
{c:811420,d: "Restoration and repair of antique furniture"},
{c:811420,d: "Reupholstery shops, furniture"},
{c:811420,d: "Upholstery (except motor vehicle) repair services"},
{c:811430,d: "Boot repair shops without retailing new boots"},
{c:811430,d: "Leather goods repair shops without retailing new leather goods"},
{c:811430,d: "Luggage repair shops without retailing new luggage"},
{c:811430,d: "Saddlery repair shops without retailing new saddlery"},
{c:811430,d: "Shoe repair shops without retailing new shoes"},
{c:811490,d: "Bicycle repair and maintenance shops without retailing new bicycles"},
{c:811490,d: "Boat, pleasure, repair and maintenance services without retailing new boats"},
{c:811490,d: "China repair services"},
{c:811490,d: "Clock repair shops without retailing new clocks"},
{c:811490,d: "Clothing repair shops, alterations only"},
{c:811490,d: "Cutlery (e.g., knives, scissors) sharpening, household-type"},
{c:811490,d: "Exercise and athletic equipment repair and maintenance services without retailing new exercise and athletic equipment"},
{c:811490,d: "Fur garment repair shops without retailing new fur garments"},
{c:811490,d: "Garment alteration and/or repair shops without retailing new garments"},
{c:811490,d: "Gun repair and maintenance shops without retailing new guns"},
{c:811490,d: "Gunsmith shops without retailing new guns"},
{c:811490,d: "Jewelry repair shops without retailing new jewelry"},
{c:811490,d: "Key duplicating shops"},
{c:811490,d: "Motorboat (i.e., inboard and outboard) repair and maintenance services"},
{c:811490,d: "Motorcycle repair shops without retailing new motorcycles"},
{c:811490,d: "Musical instrument repair shops without retailing new musical instruments"},
{c:811490,d: "Outboard motor repair shops"},
{c:811490,d: "Pleasure boat maintenance services (e.g., cleaning, scaling, waxing)"},
{c:811490,d: "Sporting equipment repair and maintenance without retailing new sports equipment"},
{c:811490,d: "Tailor shops, alterations only"},
{c:811490,d: "Tuning and repair of musical instruments"},
{c:811490,d: "Venetian blind repair and maintenance shops without retailing new venetian blinds"},
{c:811490,d: "Watch repair shops without retailing new watches"},
{c:811490,d: "Window shade repair and maintenance shops"},
{c:812111,d: "Barber shops"},
{c:812111,d: "Hair stylist services, men's"},
{c:812111,d: "Hair stylist shops, men's"},
{c:812112,d: "Beautician services"},
{c:812112,d: "Beauty and barber shops, combined"},
{c:812112,d: "Beauty parlors"},
{c:812112,d: "Beauty salons"},
{c:812112,d: "Beauty shops"},
{c:812112,d: "Cosmetology salons or shops"},
{c:812112,d: "Esthetician (i.e., skin care) services"},
{c:812112,d: "Facial salons"},
{c:812112,d: "Hair stylist salons or shops, unisex or women's"},
{c:812112,d: "Hair stylist services, unisex or women's"},
{c:812112,d: "Hairdresser services"},
{c:812112,d: "Hairdressing salons or shops, unisex or women's"},
{c:812112,d: "Makeup (except permanent) salons"},
{c:812112,d: "Unisex hair stylist shops"},
{c:812113,d: "Manicure and pedicure salons"},
{c:812113,d: "Manicurist services"},
{c:812113,d: "Nail salons"},
{c:812113,d: "Pedicure and manicure salons"},
{c:812113,d: "Pedicurist services"},
{c:812191,d: "Diet centers, non-medical"},
{c:812191,d: "Diet workshops"},
{c:812191,d: "Weight loss centers, non-medical"},
{c:812191,d: "Weight reducing centers, non-medical"},
{c:812199,d: "Baths, steam or turkish"},
{c:812199,d: "Color consulting services (i.e., personal care services)"},
{c:812199,d: "Day spas"},
{c:812199,d: "Depilatory (i.e., hair removal) salons"},
{c:812199,d: "Ear piercing services"},
{c:812199,d: "Electrolysis (i.e., hair removal) salons"},
{c:812199,d: "Hair removal (i.e., depilatory, electrolysis, laser, waxing) services"},
{c:812199,d: "Hair replacement services (except by offices of physicians)"},
{c:812199,d: "Hair weaving services"},
{c:812199,d: "Makeup salons, permanent"},
{c:812199,d: "Massage parlors"},
{c:812199,d: "Saunas"},
{c:812199,d: "Scalp treating services"},
{c:812199,d: "Steam baths"},
{c:812199,d: "Sun tanning salons"},
{c:812199,d: "Tanning salons"},
{c:812199,d: "Tattoo parlors"},
{c:812199,d: "Turkish bathhouses"},
{c:812199,d: "Turkish baths"},
{c:812210,d: "Director services, funeral"},
{c:812210,d: "Embalming services"},
{c:812210,d: "Funeral director services"},
{c:812210,d: "Funeral homes"},
{c:812210,d: "Funeral homes combined with crematories"},
{c:812210,d: "Funeral parlors"},
{c:812210,d: "Mortician services"},
{c:812210,d: "Mortuaries"},
{c:812210,d: "Undertaker services"},
{c:812220,d: "Animal cemeteries"},
{c:812220,d: "Cemeteries"},
{c:812220,d: "Cemetery associations (i.e., operators)"},
{c:812220,d: "Cemetery management services"},
{c:812220,d: "Cemetery subdividers"},
{c:812220,d: "Columbariums"},
{c:812220,d: "Crematories (except combined with funeral homes)"},
{c:812220,d: "Mausoleums"},
{c:812220,d: "Memorial gardens (i.e., burial places)"},
{c:812220,d: "Pet cemeteries"},
{c:812310,d: "Automatic laundries, coin-operated"},
{c:812310,d: "Coin-operated drycleaners and laundries"},
{c:812310,d: "Coin-operated laundry and drycleaning routes (i.e., concession operators)"},
{c:812310,d: "Drycleaning machine routes (i.e., concession operators), coin-operated or similar self-service"},
{c:812310,d: "Drycleaning services, coin-operated or similar self-service"},
{c:812310,d: "Launderettes"},
{c:812310,d: "Laundries, coin-operated or similar self-service"},
{c:812310,d: "Laundromats"},
{c:812310,d: "Laundry machine routes (i.e., concession operators), coin-operated or similar self-service"},
{c:812310,d: "Laundry services, coin-operated or similar self-service"},
{c:812310,d: "Self-service drycleaners and laundries"},
{c:812320,d: "Agents, laundry and drycleaning"},
{c:812320,d: "Apparel pressing services"},
{c:812320,d: "Bobtailers, laundry and drycleaning"},
{c:812320,d: "Cleaners, drycleaning and laundry service (except coin-operated)"},
{c:812320,d: "Cleaning and dyeing plants (except rug cleaning plants)"},
{c:812320,d: "Collecting and distributing agents, laundry and drycleaning"},
{c:812320,d: "Curtain cleaning services"},
{c:812320,d: "Drapery cleaning services"},
{c:812320,d: "Drop-off and pick-up sites for laundries and drycleaners"},
{c:812320,d: "Drycleaner drop-off and pick-up sites"},
{c:812320,d: "Drycleaners (except coin-operated)"},
{c:812320,d: "Drycleaning plants (except rug cleaning plants)"},
{c:812320,d: "Drycleaning services (except coin-operated)"},
{c:812320,d: "Fur garment cleaning services"},
{c:812320,d: "Garment cleaning (e.g., fur, leather, suede) services"},
{c:812320,d: "Hand laundries"},
{c:812320,d: "Hat cleaning services"},
{c:812320,d: "Laundries (except coin-operated, linen supply, uniform supply)"},
{c:812320,d: "Laundry and drycleaning agents"},
{c:812320,d: "Laundry drop-off and pick-up sites"},
{c:812320,d: "Laundry services (except coin-operated, linen supply, uniform supply)"},
{c:812320,d: "Leather garment cleaning services"},
{c:812320,d: "Pick-up and drop-off sites for drycleaners and laundries"},
{c:812320,d: "Pillow cleaning services"},
{c:812320,d: "Power laundries, family"},
{c:812320,d: "Suede garment cleaning services"},
{c:812331,d: "Apron supply services"},
{c:812331,d: "Bed linen supply services"},
{c:812331,d: "Coat (e.g., barber's, beautician's, doctor's, nurse's) supply services"},
{c:812331,d: "Cooperative hospital laundries (i.e., linen supply services)"},
{c:812331,d: "Diaper supply services"},
{c:812331,d: "Gown (e.g., doctors, nurses, hospital, beauticians) supply services"},
{c:812331,d: "Laundries, linen and uniform supply"},
{c:812331,d: "Laundry services, linen supply"},
{c:812331,d: "Linen supply services"},
{c:812331,d: "Table linen supply services"},
{c:812331,d: "Towel (except shop, wiping) supply services"},
{c:812331,d: "Uniform (except industrial) supply services"},
{c:812332,d: "Clean room apparel supply services"},
{c:812332,d: "Dust control textile item (e.g., cloths, mats, mops, rugs, shop towels) supply services"},
{c:812332,d: "Flame resistant clothing supply services"},
{c:812332,d: "Industrial launderers"},
{c:812332,d: "Industrial uniform supply services"},
{c:812332,d: "Laundered mat and rug supply services"},
{c:812332,d: "Launderers, industrial"},
{c:812332,d: "Laundry services, industrial"},
{c:812332,d: "Mat and rug supply services"},
{c:812332,d: "Protective apparel supply services"},
{c:812332,d: "Radiation protection garment supply services"},
{c:812332,d: "Towel supply services, shop or wiping"},
{c:812332,d: "Uniform supply services, industrial"},
{c:812332,d: "Work clothing and uniform supply services, industrial"},
{c:812910,d: "Animal grooming services"},
{c:812910,d: "Animal shelters"},
{c:812910,d: "Boarding services, pet"},
{c:812910,d: "Dog pounds"},
{c:812910,d: "Grooming services, animal"},
{c:812910,d: "Guard dog training services"},
{c:812910,d: "Guide dog training services"},
{c:812910,d: "Kennels, pet boarding"},
{c:812910,d: "Obedience training services, pet"},
{c:812910,d: "Pedigree record services, pet"},
{c:812910,d: "Pet boarding services"},
{c:812910,d: "Pet grooming services"},
{c:812910,d: "Pet sitting services"},
{c:812910,d: "Pet training services"},
{c:812910,d: "Sitting services, pet"},
{c:812910,d: "Working, sporting, and service dog training services"},
{c:812921,d: "Film developing and printing (except motion picture, one-hour)"},
{c:812921,d: "Photofinishing labs (except one-hour)"},
{c:812921,d: "Photofinishing services (except one-hour)"},
{c:812922,d: "Film developing and printing, one-hour"},
{c:812922,d: "One-hour photofinishing services"},
{c:812922,d: "Photofinishing labs, one-hour"},
{c:812922,d: "Photofinishing services, one-hour"},
{c:812930,d: "Automobile parking garages or lots"},
{c:812930,d: "Garages, automobile parking"},
{c:812930,d: "Parking garages, automobile"},
{c:812930,d: "Parking lots, automobile"},
{c:812930,d: "Parking services, valet"},
{c:812930,d: "Valet parking services"},
{c:812990,d: "Astrology services"},
{c:812990,d: "Baby shoe bronzing services"},
{c:812990,d: "Bail bonding services"},
{c:812990,d: "Balloon-o-gram services"},
{c:812990,d: "Blood pressure testing machine concession operators, coin-operated"},
{c:812990,d: "Bondsperson services"},
{c:812990,d: "Bootblack parlors"},
{c:812990,d: "Check room services"},
{c:812990,d: "Coin-operated personal service machine (e.g., blood pressure, locker, photographic, scale, shoeshine) concession operators"},
{c:812990,d: "Comfort station operation"},
{c:812990,d: "Concierge services"},
{c:812990,d: "Consumer buying services"},
{c:812990,d: "Credit card notification services (i.e., lost or stolen card reporting)"},
{c:812990,d: "Dating services"},
{c:812990,d: "Discount buying services, including medical cards and similar negotiated discount plans for individuals"},
{c:812990,d: "Doula services (providing coaching and support during childbirth)"},
{c:812990,d: "Escort services, social"},
{c:812990,d: "Fortune-telling services"},
{c:812990,d: "Genealogical investigation services"},
{c:812990,d: "House sitting services"},
{c:812990,d: "Identity theft protection services"},
{c:812990,d: "Introduction services, social"},
{c:812990,d: "Life coach services"},
{c:812990,d: "Lockers, coin-operated, rental"},
{c:812990,d: "Numerology services"},
{c:812990,d: "Palm reading services"},
{c:812990,d: "Party planning services"},
{c:812990,d: "Pay telephone equipment concession operators"},
{c:812990,d: "Personal chef services"},
{c:812990,d: "Personal fitness training services"},
{c:812990,d: "Personal organizer services"},
{c:812990,d: "Personal shopping services"},
{c:812990,d: "Photographic machine concession operators, coin-operated"},
{c:812990,d: "Porter services"},
{c:812990,d: "Psychic services"},
{c:812990,d: "Rest room operation"},
{c:812990,d: "Shoeshine parlors"},
{c:812990,d: "Shoeshine services"},
{c:812990,d: "Shopping services, personal"},
{c:812990,d: "Singing telegram services"},
{c:812990,d: "Sitting services, house"},
{c:812990,d: "Social escort services"},
{c:812990,d: "Telegram services, singing"},
{c:812990,d: "Wedding chapels (except churches)"},
{c:812990,d: "Wedding planning services"},
{c:813110,d: "Bible societies"},
{c:813110,d: "Churches"},
{c:813110,d: "Convents (except schools)"},
{c:813110,d: "Ministries, religious"},
{c:813110,d: "Missions, religious organization"},
{c:813110,d: "Monasteries (except schools)"},
{c:813110,d: "Mosques, religious"},
{c:813110,d: "Places of worship"},
{c:813110,d: "Religious organizations"},
{c:813110,d: "Retreat houses, religious"},
{c:813110,d: "Shrines, religious"},
{c:813110,d: "Synagogues"},
{c:813110,d: "Temples, religious"},
{c:813211,d: "Charitable trusts, awarding grants"},
{c:813211,d: "Community foundations"},
{c:813211,d: "Corporate foundations, awarding grants"},
{c:813211,d: "Educational trusts, awarding grants"},
{c:813211,d: "Grantmaking foundations"},
{c:813211,d: "Philanthropic trusts, awarding grants"},
{c:813211,d: "Scholarship trusts (i.e., grantmaking, charitable trust foundations)"},
{c:813211,d: "Trusts, charitable, awarding grants"},
{c:813211,d: "Trusts, educational, awarding grants"},
{c:813211,d: "Trusts, religious, awarding grants"},
{c:813212,d: "Disease awareness fundraising organizations"},
{c:813212,d: "Disease research (e.g., cancer, heart) fundraising organizations"},
{c:813212,d: "Health research fundraising organizations"},
{c:813212,d: "Voluntary health organizations"},
{c:813219,d: "Community chests"},
{c:813219,d: "Federated charities"},
{c:813219,d: "United fund councils"},
{c:813219,d: "United funds for colleges"},
{c:813311,d: "Associations for retired persons, advocacy"},
{c:813311,d: "Civil liberties organizations"},
{c:813311,d: "Developmentally disabled advocacy organizations"},
{c:813311,d: "Human rights advocacy organizations"},
{c:813311,d: "Intellectually and developmentally disabled advocacy groups"},
{c:813311,d: "Senior citizens advocacy organizations"},
{c:813311,d: "Veterans' rights organizations"},
{c:813312,d: "Animal rights organizations"},
{c:813312,d: "Animal welfare associations or leagues"},
{c:813312,d: "Conservation advocacy organizations"},
{c:813312,d: "Environmental advocacy organizations"},
{c:813312,d: "Humane societies"},
{c:813312,d: "Natural resource preservation organizations"},
{c:813312,d: "Wildlife preservation organizations"},
{c:813319,d: "Accident prevention associations"},
{c:813319,d: "Antipoverty advocacy organizations"},
{c:813319,d: "Aviation advocacy organizations"},
{c:813319,d: "Community action advocacy organizations"},
{c:813319,d: "Drug abuse prevention advocacy organizations"},
{c:813319,d: "Drunk driving prevention advocacy organizations"},
{c:813319,d: "Firearms advocacy organizations"},
{c:813319,d: "Gun control organizations"},
{c:813319,d: "Hunting, fishing, and sport shooting advocacy organizations"},
{c:813319,d: "Neighborhood development advocacy organizations"},
{c:813319,d: "Peace advocacy organizations"},
{c:813319,d: "Public safety advocacy organizations"},
{c:813319,d: "Social change advocacy organizations"},
{c:813319,d: "Social service advocacy organizations"},
{c:813319,d: "Substance abuse prevention advocacy organizations"},
{c:813319,d: "Taxpayers' advocacy organizations"},
{c:813319,d: "Temperance organizations"},
{c:813319,d: "Tenants' advocacy associations"},
{c:813319,d: "Tenants' associations, advocacy"},
{c:813319,d: "World peace and understanding advocacy organizations"},
{c:813410,d: "Alumni associations"},
{c:813410,d: "Alumni clubs"},
{c:813410,d: "Automobile clubs (except road and travel services)"},
{c:813410,d: "Book discussion clubs"},
{c:813410,d: "Booster clubs"},
{c:813410,d: "Boy guiding organizations"},
{c:813410,d: "Civic associations"},
{c:813410,d: "Classic car clubs"},
{c:813410,d: "Computer enthusiasts clubs"},
{c:813410,d: "Ethnic associations"},
{c:813410,d: "Fan clubs"},
{c:813410,d: "Farm granges"},
{c:813410,d: "Fraternal associations or lodges, social or civic"},
{c:813410,d: "Fraternal lodges"},
{c:813410,d: "Fraternal organizations"},
{c:813410,d: "Fraternities (except residential)"},
{c:813410,d: "Garden clubs"},
{c:813410,d: "Girl guiding organizations"},
{c:813410,d: "Golden age clubs"},
{c:813410,d: "Granges"},
{c:813410,d: "Historical clubs"},
{c:813410,d: "Membership associations, civic or social"},
{c:813410,d: "Parent-teachers' associations"},
{c:813410,d: "Poetry clubs"},
{c:813410,d: "Public speaking improvement clubs"},
{c:813410,d: "Retirement associations, social"},
{c:813410,d: "Scouting organizations"},
{c:813410,d: "Senior citizens' associations, social"},
{c:813410,d: "Singing societies"},
{c:813410,d: "Social clubs"},
{c:813410,d: "Social organizations, civic and fraternal"},
{c:813410,d: "Sororities (except residential)"},
{c:813410,d: "Speakers' clubs"},
{c:813410,d: "Student clubs"},
{c:813410,d: "Students' associations"},
{c:813410,d: "Students' unions"},
{c:813410,d: "University clubs"},
{c:813410,d: "Veterans'  membership organizations"},
{c:813410,d: "Women's auxiliaries"},
{c:813410,d: "Women's clubs"},
{c:813410,d: "Writing clubs"},
{c:813410,d: "Youth civic clubs"},
{c:813410,d: "Youth clubs (except recreational only)"},
{c:813410,d: "Youth farming organizations"},
{c:813410,d: "Youth scouting organizations"},
{c:813410,d: "Youth social clubs"},
{c:813910,d: "Agricultural organizations (except youth farming organizations, farm granges)"},
{c:813910,d: "Animal breeders' associations"},
{c:813910,d: "Bankers' associations"},
{c:813910,d: "Better business bureaus"},
{c:813910,d: "Boards of trade"},
{c:813910,d: "Business associations"},
{c:813910,d: "Chambers of commerce"},
{c:813910,d: "Construction associations"},
{c:813910,d: "Contractors' associations"},
{c:813910,d: "Distributors' associations"},
{c:813910,d: "Farmers' associations"},
{c:813910,d: "Farmers' unions"},
{c:813910,d: "Growers' associations"},
{c:813910,d: "Hospital associations"},
{c:813910,d: "Industrial associations"},
{c:813910,d: "Insurers' associations"},
{c:813910,d: "Junior chambers of commerce"},
{c:813910,d: "Manufacturers' associations"},
{c:813910,d: "Merchants' associations"},
{c:813910,d: "Mining associations"},
{c:813910,d: "Producers' associations"},
{c:813910,d: "Public utility associations"},
{c:813910,d: "Real estate boards"},
{c:813910,d: "Restaurant associations"},
{c:813910,d: "Retailers' associations"},
{c:813910,d: "Service industries associations"},
{c:813910,d: "Shipping companies' associations"},
{c:813910,d: "Trade associations"},
{c:813910,d: "Warehousing associations"},
{c:813910,d: "Wholesalers' associations"},
{c:813920,d: "Accountants' associations"},
{c:813920,d: "Architects' associations"},
{c:813920,d: "Bar associations"},
{c:813920,d: "Consultants' associations"},
{c:813920,d: "Dentists' associations"},
{c:813920,d: "Dietitians' associations"},
{c:813920,d: "Educators' associations"},
{c:813920,d: "Engineers' associations"},
{c:813920,d: "Health professionals' associations"},
{c:813920,d: "Hospital administrators' associations"},
{c:813920,d: "Learned societies"},
{c:813920,d: "Medical associations"},
{c:813920,d: "Nurses' associations"},
{c:813920,d: "Occupational therapists' associations"},
{c:813920,d: "Optometrists' associations"},
{c:813920,d: "Peer review boards"},
{c:813920,d: "Personnel management associations"},
{c:813920,d: "Pharmacists' associations"},
{c:813920,d: "Professional associations"},
{c:813920,d: "Professional membership associations"},
{c:813920,d: "Professional standards review boards"},
{c:813920,d: "Psychologists' associations"},
{c:813920,d: "Scientific associations"},
{c:813920,d: "Social workers' associations"},
{c:813920,d: "Standards review committees, professional"},
{c:813930,d: "Collective bargaining units"},
{c:813930,d: "Employees' associations for improvement of wages and working conditions"},
{c:813930,d: "Federation of workers, labor organizations"},
{c:813930,d: "Federations of labor"},
{c:813930,d: "Industrial labor unions"},
{c:813930,d: "Labor federations"},
{c:813930,d: "Labor unions (except apprenticeship programs)"},
{c:813930,d: "Trade unions (except apprenticeship programs)"},
{c:813930,d: "Trade unions, local"},
{c:813930,d: "Unions (except apprenticeship programs), labor"},
{c:813940,d: "Campaign organizations, political"},
{c:813940,d: "Constituencies' associations, political party"},
{c:813940,d: "Local political organizations"},
{c:813940,d: "PACs (Political Action Committees)"},
{c:813940,d: "Political action committees (PACs)"},
{c:813940,d: "Political campaign organizations"},
{c:813940,d: "Political organizations or clubs"},
{c:813940,d: "Political parties"},
{c:813990,d: "Athletic associations, regulatory"},
{c:813990,d: "Athletic leagues (i.e., regulating bodies)"},
{c:813990,d: "Condominium corporations"},
{c:813990,d: "Condominium owners' associations"},
{c:813990,d: "Cooperative owners' associations"},
{c:813990,d: "Homeowners' associations"},
{c:813990,d: "Homeowners' associations, condominium"},
{c:813990,d: "Property owners' associations"},
{c:813990,d: "Sports governing bodies"},
{c:813990,d: "Sports leagues (i.e., regulating bodies)"},
{c:813990,d: "Tenants' associations (except advocacy)"},
{c:814110,d: "Households, private, employing (e.g., cooks, maids, chauffeurs, gardeners)"},
{c:814110,d: "Households, private, employing domestic personnel"},
{c:814110,d: "Private households employing domestic personnel"},
{c:814110,d: "Private households with employees"},
{c:921110,d: "Advisory commissions, executive government"},
{c:921110,d: "City and town managers' offices"},
{c:921110,d: "County supervisors' and executives' offices"},
{c:921110,d: "Executive offices, federal, state, and local (e.g., governor, mayor, president)"},
{c:921110,d: "Governors' offices"},
{c:921110,d: "Mayor's offices"},
{c:921110,d: "President's office, United States"},
{c:921120,d: "Advisory commissions, legislative"},
{c:921120,d: "Boards of supervisors, county and local"},
{c:921120,d: "City and town councils"},
{c:921120,d: "Congress of the United States"},
{c:921120,d: "County commissioners"},
{c:921120,d: "Legislative assemblies"},
{c:921120,d: "Legislative bodies (e.g., federal, local, and state)"},
{c:921120,d: "Legislative commissions"},
{c:921120,d: "Study commissions, legislative"},
{c:921130,d: "Assessor's offices, tax"},
{c:921130,d: "Board of Governors, Federal Reserve"},
{c:921130,d: "Budget agencies, government"},
{c:921130,d: "Controllers' and comptrollers' offices, government"},
{c:921130,d: "Customs bureaus"},
{c:921130,d: "Federal Reserve Board of Governors"},
{c:921130,d: "Gambling control boards, nonoperating"},
{c:921130,d: "Internal Revenue Service"},
{c:921130,d: "Lottery control boards, nonoperating"},
{c:921130,d: "Property tax assessors' offices"},
{c:921130,d: "State tax commissions"},
{c:921130,d: "Taxation departments"},
{c:921130,d: "Treasurers' offices, government"},
{c:921140,d: "Executive and legislative office combinations"},
{c:921140,d: "Legislative and executive office combinations"},
{c:921150,d: "American Indian or Alaska Native tribal councils"},
{c:921150,d: "American Indian or Alaska Native tribal courts"},
{c:921150,d: "American Indian or Alaska Native, tribal chief's or chairman's office"},
{c:921150,d: "Courts, American Indian or Alaska Native"},
{c:921150,d: "Police, American Indian or Alaska Native tribal"},
{c:921150,d: "Tribal chief's or chairman's office, American Indian or Alaska Native"},
{c:921150,d: "Tribal councils, American Indian or Alaska Native"},
{c:921150,d: "Tribal courts, American Indian or Alaska Native"},
{c:921190,d: "Auditor's offices, government"},
{c:921190,d: "Civil rights commissions"},
{c:921190,d: "Civil service commissions"},
{c:921190,d: "Election boards"},
{c:921190,d: "General accounting offices, government"},
{c:921190,d: "General public administration"},
{c:921190,d: "General services departments, government"},
{c:921190,d: "Human rights commissions, government"},
{c:921190,d: "Indian affairs programs, government"},
{c:921190,d: "Personnel offices, government"},
{c:921190,d: "Public property management services, government"},
{c:921190,d: "Purchasing and supply agencies, government"},
{c:921190,d: "Supply agencies, government"},
{c:922110,d: "Administrative courts"},
{c:922110,d: "Circuit courts"},
{c:922110,d: "City or county courts"},
{c:922110,d: "Courts of law, civilian (except American Indian or Alaska Native)"},
{c:922110,d: "Courts, civilian (except American Indian or Alaska Native)"},
{c:922110,d: "Courts, small claims"},
{c:922110,d: "Sheriffs' offices, court functions only"},
{c:922110,d: "Traffic courts"},
{c:922120,d: "Alcohol, tobacco, and firearms control"},
{c:922120,d: "Criminal investigation offices, government"},
{c:922120,d: "DEA (Drug Enforcement Administration)"},
{c:922120,d: "Drug enforcement agencies and offices"},
{c:922120,d: "Federal Bureau of Investigation (FBI)"},
{c:922120,d: "Federal police services"},
{c:922120,d: "Highway patrols, police"},
{c:922120,d: "Housing police, government"},
{c:922120,d: "Marshals' offices"},
{c:922120,d: "Park police"},
{c:922120,d: "Police academies"},
{c:922120,d: "Police and fire departments, combined"},
{c:922120,d: "Police departments (except American Indian or Alaska Native)"},
{c:922120,d: "Sheriffs' offices (except court functions only)"},
{c:922120,d: "State police"},
{c:922120,d: "Transit police"},
{c:922130,d: "Attorney generals' offices"},
{c:922130,d: "District attorneys' offices"},
{c:922130,d: "Legal counsel offices, government"},
{c:922130,d: "Public defenders' offices"},
{c:922130,d: "Public prosecutors' offices"},
{c:922130,d: "Solicitors' offices, government"},
{c:922130,d: "U.S. attorneys' offices"},
{c:922140,d: "Correctional boot camps"},
{c:922140,d: "Correctional institutions"},
{c:922140,d: "Detention centers"},
{c:922140,d: "Honor camps, correctional"},
{c:922140,d: "Houses of correction"},
{c:922140,d: "Jails (except private operation of)"},
{c:922140,d: "Penitentiaries"},
{c:922140,d: "Prison farms"},
{c:922140,d: "Prisons"},
{c:922140,d: "Reformatories"},
{c:922150,d: "Pardon boards and offices"},
{c:922150,d: "Parole offices, publicly administered"},
{c:922150,d: "Probation offices, publicly administered"},
{c:922150,d: "Public parole offices"},
{c:922150,d: "Public probation offices"},
{c:922150,d: "Rehabilitation services, correctional, government"},
{c:922160,d: "Ambulance and fire service combined"},
{c:922160,d: "Fire and rescue service"},
{c:922160,d: "Fire departments (e.g., government, volunteer (except private))"},
{c:922160,d: "Fire marshals' offices"},
{c:922160,d: "Fire prevention offices, government"},
{c:922160,d: "Firefighting (except forest), government and volunteer (except private)"},
{c:922160,d: "Firefighting services (except forest and private)"},
{c:922190,d: "Consumer product safety commissions"},
{c:922190,d: "Criminal justice statistics centers, government"},
{c:922190,d: "Disaster preparedness and management offices, government"},
{c:922190,d: "Emergency planning and management offices, government"},
{c:922190,d: "Law enforcement statistics centers, government"},
{c:922190,d: "Public safety bureaus and statistics centers, government"},
{c:922190,d: "Public safety statistics centers, government"},
{c:923110,d: "Certification of schools and teachers"},
{c:923110,d: "County supervisors of education (except school boards)"},
{c:923110,d: "Education offices, nonoperating"},
{c:923110,d: "Education program administration"},
{c:923110,d: "Education statistics centers, government"},
{c:923110,d: "State education departments"},
{c:923110,d: "Teacher certification bureaus"},
{c:923110,d: "University regents or boards, government"},
{c:923120,d: "Cancer detection program administration"},
{c:923120,d: "Communicable disease program administration"},
{c:923120,d: "Community health programs administration"},
{c:923120,d: "Coroners' offices"},
{c:923120,d: "Environmental health program administration"},
{c:923120,d: "Food service health inspections"},
{c:923120,d: "Health planning and development agencies, government"},
{c:923120,d: "Health program administration"},
{c:923120,d: "Health statistics centers, government"},
{c:923120,d: "Immunization program administration"},
{c:923120,d: "Maternity and child health program administration"},
{c:923120,d: "Mental health program administration"},
{c:923120,d: "Public health program administration, nonoperating"},
{c:923130,d: "Community social service program administration"},
{c:923130,d: "Equal employment opportunity offices"},
{c:923130,d: "Food distribution program administration, government"},
{c:923130,d: "Medical assistance programs administration, government"},
{c:923130,d: "Medicare and Medicaid administration"},
{c:923130,d: "Old age survivors and disability programs"},
{c:923130,d: "Railroad Retirement Board"},
{c:923130,d: "Social assistance cost-sharing, government"},
{c:923130,d: "Social Security Administration (SSA), federal"},
{c:923130,d: "Unemployment insurance program administration"},
{c:923130,d: "Welfare administration, nonoperating"},
{c:923130,d: "Welfare programs administration"},
{c:923130,d: "Women's bureaus"},
{c:923130,d: "Workers' compensation program administration"},
{c:923140,d: "Veterans' affairs offices"},
{c:923140,d: "Veterans' benefits program administration, government"},
{c:924110,d: "Enforcement of environmental and pollution control regulations"},
{c:924110,d: "Environmental protection program administration"},
{c:924110,d: "NOAA (National Oceanic and Atmospheric Administration)"},
{c:924110,d: "Pollution control program administration"},
{c:924110,d: "Sanitation engineering agencies, government"},
{c:924110,d: "Waste management program administration"},
{c:924110,d: "Water control and quality program administration"},
{c:924120,d: "Community recreation programs, government"},
{c:924120,d: "Conservation and reclamation agencies"},
{c:924120,d: "Fish and game agencies"},
{c:924120,d: "Fish and wildlife conservation program administration"},
{c:924120,d: "Game and inland fish agencies"},
{c:924120,d: "Game wardens"},
{c:924120,d: "Geological research program administration"},
{c:924120,d: "Land management program administration"},
{c:924120,d: "Parks and recreation commission, government"},
{c:924120,d: "Recreational programs administration, government"},
{c:924120,d: "Soil conservation services, government"},
{c:924120,d: "Weather research program administration"},
{c:924120,d: "Wildlife conservation agencies"},
{c:924120,d: "Wind and water erosion control agencies, government"},
{c:925110,d: "Building standards agencies, government"},
{c:925110,d: "Housing authorities, nonoperating"},
{c:925110,d: "Housing programs, planning and development, government"},
{c:925120,d: "Community development agencies, government"},
{c:925120,d: "County development agencies"},
{c:925120,d: "Land redevelopment agencies, government"},
{c:925120,d: "Redevelopment land agencies, government"},
{c:925120,d: "Regional planning and development program administration"},
{c:925120,d: "Urban planning commissions, government"},
{c:925120,d: "Zoning boards and commissions"},
{c:926110,d: "Arts and cultural program administration, government"},
{c:926110,d: "Consumer protection offices"},
{c:926110,d: "Councils of Economic Advisers"},
{c:926110,d: "Cultural and arts development support program administration"},
{c:926110,d: "Development assistance program administration"},
{c:926110,d: "Economic development agencies, government"},
{c:926110,d: "Energy development and conservation agencies, nonoperating"},
{c:926110,d: "Energy program administration"},
{c:926110,d: "Enterprise development program administration"},
{c:926110,d: "General economics statistical agencies"},
{c:926110,d: "Industrial development program administration"},
{c:926110,d: "Labor statistics agencies"},
{c:926110,d: "Small business development agencies"},
{c:926110,d: "Tourism development offices, government"},
{c:926110,d: "Trade commissions, government"},
{c:926110,d: "Trade development program administration"},
{c:926120,d: "Aircraft inspection, government"},
{c:926120,d: "Coast Guard (except academy)"},
{c:926120,d: "Federal Aviation Administration (except air traffic control)"},
{c:926120,d: "Licensing of transportation equipment, facilities, and services"},
{c:926120,d: "Merchant Marine (except academy)"},
{c:926120,d: "Motor carrier licensing and inspection offices"},
{c:926120,d: "Motor vehicle licensing offices, government"},
{c:926120,d: "National Transportation Safety Board"},
{c:926120,d: "Port authorities and districts, nonoperating"},
{c:926120,d: "Public transportation commissions, nonoperating"},
{c:926120,d: "Railroad and warehouse commissions, nonoperating"},
{c:926120,d: "Transit systems and authorities, nonoperating"},
{c:926120,d: "Transportation departments, nonoperating"},
{c:926120,d: "Transportation regulatory agencies"},
{c:926120,d: "Transportation safety programs, government"},
{c:926130,d: "Communications commissions"},
{c:926130,d: "Communications licensing commissions and agencies"},
{c:926130,d: "Energy development and conservation programs, government"},
{c:926130,d: "Federal Communications Commission (FCC)"},
{c:926130,d: "Irrigation districts, nonoperating"},
{c:926130,d: "Licensing and inspecting of utilities"},
{c:926130,d: "Mosquito eradication districts"},
{c:926130,d: "Nuclear energy inspection and regulation offices"},
{c:926130,d: "Public service (except transportation) commissions, nonoperating"},
{c:926130,d: "Public utility (except transportation) commissions, nonoperating"},
{c:926130,d: "Regulation of utilities"},
{c:926130,d: "Sanitation districts, nonoperating"},
{c:926130,d: "Solar energy  regulation"},
{c:926130,d: "Wind generated electrical power regulation"},
{c:926140,d: "Agricultural cooperative extension program administration"},
{c:926140,d: "Agricultural marketing services government"},
{c:926140,d: "Agricultural pest and weed regulation, government"},
{c:926140,d: "Agriculture fair boards administration"},
{c:926140,d: "Animal quarantine service, government"},
{c:926140,d: "Food inspection agencies"},
{c:926140,d: "Pest control programs, agriculture, government"},
{c:926140,d: "Regulation and inspection of agricultural products"},
{c:926140,d: "Weed control, agriculture, government"},
{c:926150,d: "Alcoholic beverage control boards"},
{c:926150,d: "Banking regulatory agencies"},
{c:926150,d: "Building inspections, government"},
{c:926150,d: "Conciliation and mediation services, government"},
{c:926150,d: "Health professions licensure agencies"},
{c:926150,d: "Hospital licensure agencies"},
{c:926150,d: "Inspection for labor standards"},
{c:926150,d: "Insurance commissions, government"},
{c:926150,d: "Labor management negotiations boards, government"},
{c:926150,d: "Licensing and permit issuance for business operations, government"},
{c:926150,d: "Licensing and permit issuance for professional occupations, government"},
{c:926150,d: "Mediation and conciliation services, government"},
{c:926150,d: "Minimum wage program administration"},
{c:926150,d: "Occupational safety and health administration"},
{c:926150,d: "Occupational safety and health standards agencies"},
{c:926150,d: "Pet licensing"},
{c:926150,d: "Price control agencies"},
{c:926150,d: "Rent control agencies"},
{c:926150,d: "Securities regulation commissions"},
{c:926150,d: "Standards, setting and management, agencies, government"},
{c:926150,d: "Wage control agencies, government"},
{c:927110,d: "National Aeronautics and Space Administration"},
{c:927110,d: "Operating and launching government satellites"},
{c:927110,d: "Space flight operations, government"},
{c:927110,d: "Space research services, government"},
{c:928110,d: "Air Force"},
{c:928110,d: "Air traffic control, military"},
{c:928110,d: "Armed forces"},
{c:928110,d: "Army"},
{c:928110,d: "Courts, military"},
{c:928110,d: "Marine Corps"},
{c:928110,d: "Military bases and camps"},
{c:928110,d: "Military police"},
{c:928110,d: "Military reserve armories and bases"},
{c:928110,d: "Military training schools (except academies)"},
{c:928110,d: "National Guard"},
{c:928110,d: "Navy"},
{c:928120,d: "Consulates"},
{c:928120,d: "Diplomatic services"},
{c:928120,d: "Economic development assistance (i.e., international), government"},
{c:928120,d: "Embassies"},
{c:928120,d: "Foreign economic and social development services, government"},
{c:928120,d: "Foreign government service"},
{c:928120,d: "Foreign missions"},
{c:928120,d: "Immigration services"},
{c:928120,d: "International Monetary Fund"},
{c:928120,d: "Organization for Economic Cooperation and Development"},
{c:928120,d: "Organization of American States"},
{c:928120,d: "Passport issuing services"},
{c:928120,d: "Peace Corps"},
{c:928120,d: "State Department"},
{c:928120,d: "United Nations"},
{c:928120,d: "World Bank"},
];