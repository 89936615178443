import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import awsconfig from './aws-exports';
import { Amplify } from 'aws-amplify';

import alertify from 'alertifyjs';
import InitializeAlertify from './libraries/AlertifySettings';

import 'alertifyjs/build/css/alertify.css';
import 'react-calendar/dist/Calendar.css';
import './App.css';

// App pages
import { Profile } from './Profile';
import { Rewards } from './Rewards';
import { Success } from './Success';
import { Licenses } from './Licenses';

// Account pages
import { CreateAccount } from './CreateAccount';
import { Verification } from './Verification';
import { Login } from './Login';
import { Forgetpassword } from "./Forgetpassword"
import { ResetPassword } from "./ResetPassword"

// Interview funnel pages
import { Welcome } from './funnel/Welcome';
import { ChooseBusiness } from './funnel/ChooseBusiness';
import { BusinessAddress } from './funnel/BusinessAddress';
import { Employees } from './funnel/Employees';
import { AnnualGross } from './funnel/AnnualGross';
import { AnnualGrossTooHigh } from './funnel/AnnualGrossTooHigh';
import { InsuranceDates } from './funnel/InsuranceDates';
import { Statements } from './funnel/Statements';
import { AdjustCoverage } from './funnel/AdjustCoverage';
import { ContactInfo } from './funnel/ContactInfo';
import { StateNotLaunched } from './StateNotLaunched';
import { PaymentComplete } from './funnel/PaymentComplete';
import { PaymentCancel } from './funnel/PaymentCancel';

// Utility
import { SpinnerPage } from './SpinnerPage';

const Redirect = (props) => {
  window.location.href = props.to;
}

const App = () => {
    InitializeAlertify(alertify);
    Amplify.configure(awsconfig);

    return (
      <Router>
        <Routes>
          {/* Top level site pages */}
          <Route path="/" element={<Redirect to="/home.html" />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/rewards" element={<Rewards />} />
          <Route path="/licenses" element={<Licenses />} />

          {/* Account management pages */}
          <Route path="/create_account" element={<CreateAccount />} />
          <Route path="/verification/:route" element={<Verification />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forget_password" element={<Forgetpassword/>} />
          <Route path="/reset_password" element={<ResetPassword/>}/>

          {/* Cyber policy funnel routes */}
          <Route path="/cyber/welcome" element={<Welcome percent={0} policy="Cyber" next="/cyber/choose_business" />} />
          <Route path="/cyber/choose_business" element={<ChooseBusiness percent={1/8*100} next="/cyber/business_address" />} />
          <Route path="/cyber/business_address" element={<BusinessAddress percent={2/8*100} next="/cyber/employees" />} />
          <Route path="/cyber/state_not_launched" element={<Redirect to="/state-not-launched/" />} />
          <Route path="/cyber/employees" element={<Employees percent={3/8*100} next="/cyber/annual_gross" />} />
          <Route path="/cyber/annual_gross" element={<AnnualGross percent={4/8*100} next="/cyber/statements" showRevenue={true} />} />
          <Route path="/cyber/annual_gross_too_high" element={<AnnualGrossTooHigh next="/" />} />
          <Route path="/cyber/statements" element={<Statements percent={5/8*100} next="/cyber/insurance_dates" />} />
          <Route path="/cyber/insurance_dates" element={<InsuranceDates percent={6/8*100} next="/cyber/adjust_coverage" />} />
          <Route path="/cyber/adjust_coverage" element={<AdjustCoverage percent={7/8*100} next="/cyber/contact_info" />} />
          <Route path="/cyber/contact_info" element={<ContactInfo next="/cyber/success" />} />
          <Route path="/cyber/success" element={<Success next="/create_account" />} />
          <Route path="/cyber/payment_complete" element={<PaymentComplete/>}/>
          <Route path="/cyber/payment_cancel" element={<PaymentCancel/>}/>

          {/* Blog pages */}
          <Route path="/part_of_larger_strategy" element={<Redirect to="/blogs/cyber-insurance-as-part-of-a-larger-cyber-risk-management-strategy" />} />
          <Route path="/why_accountants_need" element={<Redirect to="/blogs/why-every-accountant-needs-cyber-security-insurance" />} />
          <Route path="/who_should_be_buying" element={<Redirect to="/blogs/who-should-be-buying-cyber-insurance-and-when" />} />
          <Route path="/cyber_for_creators" element={<Redirect to="/blogs/what-is-cyber-insurance-for-creators" />} />
          <Route path="/top_five_reasons" element={<Redirect to="/blogs/top-5-reasons-videographers-need-cyber-insurance" />} />
          <Route path="/insurance_is_must" element={<Redirect to="/blogs/cyber-insurance-is-a-must-for-professionals-handling-client-financial-data" />} />
          <Route path="/three_methods" element={<Redirect to="/blogs/3-methods-for-small-and-medium-sized-business-to-avoid-cyber-attacks" />} />
          <Route path="/top_five_for_smb" element={<Redirect to="/blogs/top-5-reasons-for-small-and-medium-sized-businesses-to-invest-in-cyber-insurance" />} />
          <Route path="/seven_must_have_software" element={<Redirect to="/blogs/7-must-have-software-for-accountants" />} />
          <Route path="/reduce_cyber_exposure" element={<Redirect to="/blogs/how-to-reduce-your-business-cyber-exposure" />} />
          <Route path="/top_six_threats" element={<Redirect to="/blogs/top-6-cyber-threats-for-accountants" />} />
          <Route path="/best_five_passwords" element={<Redirect to="/blogs/best-5-practices-for-setting-strong-company-passwords" />} />
          <Route path="/top_threats_for_contractors" element={<Redirect to="/blogs/top-cyber-threats-for-working-with-contractors" />} />
          <Route path="/everything_about_ransomware" element={<Redirect to="/blogs/everything-you-need-to-know-about-ransomware" />} />
          <Route path="/what_to_do_after" element={<Redirect to="/blogs/a-step-by-step-guide-on-what-to-do-after-a-cyber-incident-has-been-discovered" />} />
          <Route path="/social_engineering_coverage" element={<Redirect to="/blogs/what-is-social-engineering-coverage" />} />
          <Route path="/top_5_threats_for_2022" element={<Redirect to="/blogs/top-5-cyber-threats-to-expect-in-2022-2" />} />
          <Route path="/why_war_raises_concerns" element={<Redirect to="/blogs/why-does-the-war-in-ukraine-raise-the-u-s-government-cyber-security-concern" />} />
          <Route path="/what_dark_web_what_you_find_there" element={<Redirect to="/blogs/what-is-the-dark-web-and-what-will-you-find-there" />} />
          <Route path="/need_know_multi_factor_authentication" element={<Redirect to="/blogs/all-you-need-to-know-about-multi-factor-authentication-mfa" />} />
          <Route path="/digital_security_essential" element={<Redirect to="/blogs/risk-management-a-digital-security-essential"/>}/>
          <Route path="/zero_trust_security" element={<Redirect to="/blogs/zero-trust-security-explained"/>}/>
          <Route path="/phishing" element={<Redirect to="/blogs/phishing-the-cyber-pandemic-thats-not-going-away"/>} />
          <Route path="/top_5_privacy_laws" element={<Redirect to="/blogs/top-5-us-data-privacy-laws-to-watch-out-for-in-2022"/>} />

          {/* Press Pages*/}
          <Route path="/loginid_announcement" element={<Redirect to="/press/mindaro-announces-official-partnership-with-authentication-vendor-loginid"/>} />
          <Route path="/fortifydata_announcement" element={<Redirect to="/press/mindaro-announces-official-partnership-with-cyber-risk-management-provider-fortifydata"/>} />
          <Route path="/zentera_announcement" element={<Redirect to="/press/mindaro-establishes-official-partnership-with-zero-trust-enforcer-zentera"/>} />

          {/* Utility routes */}
          <Route path="/spinner" element={<Modal size="sm" centered show={true}><Modal.Body style={{height:'200px'}}><SpinnerPage /></Modal.Body></Modal>} />

          {/* Catch-all 404 page */}
          <Route path="*" element={<Redirect to="/not-found" />} />
        </Routes>
      </Router>
    );
}

export default App;
