export const states =
[
    { abbr: 'AL', avail: true , name: 'Alabama' },
    { abbr: 'AK', avail: false, name: 'Alaska' },
    { abbr: 'AR', avail: false, name: 'Arkansas' },
    { abbr: 'AZ', avail: false, name: 'Arizona' },
    { abbr: 'CA', avail: true , name: 'California' },
    { abbr: 'CO', avail: true , name: 'Colorado' },
    { abbr: 'CT', avail: true, name: 'Connecticut' },
    { abbr: 'DC', avail: false, name: 'District Of Columbia' },
    { abbr: 'DE', avail: true , name: 'Delaware' },
    { abbr: 'FL', avail: true , name: 'Florida' },
    { abbr: 'GA', avail: false, name: 'Georgia' },
    { abbr: 'HI', avail: false, name: 'Hawaii' },
    { abbr: 'IA', avail: false, name: 'Iowa' },
    { abbr: 'ID', avail: false, name: 'Idaho' },
    { abbr: 'IL', avail: true , name: 'Illinois' },
    { abbr: 'IN', avail: true , name: 'Indiana' },
    { abbr: 'KS', avail: false, name: 'Kansas' },
    { abbr: 'KY', avail: false, name: 'Kentucky' },
    { abbr: 'LA', avail: false, name: 'Louisiana' },
    { abbr: 'MA', avail: true , name: 'Massachusetts' },
    { abbr: 'MD', avail: true , name: 'Maryland' },
    { abbr: 'ME', avail: false, name: 'Maine' },
    { abbr: 'MI', avail: true , name: 'Michigan' },
    { abbr: 'MN', avail: true , name: 'Minnesota' },
    { abbr: 'MO', avail: false, name: 'Missouri' },
    { abbr: 'MS', avail: false, name: 'Mississippi' },
    { abbr: 'MT', avail: true , name: 'Montana' },
    { abbr: 'NC', avail: true , name: 'North Carolina' },
    { abbr: 'ND', avail: true , name: 'North Dakota' },
    { abbr: 'NE', avail: false, name: 'Nebraska' },
    { abbr: 'NH', avail: false, name: 'New Hampshire' },
    { abbr: 'NJ', avail: true , name: 'New Jersey' },
    { abbr: 'NM', avail: false, name: 'New Mexico' },
    { abbr: 'NV', avail: true , name: 'Nevada' },
    { abbr: 'NY', avail: true , name: 'New York' },
    { abbr: 'OH', avail: true , name: 'Ohio' },
    { abbr: 'OK', avail: false, name: 'Oklahoma' },
    { abbr: 'OR', avail: true , name: 'Oregon' },
    { abbr: 'PA', avail: true , name: 'Pennsylvania' },
    { abbr: 'RI', avail: false, name: 'Rhode Island' },
    { abbr: 'SC', avail: true , name: 'South Carolina' },
    { abbr: 'SD', avail: true , name: 'South Dakota' },
    { abbr: 'TN', avail: true , name: 'Tennessee' },
    { abbr: 'TX', avail: true , name: 'Texas' },
    { abbr: 'UT', avail: false, name: 'Utah' },
    { abbr: 'VA', avail: false, name: 'Virginia' },
    { abbr: 'VT', avail: false, name: 'Vermont' },
    { abbr: 'WA', avail: true , name: 'Washington' },
    { abbr: 'WI', avail: true , name: 'Wisconsin' },
    { abbr: 'WV', avail: false, name: 'West Virginia' },
    { abbr: 'WY', avail: false, name: 'Wyoming' },
];

export function findStateByAbbr(abbr) {
    return states.find((candidate) => candidate.abbr.toUpperCase() === abbr.toUpperCase());
}

export function findStateByName(name) {
    return states.find((candidate) => candidate.name.toUpperCase() === name.toUpperCase());
}

export function stateAbbrToName(abbr) {
    let state = findStateByAbbr(abbr);
    return state ? state.name : undefined;
}

export function stateNameToAbbr(name) {
    let state = findStateByName(name);
    return state ? state.abbr : undefined;
}

// This function assumes that the state names are all upper or lower cased already
export function statesSortedByName() {
    return states.sort((a, b) => {
        return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
}
