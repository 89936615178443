import React, { useState } from 'react';
import { Form, Col, Row,Image, FloatingLabel, Container, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { API } from 'aws-amplify';
import { LoadApp, SaveData } from '../GreenButton';
import alertify from 'alertifyjs';
import serializeJSON from '../../libraries/serializeJSON';
import "./index.css";
import mindaroImage from '../../assets/mindaro-white.svg';

export const Footer = () => {
    // const appId = localStorage.getItem('appId');
    let app = LoadApp();
    const [email, setEmail] = useState(app.email);
    const emailChanged = (event) => setEmail(event.target.value);
    const navigate = useNavigate();
    const onSubmit = async (event) => {
        document.getElementById('FooterGreenButton').disabled = true;
        event.preventDefault();
        const form = event.target;
        const strData = serializeJSON(form);
        const newData = JSON.parse(strData);
        await SaveData(newData);
        try {
            app = {...app, ...newData};
            let response = await API.post('applicantwebapprestapi', '/monday', {body: {board: 'newsletter', app}});
            if (response.data?.data?.create_item?.id !== undefined) {
                navigate(0);
            } else {
                throw new Error(response.data?.error);
            }
        } catch (err) {
            console.error(err);
            alertify.alert('Error', err.message);
        }
    }
    return (
        
            <div id="footer-background">
                <Container>
                    <Row id="footer-row">
                        <Col xs="6" md="3"> 
                        <Row><Image className="mindaro-logo"  src={mindaroImage} />
                        <div className="mindaro-email"><a style={{fontSize:"18px"}} href="mailto:hello@mindaro.io">hello@mindaro.io</a></div></Row>
                        </Col>
                        <Col xs="6" md="3">
                            <h6>Company</h6>
                            <div><a href="/about">About</a></div>
                            <div><a href="/partners">Become a partner</a></div>
                            <div><a href="/careers">Careers</a></div>
                            <div><a href="/privacy">Privacy policy</a></div>
                            <div><a href="/terms">Terms &amp; Conditions</a></div>
                        </Col>
                        <Col xs="6" md="3">
                            <h6>Programs</h6>
                            <div><Link to="/cyber/welcome">Cyber Insurance</Link></div>
                            <div><a href="/coming-soon/?program=downtimeinsurance">Downtime Insurance</a></div>
                            <div><a href="/coming-soon/?program=businessowners">Business owners policy</a></div>
                            <div><a href="/coming-soon/?program=generalliability">General liability</a></div>
                            <div><a href="/coming-soon/?program=professionalliability">Professional liability</a></div>
                        </Col>
                        <Col xs="6" md="3">
                            <h6>Resources</h6>
                            <div><a href="/marketplace">Partners marketplace</a></div>
                            <div><a href="/blogs">Blog</a></div>
                            <div><a href="/faqs">FAQs</a></div>
                            <div><a href="/press-releases">Press</a></div>
                            <div>&nbsp;</div>
                        </Col>
                       
                    </Row>
                    <hr style={{height: '0px'}}/>
                    <Row>
                    <Col xs="12" md="6">
                        <Row className="signup-section" >
                            <h6>Stay up to date</h6>
                            <p className='stay-uptodate-text'>Mindaro news, tips, and resources are delivered straight to your inbox.</p>
                            <div>
                                {(app.email && app.VIP === 'Yes')
                                    ?
                                        <div id="vip-thanks">Thanks for signing up!</div>
                                    :
                                        <Form onSubmit={onSubmit} autoComplete="off">
                                            <Row className="d-flex justify-content-center mb-4">
                                                <Col md="8" xs="10" className='p-0'>
                                                <input type="hidden" name="VIP" value="Yes" />
                                                <div class="mindaro-text white mt-3">
											    	<input id="email" name="email" placeholder="Email address" class="mindaro-text-input" required="" value={email} onChange={emailChanged}/>
                                                    <label for="email">Email Address</label>
                                                </div>
                                            </Col>
                                            <Col md="4" className='mt-3'>
                                                 <Button type="submit" className="quote-button " id="FooterGreenButton"> <b>Sign me up!</b></Button>
                                            </Col>
                                            </Row>
                                        </Form>
                                }
                            </div>
                        </Row> 
                    </Col>

                        <Col md="4">
                        </Col>
                        <Col  xs="12" md="2">
                            
                        <h6 className="follow-us">Follow us</h6>
                            <div id="social-row">
                                <a target="_blank" rel="noreferrer" href="https://www.facebook.com/MindaroInsurance"><i className="fab fa-facebook-square" /></a>
                                <a target="_blank" rel="noreferrer" href="https://twitter.com/MindaroInsure"><i className="fab fa-twitter" /></a>
                                <a target="_blank" rel="noreferrer" href="https://www.instagram.com/mindaroinsurance/"><i className="fab fa-instagram" /></a>
                                <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/mindaro"><i className="fab fa-linkedin" /></a>
                            </div>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                            <Col md={6} xs="12" className="d-flex justify-content-start">
                                <small>Mindaro Insurance, LLC, Minnesota Agency number 40782830</small>
                            </Col>

                            <Col md={6} xs="12" className="d-flex justify-content-end">
                                <small>© 2022 Mindaro Technologies Inc. All rights reserved.</small>
                            </Col>
                    </Row>

                    <Row>
                        <p className='footer-text'>
                            The products and services referred to on this website are available only in the jurisdictions in which Mindaro Insurance LLC is licensed to transact insurance business. Nothing on this website shall be construed as an offer or solicitation to purchase insurance in any other jurisdiction. The availability, terms, and conditions of such products and services may vary by jurisdiction. The list of licenses held by Mindaro Insurance LLC can be viewed on our <span> <a href="/state-licenses" className='orange'>licenses page.</a></span>
                        </p>
                    </Row>
                </Container>
            </div>
    
    )
}
