import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, FormLabel } from 'react-bootstrap';
import { API } from 'aws-amplify';
import { FunnelLayout } from '../components/FunnelLayout';
import { GreenButton, LoadApp, SaveData } from '../components/GreenButton';
import { FormatNumberInput} from '../components/CustomInputs';
import { CustomSelect } from '../components/CustomInputs';
import { getCowbellLimits, getCowbellLimitsOptions } from '../libraries/Cowbell';
import { dollarFormat } from '../libraries/Formatting';
import alertify from 'alertifyjs';
import { cleanErrorMessage } from '../libraries/AuthLibraries';
import styled from "styled-components"

const Styles = styled.div`

.annualRevenueInput {
    margin-bottom: 40px;
    margin-top : 60px;
}

.select-text{
    ont-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: #C4C4C4;
    text-align : left;
}



@media (max-width : 765px){

    .annualRevenueInput {
        margin-bottom: 40px;
        margin-top : 20px;
    }
}
`

const title = "What is your gross yearly revenue?"
export const AnnualGross = (props) => {
    const navigate = useNavigate();
    const app = LoadApp();
    const defaultLimit = parseInt(app.cyberLimit) || getCowbellLimits(parseInt(app.annualRevenue), parseInt(app.naicsCode))[0];
    const { control, register, handleSubmit, setValue, formState: { errors }} = useForm({
        defaultValues: {
            annualProductionCosts: app.annualProductionCosts,
            singleProductionCosts: app.singleProductionCosts,
            dailyProductionCosts: app.dailyProductionCosts,
            annualRevenue: app.annualRevenue || '',
            cyberLimit: {value: defaultLimit.toString(), label: dollarFormat(defaultLimit)},
        },
    });
    const onRevenueChange = (values, _event) => {
        app.annualRevenue = values.value;
        if (app.cyberLimit === undefined) {
            let limits = getCowbellLimits(parseInt(app.annualRevenue), parseInt(app.naicsCode)).filter(limit => limit <= app.annualRevenue && limit <= 1_000_000);
            if (limits.length > 0) {
                let defaultLimit = limits[limits.length - 1];
                setValue('cyberLimit', {value: defaultLimit.toString(), label: dollarFormat(defaultLimit)});
            }
        }
    }
    const filterLimitsOptions = (candidate, _input) => {
        return getCowbellLimits(parseInt(app.annualRevenue), parseInt(app.naicsCode)).indexOf(parseInt(candidate.value)) !== -1;
    }
    const onSubmit = async (data) => {
        console.log('data:', {...data});
        data.annualRevenue = data.annualRevenue?.replace(/[^\d]/g, '');
        data.cyberLimit = data.cyberLimit?.value || '0';
        await SaveData(data);
        if (parseInt(data.annualRevenue) > 100_000_000) {
            navigate('/cyber/annual_gross_too_high');
            return;
        }
        let app = LoadApp();
        if ((!app.cowbellAccount) || (app.cowbellAccount === '')) {
            API.post('applicantwebapprestapi', '/cyber', {body: { method: 'account', app }})
                .then((value)=> {
                    console.log(value);
                    if ((value.success === 'SUCCESS') && value.data && value.data.account) {
                        SaveData({cowbellAccount: JSON.stringify(value.data.account)});
                    } else {
                        if (value.error) {
                            alertify.alert('Error...', value.error);
                        } else {
                            alertify.alert('Error...', 'There was an error creating your account. Please contact us for help getting a quote.');
                        }
                    }
                })
                .catch((error)=> {
                    console.error(error);
                    alertify.alert('Error...', cleanErrorMessage(error));
                });
        }
        navigate(props.next);
    }


    return (
        <FunnelLayout title={title} percent={props.percent}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Styles>
                    <FormatNumberInput
                        name="annualRevenue"
                        placeholder=""
                        register={register}
                        control={control}
                        errors={errors}
                        className="annualRevenueInput"
                        onValueChange={onRevenueChange}
                        onBlur={(e) => { console.log(e); }}
                    />
                    <FormLabel className="select-text">Businesses with similar revenues often choose the following coverage limit.</FormLabel>
                    <CustomSelect
                        name="cyberLimit"
                        placeholder=""
                        options={getCowbellLimitsOptions()}
                        control={control}
                        errors={errors}
                        filterOption={filterLimitsOptions}
                    />
                    <div className="buttonwrapper">
                    <GreenButton>Continue</GreenButton>
                    </div>
                </Styles>
            </Form>
        </FunnelLayout>
    )
}
