import { dollarFormat } from "./Formatting";

export const cowbell_naics_exclusions = [
    221111, 221112, 221113, 221114, 221115, 221116, 221117, 221118, 221121, 221122, 221210, 221310, 221320, 221330,
    324110, 481111, 481112, 481211, 481212, 481212, 481219, 486110, 488111, 488119, 488190, 488330, 492110, 517410,
    518210, 522320, 522390, 523120, 523130, 523140, 523210, 611310, 611512, 713210, 713290, 713990, 721120, 813319,
    926120, 927110, 999900,
];

const cowbell_limits = [
       50_000,
      100_000,
      250_000,
      500_000,
      750_000,
    1_000_000,
    2_000_000,
    3_000_000,
];

const cowbell_deductibles = [
     2_500,
     5_000,
    10_000,
    25_000,
    50_000,
];

const cowbell_1m_max_naics = [
    92, // municipalities
    61, // education
    332510, 511210, 517311, 517312, 517410, 517911, 517919, 518210, 519130, 541511, 541512, 541513, 541519, 541690, 811211, // tech
];

const cowbell_25k_min_naics = [
    92, // municipalities
    61, // education
];

export const cowbell_must_encrypt_naics = [
    51,
    52,
    61,
    62,
];

export const isMatchingNaicsCode = (naics, naics_array) => {
    return Boolean(naics_array.find((value) => {
        return naics.toString().startsWith(value.toString());
    }));
}

export const getCowbellLimits = (revenue, naics) => {
    return cowbell_limits.filter((value) => {
        if (value === 3_000_000 && revenue < 5_000_000) return false;
        if (value > 1_000_000 && isMatchingNaicsCode(naics, cowbell_1m_max_naics)) return false;
        return true;
    });
}

export const getCowbellLimitsOptions = () => {
    return cowbell_limits.map(lim => {
        return {value: lim.toString(), label: dollarFormat(lim)};
    });
};

export const getCowbellDeductibles = (revenue, naics) => {
    return cowbell_deductibles.filter((value) => {
        if (value < 5_000 && revenue >= 25_000_000) return false;
        if (value < 10_000 && revenue >= 50_000_000) return false;
        if (value < 25_000 && isMatchingNaicsCode(naics, cowbell_25k_min_naics)) return false;
        return true;
    })
}

export const getCowbellDeductiblesOptions = () => {
    return cowbell_deductibles.map(ded => {
        return {value: ded.toString(), label: dollarFormat(ded)};
    });
}