import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, FormLabel, Row, Col } from 'react-bootstrap';
import { GreenButton, LoadApp, SaveData } from '../components/GreenButton';
import { FunnelLayout } from '../components/FunnelLayout';
import { LabeledSwitch } from '../components/CustomInputs';
import alertify from 'alertifyjs';
import { isMatchingNaicsCode, cowbell_must_encrypt_naics } from '../libraries/Cowbell';
import styled from "styled-components"

const Styles = styled.div`

.labeled-switch {
    color:var(--mindaro-theme-primary-text);
    background: #1C1D1F;
    box-shadow: -4px -4px 10px rgba(255, 255, 255, 0.07), 4px 4px 10px rgba(0, 0, 0, 0.62);
    border-radius: 15px;
    height: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: flex-start;
    letter-spacing: -0.02em;
    
}

.labeledSwitch-no-style{
    box-shadow: none !important;
}

.wrapper{
    background: #1C1D1F;
    box-shadow: -4px -4px 10px rgba(255, 255, 255, 0.07), 4px 4px 10px rgba(0, 0, 0, 0.62);
    border-radius: 15px;
    height: auto;
    padding : 10px;
}

.sub-header{
    max-width : 478px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: var(--mindaro-theme-primary-text);
    margin-bottom: 35px;
}

.checkox-wrapper{
    background: #1C1D1F;
    box-shadow: inset 4px 5px 10px rgba(0, 0, 0, 0.45);
    border-radius: 15px;
    padding : 10px;
    display : block;
    width: 100%;
    color: var(--mindaro-theme-primary-text);
    max-width : 320px;
}

`
const title = 'Please either agree or disagree with the following statements'

export const Statements = (props) => {
    const navigate = useNavigate();
    const app = LoadApp();
    
    // const cyberClaimsOptions = [
    //     { value: '1', label: '1 year ago'},
    //     { value: '2', label: '2 years ago'},
    //     { value: '3', label: '3 years ago'},
    //     { value: '4', label: '4 years ago'},
    //     { value: '5', label: '5 or more years ago'},
    // ];
    // let defaultCyberClaimsYears;
    // cyberClaimsOptions.forEach((option) => {
    //     if (app.cyberClaimsYears === option.value) {
    //         defaultCyberClaimsYears = option;
    //     }
    // });
    
    if (!('encryptAllEmails' in app)) app.encryptAllEmails = false;
    if (!('preventUnauthorizedWires' in app)) app.preventUnauthorizedWires = true;
    if (!('authenticateFundsTransferRequests' in app)) app.authenticateFundsTransferRequests = true;
    if (!('verifyBankAccounts' in app)) app.verifyBankAccounts = true;
    if (!('sensitiveInfoInCloud' in app)) app.sensitiveInfoInCloud = true;
    if (!('pastCyberClaims' in app)) app.pastCyberClaims = false;
    if (!('annualSecurityTraining' in app)) app.annualSecurityTraining = false;
    if (!('willingToImplementSecurityTraining' in app)) app.willingToImplementSecurityTraining = true;
    
    const { control, handleSubmit, register, watch } = useForm({
        defaultValues: {
            encryptAllEmails: app.encryptAllEmails,
            preventUnauthorizedWires: app.preventUnauthorizedWires,
            authenticateFundsTransferRequests: app.authenticateFundsTransferRequests,
            verifyBankAccounts: app.verifyBankAccounts,
            sensitiveInfoInCloud: app.sensitiveInfoInCloud,
            pastCyberClaims: app.pastCyberClaims,
            //cyberClaimsYears: defaultCyberClaimsYears,
            annualSecurityTraining: app.annualSecurityTraining,
            willingToImplementSecurityTraining: app.willingToImplementSecurityTraining,
        },
    });
    const onSubmit = async (data) => {
        data.cyberClaimsYears = isNaN(Number.parseInt(data.cyberClaimsYears)) ? '' : data.cyberClaimsYears.value;
        await SaveData(data);
        if (pastCyberClaims === true) {
            window.location.href = "/nonfit";
        } else {
            navigate(props.next);
        }

    }
    const [annualSecurityTraining, pastCyberClaims, willingToImplementSecurityTraining, encryptAllEmails] = watch(['annualSecurityTraining', 'pastCyberClaims','willingToImplementSecurityTraining', 'encryptAllEmails']);
    const disableContinue = () => {
        return ((!willingToImplementSecurityTraining) && (!annualSecurityTraining)) ||
               ((!encryptAllEmails) && isMatchingNaicsCode(app.naicsCode, cowbell_must_encrypt_naics));
    }
    useEffect(() => {
        if (!encryptAllEmails && isMatchingNaicsCode(app.naicsCode, cowbell_must_encrypt_naics)) {
            alertify.alert('Heads up!', 'For your type of business, email encryption is a requirement.');
        }
    }, [encryptAllEmails, app.naicsCode]);
    return (
        <Styles>
            <FunnelLayout percent={props.percent} title={title} Col={7}>
                <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <FormLabel>
                        Please either agree or disagree with the following statements
                        <br/>
                        
                    </FormLabel>

                    <FormLabel>
                    <p className="sub-header">
                        The following questions are key to getting the correct insurance. It’s important that you answer and adjust them correctly.
                        References to “You” or “Your” include anyone involved in running the business (e.g family members and business partners).
                        </p>
                    </FormLabel>

                    <Row>
                        <Row >
                            <Col md="6" xs="12" className='mb-3'> 
                                <LabeledSwitch name="encryptAllEmails" control={control}>
                                    You encrypt all emails, containing sensitive information sent to external parties.
                                </LabeledSwitch>
                            </Col>

                            <Col md="6" xs="12" className='mb-3'>   
                                <LabeledSwitch name="preventUnauthorizedWires" control={control}>
                                    You prevent unauthorized employees from initiating wire transfers.
                                </LabeledSwitch>
                            </Col>
                        </Row>

                        <Row class>
                            <Col md="6" xs="12" className='mb-3'>
                                <LabeledSwitch name="authenticateFundsTransferRequests" control={control}>
                                    You authenticate funds transfer requests by calling a customer to verify the request at a predetermined phone number.
                                </LabeledSwitch>
                            </Col>

                            <Col md="6" xs="12" className='mb-3'>
                                <LabeledSwitch name="verifyBankAccounts" control={control}>
                                    You verify vendors'/suppliers' bank accounts before adding them to your accounts payable systems.
                                </LabeledSwitch>
                            </Col>
                        </Row>
                        
                        <Row >
                            <Col md="6" xs="12" className='mb-3'>
                                <LabeledSwitch name="sensitiveInfoInCloud" control={control}>
                                    Your business have sensitive information stored in the cloud.
                                </LabeledSwitch>
                            </Col>

                            <Col md="6" xs="12" className='mb-3'> 
                                <LabeledSwitch name="pastCyberClaims" control={control}>
                                    You had Cyber insurance claims in the past.
                                    {/* <div>You had Cyber insurance claims in the past.</div>
                                    <InputGroup style={{display:pastCyberClaims ? 'block' : 'none',marginBottom:'0px'}}>
                                        <CustomSelect name="cyberClaimsYears" placeholder="How many years ago?" options={cyberClaimsOptions} control={control} errors={errors} required={pastCyberClaims} shadow={false} />
                                    </InputGroup> */}
                                </LabeledSwitch>
                            </Col>
                        </Row>

                        <Row className='d-flex justify-content-center'>
                            <Col md="6" xs="12">
                                <div className='wrapper'>
                                <LabeledSwitch  className="labeledSwitch-no-style" name="annualSecurityTraining" control={control} >
                                    <div>You provide mandatory information security training to all employees at least annually.</div>
                                    {/* TODO: Gray out instead of hiding the check box */}                                    
                                </LabeledSwitch>
                                
                                <div className="checkox-wrapper" style={{display:annualSecurityTraining ? 'none' : 'block',marginTop:'10px'}}>
                                        <Form.Check control={control}  {...register('willingToImplementSecurityTraining')} label="I'm willing to implement security training during the policy period" name="willingToImplementSecurityTraining" type="checkbox" />
                                </div>
                                </div>
                            </Col>
                        </Row>
                    </Row>
                    <div className="buttonwrapper short-margin-buttonwrapper">
                        <GreenButton disabled={disableContinue()}>Done</GreenButton>
                    </div>
                </Form>
            </FunnelLayout>
        </Styles>
    )
}
