import React, { useState } from 'react';
import { Form, FormLabel } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import alertify from 'alertifyjs';

import { Layout } from './components/Layout';
import { cleanErrorMessage } from './libraries/AuthLibraries';
import styled from "styled-components"

const Styles = styled.div`
.header-wraper{
    display: flex;
    justify-content: center;
}

.header{
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 66px;
    text-align: center; 
    color: #0D0D0D;
    margin-bottom : 35px;
    margin-top : 100px;
    max-width : 460px;
}

.sub-header{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    color: #0A0909;
    margin-bottom: 50px;
    max-width : 420px;
    color : var(--mindaro-theme-third);
    cursor : pointer;

    
}
.custom-input{
    margin-bottom: 25px;
    color : var( --mindaro-theme-primary-background);
    border-top: none;
    border-left:none;
    border-right:none;
    border-bottom: 2px solid  var(--mindaro-theme-primary-background);
    outline:none;
    padding-left:0px;
    background-color: transparent;
    border-radius : 0;
    max-width : 60px;
    margin-right : 30px;
    text-align: center;
}


.forget-password-wraper{
    margin-top : 115px;
    margin-bottom : 300px;
}
@media (max-width : 567px) {
    .header{
        text-align : center;
        font-size: 40px;
        line-height: 40px;
        width : 100%;
        margin-top : 20px;
    }  
    .buttonWrapper{
        margin-bottom: 40px;
    }
    .custom-input{
        max-width : 40px;
    margin-right : 10px;
    }
    .forget-password-wraper{
        margin-bottom : 50px;
    }
}
`

export const Verification = ({forgetPasswordSubmit}) => {
    const navigate = useNavigate();
    const nextPage = '/profile';
    const email = localStorage.getItem('email');
    const {route} = useParams();
    const [code, setCode] = useState('');
    const isForgetPasswordEnabled = route === "forgetPassword" ? true : false 
    console.log(isForgetPasswordEnabled)
    function codeChange(event) {
        if (event.target.value.length > 1) {
            event.target.value = event.target.value.slice(-1);
        }
        if (event.target.value.length === 1) {
            let nextElementId = event.target.attributes.next.value;
            let nextElement = document.getElementById(nextElementId);
            if (nextElement) nextElement.focus();
        }
        let tmpcode = '';
        const inputs = document.getElementsByName('code');
        inputs.forEach(function (input) {
            tmpcode = tmpcode + input.value;
        });
        setCode(tmpcode);
    }

    

    const onSubmit = async (event) => {
        event.preventDefault();
        codeChange(event);
        let v_code = code + event.target.value;
        Auth.confirmSignUp(email, v_code)
            .then(user => {
                console.log('confirmed: ', user);
                navigate(nextPage);
            })
            .catch(error => {
                alertify.alert('Verification error', cleanErrorMessage(error));
            });
    }

    const resendCode = () => {
        try{
            const emailAdress = isForgetPasswordEnabled ? localStorage.getItem("FP_EMAIL") : email;
            Auth.resendSignUp(emailAdress)
            alertify.alert('Success!', cleanErrorMessage("Your verification code is sent."));
            
            
        } catch (err){
            alertify.alert('Error!', cleanErrorMessage(err));
        }
        
    }

    const onForgetPassword = (event) => {
        codeChange(event);
        let v_code = code + event.target.value;
        localStorage.setItem("V_CODE",v_code);
        navigate("/reset_password")
    }


    return (
        <Layout>
            <Styles>
            <Form onSubmit={onForgetPassword } autoComplete="off">
               <div className="header-wraper"> <p className='header'> Multi Factor Authentication</p> </div>
                <FormLabel className="sub-header">Please enter the 6 digit verification code sent to the submitted email address.</FormLabel>
        
                <div className="input-row">
                    <input id="inputCode1" type="number" name="code" className="custom-input" onChange={codeChange} next="inputCode2" pattern="[0-9]" />
                    <input id="inputCode2" type="number" name="code" className="custom-input" onChange={codeChange} next="inputCode3" pattern="[0-9]" />
                    <input id="inputCode3" type="number" name="code" className="custom-input" onChange={codeChange} next="inputCode4" pattern="[0-9]" />
                    <input id="inputCode4" type="number" name="code" className="custom-input" onChange={codeChange} next="inputCode5" pattern="[0-9]" />
                    <input id="inputCode5" type="number" name="code" className="custom-input" onChange={codeChange} next="inputCode6" pattern="[0-9]" />
                    <input id="inputCode6" type="number" name="code" className="custom-input" onChange={isForgetPasswordEnabled ? onForgetPassword : onSubmit} next="greenButton" pattern="[0-9]" />
                </div>
            </Form>
            <div className="forget-password-wraper">
            <FormLabel className="sub-header" onClick={resendCode}> Didn’t get the code? </FormLabel>
            </div>
            </Styles>
        </Layout>
    )
}
