import React, { useState } from 'react';
import { Col, Row , Form, FormLabel, Container, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import alertify from 'alertifyjs';

import { Layout } from './components/Layout';
import { LoadApp } from './components/GreenButton';
import { cleanErrorMessage } from './libraries/AuthLibraries';
import styled from "styled-components";


const Styles = styled.div`

margin-top:10vh;
.account-container{
    background: linear-gradient(99.87deg, #0D0D0D 0%, #8836B4 100%);
    border-radius: 15px;
    min-height: 430px;
    width:100%;
    padding-top: 50px;
    margin-bottom: 200px;
}

.password{
    font-style: normal;
    font-weight: 800;
    font-size: 35px;
    line-height: 48px;
    color : var(--mindaro-theme-primary-text);
    max-width : 250px;
    

}

button:hover{
    background : var(--mindaro-theme-third);
    border : none;
}

.description{
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    color : var(--mindaro-theme-primary-text);
    margin-bottom: 16px;
}

.custom-input{
    margin-bottom: 25px;
    color : var(--mindaro-theme-primary-text);
    border-top: none;
    border-left:none;
    border-right:none;
    border-bottom: 2px solid #fff;
    outline:none;
    padding-left:0px;
    background-color: transparent;
    border-radius : 0;
    max-width : 330px;
    display : block;
}

input::placeholder {
    color:var(--mindaro-theme-primary-text);
    
}

.title{
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    width : 400px;
    color: #0D0D0D;
    margin-bottom:3vh;
    

}

.sub-title{
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    color: #0A0909;
    width: 420px;
    text-align: center;
    margin-bottom: 47px;
    
}

.sub-title-wraper{
display: flex;
justify-content : center;
}

@media (max-width : 567px) {
    .title{
        text-align : center;
        font-size: 40px;
        line-height: 40px;
        width : 100%;
        margin-top : 0;
    }  
    
    .sub-title{
        width : 100%;
    }

    .password{
        font-weight: 800;
        font-size: 30px;
        line-height: 35px;
        justify-content-start;
    }
    .input-wrapper{
        display:flex;
        justify-content: center;
        padding-bottom : 20px;
    }
    .custom-input{
        width : 260px;
    }
    .account-container{
        margin-bottom : 60px;
    }
}
`

export const CreateAccount = () => {
    const navigate = useNavigate();
    const app = LoadApp();
    const hasPrevEmail = app.email !== undefined;
    const hasPrevPhone = app.phone !== undefined;
    const prevEmail = app.email || "";
    const prevPhone = app.phone || "";
    const nextPage = '/verification';
    const [email, setEmail] = useState(prevEmail);
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState(prevPhone);

    const onSubmit = async (event) => {
        event.preventDefault();
        Auth.signUp({
                username: email,
                password,
                attributes: {
                    email,
                }})
            .then(user => {
                localStorage.setItem('email', email);
                navigate(nextPage);
            })
            .catch(error => {
                alertify.alert('Sign up error', cleanErrorMessage(error));
            });
    }
    return (
        <Layout fullWidth={true}>
            <Styles>     
                <Container> 
             <FormLabel className="title">Let's create your account!</FormLabel>
               <div className='sub-title-wraper'> <p className="sub-title">Your personal information is used on your policy and your phone number helps us verify your identity. Already have an account? <Link className='orange' to="/login">Log in.</Link></p> </div>

                    <div className="account-container">
                    <Row >
                        <Col md="6" className="d-flex justify-content-center align-items-center">
                                <div className="input-hint">
                                     <span> <p  className="password">Set up your password </p> </span>
                                     <div className='description'>
                                         <span>Enter at least 8 characters with:</span>
                                    <ul style={{textAlign:"left"}}>
                                        <li>1 upper case</li>
                                        <li>1 lower case</li>
                                        <li>1 number</li>
                                        <li>1 special character.</li>
                                    </ul>
                                    </div>
                                 </div>
                        </Col>

                        <Col md="6" className="d-flex justify-content-flex-start align-items-center">
                        <Form onSubmit={onSubmit} autoComplete="off">
                                <Row className="input-wrapper">
                                    <input id="inputEmail" name="email" type="email" className="custom-input" placeholder="Work email" value={email} onChange={(event) => setEmail(event.target.value)} disabled={hasPrevEmail}/>

                                    <input id="inputPassword" name="password" type="password" className="custom-input" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
                            
                                    <input id="inputPhone" name="phone" type="tel" className="custom-input" placeholder="Phone number" value={phone} onChange={(event) => setPhone(event.target.value)} disabled={hasPrevPhone}/>
                                </Row>
                                
                                <Row className="input-wrapper">
                                <Button className="standard-button" type="submit" style={{width : "272px"}}>Create account</Button>
                                </Row>
                             </Form>
                        </Col>
                    </Row>
                    </div>
                    </Container>
                </Styles>
        </Layout>
    )
}