import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, FormLabel, Col, Row, Modal, Spinner } from 'react-bootstrap';
import Select from "react-select";
import { GreenButton, LoadApp, SaveData } from '../components/GreenButton';
import alertify from 'alertifyjs';
import { API } from 'aws-amplify';
import { cleanErrorMessage } from '../libraries/AuthLibraries';
import { dollarFormat } from '../libraries/Formatting';
import { getCowbellLimits, getCowbellLimitsOptions, getCowbellDeductibles, getCowbellDeductiblesOptions } from '../libraries/Cowbell';
import { FunnelLayout } from '../components/FunnelLayout';
import styled from "styled-components"
const Styles = styled.div`
color:#fff;
#buy-button {
    border-radius : 15px;
    width: 180px;
    height: 48px;
}
.quote-banner {
    background: linear-gradient(120.07deg, #000000 -1.22%, #8836B4 100%);
    border-radius: 10px;
    min-width: 238px;
    padding:17px;
}
.price-box {
    height: 120px;
    background: rgba(18, 18, 18, 0.3);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin:17px 0px;
}
.price-box p {
    ont-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin :0;
}
h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 70px;
    line-height: 84px;
}
h1 sup {
    font-size: 25px;
    top: -30px;
}
.title {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
}
.sub-title {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
}
ul li {
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 24px;
    display: flex;
    align-items: center;
}
li i {
    margin-right : 14px;
    font-size:10px;
}
.policy-pdf-link {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    color : var(--mindaro-theme-third);
    text-align: left;
    padding: 0;
    margin: 0;
}
.select-box-title {
    ont-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    text-align: left;
    margin: 28px 0;
    padding : 0;
}
.header {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-top: 17px;
    margin-bottom: 73px;    
    max-width: 480px;
}

@media(max-width : 768px) {
    .quote-banner {
        margin-bottom: 24px;
    }
    .policy-pdf-link {
        text-align : center;
    }
}
`

const ModalStyle = styled.div`
background: #282727;
box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.18);
color : var(--mindaro-theme-primary-text);
width: 500px;
height : 400px;
display: flex;
justify-content: center;
align-items : center;

.SpinnerText {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
}
@media(max-width : 768px) {
    width: 100%;
}
`

const SelectStyles = {
    container: (provided, state) => ({
        ...provided,
        borderBottom : "1px solid #fff", 
       marginBottom : "25px" 
    }),
    control: (provided, state) => ({
        ...provided,
       boxShadow : "none",
       borderColor: "none",
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display:"none"
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        overflow: "hidden",
        transform: state.hasValue || state.selectProps.inputValue ? "translateY(0.5rem)" : "",
        transition: "transform .1s ease-in-out",
        height: "52px",
        margin:0,
        padding:0
      }),
    placeholder: (provided, state) => ({
        ...provided,
        transformOrigin: "0 0",
        opacity: state.hasValue || state.selectProps.inputValue ? "0.65" : "",
        transform: state.hasValue || state.selectProps.inputValue ? "scale(0.85) translateY(-1.35rem)" : "",
        transition: "opacity .1s ease-in-out, transform .1s ease-in-out",
        marginBottom :"1px",
        color: "#fff"
      }),
      input: (provided, state) => ({
          ...provided,
        color: "#fff",
        outline: "none",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#fff"
    }),
    option: (provided, state) => ({
        ...provided,
        background:"#0D0D0D",
        borderBottom:  "1px solid #FFFDF7",
        padding  : "15px 0",
    }),
    menu: (provided, state) => ({
        ...provided,
        padding: "10px",
        background: "#0D0D0D",
        borderRadius: "4px",
        boxShadow : "-4px 6px 19px rgba(255, 253, 247, 0.11)",
    })
}

export const AdjustCoverage = (props) => {
    const app = LoadApp();
    const limits = getCowbellLimits(parseInt(app.annualRevenue), parseInt(app.naicsCode));
    const [limit, setLimit] = useState(parseInt(app.cyberLimit) || limits[0]);
    const handleLimitChange = (newLimit) => {
        setLimit(newLimit.value);
    }
    const deductibles = getCowbellDeductibles(parseInt(app.annualRevenue), parseInt(app.naicsCode));
    const [deductible, setDeductible] = useState(parseInt(app.cyberDeductible) || deductibles[0]);
    const handleDeductibleChange = (newDeductible) => {
        setDeductible(newDeductible.value);
    }
    const filterLimitsOptions = (candidate, _input) => {
        return limits.indexOf(parseInt(candidate.value)) !== -1;
    }
    const filterDeductiblesOptions = (candidate, _input) => {
        return deductibles.indexOf(parseInt(candidate.value)) !== -1;
    }
    const [monthly, setMonthly] = useState({});
    const navigate = useNavigate();
    const [showSpinner, setShowSpinner] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [previewURL, setPreviewURL] = useState('');
    const onSubmit = (event) => {
        event.preventDefault();
        navigate(props.next);
    }
    const pdfViewerEnabled = () => {
        return !!navigator.pdfViewerEnabled;
    }
    const doShowPreview = (event) => {
        event.preventDefault();
        setPreviewURL('');
        setShowPreview(true);
        setTimeout(getPreviewURL, 100);
    }
    const getPreviewURL = async () => {
        let app = LoadApp();
        let quote = JSON.parse(app.cowbellQuote);
        let accountId = quote.accountId;
        let quoteId = quote.id;
        let createdDate = Date.parse(quote.created);
        let result;
        try {
            console.log('Requesting previewURL...');
            result = await API.post('applicantwebapprestapi', '/cyber', {body: { method: 'preview', accountId, quoteId, createdDate }});
            console.log('result:', result);
            if ((!result.error) && result.data && result.data.previewURL && result.data.previewURL !== '') {
                if (pdfViewerEnabled()) {
                    setPreviewURL(result.data.previewURL);
                } else {
                    setPreviewURL(`${window.location.origin}/pdfjs/web/viewer.html?file=${encodeURIComponent(result.data.previewURL)}`);
                }
            } else {
                throw new Error('No previewURL, yet, retrying...');
            }
        } catch (err) {
            console.error(err);
            setTimeout(getPreviewURL, 1000);
        }
    }
    const doRefresh = async (limit, deductible) => {
        setShowSpinner(true);
        try {
            setMonthly({});
            setPreviewURL('');
            let result = await API.post('applicantwebapprestapi', '/cyber', {body: { method: 'quote', limit, deductible, app }});
            console.log(result);
            if (result.error) {
                alertify.alert('Error...', result.error);
            } else if ((!result.data) || (!result.data.details)) {
                alertify.alert('Error...', `There was an error generating a quote.  Please try again.`);
            } else if (result.data.details.error) {
                alertify.alert('Error...', result.data.details.error);
            } else if (result.data.details.agencyStatus === 'INVALID') {
                alertify.alert('Error...', result.data.details.agencyDescription);
            } else {
                await SaveData({
                    cyberLimit: limit.toString(),
                    cyberDeductible: deductible.toString(),
                    cowbellAccount: JSON.stringify(result.data.account),
                    cowbellQuote: JSON.stringify(result.data.details),
                });
                let premium = dollarFormat(result.data.details.totalPremium / 12, 2);
                let [ dollars, cents ] = premium.substr(1).split('.');
                setMonthly({ dollars, cents });
            }
        } catch (err) {
            alertify.alert('Error...', cleanErrorMessage(err));
        } finally {
            setShowSpinner(false);
        }
    }
    useEffect(() => {
        doRefresh(limit, deductible);
    }, [limit, deductible]);
    return (
        <FunnelLayout percent={props.percent} Col={8} HideAmber = {true}>
            <Styles>
                <Form noValidate onSubmit={onSubmit} autoComplete="off">
                    <FormLabel><h1><i><b>Woohoo!</b></i></h1></FormLabel><br/>
                    <FormLabel className="header">Your quote is ready. Businesses with similar risk profiles often chose the defaulted limits. You can adjust the coverage and deductible amounts below.</FormLabel>
                    <Row style={{marginBottom : "130px"}}>
                        <Col md="12" lg="5">
                    
                            <Row>
                                <div className="quote-banner">
                                    <p className="title">Cyber liability yearly plan</p>

                                    <div className="price-box">
                                    <h1><sup>$</sup>{monthly.dollars}<sup>{monthly.cents}</sup></h1>
                                    <p style={{fontWeight:"600"}}>Per month </p>
                                    </div>

                                    <p className="sub-title"> Underwritten by Cowbell Cyber </p>                           
                                    
                                    <ul>
                                        <li><i className="fas fa-check white-check"></i> Up to: {dollarFormat(limit)} liability</li>
                                        <li><i className="fas fa-check white-check"></i> Deductible: {dollarFormat(deductible)}</li>
                                        <li><i className="fas fa-check white-check"></i> Starting: {app.startDate}</li>
                                    </ul>
                                    <GreenButton id="buy-button" disabled={monthly === {}}>Buy plan</GreenButton>
                                </div> 
                            </Row>
                        </Col>
                        <Col md="0" lg="1"></Col>
                        <Col md="12" lg="6">
                            <a target="_blank" rel="noreferrer" className="policy-pdf-link" href={previewURL} onClick={doShowPreview}>
                                <Row style={{padding:'0'}}>
                                    <Col className="p-0">View policy preview PDF</Col>
                                </Row>
                            </a>
                            <FormLabel className="select-box-title">What would be the coverage limit you'll need for your business?</FormLabel>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                defaultValue={{value: limit.toString(), label: dollarFormat(limit)}}
                                onChange={handleLimitChange}
                                options={getCowbellLimitsOptions()}
                                styles={SelectStyles}
                                filterOption={filterLimitsOptions}
                            />
                            <FormLabel className="select-box-title">What would be the deductible amount for your policy?</FormLabel>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                defaultValue={{value: deductible.toString(), label: dollarFormat(deductible)}}
                                onChange={handleDeductibleChange}
                                options={getCowbellDeductiblesOptions()}
                                styles={SelectStyles}
                                filterOption={filterDeductiblesOptions}
                            />
                        </Col>
                    </Row>
                </Form>

                <Modal show={showPreview} keyboard={true} onHide={()=>setShowPreview(false)} dialogClassName="preview-dialog" contentClassName="preview-content">
                    <Modal.Header closeVariant="white" closeButton style={{borderBottom:'none',paddingBottom:'8px',color:'#fff',height:'48px'}}>
                        <Modal.Title  style={{fontSize:'16px'}}>Quote Preview PDF</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{padding:'0px 8px 8px 8px',height:'calc(100% - 50px)'}}>
                        { previewURL === ''
                            ?
                                <Spinner variant="warning" animation="border" role="status" style={{position:'absolute',top:'calc(50% - 50px)',left:'calc(50% - 50px)',width:'100px',height:'100px',borderWidth : "10px"}}>
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            :
                                <iframe src={previewURL} style={{width:'100%',height:'calc(100% - 8px)'}} title="Preview"></iframe>
                        }
                    </Modal.Body>
                </Modal>

                <Modal contentClassName="modalStyles" show={showSpinner} style={{top: "20%", borderRadius : "15px"}}>
                    <ModalStyle>
                        <Modal.Body>
                            <h2 className="SpinnerText">Sit tight while we use our super cool tech!</h2>
                            <Spinner variant="warning" animation="border" role="status" size="md" style={{width:'100px',height:'100px',borderWidth : "10px"}}>
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </Modal.Body>
                    </ModalStyle>
                </Modal>
             </Styles>
        </FunnelLayout>
    )
}