import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, FormLabel, InputGroup } from 'react-bootstrap';
import alertify from 'alertifyjs';
import { API } from 'aws-amplify';

import { GreenButton, LoadApp, SaveData, scrollToTop } from '../components/GreenButton';
import { Layout } from '../components/Layout';
import { TextInput } from '../components/CustomInputs';

export const AnnualGrossTooHigh = (props) => {
    const navigate = useNavigate();
    let app = LoadApp();
    const { register, handleSubmit, getValues, formState: { errors }} = useForm({
        defaultValues: {
            email: app.email,
            phone: app.phone,
            agreeToTermsAndConditions: app.agreeToTermsAndConditions,
        },
    });
    console.log(errors);
    const onSubmit = async (data) => {
        app = LoadApp();
        await SaveData(data);
        try {
            app = {...app, ...data};
            let response = await API.post('applicantwebapprestapi', '/monday', {body: {board: 'highrev', app}});
            if (response.data?.data?.create_item?.id !== undefined) {
                alertify.alert('Thanks!', 'Stay tuned, one of our licensed brokers will be calling you shortly.');
                navigate(props.next);
            } else {
                throw new Error(response.data?.error);
            }
        } catch (err) {
            console.error(err);
            alertify.alert('Error', err.message);
        }
    }
    return (
        <Layout percent={props.percent}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <FormLabel>
                    <h1><i><b>You rock!</b></i></h1>
                    <div>{app.firstName}, your business is awesome and needs more attention.</div>
                    <small className="small-grey">
                        <i>Please submit at least one preffered contact method below and one of our licenced brokers will contact you to complete your quote.</i>
                    </small>
                </FormLabel>
                <InputGroup>
                    <TextInput
                        name="email"
                        placeholder="Email address"
                        register={register}
                        required={false}
                        validate={(value) => !!getValues().phone || !!value}
                        errors={errors}
                    />
                </InputGroup>
                <InputGroup>
                    <TextInput
                        name="phone"
                        placeholder="Phone number"
                        register={register}
                        required={false}
                        validate={(value) => !!getValues().email || !!value}
                        errors={errors}
                    />
                </InputGroup>
                <br/>
                <InputGroup>
                    <Form.Check
                        style={{whiteSpace:'nowrap'}}
                        id="inputAgreeToTermsAndConditions"
                        name="agreeToTermsAndConditions"
                        type="checkbox"
                    >
                        <Form.Check.Input {...register('agreeToTermsAndConditions', {required: true})} />
                        <Form.Check.Label>
                            I agree to the <a target='_blank' href="/terms-conditions-cowbell">terms &amp; conditions</a>
                        </Form.Check.Label>
                    </Form.Check>
                </InputGroup>
               <GreenButton>Submit</GreenButton>
            </Form>
        </Layout>
    )
}