import React, {useState} from "react";
import {Button, Col,Container, Row} from "react-bootstrap"
import styled from "styled-components"

const Styles = styled.div`
.cookie-border{
position: fixed;
bottom:1%;
left:1%;
animation-name:cookie-animation;
animation-duration: 1s;
width:98%;
height:auto;
}

.cookie-banner{
height:auto;
width:100%;
background: linear-gradient(89.45deg, #090329 7.51%, #9035C2 99.83%);
padding:20px;
border-radius: 5px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
color : var(--mindaro-theme-primary-text);
}


@keyframes cookie-animation{
    from{
        bottom:-50%;
    }
    to{
        bottom:1%;
    }
}
.cookie-policy-accepted{
    position : fixed;
    left:1%;
    width:98%;
    height:auto;
    animation-name:cookie-animation-accepted;
    animation-duration: 2s;
}

@keyframes cookie-animation-accepted{
    from{
        bottom:1%;
    }

    to{
        bottom:-100%;
        display:none;
    }
}


`

export const Cookie = () => {
const [isAccepted, setisAccepted] = useState(false);
const [isCookieComponentRendered, setIsCookieComponentRendered] = useState(false);

const IS_COOKIE_ACCEPTED =localStorage.getItem("IS_COOKIE_ACCEPTED");

    if(!isCookieComponentRendered){

        if(IS_COOKIE_ACCEPTED){
            return <></>
        }
        else{
            setIsCookieComponentRendered(true)
        }
    }
    

const accept = () =>{
    localStorage.setItem("IS_COOKIE_ACCEPTED", true);
    setisAccepted(true);
}

    return(
        <Styles>
            <div  className={ isAccepted ? "cookie-policy-accepted"  : "cookie-border" }>
                <div className="cookie-banner">
                    <Container> 
                        <Row>
                            <Col md="10" sm="12" xs="12">
                                <p>
                                WE USE COOKIES: You’re here because you take your business seriously, and so does Mindaro. By accessing or using Mindaro’s site or service in any way, you are indicating that you have read, understood, and agree to our <span className="pink"><a href="/privacy"> privacy policy </a> </span> and <span className="pink"> <a href="/terms"> terms & conditions. </a></span> The information we track while users are on our website helps us analyze site traffic, optimize performance and improve our service.        
                                </p>
                            </Col>
        
                            <Col md="2" sm="12" xs="12"> 
                            <Row className="justify-content-center">
                                <Col md="6" xs="4">
                                     <Button size="md" className="button" variant="dark" onClick={accept} > Accept </Button>
                                </Col>

                                <Col md="6" xs="4">
                                     <Button size="md" style={{color : "#fff", borderColor: "#fff"}} fullwidth className="button" variant="outline-dark"  onClick={accept} > Close </Button>
                                </Col>

                            </Row>
                                 
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </Styles>
    );


}