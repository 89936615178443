import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Form, FormLabel, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { GreenButton, LoadApp, SaveData } from '../components/GreenButton';
import { Calendar } from 'react-calendar';
import serializeJSON from '../libraries/serializeJSON';
import { FunnelLayout } from '../components/FunnelLayout';
import styled from "styled-components"
import 'react-calendar/dist/Calendar.css';

const Styles = styled.div`
color: #AAAAAA;
.test{
    width : 100px;
    height : 100px;
}
.header{
    color : var(--mindaro-theme-primary-text);
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    margin-bottom:40px;
}

.react-calendar {
    background-color: var(--mindaro-grey)
        
}

.react-calendar__navigation {
    background-color: var(--mindaro-grey)
    color: var(--mindaro-theme-primary-text);
  }

  .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--mindaro-grey);
}


.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: linear-gradient(40.54deg, #FB772E 4.73%, #FEDE31 127.3%);
    border-radius : 50%;
   
}

.react-calendar__navigation button {
    background-color:var(--mindaro-grey);
    color: var(--mindaro-theme-primary-text);
  }

.react-calendar__navigation button[disabled] {
    background-color:var(--mindaro-grey);
    color: var(--mindaro-theme-primary-text);
  }

  .react-calendar__tile {
   color : var(--mindaro-theme-primary-text);
   width: 40px !important;
   height : 55px !important;
  }
 
  .react-calendar__tile:disabled {
    background: rgba(196, 196, 196, 0.2);
    color: #B8B8B8;
  }

  .react-calendar__tile--rangeStart:disabled {
      background : red;
  }


  .react-calendar__tile--hasActive {
    background: #76baff;
  }

  .react-calendar__tile--active {
    background: linear-gradient(40.54deg, #FB772E 4.73%, #FEDE31 127.3%);
    border-radius : 50%;
    color: var(--mindaro-theme-primary-background);
  }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled, 
    .react-calendar__tile--active, 
     {
    background: linear-gradient(40.54deg, #FB772E 4.73%, #FEDE31 127.3%);
    border-radius : 50%;
}

.react-calendar__month-view__days__day: hover{
    background: rgba(196, 196, 196, 0.2);
    color: #B8B8B8;
   
}

.react-calendar__month-view__days__day{
    width : 45px;
    height : 45px;
}

.date-label{
    font-style: normal;
    font-weight: 300;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color:#fff;
    display:block;
    
}

.date-value{
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 26px;
    text-align: center;
    color:#fff;
    display:block;
}


@media (max-width : 567px) {
    .react-calendar__tile {
        width: 40px !important;
        height : 45px !important;
       }
`

const ModalStyle = styled.div`
background: #282727;
box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.18);
color : var(--mindaro-theme-primary-text);
width: 500px;
height : 400px;
display: flex;
justify-content: center;
align-items : center;

.SpinnerText {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
}
@media(max-width : 768px) {
    width: 100%;
}
`

export const InsuranceDates = (props) => {
    const navigate = useNavigate();
    const app = LoadApp();
    const minDate = new Date();
    minDate.setDate(minDate.getDate()+1);
    const maxDate = new Date();
    maxDate.setDate(maxDate.getDate()+45);
    let start = Date.parse(app.startDate);
    start = isNaN(start) || start < minDate.getTime() ? start = minDate : start > maxDate.getTime() ? start = maxDate : new Date(start);
    const [startDate, setStartDate] = useState(start);
    let end = new Date(startDate);
    end.setFullYear(end.getFullYear()+1);
    const [endDate, setEndDate] = useState(end);
    const startDateChanged = (date) => {
        setStartDate(date);
        let end = new Date(date);
        end.setFullYear(end.getFullYear()+1);
        setEndDate(end);
    }
    const [showSpinner, setShowSpinner] = useState(false);
    const cowbellAccountExists = () => {
        let tempApp = LoadApp();
        return tempApp.cowbellAccount && tempApp.cowbellAccount !== '';
    }
    const checkCowbellAccount = () => {
        if (cowbellAccountExists()) {
            setShowSpinner(false);
            navigate(props.next);
        } else {
            setShowSpinner(true);
            setTimeout(checkCowbellAccount, 1000);
        }
    }
    const onSubmit = async (event) => {
        event.preventDefault();
        const form = event.target;
        const strData = serializeJSON(form);
        const newData = JSON.parse(strData);
        await SaveData(newData);
        setTimeout(checkCowbellAccount, 100);
    }
    return (
        <FunnelLayout percent={props.percent}>
            <Form noValidate onSubmit={onSubmit} autoComplete="off">
            <Styles>
                <FormLabel className="header">When would you like your policy to begin?</FormLabel>
                
               
                    <Calendar
                        minDate={minDate}
                        maxDate={maxDate}
                        value={startDate}
                        calendarType="US"
                        tileClassName="test"
                        formatMonthYear={(locale, date) => date.toLocaleDateString('en-us', { year:"numeric", month:"short"})}
                        className="calendar-control"
                        onChange={startDateChanged}
                        
                    />
               
                <InputGroup>
                    <Row className="d-flex justify-content-center">
                        <Col xs="5">
                            <FormLabel className="date-label">Start date</FormLabel>
                            <FormLabel className="date-value">{startDate.toLocaleDateString()}</FormLabel>
                        </Col>
                        <Col xs="1">
                            <div className='vr' style={{width:"3px", height : "100%" , backgroundColor:"#fff"}}></div>
                        </Col>
                        <Col xs="5">
                                <FormLabel className="date-label">End date</FormLabel>
                                <FormLabel className="date-value">{endDate.toLocaleDateString()}</FormLabel>
                                
                        </Col>
                    </Row>

                    <input type="hidden" name="startDate" value={startDate.toLocaleDateString()} />
                    <input type="hidden" name="endDate" value={endDate.toLocaleDateString()} />
                </InputGroup>
                <InputGroup>
                </InputGroup>
                <div className="buttonwrapper short-margin-buttonwrapper">
                    <GreenButton id="green-button" disabled={showSpinner}>Continue</GreenButton>
                </div>
                </Styles>
            </Form>

            <Modal contentClassName="modalStyles" show={showSpinner} style={{top: "20%", borderRadius : "15px"}}>
                <ModalStyle >
                    <Modal.Body>
                        <h2 className="SpinnerText">Wow! You got here faster than we expected. Please wait while we finish setting up your account...</h2>
                        <Spinner variant="warning" animation="border" role="status" size="md" style={{width:'100px',height:'100px',borderWidth : "10px"}}>
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </Modal.Body>
                </ModalStyle>
            </Modal>

        </FunnelLayout>
    )
}
