import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, FormLabel, InputGroup } from 'react-bootstrap';
import Autocomplete from "react-google-autocomplete";
//import { GoogleMap, Marker, withGoogleMap } from "react-google-maps";
import { GreenButton, LoadApp, SaveData } from '../components/GreenButton';
import { CustomSelect, TextInput } from '../components/CustomInputs';
//import locatorImage from '../assets/locator.png';
import { findStateByAbbr, findStateByName, statesSortedByName } from '../data/states';
import { FunnelLayout } from '../components/FunnelLayout';
import  styled from "styled-components"

// const MyMapComponent = withGoogleMap((props) => {
//     console.log(props);
//     return (
//         <GoogleMap
//             defaultZoom={17}
//             defaultCenter={{ lat: props.lat, lng: props.lng }}
//             options={{
//                 disableDefaultUI: true,
//             }}
//             >
//             <Marker position={{ lat: props.lat, lng: props.lng }} />
//         </GoogleMap>
//     )
// }
// );

const Styles = styled.div`
.AutoComplete {
    color:#fff;
  border-bottom: 1px solid #fff;
  outline:none;
  padding-left:0px;
  background-color: #272626;
  margin-bottom: 40px;
}
`


const title = "What is your business address?"

export const BusinessAddress = (props) => {
    const navigate = useNavigate();
    const app = LoadApp();
    const [manualAddressEntry, setManualAddressEntry] = useState(app.manualAddressEntry ? true : false);
    // const [lat, setLat] = useState(0);
    // const [lng, setLng] = useState(0);
    const formatStateLabel = (state) => {
        return state.abbr +' - '+ state.name;
    }
    const { register, handleSubmit, formState: { errors }, reset, control} = useForm({
        defaultValues: {
            address: app.address,
            city: app.city,
            state: app.state ? {value: app.state, label: formatStateLabel(findStateByAbbr(app.state))} : '',
            zipCode: app.zipCode,
        },
    });
    const onSelected = (places, _ref, _autocompleteRef) => {
        console.log('places:', places);
        if (places.adr_address) {
            let parser = document.getElementById('addressParser');
            parser.innerHTML = places.adr_address;
            let temp = {};
            temp.address = document?.querySelector('#addressParser > span.street-address')?.innerText;
            temp.city = document?.querySelector('#addressParser > span.locality')?.innerText;
            temp.state = document?.querySelector('#addressParser > span.region')?.innerText;
            temp.zipCode = document?.querySelector('#addressParser > span.postal-code')?.innerText;
            reset(temp);
        }
        // setLat(places.geometry.location.lat);
        // setLng(places.geometry.location.lng);
        // document.getElementById('mapGroup').style.display = 'block';
    }
    const manualEntryClick = async () => {
        let temp = {};
        temp.manualAddressEntry = !manualAddressEntry;
        await SaveData(temp);
        setManualAddressEntry(!manualAddressEntry);
    }
    const getDefaultAddress = (app) => {
        if ('address' in app) {
            return app.address +', '+ app.city +', '+ app.state;
        } else {
            return '';
        }
    }
    const getStateOptions = () => {
        let options = [];
        statesSortedByName().forEach((state) => options.push({value: state.abbr, label: formatStateLabel(state)}))
        return options;
    }
    const onSubmit = async (data) => {
        console.log('data:', data);
        if (data.address && data.city && data.state && data.zipCode)
        {
            if (typeof data.state === 'object') data.state = data.state.value;
            data.state = data.state.toUpperCase();
            let tempState = findStateByAbbr(data.state);
            if (!tempState) {
                tempState = findStateByName(data.state);
                if (tempState) data.state = tempState.abbr;
            }
            await SaveData(data);
            if (tempState && tempState.avail) {
                navigate(props.next);
            } else {
                window.location.href = '/state-not-launched/';
            }
        }
    }
    const onEditKeyPress = (event) => {
        let key = (event.charCode || event.which || event.keyCode);
        if (key === 13) {
            event.preventDefault();
            event.stopPropagation();
        }
    }
    return (
        <FunnelLayout title={title} percent={props.percent}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <FormLabel>What is your business address?</FormLabel>
                { manualAddressEntry
                    ?
                        <>
                    
                                <TextInput name="address" placeholder="Street address" register={register} errors={errors} />
                           
                                <TextInput name="city" placeholder="City" register={register} errors={errors} />
                           
                                <CustomSelect name="state" placeholder="State" options={getStateOptions()} control={control} errors={errors} />
                           
                                <TextInput name="zipCode" placeholder="ZIP Code" register={register} errors={errors} />
                            
                        </>
                    :
                        <>
                            
                                <Styles>
                                <Autocomplete
                                    id="addressAutocomplete"
                                    defaultValue={getDefaultAddress(app)}
                                    placeholder="Street Address, City, State, ZIP"
                                    apiKey="AIzaSyCRRl2MH3Sg4O44CsMH8wjQ3FPaLmAe4IE"
                                    onPlaceSelected={onSelected}
                                    inputAutocompleteValue="off"
                                    onKeyPress={onEditKeyPress}
                                    className="AutoComplete"
                                    options={{
                                        fields: ['all'],
                                        restrictions: {
                                            country: 'us',
                                        },
                                        componentRestrictions:{
                                            country: "us",
                                        },
                                        types: ['address'],
                                    }}
                                />
                                </Styles>
                           
                            <InputGroup  style={{cursor:'pointer', paddingLeft:15}} onClick={manualEntryClick}>
                                <p className="orange">+ Add manually</p>
                            </InputGroup>
                            {/* <InputGroup id="mapGroup" style={{display:'none'}}>
                                <MyMapComponent
                                    lat={lat}
                                    lng={lng}
                                    containerElement={<div id="mapContainer" style={{width:'100%',height:'250px',borderRadius:'4px'}} className="shadow" />}
                                    mapElement={<div id="mapElement" style={{width:'100%',height:'250px',borderRadius:'4px'}} />}
                                />
                            </InputGroup> */}
                            <div id="addressParser" style={{display:'none'}}></div>
                        </>
                    }
                    <div className="buttonwrapper">
                        <GreenButton>Save address</GreenButton>
                    </div>
            </Form>
        </FunnelLayout>
    )
}