import React from 'react';
import { Form, FormLabel } from 'react-bootstrap';
import { FunnelLayout } from '../components/FunnelLayout';
import styled from 'styled-components';

const Styles = styled.div`
color : var(--mindaro-theme-primary-text);

.header{
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    margin-bottom: 17px;
}
`;

export const PaymentCancel = () => {
    return (
        <FunnelLayout HideBackButton={true} HideAmber={true}>
            <Styles>
                <Form autoComplete="off">
                    <FormLabel className="header">
                        Payment cancelled
                    </FormLabel>
                </Form>
            </Styles>
        </FunnelLayout>
    )
}
