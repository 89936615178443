import { Spinner } from "react-bootstrap"

export const SpinnerPage = () => {

    return (
        <Spinner
            role="status"
            variant="primary"
            animation="border"
            style={{
                position:'absolute',
                top:'calc(50% - 50px)',
                left:'calc(50% - 50px)',
                width:'100px',
                height:'100px',
            }}
        />
    )
}