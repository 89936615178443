import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form} from 'react-bootstrap';

import { GreenButton, LoadApp, SaveData } from '../components/GreenButton';
import { TextInput, NumberInput, CustomSelect, CustomAsyncSelect } from '../components/CustomInputs';

import { naics_codes } from '../data/naics_codes';
import { cowbell_naics_exclusions } from '../libraries/Cowbell';
import Fuse from 'fuse.js';
import { FunnelLayout } from '../components/FunnelLayout';

export const ChooseBusiness = (props) => {
    const navigate = useNavigate();
    const app = LoadApp();
    const businessTypeOptions = [
        { value: 'Public', label: 'Public'},
        { value: 'Private', label: 'Private'},
        { value: 'Non-Profit', label: 'Non-Profit'},
        { value: 'Public Sector', label: 'Public Sector'},
        { value: 'Partnership', label: 'Partnership'},
        { value: 'Non-Corporates', label: 'Non-Corporates'}
    ];
    const { control, register, handleSubmit, formState: { errors }, setValue, getValues, setError, clearErrors } = useForm({
        defaultValues: {
            businessName: app.businessName,
            business: app.business ? { value: app.business, label: app.business } : '',
            businessType: app.businessType ? { value: app.businessType, label: app.businessType } : '',
            businessYear: app.businessYear,
        },
    });
    const options = {
        keys: ['d'],
        includeScore: true,
        minMatchCharLength: 3,
        threshold: 0.3,
    }
    const fuse = new Fuse(naics_codes, options);
    const filterNaicsCodes = (inputValue) => {
        if (inputValue.length > 2) {
            let result = fuse.search(inputValue);
            return result;
        } else {
            return [];
        }
    }
    const getNaicsCode = (desc) => {
        return naics_codes.find((value) => {
            return value.d === desc;
        }).c;
    }
    const loadBusinessOptions = (inputValue, callback) => {
        let result = [];
        filterNaicsCodes(inputValue).forEach((value, index) => {
            result.push({value:value.item.d, label:value.item.d});
        });
        callback(result);
    }
    const handleInputChange = (newValue) => {
        if (newValue !== '') {
            let temp = {value: newValue, label: newValue};
            setValue('business', temp);
            return temp;
        }
    }
    const onSubmit = async (data) => {
        data.business = data.business !== '' ? data.business.value : '';
        let naicsCode = getNaicsCode(data.business);
        data.naicsCode = naicsCode.toString();
        // TODO: Need a way to flag business field as invalid if naics code wasn't found here
        data.businessType = data.businessType !== '' ? data.businessType.value : '';
        await SaveData(data);
        if (cowbell_naics_exclusions.includes(naicsCode)) {
            window.location.href = "/nonfit";
        } else {
            navigate(props.next);
        }
    }

    const OnBussinessYearChange = (e) => { 
       const year = e.target.value;
       const currentYear = parseInt(new Date().getFullYear());
       const currentValue = getValues("businessYear");
        let val = '';

        if(year === ''){
            val=''
        }    

        else if(year.length > 4){
            val = currentValue;
        }    
       
        else if( parseInt(year) <= currentYear ){
            val=year;
            clearErrors(['businessYear']); 
       }

        else{
        val = currentValue;
        setError('businessYear',{ type: 'custom', message: 'Please enter a valid year!' })
        }

        setValue('businessYear', val)
                
    }
    const getNoOptionsMessage = (inputObject) => {
        return inputObject.inputValue.length > 2 ? 'No matches found' : 'Type at least 3 letters';
    }

    const fullName = `${app.firstName} ${app.lastName}`
    const title = `Nice to meet you ${fullName}! Tell me about your business.`

    return (
            <FunnelLayout percent={props.percent} title={title}>
            <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                
                    <TextInput name="businessName" placeholder="Your business name" register={register} errors={errors} />
             
                
                    <CustomAsyncSelect name="business" placeholder="Choose your business" loadOptions={loadBusinessOptions} onInputChange={handleInputChange} control={control} errors={errors} noOptionsMessage={getNoOptionsMessage} />
               
             
                    <CustomSelect name="businessType" placeholder="Type of business" options={businessTypeOptions} control={control} errors={errors} />
                
                    
                    <NumberInput name="businessYear" placeholder="What year did you start your business?" register={register} errors={errors} onChange={OnBussinessYearChange}/>
                    {errors?.businessYear?.type === 'custom' && <p style={{color:"red", textAlign: "left"}}>{errors?.businessYear?.message}</p>}
                    
                    <div className="buttonwrapper">
                        <GreenButton>Continue</GreenButton>
                    </div>
            </Form>
            </FunnelLayout>
        
    )
}
