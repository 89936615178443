import React, { useState } from 'react';
import {  Form} from 'react-bootstrap';
import {  useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import alertify from 'alertifyjs';
import { Layout } from './components/Layout';
import { GreenButton, LoadApp } from './components/GreenButton';
import { cleanErrorMessage } from './libraries/AuthLibraries';
import styled from "styled-components";


const Styled = styled.div`
.header{
    font-family: 'Red Hat Display';
    font-style: normal;
    font-weight: 900;
    font-size: 50px;
    line-height: 66px;
    text-align: center; 
    color: #0D0D0D;
    max-width : 460px;
    margin-bottom : 35px;
    margin-top : 100px;
    
}

.sub-header{
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    color: #0A0909;
    margin-bottom: 50px;

    
}
.custom-input{
    margin-bottom: 25px;
    color : var( --mindaro-theme-primary-background);
    border-top: none;
    border-left:none;
    border-right:none;
    border-bottom: 2px solid  var(--mindaro-theme-primary-background);
    outline:none;
    padding-left:0px;
    background-color: transparent;
    border-radius : 0;
    max-width : 300px;
}

input::placeholder {
    color:var( --mindaro-theme-primary-background);
    
}

.buttonWrapper{
margin-bottom: 300px;
}

.sub-header{
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 23px;
    text-align: center;
    
    color: #0A0909;
}
@media (max-width : 567px) {
    .header{
        text-align : center;
        font-size: 40px;
        line-height: 40px;
        width : 100%;
        margin-top : 20px;
    }  
    .buttonWrapper{
        position : absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top : 10vh;
    }
}
`

export const Forgetpassword = () => {
    const navigate = useNavigate();
    const app = LoadApp();
    const prevEmail = app.email || "";
    const nextPage = '/verification/forgetPassword';
    const [email, setEmail] = useState(prevEmail);

    const onSubmit = async (event) => {
        event.preventDefault();
        Auth.forgotPassword(email)
            .then(result => {
                localStorage.setItem("FP_EMAIL", email)
                navigate(nextPage)
            })
            .catch(error => {
                alertify.alert('Error!', cleanErrorMessage(error));
            });
    }

    return (
        <Layout>
        <Styled>
        <Form onSubmit={onSubmit} autoComplete="off">
            <p className="header">Reset your <br/> password</p>
            <p className="sub-header">Please enter your sign up email.</p>
                <input id="inputEmail" name="email" type="email" className="custom-input" placeholder="Email address" value={email} onChange={(event)=>setEmail(event.target.value)} />
                <div className="buttonWrapper">
            <GreenButton style={{width: "270px", background :"var(--mindaro-theme-primary-background)", border: "none"}}>Send Code</GreenButton>
                </div>
        </Form>
        </Styled>
    </Layout>
    )
}