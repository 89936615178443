import React, { Component } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async";

const { ValueContainer, Placeholder } = components;

const Styles = {
    container: (provided, state) => ({
        ...provided,
        borderBottom : "1px solid #fff", 
       marginBottom : "25px" 
    }),
    control: (provided, state) => ({
        ...provided,
       boxShadow : "none",
       borderColor: "none",
       
        
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display:"none"
        
    }),
    
    valueContainer: (provided, state) => ({
        ...provided,
        overflow: "hidden",
        transform: state.hasValue || state.selectProps.inputValue ? "translateY(0.5rem)" : "",
        transition: "transform .1s ease-in-out",
        height: "52px",
        margin:0,
        padding:0
        
        
      }),
      
    placeholder: (provided, state) => ({
        ...provided,
        transformOrigin: "0 0",
        opacity: state.hasValue || state.selectProps.inputValue ? "0.65" : "",
        transform: state.hasValue || state.selectProps.inputValue ? "scale(0.85) translateY(-1.35rem)" : "",
        transition: "opacity .1s ease-in-out, transform .1s ease-in-out",
        marginBottom :"1px",
        color: "#fff"
      }),
      input: (provided, state) => ({
          ...provided,
        color: "#fff",
        outline: "none",
        
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#fff"
      
    }),
    option: (provided, state) => ({
        ...provided,
        background:"#0D0D0D",
        borderBottom:  "1px solid #FFFDF7",
        padding  : "15px 0",
    }),

    menu: (provided, state) => ({
        ...provided,
        padding: "10px",
        background: "#0D0D0D",
        borderRadius: "4px",
        boxShadow : "-4px 6px 19px rgba(255, 253, 247, 0.11)",
        
    
    })

} 

const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
}

export default class FloatingSelect extends Component {
    render() {
        return (
            <Select {...this.props}
                components = {{
                    ValueContainer: CustomValueContainer,
                }}
                styles = {Styles}
            />
        );
    }
}

export class FloatingAsyncSelect extends Component {
    render() {
        return (
            <AsyncSelect {...this.props}
                components = {{
                    ValueContainer: CustomValueContainer,
                }}
                styles = {Styles}
            />
        );
    }
}