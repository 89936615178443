import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Header } from './Header/';
import { Auth } from 'aws-amplify';
import { Cookie } from '../Cookie';
import { Footer } from './Footer/';
import styled from 'styled-components';
import { LoadApp, scrollToTop } from "./GreenButton"
import { useLocation, useNavigate } from 'react-router-dom';
import alertify from 'alertifyjs';
const Styles = styled.div`

@media(max-width:575px){
   .onlyDesktop{
       display:none;
   } 
}
`

export const Layout = (props) => {
    // TODO: We need a better test for this
    const navigate = useNavigate();
    const location = useLocation();

    const delayedRedirect = async () => {
        alertify.alert('Notice!', 'You already have a cyber policy. We cannot make changes or quote a new cyber policy.');
        navigate('/profile');
    }

    const app = LoadApp();
    if (location.pathname.startsWith('/cyber/') &&
        location.pathname !== '/cyber/success' &&
        location.pathname !== '/cyber/contact_info' &&
        location.pathname !== '/cyber/payment_complete' &&
        location.pathname !== '/cyber/payment_cancel' &&
        app.cowbellAttestAndBind &&
        app.cowbellAttestClientIP &&
        app.cowbellAttestTimestamp) {
            setTimeout(delayedRedirect, 100);
    }
    
    useEffect(() => scrollToTop(), [] )
    const setStyles = (loggedIn) => {
        const styleSheetId = 'loginStyles';
        // find inserted style
        let style = document.getElementById(styleSheetId);
        // if not found, create it
        if (!style) {
            style = document.createElement('style');
            style.id = styleSheetId;
            document.head.appendChild(style);
        }
        // update the style's content
        style.innerHTML = loggedIn ? `.profile-button {display: inline-block !important;}` : `.login-button {display: inline-block !important;}`;
    }
    useEffect(() => {
        Auth.currentAuthenticatedUser({ bypassCache: false })
        .then( user => {
            setStyles(true);
        })
        .catch( error => {
            setStyles(false);
        });
        return () => {
            // nothing to clean up here
        }
    });

    return (
        <React.Fragment>
            <Header percent={props.percent} hideAmberRow={props.hideAmberRow} minimalHeader={props.minimalHeader} backButton={props.backButton}/>
            <Container className={props.fullWidth && "p-0"} fluid={props.fullWidth} id={props.id} style={{minHeight:"60vh"}}>
                {props.children}
            </Container>
            {/* <Cookie/> */}
            <Styles>
                <div className={props.visibleOnMobile ? undefined : "onlyDesktop"}>
                    <Footer/>  
                </div> 
            </Styles>
        </React.Fragment>
    )
}
