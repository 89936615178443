import React from "react";
import { Layout } from "./components/Layout";
import styled from "styled-components";
import { Col, Container, Table } from "react-bootstrap";
import {LicensesData} from "./data/LicensesData"

const Styles = styled.div`
#licenses-container {
    margin: 0px;
    padding: 0px;
    max-width: unset;
}
  h1 {
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 30px;

    
  }

  p {
    
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
    
  }
  .align-left{
      text-align:left;
  }

  .line{
    width: 146px;
    height: 0px;
    border: 2px solid;
  }

  @media only screen and (max-width: 768px) {
    font-size:14px;
  }


`;
export const Licenses = () => {
  return (
      <>
      <Styles>
          
    <Layout id="licenses-container" minimalHeader={true} visibleOnMobile={true}>
            <Container>
              <Col md={8} xs={12}>
            <div className="align-left">
               
            <h1 className="mt-5">Mindaro Insurance - State Licenses</h1>
            <hr  className="line pink"/>
            <p>
            Mindaro Insurance LLC is licensed to sell insurance products in 25 U.S states:
            </p>

          <LicensesTable />
        </div>
      
    
    </Col>
    </Container>
    </Layout>
    </Styles>
    </>
  );
};


const LicensesTable = () => {

    return(
    <Table  bordered>
        <thead>
            <tr>
                <th>State</th>
                <th>P&C License#</th>
                <th>Surplus License#</th>
            </tr>
            {
                LicensesData.map( (Licenses, index) => {
                    return(
                    <tr  key={index}> 
                        <td className="align-left">{Licenses.state}</td>
                        <td className="align-left">{Licenses.pcLicense}</td>
                        <td className="align-left">{Licenses.surplusLicence}</td>
                    </tr>
                    )
                })
            }
            
        </thead>
    </Table>
    )
}
